import { Controller, global, LoggedUser } from "@autoprog/core-client"

import 'daterangepicker'
import 'daterangepicker/daterangepicker.css'

import TabsExtended from '@libs/TabsExtended'

// import C_TabEmployee from '@libs/tabs/humanRessources/TabEmployee'
import C_TabHours from '@libs/tabs/humanRessources/TabHourSheet'
import C_TabExpenseReport from '@libs/tabs/humanRessources/TabExpenseReport'
import C_TabStats from '@libs/tabs/humanRessources/TabStats'

// import T_TabEmployee from '@tpl/tabs/humanRessources/tabs1.html'
import T_TabHours from '@tpl/tabs/humanRessources/tabs2.html'
import T_TabExpenseReport from '@tpl/tabs/humanRessources/tabs3.html'
import T_TabStats from '@tpl/tabs/humanRessources/tabs4.html'



import '@css/humanRessources.css'

class HumanRessourcesCtrl extends Controller {

	private tabs: TabsExtended;

	constructor(private el: HTMLElement, params: object) {
		super(el, params)

		const loggedUser = LoggedUser.getInstance();

		this.tabs = new TabsExtended({
			navElement: this.el.querySelector('#human-resources-menu') as HTMLElement,
			renderElement: this.el.querySelector('.human-resources-content') as HTMLElement,
			navTemplate: '<button permission="${tab.data.permission}" tab-link="${tab.id}" class="btn btn-tab btn-grey-300">${tab.name}</button>',
		})

		this.tabs.on('tab.show', () => {
			const activeTab = this.tabs.getActiveTab() as any
			activeTab?.instance?.updateSelectSite && activeTab?.instance?.updateSelectSite(global.SITE)
		})

		this.tabs.add({
			name: 'Fiche Heures',
			controller: C_TabHours,
			template: T_TabHours,
			default:
				loggedUser.hasPermission('HUMAN_RESSOURCES.HOURS.SHOW'),
			data: {
				permission: 'HUMAN_RESSOURCES.HOURS.SHOW'
			}
		})

		this.tabs.add({
			name: 'Note de frais',
			controller: C_TabExpenseReport,
			template: T_TabExpenseReport,
			default:
				!loggedUser.hasPermission('HUMAN_RESSOURCES.HOURS.SHOW') &&
				loggedUser.hasPermission('HUMAN_RESSOURCES.EXPENSE_REPORT.SHOW'),
			data: {
				permission: 'HUMAN_RESSOURCES.EXPENSE_REPORT.SHOW'
			}
		})

		this.tabs.add({
			name: 'Statistiques',
			controller: C_TabStats,
			template: T_TabStats,
			default:
				!loggedUser.hasPermission('HUMAN_RESSOURCES.HOURS.SHOW') &&
				!loggedUser.hasPermission('HUMAN_RESSOURCES.EXPENSE_REPORT.SHOW') &&
				loggedUser.hasPermission('HUMAN_RESSOURCES.STATS.SHOW'),
			data: {
				permission: 'HUMAN_RESSOURCES.STATS.SHOW'
			}
		})

		this.siteSelect()
	}

	private siteSelect() {

		setTimeout(() => {

			const N_siteSelect = this.el.querySelector('#site-select') as HTMLSelectElement;

			N_siteSelect.addEventListener('input', () => {
				global.SITE = N_siteSelect.value

				const activeTab = this.tabs.getActiveTab() as any;

				if (activeTab && activeTab.instance && activeTab.instance.updateSelectSite) {
					activeTab.instance.updateSelectSite(global.SITE)
				}

			});

		});

	}

	public destructor() {

		const N_daterangepicker = document.querySelectorAll('.daterangepicker');
		N_daterangepicker.forEach((item) => {
			const parent = item.parentNode as HTMLElement
			parent.removeChild(item)
		})
		this.tabs.destructor()


	}

}

export default HumanRessourcesCtrl

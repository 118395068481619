import { Modal, Form, toaster } from "@autoprog/core-client"

import T_addEditSubAnalytic from '../../../../tpl/modals/settings/addEditSubAnalytic.html'

import SubAnalyticService, { SubAnalytic } from '../../services/SubAnalyticsService'

class AddEditSubAnalytic extends Modal {

	public constructor(id?: string) {
		super({
			template: T_addEditSubAnalytic,
			backdrop: 'static',
			keyboard: false
		});

		this.on('opened', async () => {

			let N_form = this.element.querySelector('form');

			if (N_form) {

				let form = new Form(N_form)

				let N_save = this.element.querySelector('#save-button');

				if (N_save) {

					N_save.addEventListener('click', async () => {

						if (form.checkValidity()) {

							let service = SubAnalyticService.getInstance();

							let data = form.getData();

							//Gestion des chaines vide
							if(!data._rev){
								delete data._rev
							}

							
							if (id) {


								try {
									await service.update(id, data as SubAnalytic);
									toaster.success('Sous-analytique mise à jour');
									this.resolve();
								} catch (e) {
									toaster.error( `Mise à jour du sous-analytique`, 'Erreur')
									console.error(e);
								}
							} else {

								try {
									await service.add(data as SubAnalytic);
									toaster.success('Sous-analytique ajoutée')
									this.resolve();
								} catch (e) {
									toaster.error( `Ajout du sous-analytique`, 'Erreur')
									console.error(e);
								}

							}

						}

					});

				}

				if (id) {
				
					try {
						let data = await SubAnalyticService.getInstance().getById(id);
						if (data) {
							form.setData(data);
						}
					} catch (e) {
						toaster.error(`Chargement du sous-analytique`, 'Erreur');
						console.error(e);
					}
				}

			}

		})

	}



}

export default AddEditSubAnalytic;
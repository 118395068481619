import { Tab, toaster } from "@autoprog/core-client"

import _ from 'lodash'

import { GridOptions, Grid, ColDef } from 'ag-grid-community';
import h from 'hyperscript'
import HoursCategoriesService from '../../services/HoursCategories'

import AddEditHourCategory from '../../modals/settings/AddEditHourCategory'
import AnalyticsService from '@js/libs/services/AnalyticsService';
import SubAnalyticsService from '@js/libs/services/SubAnalyticsService';

class HumanRessources extends Tab {

	private hoursCategoriesGridOptions: GridOptions

	constructor(private el: HTMLElement) {
		super(el)

		this.hoursCategoriesGridOptions = {

			columnDefs: [
				{
					headerName: 'Libellé',
					field: 'label',
					sort: 'asc',
				},
				{
					headerName: 'Sous-Analytique',
					field: 'subAnalyticName',
					hide: true
				},
				{
					headerName: "",
					field: "commands",
					width: 8 + 28 * 2,
					sortable: false,
					filter: false,
					suppressSizeToFit: true,
					pinned: 'right',
					cellRenderer: (params) => {

						let div = h('div.d-flex.justify-content-end') as HTMLElement;

						let editButton = document.createElement('button');
						editButton.classList.add('btn');
						editButton.classList.add('btn-xs');
						editButton.classList.add('btn-info');
						editButton.setAttribute('permission', 'SETTINGS.HUMAN_RESSOURCES.HOURS_CATEGORIES.EDIT')
						editButton.innerHTML = '<i class="icon icon-solid-pencil-alt"></i>'

						let deleteButton = document.createElement('button');
						deleteButton.classList.add('btn');
						deleteButton.classList.add('btn-xs');
						deleteButton.classList.add('btn-danger');
						deleteButton.setAttribute('confirmation', '');
						deleteButton.setAttribute('permission', 'SETTINGS.HUMAN_RESSOURCES.HOURS_CATEGORIES.DELETE')
						deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"></i>'

						deleteButton.disabled = params.data.locked;
						if (deleteButton.disabled) {
							deleteButton.setAttribute('tooltip', 'Actuellement utilisé')
							deleteButton.classList.add('btn-grey-200');
						} else {

							deleteButton.addEventListener2('click', async () => {
								try {
									await HoursCategoriesService.getInstance().delete(params.data._id);
									this.loadHoursCategories();
									toaster.success(`Catégorie supprimée`);
								} catch (e) {
									toaster.error(`Supression de la catégorie`, 'Erreur');
								}
							});
						}

						editButton.addEventListener2('click', () => {
							(new AddEditHourCategory(params.data._id)).open().then(() => {
								this.loadHoursCategories();
							})
						})

						div.appendChild(editButton);
						div.appendChild(deleteButton);

						return div;
					}
				}
			],

			rowData: [],

			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			},
			defaultColDef: {
				sortable: true
			}
		}

		this.createAnalyticsColumn()

		const N_hoursCategories = this.el.querySelector('#hours-categories-grid') as HTMLElement;
		new Grid(N_hoursCategories, this.hoursCategoriesGridOptions)

		this.loadHoursCategories()

		const N_addHourCategoryBtn = this.el.querySelector('#add-hoursCategories-btn') as HTMLButtonElement
		N_addHourCategoryBtn.addEventListener2('click', () => {
			(new AddEditHourCategory()).open().then(() => this.loadHoursCategories())
		})

		const N_exportHourCategoryBtn = this.el.querySelector('#export-hoursCategories-btn') as HTMLButtonElement
		N_exportHourCategoryBtn.addEventListener2('click', () => {
			const excludeFields = ['commands']
			this.hoursCategoriesGridOptions.api?.exportDataAsExcel({
				fileName: 'RessourceHumaine.xlsx',
				columnKeys: (this.hoursCategoriesGridOptions.columnDefs || []).filter((colDef : ColDef) => !!colDef.field).map((colDef : ColDef) => colDef.field as string).filter(name => !excludeFields.includes(name))
			})
		})
	}

	private createAnalyticsColumn() {
		let analytics = AnalyticsService.getInstance().getAll()
		let columns: Array<ColDef> = analytics.map(analytic => ({
			hide: true,
			headerName: analytic.name,
			field: analytic._id,
		}))

		this.hoursCategoriesGridOptions.columnDefs = this.hoursCategoriesGridOptions.columnDefs?.concat(...columns)
	}

	private async loadHoursCategories() {

		const hourCategories = await HoursCategoriesService.getInstance().getAll()

		// Utilisé pour afficher les analytique dans le fichier excel
		const data = hourCategories.map( h => {
			const newData: any = {...h}
			
			// Les analytiques
			if (h.analytics) {
				h.analytics.forEach(analytic => {
					newData[analytic.id] = analytic.percent
				})
			}

			// Le sous-analytique
			if (h.subAnalytics && h.subAnalytics.length > 0) {
				let subAnalytic = SubAnalyticsService.getInstance().getById(h.subAnalytics[0].id)
				newData.subAnalyticName = subAnalytic?.name || '';
			}
			

			return newData
		})

		if (this.hoursCategoriesGridOptions.api) {
			this.hoursCategoriesGridOptions.api.setRowData(data);
		}

	}

	public destructor(): void {

	}



}

export default HumanRessources;

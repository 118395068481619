import { Modal, Form, toaster } from "@autoprog/core-client"
import T_addEditCategory from '../../../../tpl/modals/settings/addEditHourCategory.html'
import HoursCategories, { HourCategory } from '../../services/HoursCategories'
import AnalyticsFormGenerator from '../../AnalyticsFormGenerator'

class AddEditHourCategory extends Modal {

	public constructor(id?: string) {
		super({
			template: T_addEditCategory,
			backdrop: 'static',
			keyboard: false
		})

		this.on('opened', async () => {

			const N_form = this.element.querySelector('form')

			if (N_form) {

				const form = new Form(N_form)

				const N_save = this.element.querySelector('#save-button')

				if (N_save) {

					N_save.addEventListener('click', async () => {

						if (form.checkValidity()) {

							let service = HoursCategories.getInstance();

							let data = form.getData()

							if (id) {

								try {
									await service.update(id, data as HourCategory);
									toaster.success('Catégorie mise à jour');
									this.resolve();
								} catch (e) {
									toaster.error(`Mise à jour de la catégorie`, 'Erreur')
									console.error(e);
								}
							} else {

								try {
									await service.add(data as HourCategory);
									toaster.success('Catégorie ajoutée')
									this.resolve();
								} catch (e) {
									toaster.error(`Ajout de la catégorie`, 'Erreur')
									console.error(e);
								}

							}

						}

					});

				}

				const N_analytics = this.element.querySelector('.analytics') as HTMLElement
				const afg = new AnalyticsFormGenerator(N_analytics)

				afg.on('render', () => {
					form.updateInputs();
				})

				afg.once('render', async () => {

					if (id) {

						try {
							let data = await HoursCategories.getInstance().getById(id);
							form.disableByName('_id');

							if (data) {
								form.setData(data as any);
								data.analytics && afg.setData({ analytics: data.analytics, subAnalytics: [] }); // FIXME: verifier que le code marche toujours
							}
						} catch (e) {
							toaster.error(`Chargement de la categorie`, 'Erreur');
							console.error(e);
						}
					}

				});






			}

		})

	}



}

export default AddEditHourCategory

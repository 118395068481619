import { Controller, global, SocketIO, LoggedUser } from '@autoprog/core-client'

import moment from 'moment'
import 'moment-ferie-fr'

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import Calendar, { ISchedule, TZDate } from 'tui-calendar'
import 'tui-calendar/dist/tui-calendar.css'
import 'tui-date-picker/dist/tui-date-picker.css'
import 'tui-time-picker/dist/tui-time-picker.css'

import 'daterangepicker'
import 'daterangepicker/daterangepicker.css'

import ScheduleCreationPopup from '@libs/ScheduleCreationPopup'
import ScheduleDetailPopup from '@libs/ScheduleDetailPopup'
import MoreOptionsModal from '@libs/modals/calendar/MoreOptionsModal'
import AddCalendarModal from '@libs/modals/calendar/AddCalendarModal'
import ListRecallModal from '@libs/modals/calendar/ListRecallModal'
import fullWidthCellRenderer from '@libs/agGrid/FullWidthCellRenderer'
import UpdateEventRepeat from '@libs/modals/calendar/UpdateEventRepeat'

import T_CalendarMobile from '@tpl/calendarMobile.html'

import '@css/calendar.scss'

import { calendarBdd, EventBdd, repeatBdd } from '@js/types/calendar';

import { Grid, GridOptions } from 'ag-grid-community'

import drpC from '@libs/utils/daterangepickerConfig'
import { french as agGridFrench } from '@libs/utils/agGrid'
import { SitesService, EventsService, CalendarService, CalendarHolidayService } from '@js/libs/services'
import BDD from '@js/libs/BDD'

import _ from "lodash"
import CalendarHoliday from "@js/types/calendar-holiday"

const colorPublicHolidays: string = '#009688';

class CalendarCtrl extends Controller {

	private calendar: Calendar
	private socket: SocketIO
	private data: Array<calendarBdd>
	private dataToList: Array<any>
	private loggedUser: LoggedUser
	private scheduleCreationPopup: ScheduleCreationPopup
	private scheduleDetailPopup: ScheduleDetailPopup

	private calendarList: calendarBdd[] = []

	private currentView: 'calendar' | 'list' = 'calendar'
	private gridOptionList: GridOptions = {}

	private filterCalendarId: string[] = []

	private calendarHolidays: Array<CalendarHoliday> = []

	constructor(private el: HTMLElement, params: object) {
		super(el, params)

		this.dataToList = []

		moment.updateLocale('fr', {
			week: {
				dow: 1
			}
		} as moment.LocaleSpecification)

		moment.locale('fr')

		this.socket = SocketIO.getInstance()

		if (global.IS_MOBILE) {
			el.innerHTML = T_CalendarMobile
		}

		this.loggedUser = LoggedUser.getInstance()

		global.IS_MOBILE && this.el.classList.add('no-title')
		global.IS_MOBILE && document.body.classList.add('is-mobile')

		this.data = []

		this.scheduleCreationPopup = new ScheduleCreationPopup();
		this.scheduleDetailPopup = new ScheduleDetailPopup();

		//pour gerer les evenements qui sont après le changement heure
		Calendar.setTimezoneOffsetCallback((timestamp) => {
			return new Date(timestamp).getTimezoneOffset();
		})

		//creation de l'instance de Tui-calendar
		this.calendar = new Calendar('#fullcalendar', {
			defaultView: 'week',
			taskView: false,
			useCreationPopup: false,
			useDetailPopup: false,
			isReadOnly: !this.loggedUser.hasPermission('CALENDAR.EVENT.ADD'),
			month: {
				daynames: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
				startDayOfWeek: 1,
				narrowWeekend: true
			},
			week: {
				daynames: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
				startDayOfWeek: 1,
				narrowWeekend: true
			},
			template: {
				alldayTitle: () => {
					return !global.IS_MOBILE ? '<div class="w-100 text-right">Journée entière</div>' : '';
				},
				monthGridHeaderExceed: (hiddenSchedules) => {
					if (global.IS_MOBILE) {
						return `<span class="tui-full-calendar-weekday-grid-more-schedules">${hiddenSchedules} +</span>`;
					} else {
						return `<span class="tui-full-calendar-weekday-grid-more-schedules">${hiddenSchedules} autre${hiddenSchedules > 1 ? 's' : ''}</span>`;
					}
				},
				time: (schedule) => {
					return (schedule.title || '');
				},
				//FIXME: obliger de surcharger cette fonction (déprecié) car bug dans la version 1.11.1
				timegridDisplayPrimayTime: (time) => {
					return !global.IS_MOBILE ? (time.hour + ': 00') : time.hour.toString();
				},
				timegridDisplayPrimaryTime: (time) => {
					return !global.IS_MOBILE ? (time.hour + ': 00') : time.hour.toString();
				}
			}
		})


		this.shouldInit()
	}

	private async shouldInit() {

		//récupération des données
		this.syncCalendars().then(calendarList => {

			this.calendarList = calendarList;

			//creation du calendrier des jour férié
			this.calendar.setCalendarColor('ferie', {
				color: '#FFFFFF',
				bgColor: colorPublicHolidays,
				dragBgColor: colorPublicHolidays,
				borderColor: colorPublicHolidays
			});

			//bind des evenements de tui-calendar
			this.calendar.on({
				//update d'un evenement
				'beforeUpdateSchedule': (e: any) => {

					let repeat = this.getRepeatByEvent(e.schedule);

					e.schedule.start = e.start;
					e.schedule.end = e.end;

					//si on deplace un evenement
					if (repeat) {

						let modal = new UpdateEventRepeat();

						modal.open().then((actionRepeat: string) => {

							this.updateEventWithRepeat(e.schedule, repeat, actionRepeat, e.schedule.calendarId);

						}).catch(() => {

						});

					} else {

						//update de l'evenement dans la base de données
						this.updateEvent(e.schedule, repeat, e.schedule.calendarId);

					}

				},
				//creation d'un evenement
				'beforeCreateSchedule': (e: any) => {

					if (global.IS_MOBILE) {

						let modal = new MoreOptionsModal(e, null, this.filterDataByPermission(this.data));

						//ouverture de la modal
						modal.open().then((data: any) => {

							let event = data.event as ISchedule;
							let repeat = data.repeat as repeatBdd;
							let actionRepeat = data.actionRepeat as string;
							let oldCalendarId = (data.oldCalendarId || '') as string;

							//si on a un choix pour le mise a jour des répétitions
							if (actionRepeat) {

								this.updateEventWithRepeat(event, repeat, actionRepeat, oldCalendarId);

							} else {

								if (oldCalendarId.length && event.id) {
									this.updateEvent(event, repeat, oldCalendarId);
								} else {
									this.createEvent(event, repeat);
								}

							}

						}).catch((e) => {
							console.error(e)
						});

					} else {

						//ouverture de la popup
						this.scheduleCreationPopup.open({
							start: e.start.toDate(),
							end: e.end.toDate(),
							isAllDay: e.isAllDay
						}, this.filterDataByPermission(this.data), {
							isUpdate: false,
							guide: e.guide
						});

					}

				},
				//ouverture de la modal de detail
				'clickSchedule': (e: any) => {

					if (e.schedule.calendarId != 'ferie') {

						this.scheduleDetailPopup.open(e.schedule, this.data, {
							guide: { target: e.event.target },
							suppressEdit: false,
							suppressRemove: false,
							locked: e.schedule.raw.locked || e.schedule.isReadOnly
						});

					}

				}
			});

			//evenement de la popup lors de la l'appuie du bouton save et qu'on est en creation
			this.scheduleCreationPopup.on('save', (event: ISchedule) => this.createEvent(event));

			//evenement de la popup lors de la l'appuie du bouton save et qu'on est en modification
			this.scheduleCreationPopup.on('update', (event: ISchedule, oldCalendar: string) => {

				let repeat = this.getRepeatByEvent(event) as repeatBdd;

				if (repeat) {

					let modal = new UpdateEventRepeat();

					modal.open().then((actionRepeat: string) => {
						this.updateEventWithRepeat(event, repeat, actionRepeat, event.calendarId || '');
					})

				} else {
					this.updateEvent(event, repeat, oldCalendar);
				}

			});

			//evenement de la popup lors de la l'appuie du bouton more-option
			this.scheduleCreationPopup.on('moreOptions', (event: ISchedule, _oldCalendar: string) => {

				let repeat = this.getRepeatByEvent(event) as repeatBdd;

				let modal = new MoreOptionsModal(event, repeat, this.filterDataByPermission(this.data));

				//ouverture de la modal
				modal.open().then((data: any) => {

					let event = data.event as ISchedule;
					let repeat = data.repeat as repeatBdd;
					let actionRepeat = data.actionRepeat as string;
					let oldCalendarId = (data.oldCalendarId || '') as string;

					//si on a un choix pour le mise a jour des répétitions
					if (actionRepeat) {

						this.updateEventWithRepeat(event, repeat, actionRepeat, oldCalendarId);

					} else {

						if (oldCalendarId.length && event.id) {
							this.updateEvent(event, repeat, oldCalendarId);
						} else {
							this.createEvent(event, repeat);
						}

					}

				}).catch((e) => {
					console.error(e)
				});
			});

			this.scheduleDetailPopup.on('delete', (event: ISchedule) => {

				const calendarBdd = _.find(this.data, { _id: event.calendarId });
				const repeat = _.find(calendarBdd?.repeat, { idEvent: event.id }) as repeatBdd

				if (repeat || event.raw?.idEventRef) {

					const modal = new UpdateEventRepeat('delete');

					modal.open().then((actionRepeat: string) => {

						this.deleteEventWithRepeat(event, actionRepeat);

					}).catch(() => {

					});

				} else {
					//suppression de l'evenement dans la base de données
					this.deleteEvent(event);
				}

			});

			this.scheduleDetailPopup.on('edit', (event: ISchedule, options: { [key: string]: any }) => {

				let repeat = this.getRepeatByEvent(event);

				if (global.IS_MOBILE) {

					let modal = new MoreOptionsModal(event, repeat, this.filterDataByPermission(this.data));

					//ouverture de la modal
					modal.open().then((data: any) => {

						let event = data.event as ISchedule;
						let repeat = data.repeat as repeatBdd;
						let actionRepeat = data.actionRepeat as string;
						let oldCalendarId = (data.oldCalendarId || '') as string;

						//si on a un choix pour le mise a jour des répétitions
						if (actionRepeat) {

							this.updateEventWithRepeat(event, repeat, actionRepeat, oldCalendarId);

						} else {

							if (oldCalendarId.length && event.id) {
								this.updateEvent(event, repeat, oldCalendarId);
							} else {
								this.createEvent(event, repeat);
							}

						}

					}).catch((e) => {
						console.error(e)
					});

				} else {

					//ouverture de la popup
					this.scheduleCreationPopup.open(
						event,
						this.filterDataByPermission(this.data), {
						isUpdate: true,
						guide: options
					});

				}

			});

			//initialisation du bouton "ajout evenement"
			this.addEvent();
			//initialisation de la checkbox "voir tous"
			this.eventCheckAllCalendar();
			//rendu de la liste des calendrier
			this.renderCalendarList();
			//initialisation du bouton "ajout calendrier"
			this.addCalendar();
			//rendu de la liste des rappels
			this.recall();
			//initialisation des bouton de navigation
			this.initEventNav();
			//initialisation du date picker
			this.initDatePicker();

			this.specificMobile();

			this.socket.on('updateEvent', () => this.updateEventCreateByServer());
		})

		setTimeout(() => {

			let N_siteSelect = this.el.querySelector('#site-select') as HTMLSelectElement;

			N_siteSelect.addEventListener('change', () => {

				global.SITE = N_siteSelect.value;

				let N_itemList = this.el.querySelectorAll('.calendars-item input') as NodeListOf<HTMLInputElement>;

				N_itemList.forEach((el) => {
					el.checked = false;
				});

				this.refreshCalendarVisibility();

				this.renderCalendarList();

				this.renderRecallList();

				this.refreshCalendarVisibility();

			});
			let N_search = this.el.querySelector('#search-calendar') as HTMLSelectElement

			$(N_search).select2({
				placeholder: 'Recherche',
				ajax: {
					url: `api/events/search/select2`,
					data: ({ term }) => {
						return {
							term,
							calendar: this.filterCalendarId
						};
					}
				},
				escapeMarkup: function (markup) {
					return markup;
				},
				templateResult: function (data: any) {

					if (data.event) {

						let start = moment(data.event.start)
						let end = moment(data.event.end);

						if (start.isSame(end, 'day')) {

							return `${data.text} <span class="text-muted text-xs">${moment(data.event.start).format('DD/MM/YYYY')}</span>`;

						} else if (start.isSame(end, 'month')) {

							return `${data.text} <span class="text-muted text-xs">${moment(data.event.start).format('DD')} - ${moment(data.event.end).format('DD/MM/YYYY')}</span>`;

						} else if (start.isSame(end, 'year')) {

							return `${data.text} <span class="text-muted text-xs">${moment(data.event.start).format('DD/MM')} - ${moment(data.event.end).format('DD/MM/YYYY')}</span>`;

						}

						return `${data.text} <span class="text-muted text-xs">${moment(data.event.start).format('DD/MM/YYYY')} - ${moment(data.event.end).format('DD/MM/YYYY')}</span>`;

					} else {
						return data.text;
					}
				},
				templateSelection: function (data) {

					return data.text;
				}
			}).on('change', () => {

				let res = $(N_search).select2('data')[0] as any;

				let N_date = this.el.querySelector('#currentDate') as HTMLDivElement;

				if (N_date && res) {

					let datePicker = $(N_date).data('daterangepicker');

					let date = moment(res.event.start);

					//mise ajour de la selection
					datePicker && datePicker.setStartDate(date.clone().startOf('week'));
					datePicker && datePicker.setEndDate(date.clone().endOf('week'));

					//@ts-ignore
					datePicker.clickApply();

					$(N_search).empty().trigger('change')

				}


			})


		})
	}

	private updateEventCreateByServer() {

		if (this.currentView == 'list') {

			this.renderViewList();

		} else {

			this.setCurrentDate();

		}

		this.renderRecallList();

	}

	private getAllEvents(): Promise<EventBdd[]> {

		// FIXME:Erreur de conception
		// TODO: Trouver un autre moyen
		return EventsService.getInstance().getAll()
	}

	private getRepeatByEvent(event: ISchedule): any {

		const calendar = _.find(this.data, { _id: event.calendarId });

		let repeat: any = {}

		if (event.raw?.idEventRef) {
			repeat = _.find(calendar?.repeat || [], { idEvent: event.raw.idEventRef });
		} else {
			repeat = _.find(calendar?.repeat || [], { idEvent: event.id });
		}

		return repeat;

	}

	private convertDateTuiToDate(date: Date | string | TZDate): Date {

		if (typeof date == "string") {
			date = new Date(parseInt(date));
		} else {
			date = new Date(date.getTime());
		}

		return date;

	}

	private convertDateTuiToString(date: Date | string | TZDate): string | number {

		if (typeof date == "string") {
			date = new Date(parseInt(date));
		} else {
			date = new Date(date.getTime());
		}

		return date.getTime();

	}

	private convertEventbddToEventCalendar(row: EventBdd): ISchedule {

		const calendar = _.find(this.calendarList, { _id: row.calendarId });

		let event: ISchedule = {
			id: row._id,
			start: new Date(row.start || ''),
			end: new Date(row.end || ''),
			title: row.title,
			isAllDay: row.isAllDay,
			calendarId: row.calendarId,
			bgColor: row.bgColor || calendar?.bgColor,
			borderColor: row.borderColor || calendar?.borderColor,
			dragBgColor: row.dragBgColor || calendar?.dragBgColor,
			color: row.color || calendar?.color,
			category: 'time',
			isReadOnly: row.locked,
			raw: {
				_rev: row._rev || null,
				users: row.users || null,
				idEventRef: row.idEventRef || null,
				description: row.description || null,
				locked: !!row.locked,
				validate: !!row.validate,
				bgColor: row.bgColor || null,
				borderColor: row.borderColor || null,
				dragBgColor: row.dragBgColor || null
			}
		};

		if (calendar && calendar.isRecall && row.validate) {
			event.bgColor = 'white';
			event.borderColor = row.borderColor || calendar.borderColor;
			event.dragBgColor = 'white';
			event.color = 'black';
		}

		return event;

	}

	/**
	 * Mise a jour d'un evenement avec répétition
	 */
	private updateEventWithRepeat(event: ISchedule, repeat: repeatBdd, actionRepeat: string, oldCalendarId: string) {

		//recupertation de tous les evenements
		this.getAllEvents().then((events) => {

			const calendarBdd = _.find(this.data, { _id: event.calendarId });

			//si on modifie l'evenement qui est la "source" de la répétition
			if (!event.raw?.idEventRef) {

				//si le choix de modification est "Cet evenement"
				if (actionRepeat == 'onlyOne') {

					//on recupere l'object de la répétition associé a l'evenement que l'on modifie
					let firstRepeat = _.find(calendarBdd?.repeat || [], { idEvent: event.id });

					//on filtre les evenements qui ont était créér par la répétition
					let eventsFilter = _.filter(events, { idEventRef: event.id });

					//on les tri par date
					eventsFilter = _.sortBy(eventsFilter, ['start']);

					//si on a au moins 1 evenement crer automatiquement
					if (eventsFilter.length >= 1) {

						delete eventsFilter[0].idEventRef;

						//on creer (de maniere permanante) le premier evenement automatiquement creer
						this.createEvent(this.convertEventbddToEventCalendar(eventsFilter[0]), _.cloneDeep(firstRepeat)).then(() => {

							event.raw && delete event.raw.idEventRef

							//on met a jour l'evenement modifier (en désactivant la répétition)
							this.updateEvent(event, undefined, oldCalendarId)
						});

					} else {
						//sinon mise a jour de l'événement modifie
						this.updateEvent(event, repeat, oldCalendarId);
					}

				}

				//si on choisi "Cet événement et tous les suivants" ou "Tous les événements"
				//on met a jour levenement modifié (la mise a jour des evenements creer automatiquement se fera coté serveur)
				if (actionRepeat == 'all' || actionRepeat == 'after') {
					this.updateEvent(event, repeat, oldCalendarId);
				}

			} else {

				//on recupere l'evenement "source" de la repetition
				let firstEvent = _.find(events, { _id: event.raw.idEventRef }) as EventBdd

				//on recupere la repetition associé a l'evenement source
				let firstRepeat = _.find(calendarBdd?.repeat || [], { idEvent: firstEvent._id }) as repeatBdd

				firstRepeat = _.cloneDeep(firstRepeat);

				//si on choisi "Cet événement"
				if (actionRepeat == 'onlyOne') {

					//on filtre les evenements qui ont était créér par la répétition
					let eventsFilter = _.filter(events, { idEventRef: firstEvent._id });

					//on les tri par date
					eventsFilter = _.sortBy(eventsFilter, ['start']);

					//on recupere l'index de l'evenement que l'on modifie
					let indexCurrentEvent = _.findIndex(eventsFilter, { _id: event.id });

					//on arrete de la repetion de l'evenement "source" a la date de l'evenement modifier
					(firstRepeat.end as { date: number }).date = parseInt(moment(this.convertDateTuiToDate(event.start || ''), 'x').startOf('day').format('x'));
					if (!!_.get(firstRepeat, 'end.times')) {
						delete (firstRepeat.end as any).times
					}

					//si l'evenement que l'on modifie n'est pas le dernier de la repetition
					if (eventsFilter[indexCurrentEvent + 1]) {

						let tmp = _.cloneDeep(event);

						tmp.raw && delete tmp.raw.idEventRef;

						//mise a jour de l'evenement que l'on modifie sans la reference avec l'evenement
						this.updateEvent(tmp, undefined, undefined).then(() => {

							delete eventsFilter[indexCurrentEvent + 1].idEventRef;

							//mise a jour de l'evenement qui suit l'evenement modifier sans la reference avec l'evenement source
							this.updateEvent(this.convertEventbddToEventCalendar(eventsFilter[indexCurrentEvent + 1]), repeat, undefined).then(() => {

								//mise a jour de l'evement "source"
								this.updateEvent(this.convertEventbddToEventCalendar(firstEvent), firstRepeat, undefined);

							});

						});

					} else {

						const tmp = _.cloneDeep(event);

						tmp.raw && delete tmp.raw.idEventRef;

						//mise a jour de l'evenement que l'on modifie sans la reference avec l'evenement "source"
						this.updateEvent(tmp, undefined, undefined).then(() => {

							//mise a jour de l'evement "source"
							this.updateEvent(this.convertEventbddToEventCalendar(firstEvent), firstRepeat, undefined);

						});

					}
				}

				//si on choisi "Cet événement et tous les suivants"
				if (actionRepeat == 'after') {

					//on arrete de la repetion de l'evenement "source" a la date de l'evenement modifier
					(firstRepeat.end as { date: number }).date = parseInt(moment(this.convertDateTuiToDate(event.start || ''), 'x').startOf('day').format('x'));
					if (!!_.get(firstRepeat, 'end.times')) {
						delete (firstRepeat.end as any).times
					}

					delete event.raw.idEventRef;

					//mise a jour de l'evenement que l'on modifie sans la reference avec l'evenement
					this.updateEvent(event, repeat, undefined).then(() => {

						//mise a jour de l'evement "source"
						this.updateEvent(this.convertEventbddToEventCalendar(firstEvent), firstRepeat, undefined);

					});

				}

				//si on choisi "Tous les événements"
				if (actionRepeat == 'all') {

					//modification des heures de l'evement "source" de la répétition en fonction des heure de l'evenement que l'on modifie
					let start = new Date(firstEvent.start || '');
					let end = new Date(firstEvent.end || '');

					let hoursStart = moment(this.convertDateTuiToDate(event.start || ''), 'x').format('HH');
					let minStart = moment(this.convertDateTuiToDate(event.start || ''), 'x').format('mm');

					let hoursEnd = moment(this.convertDateTuiToDate(event.end || ''), 'x').format('HH');
					let minEnd = moment(this.convertDateTuiToDate(event.end || ''), 'x').format('mm');

					start.setHours(parseInt(hoursStart), parseInt(minStart));
					end.setHours(parseInt(hoursEnd), parseInt(minEnd));

					event.start = start;
					event.end = end;
					event.id = firstEvent._id;

					event.raw = event.raw || {};
					event.raw._rev = firstEvent._rev || null;
					delete event.raw.idEventRef;

					this.updateEvent(event, repeat, oldCalendarId);

				}

			}

		});

	}

	/**
	 * Mise a d'un evenement dans la base de donnée
	 */
	private async updateEvent(data: ISchedule, repeat: repeatBdd | undefined, oldCalendarId: string | undefined): Promise<void> {

		oldCalendarId = _.isUndefined(oldCalendarId) ? data.calendarId : oldCalendarId;

		//si le calendrier a changer lors de la modification
		if (data.calendarId != oldCalendarId) {

			//creation de l'evenement dans le nouveau calendrier
			this.createEvent(_.cloneDeep(data), repeat);

			//suppression de l'evenement dans l'ancien calendrier
			this.deleteEvent(_.merge(data, { calendarId: oldCalendarId }));

		} else if (data.start && data.end) {

			const calendarBdd: calendarBdd = _.find(this.data, { _id: data.calendarId }) as calendarBdd

			calendarBdd.repeat = calendarBdd.repeat || [];

			//recuperation du calendrier et de l'index de l'evenement, pour pouvoir le mettre a jour dans la base
			let indexRepeat = _.findIndex(calendarBdd.repeat, { idEvent: data.id });

			if (repeat && !_.isEmpty(repeat)) {

				repeat = _.cloneDeep(repeat) as repeatBdd;

				repeat.idEvent = data.id;

				if (indexRepeat != -1) {
					calendarBdd.repeat[indexRepeat] = repeat;
				} else {
					calendarBdd.repeat.push(repeat);
				}

				calendarBdd.repeat = _.compact(calendarBdd.repeat);

			} else if (indexRepeat < 0 && repeat) {
				calendarBdd.repeat[indexRepeat] = repeat
				calendarBdd.repeat = _.compact(calendarBdd.repeat)
			}

			const newEvent: EventBdd = Object.assign({
				_id: data.id || BDD.generateID(),
				start: this.convertDateTuiToString(data.start),
				end: this.convertDateTuiToString(data.end),
				title: data.title,
				isAllDay: data.isAllDay,
				calendarId: calendarBdd._id
			}, data.raw) as EventBdd


			await CalendarService.getInstance().update(calendarBdd)

			const addOrUpdateEvent = data.id ? EventsService.getInstance().create : EventsService.getInstance().update
			await addOrUpdateEvent(newEvent)

			//mise a jour de l'affichage (tui-calendar)
			this.calendar.updateSchedule(data.id || '', data.calendarId || '', data, true)

			this.socket.emit('event.update', data.id)

			//si le calendrier est un calendrier de rappel, mise a jour de la liste des rappels
			calendarBdd.isRecall && this.renderRecallList()
		}

	}

	/**
	* Suppressiond'un evenement avec répétition
	*/
	private deleteEventWithRepeat(event: ISchedule, actionRepeat: string) {

		//recupertation de tous les evenements
		this.getAllEvents().then((events) => {

			const calendarBdd = _.find(this.data, { _id: event.calendarId });

			//si on modifie l'evenement qui est la "source" de la répétition
			if (!event.raw?.idEventRef) {

				//si le choix de modification est "Cet evenement"
				if (actionRepeat == 'onlyOne') {

					//on recupere l'object de la répétition associé a l'evenement que l'on modifie
					let firstRepeat = _.find(calendarBdd?.repeat || [], { idEvent: event.id });

					//on filtre les evenements qui ont était créér par la répétition
					let eventsFilter = _.filter(events, { idEventRef: event.id });

					//on les tri par date
					eventsFilter = _.sortBy(eventsFilter, ['start']);

					//si on a au moins 1 evenement creer automatiquement
					if (eventsFilter.length >= 1) {

						//on creer (de maniere permanante) le premier evenement automatiquement creer
						this.createEvent(this.convertEventbddToEventCalendar(eventsFilter[0]), firstRepeat).then(() => {

							//on met a jour l'evenement modifier (en désactivant la répétition)
							this.deleteEvent(event);

						});

					} else {
						//sinon mise a jour de l'événement modifie
						this.deleteEvent(event)
					}

				}

				//si on choisi "Cet événement et tous les suivants" ou "Tous les événements"
				//on met a jour levenement modifié (la mise a jour des evenements creer automatiquement se fera coté serveur)
				if (actionRepeat == 'all' || actionRepeat == 'after') {
					this.deleteEvent(event);
				}

			} else {

				//on recupere l'evenement "source" de la repetition
				let firstEvent = _.find(events, { _id: event.raw.idEventRef }) as EventBdd

				//on recupere la repetition associé a l'evenement source
				let firstRepeat = _.find(calendarBdd?.repeat || [], { idEvent: firstEvent._id }) as repeatBdd

				firstRepeat = _.cloneDeep(firstRepeat)

				//si on choisi "Cet événement"
				if (actionRepeat == 'onlyOne') {

					//on filtre les evenements qui ont était créér par la répétition
					let eventsFilter = _.filter(events, { idEventRef: firstEvent._id });

					//on les tri par date
					eventsFilter = _.sortBy(eventsFilter, ['start']);

					//on recupere l'index de l'evenement que l'on modifie
					let indexCurrentEvent = _.findIndex(eventsFilter, { _id: event.id });

					let newRepeat = _.cloneDeep(firstRepeat);

					//on arrete de la repetion de l'evenement "source" a la date de l'evenement modifier
					(firstRepeat.end as { date: number }).date = parseInt(moment(this.convertDateTuiToDate(event.start || ''), 'x').startOf('day').format('x'));
					if (!!_.get(firstRepeat, 'end.times')) {
						delete (firstRepeat.end as any).times
					}


					//si l'evenement que l'on modifie n'est pas le dernier de la repetition
					if (eventsFilter[indexCurrentEvent + 1]) {

						//mise a jour de l'evenement source avec sa répétition modifié
						this.updateEvent(this.convertEventbddToEventCalendar(firstEvent), firstRepeat, undefined).then(() => {

							//creation de l'evenement (créé automatiquement par le serveur) qui suit l'evenement modifier
							this.updateEvent(this.convertEventbddToEventCalendar(eventsFilter[indexCurrentEvent + 1]), newRepeat, undefined);

						});

					} else {

						//mise a jour de l'evenement source avec sa répétition modifié
						this.updateEvent(this.convertEventbddToEventCalendar(firstEvent), firstRepeat, undefined);

					}
				}

				//si on choisi "Cet événement et tous les suivants"
				if (actionRepeat == 'after') {

					//on arrete de la repetion de l'evenement "source" a la date de l'evenement modifier
					(firstRepeat.end as { date: number }).date = parseInt(moment(this.convertDateTuiToDate(event.start || ''), 'x').startOf('day').format('x'));
					if (!!_.get(firstRepeat, 'end.times')) {
						delete (firstRepeat.end as any).times
					}

					//mise a jour de l'evement "source"
					this.updateEvent(this.convertEventbddToEventCalendar(firstEvent), firstRepeat, undefined);

				}

				//si on choisi "Tous les événements"
				if (actionRepeat == 'all') {

					this.deleteEvent(this.convertEventbddToEventCalendar(firstEvent));

				}

			}

		});

	}

	/**
	 * suppresssion d'un evenement dans la base de donnée
	 */
	private async deleteEvent(event: ISchedule): Promise<void> {

		//recuperation du calendrier et de l'index de l'evenement, pour pouvoir le mettre a jour dans la base
		const calendarBdd = _.find(this.data, { _id: event.calendarId })

		let indexRepeat = _.findIndex(calendarBdd?.repeat, { idEvent: event.id })

		//mise a jour de l'affichage (tui-calendar)
		this.calendar.deleteSchedule(event.id || '', event.calendarId || '')

		event.id && await EventsService.getInstance().delete(event.id)

		if (!event.raw?.idEventRef && indexRepeat < 0 && calendarBdd) {
			calendarBdd.repeat.splice(indexRepeat, 1)
		}

		calendarBdd && await CalendarService.getInstance().update(calendarBdd)
		this.socket.emit('event.delete', event.id)

		//si le calendrier est un calendrier de rappel, mise a jour de la liste des rappeks
		calendarBdd && calendarBdd.isRecall && this.renderRecallList()
	}

	/**
	 * creation d'un evenemzent dans la base de données
	 */
	private async createEvent(data: ISchedule, repeat?: repeatBdd): Promise<void> {

		//verification si on a 2 dates
		if (data.start && data.end) {

			//recuperation du calendrier selectionner
			const calendarBdd = _.find(this.data, { _id: data.calendarId }) as calendarBdd

			data.raw = data.raw || {};

			const newEvent: EventBdd = Object.assign({
				_id: BDD.generateID(),
				start: this.convertDateTuiToString(data.start),
				end: this.convertDateTuiToString(data.end),
				title: data.title,
				isAllDay: data.isAllDay,
				calendarId: data.calendarId
			}, data.raw) as EventBdd

			delete newEvent._rev;

			data.id = newEvent._id;

			calendarBdd.repeat = calendarBdd.repeat || [];

			if (repeat) {
				repeat = _.cloneDeep(repeat) as repeatBdd;

				repeat.idEvent = newEvent._id;
				calendarBdd.repeat.push(repeat);
			}

			await CalendarService.getInstance().update(calendarBdd)

			await EventsService.getInstance().create(newEvent)

			//mise a jour de l'affichage (tui-calendar)
			this.calendar.createSchedules([data])

			//mise a jour de l'affichage des calendriers pour masquer le nouvel evenement si son calendrier est masqué
			this.refreshCalendarVisibility()

			this.socket.emit('event.create', newEvent._id)

			//si le calendrier est un calendrier de rappel, mise a jour de la liste des rappels
			calendarBdd.isRecall && this.renderRecallList()
		}

	}


	private async getHolidaysBetween(startDate: number, endDate: number): Promise<Array<CalendarHoliday>> {
		return CalendarHolidayService.getInstance().getByDates(startDate, endDate)
	}

	private isHoliday(date: number): boolean {
		return this.calendarHolidays.some(calendarHoliday => moment(calendarHoliday.day).startOf('day').diff(moment(date).startOf('day'), 'days') === 0)
	}

	/**
	 * recuperation des données de la base entre 2 dates
	 */
	private async getDataBetweenDate(date1: Date, date2: Date): Promise<void> {

		let calendars = this.filterDataByPermission(this.data);

		const events = await EventsService.getInstance().getBetweenDate(date1.getTime(), date2.getTime(), {
			filterCalendars: calendars.map(calendar => calendar._id)
		})

		for (let calendar of this.data) {
			calendar.events = [];
		}

		this.calendar.clear(true);

		for (let row of events) {

			let calendar = _.find(this.data, { _id: row.calendarId });

			if (calendar) {

				let event: ISchedule = this.convertEventbddToEventCalendar(row);

				if (calendar.owner != this.loggedUser.get('ID')) {

					if (!this.loggedUser.hasPermission('CALENDAR.ALL.EDIT')) {
						event.isReadOnly = true;
					}

					if (!this.loggedUser.hasPermission('CALENDAR.EVENT.VIEW_ALL')) {
						event.title = '---';
						event.isReadOnly = true;
					}

					if (calendar.locked) {
						event.isReadOnly = true;
					}

				}

				//ajout d'un evenements recupérer dans la base de données
				(this.currentView == 'calendar') && this.calendar.createSchedules([event]);
			}
		}

		let publicHolidays: ISchedule[] = [];

		let date1Moment = moment(date1);
		let date2Moment = moment(date2).endOf('days');

		while (date1Moment.isBefore(date2Moment)) {

			if (this.isHoliday(+date1Moment.format('x'))) {
				const holidayInformation = this.calendarHolidays.find(calendarHoliday => moment(calendarHoliday.day).startOf('day').diff(moment(+date1Moment.format('x')).startOf('day'), 'days') === 0)
				publicHolidays.push({
					id: holidayInformation!.name,
					calendarId: 'ferie',
					category: 'time',
					title: holidayInformation!.name,
					start: new Date(parseInt(date1Moment.format('x') || '')),
					end: new Date(parseInt(date1Moment.format('x') || '')),
					isAllDay: true,
					isReadOnly: true
				})
			}

			date1Moment.add(1, 'day');

		}


		if (this.currentView == 'calendar') {
			this.calendar.createSchedules(publicHolidays);
			this.refreshCalendarVisibility();
		}

	}

	/**
	 * Récupération des calendriers de données dans la BDD
	 */
	private async getAllCalendars(): Promise<calendarBdd[]> {
		return CalendarService.getInstance().getAll()
	}

	/**
	 * Permet de synchroniser les calendriers
	 */
	private async syncCalendars(): Promise<Array<calendarBdd>> {
		let calendars = await this.getAllCalendars()

		for (let calendar of calendars) {
			const oldCalendar = this.data.find(calendarOfData => calendarOfData._id === calendar._id)
			calendar.events = oldCalendar && oldCalendar.events ? oldCalendar.events : []
		}

		this.data = calendars
		return calendars
	}

	/**
	 * gestion de l'evenement du bouton "ajout evenement"
	 */
	private addEvent() {

		const N_add = this.el.querySelector('#add-event')

		if (N_add) {

			N_add.addEventListener('click', () => {

				//si on a des calendrier creer
				if (this.data[0]) {

					//ouverture de la modal pour la creer l'evenement
					const modal = new MoreOptionsModal({
						id: BDD.generateID(),
						start: moment().toDate(),
						end: moment().add('minutes', 15).toDate(),
						calendarId: this.data[0]._id
					}, null, this.filterDataByPermission(this.data));

					modal.open().then((data: any) => {

						const event = data.event as ISchedule;
						const repeat = data.repeat as repeatBdd;

						//ajout de l evenement dans la base
						this.createEvent(event, repeat);

					})

				}
				else {
					toastr.error('Veuillez créer un calendrier');
				}

			})

		}

	}

	/**
	 * gestion de l'evenement d'un groupe sur la liste des calendriers
	 */
	private eventGroupListCalendar(el: HTMLElement) {

		let N_chevron = el.querySelector('#chevron');
		let N_checkbox = el.querySelector('#select-group') as HTMLInputElement;

		let N_group = (el.parentNode as HTMLElement).querySelector(`[data-id-group="${el.dataset.refGroup}"]`);

		if (N_chevron) {

			N_chevron.addEventListener('click', () => {

				if (N_group && N_chevron) {
					N_group.classList.toggle('d-none');

					if (N_group.classList.contains('d-none')) {
						N_chevron.innerHTML = '<i class="icon icon-solid-chevron-right d-inline-block"></i>';
					} else {
						N_chevron.innerHTML = '<i class="icon icon-solid-chevron-down d-inline-block"></i>';
					}
				}

			});

		}

		if (N_checkbox) {

			N_checkbox.addEventListener('change', () => {

				if (N_group) {

					let N_checkBoxList = N_group.querySelectorAll('[data-id],[data-ref-group] input') as NodeListOf<HTMLInputElement>;

					N_checkBoxList.forEach((item) => {

						item.checked = N_checkbox.checked;

						let calendar = _.find(this.calendarList, { _id: item.dataset.id });

						let N_span = (item.parentNode as HTMLElement).querySelector('span');

						if (N_span) {

							if (item.checked) {
								N_span.style.backgroundColor = calendar ? calendar.bgColor : colorPublicHolidays;
							} else {
								N_span.style.backgroundColor = 'transparent';
							}

						}

					});

					//mise a jour des l'affichage du calendrier
					this.refreshCalendarVisibility();

				}

			});

		}

	}

	/**
	 * gestion de l'evenement de la checkbox des calendriers
	 */
	private eventListCalendar(el: HTMLElement, calendar: calendarBdd) {

		el.addEventListener('click', () => {

			let N_checkbox = el.querySelector('input');

			if (N_checkbox) {

				N_checkbox.checked = !N_checkbox.checked;

				let N_sameCheckbox = this.el.querySelectorAll(`[data-id="${N_checkbox.dataset.id || ''}"]`) as NodeListOf<HTMLInputElement>;

				N_sameCheckbox.forEach((item) => {

					let parent = item.parentNode as HTMLElement;

					let N_span = parent.querySelector('#color') as HTMLSpanElement;
					let N_sameCheckbox = parent.querySelector('input') as HTMLInputElement;

					if (N_checkbox && N_span && N_sameCheckbox) {

						N_sameCheckbox.checked = N_checkbox.checked;

						if (N_sameCheckbox.checked) {
							N_span.style.backgroundColor = calendar ? calendar.bgColor : colorPublicHolidays;
						} else {
							N_span.style.backgroundColor = 'transparent';
						}

					}

				});

				let N_liGroup = this.el.querySelector(`li[data-ref-group="${el.dataset.group || ''}"]`);
				let N_ulGroup = this.el.querySelector(`ul[data-id-group="${el.dataset.group || ''}"]`);

				let N_liSite = this.el.querySelector(`li[data-ref-group="${el.dataset.site || ''}"]`);
				let N_ulSite = this.el.querySelector(`ul[data-id-group="${el.dataset.site || ''}"]`);

				if (N_liGroup && N_ulGroup) {

					let N_listCheckboxGroup = N_ulGroup.querySelectorAll('[data-id]') as NodeListOf<HTMLInputElement>;
					let N_checkboxGroup = N_liGroup.querySelector('#select-group') as HTMLInputElement;

					let numberSelected = 0;

					N_listCheckboxGroup.forEach((item) => {
						if (item.checked) {
							numberSelected++;
						}
					});

					if (numberSelected == N_listCheckboxGroup.length) {
						N_checkboxGroup.checked = true;
					} else {
						N_checkboxGroup.checked = false;
					}

				}

				if (N_ulSite && N_liSite) {

					let N_listCheckboxSite = N_ulSite.querySelectorAll('[data-id]') as NodeListOf<HTMLInputElement>;
					let N_checkboxSite = N_liSite.querySelector('#select-group') as HTMLInputElement;

					let numberSelected = 0;

					N_listCheckboxSite.forEach((item) => {
						if (item.checked) {
							numberSelected++;
						}
					});

					if (numberSelected == N_listCheckboxSite.length) {
						N_checkboxSite.checked = true;
					} else {
						N_checkboxSite.checked = false;
					}
				}

				//mise a jour des l'affichage du calendrier
				this.refreshCalendarVisibility();

			}

		});

		let N_delete = el.querySelector('#delete');

		if (N_delete) {

			N_delete.addEventListener('click', async (e: Event) => {

				e.stopPropagation();

				calendar.deleted = true;

				// delete calendar.events;
				Reflect.deleteProperty(calendar, 'events')

				await CalendarService.getInstance().delete(calendar._id)

				let index = _.findIndex(this.data, { _id: calendar._id });

				this.data.splice(index, 1);

				this.calendar.toggleSchedules(calendar._id, true, true);

				if (this.currentView == 'list') {
					this.renderViewList()
				}

				this.renderCalendarList()
			});

		}

		let N_info = el.querySelector('#info');

		if (N_info) {

			N_info.addEventListener('click', (e: Event) => {

				e.stopPropagation();

				let type = 'colorsOnly';

				if (calendar.owner == this.loggedUser.get('ID') || this.loggedUser.hasPermission('CALENDAR.ALL.EDIT')) {

					type = 'edit';

				} else if ('locked' in calendar && calendar.locked == 'colorsOnly') {

					type = 'colorsOnly';

				}

				let modal = new AddCalendarModal(calendar, type);

				modal.open().then(() => {

					//mise ajour de la liste de calenderier chargé
					this.syncCalendars().then((calendarList) => {
						//mise ajour visuel de la liste des calendriers
						this.renderCalendarList();

						if (this.currentView == 'list') {

							this.renderViewList();

						}

						for (let calendar of calendarList) {

							//applique les couleur pour les calendriers
							this.calendar.setCalendarColor(calendar._id, {
								color: calendar.color,
								bgColor: calendar.bgColor,
								dragBgColor: calendar.dragBgColor,
								borderColor: calendar.borderColor,
							});

						}

						this.renderRecallList();

					});

				}).catch(() => {

				});

			});

		}


	}

	/**
	 * gestion de l'enement de la checkbox "voir tous" de la liste de la liste des calendriers
	 */
	private eventCheckAllCalendar() {

		let N_item = this.el.querySelector('.calendars-item-all');

		if (N_item) {

			let N_checkbox = N_item.querySelector('input');

			N_item.addEventListener('change', () => {

				if (N_checkbox) {

					let N_itemList = this.el.querySelectorAll('.calendars-item, [data-ref-group]');

					for (let N_item of N_itemList) {

						let N_input = N_item.querySelector('input');
						let N_span = N_item.querySelector('span');

						let N_checkBoxgroup = N_item.querySelector('#select-group') as HTMLInputElement;

						if (N_checkBoxgroup) {
							N_checkBoxgroup.checked = N_checkbox.checked;
						} else if (N_input && N_span) {

							let item = _.find(this.data, { _id: N_input.dataset.id });

							N_input.checked = N_checkbox.checked;

							if (item) {
								N_span.style.backgroundColor = N_checkbox.checked ? (item.bgColor || colorPublicHolidays) : 'transparent';
							} else {
								N_span.style.backgroundColor = N_checkbox.checked ? colorPublicHolidays : 'transparent';
							}
						}

					}

					//mise a jour des l'affichage du calendrier
					this.refreshCalendarVisibility();

				}

			});

		}

	}

	/**
	 * gestion de l'affichage des calendriers
	 */
	private refreshCalendarVisibility() {

		//si le calendrier (tui-calendar) est affiché
		if (this.currentView == 'calendar') {

			let flagAll = true;

			for (let calendar of this.calendarList) {

				let N_input = this.el.querySelector(`[data-id="${calendar._id}"]`) as HTMLInputElement

				if (!N_input) {

					this.calendar.toggleSchedules(calendar._id || '', true, true);

				} else {

					//mise a jour du calendrier
					this.calendar.toggleSchedules(calendar._id || '', !N_input.checked, true);

					if (!N_input.checked) {
						flagAll = false;
					}

				}

			}

			let N_viewAll = this.el.querySelector('.calendars-item-all input') as HTMLInputElement;

			//mise a jour de la checkbox "voir tous"
			if (N_viewAll) {
				N_viewAll.checked = flagAll;
			}

		}

		//si la liste (ag-grid) est affiché
		if (this.currentView == 'list') {
			this.renderViewList();
		}

	}

	private filterDataByPermission(data: calendarBdd[]): calendarBdd[] {

		let result = _.filter(data, (item: any) => {

			if (item.owner == this.loggedUser.get('ID')) {
				return true;
			}

			let seeSite = false;

			for (let site of item.sites) {
				if (global.ALLOW_SITES && global.ALLOW_SITES.includes(site)) {
					seeSite = true;
					break;
				}
			}

			if (seeSite) {

				if (this.loggedUser.hasPermission('CALENDAR.ALL.VIEW_ALL')) {
					return true;
				}

				if ('locked' in item) {
					return true;
				}

			}

			return false;

		});

		return result;

	}

	/**
	 * rendu et gestion de la liste des calendriers
	 */
	private renderCalendarList() {

		let N_list = this.el.querySelector('#calendarList');

		let data = this.filterDataByPermission(_.sortBy(this.data, 'category'));

		if (N_list) {

			let tmp: { [key: string]: any } = {};

			for (let calendar of data) {

				calendar.category = calendar.category || '';
				calendar.sites = calendar.sites || [];

				for (let site of calendar.sites) {

					if (site == global.SITE || site == '') {
						tmp[site] = tmp[site] || {}
						tmp[site][calendar.category] = tmp[site][calendar.category] || [];
						tmp[site][calendar.category].push(calendar);
						this.filterCalendarId.push(calendar._id);
					}

				}

			}

			this.filterCalendarId = _.uniq(this.filterCalendarId);

			let html = '';

			html += `<li class="calendars-item">
                <input type="checkbox" class="tui-full-calendar-checkbox-round" data-id="ferie" checked>
                <span  style="border-color: #009688; background-color: #009688;" id="color"></span>
                <span>Jour férié</span>
            `;

			for (let site in tmp) {

				if (site) {
					html += `<li data-ref-group="${site}">
                        <span class="mr-1" id="chevron">
                            <i class="icon icon-solid-chevron-down d-inline-block"></i>
                        </span>
                        <label>
                            <input class="tui-full-calendar-checkbox-square" id="select-group" type="checkbox" checked>
                            <span></span>
                        </label>
                        <span id="site-name">${site}</span>
                    </li>`;

					html += `<ul data-id-group="${site}">`;
				}

				for (let category in tmp[site]) {

					if (category) {

						html += `<li data-ref-group="${category}">
                            <span class="mr-1" id="chevron"><i class="icon icon-solid-chevron-down d-inline-block"></i></span>
                            <label>
                                <input class="tui-full-calendar-checkbox-square" id="select-group" type="checkbox" checked>
                                <span></span>
                            </label>
                            ${category}
                        </li>`;

						html += `<ul data-id-group="${category}">`;

					}

					for (let calendar of tmp[site][category]) {

						html += `<li class="calendars-item" data-group='${category}' data-site='${site}'>
                            <input type="checkbox" class="tui-full-calendar-checkbox-round" data-id="${calendar._id}" checked>
                            <span  style="border-color: ${calendar.borderColor}; background-color: ${calendar.bgColor};" id="color"></span>
                            <span> ${calendar.name}</span>
                        `;

						if (!global.IS_MOBILE) {

							html += `<div class="button-action">`;

							let editPerm = this.loggedUser.hasPermission('CALENDAR.ALL.EDIT');
							let deletePerm = this.loggedUser.hasPermission('CALENDAR.ALL.DELETE');

							//si on est propriétaire du calendrier
							if (calendar.owner == this.loggedUser.get('ID')) {

								html += `
                                    <i class="icon icon-edit mr-1 text-info" id="info"></i>
                                    <i class="icon icon-solid-trash-alt text-danger" confirmation="" id="delete"></i>
                                `;

								//si on peut modifier ou supprimer tous les calendriers ou que le calendrier est vérouillé
							} else if (editPerm || deletePerm || 'locked' in calendar) {

								if (editPerm && !calendar.locked) {
									html += `
                                        <i class="icon icon-edit mr-1 text-info" id="info"></i>
                                    `;
								}

								if (deletePerm && !calendar.locked) {
									html += `
                                        <i class="icon icon-solid-trash-alt text-danger" confirmation="" id="delete"></i>
                                    `;
								}

							}

							html += `</div>`;

						}

						html += `</li>`;

					}

					if (category) {
						html += `</ul>`;
					}

				}

				if (site) {
					html += `</ul>`;
				}

			}

			N_list.innerHTML = html;

			let N_site_name = N_list.querySelectorAll('#site-name');

			N_site_name.forEach((item) => {

				let parent = item.parentNode as HTMLElement;
				SitesService.getInstance().getByID(parent.dataset.refGroup || '').then(site => {
					item.innerHTML = site.name
				})

			})

			let N_checkBox = N_list.querySelectorAll('.calendars-item');

			for (let el of N_checkBox) {

				const input = el.querySelector('input');

				if (input) {
					let calendar = _.find(this.data, { _id: input.dataset.id });
					calendar && this.eventListCalendar(el as HTMLElement, calendar);
				}

			}

			let N_title = N_list.querySelectorAll('[data-ref-group]');

			//gestion des evenement pour les groupe
			for (let N_li of N_title) {
				this.eventGroupListCalendar(N_li as HTMLElement);
			}

		}

	}

	/**
	 * gestion de l'venement du bouton "ajout d'un calandrier"
	 */
	private addCalendar() {

		let N_add = this.el.querySelector('#add-calendar');

		if (N_add) {

			N_add.addEventListener('click', () => {

				//creation de la modal d'ajout d'un calendrier
				let modal = new AddCalendarModal({}, 'add');

				modal.open().then(() => {

					//mise ajour de la liste de calenderier chargé
					this.syncCalendars().then((calendarList) => {
						//mise a jour visuel de la liste des calendriers
						this.renderCalendarList();

						for (let calendar of calendarList) {

							//applique les couleur pour les calendriers
							this.calendar.setCalendarColor(calendar._id, {
								color: calendar.color,
								bgColor: calendar.bgColor,
								dragBgColor: calendar.dragBgColor,
								borderColor: calendar.borderColor,
							});

						}

					});

				}).catch(() => {

				});

			});

		}

	}

	/**
	 * rendu de la liste des rappelles
	 */
	private async renderRecallList(): Promise<void> {

		this.filterDataByPermission(this.data);

		// FIXME: trouver une meilleur alternative car ce n'est pas optimal
		let events = await EventsService.getInstance().getRecallEvents(+moment().startOf('day').format('x'), {
			includedSites: [global.SITE],
			limit: 20
		})

		let N_list = this.el.querySelector('#recallList');
		let groupData: { [key: string]: any[]; } = {};

		if (N_list) {

			N_list.innerHTML = '';

			if (events.length) {

				events.forEach(event => {
					let date = moment(event.start).format('YYYY_MM_DD')
					groupData[date] = groupData[date] || [];
					groupData[date].push(event);
				});

				let keys = Object.keys(groupData).sort();

				//creation des elements pour la liste
				for (let key of keys) {

					let group = groupData[key];

					let li = document.createElement('li');
					li.innerHTML = moment(key, 'YYYY_MM_DD').format('ddd DD MMM YYYY');
					li.id = 'dateRecall';
					N_list.appendChild(li);

					for (let event of group) {

						const calendar = _.find(this.data, { _id: event.calendarId });

						if (calendar && calendar.isRecall && (!calendar.sites || calendar.sites.includes(global.SITE))) {

							let li = document.createElement('li');
							li.innerHTML = `
								<span class="tui-full-calendar-icon tui-full-calendar-calendar-dot" style="background-color: ${calendar.bgColor}"></span>
								<span> ${event.title}</span>
							`;

							if (event.validate) {
								li.innerHTML += '<i class="float-right mr-2 icon icon-check-circle"></i>';
							} else {
								li.innerHTML += `<i class="float-right mr-2 icon icon-circle" id="validate" data-id="${event._id}" confirmation></i>`;
							}

							li.id = 'event';
							N_list.appendChild(li);

						}

					}

				}

			} else {

				N_list.innerHTML = `<li class="text-muted font-italic text-center pt-2">
					Aucun Rappel
				</li>`;

			}

			let N_validate = N_list.querySelectorAll('#validate'); // FIXME: #validate shouldnt be a ID

			N_validate.forEach((item) => {

				item.addEventListener('click', async () => {
					await this.updateRecallValidate((item as HTMLElement).dataset.id || '');
				});

			});

		}

	}

	private async updateRecallValidate(id: string) {
		await EventsService.getInstance().toggleValidate(id)
		this.setCurrentDate()
		this.renderRecallList()
	}

	/**
	 * rendu de la liste des rappels et gestion de l'evenement du bouton "voir plus" de celle-ci
	 */
	private recall() {

		this.renderRecallList();

		let N_button = this.el.querySelector('#view-recall');

		if (N_button) {

			N_button.addEventListener('click', () => {
				let calendars = this.filterDataByPermission(this.data);
				let modal = new ListRecallModal(calendars);
				modal.open()
			})

		}

	}

	/**
	 * gestion du date picker
	 */
	private initDatePicker() {

		let N_date = this.el.querySelector('#currentDate') as HTMLDivElement;

		if (!global.IS_MOBILE) {

			//initialisation du date picker
			$(N_date).daterangepicker(drpC({
				singleDatePicker: true,
				autoUpdateInput: false
			}));

			//gestion de la selection du date
			$(N_date).on('apply.daterangepicker', (_ev, picker) => {

				let data = this.formatDate(picker.startDate.clone().startOf('week'), picker.startDate.clone().endOf('week'));

				//mise a jour de l'affichage
				N_date.innerHTML = data.value;

				let datePicker = $(N_date).data('daterangepicker');

				//mise ajour de la selection
				datePicker && datePicker.setStartDate(data.start.toDate());
				datePicker && datePicker.setEndDate(data.end.toDate());

				N_date.dataset.startdate = data.start.format('x');
				N_date.dataset.enddate = data.end.format('x');

				//mise a jour de laffichage du calendrier (tui-calendar)
				this.calendar.setDate(picker.startDate.toDate());

				//mise a jour de la value afficher
				this.setCurrentDate();

				if (this.currentView == 'list') {
					this.renderViewList()
				}
			});

		}

		//mise a jour de la value afficher
		this.setCurrentDate();

	}

	/**
	 * mise en forme du l'affichage de la date
	 */
	private formatDate(start: moment.Moment, end: moment.Moment) {

		let viewName = (this.currentView == 'list') ? 'list' : this.calendar.getViewName();

		if (this.currentView == 'list' && global.IS_MOBILE) {
			viewName = 'month';
		}

		let result = {
			value: '',
			start: start.clone(),
			end: end.clone()
		};

		if (viewName == 'day') {//ex : "23 octobre 2019"

			result.value = start.format('DD MMMM YYYY');

			result.start = result.start.startOf('day');
			result.end = result.end.endOf('day');

		} else if (viewName == 'month') {//ex : "Octobre 2019"


			if (result.start.clone().add(2, 'month').isSame(result.end, 'month')) {//si y a 3 mois d'afficher

				result.start = result.start.add(1, 'month').startOf('month');
				result.end = result.end.subtract(1, 'month').endOf('month');

			} else if (result.start.isSame(result.end, 'month')) {//si 1 mois complet est affiché

				result.start = result.start.startOf('month');
				result.end = result.end.endOf('month');

			} else {//si 2 mois sont affichés

				/*if (result.start.clone().startOf('month').isSame(result.start, 'day')) {

						result.start = result.start.startOf('month');
						result.end = result.start.clone().endOf('month');

				}

				if (result.end.clone().endOf('month').isSame(result.end, 'day')) {

						result.start = result.end.clone().startOf('month');
						result.end = result.end.endOf('month');

				}*/

			}

			result.value = result.start.format('MMMM YYYY');

		} else {

			let monthStart = start.format('MMMM');
			let yearStart = start.format('YYYY');

			let monthEnd = end.format('MMMM');
			let yearEnd = end.format('YYYY');

			result.start = result.start.startOf('week');
			result.end = result.end.endOf('week');

			if (yearStart != yearEnd) {//si les années sont différentes, on affiche "30 Dec. 2019 - 6 Janv. 20120"

				result.value = start.format('DD MMM YYYY');
				result.value += ' - ';
				result.value += end.format('DD MMM YYYY');

			} else if (monthStart != monthEnd) {//si les mois sont différents, on affiche "30 Oct. - 6 Nov. 2019"

				result.value = start.format('DD MMM');
				result.value += ' - ';
				result.value += end.format('DD MMM YYYY');

			} else {//sinon, on affiche "10 - 17 Janvier 2019"

				result.value = start.format('DD') + '-' + end.format('DD MMMM YYYY');

			}
		}

		return result;

	}

	/**
	 * mise a jour de l'affichage de la date et la selection du date picker
	 */
	private async setCurrentDate() {

		const N_date = this.el.querySelector('#currentDate') as HTMLDivElement;

		(!global.IS_MOBILE) && (N_date.style.pointerEvents = 'all')

		const datePicker = $(N_date).data('daterangepicker');

		if (N_date && (datePicker || global.IS_MOBILE)) {

			const start = moment(this.calendar.getDateRangeStart().getTime())
			const end = moment(this.calendar.getDateRangeEnd().getTime())

			const data = this.formatDate(start, end)

			N_date.innerHTML = data.value
			N_date.dataset.startdate = start.format('x')
			N_date.dataset.enddate = end.format('x')

			if (!global.IS_MOBILE && datePicker) {
				datePicker.setStartDate(data.start)
				datePicker.setEndDate(data.end)
			}

			this.getDataBetweenDate(data.start.toDate(), data.end.toDate())
			this.calendarHolidays = await this.getHolidaysBetween(+data.start.format('x'), +data.end.format('x'))
		}

	}

	/**
	 * filtre et mise en forme pour l'ag-grid du mode liste
	 */
	private async getDataToViewList(start: number, end: number): Promise<any> {

		this.dataToList = this.dataToList || [];

		let calendars = this.filterDataByPermission(this.data)
		let date = this.dataToList.length ? _.last(this.dataToList).start : Date.now()

		const data = await EventsService.getInstance().getSince(date, {
			startIndex: start,
			endIndex: end,
			filterCalendars: calendars.map(calendar => calendar._id)
		})

		let rowData: any[] = []
		let tmp: { [key: string]: any[] } = {}

		let now = moment().startOf('day')

		//filtre des données et groupage par jour
		for (let event of data) {

			let checkboxCalendar = this.el.querySelector(`.tui-full-calendar-checkbox-round[data-id="${event.calendarId}"]`) as HTMLInputElement;

			//si le calendrier est affiché
			if (checkboxCalendar && checkboxCalendar.checked) {

				let startDate = moment(event.start);
				let endDate = moment(event.end);

				while (startDate.isBefore(endDate)) {

					let date = startDate.format('YYYY_MM_DD');

					if (now.isSameOrBefore(startDate)) {

						tmp[date] = tmp[date] || [];

						tmp[date].push({
							_id: event._id,
							color: _.find(this.data, { _id: event.calendarId })!.bgColor,
							start: event.start,
							end: event.end,
							title: event.title,
							description: event.description,
							isAllDay: event.isAllDay
						});

					}

					startDate = startDate.add('day', 1);

				}

			}

		}

		let keys = Object.keys(tmp).sort();

		//mise en forme pour l'affichage
		let index = 0;
		for (let key of keys) {

			let group = tmp[key];

			rowData.push({
				_id: key,
				date: moment(key, 'YYYY_MM_DD').format('dddd DD MMMM YYYY')
			});

			for (let item of group) {
				rowData.push(item);
			}

			if (index != keys.length - 1) {
				rowData.push({});
			}

			index++;

		}

		if (_.last(this.dataToList)) {

			for (let i = 0; i < rowData.length; i++) {

				if (_.find(this.dataToList, { _id: rowData[i]._id })) {
					rowData.splice(i, 1);
					i--;
				}

			}

		}

		this.dataToList = this.dataToList.concat(rowData);

		return { rowData, lastRow: data.length < 100 }
	}

	/**
	 * rendu de la vue liste
	 */
	private renderViewList() {

		let startRow = 0;
		let endRow = 100;

		this.dataToList = [];

		(this.gridOptionList && this.gridOptionList.api) && this.gridOptionList.api.destroy();

		let N_date = this.el.querySelector('#currentDate') as HTMLDivElement;

		(!global.IS_MOBILE) && (N_date.style.pointerEvents = 'none');

		this.getDataToViewList(startRow, endRow).then(({ rowData }) => {

			if (this.dataToList.length) {

				let start = moment(this.dataToList[1].start);
				let end = moment(_.last(this.dataToList).start);

				let data = this.formatDate(start, end);
				N_date.innerHTML = data.value;
			} else {
				N_date.innerHTML = 'Aucun événement';
			}


			startRow += 100;
			endRow += 100;

			let N_grid = this.el.querySelector('#ag-grid-list');

			//initialisation de la grille
			this.gridOptionList = agGridFrench({
				columnDefs: [{
					field: 'date',
					hide: true
				}, {
					field: 'color',
					width: 30,
					suppressSizeToFit: true,
					cellRenderer: (params) => {

						params.data = params.data || {};

						let result = `<div class="ag-grid-circle" style="border-color: ${params.data.color}; background-color:  ${params.data.color};">&nbsp;</div>`;
						return result;

					}
				}, {
					field: 'hours',
					width: 130,
					suppressSizeToFit: true,
					cellRenderer: (params) => {

						params.data = params.data || {};

						if (params.data.isAllDay) {
							return 'Toute la journée';
						}

						if (params.data.start && params.data.end) {

							return moment(params.data.start).format('HH:mm') + ' - ' + moment(params.data.end).format('HH:mm');

						}

						return '';
					}
				}, {
					field: 'title',
					cellRenderer: (params) => {
						return params.value;
					}
				}, {
					field: 'description',
					cellRenderer: (params) => {
						return params.value;
					}
				}],
				isFullWidthCell: function (rowNode: any) {

					let isHeader = (rowNode.data || {}).date;
					return isHeader;

				},
				getRowHeight: (params: any) => {

					if (params.data.date) {
						return 40;
					}
					return 25;

				},
				onGridReady: (params) => {

					params.api.setHeaderHeight(0);
					params.api.sizeColumnsToFit();

					if (N_grid) {
						let N_scroll = N_grid.querySelector('.ag-body-viewport.ag-layout-normal') as HTMLDivElement;

						let lockLoadData = false;

						if (N_scroll) {

							N_scroll.addEventListener('scroll', () => {

								let scrollPercent = (N_scroll.scrollTop * 100) / N_scroll.scrollHeight

								if (scrollPercent > 80 && !lockLoadData) {

									lockLoadData = true;

									this.getDataToViewList(startRow, endRow).then(({ rowData, lastRow }) => {

										startRow += 100;
										endRow += 100;

										params.api.applyTransaction({ add: rowData })

										let start = moment(this.dataToList[1].start);
										let end = moment(_.last(this.dataToList).start);

										let data = this.formatDate(start, end);
										N_date.innerHTML = data.value;

										if (!lastRow) {

											setTimeout(() => {
												lockLoadData = false;
											}, 500);

										}

									});

								}

							});
						}
					}
				},
				suppressContextMenu: true,
				suppressCellSelection: true,
				suppressDragLeaveHidesColumns: true,
				rowData,
				fullWidthCellRenderer
			});

			if (N_grid) {
				new Grid(N_grid as HTMLElement, this.gridOptionList);
			}

		});


	}

	/**
	 * gestion des boutons de la barre de navigations
	 */
	private initEventNav() {

		let N_grid = this.el.querySelector('#ag-grid-list');
		let N_calendar = this.el.querySelector('#fullcalendar');

		let N_prev = this.el.querySelector('#prev');

		//bouton précedent
		if (N_prev) {
			N_prev.addEventListener('click', () => {

				this.calendar.prev();
				this.setCurrentDate();

			});
		}

		let N_today = this.el.querySelector('#today');

		//bouton aujourd'hui
		if (N_today) {
			N_today.addEventListener('click', () => {

				if (this.currentView == 'calendar') {
					this.calendar.today();
					this.setCurrentDate();
				}

				if (this.currentView == 'list') {

					(this.gridOptionList && this.gridOptionList.api) && this.gridOptionList.api.ensureIndexVisible(0);

				}


			});
		}

		let N_next = this.el.querySelector('#next');

		//bouton suivant
		if (N_next) {
			N_next.addEventListener('click', () => {

				this.calendar.next();
				this.setCurrentDate();

			});
		}

		let N_month = this.el.querySelector('#month');
		let N_week = this.el.querySelector('#week');
		let N_day = this.el.querySelector('#day');
		let N_list = this.el.querySelector('#list');

		//bouton mois
		if (N_month) {
			N_month.addEventListener('click', () => {

				this.currentView = 'calendar';

				N_grid && N_grid.classList.add('d-none');
				N_calendar && N_calendar.classList.remove('d-none');

				N_month && N_month.classList.add('btn-secondary');
				N_week && N_week.classList.remove('btn-secondary');
				N_day && N_day.classList.remove('btn-secondary');
				N_list && N_list.classList.remove('btn-secondary');

				N_prev && N_prev.classList.remove('d-none');
				N_next && N_next.classList.remove('d-none');

				this.calendar.changeView('month', true);

				this.setCurrentDate();
				this.refreshCalendarVisibility();

			});
		}

		//bouton semaine
		if (N_week) {
			N_week.addEventListener('click', () => {

				this.currentView = 'calendar';

				N_grid && N_grid.classList.add('d-none');
				N_calendar && N_calendar.classList.remove('d-none');

				N_month && N_month.classList.remove('btn-secondary');
				N_week && N_week.classList.add('btn-secondary');
				N_day && N_day.classList.remove('btn-secondary');
				N_list && N_list.classList.remove('btn-secondary');

				N_prev && N_prev.classList.remove('d-none');
				N_next && N_next.classList.remove('d-none');

				this.calendar.changeView('week', true);

				this.setCurrentDate();
				this.refreshCalendarVisibility();

			});
		}

		//bouton jour
		if (N_day) {
			N_day.addEventListener('click', () => {

				this.currentView = 'calendar';

				N_grid && N_grid.classList.add('d-none');
				N_calendar && N_calendar.classList.remove('d-none');

				N_month && N_month.classList.remove('btn-secondary');
				N_week && N_week.classList.remove('btn-secondary');
				N_day && N_day.classList.add('btn-secondary');
				N_list && N_list.classList.remove('btn-secondary');

				N_prev && N_prev.classList.remove('d-none');
				N_next && N_next.classList.remove('d-none');

				this.calendar.changeView('day', true);

				this.setCurrentDate();
				this.refreshCalendarVisibility();

			});
		}

		//bouton liste
		if (N_list) {
			N_list.addEventListener('click', () => {

				this.currentView = 'list';

				N_grid && N_grid.classList.remove('d-none');
				N_calendar && N_calendar.classList.add('d-none');

				N_month && N_month.classList.remove('btn-secondary');
				N_week && N_week.classList.remove('btn-secondary');
				N_day && N_day.classList.remove('btn-secondary');
				N_list && N_list.classList.add('btn-secondary');

				N_prev && N_prev.classList.add('d-none');
				N_next && N_next.classList.add('d-none');

				this.renderViewList();

			});
		}

	}

	private specificMobile() {

		if (global.IS_MOBILE) {

			let N_calendar = this.el.querySelector('#calendar-layout');
			let N_list = this.el.querySelector('#list-calendar-layout');
			let N_recall = this.el.querySelector('#recall-layout');

			//affichage list agenda
			let N_btnListCalendar = this.el.querySelector('#display-list-calendar');

			if (N_btnListCalendar) {
				N_btnListCalendar.addEventListener('click', () => {

					if (N_calendar && N_list && N_recall) {

						N_calendar.classList.add('d-none');
						N_calendar.classList.remove('d-flex');

						N_list.classList.remove('d-none');
						N_list.classList.add('d-flex');

						N_recall.classList.add('d-none');
						N_recall.classList.remove('d-flex');

					}

				});
			}

			//affichage liste rappels
			let N_btnListrecall = this.el.querySelector('#display-list-recall');

			if (N_btnListrecall) {
				N_btnListrecall.addEventListener('click', () => {

					if (N_calendar && N_list && N_recall) {

						N_calendar.classList.add('d-none');
						N_calendar.classList.remove('d-flex');

						N_list.classList.add('d-none');
						N_list.classList.remove('d-flex');

						N_recall.classList.remove('d-none');
						N_recall.classList.add('d-flex');

					}

				});
			}

			//retour liste rappel
			let N_btnReturnRecall = this.el.querySelector('#return-recall');

			if (N_btnReturnRecall) {
				N_btnReturnRecall.addEventListener('click', () => {

					if (N_calendar && N_list && N_recall) {

						N_calendar.classList.remove('d-none');
						N_calendar.classList.add('d-flex');

						N_list.classList.add('d-none');
						N_list.classList.remove('d-flex');

						N_recall.classList.add('d-none');
						N_recall.classList.remove('d-flex');

						this.refreshCalendarVisibility();

					}

				});
			}

			//retour liste agenda
			let N_btnReturnList = this.el.querySelector('#return-list-calendar');

			if (N_btnReturnList) {

				N_btnReturnList.addEventListener('click', () => {

					if (N_calendar && N_list && N_recall) {

						N_calendar.classList.remove('d-none');
						N_calendar.classList.add('d-flex');

						N_list.classList.add('d-none');
						N_list.classList.remove('d-flex');

						N_recall.classList.add('d-none');
						N_recall.classList.remove('d-flex');

						this.refreshCalendarVisibility();

					}

				});

			}

			let touchstartX = 0;
			let touchendX = 0;
			let touchstartY = 0;
			let touchendY = 0;

			if (N_calendar) {

				N_calendar.addEventListener('touchstart', (e: Event) => {
					let event = e as TouchEvent;
					touchstartX = event.changedTouches[0].screenX;
					touchstartY = event.changedTouches[0].screenY;
				});

				N_calendar.addEventListener('touchend', (e: Event) => {

					if (this.currentView == 'calendar') {

						let event = e as TouchEvent;
						touchendX = event.changedTouches[0].screenX;
						touchendY = event.changedTouches[0].screenY;

						let N_date = this.el.querySelector('#currentDate') as HTMLDivElement;

						if (Math.abs(touchendX - touchstartX) > window.innerWidth * 40 / 100 && Math.abs(touchendY - touchstartY) < 100 && N_date) {

							//next 
							if (touchendX < touchstartX) {
								this.calendar.next();
								this.setCurrentDate();
							}

							//prev
							if (touchendX > touchstartX) {
								this.calendar.prev();
								this.setCurrentDate();
							}

						}
					}

				});

			}
		}

	}


	public destructor() {
		global.IS_MOBILE && this.el.classList.add('no-title')
		global.IS_MOBILE && document.body.classList.add('is-mobile')

		const N_dropback = document.querySelector('#dropback')

		if (N_dropback) {
			const parent = N_dropback.parentNode as HTMLElement
			parent.removeChild(N_dropback)
		}

		const N_daterangepicker = document.querySelectorAll('.daterangepicker')

		N_daterangepicker.forEach((item) => {
			const parent = item.parentNode as HTMLElement
			parent.removeChild(item);
		});

	}

}

export default CalendarCtrl;

import _ from 'lodash'

class CollectionUtils {
	private constructor() {}

	/**
	 * Permet de verifier si une des proprietes a au moins une valeur recherche
	 * @param obj Objet avec une propriete
	 * @param value La valeur recherche
	 * @param excludes Tableau 
	 * @returns Retourne true ou false
	 */
	 public static hasAnyPropertyValue(obj: any, value: any, excludes: Array<string> = []) {
		return _.difference(Object.keys(obj), excludes).some(key => Reflect.get(obj, key) === value)
	}
}

export default CollectionUtils

import { Modal, global } from "@autoprog/core-client"

import { Grid, GridOptions, ICellRendererParams } from 'ag-grid-community'
import 'ag-grid-enterprise'
import T_transfertStock from '@tpl/modals/exploitation/transfertStock.html'

import Select2CellEditor from '@libs/agGrid/Select2CellEditor'

import unitList from '@libs/lists/unitList'
import BDD from '@libs/BDD'
import moment, { Moment } from 'moment'
import { french as agGridFrench } from '@libs/utils/agGrid'

import drpC from '@libs/utils/daterangepickerConfig'
import AccountingAccountsService from '@js/libs/services/AccountingAccountsService'
import Site from '@js/types/site'
import SitesService from '@js/libs/services/SitesService'
import Product from '@js/types/products'
import ProductsService from '@js/libs/services/ProductsService'
import StocksService, { ProductPerStorage } from '@js/libs/services/StocksService'
import StockEvent from '@js/types/stock-event'

import _ from 'lodash'
import toastr from 'toastr'

/**
 * Alias de fonction pour filtrer les nodes/data
 */
type FilterNodeCallback = (data: any) => boolean
 
class TransfertStock extends Modal {

	private gridOptions: GridOptions | null = null;
	private designSite: string = global.DESIGN;

	private products: Array<Product> = []
	private site!: Site

	private unit: string = ""

	private date: Moment = moment();

	constructor(site: string) {

		super({
			tpl: T_transfertStock,
			backdrop: 'static',
			keyboard: false
		});

		this.designSite = site;

		this.on('opened', async () => {

			await this.loadData()

			const N_name_site = this.element.querySelector('#name-site') as HTMLElement
			N_name_site.innerHTML = this.site.name

			const productByStorage = await this.getProductByStorage()
			const N_select_destination = this.element.querySelector('#destination') as HTMLSelectElement
			const N_date = this.element.querySelector('#date') as HTMLInputElement

			if (N_select_destination) {

				let index = 0;

				let option_destination = new Option('Exterieur', 'out', true, true);

				N_select_destination.append(option_destination);

				for (let item of (this.site.storages || [])) {

					if (item.type == 'box' && !item.deleted) {

						let option_destination = new Option(item.name, item.id);

						N_select_destination.append(option_destination);

						if (index == 0) {
							this.unit = item.unit;
						}

						index++;

					}

				}

				N_select_destination.addEventListener('change', () => {
					this.gridOptions && this.gridOptions.api && this.gridOptions.api.redrawRows();
				});

			}

			if (N_date) {

				$(N_date).daterangepicker(drpC({
					singleDatePicker: true,
					timePicker: true,
					timePicker24Hour: true,
					autoUpdateInput: false,
					startDate: this.date,
				}), (start) => {
					this.date = start;
					N_date.value = this.date.format('DD/MM/YYYY HH:mm');
				});

				N_date.value = this.date.format('DD/MM/YYYY HH:mm')

			}

			let gridEl = this.element.querySelector('#list-product-grid') as HTMLElement;

			let dataStorage = (this.site.storages || [])
				.filter(storage => storage.type === 'box' && !storage.deleted)
				.map(storage => ({ id: storage.id, text: storage.name }))

			dataStorage.unshift({ id: 'out', text: 'Exterieur' })

			this.gridOptions = agGridFrench({
				components: {
					select2CellEditor: Select2CellEditor as any
				},
				columnDefs: [
					{
						headerName: 'Emplacement',
						field: 'storage',
						cellEditor: Select2CellEditor as any,
						cellEditorParams: {
							options: ({
								data: dataStorage,
								dropdownParent: $(gridEl as HTMLElement)
							} as Options)
						},
						editable: (params) => {
							return !params.node.rowPinned;
						},
						cellRenderer: (params) => {
							if (!params.node.rowPinned) {
								if (params.value && params.value.text) {
									return params.value.text || '';
								} else {
									return '';
								}
							}

							return '';
						}
					}, {
						headerName: 'Produit',
						field: 'product',
						cellEditorSelector: (params) => {

							let data: any[] = [];

							if (params.data.storage) {

								if (params.data.storage.id != 'out') {

									for (let item of productByStorage) {

										if (params.data.storage.id == item.storage && item.quantity) {

											let product = _.find(this.products, { _id: item.product })

											data.push({
												id: item.product,
												text: product ? product.reference : item.product
											});

										}

									}

								} else {

									for (let product of this.products) {

										let accountingAccount = AccountingAccountsService.getInstance().getById(product.accountingAccount)

										if (accountingAccount && accountingAccount.isRawMaterial && !product.deleted) {

											data.push({
												id: product._id,
												text: product.reference
											});

										}

									}

								}

							}

							return {
								component: 'select2CellEditor',
								params: {
									options: ({
										data,
										dropdownParent: $(gridEl as HTMLElement)
									} as Options)
								}
							};

						},
						editable: (params) => {
							return !params.node.rowPinned;
						},
						cellRenderer: (params) => {
							if (!params.node.rowPinned) {
								if (params.value && params.value.text) {
									return params.value.text || '';
								} else {
									return '';
								}
							}

							return '';
						}
					}, {
						headerName: 'Freinte (%)',
						field: 'wastage',
						editable: (params) => {
							return !params.node.rowPinned && params.node.data.product && N_select_destination.value != 'out';
						},
						cellRenderer: (params) => {
							if (!params.node.rowPinned) {
								if (N_select_destination.value == 'out') {
									return 0;
								} else {
									return params.value;
								}
							}
						}
					}, {
						headerName: 'Quantité',
						field: 'quantity',
						editable: (params) => {
							return !params.node.rowPinned;
						},
						cellRenderer: (params) => {

							if (!params.node.rowPinned) {

								let value = Number(params.value);

								if (isNaN(value)) {
									return '---'
								} else {
									return _.round(value, 2);
								}
							}
							return params.value;
						}
					}, {
						headerName: 'Unité',
						field: 'unit',
						editable: false,
						cellRenderer: (params) => {

							if (!params.node.rowPinned) {

								let value = unitList[params.value]

								return value ? value.unit : '---';
							}

							return unitList['tonne'].unit;
						}
					}, {
						headerName: '#',
						width: 60,
						suppressSizeToFit: true,
						cellRenderer: (params: ICellRendererParams) => {

							if (!params.node.rowPinned) {

								const N_btn = document.createElement('button')

								N_btn.classList.add('btn-xs', 'btn-danger')
								N_btn.setAttribute('confirmation', '')

								N_btn.innerHTML = '<i class="icon icon-solid-trash-alt"></i>'

								N_btn.addEventListener('click', () => {

									if (params && params.api) {
										params.api.applyTransaction({
											remove: [params.data]
										})

										const data: string[] = [];

										params.api.forEachNode((node: any) => {
											if (node.data.product && node.data.storage) {
												data.push(node.data);
											}
										});

										const N_alert = this.element.querySelector('#notSameProduct') as HTMLElement

										if (data.length != 1 && N_select_destination.value != 'out') {
											N_alert.classList.remove('d-none')
										}
										else {
											N_alert.classList.add('d-none')
										}

									}

								});

								return N_btn;

							}
							return '';

						}
					}
				],
				rowData: [],
				suppressContextMenu: true,
				suppressDragLeaveHidesColumns: true,
				pinnedBottomRowData: [{
					quantity: 0
				}],
				getRowStyle: (params: any) => {

					if (N_select_destination) {
						if (params.data.storage && params.data.storage.id == 'out' && N_select_destination.value == 'out') {
							return {
								'background-color': '#f24d4d'
							};
						}
					}

					return {
						'background-color': ''
					};
				},
				onCellEditingStopped: (params) => {

					if (params.api) {

						if (params.colDef.field == 'storage') {

							params.node.setDataValue('product', '');
							params.node.setDataValue('wastage', '');
							params.node.setDataValue('unit', '');

						}

						if (params.colDef.field == 'quantity') {

							let quantity = 0;

							params.api.forEachNode((node) => {

								let tmp = Number(node.data.quantity);

								let unit = unitList[node.data.unit];

								let value = isNaN(tmp) ? 0 : tmp;

								if (unit) {
									value *= unit.coeficient;
								}

								quantity += value;

							});

							params.api.setPinnedBottomRowData([{
								quantity: quantity ? (quantity / unitList['tonne'].coeficient) : '---'
							}]);

						}

						if (params.colDef.field == 'product') {

							let data: string[] = [];

							params.api.forEachNode((node) => {
								if (node.data.product && node.data.storage) {
									data.push(node.data);
								}
							});

							let N_alert = this.element.querySelector('#notSameProduct');

							if (data.length != 1 && N_select_destination.value != 'out') {
								N_alert && N_alert.classList.remove('d-none');
							} else {
								N_alert && N_alert.classList.add('d-none');
							}

							let product = _.find(this.products, { _id: params.data.product.id });

							params.node.setDataValue('wastage', product ? product.wastage : '')
							params.node.setDataValue('unit', product ? product.unit : '')

						}

					}

				},
				onGridReady: (params) => {

					(params && params.api) && params.api.sizeColumnsToFit();

				}
			})

			if (this.gridOptions) {
				new Grid(gridEl, this.gridOptions);
			}

			this.configureAddLine()
			await this.configureSaveBtn()


		});

	}

	private configureAddLine() {
		let N_addLine = this.element.querySelector('#addproductLine')!
		N_addLine.addEventListener('click', () => {
			this.gridOptions?.api?.applyTransaction({ add: [{}] })
		})
	}

	private getDataFromGrid(filterCallback: FilterNodeCallback | null = null): Array<any> {
		const data: Array<any> = []

		this.gridOptions?.api?.forEachNode(node => {
			if ((filterCallback && filterCallback(node.data)) || _.isNull(filterCallback)) {
				data.push(node.data)
			}
		})

		return data
	}

	private async configureSaveBtn(): Promise<void> {


		const N_save = this.element.querySelector('#save')!
		const N_select_destination = this.element.querySelector('#destination') as HTMLSelectElement

		N_save.addEventListener('click', async () => {

			try {
				if (this.gridOptions && this.gridOptions.api) {

					let stocks: Array<StockEvent> = [];
					// TODO: vérrifier que cette condition marche
					let origins = this.getDataFromGrid((data) => data.product && data.storage)
	
					if (origins.length <= 0) return
	
					// let promises: Promise<any>[] = [];
	
					// Si on a deux produits ou plus, on va alors créer un nouveau produit qui sera un mélange
					if (origins.length >= 2) {
	
						let product: any = {
							_id: BDD.generateID(),
							reference: 'Mélange ',
							price: 0,
							potentialMethane: 0,
							wastage: 0,
							organicMatter: 0,
							dryMatter: 0,
							unit: 'tonne',
							description: '',
							deleted: false
						}
	
						product.reference = origins.reduce((str, origin) => str.concat(' ', str + ' ' + origin.product.text), '')
						product.description = origins.reduce((str, origin) => str + `-${origin.product.text} : ${(origin.quantity || 0)} ${unitList[this.unit].unit} (${origin.wastage}%) \n`, '')
	
						// Fonction pour récupérer la quantité
						const getQuantity = (quantity: number | undefined | null | string, productId: string): number => {
							const product = this.products.find(p => p._id === productId) as Product
							// const tmp = isNaN(+quantity) ? 0 : +quantity
							let tmp = typeof quantity === 'string' ? (Number.isNaN(+quantity) ? 0 : +quantity) : quantity
							tmp = Number.isNaN(tmp) ? 0 : tmp as number
							return tmp * unitList[product.unit].coeficient
						}
	
	
						let totalQuantity = 0;
						for (let origin of origins) {
							let tmpQuantity = getQuantity(origin.quantity, origin.product.id)
							totalQuantity += tmpQuantity;
	
							stocks.push({
								_id: BDD.generateID(),
								date: this.date.unix() * 1000,
								type: 'output',
								storage: origin.storage.id,
								quantity: tmpQuantity,
								wastage: 0,
								site: this.designSite,
								product: origin.product.id,
								isTransfert: origin.storage.id != 'out' && N_select_destination.value != 'out'
							})
						}
	
						// Récupération des analytics, des accountingNumbers et des subAnalytics
						// let productIds = _.uniq(origins.map(origin => origin.product.id))
						// let products = this.products.filter(product => productIds.indexOf(product._id) >= 0)
						// let allAnalytics = products.map(product => product.analytics).flat()
	
						// let cumulAnalytics = allAnalytics.reduce((cumul: Map<string, { id: string, percent: number, numberOfAnalytics: number }>, analytic: any) => {
						// 	if (!cumul.has(analytic.id)) {
						// 		cumul.set(analytic.id, { id: analytic.id, percent: 0, numberOfAnalytics: 0 })
						// 	}
	
						// 	let data = cumul.get(analytic.id)
						// 	data.numberOfAnalytics++
						// 	data.percent += analytic.percent
						// 	cumul.set(analytic.id, data)
	
						// 	return cumul
						// }, new Map<string, { id: string, percent: number, numberOfAnalytics: number }>())
	
						// Créations des analytiques pour le nouveau produit
						// let analytics = Array.from(cumulAnalytics.values()).map(analytic => ({ id: analytic.id, percent: analytic.percent / analytic.numberOfAnalytics }))
	
						//calcule des valeurs pour le nouveaux produit en function du prorata de chaque produit selectionnés
						for (let origin of origins) {
	
							const dataProduct = _.find(this.products, { _id: origin.product.id }) as Product
	
							let tmpQuantity = +origin.quantity
							tmpQuantity = tmpQuantity || 0
							tmpQuantity *= unitList[dataProduct.unit].coeficient
	
							let coef = tmpQuantity / totalQuantity
							coef = coef || 1
	
							product.price += _.round(dataProduct.price * coef, 2)
							product.potentialMethane += _.round(dataProduct.potentialMethane || 0 * coef, 2)
							product.wastage += _.round(origin.wastage * coef, 2)
							product.organicMatter += _.round(dataProduct.organicMatter || 0 * coef, 2)
							product.dryMatter += _.round(dataProduct.dryMatter || 0 * coef, 2)
						}
	
	
						// let newMixinProduct: Product = {
						// 	_id: product._id,
						// 	reference: product.reference,
						// 	price: _.round(product.price, 2),
						// 	potentialMethane: product.potentialMethane,
						// 	wastage: product.wastage,
						// 	organicMatter: product.organicMatter,
						// 	dryMatter: product.dryMatter,
						// 	unit: 'tonne',
						// 	description: product.description,
						// 	deleted: product.deleted,
	
						// 	accountingAccount: "other", // On met autre par défaut
						// 	analytics: analytics,
						// 	provider: this.designSite,
						// 	sites: [this.designSite],
						// }
	
						//si la destination est différente de "exterieur", on sauvegarde le mélange
						if (N_select_destination.value != 'out') {
							await ProductsService.getInstance().create(product)
						}
	
						stocks.push({
							_id: BDD.generateID(),
							date: this.date.unix() * 1000,
							type: 'input',
							storage: N_select_destination.value,
							quantity: totalQuantity,
							wastage: _.round(product.wastage / 100, 3),
							site: this.designSite,
							product: product._id,
							isTransfert: stocks[0].storage != 'out' && N_select_destination.value != 'out',
						})
	
					} else {
	
						let origin = origins[0];
	
						const dataProduct = _.find(this.products, { _id: origin.product.id }) as Product
	
						let tmpQuantity = +origin.quantity
						tmpQuantity = tmpQuantity || 0
						tmpQuantity *= unitList[dataProduct.unit].coeficient
	
						stocks.push({
							_id: BDD.generateID(),
							date: this.date.unix() * 1000,
							type: 'output',
							storage: origin.storage.id,
							quantity: tmpQuantity,
							wastage: 0,
							site: this.designSite,
							product: origin.product.id,
							isTransfert: origin.storage.id != 'out' && N_select_destination.value != 'out'
						});
	
						stocks.push({
							_id: BDD.generateID(),
							date: this.date.unix() * 1000,
							type: 'input',
							storage: N_select_destination.value,
							quantity: tmpQuantity,
							wastage: _.round(origin.wastage / 100, 3),
							site: this.designSite,
							product: origin.product.id,
							isTransfert: origin.storage.id != 'out' && N_select_destination.value != 'out',
						});
	
					}
	
					// On ignore les evenement qui viennent pas de l'exterieur
					const newDocs = stocks.filter(item => item.storage != 'out')
					await StocksService.getInstance().updateAll(newDocs)
					toastr.success('Transfert effectué')
				}
			}
			catch {
				toastr.success('Erreur Transfert')
			}

			this.resolve()
		})


	}

	private getStorageBySite(): Promise<Site> {
		return SitesService.getInstance().getByID(this.designSite)
	}

	private getAllProducts(): Promise<Product[]> {
		return ProductsService.getInstance().getAllProducts()
	}

	private async getProductByStorage(): Promise<Array<ProductPerStorage>> {
		return StocksService.getInstance().getQuantitiesPerStorage(this.designSite)
	}

	private async loadData(): Promise<void> {
		this.products = await this.getAllProducts()
		this.site = await this.getStorageBySite()
	}

}

export default TransfertStock;

import EventsService from "../services/EventsService";
import ChecklistModelsService from "../services/ChecklistModelsService";

class CheckListModelsManager {
	private constructor() {}

	// Va supprimer de façon propre tous la checklist model
	public static async delete(id: string) : Promise<void> {

		try {
			let event = await EventsService.getInstance().getByID(id)
			event.deleted = true
			await EventsService.getInstance().update(event)
		}
		catch {

		}

		await ChecklistModelsService.getInstance().delete(id)

	}
}

export default CheckListModelsManager
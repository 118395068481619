import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { EventBdd, calendarBdd } from '@js/types/calendar'
import { CheckListModel } from '@js/types/checklist'
import checklistEventDescription from '@tpl/blocks/exploitation/checklistEventDescription.ejs'
import checklistEventTitle from '@tpl/blocks/exploitation/checklistEventTitle.ejs'
import ChecklistsService from './ChecklistsService'

type RecallEventsOptions = {
	includedSites?: Array<string>
	limit?: number
}

class EventsService {
	private static readonly instance = new EventsService()

	private constructor() { }

	public static getInstance(): EventsService {
		return EventsService.instance
	}

	public async getAll(): Promise<Array<EventBdd>> {
		return axios.get('/api/events').then(response => response.data.data)
	}

	public async getByID(id: string): Promise<EventBdd> {
		return axios.get(`/api/events/${id}`).then(response => response.data.data)
	}

	public async update(event: EventBdd): Promise<void> {
		await axios.put('/api/events', event)
	}

	public async create(event: EventBdd) : Promise<void> {
		await axios.post('/api/events', event)
	}

	public async delete(id: string) : Promise<void> {
		await axios.delete(`/api/events/${id}`)
	}

	/**
	 * Permet de récupérer tous les evenement lié à la date données
	 * @param startingDate 
	 */
	public async getByDate(startingDate: number) : Promise<Array<EventBdd>> {
		return axios.get(`/api/events/by-date/${startingDate}`).then(response => response.data.data)
	}

	/**
	 * Permet de récupérer les evenements lié entre deux dates
	 * @param startingDate 
	 * @param endingDate 
	 */
	public async getByDates(startingDate: number, endingDate: number) : Promise<Array<EventBdd>> {
		return axios.get(`/api/events/by-dates/${startingDate}/${endingDate}`).then(response => response.data.data)
	}

	public async getRecallEvents(date: number, options?: RecallEventsOptions) : Promise<Array<EventBdd>> {
		return axios.post(`/api/events/recall-events/${date}`, options).then(response => response.data.data)
	}

	/**
	 * Permet de chercher des events entre deux dates
	 * @param startingDate Date de debut en chiffre
	 * @param endingDate Date de fin en chiffre
	 * @param options Options dont filterCalendars contenant les ids des calendriers accepté
	 */
	public async getBetweenDate(startingDate: number, endingDate: number, options?: {
		filterCalendars?: Array<string>
	}) : Promise<Array<EventBdd>> {
		return axios.post(`/api/events/between-date/${startingDate}/${endingDate}`, options).then(response => response.data.data)
	}

	public async getSince(date: number, options?: {
		startIndex: number,
		endIndex: number,
		filterCalendars?: Array<string>
	}) : Promise<Array<EventBdd>> {
		return axios.post(`/api/events/since/${date}`, options).then(response => response.data.data)
	}

	/**
	 * Va toggle le tag validate
	 */
	public async toggleValidate(id: string) {
		let event = await this.getByID(id)
		event.validate = !event.validate
		await this.update(event)
	}

	public async tagDelete(id: string) : Promise<void> {
		await axios.get(`/api/events/tag-delete/${id}`)
	}


	// FIXME: mauvaise utilisation et implementation
	public async createEventsForChecklistCalendars(checklistModel: CheckListModel, _calendars: calendarBdd[]) {

		let promises = [];

		for (let site of checklistModel.sites) {
			promises.push(this.createEventsForChecklistCalendar(checklistModel, site));
		}

	}

	public async createEventsForChecklistCalendar(checklistModel: CheckListModel, site: string) {

		try {

			let calendarID = 'checklist-' + checklistModel._id + '_' + site;
			let checklistsIDs: Set<string> = new Set();

			const event = await EventsService.getInstance().getByID(calendarID)
			let events = [event]

			//On considére tous les évenements comme supprimés
			for (let i in events) {
				events[i]._deleted = true;
			}

			if (checklistModel.repeat && checklistModel.repeat.enable) {

				let repeatStart = moment(checklistModel.repeat.from).startOf('day');
				let repeatEnd = moment(checklistModel.repeat.to).endOf('day');
				let stepDate = repeatStart.clone();

				//Tant qu'on est dans l'interval défini par l'utilisateur
				while (stepDate.unix() <= repeatEnd.unix()) {

					//On revérifie les dates pour gérer la derniere répétition qui peux être plus tard que la fin de répétition si le pas ne tombe pas juste
					if (stepDate.unix() <= repeatEnd.unix()) {

						let eventID = calendarID + '_' + stepDate.format('YYYY-MM-DD');
						let checklistID = checklistModel._id + '_' + site + '_' + stepDate.format('YYYY-MM-DD');
						let index = _.findIndex(events, ['_id', eventID]);

						checklistsIDs.add(checklistID)

						//si l'evenement existe deja, on le met a jour
						if (index >= 0) {

							events[index].start = parseInt(stepDate.startOf('day').format('x'));
							events[index].end = parseInt(stepDate.endOf('day').format('x'));
							events[index]._deleted = false; //On ne considère plus l'evenement comme supprimé

						} else {

							events.push({
								_id: eventID,
								calendarId: calendarID,
								isAllDay: true,
								category: 'time',
								start: parseInt(stepDate.startOf('day').format('x')),
								end: parseInt(stepDate.endOf('day').format('x')),

								locked: true
							});
						}
					}
					//On ajoute le pas définit par l'utilisateur
					stepDate.add(checklistModel.repeat.interval.value as any, checklistModel.repeat.interval.type);
				}
			}

			const checklistService = ChecklistsService.getInstance();;
			const percents = await checklistService.getPercentByIds(Array.from(checklistsIDs.values()))


			for (let i = 0; i < events.length; i++) {
				let event = events[i];

				let checklistID = checklistModel._id + '_' + site + '_' + moment(event.start).format('YYYY-MM-DD');

				let percent = percents.get(checklistID) || 0;

				event.title = checklistEventTitle({
					name: checklistModel.name,
					percent
				});
				event.description = checklistEventDescription({
					checklistID
				});

				this.computeEventColor(event, percent);
			}

			const promises = events.map( event => this.update(event) )
			await Promise.all(promises)

		} catch (e) {
			throw e;
		}

	}

	// FIXME: ne devrait pas etre dans cette classe (créer un eventManager classe)
	private computeEventColor(event: EventBdd, percent: number) {

		if (percent >= 100) {
			event.bgColor = '#eeeeee';
			event.color = '#000000';
		}
		else 
		{
			delete event.bgColor;
			delete event.color;
		}

		return event
	}


}

export default EventsService
export type BooleanDictionary =  { [id: string] : (boolean|BooleanDictionary) }

class DictionnaryUtils {
	private constructor() {}

	/**
	 * Permet d'extraire toutes les clés d'une dictionnary
	 * @param dictionary 
	 */
	public static extractKeys(dictionary: BooleanDictionary) {
		let keys = DictionnaryUtils.utils_key_name(dictionary)

		// On reprend tous les clés générale manquante
		// example : permissions.EXPLOITATION.LOGS.SHOW => permissions.EXPLOITATION.LOGS
		let missingkeys: Array<string> = []
		for (let key of keys) {
			missingkeys.push( key.slice(0, key.lastIndexOf('.')) )
		}

		//keys.push(...missingkeys)
		keys = Array.from( new Set( keys ) )

		return keys
	}

	private static utils_key_name(dictionary: any) : Array<string> {
		
		let names = []

		for ( let name in dictionary ) {

			if (!dictionary[name]) {
				continue
			}

			if (dictionary[name] instanceof Object) {
				names.push( ...this.utils_key_name(dictionary[name]).map( n => name + "." + n ) )
			}
			else {
				// Dans le cas c'est true
				names.push( name )
			}
		}

		return names
	}
}

export default DictionnaryUtils
import { LoggedUser } from "@autoprog/core-client"
import OldDataEvent from "@js/types/data-event"

export type EventsManagerEvent = {
	type: string,
	date?: number,
	user?: string,
	[key: string]: any
}

interface EventsManagerGenericType { //DeliveryNote | Order | WorkOrder | BillType

	events?: OldDataEvent[]
}


class EventsManager {

	public static addEvent<T extends EventsManagerGenericType>(doc: T, event: string | EventsManagerEvent) {

		if (typeof event == 'string') {
			event = {
				type: event
			}
		}

		event.date = event.date || Date.now();
		event.user = event.user || LoggedUser.getInstance().get('ID');

		doc.events = Array.isArray(doc.events) ? doc.events : []
		doc.events.push(event as OldDataEvent)

		return doc;
	}

}

export default EventsManager;

import { Form, Modal, toaster } from "@autoprog/core-client"
import BDD from "@js/libs/BDD"
import { CalendarHolidayService } from '@js/libs/services'
import CalendarHoliday from "@js/types/calendar-holiday"

import T_addEditCalendarHoliday from '@tpl/modals/settings/addEditCalendarHoliday.html'
import moment from "moment"

class AddEditCalendarHoliday extends Modal {

	public constructor(private id?: string) {
		super({
			template: T_addEditCalendarHoliday,
			backdrop: 'static',
			keyboard: false
		})

		this.on('opened', async () => {
			const N_save = this.element.querySelector('#save-button') as HTMLButtonElement
			N_save.addEventListener('click', () => this.save())

			this.id && this.load()
		})
	}

	private async save() {
		const N_form = this.element.querySelector('form') as HTMLFormElement
		const form = new Form(N_form)

		const isValid = await this.checkValidity()
		if (!isValid) {
			toaster.error('Impossible de rajouter la date. Elle existe peut-etre deja.')
			return
		}

		const data = form.getData()

		const calendarHoliday: CalendarHoliday = {
			_id: this.id || BDD.generateID(),
			day: +moment(data.day.toString()).format('x'),
			description: data.description as string,
			fixed: !!data.fixed,
			name: data.name as string
		}

		const addOrUpdate = this.id ? CalendarHolidayService.getInstance().update : CalendarHolidayService.getInstance().create
		const message = this.id ? "Jour ferie ajoute" : "Jour ferie modifie"
		await addOrUpdate(calendarHoliday)
		toaster.success(message)
		this.resolve()
	}

	private async checkValidity() : Promise<boolean> {

		const N_form = this.element.querySelector('form') as HTMLFormElement
		const form = new Form(N_form)

		if (!form.checkValidity()) {
			return false
		}

		if (!!!this.id) {
			const mDate = moment(form.getDataByName<number>('day'))
			return CalendarHolidayService.getInstance().exists(+mDate.format('x')).then(response => !response)
		}

		// TODO: gerer le cas de la modification et verifier que la date n'existe pas
		return true
	}


	private async load() {
		if (this.id) {
			const N_form = this.element.querySelector('form') as HTMLFormElement
			const form = new Form(N_form)

			const calendarHoliday = await CalendarHolidayService.getInstance().getByID(this.id)
			form.setData(calendarHoliday)
		}
	}
}

export default AddEditCalendarHoliday

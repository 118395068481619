import axios from 'axios'

class AlimentationsService {
	private static instance = new AlimentationsService()

	private constructor() {

	}

	public static getInstance() {
		return this.instance;
	}

	/**
	 * Permet de récupérer 
	 */
	public async changeDate(ids: string[], date:number) {

		let { data } = await axios.put('/api/alimentations/changeDate', {
			ids,
			date
		});

		if (data.error) {
			throw data.error;
		}

	}
}

export default AlimentationsService
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="pl-3 carousel-item ' +
((__t = (selected ? 'active': '')) == null ? '' : __t) +
'">\r\n        \r\n            <h4 class="text-center mr-3 mt-1">' +
((__t = (site.name)) == null ? '' : __t) +
'</h4>\r\n\r\n            <table id="" class="table stats-table">\r\n                <thead>\r\n                    <tr>\r\n                        <td>Heures restantes à redistribuer :</td>\r\n                        <td class="text-center remaining-hours"></td>\r\n                        <td class="text-center remaining-standby-duties"></td>\r\n                    </tr>\r\n                    <tr>\r\n                        <th>Designation</th>\r\n                        <th class="text-center">Heures</th>\r\n                        <th class="text-center">Astreinte</th>\r\n                    </tr>\r\n                </thead>\r\n                <tbody>\r\n\r\n                    ';
 for(let hoursCategory of hoursCategories){;
__p += '\r\n                        <tr>\r\n                        <td>' +
((__t = (hoursCategory.label)) == null ? '' : __t) +
'</td>\r\n\r\n                        <td><input class="form-control" name="' +
((__t = (site._id)) == null ? '' : __t) +
'.' +
((__t = (hoursCategory._id)) == null ? '' : __t) +
'.hours" type="time"  value="00:00" required>\r\n                            <div class="invalid-feedback">Saisissez une valeur</div>\r\n                        </td>\r\n                        <td><input class="form-control"  name="' +
((__t = (site._id)) == null ? '' : __t) +
'.' +
((__t = (hoursCategory._id)) == null ? '' : __t) +
'.standbyDuties" type="time"  value="00:00" required>\r\n                            <div class="invalid-feedback">Saisissez une valeur</div>\r\n                        </td>\r\n                    </tr>\r\n                        ';
 } ;
__p += '\r\n\r\n                </tbody>\r\n            </table>\r\n       \r\n    </div>\r\n';

}
return __p
}
import { global, toaster } from "@autoprog/core-client"
import { Grid, GridOptions } from 'ag-grid-community'
import 'ag-grid-enterprise'
import { Product } from '@js/types/products'
import toastr from 'toastr'
import AgGridStateSaver from '@libs/agGrid/StateSaver2'
import h from 'hyperscript'

import AddEditProductModal from '@libs/modals/products/AddEditProductModal'
import DetailProductModal from '@libs/modals/products/DetailProductModal'
import unitList from '@libs/lists/unitList'
import { french as agGridFrench } from '@libs/utils/agGrid'

import Export from '@libs/agGrid/Export'

import M_file from '@libs/modals/orders/Attachments'

import TextFilterAccent from '@libs/agGrid/TextFilterAccent'
import GetQuickFilterAccentText from '@libs/agGrid/GetQuickFilterAccentText'
import AccountingAccountsService from '@js/libs/services/AccountingAccountsService'
import AnalyticsService from '@js/libs/services/AnalyticsService'
import SubAnalyticsService from '@js/libs/services/SubAnalyticsService'
import VATService from '@js/libs/services/VATService'
import SitesService from '@js/libs/services/SitesService'
import Site from '@js/types/site';
import ProductsService from '@js/libs/services/ProductsService'

import _ from "lodash"

class TabProduct {

	private gridOptions: GridOptions = <GridOptions>{};
	private el: HTMLElement;
	private listeners: EventListenerCanceller[] = [];
	private sites: Site[];
	private stateAGrid: AgGridStateSaver | undefined
	constructor(el: HTMLElement) {

		this.el = el
		this.sites = []
		
		this.init()
	}

	private async init() {
		
		this.createGrid()

		let N_quickFilter = this.el.querySelector('#quick-filter-products') as HTMLInputElement;

		N_quickFilter.addEventListener('input', () => {
			if (this.gridOptions && this.gridOptions.api) {
				this.gridOptions.api.setQuickFilter(_.deburr(N_quickFilter.value));
			}
		});

		let N_addProduct = this.el.querySelector('#button-add-product');

		if (N_addProduct) {
			this.listeners.push(N_addProduct.addEventListener2('click', () => {
				let modal = new AddEditProductModal();
				modal.open().then(() => this.updateGrid())
			}));
		}

		try {
			this.sites = await SitesService.getInstance().getAllSites()
			this.updateGrid()
		}
		catch {
			toaster.error('Impossible de charger les sites');
		}

	}

	private createGrid() {
		//recuperation de l'élément pour créer le tableau
		let N_gridProducts = this.el.querySelector('#grid-products');

		//options de la grille
		this.gridOptions = agGridFrench({
			columnDefs: [
				{
					width: 300,
					headerName: "Référence produit / Libelle mouvement",
					field: "reference",
					cellClass: ['justify-content-start'],
					sort: 'asc'
				}, {
					width: 400,
					headerName: "Description",
					field: "description",
					cellClass: ['justify-content-start'],
					aggFunc: (params) => {
						return {
							toString() {
								return params;
							}
						}
					}
				},
				{
					width: 150,
					headerName: "Compte comptable",
					field: "accountingAccount",
					filter: 'agSetColumnFilter',
					valueGetter: (params) => {
						let account = AccountingAccountsService.getInstance().getById(params.data.accountingAccount);
						if (account) {
							return account.name;
						} else {
							return `Inconnue`
						}
					},
					cellClass: (params) => {
						if (params.value.includes('Inconnue')) {
							return 'text-red'
						} else {
							return ''
						}
					}
				}, {
					width: 100,
					headerName: "Analytique",
					autoHeight: true,
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					valueGetter: (params) => {
						if (params.data.analytics) {
							let arr = [];
							for (let analytic of params.data.analytics) {

								let a = AnalyticsService.getInstance().getById(analytic.id);
								if (a) {
									arr.push(a.name+`: ${analytic.percent}%`)
								}
							}
							return arr
						}
						return [];
					},
					cellRenderer: (params)=>{

						let N_container = h('div.d-flex.h-100.w-100.align-items-center.flex-wrap.p-1.justify-content-center') as HTMLElement;

						
							for (let value of params.value) {
								let N_badge = h('span.badge.rounded-pill.px-1', '???') as HTMLElement;
								N_badge.style.margin = '2px'
								if (value) {
									N_badge.innerHTML = value;
								}
								N_container.appendChild(N_badge);

							}

						
						return N_container;

					}
				}, {
					width: 100,
					headerName: "Sous Analytique",
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					valueGetter: (params) => {
						if (params.data.subAnalytics && params.data.subAnalytics.length && params.data.subAnalytics[0]) {
							let subAnalytic = SubAnalyticsService.getInstance().getById(params.data.subAnalytics[0].id);
							if (subAnalytic) {
								return subAnalytic.name;
							}
						}
						return null;
					}
				},
				{
					width: 500,
					headerName: "Restreint aux sites",
					field: "sites",
					filter: 'agSetColumnFilter',
					autoHeight: true,
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: {
						suppressMiniFilter: true,
						values: (params: any) => {
							let values: string[] = [''];

							if (this.gridOptions.api) {

								this.gridOptions.api.forEachNode((node) => {
									values = values.concat(_.map(node.data.sites, 'name'))
								});

								values = _.uniq(values);

							}

							params.success(values);
						}
					},
					cellRenderer: (params) => {

						let N_container = h('div.d-flex.h-100.w-100.align-items-center.flex-wrap.p-1.justify-content-center') as HTMLElement;

						if (params.data.sites.length == this.sites.length) {
							return 'Tous';
						} else {
							for (let site of params.data.sites) {
								let N_badge = h('span.badge.rounded-pill.px-1', '???') as HTMLElement;
								N_badge.style.margin = '2px'
								if (site) {
									N_badge.innerHTML = site.name;
									if (site.color) {
										N_badge.classList.add(`bg-${site.color[0]}-200`)
									}
								}
								N_container.appendChild(N_badge);

							}

						}
						return N_container;
					},
					cellClass: 'text-center'
				}, {
					width: 100,
					headerName: "P.U.",
					field: "price",
					cellClass: ['justify-content-end'],
					valueGetter: (params) => {
						return _.round(params.data.price * unitList[params.data.unit].coeficient, 2);
					},
					cellRenderer: (params) => {
						return params.value + '€';
					},
					filter: 'agNumberColumnFilter',
				}, {
					width: 100,
					headerName: "Unité",
					field: "unit",
					filter: 'agSetColumnFilter',
					cellClass: ['justify-content-start'],
					valueGetter: (params) => {
						let unit = unitList[params.data.unit];
						return unit.name;
					}
				}, {
					width: 75,
					headerName: "TVA",
					field: "vat",
					filter: 'agSetColumnFilter',
					cellClass: ['justify-content-start'],
					valueGetter: (params) => {
						let vat = VATService.getInstance().getById(params.data.vat)
						if (vat) {
							return vat.label
						} else {
							return null
						}
					},
					cellRenderer: (params) => {
						return params.value || `<span class="text-red">Inconnue</span>`
					}
				}, {
					headerName: '',
					field: '',
					filter: false,
					pinned: 'right',
					width: 8 + 28 * 3,
					cellClass: ['justify-content-end'],

					cellRenderer: (params) => {

						let data = params.data;

						let div = document.createElement('div');


						if (data._attachments) {

							let N_attachments = document.createElement('button');

							N_attachments.classList.add('btn', 'btn-xs', 'btn-grey');
							N_attachments.innerHTML = `<i class="icon icon-solid-paperclip"></i>`;

							N_attachments.addEventListener2('click', () => {

								let values = Object.keys(data._attachments)

								if (values.length == 1) {

									window.open(`${global.COUCHDB_URL}/products/${data._id}/${values[0]}`, 'target')

								} else {

									new M_file('products', data._id, values).open()

								}

							})

							div.appendChild(N_attachments);

						}


						let infoButton = document.createElement('button');

						infoButton.classList.add('btn', 'btn-xs', 'btn-info');
						infoButton.setAttribute('permission', 'PRODUCTS_THIRDPARTIES.PRODUCTS.EDIT')

						infoButton.innerHTML = '<i class="icon icon-solid-eye"> </i>';

						infoButton.addEventListener('click', () => {
							let modal = new DetailProductModal(params.data._id)
							modal.open().then(() => this.updateGrid())
						});

						let deleteButton = document.createElement('button');

						deleteButton.classList.add('btn', 'btn-xs', 'btn-danger');
						deleteButton.setAttribute('confirmation', "");
						deleteButton.setAttribute('permission', 'PRODUCTS_THIRDPARTIES.PRODUCTS.DELETE')

						deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"> </i>';

						deleteButton.addEventListener2('click', async () => {
							await this.deleteProduct(params.data)
							this.updateGrid()
						});

						div.appendChild(infoButton);
						div.appendChild(deleteButton);

						return div;
					}

				}
			],

			
			enableRangeSelection: true,
			suppressAggFuncInHeader: true,
			suppressDragLeaveHidesColumns: true,
			defaultColDef: {
				floatingFilter: true,
				sortable: true,
				resizable: true,
				suppressMenu: true,
				floatingFilterComponentParams: { suppressFilterButton: true },
				filter: 'agTextColumnFilter',
				filterParams: {
					newRowsAction: 'keep',
					filterOptions: ['contains'],
					textCustomComparator: TextFilterAccent
				},
				getQuickFilterText: GetQuickFilterAccentText
			},
			getContextMenuItems: (params) => {

				if (params.columnApi) {

					let allColumns = params.columnApi.getAllColumns();

					let columnKeys = _.map(allColumns, 'colId');

					columnKeys.pop();

					let exportAllExcel = {
						name: 'Exporter tous Excel',
						action: () => {
							let exp = new Export(params);
							exp.exportExcel({
								columnKeys
							});
						}
					};

					let exportAllCSV = {
						name: 'Exporter tous CSV',
						action: () => {
							let exp = new Export(params);
							exp.exportCSV({
								columnKeys
							});
						}
					};

					return [exportAllExcel, exportAllCSV];

				}

				return [];

			},
			onGridReady: () => {
			},
			onColumnResized: (params) => {
				params.api.resetRowHeights()
			}

		});

		if (N_gridProducts) {
			//creation de l'instance d'Aggrid
			new Grid(N_gridProducts as HTMLElement, this.gridOptions);
			this.stateAGrid = new AgGridStateSaver(this.gridOptions, 'products', `Onglet Produits`);
			this.stateAGrid.load()
		}
	}

	public destructor() {

		if (this.gridOptions.api) {
			this.stateAGrid && this.stateAGrid.save()
			this.gridOptions.api.destroy();
		}

		for (let func of this.listeners) {
			func();
		}
	}

	//methode pour recuperer les données
	private async getRowData(): Promise<any[]> {

		try {
			let data = await ProductsService.getInstance().getAll().then(result => result as any[])
			
			for (let row of data) {
				if (row.sites) {
					for (let i in row.sites) {
						row.sites[i] = _.find(this.sites, { _id: row.sites[i] });
					}
				}
			}

			return data
		}
		catch (e) {
			throw e
		}
	}

	private async deleteProduct(data: Product) : Promise<void> {

		try {
			await ProductsService.getInstance().delete(data._id)
			toastr.success('Produit supprimé')
		}
		catch (e) {
			toastr.error(`Erreur lors de la supression du produit`)
			console.error(e)
		}
	}

	private async updateGrid() {


		try 
		{
			let data = await this.getRowData()
			this.gridOptions.api && this.gridOptions.api && this.gridOptions.columnApi && this.gridOptions.api.setRowData(data)
		}
		catch (e) {
			console.error("erreur : données non récupérées", e)
			toastr.error("Echec du chargement des données", "Erreur", { timeOut: 5000 })
		}
	}

	
}

export default TabProduct;

module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="tui-full-calendar-popup-container">\r\n\r\n    <div class="tui-full-calendar-popup-section">\r\n        <div class="tui-full-calendar-popup-section-item border-0 p-0 w-50" id="site-selected">\r\n\r\n            <site-select class="tui-full-calendar-content h-100 w-100"></site-select>\r\n            \r\n        </div>\r\n        <div class="tui-full-calendar-popup-section-item border-0 p-0 w-50">\r\n            <div class="tui-full-calendar-dropdown tui-full-calendar-section-calendar">       \r\n\r\n                <button class="tui-full-calendar-button tui-full-calendar-dropdown-button tui-full-calendar-popup-section-item" data-calendar-id="' +
((__t = (selectedCal._id)) == null ? '' : __t) +
'">\r\n                    <span class="tui-full-calendar-icon tui-full-calendar-calendar-dot" style="background-color: ' +
((__t = (selectedCal.bgColor)) == null ? '' : __t) +
'"></span>\r\n                    <span id="tui-full-calendar-schedule-calendar" class="tui-full-calendar-content">' +
((__t = (selectedCal.name)) == null ? '' : __t) +
'</span>\r\n                    <span class="tui-full-calendar-icon tui-full-calendar-dropdown-arrow"></span>\r\n                    </span>\r\n                </button>\r\n\r\n                ';
for(let site in calendars){;
__p += '\r\n\r\n                    <ul class="tui-full-calendar-dropdown-menu" data-site="' +
((__t = (site)) == null ? '' : __t) +
'" style="display:none; z-index: ' +
((__t = (zIndex)) == null ? '' : __t) +
'">\r\n\r\n                        ';
for(let category in calendars[site]){;
__p += '\r\n\r\n                            ';
if(category){;
__p += '\r\n                                <li class="tui-full-calendar-popup-section-item tui-full-calendar-dropdown-menu-item">\r\n                                    <span class="tui-full-calendar-content">\r\n                                        <i class="icon icon-solid-chevron-right d-inline-block"></i>\r\n                                        ' +
((__t = (category)) == null ? '' : __t) +
'\r\n                                    </span>\r\n                                </li>\r\n                                <ul>\r\n                            ';
};
__p += '\r\n\r\n                            ';
for(let calendar of calendars[site][category]){;
__p += '\r\n                                <li class="tui-full-calendar-popup-section-item tui-full-calendar-dropdown-menu-item" data-calendar-id="' +
((__t = (calendar._id)) == null ? '' : __t) +
'">\r\n                                    <span class="tui-full-calendar-icon tui-full-calendar-calendar-dot" style="background-color: ' +
((__t = (calendar.bgColor)) == null ? '' : __t) +
'"></span>\r\n                                    <span class="tui-full-calendar-content">' +
((__t = (calendar.name)) == null ? '' : __t) +
'</span>\r\n                                </li>\r\n                            ';
};
__p += '\r\n\r\n                            ';
if(category){;
__p += '\r\n                                </ul>\r\n                            ';
};
__p += '\r\n\r\n                        ';
};
__p += '\r\n                    </ul>\r\n                ';
};
__p += '\r\n            </div>\r\n        </div>\r\n    </div>\r\n\r\n    <div class="tui-full-calendar-popup-section">\r\n        <div class="tui-full-calendar-popup-section-item tui-full-calendar-section-title">\r\n            <span class="tui-full-calendar-icon tui-full-calendar-ic-title"></span>\r\n            <input id="tui-full-calendar-schedule-title" class="tui-full-calendar-content" placeholder="Titre" value="' +
((__t = (data.title)) == null ? '' : __t) +
'">\r\n        </div>\r\n    </div>\r\n\r\n    <div class="tui-full-calendar-popup-section">\r\n        <div class="tui-full-calendar-popup-section-item tui-full-calendar-section-start-date">\r\n            <span class="tui-full-calendar-icon tui-full-calendar-ic-date"></span>\r\n            <input id="tui-full-calendar-schedule-start-date" class="tui-full-calendar-content" placeholder="Date début">\r\n            <div id="tui-full-calendar-startpicker-container" style="margin-left: -1px; position: relative"></div>\r\n        </div>\r\n        <span class="tui-full-calendar-section-date-dash">-</span>\r\n        <div class="tui-full-calendar-popup-section-item tui-full-calendar-section-end-date">\r\n            <span class="tui-full-calendar-icon tui-full-calendar-ic-date"></span>\r\n            <input id="tui-full-calendar-schedule-end-date" class="tui-full-calendar-content" placeholder="Date fin">\r\n            <div id="tui-full-calendar-endpicker-container" style="margin-left: -1px; position: relative"></div>\r\n        </div>\r\n        <div class="tui-full-calendar-popup-section-item tui-full-calendar-section-allday mt-2">\r\n            <input id="tui-full-calendar-schedule-allday" type="checkbox" class="tui-full-calendar-checkbox-square" ' +
((__t = (data.isAllDay ? 'checked' : '')) == null ? '' : __t) +
'>\r\n            <span class="tui-full-calendar-icon tui-full-calendar-ic-checkbox"></span>\r\n            <span class="tui-full-calendar-content">Journée Entière</span>\r\n        </div>\r\n    </div>\r\n    <button class="tui-full-calendar-button tui-full-calendar-popup-close"><span class="tui-full-calendar-icon tui-full-calendar-ic-close"></span></button>\r\n    <div class="tui-full-calendar-section-button-save">\r\n        <button class="tui-full-calendar-button tui-full-calendar-confirm tui-full-calendar-popup-save bg-secondary">\r\n            <span>' +
((__t = (isUpdate ? 'Mise à jour' : 'Sauvegarder')) == null ? '' : __t) +
'</span>\r\n        </button>\r\n        <button class="tui-full-calendar-button tui-full-calendar-confirm tui-full-calendar-popup-more-options bg-secondary">\r\n            <span>Plus Options</span>\r\n        </button>\r\n    </div>\r\n</div>\r\n<div id="tui-full-calendar-popup-arrow" class="tui-full-calendar-popup-arrow tui-full-calendar-arrow-bottom">\r\n    <div class="tui-full-calendar-popup-arrow-border">\r\n        <div class="tui-full-calendar-popup-arrow-fill"></div>\r\n    </div>\r\n</div>';

}
return __p
}
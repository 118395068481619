module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="w-100" style="height:50px">\r\n	';
if(data.providerData.logo){;
__p += '\r\n	<img class="h-100" src="' +
((__t = (global.COUCHDB_URL)) == null ? '' : __t) +
'/tiers/' +
((__t = (data.providerData._id)) == null ? '' : __t) +
'/' +
((__t = (data.providerData.logo)) == null ? '' : __t) +
'">\r\n	';
};
__p += '\r\n</div>\r\n\r\n\r\n<div class="w-100 my-3">\r\n    <div>N° facture : ' +
((__t = (data.billNumber)) == null ? '' : __t) +
'</div>\r\n	<div>Fait à ' +
((__t = (data.providerData.city)) == null ? '' : __t) +
', le ' +
((__t = (data.dateString)) == null ? '' : __t) +
'</div>\r\n	<div>Numero de lot: ' +
((__t = (data.fxlCode)) == null ? '' : __t) +
'</div>\r\n</div>\r\n\r\n<div class="row mb-3">\r\n    <div class="col-6">\r\n        <div class="text-muted">Fournisseur : </div>\r\n        <div class="font-bold">' +
((__t = (data.providerData.name)) == null ? '' : __t) +
'</div>\r\n        ';
 if (!data.providerData.bill) { ;
__p += '\r\n	<div>' +
((__t = (data.providerData.address)) == null ? '' : __t) +
'</div>\r\n	<div>' +
((__t = (data.providerData.zipCode)) == null ? '' : __t) +
' ' +
((__t = (data.providerData.city)) == null ? '' : __t) +
'</div>\r\n	';
 } ;
__p += '\r\n	';
 if (data.providerData.bill) { ;
__p += '\r\n	<div>' +
((__t = (data.providerData.bill.address)) == null ? '' : __t) +
'</div>\r\n	<div>' +
((__t = (data.providerData.bill.zipCode)) == null ? '' : __t) +
' ' +
((__t = (data.providerData.bill.city)) == null ? '' : __t) +
'</div>\r\n	';
 } ;
__p += '\r\n	<div>Tél : ' +
((__t = (data.providerData.phone)) == null ? '' : __t) +
'</div>\r\n	<div>N° Siret : ' +
((__t = (data.providerData.siret)) == null ? '' : __t) +
'</div>\r\n</div>\r\n\r\n\r\n\r\n<div class="col-6">\r\n	<div class="text-muted">Client : </div>\r\n	<div class="font-bold">' +
((__t = (data.customerData.name)) == null ? '' : __t) +
'</div>\r\n	<div>' +
((__t = (data.customerData.address)) == null ? '' : __t) +
'</div>\r\n	<div>' +
((__t = (data.customerData.zipCode)) == null ? '' : __t) +
' ' +
((__t = (data.customerData.city)) == null ? '' : __t) +
'</div>\r\n</div>\r\n</div>\r\n\r\n<div class="w-100 my-2">\r\n	Bon commande :\r\n	<div class="orders-numbers">\r\n		';
let orders = data.orders || [];
__p += '\r\n		';
if(orders.length){
			for(let order of orders){
		;
__p += '\r\n				<span class="badge rounded-pill">' +
((__t = (order)) == null ? '' : __t) +
'</span>\r\n		';
}};
__p += '\r\n	</div>\r\n</div>\r\n\r\n<div class="w-100 my-2">\r\n	Bons livraison :\r\n\r\n	';
if(data.deliveryNotes.length){ ;
__p += '\r\n	<div class="delivery-notes-numbers">\r\n		';
let deliveryNotes = data.deliveryNotes || []
		  for(let deliveryNote of deliveryNotes){
		;
__p += '\r\n				<span class="badge rounded-pill">' +
((__t = (deliveryNote)) == null ? '' : __t) +
'</span>\r\n		';
};
__p += '\r\n	</div>\r\n	';
}else{;
__p += '\r\n		<span class="text-muted">Aucun</span>\r\n	';
};
__p += '\r\n\r\n</div>\r\n\r\n<div class="w-100 my-2">\r\n	Bons intervention : \r\n	';
if(data.workOrders.length){;
__p += '\r\n	<div class="work-orders-numbers">\r\n		';
let workOrders = data.workOrders || []
			for(let workOrder of workOrders){
		;
__p += '\r\n				<span class="badge rounded-pill">' +
((__t = (workOrder)) == null ? '' : __t) +
'</span>\r\n		';
};
__p += '\r\n	</div>\r\n	';
}else{;
__p += '\r\n		<span class="text-muted">Aucun</span>\r\n	';
};
__p += '\r\n</div>\r\n\r\n<div class="w-100 my-2">\r\n    Piece jointe :\r\n    ';
if(data._attachments){;
__p += '\r\n	';
for(let name in data._attachments){;
__p += '\r\n	<button class="btn btn-outline-secondary btn-sm btn-rounded mr-2"\r\n		data-url="' +
((__t = (files_url)) == null ? '' : __t) +
'/bills/' +
((__t = (data._id)) == null ? '' : __t) +
'/' +
((__t = (name)) == null ? '' : __t) +
'">\r\n		' +
((__t = (name)) == null ? '' : __t) +
'\r\n	</button>\r\n	';
};
__p += '\r\n    ';
};
__p += '\r\n</div>\r\n\r\n<div class="w-100">\r\n	Observation : \r\n\r\n	';
if(data.observation){;
__p += '\r\n	' +
((__t = (data.observation)) == null ? '' : __t) +
'\r\n	';
}else{;
__p += '\r\n	<span class="text-muted">Aucune</span>\r\n	';
};
__p += '\r\n\r\n</div>\r\n\r\n<div class="w-100 border-bottom pb-2 mt-2 ">\r\n    Références :\r\n</div>\r\n\r\n<div class="w-100">\r\n\r\n    <table class="table table-bordered text-center">\r\n\r\n        <thead class="thead-light">\r\n            <tr>\r\n                <th> Reference Produit </th>\r\n                <th> %MS </th>\r\n                <th> Quantité </th>\r\n                <th> Unité </th>\r\n                <th> PU HT </th>\r\n                <th> Total HT </th>\r\n                <th> TVA % </th>\r\n                <th> TVA </th>\r\n                <th colspan="2"> Total TTC </th>\r\n            </tr>\r\n        </thead>\r\n\r\n        <tbody>\r\n            ';
for(let item of data.products){;
__p += '\r\n\r\n	<tr>\r\n		<td> ' +
((__t = (item.reference)) == null ? '' : __t) +
' </td>\r\n		<td> ' +
((__t = (_.get(item, 'qualityCriteria.potentialMethane'))) == null ? '' : __t) +
' </td>\r\n		<td> ' +
((__t = (item.quantity)) == null ? '' : __t) +
' </td>\r\n		<td> ' +
((__t = (item.unit)) == null ? '' : __t) +
' </td>\r\n		<td> ' +
((__t = (item.unitPrice)) == null ? '' : __t) +
'€</td>\r\n		<td> ' +
((__t = (_.round(item.ttcTotal - item.tvaPrice,2))) == null ? '' : __t) +
'€</td>\r\n		<td> ' +
((__t = (item.vatRate)) == null ? '' : __t) +
'%</td>\r\n		<td> ' +
((__t = (item.tvaPrice)) == null ? '' : __t) +
'€</td>\r\n		<td class="text-right" colspan="2"> ' +
((__t = (item.ttcTotal)) == null ? '' : __t) +
'€</td>\r\n	</tr>\r\n\r\n	';
};
__p += '\r\n\r\n            <tr>\r\n                <td colspan="8" class="align-middle"  rowspan="' +
((__t = (Object.keys(total).length + 1)) == null ? '' : __t) +
'">\r\n                    ' +
((__t = ((data.footerText || '').replace(/\n/g, '<br>'))) == null ? '' : __t) +
'\r\n                </td>\r\n                <td class="font-bold">Total HT</td>\r\n                <td class="text-right">' +
((__t = (total.sumHT)) == null ? '' : __t) +
'€</td>\r\n            </tr>\r\n\r\n            ';
for(let tva in total.tva){;
__p += '\r\n	<tr>\r\n\r\n		<td class="font-bold">Total TVA ' +
((__t = (tva)) == null ? '' : __t) +
'%</td>\r\n		<td class="text-right">' +
((__t = (total.tva[tva])) == null ? '' : __t) +
'€</td>\r\n	</tr>\r\n	';
};
__p += '\r\n\r\n            <tr>\r\n\r\n                <td class="font-bold">Total TTC</td>\r\n                <td class="text-right">' +
((__t = (total.sum)) == null ? '' : __t) +
'€</td>\r\n            </tr>\r\n\r\n        </tbody>\r\n\r\n    </table>\r\n\r\n</div>\r\n\r\n<div class="w-100">\r\n\r\n	<h5> Changements </h5>\r\n	<hr class="border-green">\r\n\r\n	<div class="w-100" id="events-bills"></div>\r\n\r\n</div>\r\n';

}
return __p
}
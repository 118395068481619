import { Tab, toaster, LoggedUser } from "@autoprog/core-client"

import UsersService from '@js/libs/services/UsersService'
import User from '@js/types/user'

class NotificationTab extends Tab {
	private user!: User

	constructor(private el: HTMLElement) {
		super(el)
		this.init()
	}

	private async init() {
		await this.loadData()
		await this.loadComponent()
	}


	private async loadData() {
		// user
		this.user = await UsersService.getInstance().getByID(LoggedUser.getInstance().get("ID"))
	}

	private async loadComponent() {
		const N_checkboxValidationOrder = this.el.querySelector('#checkbox-mail-order-validation') as HTMLInputElement
		N_checkboxValidationOrder.addEventListener('change', this.checkboxValidationOrderEvent.bind(this))

		const N_checkboxPrevalidationOrder =  this.el.querySelector('#checkbox-mail-order-prevalidation') as HTMLInputElement
		N_checkboxPrevalidationOrder.addEventListener('change', this.checkboxPrevalidationOrderEvent.bind(this))

		N_checkboxValidationOrder.checked = this.user.has_mail_prevalidation_order
		N_checkboxPrevalidationOrder.checked = this.user.has_notification_mail_prevalidated_order
	}

	private async checkboxValidationOrderEvent(event: Event) {
		const element = event.target as HTMLInputElement
		const hasCheck = element.checked

		const functionToApply = hasCheck ? UsersService.getInstance().activatePrevalidationEmail : UsersService.getInstance().deactivatePrevalidationEmail
		
		try {
			await functionToApply(LoggedUser.getInstance().get('ID'))
			toaster.success('Paramètres sauvegardés')
		} catch (e) {
			toaster.error('Erreur de sauvegarde')
			console.error(e)
		}
	}


	private async checkboxPrevalidationOrderEvent(event: Event) {
		const element = event.target as HTMLInputElement
		const hasCheck = element.checked

		const functionToApply = hasCheck ? UsersService.getInstance().activatePrevalidationEmail : UsersService.getInstance().deactivatePrevalidationEmail
		
		try {
			await functionToApply(LoggedUser.getInstance().get('ID'))
			toaster.success('Paramètres sauvegardés')
		} catch (e) {
			toaster.error('Erreur de sauvegarde')
			console.error(e)
		}
	}

	public destructor(): void {

	}


}

export default NotificationTab;
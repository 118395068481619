import AddEditHoursSheetModal from '@libs/modals/humanRessources/AddEditHoursSheetModal'
import DetailHoursSheetModal from '@libs/modals/humanRessources/DetailHoursSheetModal'
import { Grid, GridOptions, ColDef, IAggFuncParams, ICellRendererParams } from 'ag-grid-community'
import 'ag-grid-enterprise'
import moment, { Moment } from 'moment'
import toastr from 'toastr'
import 'daterangepicker'
import 'daterangepicker/daterangepicker.css'
import { Hours } from '@js/types/hours'
import User from '@js/types/user'
import 'moment-ferie-fr'

import h from 'hyperscript'
import { global, Tab, LoggedUser } from "@autoprog/core-client"
import AgGridStateSaver from '@libs/agGrid/StateSaver2'

import drpC from '@libs/utils/daterangepickerConfig'
import { AGGridUtils, french as agGridFrench } from '@libs/utils/agGrid'

import Export from '@libs/agGrid/Export'

import { HoursCategories, HoursService, AnalyticsService, SitesService, CalendarHolidayService } from "@js/libs/services"

import _ from 'lodash'

import CSS_hoursReport from '@css/hoursReport.scss?string'
import UsersService from '@js/libs/services/UsersService'
import CalendarHoliday from "@js/types/calendar-holiday"

class TabHourSheet extends Tab {

	private gridOptions: GridOptions = {}
	private N_addHoursSheet: HTMLButtonElement
	private startDate: Moment = moment().startOf('month')
	private endDate: Moment = moment().endOf('month')
	private listeners: EventListenerCanceller[] = []
	private site: string
	private loggedUser: LoggedUser
	private excelStyles: any[]
	private usedColumnsIDs: Set<string>

	private stateAGrid?: AgGridStateSaver

	private calendarHolidays: Array<CalendarHoliday>

	constructor(private el: HTMLElement) {
		super(el)
		this.site = global.SITE
		this.loggedUser = LoggedUser.getInstance()
		this.usedColumnsIDs = new Set()
		this.calendarHolidays = []

		this.N_addHoursSheet = el.querySelector('#button-add-hour-sheet') as HTMLButtonElement

		this.listeners.push(this.N_addHoursSheet.addEventListener2('click', () => {
			const modal = new AddEditHoursSheetModal();
			modal.open().then(() => {
				this.updateGrid();
			})
		}))

		$(this.el).find('#date-picker').daterangepicker(drpC({
			startDate: this.startDate,
			endDate: this.endDate,
			opens: "center",
		}), (start, end, _label) => {

			this.startDate = start.startOf('day')
			this.endDate = end.endOf('day')

			this.loadCalendarHolidays()
				.then(() => {
					this.saveDates()
					this.updateGrid()
				})
		})


		this.excelStyles = [

			{
				id: 'cell',
				alignment: {
					horizontal: 'Center'
				},
				font: {
					size: 10
				},
				borders: {
					borderBottom: {
						color: '#000000',
						lineStyle: 'Continuous',
						weight: 1,
					},
					borderLeft: {
						color: '#000000',
						lineStyle: 'Continuous',
						weight: 1,
					},
					borderRight: {
						color: '#000000',
						lineStyle: 'Continuous',
						weight: 1,
					},
					borderTop: {
						color: '#000000',
						lineStyle: 'Continuous',
						weight: 1,
					},
				},
			},
			{
				id: 'header',
				interior: {
					color: '#CCCCCC',
					pattern: 'Solid',
				},
				font: {
					size: 12
				},
				borders: {
					borderBottom: {
						color: '#000000',
						lineStyle: 'Continuous',
						weight: 1,
					},
					borderLeft: {
						color: '#000000',
						lineStyle: 'Continuous',
						weight: 1,
					},
					borderRight: {
						color: '#000000',
						lineStyle: 'Continuous',
						weight: 1,
					},
					borderTop: {
						color: '#000000',
						lineStyle: 'Continuous',
						weight: 1,
					},
				},
			}
		]
		this.initGrid()
		this.loadCalendarHolidays()
	}

	private async loadCalendarHolidays() {
		this.calendarHolidays = await CalendarHolidayService.getInstance().getByDates(+this.startDate.format('x'), +this.endDate.format('x'))
	}

	private isHoliday(date: number): boolean {
		return this.calendarHolidays.some(calendarHoliday => moment(calendarHoliday.day).startOf('day').diff(moment(date).startOf('day'), 'days') === 0)
	}

	private async initGrid() {

		this.gridOptions = agGridFrench({

			columnDefs: [
				{
					headerName: "Utilisateur",
					field: "user",
					rowGroup: true,
					rowGroupIndex: 0,
					hide: true,
					width: 170,
					checkboxSelection: true,
					valueGetter: (params) => {

						if (params.data && params.data.user) {
							if (params.data.user.firstname || params.data.user.lastname) {
								return (params.data.user.firstname || '') + ' ' + (params.data.user.lastname || '')
							}

							return params.data.user || '';
						}

						return ''
					}
				},
				{
					headerName: "Mois",
					field: "day",
					width: 70,
					rowGroup: true,
					rowGroupIndex: 1,
					hide: true,
					valueGetter: (params) => {

						if (params.data && !params.data.pinnedRow) {
							const date = moment(params.data.date, 'x').locale('fr')
							return date.format('MMMM')
						}

						return '';
					}
				},
				{
					headerName: "Travaillé",
					field: "total",
					width: 75,
					aggFunc: 'countDays',
					valueGetter: (params: any) => {
						if (params.data && params.data.pinnedRow) {
							return true
						}

						if (!params.pinnedRow && params.data && !params.data.pinnedRow) {

							if (params.data.total) {
								const time = moment.duration(params.data.total, 'ms')
								const hours = time.asHours()
								return hours >= 1; // A travailler au moins une heure pour être valider.
							}

						}

						return false
					},
					cellRenderer: (params) => {

						if (params.value && !params.data) {
							return params.value + " Jour(s)"
						}

						if (params.data && params.data.pinnedRow) {
							return params.data.totalWork + " Jour(s)"
						}

						if (!params.pinnedRow && params.data && !params.data.pinnedRow) {
							return params.value ? 'oui' : 'non'
						}

						return ''
					}
				},
				{
					headerName: "Semaine",
					field: "day",
					width: 85,
					valueGetter: (params) => {

						if (params.data && !params.data.pinnedRow) {
							const date = moment(params.data.date, 'x').locale('fr').isoWeekday(1)
							return date.format('w')
						}

						return ''
					}
				},
				{
					headerName: "Férié",
					field: "date",
					width: 90,
					valueGetter: (params) => {
						if (params.data && !params.data.pinnedRow) {
							return this.isHoliday(params.data.date) ? 'Oui' : 'Non'
						}

						return ''
					},
					cellRenderer: (params) => {
						if (params.type == 'csv' || params.type == 'excel') {
							return params.value;
						}
						else if (!params.data || params.data.pinnedRow) {
							return ''
						}
						else {

							if (params.data && params.data.generate) {
								if (params.value == 'Oui') {
									return '<i class="icon icon-solid-check"></i>'
								} else if (params.value == 'Non') {
									return '<i class="icon icon-solid-times"></i>'
								}
							}
							else {
								if (params.value == 'Oui') {
									return '<i class="icon icon-solid-check text-green"></i>'
								} else if (params.value == 'Non') {
									return '<i class="icon icon-solid-times text-red"></i>'
								}
							}

						}
						return '';
					},
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filter: 'agSetColumnFilter',
				},
				{
					headerName: "Jour",
					field: "day",
					width: 75,
					valueGetter: (params) => {

						if (params.data && !params.data.pinnedRow) {
							let date = moment(params.data.date, 'x').locale('fr');
							return date.format('dddd');
						}
						return '';
					}
				},
				{
					headerName: "Date",
					field: "date",
					sort: 'asc',
					width: 90,
					valueGetter: (params) => {
						if (params.data && !params.data.pinnedRow) {
							const date = moment(params.data.date, 'x')
							return date.format('DD/MM/YYYY')
						}

						return '';
					},
					cellRenderer: (params) => {

						if (params.data && (params.type == 'csv' || params.type == 'excel')) {
							//return moment(params.data.date, 'x').format('YYYY-MM-DDTHH:mm:ss.SSS');
							return moment(params.data.date, 'x').format('DD/MM/YYYY')
						}

						return params.value
					}
				},
				{
					headerName: "Total", field: "total", width: 75,
					cellRenderer: this.durationCellRenderer,
					aggFunc: 'sum',
					headerClass: 'multiline'

				},
				{
					headerName: "Total hors-astreinte", field: "totalWithoutStandyDuties", width: 75,
					cellRenderer: this.durationCellRenderer,
					aggFunc: 'sum',
					headerClass: 'multiline'

				},
				{
					headerName: "Total astreinte",
					field: "totalStandbyDuties",
					width: 125,
					cellRenderer: this.durationCellRenderer,
					aggFunc: 'sum',
					headerClass: 'multiline'
				},
				{
					headerName: "Total férié/Dimanche", field: "totalPublicHolidaysSunday", width: 75,
					cellRenderer: this.durationCellRenderer,
					aggFunc: 'sum',
					headerClass: 'multiline'
				},
				{
					headerName: "Km",
					field: "kilometers",
					width: 70,
					aggFunc: 'sum',
					cellClass: (params) => {
						return params.value ? 'excel-value' : 'excel-no-value'
					},
					cellRenderer: (params) => {
						if (params.value) {
							if (params.type == 'csv' || params.type == 'excel') {
								return params.value
							}
							else {
								return _.round(params.value, 2) + ' km'
							}
						}
						return '';
					}
				}, {
					headerName: "Commentaire",
					field: "comment",
					width: 200,
					cellClass: (params) => {
						return params.value ? 'excel-value' : 'excel-no-value'
					}
				},
				{
					headerName: 'Controles',
					field: 'seeMore',
					filter: false,
					sortable: false,
					colId: 'actions',
					pinned: 'right',
					cellRenderer: (params: ICellRendererParams) => {
						if ((params as { type?: string }).type === 'csv' || (params as { type?: string }).type === 'excel') {
							return '';
						}

						if (params.data && params.data.user) {

							const div = document.createElement('div')

							const infoButton = document.createElement('button');

							infoButton.classList.add('btn');
							infoButton.classList.add('btn-xs');
							infoButton.classList.add('btn-info');
							infoButton.innerHTML = '<i class="icon icon-solid-eye"> </i>';

							infoButton.addEventListener('click', () => {

								const modal = new DetailHoursSheetModal(params.data._id);
								modal.open().then(edited => {
									edited && this.updateGrid()
								})
							})

							const deleteButton = document.createElement('button');

							deleteButton.classList.add('btn', 'btn-xs', 'btn-danger');
							deleteButton.setAttribute('confirmation', "");

							if (params.data.user._id == this.loggedUser.get('ID')) {
								deleteButton.setAttribute('permission', "HUMAN_RESSOURCES.HOURS.DELETE");
							} else {
								deleteButton.setAttribute('permission', "HUMAN_RESSOURCES.HOURS.DELETE_ALL_USERS");
							}

							deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"> </i>';

							deleteButton.addEventListener2('click', async () => {
								await this.deleteHours(params.data)
								params.api.applyTransaction({ remove: [params.data] })
							})

							const lockButton = document.createElement('button');

							lockButton.classList.add('btn', 'btn-xs');
							lockButton.setAttribute('confirmation', "");

							if (params.data.user._id == this.loggedUser.get('ID')) {
								lockButton.setAttribute('permission', "HUMAN_RESSOURCES.HOURS.LOCK");
							}
							else {
								lockButton.setAttribute('permission', "HUMAN_RESSOURCES.HOURS.LOCK_ALL_USERS");
							}

							if (params.data.locked) {
								lockButton.innerHTML = '<i class="icon icon-solid-lock"> </i>';
								lockButton.classList.add('btn-dark');
							}
							else {
								lockButton.innerHTML = '<i class="icon icon-solid-unlock-alt"> </i>';
								lockButton.classList.add('btn-blue-grey');
							}

							lockButton.addEventListener2('click', () => {

								this.unlock_lockHourSheet(params.data._id).then((newData) => {
									toastr.success("Heure modifié");

									params.data.locked = newData.locked;

									params.node.setData(params.data)
									params.api.redrawRows({ rowNodes: [params.node] });
								}).catch((e) => {
									console.error(e);
									toastr.error('Erreur de base de données');
								})

							});

							if (!params.data.generate) {

								div.appendChild(infoButton);

								div.appendChild(lockButton);
								div.appendChild(deleteButton);

							}

							return div

						}

						return ''
					}
				}
			],
			autoGroupColumnDef: {
				headerName: 'Employes',
				pinned: 'left',
				checkboxSelection: true
			},
			rowSelection: 'multiple',
			groupSelectsChildren: true,
			rowMultiSelectWithClick: true,
			suppressDragLeaveHidesColumns: true,
			groupRowRenderer: 'agGroupCellRenderer',
			groupRowRendererParams: {
				checkbox: true,
			},
			getContextMenuItems: (params) => {
				if (params.api) {
					const seletedNodes = params.api.getSelectedNodes()

					const lockedModification = {
						name: "Bloquer les modifications",
						action: () => {

							const promises = []

							for (let node of seletedNodes) {
								promises.push(this.unlock_lockHourSheet(node.data._id, { locked: false }).then((newData) => {
									node.setData(newData)
									params.api && params.api.redrawRows({ rowNodes: [node] })
								}))
							}

							Promise.all(promises).then(() => toastr.success("Modification(s) bloquée(s)"))
						}
					}

					const unlockedModification = {
						name: "Débloquer les modifications",
						action: () => {

							const promises = [];

							for (let node of seletedNodes) {
								promises.push(this.unlock_lockHourSheet(node.data._id, { locked: true }).then((newData) => {
									node.setData(newData)
									if (params.api) {
										params.api.redrawRows({ rowNodes: [node] });
									}
								}))
							}

							Promise.all(promises).then(() => toastr.success("Modification(s) débloquée(s)"))
						}
					}

					if (params.columnApi) {

						const columnKeys = AGGridUtils.getColumnsIds(this.gridOptions, 'actions')

						const exportAllExcel = {
							name: 'Exporter tous Excel',
							subMenu: [
								{
									name: 'Format Heure',
									action: () => {
										let exp = new Export(params, {
											type: 'hours'
										});
										exp.exportExcel({
											fileName: `Heures - ${global.SITE} - ${moment().format('YYYY-MM-DD')}`,
											columnGroups: true,
											columnKeys
										});
									}
								}, {

									name: 'Format Numérique',
									action: () => {
										let exp = new Export(params, {
											type: 'number'
										});
										exp.exportExcel({
											fileName: `Heures - ${global.SITE} - ${moment().format('YYYY-MM-DD')}`,
											columnGroups: true,
											columnKeys
										});
									}

								}
							]

						};


						const exportAllCSV = {
							name: 'Exporter tous CSV',
							action: () => {
								let exp = new Export(params);
								exp.exportCSV({
									fileName: `Heures - ${global.SITE} - ${moment().format('YYYY-MM-DD')}`,
									columnKeys
								})
							}
						}

						const exportSelectedCSV = {
							name: "Exporter selection CSV",
							action: () => {
								if (params.api) {
									let exp = new Export(params);
									exp.exportCSV({
										fileName: `Heures - ${global.SITE} - ${moment().format('YYYY-MM-DD')}`,
										columnKeys,
										onlySelected: true
									});
								}
							}
						}

						const exportSelectedExcel = {
							name: "Exporter selection Excel",
							action: () => {
								let exp = new Export(params);
								exp.exportExcel({
									fileName: `Heures - ${global.SITE} - ${moment().format('YYYY-MM-DD')}`,
									columnGroups: true,
									columnKeys,
									onlySelected: true
								});
							}
						}

						const print = {
							name: "Imprimer rapport",
							action: () => {
								const report = new HoursReport(this.gridOptions)
								report.generate()
							}
						};

						let result = []

						if (seletedNodes.length > 0) {

							let locked = 0;
							let unlocked = 0;

							for (let node of seletedNodes) {
								if (node.data.locked) {
									locked++;
								}
								if (!node.data.locked) {
									unlocked++;
								}
							}

							if (this.loggedUser.hasPermission('HUMAN_RESSOURCES.HOURS.LOCK')) {
								if (unlocked > 0) {
									result.push(lockedModification);
								}
								if (locked > 0) {
									result.push(unlockedModification);
								}
							}

							if (this.loggedUser.hasPermission('HUMAN_RESSOURCES.HOURS.EXPORT')) {
								result.push(exportAllCSV, exportSelectedCSV, exportAllExcel, exportSelectedExcel);
							}

						} else {

							if (this.loggedUser.hasPermission('HUMAN_RESSOURCES.HOURS.EXPORT')) {
								result.push(exportAllCSV, exportAllExcel);
							}

						}

						result.push(print);

						return result

					}

				}
				return []
			},
			excelStyles: this.excelStyles,
			sideBar: {
				toolPanels: [{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnFilter: true,
					}
				}]
			},
			groupHeaderHeight: 25,
			headerHeight: 100,
			suppressAggFuncInHeader: true,
			defaultColDef: {
				resizable: true,
				sortable: true,
				filter: 'agTextColumnFilter',
				enableRowGroup: true,
				suppressMenu: true,
				floatingFilter: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				cellClassRules: {
					'cell': () => true
				}
			},
			onGridReady: (_params) => this.updateGrid(),
			onColumnVisible: (params) => {
				if (params.column && params.column.getColId() == 'seeMore') {
					if (params.columnApi) {
						params.columnApi.setColumnVisible('seeMore', true)
					}
				}
			},
			getRowClass: (params) => {

				const cls: string[] = []
				if (params.data) {

					// const date = moment(params.data.date, 'x').locale('fr')
					const date = moment(params.data.date, 'x')

					if (params.data.locked == true) {
						cls.push('locked')
					}

					if (this.isHoliday(+date.format('x'))) {
						cls.push('publicHoliday')
					} else if (date.isoWeekday() == 7) {
						cls.push('sunday')
					}

					if (params.data.generate) {
						cls.push('text-blue-grey-300')
					}


				}

				return cls
			},
			aggFuncs: {
				countDays: this.aggFuncCountDays
			},
			getRowStyle: (params: any) => {

				if (params.data && params.data.generate) {
					return {
						'line-height': '18px'
					}
				}
			},
			getRowHeight: (params: any) => {

				if (params.data && params.data.generate) {
					return 20;
				}
				return 30
			},
			onRowDataChanged: () => {
				this.updatePinnedRowData()
			},
			onFilterChanged: () => {
				this.updatePinnedRowData()
			}
		})


		await this.loadDates()
		await this.createSitesColumns()

		if (this.loggedUser.hasPermission('HUMAN_RESSOURCES.HOURS.ANALYTICS')) {
			await this.createAnalyticsColumns()
		}

		const N_hours = this.el.querySelector('#hours-grid') as HTMLElement
		new Grid(N_hours, this.gridOptions)
		this.stateAGrid = new AgGridStateSaver(this.gridOptions, `${global.SITE}_hours`, `Onglet Fiche Heures ${global.SITE}`)
		this.stateAGrid.load()
		this.updateGrid()
	}

	private async createSitesColumns() {

		let sitesIDs = _.uniq([global.SITE, ...global.ALLOW_SITES]);

		for (let siteID of sitesIDs) {

			try {

				let site = await SitesService.getInstance().getByID(siteID)

				if (this.gridOptions.columnDefs) {
					let index = this.gridOptions.columnDefs.length - 2;

					let hoursChildren = [];
					let standbyDutiesChildren = [];

					let groups = this.loggedUser.get('groups')
					let userHoursCategories = new Set(_.flattenDeep(_.map(groups, 'hoursCategories')))

					let cats = HoursCategories.getInstance().getAll()

					for (let cat of cats) {
						if (userHoursCategories.has(cat._id) || this.loggedUser.hasPermission('HUMAN_RESSOURCES.HOURS.SHOW_ALL_CAT')) {
							hoursChildren.push({
								headerName: cat.label,
								field: 'stats.' + site._id + '.' + cat._id + '.hours',
								width: 60,
								cellRenderer: this.durationCellRenderer,
								headerClass: `text-vertical multiline bg-${site.color && site.color[0]}-100`,
								aggFunc: 'sum',
								columnGroupShow: 'open',
								cellClass: (params: any) => {
									return [`bg-${site.color && site.color[0]}-50`,
									params.value ? 'excel-value' : 'excel-no-value'
									].join(' ');
								}
							});

							standbyDutiesChildren.push({
								headerName: cat.label,
								field: 'stats.' + site._id + '.' + cat._id + '.standbyDuties',
								width: 60,
								cellRenderer: this.durationCellRenderer,
								headerClass: `text-vertical multiline bg-${site.color && site.color[0]}-200`,
								aggFunc: 'sum',
								columnGroupShow: 'open',
								cellClass: (params: any) => {
									return [`bg-${site.color && site.color[0]}-50`,
									params.value ? 'excel-value' : 'excel-no-value'
									].join(' ');
								}
							});
						}

					}

					this.gridOptions.columnDefs.splice(index, 0, {
						headerName: site.name,
						headerClass: `bg-${site.color && site.color[0]}-100`,
						marryChildren: true,
						children: [
							{
								headerName: 'Total',
								headerClass: `bg-${site.color && site.color[0]}-100`,
								field: 'stats.' + site._id + '.' + 'total.total',
								width: 100,
								cellRenderer: this.durationCellRenderer,
								aggFunc: 'sum',
								hide: false

							}, {
								headerName: 'Heures',
								headerClass: `bg-${site.color && site.color[0]}-100`,
								marryChildren: true,
								children: [{
									headerName: 'Total',
									field: 'stats.' + site._id + '.' + 'total.hours',
									width: 100,
									cellRenderer: this.durationCellRenderer,
									headerClass: `bg-${site.color && site.color[0]}-100`,
									aggFunc: 'sum',
									cellClass: (params: any) => {
										return [`bg-${site.color && site.color[0]}-50`,
										params.value ? 'excel-value' : 'excel-no-value'
										].join(' ');
									}
								}, ...hoursChildren]
							}, {
								headerName: 'Astreinte',
								headerClass: `bg-${site.color && site.color[0]}-200`,
								marryChildren: true,
								children: [{
									headerName: 'Total',
									field: 'stats.' + site._id + '.' + 'total.standbyDuties',
									width: 100,
									cellRenderer: this.durationCellRenderer,
									headerClass: `bg-${site.color && site.color[0]}-200`,
									aggFunc: 'sum',
									//columnGroupShow: 'closed',
									cellClass: (params: any) => {
										return [`bg-${site.color && site.color[0]}-50`,
										params.value ? 'excel-value' : 'excel-no-value'
										].join(' ');
									}
								}, ...standbyDutiesChildren]
							}
						]
					} as any);

				}
			} catch (e) {
				console.error(e);
			}
		}


	}

	private async createAnalyticsColumns() {
		let analyticsService = AnalyticsService.getInstance()
		let analytics = analyticsService.getAll();

		let analyticsColDefs: ColDef[] = [];
		let subAnalyticsColDefs: ColDef[] = [];

		for (let analytic of analytics) {
			let col = {
				headerName: analytic.name,
				width: 60,
				field: '',
				cellRenderer: this.durationCellRenderer,
				headerClass: `text-vertical multiline`,
				aggFunc: 'sum'
			}

			col.field = `subAnalytics.${analytic._id}`;
			subAnalyticsColDefs.push(_.clone(col));
			col.field = `analytics.${analytic._id}`;
			analyticsColDefs.push(_.clone(col));

		}

		if (this.gridOptions.columnDefs) {
			let index = this.gridOptions.columnDefs.length - 2;
			this.gridOptions.columnDefs.splice(index, 0, {
				headerName: 'Analytiques',
				children: analyticsColDefs
			});
			this.gridOptions.columnDefs.splice(index, 0, {
				headerName: 'Sous Analytiques',
				children: subAnalyticsColDefs
			})
		}
	}


	public updateSelectSite(value: string) {
		this.stateAGrid && this.stateAGrid.save()
		this.stateAGrid = new AgGridStateSaver(this.gridOptions, `${global.SITE}_hours`, `Onglet Fiche Heures ${global.SITE}`)
		this.stateAGrid.load()
		this.loadDates()
		this.site = value
		this.updateGrid()
	}

	private async updatePinnedRowData() {

		let row: { [key: string]: string | number | boolean } = {
			pinnedRow: true,
			total: 0,
			totalWithoutStandyDuties: 0,
			totalStandbyDuties: 0,
			totalPublicHolidaysSunday: 0,
			kilometers: 0,
			totalWork: 0
		};

		if (this.gridOptions.api) {

			let sites = await SitesService.getInstance().getAllSites()

			let totalStats: { [key: string]: string | number } = {}

			let hoursCategories = HoursCategories.getInstance().getAll()

			for (let site of sites) {
				for (let hoursCategory of hoursCategories) {
					let hKey = 'stats.' + site._id + '.' + hoursCategory._id + '.hours'
					let sdKey = 'stats.' + site._id + '.' + hoursCategory._id + '.standbyDuties'
					totalStats[hKey] = 0
					totalStats[sdKey] = 0
				}
			}

			this.gridOptions.api.forEachNodeAfterFilter((node) => {

				let data = _.cloneDeep(node.data);

				if (data) {
					for (let site of sites) {
						for (let hoursCategory of hoursCategories) {
							let hKey = 'stats.' + site._id + '.' + hoursCategory._id + '.hours'
							let sdKey = 'stats.' + site._id + '.' + hoursCategory._id + '.standbyDuties'
							totalStats[hKey] += _.get(data, hKey) || 0
							totalStats[sdKey] += _.get(data, sdKey) || 0
						}
					}

					row.total += data.total || 0;
					row.totalWithoutStandyDuties += data.totalWithoutStandyDuties || 0
					row.totalStandbyDuties += data.totalStandbyDuties || 0
					row.totalPublicHolidaysSunday += data.totalPublicHolidaysSunday || 0
					row.kilometers += data.kilometers || 0
					row.totalWork += ((data.total > 3600000) ? 1 : 0) as any
				}

			});

			for (let key in totalStats) {
				_.set(row, key, totalStats[key]);
			}


			this.gridOptions.api.setPinnedBottomRowData([row]);

		}

	}

	private aggFuncCountDays(params: IAggFuncParams): number {
		let sum = 0;

		// Tableau de booleens
		params.values.forEach(v => {
			sum += v ? 1 : 0;
		});

		// Ce cas arrive quand on a un groupement de groupement. A côté du prénom, on trouve la quantité de jours corrrespondant à ce groupement.
		if (typeof params.values[0] === 'number') {
			sum = _.sum(params.values);
		}

		return sum
	}

	private async getRowData(): Promise<any[]> {

		let userId = this.loggedUser.hasPermission('HUMAN_RESSOURCES.HOURS.SHOW_ALL_USERS') ? null : this.loggedUser.get('ID')
		let rows: Array<any> = await HoursService.getInstance().getHours(this.site, +this.startDate.format('x'), +this.endDate.format('x'), userId)

		//Ensemble regroupant les utilisateurs
		this.usedColumnsIDs.clear()

		for (let row of rows) {

			row.totalWithoutStandyDuties = 0;
			row.totalStandbyDuties = 0;
			row.totalPublicHolidaysSunday = 0;
			row.totalWithoutStandyDuties = row.total;
			row.totalStandbyDuties = row.totalStandbyDutys;
			row.analytics = [];
			row.subAnalytics = [];

			if (this.isHoliday(row.date) || moment(row.date, 'x').day() === 0) {
				row.totalPublicHolidaysSunday = row.totalWithoutStandyDuties;
				row.totalWithoutStandyDuties = 0;
			}

			if (row.stats) {

				for (let siteID in row.stats) {
					let values = _.values(row.stats[siteID]);

					row.stats[siteID].total = {
						hours: _.sum(_.map(values, 'hours')),
						standbyDuties: _.sum(_.map(values, 'standbyDuties')),
						total: _.sum(_.map(values, 'hours')) + _.sum(_.map(values, 'standbyDuties'))
					}

					this.usedColumnsIDs.add(`stats.${siteID}.total.total`);
					this.usedColumnsIDs.add(`stats.${siteID}.total.hours`);
					this.usedColumnsIDs.add(`stats.${siteID}.total.standbyDuties`);


					for (let hoursCategoryID in row.stats[siteID]) {


						if (row.stats[siteID][hoursCategoryID].hours) {
							this.usedColumnsIDs.add(`stats.${siteID}.${hoursCategoryID}.hours`);
						}
						if (row.stats[siteID][hoursCategoryID].standbyDuties) {
							this.usedColumnsIDs.add(`stats.${siteID}.${hoursCategoryID}.standbyDuties`);
						}


						let hoursCategory = HoursCategories.getInstance().getById(hoursCategoryID);
						if (hoursCategory) {

							if (hoursCategory.analytics) {
								for (let hourAnalytic of hoursCategory.analytics) {
									this.usedColumnsIDs.add(`analytics.${hourAnalytic.id}`);
									row.analytics[hourAnalytic.id] = row.analytics[hourAnalytic.id] || 0;
									row.analytics[hourAnalytic.id] += ((row.stats[siteID][hoursCategoryID].hours * hourAnalytic.percent) / 100);
									row.analytics[hourAnalytic.id] += ((row.stats[siteID][hoursCategoryID].standbyDuties * hourAnalytic.percent) / 100);
								}
							}

							if (hoursCategory.subAnalytics) {
								for (let hourSubAnalytic of hoursCategory.subAnalytics) {
									this.usedColumnsIDs.add(`subAnalytics.${hourSubAnalytic.id}`);
									row.subAnalytics[hourSubAnalytic.id] = row.subAnalytics[hourSubAnalytic.id] || 0;
									row.subAnalytics[hourSubAnalytic.id] += ((row.stats[siteID][hoursCategoryID].hours * hourSubAnalytic.percent) / 100);
									row.subAnalytics[hourSubAnalytic.id] += ((row.stats[siteID][hoursCategoryID].standbyDuties * hourSubAnalytic.percent) / 100);
								}
							}

						}
					}
				}
			}

			row.total = row.totalWithoutStandyDuties + row.totalStandbyDuties + row.totalPublicHolidaysSunday;
			row.tmp = moment(row.date, 'x').format('DD_MM_YYYY') + '_' + row.user;
		}

		let userIds = Array.from(new Set<string>(rows.map(row => row.user)))
		let users = await UsersService.getInstance().getByIds(userIds)
		let mapUsers = users.reduce((mu, user) => mu.set(user._id, user), new Map<string, User>())


		for (let user of users) {

			let startDate = this.startDate.clone();

			while (startDate.isSameOrBefore(this.endDate)) {

				const tmpID = startDate.format('DD_MM_YYYY') + '_' + user._id
				if (rows.findIndex(row => row.tmp === tmpID) < 0) {

					rows.push({
						date: startDate.format('x'),
						generate: true,
						user
					})
				}

				startDate.add(1, 'day')
			}

		}

		for (let i in rows) {
			let row = rows[i];
			row.user = mapUsers.has(row.user) ? mapUsers.get(row.user) : row.user;
		}

		return rows
	}

	public destructor() {

		if (this.gridOptions.api) {
			this.stateAGrid && this.stateAGrid.save()
			this.gridOptions.api.destroy();
		}

		for (let canceller of this.listeners) {
			canceller();
		}

		this.saveDates();

		let N_daterangepicker = document.querySelectorAll('.daterangepicker');

		N_daterangepicker.forEach((item) => {
			let parent = item.parentNode as HTMLElement;
			parent.removeChild(item);
		});
	}

	private async deleteHours(data: Hours): Promise<void> {

		try {
			await HoursService.getInstance().delete(data._id)
			toastr.success('Fiche d\'heure supprimé')
		} catch (e) {
			toastr.error(`Erreur lors de la supression de la fiche d'heures`);
			console.error(e)
		}

	}





	/**
	 * Permet de fermer tous les colonnes de AG grid s'il y a pas au moins une entrée heure
	 */
	private hideColumnGrid() {
		if (this.gridOptions.columnApi) {
			const columnsStates = this.gridOptions.columnApi.getColumnState()

			const columnsStatesReplaced = columnsStates.map(c => {

				//Si c'est une colonne de statistique
				if (c.colId?.startsWith('stats.') || c.colId?.startsWith('analytics.') || c.colId?.startsWith('subAnalytics.')) {

					if (!this.usedColumnsIDs.has(c.colId)) {
						c.hide = true;
					}
				}

				return c;
			});

			this.gridOptions.columnApi.setColumnState(columnsStatesReplaced)
		}
	}

	private async updateGrid(): Promise<void> {

		try {
			const rowData = await this.getRowData()
			this.gridOptions && this.gridOptions.api && this.gridOptions.columnApi && this.gridOptions.api.setRowData(rowData);

			this.hideColumnGrid()

			const colMove: string[] = [];

			const hoursCategories = HoursCategories.getInstance().getAll();

			colMove.push('stats.' + this.site + '.total.total');
			colMove.push('stats.' + this.site + '.total.hours');
			for (let cat of hoursCategories) {
				colMove.push('stats.' + this.site + '.' + cat._id + '.hours');
			}

			colMove.push('stats.' + this.site + '.total.standbyDuties');
			for (let cat of hoursCategories) {
				colMove.push('stats.' + this.site + '.' + cat._id + '.standbyDuties');
			}

			this.gridOptions.columnApi && this.gridOptions.columnApi.moveColumns(colMove, 14)
		}
		catch (e) {
			console.error(e);
			toastr.error('Erreur de base de données');
		}
	}

	private async unlock_lockHourSheet(id: string, data?: any): Promise<Hours> {
		let hour = await HoursService.getInstance().getByID(id)
		hour.locked = data ? data.locked : !hour.locked
		await HoursService.getInstance().update(hour)
		return hour as unknown as Hours
	}

	private durationCellRenderer(params: any): string | HTMLElement {

		let total = moment.duration(params.value, 'ms');

		let hours = total.asHours();
		let minutes = total.get('minutes');


		let hoursTxt = '';


		if (hours >= 10000) {
			hoursTxt = ('00000' + Math.floor(hours)).slice(-5);
		} else if (hours >= 1000) {
			hoursTxt = ('0000' + Math.floor(hours)).slice(-4);
		} else if (hours >= 100) {
			hoursTxt = ('000' + Math.floor(hours)).slice(-3);
		} else {
			hoursTxt = ('00' + Math.floor(hours)).slice(-2);
		}


		if (params.type == 'csv' || params.type == 'excel') {
			//return moment(total.asMilliseconds(), 'x').format('YYYY-MM-DDTHH:mm:ss.SSS');

			if (params.dataExport && params.dataExport.type == 'hours') {

				return hoursTxt + ':' + ('00' + minutes).slice(-2);

			} else {
				return (Math.floor(hours * 100) / 100).toString()
			}

		}

		let value = hoursTxt + 'h' + ('00' + minutes).slice(-2)


		if (!params.value) {
			return `<span class="text-blue-grey-300">${value}</span>`;
		}

		return value;

	}

	/**
		 * @description Permet de sauvegarder les dates dans le localstorage
		 */
	private saveDates() {

		let identifier = `${global.SITE}_humanressource_dates`;
		let dates = { startDate: this.startDate.format('x'), endDate: this.endDate.format('x') };

		localStorage.setItem(identifier, JSON.stringify(dates));
	}

	/**
	 * @description Permet de récupérer les dates sauvegardées dans le localstorage
	 */
	private loadDates() {
		const identifier = `${global.SITE}_humanressource_dates`;

		// Dans le cas une session de date a été crée
		if (localStorage.getItem(identifier)) {
			const dates = JSON.parse((localStorage.getItem(identifier) as string))

			this.startDate = moment(dates.startDate, 'x')
			this.endDate = moment(dates.endDate, 'x')

			this.loadCalendarHolidays().then(() => {
				// Erreur à laisser en typescript
				// @ts-ignore: Unreachable code error
				$(this.el).find('#date-picker').data('daterangepicker').setStartDate(this.startDate.format('DD/MM/YYYY'))
				// @ts-ignore: Unreachable code error
				$(this.el).find('#date-picker').data('daterangepicker').setEndDate(this.endDate.format('DD/MM/YYYY'))
			})

		}

	}

}


class HoursReport {

	private calendarHolidays: Array<CalendarHoliday>

	public constructor(private gridOptions: GridOptions) {
		this.calendarHolidays = []
	}

	public async generate() {

		if (!this.gridOptions.api) {
			return
		}

		let rows = this.gridOptions.api.getSelectedRows()
		if (!rows.length) {
			this.gridOptions.api.forEachNodeAfterFilter(node => rows.push(node.data))
		}

		//Il peux arriver qu'en fonction des filtres, les lignes ne soit pas ordre chronologique.
		rows = _.compact(_.sortBy(rows, 'date'))

		// Recuperation des jours feries
		this.calendarHolidays = await CalendarHolidayService.getInstance().getByDates(_.first(rows).date, _.last(rows).date)
		const isHoliday = (date: number) => this.calendarHolidays.some(calendarHoliday => moment(calendarHoliday.day).startOf('day').diff(moment(date).startOf('day'), 'days') === 0)

		// TODO: overcomplicated : devrait ameliorer ce code
		const data: Map<string, Map<string, Map<string, any[]>>> = new Map()
		const users: Map<string, User> = new Map()

		for (let row of rows) {
			if (row.user) {
				if (!data.has(row.user._id)) {
					data.set(row.user._id, new Map())
				}
				users.set(row.user._id, row.user);
			}

			if (data.has(row.user._id)) {
				const monthKey = moment(row.date, 'x').locale('fr').format('YYYY-MM');
				const dateKey = moment(row.date, 'x').locale('fr').startOf('isoWeek').format('YYYY-MM-DD');

				const userData = data.get(row.user._id)!

				if (!userData.has(monthKey)) {
					userData.set(monthKey, new Map())
				}

				const monthData = userData.get(monthKey)!
				if (!monthData?.has(dateKey)) {
					monthData.set(dateKey, [])
				}

				let dateData = monthData.get(dateKey)!
				dateData.push(row)
			}

		}

		const roundHour = (hour: number) => _.round((hour || 0) / (1000 * 60 * 60), 2)

		const w = window.open();
		w && w.document.head.appendChild(h('style', CSS_hoursReport))

		for (let [userID, monthMap] of data.entries()) {

			const user = users.get(userID)

			if (!user) {
				continue
			}

			for (let [monthID, dateMap] of monthMap.entries()) {

				let N_thead = h('thead',

					h('tr',
						h('th', { attrs: { rowspan: 2 } }, 'Jour'),
						h('th', { attrs: { rowspan: 2 } }, 'Semaine'),
						h('th', { attrs: { rowspan: 2 } }, 'Férié'),
						h('th', { attrs: { rowspan: 2 } }, 'Date'),
						h('th', { attrs: { rowspan: 2 } }, 'Travaillé'),
						h('th', { attrs: { colspan: 2 } }, 'Total'),
						h('th', { attrs: { rowspan: 2 } }, 'Km'),
						h('th', { attrs: { rowspan: 2 } }, 'Total Heure'),
					),
					h('tr',
						h('th', 'Travail'),
						h('th', 'Astreinte'),
					)
				) as HTMLTableSectionElement;
				const N_table = h('table',
					h('thead',
						h('tr.date',
							h('th', { attrs: { colspan: 9 } }, `${_.upperFirst(moment(monthID, 'YYYY-MM').locale('fr').format('MMMM YYYY'))}`)
						),
						h('tr.user',
							h('th', { attrs: { colspan: 9 } }, `${user.firstname} ${user.lastname}`)
						)
					),
					h('tr.spacer'),
					N_thead
				) as HTMLTableElement;

				N_table.appendChild(h('tr.spacer'))

				let workedCount = 0;
				let totalHours = 0;
				let totalStandbyDuties = 0;
				let totalKilometers = 0;
				let totalPublicHolidaysSunday = 0;

				for (let [dateID, rows] of dateMap.entries()) {

					let N_tbody = h('tbody');
					rows = _.sortBy(rows, 'date')

					let weekWorkedCount = 0;
					let weekTotalHours = 0;
					let weekTotalStandbyDuties = 0;
					let weekTotalKilometers = 0;
					let weekTotalPublicHolidaysSunday = 0;
					let totalHourPerWeek = 0;

					for (let row of rows) {

						let N_row = N_tbody.insertRow();
						let date = moment(row.date, 'x').locale('fr');

						//Si le jour est "Dimanche"
						date.day() == 0 && N_row.classList.add('sunday');

						//Si le jour est férié
						isHoliday(+date.format('x')) && N_row.classList.add('ferie')

						N_row.insertCell().innerHTML = _.upperFirst(date.format('dddd'));
						N_row.insertCell().innerHTML = date.format('WW');

						// Ferie ou non
						let N_cell = N_row.insertCell()
						N_cell.innerHTML = isHoliday(+date.format('x')) ? 'Oui' : 'Non'
						if (!isHoliday(+date.format('x'))) {
							N_cell.classList.add('muted')
						}


						// Date
						N_row.insertCell().innerHTML = date.format('DD/MM/YYYY');

						// Travaille
						N_cell = N_row.insertCell()
						N_cell.innerHTML = !row.generate ? 'Oui' : 'Non';
						row.generate && N_cell.classList.add('muted');

						// Travail
						let totalHourPerDay = 0
						if (date.day() === 0 || isHoliday(+date.format('x'))) {
							let t = roundHour(row.totalPublicHolidaysSunday)
							totalHourPerDay = t
							N_cell = N_row.insertCell()
							N_cell.innerHTML = t + 'h';
							!t && N_cell.classList.add('muted');
						} else {
							let t = roundHour(row.totalWithoutStandyDuties)
							totalHourPerDay = t
							N_cell = N_row.insertCell()
							N_cell.innerHTML = t + 'h';
							!t && N_cell.classList.add('muted')
						}

						// Abstreinte
						let t = roundHour(row.totalStandbyDuties)
						totalHourPerDay += t
						N_cell = N_row.insertCell()
						N_cell.innerHTML = t + 'h'
						!t && N_cell.classList.add('muted')


						// Km
						N_cell = N_row.insertCell()
						N_cell.innerHTML = row.kilometers || 0
						!row.kilometers && N_cell.classList.add('muted')

						// Total heure Travaille
						N_cell = N_row.insertCell()
						N_cell.innerHTML = _.round(totalHourPerDay, 2) + 'h'

						!row.generate && weekWorkedCount++;

						if (date.day() == 0 || isHoliday(+date.format('x'))) {
							weekTotalPublicHolidaysSunday += (row.totalPublicHolidaysSunday || 0);
							weekTotalPublicHolidaysSunday += (row.totalStandbyDuties || 0)
							totalStandbyDuties -= (row.totalStandbyDuties || 0);
						} else {
							weekTotalHours += (row.totalWithoutStandyDuties || 0)
						}

						weekTotalStandbyDuties += (row.totalStandbyDuties || 0);
						weekTotalKilometers += row.kilometers || 0;
						totalHourPerWeek += totalHourPerDay || 0;
					}

					workedCount += weekWorkedCount;
					totalHours += weekTotalHours;
					totalStandbyDuties += weekTotalStandbyDuties;
					totalKilometers += weekTotalKilometers;
					totalPublicHolidaysSunday += weekTotalPublicHolidaysSunday;

					N_table.appendChild(N_tbody)

					let weekTotalHours_Formated = roundHour(weekTotalHours)
					let weekTotalStandbyDuties_Formated = roundHour(weekTotalStandbyDuties)
					let weekTotalPublicHolidaysSunday_Formated = roundHour(weekTotalPublicHolidaysSunday)
					let totalHours_Formated = _.round(totalHourPerWeek || 0, 2)

					N_table.appendChild(h('tbody.foot', h('tr',
						h('td', { attrs: { colspan: 4 } }, 'Semaine ' + moment(dateID, 'YYYY-MM-DD').format('WW')),
						h('td', `${weekWorkedCount}j`),
						h('td', `${weekTotalHours_Formated + weekTotalPublicHolidaysSunday_Formated}h`),
						h('td', weekTotalStandbyDuties_Formated + 'h'),
						h('td', weekTotalKilometers + 'km'),
						h('td', totalHours_Formated + 'h'),
					)));
					N_table.appendChild(h('tr.spacer'))
				}

				let totalHours_Formated = roundHour(totalHours)
				let totalStandbyDuties_Formated = roundHour(totalStandbyDuties)
				let totalPublicHolidaysSunday_Formated = roundHour(totalPublicHolidaysSunday)
				let totalOftotals = totalHours_Formated + totalStandbyDuties_Formated + totalPublicHolidaysSunday_Formated //- totalPublicHolidaysSundayStandbyDuties_Formated

				N_table.appendChild(h('tbody.final.foot',

					//Nombre de jour travraillé
					h('tr',
						h('td.offset', { attrs: { colspan: 2 } }),
						h('td', { attrs: { colspan: 5 } }, 'Nombre de jour travaillé'),
						h('td', { attrs: { colspan: 2 } }, workedCount + 'j')
					),

					//Total heures hors astreinte/dimanche/férié
					h('tr',
						h('td.offset', { attrs: { colspan: 2 } }),
						h('td', { attrs: { colspan: 5 } }, 'Total heures hors astreinte/dimanche/férié'),
						h('td', { attrs: { colspan: 2 } }, totalHours_Formated + 'h')
					),

					//Total heures astreinte
					h('tr',
						h('td.offset', { attrs: { colspan: 2 } }),
						h('td', { attrs: { colspan: 5 } }, 'Total heures astreinte'),
						h('td', { attrs: { colspan: 2 } }, totalStandbyDuties_Formated + 'h')
					),

					//Total heures férié/Dimanche
					h('tr',
						h('td.offset', { attrs: { colspan: 2 } }),
						h('td', { attrs: { colspan: 5 } }, 'Total heures férié/dimanche'),
						h('td', { attrs: { colspan: 2 } }, totalPublicHolidaysSunday_Formated + 'h')
					),

					//Total de toutes les heures
					h('tr',
						h('td.offset', { attrs: { colspan: 2 } }),
						h('td', { attrs: { colspan: 5 } }, 'Total'),
						h('td', { attrs: { colspan: 2 } }, totalOftotals + 'h')
					),

					//Total kilomeètre
					h('tr',
						h('td.offset', { attrs: { colspan: 2 } }),
						h('td', { attrs: { colspan: 5 } }, 'Kilomètres'),
						h('td', { attrs: { colspan: 2 } }, totalKilometers + 'km')
					),
				))

				w && w.document.body.appendChild(h('div.page', N_table));
			}
		}
		w && w.print()
		w && w.close()
	}


}

export default TabHourSheet;

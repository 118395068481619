import { global, LoggedUser } from "@autoprog/core-client"
import { Grid, GridOptions, MenuItemDef, ColDef, ProcessCellForExportParams, ICellRendererFunc, RowNode, ICellRendererParams } from 'ag-grid-community'
import { AnalyticsService, SubAnalyticsService, AccountingAccountsService, TiersService, UsersService, OrdersService } from "@js/libs/services"
import 'ag-grid-enterprise'
import AgGridStateSaver from '@libs/agGrid/StateSaver2'
import toastr from 'toastr'
import moment from 'moment'
import DetailOrdersModal from '@libs/modals/orders/DetailOrderModal'
import AddEditOrders from '@libs/modals/orders/AddEditOrders'
import AddBillModal from '@libs/modals/orders/AddBillModal'
import Export from '@libs/agGrid/Export'

import '@css/orders.scss'
import unitList from '@libs/lists/unitList'
import stateList from '@libs/lists/stateListMale'
import typeList from '@libs/lists/typeList'

import h from 'hyperscript'
import { AGGridUtils, french as agGridFrench } from '@libs/utils/agGrid'

import DatePickerRangeFilter from '@libs/agGrid/DatePickerRangeFilter'
import humanizeNumber from '@libs/HumanizeNumber'
import displayWeight from '@libs/DisplayWeight'

import M_file from '@libs/modals/orders/Attachments'
import TextFilterAccent from '@libs/agGrid/TextFilterAccent'

import OldDataEvent from '@js/types/data-event'
import OrdersManager from '@js/libs/managers/OrdersManager'

import Order from '@js/types/order'
import Tier from '@js/types/Tiers'
import { Analytic } from '@js/libs/services/AnalyticsService'
import { SubAnalytic } from '@js/libs/services/SubAnalyticsService'


import _ from 'lodash'

import '@css/tabs.scss'
import '@css/stateslist.scss'
import StateTransaction from "@js/libs/lists/StateTransactionEnum"

import Confirm from '@js/libs/modals/Confirm'
import DataEventsService from "@js/libs/services/DataEventsService"
import BDD from "@js/libs/BDD"

interface TabOrderRow extends Order {
	customerData: Tier
	providerData: Tier
	analytics: Array<string>
	subAnalytics: Array<string>
	accountingAccount: Array<string>
}

interface IRulesStateCustomSubmenuAction {
	[rule: string]: {
		preconditionStateAction?: (node: RowNode) => boolean | Array<string>, // Permet de determiner si il est capable d'afficher les actions possible en fonction des precondition requis
		simple?: (node: RowNode, state: string) => void | Promise<void> // Permet d'action un effet particulier lors de l'appel de l'etat
	}
}

class TabOrders {

	private gridOptions: GridOptions = <GridOptions>{}
	private N_gridOrders?: HTMLElement
	private loggedUser: LoggedUser

	private stateAGrid: AgGridStateSaver | undefined
	private states = ['all', 'toConfirm', 'preValidate', 'notDelivered', 'inDelivering', 'delivered', 'billed', 'archived']
	// Affichage de tous les actions possibles en fonction de l'etat de depart
	// ATTENTION: les etats suivant peuvent dependre de la precondition dans rulesStateCustomSubmenuAction
	private rulesState: { [key: string]: string[] } = {
		toConfirm: ['preValidate', 'validated'], // Nest pas tellement pris en compte a cause de la precondition
		preValidate: ['validated'],
		notDelivered: ['inDelivering', 'delivered', 'billed', 'archived'],
		inDelivering: ['notDelivered', 'delivered', 'billed', 'archived'],
		delivered: ['notDelivered', 'inDelivering', 'billed', 'archived'],
		archived: [],
		validated: [],
		billed: ['archived'],
		all: ['toConfirm', 'preValidate', 'notDelivered', 'inDelivering', 'delivered', 'billed', 'archived']
	}

	// FIXME: Devrait etre fusionner avec le tableau ruleState pour plus d'homogénité
	private rulesStateCustomSubmenuAction: IRulesStateCustomSubmenuAction = {
		toConfirm: { preconditionStateAction: this.preconditionToConfirm.bind(this) },
		preValidate: { preconditionStateAction: this.preconditionPrevalidate.bind(this), simple: this.simplePreValidate.bind(this) },
		validated: { simple: this.simpleValidated.bind(this) },
		archived: { simple: this.simpleArchived.bind(this) }
	}

	constructor(private el: HTMLElement) {
		this.N_gridOrders = this.el.querySelector("#grid-orders") as HTMLDivElement
		this.loggedUser = LoggedUser.getInstance()
		this.init()
	}

	private async init() {

		this.initQuickFilter()

		this.createGrid()

		const N_btnAddOrders = this.el.querySelector('#button-add-orders') as HTMLButtonElement

		N_btnAddOrders.addEventListener('click', () => {
			const modal = new AddEditOrders()
			modal.open().then(() => this.updateGrid())
		})

		const N_filterOrder = this.el.querySelector('#quick-filter-orders') as HTMLInputElement
		N_filterOrder.addEventListener('input', async () => {
			this.gridOptions.api?.setQuickFilter(_.deburr(N_filterOrder.value))
		})
	}

	private createGrid() {
		this.gridOptions = agGridFrench({
			suppressCopyRowsToClipboard: true,
			sideBar: {
				toolPanels: [{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnFilter: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}],
			},
			excelStyles: [
				{
					id: 'date',
					dataType: 'DateTime',
					numberFormat: {
						format: 'dd/mm/yyyy;@',
					}
				},
				{
					id: 'money-type',
					numberFormat: {
						format: "#,##0.00 [$EUR]",
					}
				},
				{
					id: 'number',
					dataType: 'Number'
				}
			],
			columnDefs: [
				{
					headerName: "Etat",
					field: "state",
					filter: 'agSetColumnFilter',
					pinned: 'left',
					filterValueGetter: (params) => {
						if (params.data) {
							return stateList[params.data.state].name
						}
					},
					cellRenderer: (params) => {

						if (params.node.level == 0 && params.value && Object.keys(stateList).includes(params.value)) {
							return stateList[params.value].name
						}
						return '';

					},
					aggFunc: 'first'
				},
				{
					headerName: "N° Commande",
					showRowGroup: 'groupKey',
					colId: 'orderNumber',
					cellRenderer: 'agGroupCellRenderer',
					pinned: 'left',
					sort: 'desc',
					filterValueGetter: (params) => params.data ? params.data.orderNumber : null,
					valueGetter: (params) => {

						if (!params.node?.rowPinned) {
							return params.node?.level === 0 && params.data.orderNumber ? params.data.orderNumber : '';
						}

						return params.data.orderNumber
					}
				},
				{
					field: "groupKey",
					rowGroup: true,
					hide: true,
					suppressColumnsToolPanel: true
				},
				{
					field: "_id", //Ne pas supprimer cette colonne, utilisé pour avoir l'_id dans les aggData
					hide: true,
					aggFunc: 'first',
					suppressColumnsToolPanel: true
				},
				{
					headerName: "Date Commande",
					field: "orderDate",
					filter: 'agDateColumnFilter',
					cellClass: 'date',
					floatingFilterComponent: DatePickerRangeFilter,
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					filterParams: {
						filterOptions: ['inRange', 'empty'],
						suppressAndOrCondition: true,
						inRangeInclusive: true,
						comparator: (filterValue: Date, cellValue: number) => {

							const filter = parseInt(moment(filterValue).startOf('day').format('x'));
							const cell = parseInt(moment(cellValue).startOf('day').format('x'))

							if (cell < filter) {
								return -1;
							}
							else if (cell > filter) {
								return 1
							}

							return 0
						}
					},
					cellRenderer: (params) => {

						if (params.node.level == 0 && params.value) {
							if (params.type == 'excel') {
								return moment(params.value, 'x').format('YYYY-MM-DD')
							}

							return moment(params.value, 'x').format('DD/MM/YYYY')
						}

						return '';
					},
					aggFunc: 'first'
				},
				{
					headerName: "Fournisseur",
					field: "providerData",
					filter: 'agSetColumnFilter',
					filterValueGetter: (params) => _.get(params.data, 'providerData.name'),
					cellRenderer: (params) => {

						if (params.node.level == 0 && params.value) {
							if (params.value && params.value.name) {
								return params.value.name
							} else {
								return params.value
							}
						}
						return ''
					},
					aggFunc: 'first'
				},
				{
					headerName: "Référence",
					field: "product",
					aggFunc: 'concat'
				},
				{
					headerName: "Quantité commandée",
					field: "orderedQuantity",
					cellClass: (params) => {

						if (params.node.rowPinned == 'bottom') {
							return 'text-right';
						}

						return ['text-right', 'number']
					},
					aggFunc: 'sum',
					filter: 'agNumberColumnFilter',
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return params.value;
						}
						else {

							let unit = this.getUnit(params);
							let value = '';
							if (unit) {
								value = displayWeight(params.value, unit);
							}

							if (params.type == 'excel') {
								return parseFloat(value.replace(/\s/g, ''))
							}

							return value
						}
					}
				},
				{
					headerName: 'Unité',
					field: "orderedQuantity",
					aggFunc: 'sum',
					width: 80,
					suppressSizeToFit: true,
					cellRenderer: (params) => {

						if (params.value) {
							return this.getUnit(params) || ''
						}

						return ''
					}
				},
				{
					headerName: "Qté Livrée",
					field: "deliveredQuantity",
					cellClass: (params) => {

						if (params.node.rowPinned == 'bottom') {
							return 'text-right'
						}

						return ['text-right', 'number']
					},
					filter: 'agNumberColumnFilter',
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return params.value
						}
						else {
							let unit = this.getUnit(params);
							let value = '';
							if (unit) {
								value = displayWeight(params.value, unit)
							}

							if (params.type == 'excel') {
								return parseFloat(value.replace(/\s/g, ''))
							}

							return value
						}
					},
					aggFunc: 'sum'
				},
				{
					headerName: 'Unité',
					field: "deliveredQuantity",
					width: 80,
					suppressSizeToFit: true,
					aggFunc: 'sum',
					cellRenderer: (params) => {

						if (params.value) {
							return this.getUnit(params) || '';
						}

						return '';
					}
				},
				{
					headerName: 'Prix',
					field: "price",
					width: 80,
					suppressSizeToFit: true,
					cellClass: 'money-type',
					aggFunc: 'sum',
					cellRenderer: (params) => {
						const value = _.round(params.value, 2)

						if (params.type == 'excel') {
							return value.toString()
						}

						return value + '€';
					}
				},
				{
					headerName: 'Total Facture HT',
					field: "billedTotal",
					width: 80,
					suppressSizeToFit: true,
					cellClass: 'money-type',
					aggFunc: 'sum',
					cellRenderer: (params) => {

						let value = _.round(params.value || 0, 2);

						if (params.type == 'excel') {
							return value.toString();
						}

						return value + '€';
					}
				},
				{
					headerName: "Type",
					field: "type",
					filter: 'agSetColumnFilter',
					hide: true,
					cellRenderer: (params) => {
						if (params.node.level == 0 && params.value) {
							return params.value;
						}
						return '';
					},
					valueGetter: (params) => {
						if (params.data) return typeList[params.data.type];
					},
					aggFunc: 'first'
				},
				{
					headerName: "Detail",
					field: "detail",
					hide: true,
					cellRenderer: (params) => {
						if (params.node.level == 0 && params.value) {
							return params.value
						}
						return '';
					},
					aggFunc: 'first'
				},
				{
					headerName: "Compte Comptable",
					field: "accountingAccount",
					aggFunc: 'concat',
					hide: true
				},
				{
					headerName: "Analytique",
					field: "analytics",
					aggFunc: 'concat',
					hide: true
				},
				{
					headerName: "Sous-Analytique",
					field: "subAnalytics",
					aggFunc: 'concat',
					hide: true
				},
				{
					headerName: "Date Livraison / Intervention",
					field: "deliveryDate",
					filter: 'agDateColumnFilter',
					cellClass: 'date',
					hide: true,
					floatingFilterComponent: DatePickerRangeFilter,
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					filterParams: {
						filterOptions: ['inRange', 'empty'],
						suppressAndOrCondition: true,
						inRangeInclusive: true,
						comparator: (filterValue: Date, cellValue: number) => {

							let filter = parseInt(moment(filterValue).startOf('day').format('x'));
							let cell = parseInt(moment(cellValue).startOf('day').format('x'))

							if (cell < filter) {
								return -1;
							} else if (cell > filter) {
								return 1
							}

							return 0
						}
					},
					cellRenderer: (params) => {

						if (params.node.level == 0 && params.value) {
							return moment(params.value, 'x').format('DD/MM/YYYY');
						}
						return '';

					},
					aggFunc: 'first'
				},
				{
					headerName: "Client",
					hide: true,
					field: "customerData",
					filter: 'agSetColumnFilter',
					filterValueGetter: (params) => {
						return _.get(params.data, 'customerData.name');
					},
					cellRenderer: (params) => {

						if (params.node.level == 0 && params.value) {
							if (params.value && params.value.name) {
								return params.value.name
							} else {
								return params.value
							}
						}
						return '';

					},
					aggFunc: 'first'
				},
				{
					headerName: '',
					field: '_id',
					colId: 'actions',
					width: 8 + 28 * 4,
					filter: false,
					resizable: false,
					pinned: 'right',
					cellRenderer: (params) => {
						const div = document.createElement('div') as HTMLDivElement
						if (params.node.level == 0 && params.value && !params.node.rowPinned) {

							const data = params.node.aggData || params.node.data;

							/* ------------------------------ Bouton détail ----------------------------- */

							const infoButton = h('button.btn.btn-xs.btn-blue', h('i.icon.icon-solid-eye'));

							infoButton.addEventListener('click', () => {
								const modal = new DetailOrdersModal(params.value);
								modal.open().then((edited) => {
									edited && this.updateGrid();
								})
							});

							/* ---------------------------- Bouton supprimer ---------------------------- */

							const deleteButton = h('button.btn.btn-xs.btn-red', h('i.icon.icon-solid-trash'), {
								attrs: {
									confirmation: '',
									permission: 'ORDERS.BC.DELETE'
								}
							})

							deleteButton.addEventListener2('click', async () => {
								await this.deleteRow(params.value)
								this.updateGrid()
							})

							/* ---------------------------- Bouton prévalidé ---------------------------- */

							const prevalidateButton = h('button.btn.btn-xs.btn-teal', h('i.icon.icon-solid-check'), {
								attrs: {
									confirmation: '',
									permission: 'ORDERS.BC.STATE.PREVALIDATE'
								}
							})

							prevalidateButton.addEventListener2('click', async () => this.simplePreValidate(params.node, ''));

							/* ------------------------------ Bouton validé ----------------------------- */

							const validateButton = h('button.btn.btn-xs.btn-green', h('i.icon.icon-solid-check'), {
								attrs: {
									confirmation: '',
									permission: 'ORDERS.BC.STATE.VALIDATED'
								}
							})

							validateButton.addEventListener2('click', async () => this.simpleValidated(params.node, ''))

							/* ------------------------------ Bouton archive ----------------------------- */

							const archivedButton = h('button.btn.btn-xs.btn-green', h('i.icon.icon-solid-archive'), {
								attrs: {
									confirmation: '',
									permission: 'ORDERS.BC.STATE.ARCHIVED'
								}
							})

							archivedButton.addEventListener2('click', async () => this.simpleArchived(params.node, 'archived'))

							//----------------------------------------------------------------------------

							div.appendChild(infoButton)

							//Si le bc est facturé, on ne peux plus le supprimer
							if (data && data.state != 'billed' && data.state != 'archived') {
								div.appendChild(deleteButton)
							}

							// Le bouton archive
							if (['notDelivered', 'inDelivering', 'delivered', 'billed'].includes(data.state)) {
								div.appendChild(archivedButton)
							}

							const orderLimit = UsersService.getInstance().orderLimit()

							//Bouton pré-validé:
							//Si la commande est a confirmer et que l'utilisateur a un seuil d'achat inférieur a la commande,  il peux alors seulement la prévalider

							if (data && data.state == 'toConfirm' && data.price > orderLimit) {
								div.appendChild(prevalidateButton)
							}

							//Bouton validé: 
							//Si la commande est a confirmer ou pré-validée et que l'utilisateur a un seuil d'achat supérieur a la commande,  il peux alors la valider
							if (data && (data.state == 'toConfirm' || data.state == 'preValidate') && data.price <= orderLimit) {
								div.appendChild(validateButton)
							}

							const attachments = (params.node.data) ? data._attachments : params.node.allLeafChildren[0].data._attachments

							if (attachments) {

								const N_attachments = document.createElement('button')

								N_attachments.classList.add('btn', 'btn-xs', 'btn-grey')
								N_attachments.innerHTML = `<i class="icon icon-solid-paperclip"></i>`

								N_attachments.addEventListener2('click', () => {

									let values = Object.keys(attachments)

									if (values.length == 1) {
										window.open(`${global.COUCHDB_URL}/orders/${data._id}/${values[0]}`, 'target')
									}
									else {
										new M_file('orders', data._id, values).open()
									}
								})

								div.appendChild(N_attachments)
							}

						}

						return div
					},
					aggFunc: 'first'
				}
			],
			groupMultiAutoColumn: true,
			groupSuppressAutoColumn: true,
			suppressAggFuncInHeader: true,
			suppressDragLeaveHidesColumns: true,
			getContextMenuItems: (params) => {
				//FIXME: Typer correctement
				let data: any = null

				if (params.node.group) {
					data = params.node.allLeafChildren[0].data //On utilise allLeafChildren car on a besoin de l'id et aggData ne le fournis pas
				} else {
					data = params.node.data;
				}
				const menu: MenuItemDef[] = []


				if (params.columnApi) {
					const columnKeys = AGGridUtils.getColumnsIds(this.gridOptions, 'actions')

					const exportAllExcel = {
						name: 'Exporter tous Excel',
						action: () => {
							const exp = new Export(params)
							exp.exportExcel({
								columnKeys
							})
						}
					}

					const exportAllCSV = {
						name: 'Exporter tous CSV',
						action: () => {
							const exp = new Export(params)
							exp.exportCSV({
								columnKeys
							})
						}
					}

					this.loggedUser.hasPermission('ORDERS.BC.MENU.EXPORT_EXCEL') && menu.push(exportAllExcel, exportAllCSV)
				}

				if (data) {

					const detail: MenuItemDef = {
						name: "Voir détails",
						action: () => {
							if (data) {
								const modal = new DetailOrdersModal(data._id)
								modal.open().then(() => this.updateGrid())
							}
						}
					}

					const bill: MenuItemDef = {
						name: 'Facturer',
						action: () => {
							const modal = AddBillModal.fromOrder(data._id);
							modal.open()
						}
					}

					const duplicate: MenuItemDef = {
						name: 'Dupliquer',
						action: async () => {
							if (data) {
								const modal = new AddEditOrders(data._id, true);
								modal.open().then(() => this.updateGrid())
							}
						}
					}

					this.loggedUser.hasPermission('ORDERS.BC.MENU.DUPLICATE') && menu.push(duplicate)
					this.loggedUser.hasPermission('ORDERS.BC.MENU.DETAILS') && menu.push(detail)

					if (!['billed', 'preValidate', 'toConfirm', 'archived'].includes(data.state) && this.loggedUser.hasPermission('ORDERS.BC.MENU.BILL')) {
						menu.push(bill)
					}

					// Creation de notre sous-menu etat
					const subMenu = this.createStateMenuContextForOne(params.node)

					if (subMenu.length) {
						menu.push({
							name: 'Etat',
							subMenu
						})
					}

					// Menu specialement concu pour le debuggage par autoprog, ces appels d'API sont extrements dangereux
					if (!!this.loggedUser.get("isSuperAdmin")) {
						const debugMenu = this.createDebuggingMenu(params.node)
						menu.push(debugMenu)
					}
				}

				return menu;

			},
			defaultColDef: {
				floatingFilter: true,
				filter: "agTextColumnFilter",
				suppressMenu: true,
				resizable: true,
				sortable: true,
				filterParams: {
					filterOptions: ['contains'],
					textCustomComparator: TextFilterAccent,
					excelMode: 'windows'
				}
			},
			aggFuncs: {
				concat: (params) => _.uniq(params.values).join(', ')
			},
			getRowClass: (params) => {
				const data = params.node.aggData || params.data;
				return data && stateList[data.state] ? 'ag-row-' + stateList[data.state].cls : ''
			},
			//onGridReady: () => this.updateGrid(), On ne charge plus les données ici car la méthode `updateSelectSite` le fait déja lors du chargement de l'onglet			onRowDataChanged: () => this.updatePinnedRowData(),
			onFilterChanged: () => this.updatePinnedRowData(),
			processCellForClipboard: params => this.processCellCallback(params as any)
		})

		this.N_gridOrders && new Grid(this.N_gridOrders as HTMLElement, this.gridOptions)
	}

	private createDebuggingMenu(node: RowNode): MenuItemDef {
		const data = node.aggData || node.data
		const menu: MenuItemDef = {
			name: "Debug"
		}

		// API pour mettre a jour le status d'une commande
		const updateOrderMenu: MenuItemDef = {
			name: "Mettre a jour le status de la commande",
			icon: "🧙🏾‍♂️",
			action: async () => {
				await OrdersService.getInstance().updateOrder(data._id)
				await DataEventsService.getInstance().create({
					id: data._id,
					date: Date.now(),
					table: "ORDERS",
					type: "maintenance-autoprog-update-status",
					user: this.loggedUser.get("ID"),
					_id: BDD.generateID()
				})
			}
		}

		// API pour mettre a jour la quantite livres
		const updateDeliveredQuantity: MenuItemDef = {
			name: "Mettre a jour les quantites livres",
			icon: "📬",
			action: async () => {
				await OrdersService.getInstance().updateDeliveryProducts(data._id)
				await DataEventsService.getInstance().create({
					id: data._id,
					date: Date.now(),
					table: "ORDERS",
					type: "maintenance-autoprog-update-delivered",
					user: this.loggedUser.get("ID"),
					_id: BDD.generateID()
				})
			}
		}

		// API pour mettre a jour les informations lies a la facture
		const updateBillStatus: MenuItemDef = {
			name: "Mettre a jour la quantite facturee",
			icon: "📊",
			action: async () => {
				await OrdersService.getInstance().updateTotalBills(data._id)
				await DataEventsService.getInstance().create({
					id: data._id,
					date: Date.now(),
					table: "ORDERS",
					type: "maintenance-autoprog-update-bills",
					user: this.loggedUser.get("ID"),
					_id: BDD.generateID()
				})
			}
		}

		// Affection menu
		menu.subMenu = [updateOrderMenu, updateDeliveredQuantity, updateBillStatus]

		return menu
	}

	private createStateMenuContextForOne(node: RowNode): Array<MenuItemDef> {
		const keys = _.cloneDeep(this.rulesState)
		const subMenu: Array<MenuItemDef> = []

		const loggedUser = LoggedUser.getInstance()
		const data = node.aggData || node.data
		let allKeysAvailableFromCurrentStattus = keys[data.state] || []

		// Ce sont toutes les commandes comme toConfirm qui ont une spécificité
		const exceptionKeys = Object.keys(this.rulesStateCustomSubmenuAction).filter(key => allKeysAvailableFromCurrentStattus.includes(key))

		if (exceptionKeys.includes(data.state) && this.rulesStateCustomSubmenuAction[data.state].preconditionStateAction) {
			const precondition = this.rulesStateCustomSubmenuAction[data.state].preconditionStateAction!(node)
			if (Array.isArray(precondition)) {
				allKeysAvailableFromCurrentStattus = precondition
			}
			else if (!precondition) {
				allKeysAvailableFromCurrentStattus = []
			}
		}

		if (loggedUser.hasPermission('ORDERS.BC.STATE.FORCING')) {
			allKeysAvailableFromCurrentStattus = keys['all']
		}

		for (let key of allKeysAvailableFromCurrentStattus) {

			const permission = key

			if (loggedUser.hasPermission(`ORDERS.BC.STATE.${permission.toUpperCase()}`) || loggedUser.hasPermission('ORDERS.BC.STATE.FORCING')) {

				if (!exceptionKeys.includes(key) || !this.rulesStateCustomSubmenuAction[key].simple) {
					subMenu.push({
						name: stateList[key].name,
						action: () => this.genericActionSimple(node, permission)
					})
				}
				else {
					subMenu.push({
						name: stateList[key].name,
						action: () => this.rulesStateCustomSubmenuAction[key].simple!(node, permission)
					})
				}
			}

		}

		return subMenu
	}

	private async genericActionSimple(node: RowNode, state: string): Promise<void> {
		const data = node.aggData || node.data
		if (data) {
			try {
				await this.updateOrderStatus(data._id, state)

				// Current data State === 'archived'
				// New State n'est pas acrhive
				// Et a la permission special de forcing
				if (data.state === StateTransaction.archived && state !== StateTransaction.archived && this.loggedUser.hasPermission('ORDERS.BC.STATE.FORCING')) {
					await OrdersService.getInstance().unarchived(data._id)
				}

				this.updateGrid()
				toastr.success(`La commande ${data._id} a été modifiée `)
			}
			catch (e) {
				console.error(e)
			}
		}
	}


	private processCellCallback(params: ProcessCellForExportParams) {

		let colDef = params.column.getColDef();

		let value = params.value || '';

		if (colDef && colDef.cellRenderer && typeof colDef.cellRenderer == 'function') {
			value = (colDef.cellRenderer as ICellRendererFunc)({
				data: (params.node || { data: {} }).data,
				colDef,
				...params
			})
		}

		return value;
	}

	public async updateSelectSite(value: string) {		
		this.stateAGrid && this.stateAGrid.save()

		const currentFilterModel = this.gridOptions.api?.getFilterModel() || {}
		
		this.stateAGrid = new AgGridStateSaver(this.gridOptions, value + "_orders", `Tableau Bon de Commande ${value}`)		
		await this.updateGrid()		
		this.stateAGrid.load()
		//Garder le filtre du site précédent
		if (_.isEmpty(currentFilterModel)) {
			const values = this.states.filter(state => state !== 'all' && !!stateList[state]).map(state => stateList[state].name)
			this.gridOptions.api?.setFilterModel({state: {type: 'set',values}})
		} else {
			this.gridOptions.api?.setFilterModel(currentFilterModel)
		}

		this.gridOptions.api?.onFilterChanged()
	}

	private updatePinnedRowData() {

		let orderedQuantity: { [key: string]: any } = {};
		let deliveredQuantity: { [key: string]: any } = {};

		let totalPrice = 0;
		let ids = new Set();

		if (this.gridOptions.api) {

			this.gridOptions.api.forEachNodeAfterFilter((node) => {

				let data = _.cloneDeep(node.data);

				if (data) {

					let valueOrdered = data.orderedQuantity;
					let valueDelivery = data.deliveredQuantity;

					if (data.unit == 'tonne') {
						valueOrdered *= unitList['tonne'].coeficient;
						valueDelivery *= unitList['tonne'].coeficient;
						data.unit = 'kg';
					}

					orderedQuantity[data.unit] = orderedQuantity[data.unit] || [];
					deliveredQuantity[data.unit] = deliveredQuantity[data.unit] || [];

					orderedQuantity[data.unit].push(valueOrdered);
					deliveredQuantity[data.unit].push(valueDelivery);

					totalPrice += data.price;

					ids.add(data._id);
				}

			});

			let average: { [key: string]: any } = {
				orderedQuantity: '',
				deliveredQuantity: '',
				price: totalPrice / ids.size
			};
			let sum: { [key: string]: any } = {
				orderedQuantity: '',
				deliveredQuantity: '',
				price: totalPrice
			};

			for (let unit in orderedQuantity) {

				const value = _.sum(orderedQuantity[unit])

				sum.orderedQuantity += `${humanizeNumber(value)} ${unitList[unit].unit} `;

				const valueAverage = humanizeNumber(_.round(value / orderedQuantity[unit].length, 2))

				average.orderedQuantity += `${valueAverage} ${unitList[unit].unit} `

			}

			for (let unit in deliveredQuantity) {

				const value = _.sum(deliveredQuantity[unit]);

				sum.deliveredQuantity += `${humanizeNumber(value)} ${unitList[unit].unit} `;

				const valueAverage = humanizeNumber(_.round(value / deliveredQuantity[unit].length, 2))

				average.deliveredQuantity += `${valueAverage} ${unitList[unit].unit} `
			}

			average.orderNumber = 'Moyenne'
			sum.orderNumber = 'Somme'

			this.gridOptions.api.setPinnedBottomRowData([average, sum])
		}

	}

	public destructor() {
		if (this.gridOptions.api) {
			this.stateAGrid && this.stateAGrid.save()
			this.gridOptions.api.destroy()
		}
	}

	private async getRowData(): Promise<TabOrderRow[]> {
		const orders = await OrdersService.getInstance().getBySite(global.SITE)

		const tiersIDs = _.uniq(orders.flatMap(order => [order.customer, order.provider]))
		const tiers = await TiersService.getInstance().getByIds(tiersIDs)

		return orders.map(order => ({
			...order,
			customerData: tiers.find(t => t._id === order.customer)!,
			providerData: tiers.find(t => t._id === order.provider)!,
			analytics: _.uniq(_.compact(order.products?.flatMap(product => product.analytics?.map(analytic => AnalyticsService.getInstance().getById(analytic.id)?.name)))),
			subAnalytics: _.uniq(_.compact(order.products?.flatMap(product => SubAnalyticsService.getInstance().getById(product.subAnalytics?.[0]?.id)?.name))),
			accountingAccount: _.uniq(_.compact(order.products?.map(product => AccountingAccountsService.getInstance().getById(product.accountingAccount)?.name)))
		}))
	}

	private async updateGrid() {

		try {
			const data = await this.getRowData()

			if (this.gridOptions.api && this.gridOptions.columnApi) {

				const rows = []
				const uniqAnalyticsKeys = new Set()
				const uniqSubAnalyticsKeys = new Set()

				const model = this.gridOptions.api.getFilterModel()

				for (let d of data) {
					if (d.products && d.products.length) {
						for (let element of d.products) {
							const aRowProduct = {
								groupKey: d.products.length > 1 ? d.orderNumber : null,
								...d,
								unit: element.unit,
								product: element.reference,
								orderedQuantity: element.quantity / unitList[element.unit].coeficient,
								deliveredQuantity: (element.deliveredQuantity || 0) / unitList[element.unit].coeficient,
								billedTotal: element.billedTotal || 0,
								price: element.quantity * element.unitPrice
							}

							// Pour les analytiques
							if (element.analytics && element.analytics.length > 0) {

								for (let analytic of element.analytics) {
									uniqAnalyticsKeys.add(analytic.id)
									// aRowProduct['analytic_' + analytic.id] = aRowProduct.price * (analytic.percent / 100)
									_.set(aRowProduct, 'analytic_' + analytic.id, aRowProduct.price * (analytic.percent / 100))
								}

							}

							// Pour les sous-analytiques
							if (element.subAnalytics && element.subAnalytics.length > 0) {
								uniqSubAnalyticsKeys.add(element.subAnalytics[0].id)
								// aRowProduct['subAnalytic_' + element.subAnalytics[0].id] = aRowProduct.price * (element.subAnalytics[0].percent / 100)
								_.set(aRowProduct, 'subAnalytic_' + element.subAnalytics[0].id, aRowProduct.price * (element.subAnalytics[0].percent / 100))
							}

							rows.push(aRowProduct)
						}
					}
					else {
						rows.push(d)
					}
				}


				if (LoggedUser.getInstance().hasPermission('ORDERS.BC.ANALYTICS')) {
					// Création des colonnes analytiques
					const uniqAnalytics = AnalyticsService.getInstance().getByIds(Array.from(uniqAnalyticsKeys) as Array<string>)
					const colAnalytic = this.createAnalyticsColumn(uniqAnalytics)

					const uniqSubAnalytics = SubAnalyticsService.getInstance().getByIds(Array.from(uniqSubAnalyticsKeys) as Array<string>)
					const colSubAnalytic = this.createSubAnalyticsColumn(uniqSubAnalytics)

					// REMARK: ne mettra jamais à jour en mémoire
					const colDefs = this.gridOptions.columnDefs
					colAnalytic && colDefs?.push(colAnalytic)
					colSubAnalytic && colDefs?.push(colSubAnalytic)
					colDefs && this.gridOptions.api.setColumnDefs(colDefs)
				}

				this.gridOptions.api.setRowData(rows)
				this.gridOptions.api.setFilterModel(model)
			}
		}
		catch (e) {
			console.error(e)
			toastr.error("Echec du chargement des données", "Erreur")
		}
	}

	/**
	 * Créer les columns analytics
	 * @param analytics 
	 */
	private createAnalyticsColumn(analytics: Array<Analytic>): ColDef | null {
		if (analytics && analytics.length > 0) {
			// On crée la column analytics
			let colAnalytic: ColDef | any = {
				colId: 'analytic',
				headerName: 'Analytique',
				children: []
			}

			analytics.forEach(analytic => colAnalytic.children.push({
				headerName: analytic.name,
				field: 'analytic_' + analytic._id,
				hide: false,
				aggFunc: 'sum',
				cellClass: 'money-type',
				cellRenderer: (params: ICellRendererParams) => {

					if (params.value) {
						const value = _.round(params.value || 0, 2)
						return _.get(params, 'type') === 'excel' ? value : `${value}€`
					}

					return ''
				}
			}))

			return colAnalytic
		}

		return null
	}


	/**
	 * Créer les columns analytics
	 * @param analytics 
	 */
	private createSubAnalyticsColumn(subAnalytics: Array<SubAnalytic>): ColDef | null {
		if (subAnalytics && subAnalytics.length > 0) {
			// On crée la column analytics
			let colSubAnalytic: ColDef | any = {
				colId: 'subAnalytic',
				headerName: 'Sous-Analytique',
				children: []
			}

			subAnalytics.forEach(subanalytic => colSubAnalytic.children.push({
				headerName: subanalytic.name,
				field: 'subAnalytic_' + subanalytic._id,
				hide: false,
				aggFunc: 'sum',
				cellClass: 'money-type',
				cellRenderer: (params: ICellRendererParams) => {
					if (params.value) {
						const value = _.round(params.value || 0, 2)
						return _.get(params, 'type') === 'excel' ? value : `${value}€`
					}

					return ''
				}
			}))

			return colSubAnalytic
		}

		return null
	}

	// TODO: Faire pareil pour les sous-analytiques

	/**
	 * Supprimer une ligne de commande
	 * @param id 
	 */
	private async deleteRow(id: string): Promise<void> {

		try {
			await OrdersManager.deleteOrder(id)
			await OrdersService.getInstance().createEvent({
				date: Date.now(),
				type: 'delete',
				user: LoggedUser.getInstance().get('ID'),
				id
			})
			toastr.success('Commande supprimée')
		}
		catch (e) {
			toastr.error(`Erreur lors de la supression de la commande`)
			console.error(e)
		}

	}

	/**
	 * Va changer le status d'un BC
	 * Il va d'abord changer le status de la commande et ensuite changer ajouter un DataEvent dans la commande
	 * @param id 
	 * @param state 
	 */
	private async updateOrderStatus(id: string, state: string) {
		const oldOrder = await OrdersService.getInstance().getByID(id, true)
		await OrdersService.getInstance().setState(id, state)

		const dataEvent: Omit<OldDataEvent, 'table'> = {
			date: Date.now(),
			type: 'edit',
			user: LoggedUser.getInstance().get('ID'),
			id
		}

		// TODO: vérifier les changement d'états

		if (oldOrder.state !== state) {
			dataEvent.changeState = {
				from: oldOrder.state,
				to: state
			}
		}

		await OrdersService.getInstance().createEvent(dataEvent)
	}

	private async prevalidateOrder(id: string) {
		await OrdersService.getInstance().prevalidate(id)
	}

	private async validateOrder(id: string) {
		await OrdersService.getInstance().validate(id)
	}

	private getUnit(params: any) {

		let unit = null;
		if (params.data && params.data.unit) {
			unit = unitList[params.data.unit].unit;
		}
		else if (params.node.allLeafChildren) {
			let childrenUnitUniq = _.uniq(_.map(params.node.allLeafChildren, 'data.unit'));
			if (childrenUnitUniq.length == 1) {
				unit = unitList[childrenUnitUniq[0]].unit;
			}
		}

		return unit
	}

	private initQuickFilter() {
		const N_quickFilters = this.el.querySelector("#quick-filters") as HTMLElement

		this.states.forEach(stateName => {

			let state = stateList[stateName]

			const N_btn = h(`button.btn.btn-sm.btn-rounded.mr-1.btn-state-${state.cls}`, state.name, {
				onclick: () => {

					if (this.gridOptions && this.gridOptions.api) {

						if (stateName == 'all') {

							const values = this.states.filter(state => state !== 'all' && !!stateList[state]).map(state => stateList[state].name)

							this.gridOptions.api.setFilterModel({
								state: {
									type: 'set',
									values
								}
							})

						}
						else {
							this.gridOptions.api.setFilterModel({
								state: {
									type: 'set',
									values: [state.name]
								}
							})
						}

						this.gridOptions.api.onFilterChanged()
					}
				}
			})

			N_quickFilters.appendChild(N_btn)
		})
	}

	private preconditionToConfirm(node: RowNode): Array<string> {
		const orderLimit = UsersService.getInstance().orderLimit()
		const data = node.aggData || node.data
		return data.price <= orderLimit ? ['validated'] : ['preValidate']
	}

	private preconditionPrevalidate(node: RowNode): boolean {
		const orderLimit = UsersService.getInstance().orderLimit()
		const data = node.aggData || node.data
		return data.price <= orderLimit
	}

	private async simpleValidated(node: RowNode, _state: string) {
		const data = node.aggData || node.data
		if (data) {

			try {
				await this.updateOrderStatus(data._id, 'notDelivered')
				await this.validateOrder(data._id)
				this.updateGrid()
				toastr.success(`La commande ${data._id} a été modifiée `)
			}
			catch (e) {
				console.error(e)
			}

		}
	}

	private async simplePreValidate(node: RowNode, _state: string) {
		const data = node.aggData || node.data
		if (data) {

			try {
				await this.updateOrderStatus(data._id, 'preValidate')
				await this.prevalidateOrder(data._id)
				this.updateGrid()
				toastr.success(`La commande ${data._id} a été modifiée `)
			}
			catch (e) {
				console.error(e)
			}
		}
	}

	private async simpleArchived(node: RowNode, state: string) {
		const data = node.aggData || node.data

		if (!data) {
			toastr.error('Erreur base de donnees')
			throw new Error('There is no data')
		}

		// Avant de pouvoir archiver, on doit prevenir l'utilisateur que des bons des livraison et des bons de d'interventions sont factures
		const attachedNotBilled = await OrdersService.getInstance().getAttachedNotBilled(data._id)
		let message = `<p>Voulez-vous archiver cette commande, il y a des bons de livraisons (non facturés) ou des bons d'interventions (non facturés) associés ?</p>`

		if (attachedNotBilled.deliveryNotes.length) {
			message += `<p>Bons de livraisons :<p><ul>`
			message += attachedNotBilled.deliveryNotes.map(deliveryId => `<li>${deliveryId}</li>`)
			message += `</ul>`
		}

		if (attachedNotBilled.workOrders.length) {
			message += `<p>Bons d'interventions :<p><ul>`
			message += attachedNotBilled.workOrders.map(workOrderId => `<li>${workOrderId}</li>`)
			message += `</ul>`
		}

		try {
			const confirmationModal = new Confirm('Confirmation', message, { raw: true, yesText: 'Confirmer', 'noText': 'Annuler' })
			const needConfirmation = !!attachedNotBilled.deliveryNotes.length || !!attachedNotBilled.workOrders.length
			needConfirmation && await confirmationModal.open()
			await this.updateOrderStatus(data._id, state)
			await OrdersService.getInstance().archived(data._id)
			this.updateGrid()
			toastr.success(`La commande ${data._id} a été modifiée`)
		}
		catch (e) {
			console.error(e)
		}
	}
}

export default TabOrders;

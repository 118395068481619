import axios from 'axios'
import SitesService from './SitesService'
import StockEvent from '@js/types/stock-event'

export type GroupingStocksResult = {
	[idProduct: string] : number
}

export type ProductPerStorage = {
	product: string
	storage: string
	quantity: number
	quantityWastage: number
}

export type ProductPerStorageAndType = {
	product: string
	storage: string
	type: string
	quantity: number
	quantityWastage: number
}

export type ProductQuantityPerDate = {
	product: string
	key: Array<string>
	quantity: number,
	month: string
}

export type NumberOfProducterStorage = {
	key: Array<string>
	productId: string
	storageId: string
	numberOfLineProduct: number
}

/**
 * Permet de gérer les stocks events
 */
class StocksService {

	private static instance = new StocksService()

	private constructor() {}

	public static getInstance() {
		return StocksService.instance
	}


	/**
	 * Permet de récupérer la liste des identifiants de produits disponibles en fonction des sites, par défaut si les sites ne sont pas renseigné, il prendra les sites autorisés
	 * @param siteIds 
	 */
	public async getAvailableProductsIds(siteIds: string | Array<string> = []) : Promise<Array<string>> {
		let groupingData = await this.getGroupingDataQuantityPerProduct(siteIds)
		// EN SUSPENS : On filtre les éléments qui ont plus de zero et on récupère la clé
		// return Object.entries(groupingData).filter( r => r[1] > 0 ).map( r => r[0] )
		return Object.entries(groupingData).map( r => r[0] )
	}

	/**
	 * Permet de récupérer une vue qui contient la quantité en stock par produit
	 * Si le site n'est pas renseigné, il va prendre par defaut les sites autorisés par l'utilisateur
	 * Dans le cas d'un tableau de sites (plusieurs sites), vous aurez une somme de chaque produit
	 */
	public async getGroupingDataQuantityPerProduct(siteIds : string | Array<string> = []) : Promise<GroupingStocksResult> {

		let result: { [product: string]: number } = {}
		if (Array.isArray(siteIds)) {
			let sites = siteIds.length > 0 ? siteIds : SitesService.getInstance().getAllowedSitesString()

			// FIXME: le faire en parallele
			for (let site of sites) {
				const aGroupingDataQuantityPerProductFromSite = await this.getGroupingDataQuantityPerProductFromSite(site)

				// On va concaténé tous les produits
				for (let product in aGroupingDataQuantityPerProductFromSite) {
					result[product] = result[product] || 0
					result[product] += aGroupingDataQuantityPerProductFromSite[product]
				}

			}

		}
		else if (typeof siteIds == "string") {
			return await this.getGroupingDataQuantityPerProductFromSite(siteIds.toString())
		}

		return result
	}

	/**
	 * Permet de récupérer le groupement de données concernant les stocks par produit
	 * @param site 
	 */
	private async getGroupingDataQuantityPerProductFromSite(siteId: string) : Promise<GroupingStocksResult> {
		// retourne un tableau avec { key: string, value: number }
		// TODO: faire ça en API
		return axios.get(`/api/stock-event/grouping-data-product-from-site/${siteId}`).then((response) => response.data.data)
	}


	/**
	 * Récupère tous la liste du nombre de produits dans chaque emplacements par rapport à un site donnée
	 * @param siteId 
	 */
	public async getQuantitiesPerStorage(siteId: string) : Promise<Array<ProductPerStorage>> {
		return axios.get(`/api/stock-event/grouping-data-quantitiesByStorage-product-type/${siteId}`).then(response => response.data.data)
	}

	/**
	 * Permet la liste de tous du nombre de produits par types, par empalcements et par site
	 * @param siteID 
	 * @param storageId 
	 */
	public async getQuantitiesPerStorageWithType(siteId: string, storageId: string) : Promise<Array<ProductPerStorageAndType>> {
		return axios.post('/api/stock-event/grouping-data-quantitiesByStorage', {
			siteID: siteId,
			storage: storageId
		}).then(response => response.data.data)
	}

	/**
	 * Comme getQuantitiesPerStorageWithType, il va chercher une liste d'un produit sur un emplacement donné avec les différent types (output, input, alimentation)
	 * @param siteId 
	 * @param storageId 
	 * @param productId 
	 */
	public async getQuantityByStorageAndProductAndType(siteId: string, storageId: string, productId: string) : Promise<Array<ProductPerStorageAndType>> {
		// TODO: devrait faire une vue pour ça
		return this.getQuantitiesPerStorageWithType(siteId, storageId)
			.then(row => row.filter(data => data.storage === storageId && data.product === productId))
	}

	/**
	 * Permet de supprimer un stock-event
	 * @param id 
	 */
	public async delete(id: string) : Promise<void> {
		await axios.get(`/api/stock-event/tag-delete/${id}`)
	}

	public async update(data: StockEvent) : Promise<void> {
		await axios.put('/api/stock-event', data)
	}

	public async create(data: StockEvent): Promise<void> {
		await axios.post('/api/stock-event', data)
	}

	public async getAll() : Promise<Array<StockEvent>> {
		return axios.get('/api/stock-event').then(response => response.data.data)
	}

	/**
	 * Permet de récupérer toutes les alimentations entre deux dates ou non
	 * @param siteId 
	 * @param startingDate 
	 * @param endingDate 
	 */
	public async getAlimentationBySite(siteId: string, startingDate: number | null, endingDate: number | null) : Promise<Array<StockEvent>> {
		
		if (siteId && startingDate && endingDate) {
			return this._getAlimentationBySiteAndDate(siteId, startingDate, endingDate)
		}

		return this._getAlimentationBySite(siteId)
	}

	private async _getAlimentationBySite(siteId: string) : Promise<Array<StockEvent>> {
		return axios.get(`/api/stock-event/alimentation-by-site/${siteId}`)
			.then(response => response.data.data)
	}

	private async _getAlimentationBySiteAndDate(siteId: string, startingDate: number, endingDate: number) : Promise<Array<StockEvent>>  {
		return axios.get(`/api/stock-event/alimentation-by-site-and-date/${siteId}/${startingDate}/${endingDate}`)
			.then(response => response.data.data)
	}

	/**
	 * Permet de récupérer les stock-event par site, pr date de début et de fin
	 * @param siteId 
	 */
	public async getBySite(siteId: string, startingDate?: number, endingDate?: number) : Promise<Array<StockEvent>> {
		if (startingDate && endingDate) {
			return this._getBySiteAndByDate(siteId, startingDate, endingDate)
		}

		return this._getBySite(siteId)
	}

	private async _getBySite(siteId: string) : Promise<Array<StockEvent>> {
		return axios.get(`/api/stock-event/by-site/${siteId}`).then(response => response.data.data)
	}

	private async _getBySiteAndByDate(siteId: string, startingDate: number, endingDate: number) : Promise<Array<StockEvent>> {
		return axios.get(`/api/stock-event/by-site-and-date/${siteId}/${startingDate}/${endingDate}`).then(response => response.data.data)
	}

	public async getBySiteStorage(siteId: string, storageId: string) : Promise<Array<StockEvent>> {
		return axios.get(`/api/stock-event/by-site-storage/${siteId}/${storageId}`).then(response => response.data.data)
	}

	/**
	 * Permet de récupérer les alimentations par mois
	 * @param siteId 
	 * @param startingDate format YYYY_MM
	 * @param endingDate format YYYY_MM
	 */
	public async getAlimentationByMonth(siteId: string, startingDate: string | null = null, endingDate: string | null = null) : Promise<Array<ProductQuantityPerDate>> {
		if (siteId && startingDate && endingDate) {
			return this._getAlimentationByMonthAndDate(siteId, startingDate, endingDate)
		}

		return this._getAlimentationByMonth(siteId)
	}

	private async _getAlimentationByMonth(_siteId: string) : Promise<Array<ProductQuantityPerDate>> {
		throw new Error("Not Implemented Yet")
	}

	private async _getAlimentationByMonthAndDate(siteId: string, startingDate: string, endingDate: string) : Promise<Array<ProductQuantityPerDate>> {
		return axios.get(`/api/stock-event/alimentation-by-month/${siteId}/${startingDate}/${endingDate}`)
			.then(response => response.data.data)
	}


	/**
	 * Permet de récupérer la quantité de produits par mois
	 * @param siteId 
	 * @param startingDate format YYYY_MM
	 * @param endingDate format YYYY_MM
	 */
	public async getQuantitiesByProduct(siteId: string, startingDate: string | null = null, endingDate: string | null = null) : Promise<Array<ProductQuantityPerDate>> {
		
		if (!startingDate || !endingDate) {
			throw new Error('Not Implemented')
		}
		
		return axios.get(`/api/stock-event/quantities-by-month/${siteId}/${startingDate}/${endingDate}`)
			.then(response => response.data.data)
	}

	/**
	 * Permet de sauvegarder et mettre à jour plusieurs Stock Event en meme temps
	 * @param data 
	 */
	public async updateAll(data: Array<StockEvent>) : Promise<void> {
		await axios.put(`/api/stock-event/update-all/:null`, data)
	}

	/**
	 * Permet de récupérer le nombre de ligne stock-event par produit et par stockage
	 * @param siteId 
	 * @param storageId 
	 */
	public async getNumberOfProductPerStorage(siteId: string, storageId: string) : Promise<Array<NumberOfProducterStorage>> {
		return axios.get(`/api/stock-event/product-by-storage/${siteId}/${storageId}`).then(response => response.data.data)
	}
}

export default StocksService

import { Modal, global, LoggedUser, toaster } from "@autoprog/core-client"

import moment from 'moment'
import toastr from 'toastr'

import T_detailBillModal from '@tpl/modals/orders/detailBillModal.html'
import T_detailBillBlock from '@tpl/blocks/orders/bill/detailBill.ejs'

// import BillPrinter from '@libs/printers/BillPrinter'
import VATService from '@js/libs/services/VATService'

import BillType from '@js/types/billType'

import T_events from '@tpl/blocks/orders/detailEvent.ejs'
import _ from 'lodash'
import Tier from '@js/types/Tiers'
import BillsService from '@js/libs/services/BillsService'
import Order from '@js/types/order'
import OrdersService from '@js/libs/services/OrdersService'
import TiersService from '@js/libs/services/TiersService'

import stateList from '@libs/lists/stateListMale'

import { User } from '@js/types/user';
import UsersService from '@js/libs/services/UsersService'

import unitList from '../../lists/unitList'

import '../../../../css/billDetail.scss'
import StateTransaction from '@js/libs/lists/StateTransactionEnum'
import PrinterHelper from "@js/libs/utils/printer.helper"



interface DetailBillData extends BillType {
	customerData: Tier,
	providerData: Tier,
	orderData: Order
	dateString: string,
	eventUsers: Array<User>
}

class BillModal extends Modal {

	constructor(id: string) {

		super({
			tpl: T_detailBillModal,
			backdrop: 'static',
			keyboard: false
		});

		this.on('opened', async () => {

			let data = await this.getBill(id)

			data.dateString = moment(data.date).format('DD/MM/YYYY');

			let total = {
				tva: {} as { [key: string]: number },
				sum: 0,
				sumHT: 0
			};

			for (let item of data.products) {


				let vat = VATService.getInstance().getById(item.vat)

				if (vat) {
					item.quantity = _.round( item.quantity / unitList[item.unit].coeficient , 2)
					item.unitPrice = _.round( item.unitPrice * unitList[item.unit].coeficient , 2)
					item.ttcTotal = _.round(item.ttcTotal, 2);
					item.tvaPrice = _.round(item.tvaPrice, 2);
					item.vatRate = vat.percent

					total.tva[vat.percent] = total.tva[vat.percent] || 0;

					total.tva[vat.percent] += item.tvaPrice, 2;
					total.sum += item.ttcTotal;
					total.sumHT += item.ttcTotal - item.tvaPrice;

				}
				else {
					toaster.error('TVA inconnue');
				}
			}
			total.sum = _.round(total.sum, 2);
			total.sumHT = _.round(total.sumHT, 2);

			for (let tva in total.tva) {

				total.tva[tva] = _.round(total.tva[tva], 2);

			}

			let N_modalBody = this.element.querySelector('.modal-body');

			if (N_modalBody) {

				const files_url = global.COUCHDB_URL
				N_modalBody.innerHTML = T_detailBillBlock({
					data,
					total,
					global,
					files_url
				})


				const N_attachments = this.element.querySelectorAll('[data-url]') as NodeListOf<HTMLElement>
				N_attachments.forEach( el => el.addEventListener2('click', () => window.open(el.dataset.url, '_blank')) )

			}







			const N_edit = this.element.querySelector('#edit-button')!
			// On ne peut pas modifier quand l'etat est en paye ou qu'il n'a pas tous les droit de changement d'etat
			if ( (data.state === StateTransaction.paid || data.state === StateTransaction.archived) && !LoggedUser.getInstance().hasPermission('ORDERS.BILL.EDIT_PAID')) {
				N_edit.classList.add('d-none')
			} else {
				N_edit.addEventListener2('click', () => this.resolve(true))
			}
			



			// Les boutons d'impressions
			const N_print = this.element.querySelector('#print') as HTMLButtonElement
			const N_printBLBI = this.element.querySelector('#print-bl-bi') as HTMLButtonElement
			const N_printBC = this.element.querySelector('#print-bc') as HTMLButtonElement
			const N_printALL = this.element.querySelector('#print-all') as HTMLButtonElement

			const print = async (options = { workOrders: false, order: false, deliveryNotes: false }) => {
				try {
					const blob = await BillsService.getInstance().getPDF(id, options)
					PrinterHelper.previewPDF(blob, `Facture-${id}-${moment().format('YYYY-MM-DD')}.pdf`)
				}
				catch (e) {
					toastr.error(`Erreur lors de l'impression`)
					console.error(e)
				}
			}

			N_print.addEventListener('click', () => print())
			N_printBLBI.addEventListener('click', () => print({ deliveryNotes: true, workOrders: true, order: false }))
			N_printBC.addEventListener('click', () => print({ deliveryNotes: false, workOrders: false, order: true }))
			N_printALL.addEventListener('click', () => print({ deliveryNotes: true, workOrders: true, order: true }))

			this.initEvent(data.events.map(event => ({...event, user: data.eventUsers.find(u => u._id === event.user)  })) || [])
		})


	}

	private initEvent(events: any[]) {

		const N_container = this.element.querySelector('#events-bills')

		if (N_container) {
			N_container.innerHTML = T_events({
				events,
				moment,
				stateList
			})
		}
	}


	private async getBill(id: string) : Promise<DetailBillData> {
		const bill = await BillsService.getInstance().getByID(id)
		const orderData = await OrdersService.getInstance().getByID(bill.orders[0])

		const providerData = await TiersService.getInstance().getByID(orderData.provider)
		const customerData = await TiersService.getInstance().getByID(orderData.customer)

		const userids = Array.from(new Set(bill.events.map(event => event.user)))
		const users = await UsersService.getInstance().getByIds(userids)

		return {
			...bill,
			customerData,
			providerData,
			orderData, 
			dateString: "",
			eventUsers: users || []
		}
	}
}

export default BillModal 

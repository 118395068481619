import Digestats from "@js/types/digestats"
import axios from "axios"

export type DictionaryDigestatStorageInformation = { [storage: string] : DigestatStorageInformation }

export interface DigestatStorageInformation {
	storage: string
	quantity: number
	maxDate: number
	minDate: number
	diffDay: number
	quantityPerDay: number
}

export type DigestatQuantityPerStorage = {
	quantity: number
	storage: string
}

class DigestatsService {
	private static readonly instance: DigestatsService = new DigestatsService()
	private constructor() {}

	public static getInstance() : DigestatsService {
		return DigestatsService.instance
	}

	/**
	 * Permet de mettre à jour ou d'insérer des données
	 * @param data 
	 */
	public async updateAll(data: Array<Digestats>) {
		await axios.put(`/api/digestats/update-all/:null`, data)
	}

	public async delete(id: string) : Promise<void> {
		await axios.get(`/api/digestats/tag-delete/${id}`)
	}

	/**
	 * Permet de récupérer le dernier Digestat en fonction du site et de l'emplacement
	 * @param siteId le dernier 
	 * @param storageId 
	 */
	public async getLastDigestatFromStorage(siteId: string, storageId: string) : Promise<Digestats> {
		return await axios.get(`/api/digestats/digestats/digestat-from-storage/${siteId}/${storageId}`).then(response => response.data.data)
	}

	/**
	 * Permet de récupérer les quantités par emplacement sur un site donné
	 * @param siteId 
	 */
	public async getQuantityPerStorage(siteId: string) : Promise<Array<DigestatQuantityPerStorage>> {
		return axios.get(`/api/digestats/quantity-per-storage/${siteId}`).then(response => response.data.data)
	}

	public async getBySite(siteId: string, startingDate: number | null = null, endingDate: number | null = null) : Promise<Array<Digestats>> {
		if (siteId && startingDate && endingDate) {
			return this._getBySiteAndDate(siteId, startingDate, endingDate)
		}

		throw '_getBySite : Not Implemented Yet'
	}

	private _getBySiteAndDate(siteId: string, startingDate: number, endingDate: number) : Promise<Array<Digestats>> {
		return axios.get(`/api/digestats/by-site-and-date/${siteId}/${startingDate}/${endingDate}`)
			.then(response => response.data.data)
	}

	/**
	 * Permet de récupérer des statistiques sur un site donnée, les informations disponible sur le stockage
	 * REMARK: Si un emplacement donnée n'exite pas, cela ne veut pas dire que l'emplacement n'existe pas mais qu'il y aucune données digestats pur faire des stats sur l'emplacement
	 * Il faudra donc vérifier vous-meme que l'emplacement possede des données ou non
	 * @param siteId 
	 */
	public async getQuantityTotalPerDay(siteId: string) : Promise<DictionaryDigestatStorageInformation> {
		return axios.get(`/api/digestats/by-site-storage-information/${siteId}`)
			.then(response => response.data.data)
	}
}

export default DigestatsService
function removeAccent(str: string) {
	return str
		.toLowerCase()
		.replace(/\s/g, '')
		.replace(/[àáâãäå]/g, 'a')
		.replace(/æ/g, 'ae')
		.replace(/ç/g, 'c')
		.replace(/[èéêë]/g, 'e')
		.replace(/[ìíîï]/g, 'i')
		.replace(/ñ/g, 'n')
		.replace(/[òóôõö]/g, 'o')
		.replace(/œ/g, 'oe')
		.replace(/[ùúûü]/g, 'u')
		.replace(/[ýÿ]/g, 'y')
		.replace(/\W/g, '');
}

function GetQuickFilterAccentText(params: any) {
	if (params.value) {
		return removeAccent(params.value.toString())
	}
	
	return ""
}

export default GetQuickFilterAccentText
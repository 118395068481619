import axios from 'axios'
import _ from 'lodash'


export type SubAnalytic = {
	_id: string,
	_rev?: string,
	name: string,
	deleted?: boolean
	locked: boolean
}

class SubAnalyticsService {

	private static instance = new SubAnalyticsService();

	private subAnalytics: SubAnalytic[];

	private constructor() {
		this.subAnalytics = [];
	}

	public static getInstance() {
		return SubAnalyticsService.instance;
	}

	public async load() : Promise<void> {
		try {
			const { data } = await axios.get('/api/sub-analytics')


			if (data && !data.error) {
				this.subAnalytics = data.data;
			} else {
				this.subAnalytics = [];
				throw data.error;
			}
		} catch (e) {
			console.log(e);
		}
	}

	public getAll() : Array<SubAnalytic> {
		return _.cloneDeep(this.subAnalytics);
	}

	public getAllAvailable() : Array<SubAnalytic> {
		return this.getAll().filter(subAnalytic => !subAnalytic.deleted)
	}

	public getById(id: string): SubAnalytic | null {
		return _.find(this.subAnalytics, { _id: id }) || null;
	}

	public getByIds(ids: Array<string>): Array<SubAnalytic> {
		return this.subAnalytics.filter( subAnalytic => ids.indexOf(subAnalytic._id) >= 0 )
	}


	public async update(id: string, analytics: SubAnalytic) : Promise<void> {

		const { data } = await axios.put('/api/sub-analytics/', {
			...analytics,
			_id: id
		})

		if (data && !data.error) {
			this.subAnalytics = data.data;
		} else {
			this.subAnalytics = [];
			throw data.error;
		}

	}

	public async add(analytics: SubAnalytic) : Promise<void> {

		let { data } = await axios.post('/api/sub-analytics/', analytics)

		if (data && !data.error) {
			this.subAnalytics = data.data;
		} else {
			this.subAnalytics = [];
			throw data.error;
		}

	}

	public async delete(ID: string) : Promise<void> {

		let { data } = await axios.delete(`/api/sub-analytics/${ID}`)

		if (data && !data.error) {
			this.subAnalytics = data.data;
		} else {
			this.subAnalytics = [];
			throw data.error;
		}
	}


}

export default SubAnalyticsService;

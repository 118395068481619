import _ from "lodash"
import T_tableditor from "../../../../tpl/components/table-editor.html"
import TableEditorRow, { TableEditorRowStructure } from "./TableEditorRow"
import { StructureDefinition } from "@js/libs/utils/table-editor.helper"
import BDD from "@js/libs/BDD"

import '@css/table-editor.scss'

// TODO: creer un systeme de tags pour gerer les doublons de custom input

class TableEditor extends HTMLElement {

	public static readonly tagName = "table-editor"

	private _definitions: Array<StructureDefinition>
	private body: HTMLElement
	private _duplicated: boolean
	private _model: string
	private _isLoading: boolean

	constructor() {
		super()

		this._definitions = []

		this.classList.add('flex-grow-1')
		this.style.display = 'flex'

		this.innerHTML = T_tableditor
		this.body = this.querySelector('tbody') as HTMLElement
		this.draggable = false
		this._duplicated = false
		this._model = BDD.generateID()
		this._isLoading = false
		this.init();
	}

	get definitions(): Array<StructureDefinition> {
		return this._definitions
	}

	set definitions(lists: Array<StructureDefinition>) {
		this._definitions = lists
		this.draw()
	}

	get values(): Array<TableEditorRowStructure> {
		// Pour le futur, modifier l'ordres des checklists
		return this.rows().map(node => node.value)
	}

	set values(data: Array<TableEditorRowStructure>) {
		// REMARK, on stock jamais l'information, cela nous evite deouble gerer les donnees et gerer plus facilement le drag and drop
		this.draw(data)
	}

	get duplicated(): boolean {
		return this._duplicated
	}

	set duplicated(value: boolean) {
		this._duplicated = value
	}

	get model(): string {
		return this._model
	}

	set model(value: string) {
		this._model = value
	}

	private rows(): Array<TableEditorRow> {
		return Array.from(this.querySelectorAll<TableEditorRow>(`tr[data-is="${TableEditorRow.tagName}"]`))
	}

	public isEmpty(): boolean {
		return this.values.length <= 0
	}

	public async connectedCallback() {
		this.draw()
		this._duplicated = this.hasAttribute("duplicated") && this.getAttribute('duplicated') === "" || this.getAttribute('duplicated') === "true"
	}

	private setLoading(value: boolean) {
		this._isLoading = value

		const N_addrow = this.querySelector('button.add-btn-row') as HTMLButtonElement
		N_addrow.disabled = value
	}

	private async draw(structures?: Array<TableEditorRowStructure>) {
		// TODO: ajouter un petit loader pour le temps que sa charge
		this.setLoading(true)
		this.body.innerHTML = ''
		const documentFragment = document.createDocumentFragment()

		const inputs: Array<TableEditorRow> = []
		if (structures) {
			await this.generateInputs(structures).then(newInputs => inputs.push(...newInputs))
			inputs.forEach(input => documentFragment.appendChild(input))
		}

		this.body.appendChild(documentFragment)
		await Promise.all(inputs.map(input => {
			input.draw()
			return Promise.resolve(input)
		})) // On force le UI a charger car il ne le fait pas tant qu'il nest pas connecte au body
		this.setLoading(false)
	}

	private async generateInputs(structures: Array<TableEditorRowStructure>): Promise<Array<TableEditorRow>> {
		// On ne sait pas combien de temps cela va durer de creer juste un element, alors on fait des promises et on fait tout en parallele
		return Promise.all(structures.map(structure => {
			const N_row = TableEditorRow.createElement()
			N_row.valueChangedCallback = this.onChangeValue.bind(this)
			N_row.value = structure
			N_row.model = this._model
			return Promise.resolve(N_row)
		}))
	}

	public static register() {
		customElements.define(this.tagName, TableEditor)
		TableEditorRow.register() // Par defaut, il doit etre enregistrer
	}

	private init() {
		this.initAddRow();
		this.initDragAndDrop();
	}

	private initAddRow() {
		const N_addrow = this.querySelector('button.add-btn-row') as HTMLButtonElement
		N_addrow.disabled = false
		N_addrow.addEventListener('click', () => {
			const N_row = TableEditorRow.createElement()
			N_row.valueChangedCallback = this.onChangeValue.bind(this)
			N_row.model = this._model
			this.body.appendChild(N_row)
		})
	}

	private onChangeValue(element: TableEditorRow, _newValue: string) {
		this.clear()
		_.isEqual(element.type === 'input' && !this._isLoading, true) && this.checkValidityCustomInputs()
	}

	public checkValidity(): boolean {
		this.clear()
		return this.checkValidityCustomInputs()
	}

	private clear() {
		const classes = ['bg-red-100']
		this.querySelectorAll<TableEditorRow>(`tr[data-is="${TableEditorRow.tagName}"]`).forEach(row => row.classList.remove(...classes))
	}

	private checkValidityCustomInputs(): boolean {
		if (this._duplicated) { return true }

		const inputs = this.values

		const customInputsValues = inputs.filter(input => input.type === 'input').map(input => ({ id: input.id, value: input.value }))
		const countValues = _.countBy(customInputsValues.map(input => input.value))
		const duplicatedValues = Object.keys(countValues).filter(value => countValues[value] > 1)

		const invalidRowIds = customInputsValues.filter(input => duplicatedValues.includes(input.value)).map(input => input.id)

		const corruptedInputs = Array.from(this.querySelectorAll<TableEditorRow>(`tr[data-is="${TableEditorRow.tagName}"]`)).filter(input => invalidRowIds.includes(input.id))
		corruptedInputs.forEach(input => input.classList.add('bg-red-100'))

		_.isEqual(!!invalidRowIds.length, true) && this.triggerError(400, "Duplicated Values for custom inputs")

		return invalidRowIds.length <= 0
	}

	private triggerError(code: number, message: string) {
		this.dispatchEvent(new CustomEvent('error', { detail: { code, message } }))
	}

	private initDragAndDrop() {

		let hoveredRow: TableEditorRow | null = null;
		let draggedId: string | null = null;
		let position: 'beforebegin' | 'afterend' | null = null;
		let N_draggedRow: HTMLElement | null = null;


		const N_ghost: HTMLElement | null = document.createElement('tr');
		N_ghost.classList.add('ghost');

		this.addEventListener('dragover', (e) => {

			// let dragOverEl: HTMLElement | null = e.target as (HTMLElement | null); //TODO: Regarder pourquoi le cast est obligatoire
			// draggedId = e.dataTransfer?.getData('text/plain') || null
			draggedId = this.querySelector(`tr[data-is="${TableEditorRow.tagName}"][data-dragging=true]`)?.id ?? null

			if (draggedId) {
				N_draggedRow = this.querySelector(`#${draggedId}`);
				N_ghost.style.height = `${N_draggedRow?.getBoundingClientRect().height}px`
			}

			//Recherche du TR survolé
			// while (dragOverEl) {
			// 	if (dragOverEl instanceof TableEditorRow) {
			// 		hoveredRow = dragOverEl;
			// 		break;
			// 	}
			// 	dragOverEl = dragOverEl.parentElement;
			// }
			
			hoveredRow = (e.target as HTMLElement).closest(`tr[data-is="${TableEditorRow.tagName}"]`) as TableEditorRow

			if (hoveredRow) {
				if (hoveredRow.id != draggedId) {
					const rect = hoveredRow.getBoundingClientRect();

					//Si le dragOver est dans la partie motié haute du TR survolé, on insere la ligne fantome au dessus
					if ((e.clientY - rect.y) < (rect.height / 2)) {
						if (hoveredRow.previousElementSibling?.id != draggedId) { //Sauf si la ligne précédente est la ligne que l'on déplace
							hoveredRow.insertAdjacentElement('beforebegin', N_ghost);
							position = 'beforebegin';
						} else {
							N_ghost.remove();
							position = null;
						}
					} else {//Si le dragOver est dans la partie motié basse du TR survolé, on insere la ligne fantome au dessous
						if (hoveredRow.nextElementSibling?.id != draggedId) { //Sauf si la ligne suivante est la ligne que l'on déplace
							hoveredRow.insertAdjacentElement('afterend', N_ghost);
							position = 'afterend';
						} else {
							N_ghost.remove();
							position = null;
						}
					}

				} else {
					N_ghost.remove();
					position = null;
				}
			}

			//Pour ne pas empécher l'évenement de drop
			e.preventDefault();
		})

		this.addEventListener2('dragend', () => {
			N_ghost.remove();
		});

		this.addEventListener('drop', (e) => {
			// console.log(draggedId)
			const N_draggedRow = this.querySelector(`#${draggedId}`);

			// console.log(hoveredRow, N_draggedRow, position)
			if (N_draggedRow && position) {
				N_ghost.replaceWith(N_draggedRow)
			}

			e.preventDefault();
		});
	}
}

export default TableEditor

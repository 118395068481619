import { Modal, Form, global } from "@autoprog/core-client"

import { Grid, GridOptions, ICellRendererParams } from 'ag-grid-community'
import toastr from 'toastr'
import FilesManager from '@js/libs/FilesManager'
import moment, { Moment } from 'moment'

import T_EditOrders from '@tpl/modals/orders/addEditOrders.html'

import Select2CellEditor from '@libs/agGrid/Select2CellEditor'
import NumericCellEditor from '@libs/agGrid/NumericCellEditor'
import { Options } from 'select2'

import OrdersService from '@libs/services/OrdersService'

import unitList from '@libs/lists/unitList'

import drpC from '@libs/utils/daterangepickerConfig'
import { french as agGridFrench } from '@libs/utils/agGrid'
import AccountingAccountsService from '@js/libs/services/AccountingAccountsService'
import UsersService from '@js/libs/services/UsersService'
import Order, { orderedProduct } from '@js/types/order'
import TiersService from '@js/libs/services/TiersService'

import _ from 'lodash'
import EventsManager, { EventsManagerEvent } from '@js/libs/EventsManager'

interface OrderDataDetail extends Order {
	deliveryDateMoment: Moment
	orderDateMoment: Moment
}

// Ajoutez tous les informations que vous garder en memoire
// Le but c'est de ne pas stocker tous la commande dans le controller
type EditOrdersAdditionalInformation = {
	prevalidated?: boolean
	validated?: boolean
}

class EditOrders extends Modal {

	private gridOptions: GridOptions = {}
	private N_gridOrderProducts?: HTMLElement
	private filesManager: FilesManager = new FilesManager();
	private formOrder?: Form

	private canEditProducts: boolean = true
	private additionalInformations: EditOrdersAdditionalInformation

	private rightPartSelectected: any = ""

	public constructor(private orderId?: string, private isDuplicationMode: boolean = false) {

		super({
			tpl: T_EditOrders,
			backdrop: 'static',
			keyboard: false
		})

		this.additionalInformations = { prevalidated: false, validated: false }

		this.on('opened', () => {

			setTimeout(async () => {

				try {

					const N_form = this.element.querySelector("#add-order-form") as HTMLFormElement;
					this.formOrder = new Form(N_form)

					$(this.element).find('.date-picker').daterangepicker(drpC({
						singleDatePicker: true,
					}))

					const N_attachments = this.element.querySelector('#attachments') as HTMLElement;
					N_attachments.appendChild(this.filesManager.getNode());

					this.createAgGrid()
					this.updateType()
					await this.createSelect2()

					//On récupère le site-select qui est forcement dans la partie gauche
					const leftPart = this.element.querySelector('.customer-provider-left > .form-control') as HTMLSelectElement;

					leftPart.addEventListener2('change', async () => {
						this.saveCustomerProviderChoice()
						await this.createSelect2()
						this.loadCustomerProviderChoice()
					});

					(this.element.querySelector('#change-type-btn') as HTMLButtonElement).addEventListener2('click', async () => {

						if (this.formOrder) {
							let type = this.formOrder.getDataByName('type') == 'input' ? 'output' : 'input'
							this.formOrder.setDataByName('type', type)
						}

						this.saveCustomerProviderChoice()
						await this.updateType()
						await this.createSelect2()
						this.loadCustomerProviderChoice()
					})

					const N_addLine = this.element.querySelector("#addproductLine") as HTMLButtonElement;
					N_addLine.addEventListener2("click", () => {
						this.gridOptions.api?.applyTransaction({ add: [{}] })
					})

					const N_save = this.element.querySelector('#save') as HTMLButtonElement
					N_save.addEventListener2('click', () => this.save())

					this.orderId && await this.loadData()

					// Permet de avoir si on peut editer les produits ou non
					await this.evaludateAreProductsEditable()
					N_addLine.disabled = !this.canEditProducts
					this.refreshGridRules()

				}
				catch (e) {
					console.error(e)
				}
			})

		})
	}

	private async loadData(): Promise<void> {
		const order = await this.getRowData()

		if (this.isDuplicationMode) {
			Reflect.deleteProperty(order, 'orderNumber')

			order.prevalidated = false
			order.validated = false

			order.products = order.products?.map((pro, i) => {
				pro.rowID = `${i}_${Date.now().toString(36)}`
				return pro
			})
		}

		if (this.formOrder) {
			try {
				await this.updateType()
				await this.createSelect2()
				this.formOrder.setData({
					...order as any,
					infiniteOrderBudget: order.isInfiniteBudget || false
				})
			}
			catch (e) {
				toastr.error('Erreur lors du remplissage du formulaire');
				console.error(e)
			}
		}

		const productRows = order.products?.map(product => ({
			..._.omit(product, 'quantity', 'unitPrice'),
			quantity: product.quantity / unitList[product.unit].coeficient,
			unitPrice: product.unitPrice * unitList[product.unit].coeficient,
			totalHT: (product.quantity / unitList[product.unit].coeficient) * product.unitPrice * unitList[product.unit].coeficient
		})) || []

		this.gridOptions.api?.setRowData(productRows)

		for (let i = 0; i < productRows.length; i++) {
			this.gridOptions.api?.startEditingCell({
				rowIndex: i,
				colKey: 'quantity'
			})
			this.gridOptions.api?.stopEditing()
		}

		order._attachments && this.filesManager.setFiles(order._attachments)

		this.additionalInformations.prevalidated = order.prevalidated
		this.additionalInformations.validated = order.validated
	}

	// REMARK pour une bonne pratiquer, il faut créer un type personnalisé
	private async getRowData(): Promise<OrderDataDetail> {
		const order = await OrdersService.getInstance().getByID(this.orderId!)
		return {
			...order,
			deliveryDateMoment: moment(order.deliveryDate),
			orderDateMoment: moment(order.orderDate)
		}
	}

	private async save(): Promise<void> {
		if (this.formOrder && this.checkValidity()) {
			try {
				const orderId = await this.insertOrder()
				await OrdersService.getInstance().syncAttachment(orderId, this.filesManager.getArrayFiles())
				toastr.success(this.orderId ? 'Commande modifiée' : 'Commande ajoutée')
				this.resolve()
			}
			catch (e) {
				toastr.error('Erreur de base de données')
				console.error(e)
				this.reject()
			}
		}
	}

	private createAgGrid(): void {

		this.N_gridOrderProducts = this.element.querySelector('#grid-orderProducts') as HTMLElement
		const N_addLine = this.element.querySelector("#addproductLine") as HTMLButtonElement

		this.gridOptions = agGridFrench({
			//définition des colonnes du tableau
			columnDefs: [
				{
					headerName: 'Produit',
					field: 'reference',
					editable: (_params) => this.canEditProducts,
					cellEditor: Select2CellEditor,
					cellEditorParams: (_params: any) => { // requete ajax sur la base products

						return {
							options: ({
								ajax: {
									url: `api/products/search/select2`,
									data: function (params) {
										return {
											...params,
											site: global.SITE
										}
									}
								}, dropdownParent: $(this.N_gridOrderProducts as HTMLElement)
							} as Options)
						}
					}
				},
				{
					headerName: 'Quantité',
					field: 'quantity',
					width: 100,
					cellEditor: NumericCellEditor,
					editable: (_params) => this.canEditProducts
				},
				{
					headerName: 'PU HT',
					field: 'unitPrice',
					cellEditor: NumericCellEditor,
					editable: (_params) => this.canEditProducts,
					width: 100,
					cellRenderer: (params) => {
						const valueStr = params.value === 0 ? '0' : (_.round(params.value, 2) || '').toString()
						return valueStr + '€';
					}
				},
				{
					headerName: 'Unité',
					field: 'unit',
					width: 75,
					editable: (params) => params.data.reference && params.data.type === 'custom' && this.canEditProducts,
					cellEditor: 'agRichSelectCellEditor',
					cellEditorParams: {
						values: _.keys(unitList),
						cellRenderer: (params: ICellRendererParams) => unitList[params.value]?.name || ''
					},
					cellRenderer: (params: ICellRendererParams) => unitList[params.value]?.name || params.value
				},
				{
					headerName: 'Total HT',
					field: 'totalHT',
					width: 75,
					editable: false,
					cellRenderer: (params) => _.round(params.value, 2).toString() + '€'
				},
				{
					headerName: '',
					field: 'suppress',
					editable: false,
					hide: !this.canEditProducts,
					width: 38,
					cellRenderer: (params: ICellRendererParams) => {
						const div = document.createElement('div') as HTMLElement
						if (!params.node.rowPinned) {

							const N_delete = document.createElement('button') as HTMLButtonElement

							N_delete.innerHTML = `<span aria-hidden="true">&times;</span>`
							N_delete.setAttribute('type', 'button')
							N_delete.setAttribute('confirmation', '')
							N_delete.classList.add('close')

							N_delete.addEventListener2('click', () => {
								params.api.applyTransaction({ remove: [params.node.data] })
							})

							div.appendChild(N_delete)
						}

						return div
					}
				}
			],
			suppressDragLeaveHidesColumns: true,
			singleClickEdit: true,
			rowData: [],
			onCellValueChanged: (params) => {

				if (params.colDef.field === 'reference') {

					if (typeof params.newValue === 'string') {
						params.data.reference = params.newValue
					}
					else {
						params.data.reference = params.value.text
						params.data.id = params.value.id

						if (params.value.product) {
							params.data.unit = params.value.product.unit

							params.data.analytics = params.value.product.analytics
							params.data.subAnalytics = params.value.product.subAnalytics
							params.data.accountingAccount = params.value.product.accountingAccount

							params.data.unitPrice = params.value.product.price * unitList[params.value.product.unit].coeficient
						}

						const numberLine = params.api.getModel().getRowCount()
						const accountingAccount = AccountingAccountsService.getInstance().getById(params.value.product.accountingAccount)

						N_addLine.disabled = (params.value.product && accountingAccount?.isRawMaterial && numberLine === 1)
					}
				}

				if (params.colDef.field === 'quantity' && !params.newValue) {
					params.data.quantity = params.oldValue
				}

				if (params.colDef.field == 'unitPrice') {
					const acceptZero = this.acceptZero(params.data.accountingAccount)
					if (typeof params.newValue !== 'number' || params.newValue < 0 || (params.newValue === 0 && !acceptZero)) {
						params.data.unitPrice = params.oldValue
					}
				}

				if (params.data.quantity) {
					params.data.totalHT = params.data.quantity * params.data.unitPrice;
				}

				params.node.setData(params.data)

				if (params.data.quantity) {

					let subTotal = 0;
					params.api.forEachNode(rowNode => {
						if (rowNode.data.totalHT) {
							subTotal += rowNode.data.totalHT
						}
					})

					params.api.setPinnedBottomRowData([{
						unitPrice: 'Sous Total',
						totalHT: subTotal,
					}])
				}

			},
			defaultColDef: {
				editable: (params) => !!params.data.reference
			},
			rowClassRules: {
				'ag-row-danger': (params) => params.data?.error
			},
			onGridReady: () => this.gridOptions.api?.sizeColumnsToFit()
		})

		this.N_gridOrderProducts && new Grid(this.N_gridOrderProducts, this.gridOptions)
	}

	private async updateType(): Promise<void> {

		if (this.formOrder) {
			const type = this.formOrder.getDataByName('type')

			const leftPart = this.element.querySelector('.customer-provider-left') as HTMLElement
			const rightPart = this.element.querySelector('.customer-provider-right') as HTMLElement

			if (type == 'input') {

				(leftPart.querySelector('.form-control') as HTMLInputElement).name = 'customer';
				(rightPart.querySelector('.form-control') as HTMLInputElement).name = 'provider';

				leftPart.classList.add('order-1')
				rightPart.classList.add('order-3')

				leftPart.classList.remove('order-3')
				rightPart.classList.remove('order-1')

			}
			else if (type == 'output') {

				(leftPart.querySelector('.form-control') as HTMLInputElement).name = 'provider';
				(rightPart.querySelector('.form-control') as HTMLInputElement).name = 'customer'

				leftPart.classList.add('order-3')
				rightPart.classList.add('order-1')

				leftPart.classList.remove('order-1')
				rightPart.classList.remove('order-3')
			}

			this.formOrder.updateInputs()
		}
	}

	private async createSelect2(): Promise<void> {
		if (this.formOrder) {
			const type = this.formOrder.getDataByName('type')

			const labelSite = type == 'input' ? 'customer' : 'provider'
			const siteId = this.formOrder.getDataByName(labelSite) as string

			//On récupère le select2 qui est forcement dans la partie droite
			const rightPart = this.element.querySelector('.customer-provider-right > .form-control') as HTMLInputElement

			const tiers = await TiersService.getInstance().getBySite(siteId).then(lists => _.sortBy(lists, 'name'))

			const data = tiers.map(tier => ({ id: tier._id, text: tier.name }));

			rightPart.innerHTML = ''
			const el = $(rightPart)
			el.select2({
				data,
				dropdownParent: el.parent()
			})
		}
	}

	/**
	 * Dans cette fonction, on sauvegarde le choix des tiers que l'utilisateur a choisi
	 */
	private saveCustomerProviderChoice(): void {
		let rightPart = this.element.querySelector('.customer-provider-right > .form-control') as HTMLInputElement
		this.rightPartSelectected = $(rightPart).val()
	}

	private loadCustomerProviderChoice(): void {
		const rightPart = this.element.querySelector('.customer-provider-right > .form-control') as HTMLInputElement
		$(rightPart as HTMLElement).val(this.rightPartSelectected).trigger('change')
	}

	private async insertOrder(): Promise<string> {

		//On récupère les champs du formulaire
		if (!this.formOrder) {
			throw new Error('Form Order est indisponible')
		}

		const formData = this.formOrder.getData()

		//On récupère les lignes du tableau des produits livrées
		const rows = [] as Array<any>
		let total = 0

		if (this.gridOptions.api) {
			this.gridOptions.api.forEachNode((rowNode) => {
				total += rowNode.data.totalHT
				delete rowNode.data.totalHT
				delete rowNode.data.error
				rows.push(rowNode.data)
			})
		}

		// Il faut qu'il est des lignes de produits dans la commande pour continuer
		if (!rows.length) {
			throw new Error("Pas de produits.")
		}

		//On met la quantité délivrée à la bonne unité

		for (let i = 0; i < rows.length; i++) {
			rows[i].quantity = rows[i].quantity * unitList[rows[i].unit].coeficient
			rows[i].unitPrice = rows[i].unitPrice / unitList[rows[i].unit].coeficient
			rows[i].rowID = rows[i].rowID || `${i}_${Date.now().toString(36)}`
		}

		const orderLimit = UsersService.getInstance().orderLimit()
		const hasBeenValidated = this.additionalInformations && (this.additionalInformations.prevalidated || this.additionalInformations.validated)

		let orderBDDData: Order = {
			customer: formData.customer as string,
			provider: formData.provider as string,
			deliveryDate: parseInt((formData.deliveryDateMoment as moment.Moment).format('x'), 10),
			orderDate: parseInt((formData.orderDateMoment as moment.Moment).format('x'), 10),
			deleted: false,
			state: (total <= orderLimit || hasBeenValidated) ? 'notDelivered' : 'toConfirm',
			detail: formData.detail as string,
			events: [],
			nature: "",
			site: (formData.type == "input" ? formData.customer : formData.provider) as string,
			type: formData.type as string,
			products: rows.map(row => {
				return {
					id: row.id,
					quantity: row.quantity,
					reference: row.reference,
					deliveredQuantity: 0,
					rowID: row.rowID,
					unitPrice: row.unitPrice,
					unit: row.unit,
					accountingAccount: row.accountingAccount,
					analytics: row.analytics,
					subAnalytics: row.subAnalytics
				} as orderedProduct
			}),
			orderNumber: "",
			_id: "",
			isInfiniteBudget: formData.infiniteOrderBudget as boolean
		}

		try {
			// Dans le cas d'une edition
			if (this.orderId && !this.isDuplicationMode) {
				orderBDDData._id = this.orderId
				orderBDDData.orderNumber = this.orderId

				// REMARK: peut être trouver quelque chose de plus optimal
				const oldOrder = await OrdersService.getInstance().getByID(this.orderId, true)
				orderBDDData.events = oldOrder.events

				// Hidden informations (exemple: email sent), information qui n'a pas besoin d'etre cache dans le formulaire
				orderBDDData.prevalidated = oldOrder.prevalidated
				orderBDDData.validated = oldOrder.validated
				orderBDDData.hasSendEmailValidated = oldOrder.hasSendEmailValidated
				orderBDDData.hasSentEmailPrevalidated = oldOrder.hasSentEmailPrevalidated

				const event: EventsManagerEvent = {
					type: 'edit',
				}

				if (oldOrder.state !== orderBDDData.state) {
					event.changeState = {
						from: oldOrder.state,
						to: orderBDDData.state
					}
				}

				orderBDDData = EventsManager.addEvent(orderBDDData, event)
				await OrdersService.getInstance().update(orderBDDData)
			}
			else {

				// Dans le cas d'un ajout
				orderBDDData._id = await OrdersService.getInstance().getAvailableID();
				orderBDDData.orderNumber = orderBDDData._id

				orderBDDData = EventsManager.addEvent(orderBDDData, 'create')
				await OrdersService.getInstance().create(orderBDDData)
			}

			return orderBDDData._id
		}
		catch (e) {
			throw new Error(e)
		}
	}

	private checkValidity(): boolean {

		if (this.formOrder && !this.formOrder.checkValidity()) {
			return false
		}

		let pass = true;
		if (this.gridOptions.api) {

			this.gridOptions.api.forEachNode(node => {
				if (!node.data.unit || !node.data.quantity) {
					pass = false
					node.data.error = true
				}
				else {
					node.data.error = false
				}
				node.setData(node.data)
			})
		}

		return pass
	}

	/**
	 * Va evaluer si les produits commande est editable
	 */
	private async evaludateAreProductsEditable() {

		// Les produits de la commande n'est pas editable
		// REMARK: dans un futur, que l'etat de validation ou prevalidation d'une commande ne sera pas le seul critere pour empecher edition des produits (pensez  a effacer ce commentaire dans le futur)
		this.canEditProducts = !this.additionalInformations || (!this.additionalInformations.prevalidated && !this.additionalInformations.validated)
	}

	private refreshGridRules() {
		if (this.gridOptions.api && this.gridOptions.columnApi) {
			const colReference = this.gridOptions.api.getColumnDef('reference')!
			const colQuantity = this.gridOptions.api.getColumnDef('quantity')!
			const colUnitPrice = this.gridOptions.api.getColumnDef('unitPrice')!
			const colUnit = this.gridOptions.api.getColumnDef('unit')!
			const colSuppress = this.gridOptions.api.getColumnDef('suppress')!


			colReference.editable = this.canEditProducts
			colQuantity.editable = this.canEditProducts
			colUnitPrice.editable = this.canEditProducts
			colUnit.editable = this.canEditProducts
			colSuppress.hide = !this.canEditProducts
			this.gridOptions.columnApi.setColumnVisible('suppress', !colSuppress.hide)
			this.gridOptions.columnApi.setColumnWidth('suppress', colSuppress.hide ? 0 : colSuppress.width!)
			this.gridOptions.api.sizeColumnsToFit()
		}
	}

	private acceptZero(accountingNumber: string): boolean {
		const accountingAccount = AccountingAccountsService.getInstance().getById(accountingNumber)
		return accountingAccount?.acceptZero || false
	}

}
export default EditOrders;

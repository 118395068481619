import { global, Tab } from "@autoprog/core-client"

import h from 'hyperscript'

import AddEditSiteModal from '@libs/modals/settings/AddEditSite'
import AgGridStateSaver from '@libs/agGrid/StateSaver2'

import toastr from 'toastr'
import { Grid, GridOptions, ICellRendererParams } from 'ag-grid-community'
import 'ag-grid-enterprise'

import { french as agGridFrench } from '@libs/utils/agGrid'

import M_file from '@libs/modals/orders/Attachments'
import SitesService from '@js/libs/services/SitesService'


class SitesTab extends Tab {

	private gridOptions: GridOptions;
	private stateAGrid: AgGridStateSaver | undefined
	constructor(private el: HTMLElement) {
		super(el)

		this.gridOptions = agGridFrench({
			columnDefs: [

				{
					headerName: '',
					pinned: 'left',
					field: 'color',
					width: 30,
					suppressMovable: true,
					resizable: false,
					cellClass: (params) => {
						if (params.value && params.value instanceof Array) {
							return `bg-${params.value[0]}`;
						}
						return '';
					},
					cellRenderer: () => {
						return ''
					}
				},
				{
					headerName: 'Nom',
					pinned: 'left',
					field: 'name',
					suppressMovable: true,
					sort: 'asc'
				},

				{
					headerName: 'Adresse',
					field: 'address'
				},
				{
					headerName: 'Ville',
					field: 'city'
				},
				{
					headerName: 'Code postal',
					field: 'zipCode'
				},
				{
					headerName: 'Téléphone',
					field: 'phone'
				},
				{
					headerName: 'Siret',
					field: 'siret'
				},
				{
					headerName: 'N° TVA',
					field: 'tvaNumber'
				},
				{
					headerName: 'Statut Juridique',
					field: 'legalStatus'
				},
				{
					headerName: 'Domiciliation bancaire ',
					field: 'bankingDomiciliation'
				},
				{
					headerName: 'IBAN',
					field: 'IBAN'
				},
				{
					headerName: 'R.C.S',
					field: 'RCS'
				},
				{
					headerName: 'Code Naf',
					field: 'NafCode'
				},
				{
					headerName: 'RIB',
					field: 'rib'
				},
				{
					headerName: 'Kbis',
					field: 'kbis'
				},
				{
					headerName: "",
					field: "commands",
					width: 8 + 28 * 3,
					sortable: false,
					filter: false,
					suppressSizeToFit: true,
					pinned: 'right',
					cellRenderer: (params: ICellRendererParams) => {

						const div = h('div.d-flex.justify-content-end') as HTMLElement

						const editButton = h('button.btn.btn-xs.btn-info', h('i.icon.icon-solid-pencil-alt'))
						editButton.setAttribute('permission', 'SETTINGS.SITES.EDIT');
						editButton.setAttribute('tooltip', 'Modifier');

						const deleteButton = h('button.btn.btn-xs.btn-danger', h('i.icon.icon-solid-trash-alt'))
						deleteButton.setAttribute('confirmation', '');
						deleteButton.setAttribute('permission', 'SETTINGS.SITES.DELETE');
						deleteButton.setAttribute('tooltip', 'Supprimer');

						deleteButton.addEventListener2('click', async () => {

							try {
								await SitesService.getInstance().delete(params.data._id)
								params.api.applyTransaction({ remove: [params.data] })
								toastr.success('Site supprimé')
							}
							catch (e) {
								toastr.error('Impossible de supprimer le site')
								console.error(e)
							}

						})

						editButton.addEventListener2('click', () => {
							new AddEditSiteModal(params.data._id).open().then(() => this.loadData())
						})


						if (params.data._attachments) {

							const N_attachments = document.createElement('button');

							N_attachments.classList.add('btn', 'btn-xs', 'btn-grey')
							N_attachments.innerHTML = `<i class="icon icon-solid-paperclip"></i>`
							N_attachments.setAttribute('tooltip', 'Pièce(s) jointe(s)')

							N_attachments.addEventListener2('click', () => {

								const values = Object.keys(params.data._attachments)

								if (values.length == 1) {

									window.open(`${global.COUCHDB_URL}/sites/${params.data._id}/${values[0]}`, 'target')

								} else {

									new M_file('sites', params.data._id, values).open()

								}

							})

							div.appendChild(N_attachments);

						}



						div.appendChild(editButton);
						div.appendChild(deleteButton);

						return div;
					}
				},


			],
			rowData: [],
			suppressContextMenu: true,
			suppressDragLeaveHidesColumns: true,
			defaultColDef: {
				floatingFilter: true,
				sortable: true,
				resizable: true,
				filter: 'agTextColumnFilter',
				floatingFilterComponentParams: { suppressFilterButton: true },
				lockPinned: true,
				suppressMenu: true,
			},
			onGridReady: (_params) => this.loadData()
		});

		let gridEl = document.querySelector('#sites-grid') as HTMLElement;
		new Grid(gridEl, this.gridOptions);
		this.stateAGrid = new AgGridStateSaver(this.gridOptions, 'settings-sites', `Onglet Paramètres/Sites`)
		this.stateAGrid.load()
		let N_addSitesButton = this.el.querySelector('#button-add-sites') as HTMLButtonElement
		N_addSitesButton.addEventListener2('click', () => {
			new AddEditSiteModal().open().then(() => this.loadData())
		})


		const N_exportSitesButton = this.el.querySelector('#button-export-sites') as HTMLButtonElement
		N_exportSitesButton.addEventListener2('click', () => {
			this.gridOptions.api && this.gridOptions.api.exportDataAsExcel({
				fileName: 'sites.xlsx',
				columnKeys: ['name', 'address', 'city', 'zipCode', 'phone', 'siret', 'tvaNumber', 'legalStatus', 'bankingDomiciliation', 'IBAN', 'RCS', 'NafCode', 'rib', 'kbis']
			})
		})
	}

	private async loadData(): Promise<void> {

		try {
			let sites = await SitesService.getInstance().getAllSites()
			sites && this.gridOptions.api && this.gridOptions.api.setRowData(sites)
		}
		catch (e) {
			toastr.error(`Erreur lors de la récupération des sites`)
			console.error(e)
		}

	}

	public destructor(): void {
		if (this.gridOptions.api){
			this.stateAGrid && this.stateAGrid.save()
			this.gridOptions.api.destroy()
		}
	}


}

export default SitesTab;

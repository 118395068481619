import { Form, Modal } from "@autoprog/core-client"

import T_addTiers from '@tpl/modals/tiers/addTiers.html'
import BDD from '@libs/BDD'
import toastr from 'toastr'
import h from 'hyperscript'

import InputMask from 'inputmask'
import { Tier } from "@js/types/Tiers"
import SitesService from '@js/libs/services/SitesService'
import TiersService from '@js/libs/services/TiersService'

import _ from 'lodash'
import FilesManager from '@js/libs/FilesManager'

class AddEditTiersModal extends Modal {

	private indexPlaces: number
	private indexContacts: number
	private imCP: InputMask
	private imEmail: InputMask
	private imSiret: InputMask
	private imTvaNumber: InputMask

	private N_placesContainer: HTMLElement | null = null
	private N_contactsContainer: HTMLElement | null = null

	// private id: string | null = null

	private form: Form | null = null

	private filesManager: FilesManager = new FilesManager()

	public constructor(private id?: string) {
		super({
			tpl: T_addTiers,
			backdrop: 'static',
			keyboard: false
		})

		this.indexPlaces = 0;
		this.indexContacts = 0;
		this.imCP = new InputMask("99 999");
		this.imEmail = new InputMask("email");
		this.imSiret = new InputMask("999 999 999 99999");
		this.imTvaNumber = new InputMask("FR 99999999999");

		this.on('opened', async () => {

			if (this.element) {

				this.form = new Form(this.element.querySelector('#tier-form') as HTMLFormElement)

				this.imCP.mask(this.form.getElementByName('zipCode') as HTMLInputElement)
				this.imSiret.mask(this.form.getElementByName('siret') as HTMLInputElement)
				this.imTvaNumber.mask(this.form.getElementByName('tvaNumber') as HTMLInputElement)
				this.imCP.mask(this.form.getElementByName('bill.zipCode') as HTMLInputElement)

				this.N_placesContainer = this.element.querySelector('#places-container');
				this.N_contactsContainer = this.element.querySelector('#contacts-container');

				let sites = await SitesService.getInstance().getAllSites()

				let select2Sites = sites.map(site => ({ id: site._id, text: site.name }))

				$('#sites-select').select2({ data: select2Sites, dropdownParent: $('#sites-select').parent() })
				$('#sites-select').val(select2Sites.map(s => s.id)).trigger('change')

				let N_buttonAddField = this.element.querySelector("#add-place-btn") as HTMLButtonElement
				N_buttonAddField.addEventListener('click', (e) => {
					this.addPlaceBlock()
					e.preventDefault()
				});


				let N_btn_add_contact = this.element.querySelector('#add-contact-btn') as HTMLButtonElement
				N_btn_add_contact.addEventListener('click', (e) => {
					this.addContactBlock()
					e.preventDefault()
				})

				let N_Attachment = this.element.querySelector('#attachments') as HTMLDivElement
				N_Attachment.appendChild(this.filesManager.getNode())

				let N_saveBtn = this.element.querySelector('#save-btn') as HTMLButtonElement
				N_saveBtn.addEventListener2('click', this.saveBtnEvent.bind(this))

				this.id && this.loadTier()
			}
		})
	}

	private async loadTier() {
		const tier = await TiersService.getInstance().getByID(this.id!)

		if (this.form) {
			const N_title = this.element.querySelector('#title') as HTMLElement
			N_title.innerHTML = 'Modification Tiers'

			tier.places && tier.places.forEach(() => this.addPlaceBlock())
			tier.contacts && tier.contacts.forEach(() => this.addContactBlock())

			this.form.setData(tier as any)

			tier._attachments && this.filesManager.setFiles(tier._attachments)
		}
	}

	/**
	 * Evenement déclenché au clic de la sauvegarde
	 * @param event 
	 */
	private async saveBtnEvent(_event: Event) {
		if (this.form && this.form.checkValidity()) {

			let data = this.form.getData() as any as Tier;

			data.contacts = _.compact(data.contacts);
			data.places = _.compact(data.places);

			data._id = data._id || BDD.generateID()
			Reflect.deleteProperty(data, '_rev')

			const siretAlreadyInUse = data.siret ? await TiersService.getInstance().doesSiretNumberExist(data.siret) : undefined

			if (!this.id && siretAlreadyInUse) {
				toastr.error('Numéro SIRET déja existant')
				return
			}

			try {
				let createOrUpdate = this.id ? TiersService.getInstance().update : TiersService.getInstance().create
				await createOrUpdate(data)
				await TiersService.getInstance().syncAttachment(data._id, this.filesManager.getArrayFiles())
				toastr.success('Tier sauvegarder avec succès')
				this.resolve()
			}
			catch (e) {
				toastr.error('Erreur de base de données')
				console.error(e)
				this.reject()
			}
		}
	}

	private addPlaceBlock(): void {

		if (this.element && this.N_placesContainer && this.form) {

			const hc = h.context();

			const div = hc('div.input-group.mb-2',
				hc('input.form-control.name-input', {
					name: `places[${this.indexPlaces}].name`,
					placeholder: 'Nom',
					required: true
				}),
				hc('span.input-group-append',
					hc('button.btn.btn-danger.delete-btn',
						{
							type: 'button',
							attrs: {
								confirmation: ''
							},
							onclick: () => {
								hc.cleanup();
								div.remove();
								if (this.form) {
									this.form.updateInputs();
								}
							}
						},
						hc('i.icon.icon-solid-minus')
					)
				)
			);

			this.N_placesContainer.appendChild(div)
			this.indexPlaces++
			this.form.updateInputs()
		}
	}


	private addContactBlock(): void {

		if (this.element && this.N_contactsContainer && this.form) {

			const hc = h.context()

			const div = hc('div.d-flex.mb-2',
				hc('div.d-flex.flex-column.w-100',
					hc('input.form-control.name-input', {
						name: `contacts[${this.indexContacts}].name`,
						placeholder: 'Nom',
						required: true
					}),
					hc('input.form-control.name-input', {
						name: `contacts[${this.indexContacts}].firstname`,
						placeholder: 'Prénom',
						required: true
					}),
					hc('input.form-control.email-input', {
						name: `contacts[${this.indexContacts}].email`,
						placeholder: 'Email'
					}),
					hc('input.form-control.phone-input', {
						name: `contacts[${this.indexContacts}].phone`,
						placeholder: 'Téléphone'
					}),
					hc('input.form-control.name-input', {
						name: `contacts[${this.indexContacts}].function`,
						placeholder: 'Fonction',
						required: true
					})
				),
				hc('button.btn.btn-danger.delete-btn',
					{
						type: 'button',
						attrs: {
							confirmation: ''
						},
						onclick: () => {
							hc.cleanup();
							div.remove();
							if (this.form) {
								this.form.updateInputs();
							}
						}
					},
					hc('i.icon.icon-solid-minus')
				)

			) as HTMLElement

			const N_email = div.querySelector(`.email-input`) as HTMLInputElement
			this.imEmail.mask(N_email)

			this.N_contactsContainer.appendChild(div)
			this.indexContacts++
			this.form.updateInputs()
		}
	}
}

export default AddEditTiersModal
/**
 * Contient les états de transactions qui sont effectué sur une commande, un bon de livraison, un bon d'intervention et une facture
 */
enum StateTransaction {
	all = "all",
	toConfirm = "toConfirm",
	preValidate = "preValidate",
	delivered = "delivered",
	archived = "archived",
	notArchived = "notArchived",
	notDelivered = "notDelivered",
	inDelivering = "inDelivering",
	notBilled = "notBilled",
	inBilling = "inBilling",
	billed = "billed",
	waiting = "waiting",
	recorded = "recorded",
	validated = "validated",
	locked = "locked",
	paid = "paid",
	paying = "paying",
	cancel_payment = "cancel_payment"
}

export default StateTransaction
import { Modal, global } from "@autoprog/core-client"

import moment from 'moment';

import unitList from '@libs/lists/unitList';

import T_addQuantityPredictive from '@tpl/modals/exploitation/addQuantityPredictive.html';

import BDD from '@libs/BDD';
import ProductsService from '@js/libs/services/ProductsService';
import SitesService from '@js/libs/services/SitesService';
import AlimentationPredictiveService from '@js/libs/services/AlimentationPredictiveService';
import AlimentationPredictive from '@js/types/alimentation-predictive';

import _ from 'lodash'

class AddQuantityPredictive extends Modal {

	private unit = ''

	constructor(private designSite: string, month?: string, idProduct?: string) {

		super({
			tpl: T_addQuantityPredictive,
			backdrop: 'static',
			keyboard: false
		})

		this.on('opened', async () => {

			const N_year = this.element.querySelector('#year') as HTMLSelectElement;
			const N_month = this.element.querySelector('#month') as HTMLSelectElement;

			const N_unit = this.element.querySelector('#unit') as HTMLElement
			const N_product = this.element.querySelector('#product') as HTMLElement

			$(N_product).select2({
				ajax: {
					url: `api/products/search/select2`,
					data: function (params) {
						return {
							...params,
							site: global.SITE
						}
					}
				},
				dropdownParent: $(this.element as HTMLElement)
			}).on('change', async () => {

				if (N_product) {

					let products = $(N_product).select2('data') as any;

					if (products.length) {
						let product = await ProductsService.getInstance().getByID(products[0].id)
						if (N_unit) {
							this.unit = product.unit;
							N_unit.innerHTML = unitList[product.unit].name;
						}

					}

				}

			});

			if (idProduct) {

				const product = await ProductsService.getInstance().getByID(idProduct)
				const value = {
					id: product._id,
					text: product.reference,
					product
				}

				if (N_product && N_unit) {

					N_unit.innerHTML = unitList[product.unit].name;

					const option = new Option(value.text, value.id, true, true);
					$(N_product).append(option).trigger('change')
					$(N_product).val(value.id).trigger('change')
				}

			}

			if (N_year) {

				const currentYear = new Date().getFullYear()

				let html = '';
				for (let i = -1; i < 3; i++) {
					html += `<option value="${currentYear + i}">${currentYear + i}</option>`;
				}

				N_year.innerHTML = html
			}

			if (N_year && N_month) {

				if (month) {

					const chunkMonth = month.split('_');

					N_year.value = chunkMonth[0];
					N_month.value = chunkMonth[1];

				} else {

					N_year.value = moment().format('YYYY');
					N_month.value = moment().format('MM');

				}

			}

			const N_save = this.element.querySelector('#save') as HTMLElement
			N_save.addEventListener('click', () => this.save())

			const site = await SitesService.getInstance().getByID(this.designSite)
			const N_name_site = this.element.querySelector('#name-site')!
			N_name_site.innerHTML = site.name
		})

	}

	private async save() {

		const N_product = this.element.querySelector('#product') as HTMLElement
		const N_quantity = this.element.querySelector('#quantity') as HTMLInputElement;

		const N_month = this.element.querySelector('#month') as HTMLSelectElement;
		const N_year = this.element.querySelector('#year') as HTMLSelectElement;

		const N_numberMonth = this.element.querySelector('#numberMonth') as HTMLInputElement;

		if (N_product && N_quantity && N_month && N_year && N_numberMonth) {

			let save = true;

			let products = $(N_product).select2('data')

			if (!N_quantity.valueAsNumber) {
				N_quantity.classList.add('is-invalid')
				save = false
			}

			if (products && products.length == 0) {
				N_product.classList.add('is-invalid')
				save = false
			}

			if (save) {

				const numberMonth = N_numberMonth.valueAsNumber;
				let dateStart = moment(N_year.value + '_' + N_month.value, 'YYYY_MM');
				const quantity = _.round((N_quantity.valueAsNumber || 0) * unitList[this.unit].coeficient / numberMonth, 2)
				const product = products[0]

				// TODO: optimiser cette requête
				let oldData = await AlimentationPredictiveService.getInstance().getBySite(this.designSite)

				const docs : Array<AlimentationPredictive> = []

				for (let i = 0; i < numberMonth; i++) {
					let month = dateStart.format('YYYY_MM')
					let item = _.find(oldData, { month, product: product.id }) as { _id: string, quantity: number, month: string, product: string, site: string }

					if (item) {
						item.quantity += quantity;
					}
					else {
						item = {
							_id: BDD.generateID(),
							quantity,
							month,
							product: product.id,
							site: this.designSite
						}
					}

					docs.push(item)
					dateStart = dateStart.add(1, 'month')
				}

				await AlimentationPredictiveService.getInstance().updateAll(docs)
				this.resolve()
			}

		}
	}


}

export default AddQuantityPredictive;

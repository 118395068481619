import { Modal, Form, global } from "@autoprog/core-client"

import T_detailProduct from '../../../../tpl/modals/products/detailProduct.html';
import rawMaterialProperties from '@libs/lists/rawMaterialPropertiesList'
import AddEditProductModal from './AddEditProductModal';
import unitList from '@libs/lists/unitList'
import T_rawMaterialDetails from '@tpl/blocks/ProductsTiers/rawMaterialDetails.ejs'
import VATService from '@js/libs/services/VATService';
import AnalyticsService from '@js/libs/services/AnalyticsService';
import SubAnalyticsService from '@js/libs/services/SubAnalyticsService';
import AccountingAccountsService from '@js/libs/services/AccountingAccountsService';
import h from 'hyperscript'
import Product from '@js/types/products'

interface ProductDetail extends Product {
	analyticName: string
	subAnalyticName: string
	priceStr: string
}

import _ from 'lodash'
import ProductsService from '@js/libs/services/ProductsService';
import SitesService from '@js/libs/services/SitesService';

class DetailProductModal extends Modal {

	private form: Form | null = null;

	//Constrcuteur
	constructor(id: string) {

		super({
			tpl: T_detailProduct,
			backdrop: 'static',
			keyboard: false
		});

		//lorsque la modale est ouverte
		this.on('opened', async () => {

			let N_form = this.element.querySelector("#form-detail-modal") as HTMLFormElement;
			this.form = new Form(N_form);

			let product = await this.getData(id)

			let accountingAccount = AccountingAccountsService.getInstance().getById(product.accountingAccount)

			// TODO: vérifier pertinence
			const analytics = AnalyticsService.getInstance().getByIds(product.analytics.map(analytic => analytic.id))
			const subanalytics = SubAnalyticsService.getInstance().getByIds((product.subAnalytics || []).map(subanalytic => subanalytic.id))
			product.analyticName = analytics.map(analytic => analytic.name).join(',')
			product.subAnalyticName = subanalytics.map(subanalytic => subanalytic.name).join(',')


			
			const vat = product.vat ? VATService.getInstance().getById(product.vat) : undefined
			product.vat = vat ? `${vat.label} (${vat.percent}%)` : '???'

			product.priceStr = (product.price * unitList[product.unit].coeficient) + '€';


			if (accountingAccount) {

				product.accountingAccount = accountingAccount.name;

				let N_rawMaterialDiv = this.element.querySelector("#rawMaterialProperties") as HTMLDivElement;
				if (accountingAccount.isRawMaterial) {
					N_rawMaterialDiv.innerHTML = T_rawMaterialDetails({ rawMaterialProperties, unitList })
				} else {
					N_rawMaterialDiv.innerHTML = ""
				}

			}

			//Insertion des données dans le HTML
			let N_title = this.element.querySelector("#title") as HTMLDivElement;
			N_title.innerHTML = "Fiche produit " + product.reference;



			let N_attachments = this.element.querySelector("#attachments") as HTMLDivElement;
			if (product._attachments) {
				for (let attachment in product._attachments) {

					let N_buttonAttachment = document.createElement('button') as HTMLButtonElement;

					N_buttonAttachment.classList.add('btn-outline', 'btn-blue', 'btn-xs', 'btn-rounded', 'mr-2');
					N_buttonAttachment.innerHTML = _.truncate(attachment, { length: 20 });

					N_attachments.appendChild(N_buttonAttachment);

					if (N_buttonAttachment) {
						N_buttonAttachment.addEventListener('click', () => {
							window.open(`${global.COUCHDB_URL}/products/${product._id}/${attachment}`, "_blank");
						});
					}
				}

			} else {
				N_attachments.innerHTML = 'Pas de pièces jointes';
			}

			let N_buttonEdit = this.element.querySelector("#buttonEdit")
			if (N_buttonEdit) {

				N_buttonEdit.addEventListener('click', () => {
					let modal = new AddEditProductModal(id)
					this.hide()
					modal.open().then(() => this.resolve()).catch(() => this.show())
				})
			}


			const N_sitesContainer = this.element.querySelector('#sites-container') as HTMLElement

			const sites = await SitesService.getInstance().getAllSites()

			if (product.sites.length == sites.length) {
				N_sitesContainer.innerHTML = 'Tous';
			}
			else {
				for (let siteID of product.sites) {
					let site = _.find(sites, { _id: siteID })
					let N_badge = h('span.badge.rounded-pill.px-1', '???') as HTMLElement;
					N_badge.style.margin = '2px'
					if (site) {
						N_badge.innerHTML = site.name
						site.color && N_badge.classList.add(`bg-${site.color[0]}-200`)
					}

					N_sitesContainer.appendChild(N_badge);
				}
			}

			const N_analyticsContainer = this.element.querySelector('#analytics-container') as HTMLTableSectionElement

			if (product.analytics) {

				for (let analytic of product.analytics) {

					const a = AnalyticsService.getInstance().getById(analytic.id);

					if (a) {
						let N_row = N_analyticsContainer.insertRow();
						N_row.insertCell().innerHTML = a.name;
						N_row.insertCell().innerHTML = analytic.percent + '%';
					}
				}

			}

			if (product.subAnalytics && product.subAnalytics.length && product.subAnalytics[0]) {
				const sa = SubAnalyticsService.getInstance().getById(product.subAnalytics[0].id)
				product.subAnalytics[0].id = sa?.name || '???'
			}

			if (this.form) {
				this.form.updateInputs()
				this.form.setData(product as any)
			}

		})
	}

	//-------------------Méthodes---------------------------//

	private async getData(id: string): Promise<ProductDetail> {
		const product = await ProductsService.getInstance().getByID(id)
		return {
			...product,
			analyticName: "",
			priceStr: "",
			subAnalyticName: ""
		}
	}

}

export default DetailProductModal;

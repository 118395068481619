import { Alert, toaster, global, Controller, DOM, LoggedUser } from "@autoprog/core-client"

import TabsExtended from '@libs/TabsExtended'

import C_TabOrders from '@libs/tabs/orders/TabOrders'
import C_TabDelivery from '@js/libs/tabs/orders/TabDeliveryNote'
import C_TabWorkOrder from '@js/libs/tabs/orders/TabWorkOrder'
import C_TabBilling from '@js/libs/tabs/orders/TabBilling'

import T_TabOrders from '@tpl/tabs/orders/orders.html'
import T_TabDelivery from '@tpl/tabs/orders/delivery.html'
import T_TabWorkOrder from '@tpl/tabs/orders/workOrder.html'
import T_TabBilling from '@tpl/tabs/orders/billing.html'
import OrdersService from "@js/libs/services/OrdersService"
import DetailOrderModal from "@js/libs/modals/orders/DetailOrderModal"
import DeliveryNotesService from "@js/libs/services/DeliveryNotesService"
import DetailDelivery from "@js/libs/modals/orders/DetailDelivery"
import WorkOrdersService from "@js/libs/services/WorkOrdersService"
import DetailWorkOrder from "@js/libs/modals/orders/DetailWorkOrder"
import BillsService from "@js/libs/services/BillsService"
import BillModal from "@js/libs/modals/orders/DetailBillModal"

class OrdersCtrl extends Controller {

	private tabs: TabsExtended
	private waitClosing: boolean = false
	private cancellers: Array<EventListenerCanceller> = []

	constructor(private el: HTMLElement, params: object) {
		super(el, params)

		this.tabs = new TabsExtended({
			navElement: this.el.querySelector('#orders-menu') as HTMLElement,
			renderElement: this.el.querySelector('.orders-content') as HTMLElement,
			navTemplate: '<button permission="${tab.permission}" tab-link="${tab.id}" class="btn btn-tab btn-grey-300">${tab.name}</button>',
		})
		
		try {
			const loggedUser = LoggedUser.getInstance()

			this.tabs.on('tab.show', async () => {
				await DOM.nextTick()
				const activeTab = this.tabs.getActiveTab() as any;
				if (activeTab && activeTab.instance && activeTab.instance.updateSelectSite) {
					activeTab.instance.updateSelectSite(global.SITE);
				}
			})

			this.tabs.add({
				name: 'Bon de commande',
				controller: C_TabOrders,
				default: loggedUser.hasPermission('ORDERS.BC.SHOW'),
				template: T_TabOrders,

				permission: 'ORDERS.BC.SHOW'

			});

			this.tabs.add({
				name: 'Bon de livraison',
				controller: C_TabDelivery,
				default:
					!loggedUser.hasPermission('ORDERS.BC.SHOW') &&
					loggedUser.hasPermission('ORDERS.BL.SHOW'),
				template: T_TabDelivery,

				permission: 'ORDERS.BL.SHOW'

			});

			this.tabs.add({
				name: 'Bon d\'intervention',
				controller: C_TabWorkOrder,
				default:
					!loggedUser.hasPermission('ORDERS.BC.SHOW') &&
					!loggedUser.hasPermission('ORDERS.BL.SHOW') &&
					loggedUser.hasPermission('ORDERS.BI.SHOW'),
				template: T_TabWorkOrder,

				permission: 'ORDERS.BI.SHOW',

			});

			this.tabs.add({
				name: 'Facturation',
				controller: C_TabBilling,
				default:
					!loggedUser.hasPermission('ORDERS.BC.SHOW') &&
					!loggedUser.hasPermission('ORDERS.BL.SHOW') &&
					!loggedUser.hasPermission('ORDERS.BI.SHOW') &&
					loggedUser.hasPermission('ORDERS.BILL.SHOW'),
				template: T_TabBilling,
				permission: 'ORDERS.BILL.SHOW',
			});

		}
		catch (e) {
			console.log(e);
		}


		const N_search = this.el.querySelector('.search-btn') as HTMLButtonElement;
		N_search.addEventListener('click', () => this.search())

		this.cancellers.push(document.addEventListener2('keydown', async (e: KeyboardEvent) => {

			if (e.key == 'f' && e.ctrlKey && !this.waitClosing) {
				this.search()
				e.preventDefault()
			}

		}))

		this.siteSelect()
	}

	private async search() {
		if (!this.waitClosing) {

			const ID = await Alert.prompt('Recherche', 'Entrez le numéro du BC/BL/BI/FA', { value: '' }) as string
			let found = false
			this.waitClosing = true
			
			try {
				await OrdersService.getInstance().getByID(ID);
				found = true
				await new DetailOrderModal(ID).open();
			}
			catch (e) {}

			try {
				await DeliveryNotesService.getInstance().getByID(ID);
				found = true
				await new DetailDelivery(ID).open();
			}
			catch (e) {}

			try {
				await WorkOrdersService.getInstance().getByID(ID);
				found = true
				await new DetailWorkOrder(ID).open();
			}
			catch (e) {}

			try {
				await BillsService.getInstance().getByID(ID);
				found = true
				await new BillModal(ID).open();
			}
			catch (e) {}

			this.waitClosing = false;
			if (!found) {
				toaster.error(`n'existe pas`, `${ID}`);
			}
		}
	}

	private siteSelect() {

		setTimeout(() => {

			let N_siteSelect = this.el.querySelector('#site-select') as HTMLSelectElement;

			N_siteSelect.addEventListener('input', () => {

				global.SITE = N_siteSelect.value;

				let activeTab = this.tabs.getActiveTab() as any;

				if (activeTab && activeTab.instance && activeTab.instance.updateSelectSite) {
					activeTab.instance.updateSelectSite(global.SITE);
				}

			});

		});

	}

	public destructor() {
		this.tabs.destructor()
		this.cancellers.forEach(c => c())
	}
}

export default OrdersCtrl;

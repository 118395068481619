import h from 'hyperscript'

import { Tab, global, LoggedUser, toaster, utils } from "@autoprog/core-client"

import { Grid, GridOptions, ColDef, ICellRendererParams, MenuItemDef } from 'ag-grid-community'
import 'ag-grid-enterprise'

import moment from 'moment'
import toastr from 'toastr'

import AddEditCheckListModal from '@libs/modals/exploitation/AddEditCheckList'

import { french as agGridFrench, AGGridUtils } from '@libs/utils/agGrid'

import checklistEventDescription from '@tpl/blocks/exploitation/checklistEventDescription.ejs'
import checklistEventTitle from '@tpl/blocks/exploitation/checklistEventTitle.ejs'

import Export from '@libs/agGrid/Export';
import ChecklistsService from '@js/libs/services/ChecklistsService'
import EventsService from '@js/libs/services/EventsService'

import _ from 'lodash'
import ChecklistModelsService from '@js/libs/services/ChecklistModelsService'
import { OperatingDataService } from "@js/libs/services"

class ChecklistTab extends Tab {

	private gridOptions: GridOptions

	constructor(private el: HTMLElement, private model: string) {
		super(el)
		this.gridOptions = {}

		this.init()
	}

	private async init(): Promise<void> {		
		this.createGrid()

	

		setTimeout(() => {
			let urlParams = utils.getQuery() as any

			if (urlParams.checklist) {

				const modal = new AddEditCheckListModal(this.model, urlParams.checklist);
				modal.open().finally(() => this.loadData())

				//On supprime les paramètres get pour évité la réouverture de la modal
				window.location.href = window.location.href.split('?')[0]
				urlParams = undefined
			}

		})
	}


	private createGrid() {
		const columnDefs: ColDef[] = [
			{
				headerName: "ID",
				field: "_id",
				hide: !!!LoggedUser.getInstance().get('isSuperAdmin')
			},
			{
				headerName: "Création",
				colId: 'created',
				field: "date",
				sortable: true,
				cellClass: 'date',
				filter: 'agDateColumnFilter',
				sortingOrder: ['desc', 'asc', null],
				cellRenderer: (params) => {
					if (params.value) {
						return moment(params.value, 'x').format('DD/MM/YYYY HH:mm')
					}

					return ''
				},
				filterParams: {
					suppressAndOrCondition: true,
					inRangeInclusive: true,
					comparator: (filterValue: Date, cellValue: number) => {

						const filter = parseInt(moment(filterValue).startOf('day').format('x'));
						const cell = parseInt(moment(cellValue).startOf('day').format('x'));

						if (cell < filter) {
							return -1
						}
						else if (cell > filter) {
							return 1
						}

						return 0
					}
				},
			},
			{
				headerName: "Mise à jour",
				field: "updateDate",
				cellRenderer: (params) => {
					if (params.value) {
						return moment(params.value, 'x').format('DD/MM/YYYY HH:mm')
					}

					return ''
				}
			},
			{
				headerName: "Nom",
				field: "name"
			},
			{
				headerName: "Pourcentage",
				field: 'percent',
				cellRenderer: (params) => params.value ? _.round(params.value, 0) + '%' : "---",
				cellClass: (params) => params.value ? "" : "text-center",
				cellStyle: (params) => ({ color: `hsl(${params.value}, 100%, 40%)` })
			},
			{
				headerName: '',
				field: '_id',
				width: 8 + 28 * 3,
				pinned: 'right',
				colId: 'actions',
				cellRenderer: (params: ICellRendererParams) => {

					const checklistService = ChecklistsService.getInstance()
					const eventsService = EventsService.getInstance()

					const div = document.createElement('div')
					div.classList.add('d-flex', 'justify-content-center');

					/* --------------------------- Bouton de création --------------------------- */

					const N_create = h('button.btn.btn-xs.btn-success', h('i.icon.icon-solid-plus'))
					N_create.addEventListener('click', () => {
						const modal = new AddEditCheckListModal(this.model, undefined, params.data.date)
						modal.open().finally(() => this.loadData())
					})

					/* ---------------------------- Bouton d'édition ---------------------------- */

					const N_edit = h('button.btn.btn-xs.btn-info', h('i.icon.icon-solid-pencil-alt'))
					N_edit.addEventListener('click', () => {
						const modal = new AddEditCheckListModal(this.model, params.data._id)
						modal.open().finally(() => this.loadData())
					})

					/* -------------------------- Bouton de suppression ------------------------- */

					const N_delete = h('button.btn.btn-xs.btn-danger', { attrs: { confirmation: '' } }, h('i.icon.icon-solid-trash-alt'))
					N_delete.addEventListener('click', async () => {

						try {

							await Promise.all([
								checklistService.delete(params.data._id),
								OperatingDataService.getInstance().deleteList(params.data._id)
							])

							try {
								const event = await eventsService.getByID(params.data._id).then(ev => _.omit(ev, 'bgColor', 'color'))

								event.title = checklistEventTitle({
									name: params.data.name,
									percent: 0
								})
								event.description = checklistEventDescription({
									checklistID: this.model,
								})
								eventsService.update(event)
							}
							catch {
								//Rien ici car le catch est normale en cas de non existance de l'événement.
							}

							this.loadData()
							toastr.success('Checklist supprimée.')

						}
						catch {
							toaster.error(`Erreur lors de la supression.`)
						}
					})

					/* -------------------------- Bouton de vérouillage ------------------------- */

					const N_lock = h('button.btn.btn-xs.btn-dark', h('i.icon.icon-solid-lock'))
					N_lock.setAttribute('permission', "EXPLOITATION.CHECKLIST.LOCK")
					N_lock.addEventListener('click', async () => {
						try {
							await checklistService.lock(params.data._id);
							params.data.locked = true;
							params.node.setData(params.data)
							params.api.redrawRows({ rowNodes: [params.node] });

							toaster.success('Checklist vérouillée')
						}
						catch (e) {
							toaster.error(`Erreur lors du vérouillage.`)
						}
					})

					/* ------------------------- Bouton de dévérouillage ------------------------ */

					const N_unlock = h('button.btn.btn-xs.btn-blue-grey', h('i.icon.icon-solid-unlock-alt'));
					N_unlock.setAttribute('permission', "EXPLOITATION.CHECKLIST.UNLOCK");
					N_unlock.addEventListener('click', async () => {
						try {
							await checklistService.unlock(params.data._id)
							params.data.locked = false
							params.node.setData(params.data)
							params.api.redrawRows({ rowNodes: [params.node] })

							toaster.success('Checklist dévérouillée')
						}
						catch (e) {
							toaster.error(`Erreur lors du dévérouillage.`)
						}
					})

					/* -------------------------------------------------------------------------- */

					if (!params.data.fakeRow) {

						if (params.data.locked) {
							N_edit.setAttribute('permission', "EXPLOITATION.CHECKLIST.EDIT_LOCKED")
							N_delete.setAttribute('permission', "EXPLOITATION.CHECKLIST.DELETE_LOCKED")
						}
						else {
							N_edit.setAttribute('permission', "EXPLOITATION.CHECKLIST.EDIT");
							N_delete.setAttribute('permission', "EXPLOITATION.CHECKLIST.DELETE");
						}

						div.appendChild(N_edit)
						div.appendChild(N_delete)

						if (params.data.locked) {
							div.appendChild(N_unlock)
						}
						else {
							div.appendChild(N_lock)
						}
					}
					else {

						if (params.data.locked) {
							N_create.setAttribute('permission', "EXPLOITATION.CHECKLIST.EDIT_LOCKED")
						}
						else {
							N_create.setAttribute('permission', "EXPLOITATION.CHECKLIST.EDIT")
						}

						div.appendChild(N_create)
					}

					return div
				}
			}

		]

		this.gridOptions = agGridFrench({
			columnDefs: columnDefs,
			rowData: [],
			suppressCellSelection: true,
			suppressDragLeaveHidesColumns: true,
			defaultColDef: {
				resizable: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				suppressMenu: true
			},
			getContextMenuItems: (params) => {

				const menu: Array<MenuItemDef> = []

				if (params.columnApi) {
					const columnKeys = AGGridUtils.getColumnsIds(this.gridOptions, 'actions')

					const exportAllExcel = {
						name: 'Exporter tous Excel',
						action: () => {
							const exp = new Export(params)
							exp.exportExcel({
								columnKeys
							})
						}
					}

					const exportAllCSV = {
						name: 'Exporter tous CSV',
						action: () => {
							const exp = new Export(params);
							exp.exportCSV({
								columnKeys
							})
						}
					}

					menu.push(exportAllExcel, exportAllCSV)
				}

				return menu
			},
			onGridReady: () => this.loadData(),
			getRowClass: (params) => {

				if (params.data.fakeRow) {
					return 'text-muted font-italic'
				}

				return ''
			}
		})

		const gridEl = this.el.querySelector('#checklist-grid') as HTMLElement
		new Grid(gridEl, this.gridOptions)
	}


	private async loadData(): Promise<void> {

		if (!this.gridOptions.api) {
			return
		}

		const model = await ChecklistModelsService.getInstance().getByID(this.model)
		const checklists = await ChecklistsService.getInstance().getBySiteModel(global.SITE, this.model)

		if (model.repeat.enable) {

			const startDate = moment(model.repeat.from, 'x')
			const endDate = moment(Math.min(Date.now(), model.repeat.to as number), 'x')
			const checklistsByDate = _.keyBy(checklists, (checklist) => moment(checklist.date, 'x').startOf('day').format('x'))
			const rowData: Array<{ date: number, name: string, percent: number, fakeRow: boolean }> = checklists.map(checklist => ({ ...checklist, fakeRow: false }))

	
			while (startDate.unix() < endDate.unix()) {
				const dayStartTimestamp = startDate.startOf('day').format('x')

				if (!Reflect.has(checklistsByDate, dayStartTimestamp)) {
			
					rowData.push({
						date: parseInt(startDate.format('x')),
						name: model.name,
						percent: 0,
						fakeRow: true
					})
				}

				startDate.add(model.repeat.interval.value, model.repeat.interval.type)
			}


			this.gridOptions.api?.setRowData(_.sortBy(rowData, 'date'))
		}
		else {
			this.gridOptions.api?.setRowData(checklists)
		}

		// Range par date de creation
		this.gridOptions.columnApi?.applyColumnState({ state: [{ colId: 'created', sort: 'desc' }] })
	}

	public destructor() {
		this.gridOptions.api?.destroy()
	}
}


export default ChecklistTab

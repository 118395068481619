import { Modal, global } from "@autoprog/core-client"

import T_updateEventRepeat from '@tpl/modals/calendar/updateEventRepeat.ejs';

class UpdateEventRepeat extends Modal {

	constructor(type: string = 'update') {
		super({
			tpl: T_updateEventRepeat({
				global
			}),
			backdrop: 'static',
			keyboard: false
		});

		this.on('opened', () => {

			let N_save = this.element.querySelector('#save');
			let N_title = this.element.querySelector('.modal-title');

			if (N_title && type == 'delete') {

				N_title.innerHTML = 'Supprimer l\'événement périodique';

			}

			if (N_save) {

				N_save.addEventListener('click', () => {

					this.save();

				})

			}

		});

	}

	public save(): void {

		let N_onlyOne = this.element.querySelector('[name="typeEvent"]#onlyOne') as HTMLInputElement;
		let N_after = this.element.querySelector('[name="typeEvent"]#after') as HTMLInputElement;
		let N_all = this.element.querySelector('[name="typeEvent"]#all') as HTMLInputElement;

		if (N_onlyOne.checked) {
			this.resolve('onlyOne');
		}

		if (N_after.checked) {
			this.resolve('after');
		}

		if (N_all.checked) {
			this.resolve('all');
		}

	}

}

export default UpdateEventRepeat;
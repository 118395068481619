import { Tab, toaster } from "@autoprog/core-client"
import Select2CellEditor, { Select2CellEditorParams } from "@js/libs/agGrid/Select2CellEditor"
import { OperatingDataDefinitionService } from "@js/libs/services"
import ImportersService from "@js/libs/services/ImportersService"
import { french } from "@js/libs/utils/agGrid"

import { DefinitionTypesList } from "@js/libs/lists/definitions-types.list"

import { Grid, GridOptions, ICellRendererParams, ITextFilterParams, RowNode, ValueFormatterParams } from 'ag-grid-community'
import _ from "lodash"
import BDD from "@js/libs/BDD"


type ImportedTabRow = {
	identifier: string
	company: string
	column: string
	definition?: string
	error?: boolean
	color?: string
}

class ImportedTab extends Tab {

	private gridOptions: GridOptions

	constructor(private el: HTMLElement) {
		super(el)
		this.gridOptions = {}
		this.init()
	}

	private async init() {
		await this.createGrid()
	}

	private async createGrid() {
		const N_div = this.el.querySelector('#grid-imported') as HTMLElement
		
		const definitions = await OperatingDataDefinitionService.getInstance().getAll()

		this.gridOptions = french({
			columnDefs: [
				{
					headerName: "Constructeur",
					field: "company",
					sort: 'ASC'
				},
				{
					headerName: "Colonne Associe",
					field: "column"
				},
				{
					headerName: "Definition",
					colId: 'definition',
					field: "definition",
					editable: true,
					cellEditor: Select2CellEditor,
					cellEditorParams: {
						options: {
							typeResponse: 'identifier',
							allowClear: true,
							canBeEmpty: true,
							data: definitions.map(definition => ({ id: definition._id, text: definition.label })),
							dropdownParent: $(N_div)
						}
					} as Select2CellEditorParams,
					filterParams: {
						valueGetter: (params) => definitions.find(definition => definition._id === params.data.definition)?.label
					} as ITextFilterParams,
					cellRenderer: (params: ICellRendererParams) => definitions.find(definition => definition._id === params.value)?.label || ''
				},
				{
					headerName: "Type",
					headerTooltip: "Le type de la definition.",
					colId: "type",
					cellClass: "text-center",
					filterParams: {
						valueGetter: (params) => DefinitionTypesList[definitions.find(definition => definition._id === params.data.definition)?.dataType || "unknown"]?.displayName || ""
					} as ITextFilterParams,
					valueGetter: (params) => definitions.find(definition => definition._id === params.data.definition)?.dataType || '',
					valueFormatter: (params: ValueFormatterParams) => DefinitionTypesList[params.value]?.displayName || ""
				}
			],
			getRowClass: (params) => {
				const classes: Array<string> = []
				params.data.error && classes.push('ag-row-danger')
				_.isEqual(!!!params.data.error && !_.isEmpty(params.data.color), true) && classes.push(params.data.color)
				return classes
			},
			rowData: [],
			suppressDragLeaveHidesColumns: true,
			defaultColDef: {
				editable: false,
				floatingFilter: true,
				sortable: true,
				resizable: true,
				filter: 'agTextColumnFilter',
				floatingFilterComponentParams: { suppressFilterButton: true },
				suppressMenu: true,
				lockPinned: true
			},
			getContextMenuItems: () => [],
			onGridReady: () => this.load(),
			onCellValueChanged: async (params) => {
				// On raffraichit la case type si la definition change
				_.isEqual(params.colDef.field, 'definition') && params.api.refreshCells({  force: true, columns: ["type"], rowNodes: [params.node] })

				// On commence par detruire les ancienne donnees, on pourrait simplement
				await ImportersService.getInstance().deleteByColumn(params.data.identifier, params.data.column)

				// On va verifier que tout est correct
				if (this.checkValidity() && !_.isEmpty(params.data.definition)) {
					// On enregistrer seulement si les donnees sont corrects
					await this.saveOne(params.data)
				}
			}
		})

		new Grid(N_div, this.gridOptions)
	}

	private async load() {
		const importers = await ImportersService.getInstance().getHeaders()
		const data = await ImportersService.getInstance().getAll()

		const rows: Array<ImportedTabRow> = importers.flatMap<ImportedTabRow>(importer => {
			return importer.headers
				.sort((a, b) => a.localeCompare(b))
				.map(header => ({ 
						company: importer.displayName,
						definition: data.find(row => row.identifier === importer.ID && row.column === header)?.definition,
						column: header, identifier: importer.ID,
						color: importer.color
					})
				)
		})

		this.gridOptions.api?.setRowData(rows)
	}

	private checkValidity() : boolean {
		let hasError = false

		const duplicated: Array<string> = []

		// Recuperation des nodes
		const nodes: Array<RowNode> = []
		this.gridOptions.api?.forEachNode(node => nodes.push(node))

		// TODO: faire une verification serveur pour que les definitions ne sont pas pris par les models de checklist
		nodes.reverse().forEach(node => {
			_.set(node.data, 'error', _.compact(duplicated).includes(node.data.definition) && !_.isEmpty(node.data.definition))

			if (node.data.error) {
				hasError = true
			}

			duplicated.push(node.data.definition)
			node.setData(node.data)
		})

		this.gridOptions.api?.redrawRows() // Pour reecrire le CSS de la grid
		return !hasError
	}

	private async saveOne(data: ImportedTabRow) {
		await ImportersService.getInstance().create({ _id: BDD.generateID(), definition: data.definition!, ..._.omit(data, 'definition', 'error', 'company', 'color') })
		toaster.success('Enregistrement de la nouvelle liaison')
	}

	public destructor() {

	}
}


export default ImportedTab

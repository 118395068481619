import OrdersService from "../services/OrdersService"
import DeliveryNotesService from "../services/DeliveryNotesService"
import WorkOrdersService from "../services/WorkOrdersService"
import Order from "@js/types/order"
import DeliveryNote from "@js/types/delivery-note"
import WorkOrder from "@js/types/workorder"
import BillsService from "../services/BillsService"

import OrderInfo, { ProductsInfoList } from "@js/libs/models/OrderInfo"



/**
 * Cette classe permet de gérer les commandes
 * - Permet de déterminer si on peut commander
 */
class OrdersManager {
	private constructor() {}

	/**
	 * Cette classe va renvoyer un tableau d'information sur les produits d'une commandes
	 * @param orderId Numero de la commande
	 */
	public static async getOrderInfo(orderId: string) : Promise<ProductsInfoList> {

		let order: Order
		// On récupère les information neccessaire pour créer notre liste d'information
		try {
			order = await OrdersService.getInstance().getByID(orderId)
		}
		catch {
			console.error("La commande n'existe pas.")
			throw new Error("La commande n'existe pas.")
		}
		
		// REMARK: deliveryNotes et workOrders => la commande peut ne pas avoir de bon de livraisons ou de bon d'interventions

		let deliveryNotes: DeliveryNote[] = []
		try { deliveryNotes = await DeliveryNotesService.getInstance().getFromOrder(orderId) } catch {}
		deliveryNotes = deliveryNotes.filter( d => !d.deleted ) // On ne prend pas ceux qui ont été détruit

		let workOrders: WorkOrder[] = []
		try { workOrders = await WorkOrdersService.getInstance().getFromOrder(orderId) } catch {}
		workOrders = workOrders.filter( w => !w.deleted )

		// On indexe tous les produits avec leur limitation en quantité commandable
		// TODO: faire en sorte de régler le problème avec le ticket suivant : (https://gitlab.com/charlesletang77/autoprog-letang-bioagaz/-/issues/197) => trouver un autre type ID pour la correspondant produit
		let productsLimitsOrder: { [id: string] : { quantity: number, idProduct: string, unit: string } } = {}
		for (let product of order.products || []) {
			productsLimitsOrder[product.rowID] = { idProduct: product.id, quantity: product.quantity, unit: product.unit }
		}

		// On indexe tous les produits dans les bon livraisons qui été livré
		let deliveryNoteOrdered: { [id:string]: number } = {}
		for (let deliveryNote of deliveryNotes) {
			for (let product of deliveryNote.products) {

				if (!deliveryNoteOrdered[product.rowID]) {
					deliveryNoteOrdered[product.rowID] = 0
				}

				deliveryNoteOrdered[product.rowID] += product.deliveredQuantity
			}
		}

		const workOrdersOrdered : { [id:string] : number } = {}
		for (let workOrder of workOrders) {
			for (let product of workOrder.products) {

				if (!workOrdersOrdered[product.rowID]) {
					workOrdersOrdered[product.rowID] = 0
				}

				workOrdersOrdered[product.rowID] += product.deliveredQuantity
			}
		}

		// On crée notre jeu de données
		const result : ProductsInfoList = {}

		Object.keys(productsLimitsOrder).forEach( key => {
			result[key] = new OrderInfo(
				key, 
				productsLimitsOrder[key].idProduct, 
				productsLimitsOrder[key].quantity, 
				deliveryNoteOrdered[key] ? deliveryNoteOrdered[key] : 0, // Dans le cas où il y a pas de bons de livraison
				workOrdersOrdered[key] ? workOrdersOrdered[key] : 0, // Dans le cas où il y a pas de bons d'interventions
				productsLimitsOrder[key].unit
			)
		} )

		return result
	}

	/**
	 * Permet de récupérer les infos sans un Bon livraison en particulier
	 * REMARK: On utilise dans les cas de modification d'un bon de livraison
	 * REMARK: Le bon de livraison doit forcément existé
	 */
	public static async getOrderInfoFilterDeliveryOrder(orderId: string, deliveryOrderId: string) : Promise<ProductsInfoList> {
		const orderInfoData = await OrdersManager.getOrderInfo(orderId)
		const deliveryOrder = await DeliveryNotesService.getInstance().getByID(deliveryOrderId)

		// On recrée notre tableau avec les quantités qui n'appartiennent pas au bon de livraison à filtré
		const result: ProductsInfoList = {}

		// TODO: j'ai pas envie d'expliquer ce bout de code
		const keysProductsOfDeliveryOrder = deliveryOrder.products.map( product => product.rowID ) 
		Object.keys(orderInfoData).forEach( rowIdProduct => {
			if (keysProductsOfDeliveryOrder.includes(rowIdProduct)) {

				// Si on arrive dans ce cas, il faut modifier la quantité livrée
				
				// le produit concerné (il existe forcement)
				const productToRemove = deliveryOrder.products.find( p => p.rowID === rowIdProduct )!

				result[rowIdProduct] = new OrderInfo(
					rowIdProduct,
					orderInfoData[rowIdProduct].productId,
					orderInfoData[rowIdProduct].maxOrdered,
					orderInfoData[rowIdProduct].deliveryOrdered - productToRemove.deliveredQuantity,
					orderInfoData[rowIdProduct].workOrdered,
					orderInfoData[rowIdProduct].unit
				)

			}
			else {
				// Dans le cas ou le produit ne fait pas partie de la commande
				result[rowIdProduct] = orderInfoData[rowIdProduct]
			}
		})

		return result
	}

	public static async getOrderInfoFilterWorkOrder(orderId: string, workOrderId: string) : Promise<ProductsInfoList> {
		let orderInfoData = await OrdersManager.getOrderInfo(orderId)
		let workOrder = await WorkOrdersService.getInstance().getByID(workOrderId)

		let keysProductsOfWorkOrder = workOrder.products.map( product => product.rowID )
		let result: ProductsInfoList = {}

		Object.keys(orderInfoData).forEach( rowIdProduct => {

			if (keysProductsOfWorkOrder.includes(rowIdProduct)) {

				let productToRemove = workOrder.products.filter( p => p.rowID === rowIdProduct )[0]

				result[rowIdProduct] = new OrderInfo(
					rowIdProduct,
					orderInfoData[rowIdProduct].productId,
					orderInfoData[rowIdProduct].maxOrdered,
					orderInfoData[rowIdProduct].deliveryOrdered,
					orderInfoData[rowIdProduct].workOrdered - productToRemove.deliveredQuantity,
					orderInfoData[rowIdProduct].unit
				)

			}
			else {
				result[rowIdProduct] = orderInfoData[rowIdProduct]
			}

		})

		

		return result
	}

	/**
	 * Permet de supprimer PROPREMENT la commande en éliminant les factures, les bons de livraisons et les bons d'interventions qui sont en relation avec la commande
	 * REMARK, cela peut être fait côté serveur
	 * @param id 
	 */
	public static async deleteOrder(id : string) {
		
		await Promise.allSettled([
			OrdersService.getInstance().delete(id),
			DeliveryNotesService.getInstance().deleteByOrder(id),
			WorkOrdersService.getInstance().deleteByOrder(id),
			BillsService.getInstance().deleteByOrder(id)
		])
	}
}

export default OrdersManager

import { LoggedUser, Modal, toaster } from "@autoprog/core-client"
import TEMPLATE_MODAL from '@tpl/utils/imports.helper.html'
import ImportersService, { UploadedFile } from "../services/ImportersService"
import _ from 'lodash'

const MAX_FILES = 30 // TODO: chercher la limite avec le service application pour recuperer la limite dans le fichier config.js
const LIMIT_FILE_SIZE = 100 * 1024 * 1024 // 100 mo
const TEXT_SENDING = 'Envoyer'

// Ce composant n'est pas reutilisable pour les autres projets
class ImportHelper {
	private constructor() { }

	public static async upload(extensions?: Array<string>) {
		return new ImportFileModal(extensions).open()
	}

	// Stackoverflow : https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
	public static humanFileSize(bytes: number, si: boolean = false, dp: number = 1) {
		const thresh = si ? 1000 : 1024;
	
		if (Math.abs(bytes) < thresh) {
			return bytes + ' B';
		}
	
		const units = si 
			? ['ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'] 
			: ['Kio', 'Mio', 'Gio', 'Tio', 'Pio', 'Eio', 'Zio', 'Yio']
		
		let u = -1
		const r = 10**dp
	
		do {
			bytes /= thresh
			++u;
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)
	
		return bytes.toFixed(dp) + ' ' + units[u]
	}
}

class ImportFileModal extends Modal {
	private patterns: Array<string>

	constructor(private extensions: Array<string> = [".csv"]) {
		super({ template: TEMPLATE_MODAL, backdrop: 'static', keyboard: false })
		this.patterns = []
		this.on('opened', () => this.shouldInit())
	}

	private async shouldInit() {
		await this.load()
		this.initComponents()
	}

	private initComponents() {
		const N_input = this.element.querySelector('input[type="file"]') as HTMLInputElement
		N_input.accept = this.extensions.join(',')
		N_input.addEventListener('change', () => this.refresh())


		const N_import = this.element.querySelector('button#import-files') as HTMLButtonElement
		N_import.addEventListener('click', () => this.send())

		const N_alert = this.element.querySelector('div.alert-instructions') as HTMLDivElement
		N_alert.addEventListener('click', () => N_alert.classList.toggle('d-none', true))
	}

	private refresh() {
		const N_input = this.element.querySelector('input[type="file"]') as HTMLInputElement
		const N_list = this.element.querySelector('#files-list') as HTMLUListElement
		const N_import = this.element.querySelector('button#import-files') as HTMLButtonElement
		const N_error = this.element.querySelector('div.alert-error') as HTMLDivElement

		N_list.innerHTML = ``

		const files = Array.from(N_input.files as FileList)
		N_import.disabled = !!!files.length || files.length >= MAX_FILES
		N_error.classList.toggle('d-none', files.length < MAX_FILES)

		const fragment = document.createDocumentFragment()

		files.forEach(file => {
			const N_item = document.createElement('li') as HTMLLIElement
			N_item.classList.add('list-group-item')
			let icon = file.type === 'application/vnd.ms-excel' || file.name.endsWith('.csv') ? `<i class="icon icon-solid-file-csv"></i>` : `📃`
			icon = !this.patterns.length || (this.patterns.length && this.patterns.some(pattern => new RegExp(pattern).test(file.name))) ? icon : `❌`

			// Gestion des erreurs
			let error = icon === '❌' ? `Nom de fichier incorrect` : ''
			error = file.size > LIMIT_FILE_SIZE ? `Fichier trop volumineux, limit=${ImportHelper.humanFileSize(LIMIT_FILE_SIZE, false)}` : error

			N_item.innerHTML = `${icon} ${file.name} (${ImportHelper.humanFileSize(file.size, false, 2)}) ${!_.isEmpty(error) ? `<small class="text-red">(${error})</small>` : ''}`
			fragment.appendChild(N_item)
		})

		N_import.textContent = (!this.checkValidity()) ? `${TEXT_SENDING} (quand même)` : TEXT_SENDING
		N_list.appendChild(fragment)
	}

	private checkValidity(): boolean {
		const N_input = this.element.querySelector('input[type="file"]') as HTMLInputElement
		const files = Array.from(N_input.files as FileList)
		
		return files.every(this.isValid.bind(this))
	}

	private isValid(file: File) {
		return ( !this.patterns.length || (this.patterns.length && this.patterns.some(pattern => !!file.name.match(pattern)?.length)) ) && file.size <= LIMIT_FILE_SIZE
	}

	private async load() {
		const importers = await ImportersService.getInstance().getHeaders()
		this.patterns = _.compact(importers.map(importer => importer.pattern))

		// Mettre un message pour guider l'utilisateur
		const N_alert = this.element.querySelector('div.alert-instructions') as HTMLDivElement
		N_alert.classList.toggle('d-none', false)

		if (this.patterns.length) {
			const N_formats = N_alert.querySelector('ul') as HTMLElement
			N_formats.innerHTML = ``

			importers.filter(importer => !_.isEmpty(importer.pattern)).forEach(importer => {
				const N_item = document.createElement('li') as HTMLLIElement
				N_item.classList.add('pattern')
				N_item.textContent = `${importer.displayName} => [[${importer.legendPattern || importer.pattern || 'Pas de formats particulier'}]]`
				N_formats.appendChild(N_item)
			})
		}
	}

	private async send() {
		const N_import = this.element.querySelector('button#import-files') as HTMLButtonElement
		const N_input = this.element.querySelector('input[type="file"]') as HTMLInputElement
		const N_progress = this.element.querySelector('.progress') as HTMLDivElement
		N_import.disabled = true
		N_input.disabled = true
		N_progress.classList.toggle('d-none', false)

		try {	

			// Traitement des fichiers un par un
			const files = Array.from(N_input.files as FileList)
			const user = LoggedUser.getInstance().get('ID')

			const uploaded = files
												.filter(this.isValid.bind(this))
												.map<UploadedFile>(file => ({ data: file, user, filename: file.name, size: file.size, type: file.type }))
			
			// On envoie tous aux serveurs									
			await ImportersService.getInstance().upload(uploaded, this.onProgress.bind(this))

			toaster.success('Envoie des fichiers terminées.')
			this.resolve()
		}
		catch {
			toaster.error(`Echec de l'envoie de fichier.`)
		}

		N_import.disabled = false
		N_input.disabled = false
		N_progress.classList.toggle('d-none', true)
	}

	private onProgress(_loaded: number, _total: number, percent: number) {
		const N_bar = this.element.querySelector('.progress .progress-bar') as HTMLDivElement

		// aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"
		N_bar.setAttribute('aria-valuemax', "100")
		N_bar.setAttribute('aria-valuenow', percent.toString())
	}
}

export default ImportHelper

import { Modal, Form, toaster } from "@autoprog/core-client"

import T_addEditUnit from '../../../../tpl/modals/settings/addEditUnit.html'
import UnitsService from '@js/libs/services/UnitsService'
import { Unit } from '@js/types/unit'

class AddEditUnit extends Modal {

	public constructor(private id?: string) {
		super({
			template: T_addEditUnit,
			backdrop: 'static',
			keyboard: false
		})

		this.on('opened', async () => {
			this.initSaveEvent()
			this.id && this.load()
		})

	}

	private initSaveEvent() {
		const N_form = this.element.querySelector('form') as HTMLFormElement
		const N_save = this.element.querySelector('#save-button') as HTMLButtonElement


		if (N_form && N_save) {
			N_save.addEventListener('click', async () => {
				const form = new Form(N_form)

				if (form.checkValidity()) {

					const service = UnitsService.getInstance()
					const data = form.getData()

					if (!data._rev) {
						delete data._rev
					}

					// Verifier que l'unite est unique
					if (service.exists(data.unit as string)) {
						toaster.error('Ce type d\'unité existe déja.')
						return
					}

					if (this.id) {
						try {
							await service.update(data as Unit)
							toaster.success('Unité mis à jour')
							this.resolve()
						}
						catch (e) {
							toaster.error(`Mise a jour de l'unité`, 'Erreur')
							console.error(e)
						}
					}
					else {
						try {
							await service.create(data as Unit)
							toaster.success('Unité ajoutée')
							this.resolve()
						}
						catch (e) {
							toaster.error(`Ajout de l'unité`, 'Erreur')
							console.error(e)
						}
					}

				}
			})
		}
	}

	private async load() {
		if (this.id) {
			const unit = UnitsService.getInstance().getById(this.id)
			const N_form = this.element.querySelector('form') as HTMLFormElement
			const form = new Form(N_form)

			form && form.setData(unit as any)
		}
	}

}

export default AddEditUnit;
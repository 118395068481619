import { Modal, Form, toaster } from "@autoprog/core-client"
import moment from 'moment'

import h from 'hyperscript'
import _ from 'lodash'
import T_addEditOperatingDataDefinition from '../../../../tpl/modals/settings/addEditOperatingDataDefinition.html'

import AnalyticsFormGenerator from '@js/libs/AnalyticsFormGenerator'

import B_checkbox from '@tpl/blocks/settings/operatingDataDefinition/dataTypeParams/checkbox.html'
import B_date from '@tpl/blocks/settings/operatingDataDefinition/dataTypeParams/date.html'
import B_select from '@tpl/blocks/settings/operatingDataDefinition/dataTypeParams/select.html'
import B_number from '@tpl/blocks/settings/operatingDataDefinition/dataTypeParams/number.html'
import B_text from '@tpl/blocks/settings/operatingDataDefinition/dataTypeParams/text.html'
import B_textarea from '@tpl/blocks/settings/operatingDataDefinition/dataTypeParams/textarea.html'
import B_time from '@tpl/blocks/settings/operatingDataDefinition/dataTypeParams/time.html'
import unitList from '@js/libs/lists/unitList'
import OperatingDataDefinitionService, { OperatingDataDefinition } from '@js/libs/services/OperatingDataDefinitionService'

class AddEditOperatingDataDefinition extends Modal {

	private form?: Form

	public constructor(private currentID?: string, private duplicate: boolean = false) {
		super({
			template: T_addEditOperatingDataDefinition,
			backdrop: 'static',
			keyboard: false
		})

		this.on('opened', async () => {

			const N_form = this.element.querySelector('form') as HTMLFormElement

			const dataTypeParams = {
				checkbox: B_checkbox,
				date: B_date,
				select: B_select,
				number: B_number,
				text: B_text,
				textarea: B_textarea,
				time: B_time,
			}

			this.form = new Form(N_form)

			/* -------------------------------------------------------------------------- */
			/*                             Gestion des unités                             */
			/* -------------------------------------------------------------------------- */

			const N_unit = this.form.getElementByName('unit') as HTMLSelectElement
			Object.keys(unitList).forEach(id => N_unit.appendChild(new Option(`${unitList[id].name} (${unitList[id].unit})`, id)))


			/* -------------------------------------------------------------------------- */
			/*                      Affichage des paramètres de types                     */
			/* -------------------------------------------------------------------------- */

			const dataTypeParamsContainer = this.element.querySelector('#data-type-params') as HTMLElement

			//Permet d'afficher les paramètres de type au changement de valeur
			this.form && this.form.on("dataType.change", () => {
				const type = this.form!.getDataByName('dataType') as string;
				dataTypeParamsContainer.innerHTML = dataTypeParams[type as keyof typeof dataTypeParams] || ''
				this.form!.updateInputs()
			})


			/* -------------------------------------------------------------------------- */
			/*                       Gestion des valeurs des select                       */
			/* -------------------------------------------------------------------------- */
			this.form && this.form.on("dataTypeParams.values.change", () => {

				const type = this.form!.getDataByName('dataType') as string;
				if (type == 'select') {

					const values: string[] = (this.form!.getDataByName('dataTypeParams.values') as string).split(';')
					const previousDefaultValue = this.form!.getDataByName('dataTypeParams.value') as string
					const N_dataTypeParamsValue = this.form!.getElementByName('dataTypeParams.value') as HTMLElement

					N_dataTypeParamsValue.innerHTML = '';
					N_dataTypeParamsValue.appendChild(h('option', '', { value: '' }))

					for (let value of values) {
						N_dataTypeParamsValue.appendChild(h('option', value, { value }))
					}

					this.form!.setDataByName('dataTypeParams.value', previousDefaultValue)

				}
			});

			/* -------------------------------------------------------------------------- */

			const N_analytics = this.element.querySelector('.analytics') as HTMLElement
			const afg = new AnalyticsFormGenerator(N_analytics)

			afg.on('render', () => {
				this.form && this.form.updateInputs()
			})

			this.initPreview()

			//On déclanche manuellement l'evenement de changement de type afin de générer les formulaires a l'ouverture de la modale
			this.form.emit('dataType.change')

			const N_save = this.element.querySelector('#save-button') as HTMLButtonElement

			N_save.addEventListener('click', () => this.save())

			afg.once('render', async () => {

				if (this.currentID) {
					try {
						const data = await OperatingDataDefinitionService.getInstance().getByID(this.currentID)
						this.form!.setData(data as any);
						afg.setData({
							analytics: data.analytics || [],
							subAnalytics: data.subAnalytics || [],
						})
						this.form!.emit('dataType.change');
						this.form!.setData(data as any);
					}
					catch (e) {
						toaster.error('Erreur de chargement des données')
						console.error(e)
					}
				}
			})

		})

	}

	private async save() {
		const data = this.form!.getData()

		this.duplicate && Reflect.deleteProperty(data, '_id')

		try {
			if (data._id) {
				await OperatingDataDefinitionService.getInstance().update(data as OperatingDataDefinition)
			}
			else {
				await OperatingDataDefinitionService.getInstance().create(data as OperatingDataDefinition)
			}
			toaster.success(`Donnée d'exploitation enregistrée`)
			this.resolve()
		}
		catch (e) {
			toaster.error(`Erreur d'enregistrement de la donnée`)
			console.error(e)
		}
	}

	private initPreview() {

		const N_preview = this.element.querySelector('#preview') as HTMLElement

		if (this.form) {

			this.form.on(['**.change', '**.input'], _.throttle(() => {

				const data = this.form!.getData() as any;
				let N_input = null

				if (data.dataType == 'checkbox') {
					N_input = h('input.form-check-input') as HTMLInputElement
					N_input.type = 'checkbox';
					N_input.checked = data.dataTypeParams.value

					N_preview.innerHTML = '';
					if (N_input) {
						N_preview.appendChild(
							h('div.d-flex.flex-column',
								h('div.form-check',
									N_input,
									h('label.col-check-label.m-0', data.label),
								),
								h('small.form-text.text-muted', data.description)
							)
						)
					}


				}
				else if (data.dataType == 'select') {
					N_input = h('select.custom-select') as HTMLSelectElement

					const values: string[] = (data.dataTypeParams.values || '').split(';')

					N_input.innerHTML = ''
					for (let value of values) {
						N_input.appendChild(h('option', value, { value }))
					}
				}
				else if (data.dataType == 'textarea') {
					N_input = h('textarea.form-control') as HTMLInputElement
				}


				if (['text', 'textarea', 'number', 'date', 'time', 'select'].includes(data.dataType)) {

					if (!N_input) {
						N_input = h('input.form-control') as HTMLInputElement;
						N_input.type = data.dataType
					}

					if (data.dataTypeParams.maxlength || data.dataTypeParams.maxlength === 0) {
						N_input.setAttribute('maxlength', data.dataTypeParams.maxlength)
					}
					if (data.dataTypeParams.minlength || data.dataTypeParams.minlength === 0) {
						N_input.setAttribute('minlength', data.dataTypeParams.minlength)
					}
					if (data.dataTypeParams.pattern || data.dataTypeParams.pattern === 0) {
						N_input.setAttribute('pattern', data.dataTypeParams.pattern)
					}
					if (data.dataTypeParams.placeholder || data.dataTypeParams.placeholder === 0) {
						N_input.setAttribute('placeholder', data.dataTypeParams.placeholder)
					}
					if (data.dataTypeParams.step || data.dataTypeParams.step === 0) {
						N_input.setAttribute('step', data.dataTypeParams.step)
					}
					if (data.dataTypeParams.max || data.dataTypeParams.max === 0) {

						if (moment.isMoment(data.dataTypeParams.max) && data.dataTypeParams.max.isValid()) {
							N_input.setAttribute('max', data.dataTypeParams.max.format('YYYY-MM-DD'))
						}
						else if (moment.isDuration(data.dataTypeParams.max) && data.dataTypeParams.max.isValid()) {
							N_input.setAttribute('max', moment.utc(data.dataTypeParams.max.as('milliseconds')).format('HH:mm'))
						}
						else {
							N_input.setAttribute('max', data.dataTypeParams.max)
						}
					}
					if (data.dataTypeParams.min || data.dataTypeParams.min === 0) {
						if (moment.isMoment(data.dataTypeParams.min) && data.dataTypeParams.min.isValid()) {
							N_input.setAttribute('min', data.dataTypeParams.min.format('YYYY-MM-DD'))
						}
						else if (moment.isDuration(data.dataTypeParams.min) && data.dataTypeParams.min.isValid()) {
							N_input.setAttribute('min', moment.utc(data.dataTypeParams.min.as('milliseconds')).format('HH:mm'));
						}
						else {
							N_input.setAttribute('min', data.dataTypeParams.min)
						}
					}



					if (data.dataTypeParams.value || data.dataTypeParams.value === 0) {
						if (data.dataType == 'textarea') {
							N_input.innerHTML = data.dataTypeParams.value;
						}
						else if (data.dataType == 'select') {
							const N_option = N_input.querySelector(`[value="${data.dataTypeParams.value}"]`) as HTMLOptionElement | undefined
							N_option && (N_option.selected = true);
						}
						else {
							if (moment.isMoment(data.dataTypeParams.value) && data.dataTypeParams.value.isValid()) {
								N_input.setAttribute('value', data.dataTypeParams.value.format('YYYY-MM-DD'))
							}
							else if (moment.isDuration(data.dataTypeParams.value) && data.dataTypeParams.value.isValid()) {
								N_input.setAttribute('value', moment.utc(data.dataTypeParams.value.as('milliseconds')).format('HH:mm'))
							}
							else {
								N_input.setAttribute('value', data.dataTypeParams.value)
							}
							N_input.innerHTML = data.dataTypeParams.value
						}
					}

					N_preview.innerHTML = ''
					if (N_input) {
						N_preview.appendChild(h('div.form-group.row',
							h('label.col-form-label.col-sm-4', data.label),
							h('div.col-sm-8',
								h('div.input-group',
									N_input,
									(data.unit ? h('div.input-group-append', h('span.input-group-text', unitList[data.unit].unit)) : '')
								),

								h('small.form-text.text-muted', data.description)
							)

						))
					}

				}




			}, 200))

		}

	}
}

export default AddEditOperatingDataDefinition

import { CouchDBData } from '@js/types/couchDB'
import axios from 'axios'
import { global } from "@autoprog/core-client"

import _ from 'lodash'

class BDD {


	private constructor() {

	}


	public static getDataByID(table: string, id: string): Promise<CouchDBData> {

		return new Promise((resolve, reject) => {

			axios.get(`${global.COUCHDB_URL}/${table}/${id}`).then((response) => {
				resolve(response.data);
			}).catch((e) => {
				reject(e);

			})
		})
	}

	public static generateID(): string {
		return _.uniqueId(Date.now().toString(36) + '_');
	}

	public static putData(table: string, data: CouchDBData): Promise<{ id: string, ok: boolean, rev: string }> {

		return new Promise((resolve, reject) => {
			axios.put(`${global.COUCHDB_URL}/${table}/${data._id}`, data).then((response) => {
				if (response.data.ok) {
					resolve(response.data);
				} else {
					reject(response);
				}
			}).catch((e) => {
				reject(e);
			})
		})
	}

}

export default BDD;
import { Modal, Form } from "@autoprog/core-client"

import 'select2'
import toastr from 'toastr'

import T_addUser from '@tpl/modals/settings/addUser.html'


import { UserData, GroupData } from '@js/types/settings'
import User from '@js/types/user'
import UsersService from '@js/libs/services/UsersService';
import UtilsService from '@js/libs/services/UtilsService';

import PasswordUtils from '@js/libs/utils/password'
import GroupsService from '@js/libs/services/GroupsService';
import SitesService from '@js/libs/services/SitesService';


class AddEditUser extends Modal {

	private groups: GroupData[]
	private currentUser?: User

	private static passwordDefaultMessage = "C'est raté :)"

	constructor(private id?: string) {
		super({
			tpl: T_addUser,
			backdrop: 'static',
			keyboard: false
		});

		this.groups = []

		this.on('opened', async () => {

			let groups = await GroupsService.getInstance().getAll()
			let sites = await SitesService.getInstance().getAllSites()

			if (this.element && groups) {

				this.groups = groups;
				let select2Groups = this.groups.map(group => { return { id: group._id, text: group.name } });
				let select2Sites = sites.map(site => { return { id: site._id, text: site.name } });

				$('#groups-select').select2({ data: select2Groups, dropdownParent: $(this.element) });
				$('#site-select').select2({ data: select2Sites, dropdownParent: $(this.element) });
				$('#sites-select').select2({ data: select2Sites, dropdownParent: $(this.element) });

				let addButton = this.element.querySelector('#add-user-button') as HTMLButtonElement;
				addButton.addEventListener2('click', this.addButtonEvent.bind(this))

				setTimeout(() => {
					let N_el = this.element.querySelectorAll('[readonly]');
					N_el.forEach((el) => el.removeAttribute('readonly'))
				}, 300)

				if (id) {
					const N_title = this.element.querySelector('.modal-title') as HTMLElement
					N_title.innerHTML = 'Modification Utilisateur';
					this.loadData()
				}


			}

		});


	}

	private async loadData() {
		const N_form = this.element.querySelector('#add-user-form') as HTMLFormElement
		const form = new Form(N_form)

		const user = await UsersService.getInstance().getByID(this.id!)
		this.currentUser = user

		//FIXME: Retirer le cast
		form.setData(this.currentUser as any);
		form.setDataByName('password', AddEditUser.passwordDefaultMessage)

		form.on('password.keypress', () => {
			if (form.getDataByName('password') === AddEditUser.passwordDefaultMessage) {
				form.setDataByName('password', null)
			}
		})
	}


	private async addButtonEvent(_event: Event) {

		const N_form = this.element.querySelector('#add-user-form') as HTMLFormElement;
		const form = new Form(N_form);

		if (form.checkValidity()) {
			const userData = form.getData() as UserData;

			const user: User = {
				_id: "",
				employee: userData.employee as string,
				allowSites: userData.allowSites,
				firstname: userData.firstname,
				groups: userData.groups,
				lastname: userData.lastname,
				login: userData.login,
				password: "",
				site: userData.site,
				email: userData.email,
				job: userData.job,
				has_mail_prevalidation_order: true,
				has_notification_mail_prevalidated_order: true
			}

			try {
				if (!this.currentUser) {
					const doesLoginTaken = await UsersService.getInstance().loginExists(userData.login)

					if (doesLoginTaken) {
						toastr.error(`Identifiant existant`);
						return
					}

					const uuid = await UtilsService.getInstance().generateUUID()
					user._id = uuid
					user.password = PasswordUtils.hashPassword(userData.password)

					await UsersService.getInstance().create(user)
					toastr.success('Utilisateur ajouté')
				}
				else {
					user._id = this.currentUser._id
					user.password = (userData.password != AddEditUser.passwordDefaultMessage)
						? PasswordUtils.hashPassword(userData.password)
						: this.currentUser.password

					userData.login = userData.login.trim().toLowerCase();
					userData.employee = this.currentUser.employee;
					await UsersService.getInstance().update(user)
					toastr.success('Utilisateur Modifié')
				}
				this.resolve()
			}
			catch (e) {
				console.error(e)
				toastr.error('Erreur de base de données')
				this.reject()
			}


		}
	}


}

export default AddEditUser;
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="modal fade" role="dialog">\r\n    <div class="modal-dialog" id="modal-update-event-repeat" role="document">\r\n\r\n        <div class="modal-content">\r\n\r\n            <div class="modal-header sticky-top container-fluid white">\r\n\r\n                <div>\r\n                    <h5 class="modal-title">Modification de l\'événement périodique</h5>\r\n                </div>\r\n\r\n            </div>\r\n\r\n            <div class="modal-body">\r\n\r\n                <div class="custom-control custom-radio my-2">\r\n                    <input type="radio" id="onlyOne" name="typeEvent" class="custom-control-input" checked>\r\n                    <label class="custom-control-label" for="onlyOne">Cet événement</label>\r\n                </div>\r\n\r\n                <div class="custom-control custom-radio my-2">\r\n                    <input type="radio" id="after" name="typeEvent" class="custom-control-input">\r\n                    <label class="custom-control-label" for="after">Cet événement et tous les suivants</label>\r\n                </div>\r\n\r\n                <div class="custom-control custom-radio my-2">\r\n                    <input type="radio" id="all" name="typeEvent" class="custom-control-input">\r\n                    <label class="custom-control-label" for="all">Tous les événements</label>\r\n                </div>\r\n\r\n            </div>\r\n\r\n            ';
if(global.IS_MOBILE){;
__p += '\r\n            \r\n            <div class="modal-footer btn-group w-100">\r\n                \r\n                <button class="btn btn-success w-50" id="save">\r\n                    Sauvegarder\r\n                </button>\r\n\r\n                <button class="btn btn-danger w-50" data-reject="modal">\r\n                    Annuler\r\n                </button>\r\n\r\n            </div>\r\n\r\n            ';
}else{;
__p += '\r\n            <div class="modal-footer text-right">\r\n                \r\n                <button class="btn btn-success" id="save">\r\n                    Sauvegarder\r\n                </button>\r\n\r\n                <button class="btn btn-danger" data-reject="modal">\r\n                    Annuler\r\n                </button>\r\n\r\n            </div>\r\n            ';
};
__p += '\r\n            \r\n\r\n        </div>\r\n\r\n    </div>';

}
return __p
}
import TuiDatePicker from 'tui-date-picker';

TuiDatePicker.localeTexts['french'] = {
	titles: {
		// days
		DD: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
		// daysShort
		D: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
		// months
		MMMM: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
		// monthsShort
		MMM: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aout', 'Sep', 'Oct', 'Nov', 'Dec']
	},
	titleFormat: 'MMM yyyy',
	todayFormat: 'D dd MMMM yyyy',
	date: 'Date',
	time: 'Heure'
};

export default TuiDatePicker;
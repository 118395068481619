import { Modal, Form, global } from "@autoprog/core-client"
import { TiersService, AccountingAccountsService, SubAnalyticsService, AnalyticsService, OrdersService, UsersService } from '@js/libs/services'
  
import T_detailOrders from '@tpl/modals/orders/detailOrders.html';
import moment from 'moment'
import toastr from 'toastr';
import { Product } from '@js/types/products'
import T_lineTableOrderedProduct from '@tpl/blocks/orders/order-note/tableProductLine2.ejs';
import AddEditOrders from './AddEditOrders'
import unitList from '@libs/lists/unitList'
import stateList from '@libs/lists/stateListMale'

import T_events from '@tpl/blocks/orders/detailEvent.ejs'
import Order from '@js/types/order'

import Tier from '@js/types/Tiers'


import _ from 'lodash'
import { User } from '@js/types/user'
import PrinterHelper from "@js/libs/utils/printer.helper";

type OrderProducts = {
	product: Product
	quantity: number
	unitPrice: number
}

interface OrderDataDetail extends Order {
	customerData: Tier
	providerData: Tier,
	eventUsers: Array<User>
}

class DetailOrderModal extends Modal {

	private N_title: HTMLElement | null = null
	private N_form: HTMLFormElement | null = null
	private N_productTable: HTMLTableElement | null = null
	private N_subTotal: HTMLInputElement | null = null
	private order?: OrderDataDetail
	private total: number = 0

	private id: string

	constructor(id: string) {
		super({
			tpl: T_detailOrders,
			backdrop: 'static',
			keyboard: false
		})

		this.id = id

		this.on('opened', async () => {

			let type = {
				purchaseProduct: 'Achat produit',
				saleProduct: 'Vente produit',
				purchaseWorkOrder: 'Achat Intervention',
				saleWorkOrder: 'Vente Intervention'
			} as any

			this.N_title = this.element.querySelector('#title') as HTMLElement;
			this.N_form = this.element.querySelector('#detail-order-form') as HTMLFormElement;
			this.N_productTable = this.element.querySelector('#tableProduct') as HTMLTableElement;
			this.N_subTotal = this.N_productTable.querySelector("#subTotalPrice") as HTMLInputElement;

			this.order = await this.getRowData()

			this.initEvent(this.order.events.map(event => ({...event, user: this.order!.eventUsers.find(u => u._id === event.user)  })) || [])

			if (this.N_title) {
				this.N_title.innerHTML = this.order.orderNumber;
			}

			 this.order.orderDate = parseInt(moment(this.order.orderDate, 'x').add(12, 'hours').format('x'));
			 this.order.deliveryDate = parseInt(moment(this.order.deliveryDate, 'x').add(12, 'hours').format('x'));

			this.order.type = type[this.order.type]

			if (this.order.products) {
				for (let i = 0; i < this.order.products.length; i++) {

					const orderProduct = _.cloneDeep(this.order.products[i] as any);
					orderProduct.quantity = orderProduct.quantity / unitList[orderProduct.unit].coeficient;
					orderProduct.unitPrice = orderProduct.unitPrice * unitList[orderProduct.unit].coeficient;
	
					orderProduct.accountingAccount = orderProduct.subAnalytics = orderProduct.analytics = ""
	
					if (this.order.products[i].accountingAccount) {
						const data = AccountingAccountsService.getInstance().getById(this.order.products[i].accountingAccount)
						orderProduct.accountingAccount = data ? data.name : ""
					}
	
					if (this.order.products[i].subAnalytics && this.order.products[i].subAnalytics.length > 0) {
						const data = SubAnalyticsService.getInstance().getById(this.order.products[i].subAnalytics[0].id)
						orderProduct.subAnalytics = data ? data.name : ""
					}
					
					if (this.order.products[i].analytics && this.order.products[i].analytics.length > 0) {
						orderProduct.analytics = this.order.products[i].analytics.map(analytic => {
							const analyticObject = AnalyticsService.getInstance().getById(analytic.id)
							return analyticObject ? analyticObject.name : ""
						}).join(', ')
					}
	
					this.addProductLine(orderProduct);
					this.total = this.total + orderProduct.unitPrice * orderProduct.quantity;
				}
			}


			if (this.N_form) {
				let form = new Form(this.N_form)
				form.setData(this.order as any)
			}

			if (this.N_subTotal) {
				this.N_subTotal.innerHTML = _.round(this.total, 2).toString()
			}

			let N_attachment = this.element.querySelector('#attachments') as HTMLElement

			if (this.order._attachments) {
				this.addAttachment('orders', N_attachment, this.order);

			} else {
				N_attachment.innerHTML = 'Pas de pièces jointes';
			}

			let N_editBtn = this.element.querySelector('#editButton') as HTMLButtonElement

			if (this.order.state == 'billed') {
				N_editBtn.remove()
			}

			N_editBtn.addEventListener2('click', () => {
				if (this.order && this.order.state != 'billed') {
					let modal = new AddEditOrders(id);
					this.hide()
					modal.open().then(() => this.resolve(true)).catch(() => this.show())
				}
			})


			const N_printBtn = this.element.querySelector('#printButton') as HTMLButtonElement
			N_printBtn.addEventListener('click', async () => {
				try {
					const blob = await OrdersService.getInstance().getPDF(id)
					PrinterHelper.previewPDF(blob, `${id}.pdf`)
				}
				catch (e) {
					toastr.error(`Erreur lors de l'impression`)
					console.error(e)
				}
			})

		})
	}

	private async getRowData() : Promise<OrderDataDetail> {
		const order = await OrdersService.getInstance().getByID(this.id)
		const tiers = await TiersService.getInstance().getByIds(_.uniq([order.customer, order.provider]))
	
		const eventUsers = order.events && order.events.length > 0 ? await UsersService.getInstance().getByIds(order.events.map(event => event.user)) : []
	
		return {
			...order,
			customerData: tiers.find(tier => tier._id == order.customer)!,
			providerData: tiers.find(tier => tier._id == order.provider)!,
			eventUsers
		}
	}

	private initEvent(events: any[]) {

		const N_container = this.element.querySelector('#events');

		if (N_container) {
			N_container.innerHTML = T_events({
				events,
				moment,
				stateList
			})
		}
	}

	private addProductLine(orderProduct: OrderProducts) {
		if (this.N_productTable) {
			const tbody = this.N_productTable.querySelector('tbody') as HTMLTableSectionElement
			const row = tbody.insertRow() as HTMLTableRowElement
			const index = tbody.rows.length - 1
			const totalPrice = _.round((orderProduct.unitPrice * orderProduct.quantity), 2)
			row.innerHTML = T_lineTableOrderedProduct({ index, unitList, orderProduct, totalPrice })
		}
	}

	private addAttachment(table: string, N_element: HTMLElement, object: any): void {

		for (let attachment in object._attachments) {

			const N_buttonAttachment = document.createElement('button') as HTMLButtonElement

			N_buttonAttachment.type = 'button'
			N_buttonAttachment.classList.add('btn', 'btn-outline-secondary', 'btn-sm', 'btn-rounded', 'mr-2')
			N_buttonAttachment.innerHTML = attachment

			N_element.appendChild(N_buttonAttachment)

			if (N_buttonAttachment) {
				N_buttonAttachment.addEventListener('click', () => {
					window.open(`${global.COUCHDB_URL}/${table}/${object._id}/${attachment}`, "_blank");
				})
			}
		}
	}
}

export default DetailOrderModal;

import { Controller, LoggedUser } from "@autoprog/core-client"

import TabsExtended from '@libs/TabsExtended'

import C_TabProduct from '@libs/tabs/productTiers/TabProduct'
import C_TabTiers from '@libs/tabs/productTiers/TabTiers'

import T_TabProduct from '@tpl/tabs/productsTiers/products.html'
import T_TabTiers from '@tpl/tabs/productsTiers/tiers.html'

class ProductsCtrl extends Controller {
	private tabs: TabsExtended
	constructor(private el: HTMLElement, params: object) {
		super(el, params)

		this.tabs = new TabsExtended({
			navElement: this.el.querySelector('#product-tier-menu') as HTMLElement,
			renderElement: this.el.querySelector('.product-tier-content') as HTMLElement,
			navTemplate: '<button permission="${tab.data.permission}" tab-link="${tab.id}" class="btn btn-tab btn-grey-300">${tab.name}</button>',
		})

		const loggedUser = LoggedUser.getInstance()

		this.tabs.add({
			name: 'Produits',
			controller: C_TabProduct,
			default: loggedUser.hasPermission('PRODUCTS_THIRDPARTIES.PRODUCTS.SHOW'),
			template: T_TabProduct,
			data: {
				permission: 'PRODUCTS_THIRDPARTIES.PRODUCTS.SHOW'
			}
		})

		this.tabs.add({
			name: 'Tiers',
			controller: C_TabTiers,
			template: T_TabTiers,
			default:
				!loggedUser.hasPermission('PRODUCTS_THIRDPARTIES.PRODUCTS.SHOW') &&
				loggedUser.hasPermission('PRODUCTS_THIRDPARTIES.THIRDPARTIES.SHOW'),
			data: {
				permission: 'PRODUCTS_THIRDPARTIES.THIRDPARTIES.SHOW'
			}
		})

	}

	destructor() {
		this.tabs.destructor()

	}
}

export default ProductsCtrl

module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p +=
((__t = (name)) == null ? '' : __t) +
' <i>(' +
((__t = (percent)) == null ? '' : __t) +
'%)</i>';

}
return __p
}
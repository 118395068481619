module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h5>Caractéristiques de l\'intrant</h5>\r\n<hr class="border-green">\r\n\r\n<div class=\'form-row\'>\r\n    ';
 for (let property in rawMaterialProperties){;
__p += '\r\n    ';
let item = rawMaterialProperties[property];;
__p += '\r\n    ';
let unitData = unitList[item.unit];
__p += '\r\n    <div class="form-group col-lg-3 col-md-6">\r\n        <label for="' +
((__t = (property)) == null ? '' : __t) +
'" class="font-weight-bold">' +
((__t = (item.name)) == null ? '' : __t) +
'\r\n            (' +
((__t = (unitData ? unitData.unit :  item.unit)) == null ? '' : __t) +
')</label>\r\n        <div class="input-group">\r\n            <input type="text" class="form-control-plaintext" name="' +
((__t = (property)) == null ? '' : __t) +
'" readonly>\r\n        </div>\r\n    </div>\r\n    ';
};
__p += '\r\n</div>';

}
return __p
}
import { global, LoggedUser, Tab } from "@autoprog/core-client"
import { Grid, GridOptions, ICellRendererParams } from 'ag-grid-community'
import { DeliveryNotesService, OrdersService, TiersService } from "@js/libs/services"

import 'ag-grid-enterprise'
import moment from 'moment'
import toastr from 'toastr'
import h from 'hyperscript'
import unitList from '@libs/lists/unitList'
import stateList from '@libs/lists/stateListMale'
import { AGGridUtils, french as agGridFrench } from '@libs/utils/agGrid'

import Export from '@libs/agGrid/Export'
import displayWeight from '@libs/DisplayWeight'

import DatePickerRangeFilter from '@libs/agGrid/DatePickerRangeFilter'

import AddEditInputTransaction from '@js/libs/modals/weighBridge/AddEditInputTransaction'
import TextFilterAccent from '@libs/agGrid/TextFilterAccent'

import Order from '@js/types/order'
import DeliveryNote from '@js/types/delivery-note'
import Tier from '@js/types/Tiers'

import _ from "lodash"
import PrinterHelper from "@js/libs/utils/printer.helper"

interface TabHistoricRowOrder extends Order {
	customerObject?: Tier
	providerObject?: Tier
}

interface TabHistoricRow extends DeliveryNote {
	orderData: TabHistoricRowOrder
}

class TabHistoric extends Tab {

	private gridOptions: GridOptions = <GridOptions>{};
	// private N_gridTransactions: HTMLElement | null = null;

	constructor(private el: HTMLElement) {

		super(el)

		const states = ['all', 'notArchived','notBilled', 'billed','archived']

		const N_quickFilters = this.el.querySelector("#quick-filters") as HTMLElement

		states.forEach(key => {

			const state = stateList[key]

			const N_btn = h(`button.btn.btn-sm.btn-rounded.mr-1.btn-state-${state.cls}`, state.name, {
				onclick: () => {

					if (this.gridOptions && this.gridOptions.api) {

						if (key == 'all') {

							let values = [];

							for (let state of states) {
								if (stateList[state]) {
									values.push(stateList[state].name)
								}
							}

							this.gridOptions.api.setFilterModel({
								state: {
									type: 'set',
									values
								}
							});

						} else {
							this.gridOptions.api.setFilterModel({
								state: {
									type: 'set',
									values: [state.name]
								}
							})
						}

						this.gridOptions.api.onFilterChanged();
					}
				}
			})

			N_quickFilters.appendChild(N_btn)
		})



		this.gridOptions = agGridFrench({
			excelStyles: [
				{
					id: 'dateHours',
					dataType: 'DateTime',
					numberFormat: {
						format: 'dd/mm/yyyy hh:MM',
					}
				},
				{
					id: 'number',
					dataType: 'Number'
				}
			],
			columnDefs: [
				{
					headerName: 'Bon livraison',
					field: 'deliveryNumber'
				},
				{
					headerName: 'Etat',
					field: 'state',
					filter: 'agSetColumnFilter',
					filterValueGetter: (params) => {
						if (params.data && params.data.state && stateList[params.data.state]) {
							return stateList[params.data.state].name
						}
					},
					cellRenderer: (params) => {

						if (params.node.level == 0 && params.value && stateList[params.value]) {
							return stateList[params.value].name
						}
						return '';

					},
				},
				{
					headerName: 'Date',
					sort: 'desc',
					field: 'date',
					cellClass: 'dateHours',
					filter: 'agDateColumnFilter',
					floatingFilterComponent: DatePickerRangeFilter,
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					filterParams: {
						filterOptions: ['inRange', 'empty'],
						suppressAndOrCondition: true,
						inRangeInclusive: true,
						comparator: (filterValue: Date, cellValue: number) => {

							let filter = parseInt(moment(filterValue).startOf('day').format('x'));
							let cell = parseInt(moment(cellValue).startOf('day').format('x'))

							if (cell < filter) {
								return -1;
							} else if (cell > filter) {
								return 1
							}

							return 0
						}
					},
					cellRenderer: (params) => {
						let date = moment(params.value, 'x');
						if (date.isValid()) {
							if (params.type == 'excel') {

								return date.format('YYYY-MM-DDTHH:mm');
							}

							return date.format('DD/MM/YYYY HH:mm');
						}
						return '';
					}
				},
				{
					headerName: 'Commande',
					field: 'order'
				},
				{
					headerName: 'Founisseur',
					field: 'orderData.providerObject.name'
				},
				{
					headerName: 'Client',
					field: 'orderData.customerObject.name'
				},
				{
					headerName: 'Chauffeur',
					field: 'driver'
				},
				{
					headerName: 'Commune de chargement',
					field: 'loadingZone'
				},
				{
					headerName: 'Parcelle',
					field: 'parcel'
				},
				{
					headerName: 'Produit',
					field: 'products.0.reference'
				},
				{
					headerName: 'Quantité',
					field: 'products.0.deliveredQuantity',
					cellClass: ['text-right', 'number'],
					cellRenderer: (params) => {
						let value = displayWeight(params.value, 'kg') || '0';

						if (params.type == 'excel') {
							return Number(value.replace(/\s/g, '')).toString();
						}

						return value
					}
				}, 
				{
					headerName: 'Unité',
					cellRenderer: (_params) => unitList['kg'].unit
				}, 
				{
					headerName: 'Poids Entrée',
					cellClass: 'number',
					field: 'weights.input.weight',
					cellRenderer: (params) => {

						let value = ''

						if (!params.node.rowPinned) {

							if (params.value) {
								value = displayWeight(params.value, 'kg') + ` kg`
							}

						} else {
							value = displayWeight(params.value, 'kg') + ' kg';
						}

						if (params.type == 'excel') {
							return parseFloat(value.replace(/\s/g, '')).toString();
						}

						return value;
					}
				},
				{
					headerName: 'DSD Entrée',
					field: 'weights.input.dsd'
				},
				{
					headerName: 'Poids Sortie',
					cellClass: 'number',
					field: 'weights.output.weight',
					cellRenderer: (params) => {

						let value = ''

						if (!params.node.rowPinned) {

							if (params.value) {
								value = displayWeight(params.value, 'kg') + ` kg`
							}

						} else {
							value = displayWeight(params.value, 'kg') + ' kg';
						}

						if (params.type == 'excel') {
							return parseFloat(value.replace(/\s/g, '')).toString();
						}

						return value;

					}
				},
				{
					headerName: 'DSD Sortie',
					field: 'weights.ouput.dsd'
				},
				{
					headerName: '#',
					field: '_id',
					colId: 'actions',
					width: 80,
					suppressSizeToFit: true,
					cellRenderer: (params: ICellRendererParams) => {

						const N_div = document.createElement('div')

						if (params.data.state != 'notArchived' && !params.node.rowPinned) {

							const deleteButton = document.createElement('button');

							deleteButton.classList.add('btn', 'btn-xs', 'btn-danger');
							deleteButton.setAttribute('confirmation', "");
							deleteButton.setAttribute('permission', "WEIGHBRIDGE.TRANSACTIONS.DELETE");

							deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"> </i>';

							deleteButton.addEventListener2('click', async () => {

								const loggedUser = LoggedUser.getInstance()

								try {
									await DeliveryNotesService.getInstance().delete(params.value)
									await OrdersService.getInstance().updateOrder(params.data.orderData.orderNumber)
									await DeliveryNotesService.getInstance().createEvent({
										date: Date.now(),
										user: loggedUser.get('ID'),
										type: 'delete',
										id: params.value,
									})

									params.api.applyTransaction({ remove: [params.data] })
									toastr.success('BL supprimée')
								}
								catch (e) {
									toastr.error(`Erreur lors de la supression du BL`)
									console.log(e)
								}

							})

							N_div.appendChild(deleteButton)

							if (params.data.state != 'billed') {

								const editButton = document.createElement('button')

								editButton.classList.add('btn', 'btn-xs', 'btn-info');
								editButton.setAttribute('permission', "WEIGHBRIDGE.TRANSACTIONS.EDIT")
								editButton.innerHTML = '<i class="icon icon-solid-edit"> </i>'

								editButton.addEventListener2('click', () => {

									const modal = new AddEditInputTransaction(params.data._id);

									modal.open().then(() => {
										this.updateGrid()
										toastr.info('Transaction mis à jour')
									}).catch(() => {
										toastr.info('Modification annulée')
									})

								})

								N_div.appendChild(editButton)

							}


						} 
						

						return N_div
					}
				}
			],


			// floatingFilter: true,
			suppressDragLeaveHidesColumns: true,
			suppressAggFuncInHeader: true,
			defaultColDef: {
				floatingFilter: true,
				resizable: true,
				sortable: true,
				filter: 'agTextColumnFilter',
				suppressMenu: true,
				floatingFilterComponentParams: { suppressFilterButton: true },
				filterParams: {
					filterOptions: ['contains'],
					textCustomComparator: TextFilterAccent
				},
			},
			sideBar: {
				toolPanels: [{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnFilter: true
					}
				}]
			},
			autoGroupColumnDef: {
				headerName: 'Commande',
			},
			getContextMenuItems: (params) => {
				const columnKeys = AGGridUtils.getColumnsIds(this.gridOptions, 'actions')

				const exportAllExcel = {
					name: 'Exporter tous Excel',
					action: () => {
						let exp = new Export(params);
						exp.exportExcel({
							columnKeys
						})
					}
				}

				const exportAllCSV = {
					name: 'Exporter tous CSV',
					action: () => {
						let exp = new Export(params);
						exp.exportCSV({
							columnKeys
						})
					}
				}

				const print = {
					name: 'Imprimer',
					action: async () => {
						const blob = await DeliveryNotesService.getInstance().getPDF(params.node.data._id)
						PrinterHelper.previewPDF(blob, `${params.node.data._id}.pdf`)
					}
				}

				const result = [exportAllExcel, exportAllCSV]

				if (params.node && params.node.data && params.node.data.state != 'notArchived') {
					result.push(print);
				}

				return result
			},
			getRowClass: (params) => {
				const data = params.node.aggData || params.data;

				if (data) {
					return stateList[data.state] ? 'ag-row-' + stateList[data.state].cls : '';
				}

				return ''
			},
			onGridReady: () => this.updateGrid(),
			onRowDataChanged: () => this.updatePinnedRowData(),
			onFilterChanged: () => this.updatePinnedRowData()
		})

		const N_gridTransactions = this.el.querySelector('#grid-historic') as HTMLElement
		new Grid(N_gridTransactions, this.gridOptions)
	}

	public updateSelectSite(_value: string) {
		this.updateGrid()
	}

	private updatePinnedRowData() {

		const keys = ['weights.input.weight', 'weights.output.weight', 'products.0.deliveredQuantity']
		const values: { [key: string]: any } = {}

		if (this.gridOptions.api) {

			this.gridOptions.api.forEachNodeAfterFilter((node) => {

				const data = _.cloneDeep(node.data);

				for (let key of keys) {

					let value = _.get(data, key);

					values[key] = values[key] || []

					if (!_.isUndefined(value)) {
						values[key].push(value)
					}

				}

			})

			const average: { [key: string]: any } = {}
			const sum: { [key: string]: any } = {}

			for (let key in values) {

				const value = _.sum(values[key]) || 0

				_.set(sum, key, value);

				_.set(average, key, ((value / values[key].length) || 0).toFixed(2))
			}

			average.deliveryNumber = 'Moyenne'
			sum.deliveryNumber = 'Somme'

			this.gridOptions.api.setPinnedBottomRowData([average, sum])
		}

	}

	private async updateGrid() {
		this.getRowData().then(data => {
			this.gridOptions.api?.setRowData(data)
			setTimeout(() => { this.gridOptions.api?.sizeColumnsToFit(); }, 50)
		})
			.catch(e => {
				console.error(e)
				toastr.error("Echec du chargement des données", "Erreur")
			})
	}



	private async getRowData(): Promise<Array<TabHistoricRow>> {
		const transactionalDeliveriesData = await DeliveryNotesService.getInstance().getTransactionalBySite(global.SITE)

		////// Récupération des commandes lié au Bon de livraisons
		const orderNumbers = _.uniq(transactionalDeliveriesData.map(deliveryNote => deliveryNote.order))
		const ordersData: Array<Order> = await OrdersService.getInstance().getByIds(orderNumbers)

		// Récupérations des Customers
		// Récupération des Providers
		const providersNumber = _.uniq(ordersData.map(order => order.provider))
		const customersNumber = _.uniq(ordersData.map(order => order.customer))
		const tiersNumber = _.uniq([...providersNumber, ...customersNumber])

		// Récupération de tous les tiers
		const tiers = await TiersService.getInstance().getByIds(tiersNumber)

		// On fait une première transformation de notre data
		// TODO: ameliorer cette partie, meme si elle fonctionne
		const result: Array<TabHistoricRow> = transactionalDeliveriesData.reduce((cumul, deliveryNote) => {
			const order = ordersData.filter(order => order._id == deliveryNote.order)[0]
			cumul.push({
				...deliveryNote, orderData: {
					...order,
					customerObject: tiers.filter(t => t._id == order.customer)[0],
					providerObject: tiers.filter(t => t._id == order.provider)[0]
				}
			})
			return cumul
		}, [] as Array<TabHistoricRow>)


		return result
	}


	public destructor() {

	}
}

export default TabHistoric

module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="tui-full-calendar-popup-container">\r\n    <div class="tui-full-calendar-popup-section tui-full-calendar-section-header">\r\n        <div>\r\n            <span class="tui-full-calendar-schedule-private tui-full-calendar-icon tui-full-calendar-ic-private"></span>\r\n            <span class="tui-full-calendar-schedule-title">' +
((__t = (data.title)) == null ? '' : __t) +
'</span>\r\n        </div>\r\n        <div class="tui-full-calendar-content text-muted" style="height: auto;">\r\n            ';
let html = [];;
__p += '\r\n\r\n            ';
for(let idSite of data.calendar.sites){;
__p += '\r\n\r\n                ';
let tmp = _.find(sites, {_id : idSite});;
__p += '\r\n\r\n                ';
html.push(tmp ? tmp.name : idSite);;
__p += '\r\n\r\n            ';
};
__p += '\r\n\r\n            ' +
((__t = (html.length > 1 ? 'Sites' : 'Site')) == null ? '' : __t) +
' : ' +
((__t = (html.join(', '))) == null ? '' : __t) +
'\r\n        </div>\r\n        <div class="tui-full-calendar-content">\r\n            ' +
((__t = (data.date)) == null ? '' : __t) +
'\r\n        </div>\r\n        ';
if(data.description){;
__p += '\r\n            <div class="tui-full-calendar-content">\r\n                ' +
((__t = (data.description)) == null ? '' : __t) +
'\r\n            </div>\r\n        ';
};
__p += '\r\n        ';
if(data.users && data.users.length){;
__p += '\r\n            <div class="tui-full-calendar-content">Interventants :</div>\r\n            ';
for(let user of data.users){;
__p += '\r\n                ';
let tmp = _.find(users, {_id : user });
__p += '\r\n                ';
if(tmp){;
__p += '\r\n                    <div class="tui-full-calendar-content">\r\n                        ' +
((__t = (tmp.firstname)) == null ? '' : __t) +
' ' +
((__t = (tmp.lastname)) == null ? '' : __t) +
'\r\n                    </div>\r\n                ';
}else{;
__p += '\r\n                    <div class="tui-full-calendar-content">\r\n                        ' +
((__t = (user)) == null ? '' : __t) +
'\r\n                    </div>\r\n                ';
};
__p += '\r\n            ';
};
__p += '\r\n        ';
};
__p += '\r\n\r\n    </div>\r\n\r\n    <div class="tui-full-calendar-section-button">\r\n    \r\n        ';
if(!data.locked){;
__p += '\r\n            ';
if(!data.suppressEdit){;
__p += '\r\n                <button class="tui-full-calendar-popup-edit ' +
((__t = (data.suppressRemove ? 'w-100' : '')) == null ? '' : __t) +
'">\r\n                    <span class="tui-full-calendar-icon tui-full-calendar-ic-edit"></span>\r\n                    <span class="tui-full-calendar-content">Modifier</span>\r\n                </button>\r\n            ';
};
__p += '\r\n\r\n            ';
if(!data.suppressEdit && !data.suppressRemove){;
__p += '\r\n                <div class="tui-full-calendar-popup-vertical-line"></div>\r\n            ';
};
__p += '\r\n\r\n            ';
if(!data.suppressRemove){;
__p += '\r\n                \r\n                <button class="tui-full-calendar-popup-delete ' +
((__t = (data.suppressEdit ? 'w-100' : '')) == null ? '' : __t) +
'">\r\n                    <span class="tui-full-calendar-icon tui-full-calendar-ic-delete"></span>\r\n                    <span class="tui-full-calendar-content">Supression</span>\r\n                </button>\r\n            ';
};
__p += '\r\n        ';
};
__p += '\r\n    </div>\r\n</div>\r\n<div class="tui-full-calendar-popup-top-line" style="background-color: ' +
((__t = (data.bgColor)) == null ? '' : __t) +
'"></div>\r\n    <div id="tui-full-calendar-popup-arrow" class="tui-full-calendar-popup-arrow tui-full-calendar-arrow-left">\r\n        <div class="tui-full-calendar-popup-arrow-border">\r\n        <div class="tui-full-calendar-popup-arrow-fill"></div>\r\n    </div>\r\n</div>\r\n';

}
return __p
}
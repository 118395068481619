import { Modal, Form, toaster } from "@autoprog/core-client"
import { AccountingAccountsService, VATService, SubAnalyticsService, AnalyticsService, SitesService, ProductsService } from "@js/libs/services"

import T_addProduct from '../../../../tpl/modals/products/formProduct.ejs'
import { Analytic } from '@libs/services/AnalyticsService'
import { SubAnalytic } from '@libs/services/SubAnalyticsService'
import unitList from '@libs/lists/unitList'
import rawMaterialProperties from '@libs/lists/rawMaterialPropertiesList'
import T_rawMaterialPropertiesForm from '@tpl/modals/products/rawMaterialPropertiesForm.ejs'
import 'select2';
import 'select2/dist/css/select2.min.css'
import FilesManager from '../../FilesManager'
import toastr from 'toastr'
import { CouchDBAttachment, CouchDBData } from '@js/types/couchDB'
import { Product } from '@js/types/products'

import AnalyticsFormGenerator from '@js/libs/AnalyticsFormGenerator'
import BDD from '@js/libs/BDD'
import Unit from '@js/types/unit'

import _ from 'lodash'

interface ProductFormData extends Product {
	year: number
}

class AddEditProductModal extends Modal {

	private formProduct: Form | null = null
	private attachments: { [key: string]: CouchDBAttachment } | null = null
	private filesManager: FilesManager = new FilesManager()
	private afg!: AnalyticsFormGenerator

	constructor(private idProduct?: string) {

		super({
			tpl: T_addProduct({
				unitList
			}),
			backdrop: 'static',
			keyboard: false
		})

		this.on('opened', async () => {

			/* --------------- Chargement des sites et création du select2 -------------- */

			try {
				//TODO: Remplacer par un service
				const sites = await SitesService.getInstance().getAllSites()
				const select2Data = sites.map(site => ({ id: site._id, text: site.name }))

				//Création du select2
				$('#sites-select').select2({ data: select2Data, dropdownParent: $(this.element) })
				//Selection des tous les sites par défaut
				$('#sites-select').val(select2Data.map(s => s.id)).trigger('change')

			} catch (e) {
				toastr.error('Impossible de récupérer les sites')
				console.error(e)
			}

			/* ------------ Chargement des analytiques et création du select2 ----------- */

			try {

				const analytics = _.sortBy(AnalyticsService.getInstance().getAll(), 'name')
				const select2Data = Array.from(analytics).map((analytic: Analytic) => ({ id: analytic._id, text: analytic.name }))

				//Création du select2
				$('#analytic-select').select2({ data: select2Data, dropdownParent: $(this.element) })
			}
			catch (e)
			{
				toastr.error('Impossible de récupérer les sites')
				console.error(e)
			}

			/* ---------- Chargement des sous-analytique et création du select2 --------- */

			try {

				const subAnalytics = _.sortBy(SubAnalyticsService.getInstance().getAllAvailable(), 'name');
				const select2Data = Array.from(subAnalytics).map((subAnalytic: SubAnalytic) => ({ id: subAnalytic._id, text: subAnalytic.name }));

				//Création du select2
				$('#subAnalytic-select').select2({ data: select2Data, dropdownParent: $(this.element) });

			}
			catch (e)
			{
				toastr.error('Impossible de récupérer les sites');
				console.error(e);
			}


			/* --------- Chargement des comptes comptable et création du select2 -------- */

			try {

				const accounts = _.sortBy(AccountingAccountsService.getInstance().getAll(), 'name');
				const select2Data = Array.from(accounts).map((account: any) => ({ id: account._id, text: account.name }))

				//Création du select2
				$('#accoutingAccount-select').select2({ data: select2Data, dropdownParent: $(this.element) })


			}
			catch (e)
			{
				toastr.error('Impossible de récupérer les sites');
				console.error(e);
			}


			// ----------------------------------------------------------------------


			//On créée une instance de Form
			const N_form = this.element.querySelector('#form-product') as HTMLFormElement
			this.formProduct = new Form(N_form)

			//On inclut ou non les champs caractéristiques d'intrants selon le type (consommable, matières, etc...)
			const N_divRawMaterial = this.element.querySelector("#rawMaterialProperties") as HTMLDivElement

			//Quand le compte comptable change
			const N_accountingAccount = this.formProduct.getElementByName('accountingAccount') as HTMLElement
			$(N_accountingAccount).on('select2:select', async () => {

				if (this.formProduct) {

					//On le récupère et met a jour le formulaire en fonction de si il est considéré comme matière première ou non
					const accountingAccount = this.formProduct.getDataByName("accountingAccount") as string | null;

					if (accountingAccount) {

						const account = AccountingAccountsService.getInstance().getById(accountingAccount)

						if (account?.isRawMaterial) {
							N_divRawMaterial.innerHTML = T_rawMaterialPropertiesForm({ rawMaterialProperties, unitList });

							this.formProduct.setDataByName('unit', 'tonne')
							this.formProduct.disableByName('unit')
						}
						else
						{
							N_divRawMaterial.innerHTML = '';
							this.formProduct.enableByName('unit')
						}
					}
					else
					{
						N_divRawMaterial.innerHTML = '';
						this.formProduct.enableByName('unit')
					}

					this.formProduct.updateInputs()
				}
			});


			/* ---------------- Chargement des TVA et création du select ---------------- */

			const N_selectTVACode = this.element.querySelector('[name="vat"]') as HTMLSelectElement;

			const vats = VATService.getInstance().getAll();
			for (let d of vats) {
				const N_option = document.createElement('option') as HTMLOptionElement
				N_option.value = d._id;
				N_option.innerHTML = `${d.label} (${d.percent}%)`;
				N_option.selected = d.default;
				N_selectTVACode.append(N_option);
			}

			// ----------------------------------------------------------------------


			const N_analytic = this.element.querySelector('.analytics') as HTMLElement
			this.afg = new AnalyticsFormGenerator(N_analytic)

			this.afg.on('render', () => this.formProduct?.updateInputs())

			const N_attachments = this.element.querySelector('#attachments') as HTMLDivElement
			N_attachments.appendChild(this.filesManager.getNode())

			//Récupération du bouton sauvegarder
			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', () => this.save())

			this.afg.once('render', async () => {

				const product = await this.loadData() //FIXME: typer

				// Les unitlists ne renvoient pas tous les unites dans produits (lors de la generation du formulaire)
				// Dans ce cas on le rajoute a la main dans les options pour ne pas avoir de bugs
				const availableUnits = Object.keys(unitList)

				if (product.unit && !availableUnits.includes(product.unit)) {
					const missingUnit = unitList[product.unit] as Unit
					const N_select = this.element.querySelector('[name="unit"]') as HTMLSelectElement
					N_select.appendChild(new Option(missingUnit.unit, missingUnit._id))
				}

				//On commence par remplir le compte comptable car il peut générer une partie de formulaire
				this.formProduct?.setDataByName('accountingAccount', product.accountingAccount);
				//On déclanche le changement de compte comptable au chargement pour passer dans l'évenement si dessus.
				// const N_accoutingAccount = this.formProduct!.getElementByName('accountingAccount')
				$(N_accountingAccount).trigger('select2:select')

				//Replissage du formulaire
				this.fillForm(product)
			})

		})

	}

	private async save() {
		if (this.formProduct && this.formProduct.checkValidity()) {

			//insertion produit dans bdd
			const data = this.getData() as ProductFormData

			// Obligatoire pour ne pas avoir un tableau corrompu
			if (data.subAnalytics) {
				data.subAnalytics = data.subAnalytics.filter(subanalytic => !_.isEmpty(subanalytic.id))
			}

			data.reference = _.upperFirst(data.reference)

			if (data.year) {
				data.reference += " " + data.year
			}

			// delete data.year;
			Reflect.deleteProperty(data, 'year')

			//TODO: Utiliser un service
			try {
				await this.insertData(data as CouchDBData)
				await ProductsService.getInstance().syncLogoAttachment(this.idProduct!, this.filesManager.getArrayFiles())
				toastr.success('Ajout à la base données réussi', 'Succès', { timeOut: 5000 })
				this.resolve()
			}
			catch
			{
				toastr.error('Erreur d\'insertion dans la base de données', 'Attention')
				this.reject()
			}
		}
	}

	private async loadData() {

		try {
			if (this.idProduct) {
				let product:any = await ProductsService.getInstance().getByID(this.idProduct)

				product.price = product.price * unitList[product.unit].coeficient;

				//Découpe du milèsime
				let match = product.reference.match(/(.*) (2[0-9]{3})$/)
				if (match) {
					product.reference = match[1]
					product.year = match[2]
				}

				return product;
			}
		} catch (e) {
			toaster.error(`Erreur de chargement du produit`);
		}
	}

	private fillForm(product: Product) {

		this.formProduct?.setData(product as any);
		this.afg.setData({
			analytics: product.analytics || [],
			subAnalytics: product.subAnalytics || []
		})

		product._attachments && this.filesManager.setFiles(product._attachments)
	}

	private getData() {

		if (this.formProduct) {

			this.formProduct.updateInputs()
			let data = this.formProduct.getData() as Product
			data.price = data.price / unitList[data.unit].coeficient
			data.deleted = false

			if (this.attachments) {
				data._attachments = this.attachments
			}

			return data
		}
	}

	private async insertData(data: CouchDBData): Promise<void> {
		const product: Product = data as Product

		if (this.idProduct) {
			product._id = this.idProduct
			await ProductsService.getInstance().update(product)
		}
		else
		{
			product._id = BDD.generateID()
			this.idProduct = product._id
			await ProductsService.getInstance().create(product)
		}
	}


}
export default AddEditProductModal;

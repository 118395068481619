import { Controller, global, Router } from "@autoprog/core-client"

import '@css/settings.scss'
import BDD from '@js/libs/BDD';
import h from 'hyperscript'

import toastr from 'toastr'
import SitesService from "@js/libs/services/SitesService";
import StocksService from "@js/libs/services/StocksService";
import StockEvent from "@js/types/stock-event";
import StocksManager from "@js/libs/managers/StocksManager";


class SettingsCtrl extends Controller {

	private el: HTMLElement;
	private selectedBox?: string;
	private selectedProduct?: string;


	constructor(el: HTMLElement, params: object) {
		super(el, params)

		this.el = el.querySelector('.full-screen') as HTMLElement

		const N_input = this.el.querySelector('.weight-input') as HTMLElement
		const N_saveButton = this.el.querySelector('#save-button') as HTMLButtonElement

		const N_home = this.el.querySelector('#home-button') as HTMLButtonElement
		N_home.addEventListener('click', () => Router.getInstance().navigate(''))

		SitesService.getInstance().getByID(global.SERVER_SITE).then(site => {
			const N_storagesContainer = this.el.querySelector('.storages-container') as HTMLElement;

			site.storages.forEach((storage: any) => {

				if (storage.type == 'box') {
					let btn = h('button.bg-blue-grey.border-blue-grey.py-4.flex-fill.col-lg-3.col-md-4',
						h('span.h1', storage.name)
					)

					btn.addEventListener('click', async () => {
						this.selectedBox = storage.id;
						await this.updateRawMaterial()
						$('#tablet-carousel').carousel(1)
					})

					N_storagesContainer.appendChild(btn);
				}
			})
		})

		let weight = '';

		const N_dataChar = this.el.querySelectorAll('[data-char]') as NodeListOf<HTMLElement>
		N_dataChar.forEach((N_btn) => {

			N_btn.addEventListener('click', () => {


				if (N_btn.dataset.char == 'backspace') {
					weight = weight.slice(0, -1);
				}
				else if (N_btn.dataset.char == 'reset') {
					weight = '';
				}
				else if (weight.length < 5) {
					weight += N_btn.dataset.char;
				}

				N_input.innerHTML = weight + ' Kg';
				N_saveButton.disabled = parseInt(weight) <= 0

			})
		})

		N_saveButton.addEventListener('click', async () => {

			const stockEvent: StockEvent = {
				_id: 'tablet_' + BDD.generateID(),
				date: Date.now(),
				site: global.SERVER_SITE,
				type: 'alimentation',
				storage: this.selectedBox || '',
				product: this.selectedProduct || '',
				quantity: parseInt(weight),
				unlocked: true,
				wastage: 0,
				isTransfert: false
			}

			try {
				await StocksService.getInstance().create(stockEvent)
				toastr.success('Alimentation ajouté');

				this.selectedBox = undefined;
				this.selectedProduct = undefined;
				weight = '';
				$('#tablet-carousel').carousel(0)
			}
			catch (e) {
				toastr.error('Erreur de base de données');
				console.error(e);
			}



		});

	}

	private async updateRawMaterial(): Promise<void> {

		const products = await StocksManager.getProductPerStorage(global.SERVER_SITE, this.selectedBox!)
		const N_productsContainer = this.el.querySelector('.rawMaterial-container') as HTMLElement;
		N_productsContainer.innerHTML = '';


		products.forEach(product => {
			const btn = h('button.bg-blue-grey.border-blue-grey.py-4.flex-fill.col-lg-3.col-md-4',
				h('span.h1', product.reference)
			)

			btn.addEventListener('click', () => {

				this.selectedProduct = product._id;
				$('#tablet-carousel').carousel(2);

			})

			N_productsContainer.appendChild(btn);
		})
	}

	public destructor() {

	}
}

export default SettingsCtrl;
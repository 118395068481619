import { global } from "@autoprog/core-client"

import { Grid, GridOptions } from 'ag-grid-community'
import 'ag-grid-enterprise'
import AgGridStateSaver from '@libs/agGrid/StateSaver2'
import { Tier } from '@js/types/Tiers'
import toastr from 'toastr'

import AddEditTiersModal from '@libs/modals/tiers/AddEditTiersModal'
import DetailTiersModal from '@libs/modals/tiers/DetailTiersModal'
import { french as agGridFrench } from '@libs/utils/agGrid'

import Export from '@libs/agGrid/Export'
import TextFilterAccent from '@libs/agGrid/TextFilterAccent'
import GetQuickFilterAccentText from '@libs/agGrid/GetQuickFilterAccentText'
import SitesService from '@js/libs/services/SitesService'

import M_file from '@libs/modals/orders/Attachments'

import _ from "lodash"
import TiersService from '@js/libs/services/TiersService'
import Site from '@js/types/site';


interface TabTierRow extends Tier {
	sitesData: Array<Site>
} 

class TabTiers {

	private gridOptions: GridOptions = <GridOptions>{};
	private stateAGrid: AgGridStateSaver | undefined;
	constructor(_el: HTMLElement) {

		let N_gridTiers = document.querySelector('#grid-tiers');

		//options de la grille
		this.gridOptions = agGridFrench({
			columnDefs: [
				{
					headerName: "Nom",
					field: "name",
					sort: 'asc'
				}, {
					headerName: "Adresse",
					field: "address",
					valueGetter: (params) => {
						return [params.data.address, params.data.zipCode, params.data.city].join(' ');
					}
				}, {
					headerName: "Adresse Facturation",
					field: "bill",
					valueGetter: (params) => {
						params.data.bill = params.data.bill || { address: '', zipCode: '', city: '' };
						return [params.data.bill.address, params.data.bill.zipCode, params.data.bill.city].join(' ');
					}
				}, {
					headerName: "Téléphone",
					field: "phone",
				}, {
					headerName: "Affecté aux sites",
					field: "sitesData",
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: {
						suppressMiniFilter: true,
						values: (params: any) => {
							let values: string[] = [''];

							if (this.gridOptions.api) {

								this.gridOptions.api.forEachNode((node) => {
									values = values.concat(_.map(node.data.sitesData, 'name'))
								})

								values = _.uniq(values)

							}

							params.success(values)
						}
					},
					valueGetter: (params) => {
						return _.map(params.data.sitesData, 'name');
					}
				}, {
					headerName: "Contacts",
					field: "contacts",
					cellRenderer: (params) => {
						let result = [];
						for (let key of params.value || []) {
							result.push(key.name)
						}
						return result.join(', ');
					}
				}, {
					headerName: "Communes de dé/chargements",
					field: "places",
					cellRenderer: (params) => {
						let result = [];
						for (let key of params.value || []) {
							result.push(key.name)
						}
						return result.join(', ');
					}
				}, {
					headerName: "N° Siret",
					field: "siret",
				}, {
					headerName: "N° TVA",
					field: "tvaNumber",
				}, {
					headerName: "Domiciliation bancaire",
					field: "bankingDomiciliation",
				}, {
					headerName: "IBAN",
					field: "IBAN",
				}, {
					headerName: "Numéro de compte",
					field: "accountNumber",
				},

				{
					headerName: "Type de réglement",
					field: "regulationType",
					valueGetter: (params) => {

						//FIXME : En plusieur exemplaire dans le code
						let values: { [key: string]: string } = {
							transfer: 'Virement',
							bankCheck: 'Chèque',
							bankDraft: 'Traite bancaire'
						}

						return values[params.data.regulationType] || '';
					}
				},

				{
					headerName: "Délai de paiement",
					field: "paymentPeriod",
					valueGetter: (params) => {
						let values: { [key: string]: string } = {
							'30days': '30 jours',
							monthEnd: 'Fin du mois',
							cash: 'Comptant'
						}

						return values[params.data.paymentPeriod] || '';

					}
				},

				{
					headerName: '',
					filter: false,
					pinned: 'right',
					width: 80,
					cellRenderer: (params) => {

						let div = document.createElement('div');

						let infoButton = document.createElement('button');

						infoButton.classList.add('btn', 'btn-xs', 'btn-info');
						infoButton.setAttribute('permission', 'PRODUCTS_THIRDPARTIES.THIRDPARTIES.EDIT');

						infoButton.innerHTML = '<i class="icon icon-solid-eye"> </i>';

						infoButton.addEventListener('click', () => {
							let modal = new DetailTiersModal(params.data._id);
							modal.open().then(() => this.updateGrid())
						});

						let deleteButton = document.createElement('button');

						deleteButton.classList.add('btn', 'btn-xs', 'btn-danger');
						deleteButton.setAttribute('confirmation', "");
						deleteButton.setAttribute('permission', 'PRODUCTS_THIRDPARTIES.THIRDPARTIES.DELETE');

						deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"> </i>';

						deleteButton.addEventListener2('click', async () => {
							await this.deleteTiers(params.data)
							this.updateGrid()
						});

						div.appendChild(infoButton);

						if (params.data && !params.data.locked && (params.data.deletable !== false)) {
							div.appendChild(deleteButton);
						}


						if (params.data._attachments) {

							let N_attachments = document.createElement('button');

							N_attachments.classList.add('btn', 'btn-xs', 'btn-grey');
							N_attachments.innerHTML = `<i class="icon icon-solid-paperclip"></i>`;

							N_attachments.addEventListener2('click', () => {

								let values = Object.keys(params.data._attachments)

								if (values.length == 1) {
									window.open(`${global.COUCHDB_URL}/tiers/${params.data._id}/${values[0]}`, 'target')
								} else {
									new M_file('tiers', params.data._id, values).open()
								}

							})

							div.appendChild(N_attachments);

						}

						return div;

					}
				}
			],

			
			enableRangeSelection: true,
			suppressAggFuncInHeader: true,
			suppressDragLeaveHidesColumns: true,

			defaultColDef: {
				floatingFilter: true,
				sortable: true,
				resizable: true,
				suppressMenu: true,
				floatingFilterComponentParams: { suppressFilterButton: true },
				filter: 'agTextColumnFilter',

				filterParams: {
					newRowsAction: 'keep',
					filterOptions: ['contains'],
					textCustomComparator: TextFilterAccent
				},
				getQuickFilterText: GetQuickFilterAccentText
			},

			getContextMenuItems: (params) => {

				if (params.columnApi) {

					let allColumns = params.columnApi.getAllColumns();

					let columnKeys = _.map(allColumns, 'colId');

					columnKeys.pop();

					let exportAllExcel = {
						name: 'Exporter tous Excel',
						action: () => {
							let exp = new Export(params);
							exp.exportExcel({
								columnKeys
							});
						}
					};

					let exportAllCSV = {
						name: 'Exporter tous CSV',
						action: () => {
							let exp = new Export(params);
							exp.exportCSV({
								columnKeys
							});
						}
					};

					return [exportAllExcel, exportAllCSV];

				}

				return [];

			},
			onGridReady: () => {
				this.updateGrid();
			}

		})

		if (N_gridTiers) {
			//creation de l'instance d'Aggrid
			new Grid(N_gridTiers as HTMLElement, this.gridOptions);
			this.stateAGrid = new AgGridStateSaver(this.gridOptions, 'tiers', `Onglet Tiers`);
			this.stateAGrid.load()
		}

		let N_quickFilterTiers = document.querySelector('#quick-filter-tiers') as HTMLSelectElement;

		N_quickFilterTiers.addEventListener('input', () => {

			if (this.gridOptions && this.gridOptions.api) {
				this.gridOptions.api.setQuickFilter(_.deburr(N_quickFilterTiers.value));
			}

		});

		let N_buttonAddTiers = this.getHtmlElement('button-add-tiers');

		if (N_buttonAddTiers) {
			N_buttonAddTiers.addEventListener('click', () => {
				let modalAddTiers = new AddEditTiersModal();

				modalAddTiers.open().then(() => {
					this.updateGrid();
				}).catch(() => {})
			});
		}
	}




	public destructor() {

		if (this.gridOptions.api) {
			this.stateAGrid && this.stateAGrid.save()
			this.gridOptions.api.destroy();
		}

	}

	//methode pour recuperer les données
	private async getRowData(): Promise<Array<TabTierRow>> {

		const rows = await TiersService.getInstance().getAll()
		const siteIds = Array.from(new Set(rows.flatMap(tier => tier.sites))).filter(Boolean)
		const sites = await SitesService.getInstance().getByIds(siteIds)

		return rows.map(tier => ({
			...tier,
			sitesData: sites.filter(site => tier.sites ? tier.sites.includes(site._id) : false)
		}))
	}

	public getHtmlElement(id: string) {
		let N_id = document.querySelector(`#${id}`) as HTMLElement;
		return N_id;
	}

	private async updateGrid() : Promise<void> {
		try {
			const data = await this.getRowData()
			this.gridOptions && this.gridOptions.api && this.gridOptions.api.setRowData(data)
		}
		catch (e) {
			toastr.error('Impossible de récupérer les informations de la base de données')
			console.error(e)
		}
	}

	private async deleteTiers(data: Tier) : Promise<void> {

		try {
			await TiersService.getInstance().delete(data._id)
			toastr.success('Tiers supprimé')
		}
		catch (e) {
			toastr.error(`Erreur lors de la supression du tiers`)
			console.error(e)
		}
	}
}

export default TabTiers;

module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="modal" role="dialog">\r\n    <div class="modal-dialog modal-dialog-centered" role="document">\r\n\r\n        <div class="modal-content">\r\n\r\n            <div class="modal-header sticky-top">\r\n                <h4 class="modal-title" id="title">\r\n                    Pièces Jointe\r\n                </h4>\r\n                <button type="button" class="close" data-reject="modal" aria-label="Close">\r\n                    <span aria-hidden="true">&times;</span>\r\n                </button>\r\n            </div>\r\n\r\n            <div class="modal-body">\r\n\r\n                ';
 for(let item of values){;
__p += '\r\n                    <div class="d-flex <-100">\r\n\r\n                        ' +
((__t = (item)) == null ? '' : __t) +
'\r\n                        <a class="ml-auto" target="_blank" href="' +
((__t = (COUCHDB_URL)) == null ? '' : __t) +
'/' +
((__t = (database)) == null ? '' : __t) +
'/' +
((__t = (id)) == null ? '' : __t) +
'/' +
((__t = (item)) == null ? '' : __t) +
'">\r\n                            <i class="icon icon-file"></i>\r\n                        </a>\r\n\r\n                    </div>\r\n                ';
};
__p += '\r\n\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n';

}
return __p
}
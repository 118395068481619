import { Modal, Form, toaster } from "@autoprog/core-client"

import 'select2'
import _ from 'lodash'
import moment from 'moment'

import T_addEditCheckListModel from '@tpl/modals/exploitation/addEditCheckListModel2.html'

import { SitesService, GroupsService, ChecklistModelsService, EventsService, CalendarService, OperatingDataDefinitionService } from '@js/libs/services'

import TableEditor from "@js/libs/customElements/checklists/TableEditor"
import { CheckListModel } from "@js/types/checklist"
import { TableEditorRowStructure } from "@js/libs/customElements/checklists/TableEditorRow"

type DataTypeOperating = 'checkbox' | 'date' | 'select' | 'number' | 'text' | 'textarea' | 'time'

class AddEditCheckListModel extends Modal {

	public constructor(private id?: string) {
		super({
			tpl: T_addEditCheckListModel,
			backdrop: 'static',
			keyboard: false
		})

		this.on('opened', async () => {

			const operatingDataDefinitions = await OperatingDataDefinitionService.getInstance().getAll()
			const definitions = operatingDataDefinitions.map(definition => ({ id: definition._id, description: definition.description, type: definition.dataType as DataTypeOperating, text: definition.label }))

			const N_table = this.element.querySelector(TableEditor.tagName) as TableEditor
			N_table.definitions = definitions
			N_table.addEventListener('error', (event: any) => this.onTableEditorError(event.detail.code))

			const N_expand = this.element.querySelector('#expand-button') as HTMLButtonElement
			N_expand.addEventListener('click', () => this.expand())

			await this.init()
			this.id && this.load()

			// REMARK: Je garde ce bout de code au cas ou

			// 		} else if (id) {

			// 			// TODO: verifier pourquoi ce code ne fonctionne pas et surtout a quoi il sert
			// 			// toaster.info('Actualisation des calendriers associés', '', { timeOut: 0, extendedTimeOut: 0 })
			// 			// await calendarService.delete(`checklist-${data._id}`)
			// 			// toaster.info('Actualisation des évenements associés', '', { timeOut: 0, extendedTimeOut: 0, preventDuplicates: true })
			// 			// await eventsService.tagDelete(`checklist-${data._id}`)
			// 		}

			// })


			const N_form = this.element.querySelector('form') as HTMLFormElement
			const form = new Form(N_form)

			form.on('repeat.enable.input', () => this.updateRepeatInputs())
			form.on('repeat.from.blur', () => {
				const from = form.getDataByName('repeat.from') as moment.Moment
				const toInput = form.getElementByName('repeat.to') as HTMLInputElement
				toInput.setAttribute('min', from.format('YYYY-MM-DD'))
				const to = form.getDataByName('repeat.to') as moment.Moment | null
				_.isEqual(to && to.unix() < from.unix(), true) && _.set(toInput, 'value', '')
			})

			form.on('repeat.to.blur', () => {
				const from = form.getDataByName('repeat.from') as moment.Moment
				// TODO: mettre un evenement lorsque le from n'a pas initialise
				const to = form.getDataByName('repeat.to') as moment.Moment
				if (to.unix() < from.unix()) {
					const toInput = form.getElementByName('repeat.to') as HTMLInputElement
					toInput.value = ''
				}
			})

			// Bouton Sauvegarder
			const N_save = this.element.querySelector('#add-checklist-button') as HTMLButtonElement
			N_save.addEventListener('click', () => this.save())
		})
	}

	private expand() {
		const N_expand = this.element.querySelector('#expand-button') as HTMLButtonElement
		const N_formPart = this.element.querySelector('.form-part') as HTMLElement
		const N_tableEditor = this.element.querySelector(TableEditor.tagName) as TableEditor

		const currentState = N_expand.dataset.state?.toString()
		N_expand.dataset.state = currentState === 'collapse' ? 'expanded' : 'collapse'

		if (currentState == 'collapse') {
			N_expand.innerHTML = `
						<i class="icon icon-solid-chevron-right"></i>
						Rétrécir
						`
			N_formPart.classList.add('d-none')
		}
		else if (currentState == 'expanded') {
			N_expand.innerHTML = `
						<i class="icon icon-solid-chevron-left"></i>
						Agrandir
						`;
			N_formPart.classList.remove('d-none')
		}

		N_tableEditor.classList.toggle('expanded')
	}

	private onTableEditorError(code: number) {
		let message = ""
		switch (code) {
			case 400:
				message = "Doublon dans les saisies des données d'exploitation."
				break;

			default:
				message = "Vous avez un erreur quelque-part."
				break;
		}

		toaster.error(message)
	}

	private async save() {

		if (!this.checkValidity()) {
			return
		}

		const N_form = this.element.querySelector('form') as HTMLFormElement
		const N_tableEditor = this.element.querySelector(TableEditor.tagName) as TableEditor
		const form = new Form(N_form)

		const data = form.getData() as Omit<CheckListModel, 'structures'>

		if (data.repeat && moment.isMoment(data.repeat.from)) {
			data.repeat.from = parseInt(data.repeat.from.format('x'))
		}
		if (data.repeat && moment.isMoment(data.repeat.to)) {
			data.repeat.to = parseInt(data.repeat.to.format('x'))
		}

		const addOrUpdate = this.id ? ChecklistModelsService.getInstance().update : ChecklistModelsService.getInstance().create
		toaster.info('Sauvegarde du modèle', '', { timeOut: 0, extendedTimeOut: 0 })
		await addOrUpdate({ ...data, structure: N_tableEditor.values })
		toaster.clear()

		if (data?.repeat?.enable) {
			toaster.info('Actualisation des calendriers associés', '', { timeOut: 0, extendedTimeOut: 0 })
			try {
				const calendars = await CalendarService.getInstance().createForChecklist(data)
				toaster.clear();
				toaster.success('Calendriers actualisés')

				toaster.info('Actualisation des évenements associés', '', { timeOut: 0, extendedTimeOut: 0, preventDuplicates: true })
				toaster.clear();
				await EventsService.getInstance().createEventsForChecklistCalendars(data, calendars)
			}
			catch (e) {
				toaster.error(`Erreur d'actualisation des calendriers`)
			}
		}

		this.resolve()
	}

	private async load() {
		const model = await ChecklistModelsService.getInstance().getByID(this.id!)
		const N_form = this.element.querySelector('form') as HTMLFormElement
		const N_tableEditor = this.element.querySelector(TableEditor.tagName) as TableEditor
		const form = new Form(N_form)

		form.setData(_.omit(model, 'structures') as any)
		N_tableEditor.values = model.structure as TableEditorRowStructure[]
		this.updateRepeatInputs()
	}

	private checkValidity(): boolean {
		const N_tableEditor = this.element.querySelector(TableEditor.tagName) as TableEditor
		const N_form = this.element.querySelector('form') as HTMLFormElement
		const form = new Form(N_form)

		return form.checkValidity() && !N_tableEditor.isEmpty() && N_tableEditor.checkValidity()
	}

	private async init() {

		const N_dropdownParent = this.element.querySelector('.modal-content') as HTMLElement

		/* --------------- Chargement des sites et création du select2 -------------- */

		try {
			const sites = await SitesService.getInstance().getAllSites()
			const select2Data = sites.map(site => ({ id: site._id, text: site.name }));
			//Création du select2
			$('#sites-select').select2({ data: select2Data, dropdownParent: $(N_dropdownParent) });
			//Selection des tous les sites par défaut
			$('#sites-select').val(select2Data.map(s => s.id)).trigger('change')

		} catch (e) {
			toaster.error('Impossible de récupérer les sites');
			console.error(e);
		}

		const groups = await GroupsService.getInstance().getAll()
		const select2Groups = groups.map(group => { return { id: group._id, text: group.name } });
		$('#groups-select').select2({ data: select2Groups, dropdownParent: $(N_dropdownParent) });

	}

	private updateRepeatInputs() {
		const N_form = this.element.querySelector('form') as HTMLFormElement
		const form = new Form(N_form)

		if (form.getDataByName('repeat.enable')) {
			form.enableByName('repeat.from')
			form.setRequiredByName('repeat.from', true)
			form.enableByName('repeat.to')
			form.setRequiredByName('repeat.to', true)
			form.enableByName('repeat.interval.value')
			form.setRequiredByName('repeat.interval.value', true)
			form.enableByName('repeat.interval.type')
			form.setRequiredByName('repeat.interval.type', true)
		}
		else {
			form.disableByName('repeat.from')
			form.setRequiredByName('repeat.from', false)
			form.setDataByName('repeat.from', null)
			form.disableByName('repeat.to')
			form.setRequiredByName('repeat.to', false)
			form.setDataByName('repeat.to', null)
			form.disableByName('repeat.interval.value')
			form.setRequiredByName('repeat.interval.value', false)
			form.setDataByName('repeat.interval.value', null)
			form.disableByName('repeat.interval.type')
			form.setRequiredByName('repeat.interval.type', false)
			form.setDataByName('repeat.interval.type', null)
		}
	}
}

export default AddEditCheckListModel

import _ from "lodash"
import apCheckboxSelectCss from '../../../css/apCheckboxSelect.scss?string'

type ApCheckboxSelectValue = {
	id: string,
	text: string
}

class ApCheckboxSelect extends HTMLElement {
	public static readonly tagName = 'ap-checkbox-select'

	private _root: ShadowRoot

	public constructor() {
		super()

		this._root = this.attachShadow({ mode: "closed" })
		this._root.innerHTML = `
			<style>${apCheckboxSelectCss}</style>
			<div class="search-container">
				<div class="checkbox-container">
					<input class="select-all" type="checkbox">
				</div>
				<input class="search" type="search" placeholder="Recherche">
			</div>
			<div class="options">
			</div>
		`

		this.init()
	}

	get values(): Array<ApCheckboxSelectValue> {
		return this.rows().map(node => ({ id: node.value, text: node.text }))
	}

	set values(items: Array<ApCheckboxSelectValue>) {
		this.isConnected && this.draw(items)
	}

	get selected() : Array<string> {
		return this.rows().filter(node => node.checked).map(node => node.value)
	}

	set selected(toSelect: Array<string>) {
		this.unselectAll()
		this.rows().filter(node => toSelect.includes(node.value)).forEach(node => _.set(node, 'checked', true))
	}

	public static register() {
		customElements.define(ApCheckboxSelect.tagName, ApCheckboxSelect)
		ApCheckboxSelectItem.register()
	}

	private init() {
		let timeout: number | null = null
		const debounce = (callback: () => void) => {
			timeout && clearTimeout(timeout)
			timeout = window.setTimeout(() => {
				callback()
			}, 10)
		}

		const N_all = this._root.querySelector('input.select-all') as HTMLInputElement
		N_all.addEventListener('input', () => {
			const toCalled = !!this.selected.length && !N_all.checked ? this.unselectAll.bind(this) : this.selectAll.bind(this)
			toCalled()
		})

		const N_search = this._root.querySelector('input[type="search"]') as HTMLInputElement
		N_search.addEventListener('input', () => debounce(this.filter.bind(this)))
	}

	private async draw(items: Array<ApCheckboxSelectValue>) {
		const N_all = this._root.querySelector('input.select-all') as HTMLInputElement
		const N_list = this._root.querySelector('.options') as HTMLDivElement
		N_list.innerHTML = ''

		const fragment = document.createDocumentFragment()

		const N_items = await Promise.all(items.map(item => {
			const N_item = ApCheckboxSelectItem.createElement()
			N_item.value = item.id,
			N_item.text = item.text
			N_item.addEventListener('change', () => _.set(N_all, 'indeterminate', !!this.selected.length && this.selected.length < this.rows().length))
			return Promise.resolve(N_item)
		}))
		N_items.forEach(item => fragment.appendChild(item))

		N_list.appendChild(fragment)
		this.dispatchEvent(new Event('load'))
	}

	private normalizeSearchStr(str: string) {
		return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\W/g, '')
	}

	private rows() : Array<ApCheckboxSelectItem> {
		return Array.from(this._root.querySelectorAll(`div[data-is="${ApCheckboxSelectItem.tagName}"]`))
	}

	public unselectAll() {
		this.rows().forEach(node => _.set(node, 'checked', false))
	}

	public selectAll() {
		this.rows().forEach(node => _.set(node, 'checked', true))
	}

	private filter() {
		const N_search = this._root.querySelector('input[type="search"]') as HTMLInputElement
		const term = this.normalizeSearchStr(N_search.value)
		this.rows().forEach(node =>  _.set(node, 'visible', _.isEmpty(term) || !_.isEmpty(term) && this.normalizeSearchStr(node.text).includes(term)))
	}
}

class ApCheckboxSelectItem extends HTMLDivElement {
	public static readonly tagName = 'ap-checkbox-select-item'

	constructor() {
		super()

		this.classList.add('item')
		this.dataset.is = ApCheckboxSelectItem.tagName
		this.innerHTML = `
			<div class="checkbox-container">
				<input type="checkbox">
			</div>
			<label></label>
		`

		const N_checkbox = this.querySelector('input[type="checkbox"]') as HTMLInputElement
		N_checkbox.addEventListener('change', event => {
			event.stopPropagation()
			event.stopImmediatePropagation()
			this.dispatchEvent(new Event('change'))
		})
	}

	get value() : string {
		const N_checkbox = this.querySelector('input[type="checkbox"]') as HTMLInputElement
		return N_checkbox.value
	}

	set value(newValue: string) {
		const N_checkbox = this.querySelector('input[type="checkbox"]') as HTMLInputElement
		const N_label = this.querySelector('label') as HTMLLabelElement
		N_checkbox.value = newValue
		N_label.htmlFor = newValue
	}

	get text() : string {
		const N_label = this.querySelector('label') as HTMLLabelElement
		return N_label.textContent || ""
	}

	set text(newLabel: string) {
		const N_label = this.querySelector('label') as HTMLLabelElement
		N_label.textContent = newLabel
	}

	get visible(): boolean {
		return !this.classList.contains('hidden')
	}

	set visible(isVisible: boolean) {
		this.classList.toggle('hidden', !isVisible)
	}

	get checked(): boolean {
		const N_checkbox = this.querySelector('input[type="checkbox"]') as HTMLInputElement
		return N_checkbox.checked
	}

	set checked(shouldCheck: boolean) {
		const N_checkbox = this.querySelector('input[type="checkbox"]') as HTMLInputElement
		N_checkbox.checked = shouldCheck
	}

	public connectedCallback() {
		if (this.getRoot()?.tagName !== ApCheckboxSelect.tagName.toUpperCase()) {
			throw new Error(`Should be attached and created only to ${ApCheckboxSelect.tagName}`)
		}
	}

	private getRoot(): ApCheckboxSelect | undefined {
		return (this.getRootNode() as { host?: ApCheckboxSelect }).host as ApCheckboxSelect | undefined
	}

	public static createElement() : ApCheckboxSelectItem {
		return document.createElement('div', { is: ApCheckboxSelectItem.tagName }) as ApCheckboxSelectItem
	}

	public static register() {
		if (_.isNil(customElements.get(ApCheckboxSelect.tagName))) {
			throw new Error(`${ApCheckboxSelect.tagName} is not declared.`)
		}
		customElements.define(ApCheckboxSelectItem.tagName, ApCheckboxSelectItem, { extends: 'div' })
	}

	
}

export default ApCheckboxSelect

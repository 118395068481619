import { Modal } from "@autoprog/core-client"

import T_Prompt from '@tpl/modals/prompt.html'

class Prompt extends Modal {

	constructor(title?: string) {

		super({
			tpl: T_Prompt,
			backdrop: 'static',
			keyboard: false
		});

		this.on('opened', () => {

			let N_title = this.element.querySelector('.modal-title');

			if (title && N_title) {
				N_title.innerHTML = title;
			}

			let N_save = this.element.querySelector('#save-button');
			let N_input = this.element.querySelector('#value') as HTMLInputElement;

			if (N_save && N_input) {

				N_save.addEventListener('click', () => {

					N_input && this.resolve(N_input.value);

				});

			}

		})

	}

}

export default Prompt;
import _ from 'lodash'
import axios from 'axios'

export type AccountingAccount = {
	_id: string,
	_rev?: string,
	name: string,
	isRawMaterial: boolean,
	acceptZero: boolean,
	locked: boolean
}

class AccountingAccountsService {

	private static instance = new AccountingAccountsService()

	private accounts: AccountingAccount[]

	private constructor() {
		this.accounts = []
	}

	public static getInstance() : AccountingAccountsService {
		return AccountingAccountsService.instance
	}

	/**
	 * @description Charge tous les comptes comptable et les met en mémoire
	 */
	public async load() : Promise<void> {

		let { data } = await axios.get('/api/accounting-accounts/')

		if (data && !data.error) {
			this.accounts = data.data
		} else {
			this.accounts = []
			throw data.error
		}


	}

	public getAll() : Array<AccountingAccount> {
		return _.cloneDeep(this.accounts);
	}

	public getById(id: string): AccountingAccount | null {
		return _.find(this.accounts, { _id: id }) || null;
	}



	public async update(id: string, aa: AccountingAccount) : Promise<void> {

		let { data } = await axios.put('/api/accounting-accounts/', {
			...aa,
			_id: id
		})

		if (data && !data.error) {
			this.accounts = data.data;
		}
		else {
			this.accounts = [];
			throw data.error;
		}

	}

	public async add(aa: AccountingAccount): Promise<void> {

		let { data } = await axios.post('/api/accounting-accounts/', aa)

		if (data && !data.error) {
			this.accounts = data.data;
		} else {
			this.accounts = [];
			throw data.error;
		}

	}

	public async delete(ID: string) : Promise<void> {

		let { data } = await axios.delete(`/api/accounting-accounts/${ID}`)

		if (data && !data.error) {
			this.accounts = data.data;
		} else {
			this.accounts = [];
			throw data.error;
		}
	}

}

export default AccountingAccountsService;

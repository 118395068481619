import { Modal, Form, toaster } from "@autoprog/core-client"
import T_addEditAccountingAccount from '../../../../tpl/modals/settings/addEditAccountingAccount.html'

import AccountingAccountService, { AccountingAccount } from '../../services/AccountingAccountsService'

class AddEditAccountingAccount extends Modal {

	public constructor(id?: string) {
		super({
			template: T_addEditAccountingAccount,
			backdrop: 'static',
			keyboard: false
		})

		this.on('opened', async () => {

			const N_form = this.element.querySelector('form') as HTMLFormElement

			const form = new Form(N_form)

			const N_isRaw = form.getElementByName('isRawMaterial') as HTMLInputElement
			N_isRaw.addEventListener('change', () => this.displayAcceptZero(N_isRaw.checked))

			const N_save = this.element.querySelector('#save-button') as HTMLButtonElement

			N_save.addEventListener('click', async () => {

				if (form.checkValidity()) {

					const service = AccountingAccountService.getInstance()
					const data = form.getData()

					Reflect.deleteProperty(data, '_rev')

					if (id) {
						try {
							await service.update(id, data as AccountingAccount);
							toaster.success('Compte mis à jour')
							this.resolve()
						}
						catch (e)
						{
							toaster.error(`Mise à jour du compte`, 'Erreur')
							console.error(e)
						}
					}
					else
					{
						try
						{
							await service.add(data as AccountingAccount)
							toaster.success('Compte ajouté')
							this.resolve()
						}
						catch (e)
						{
							toaster.error(`Ajout du compte`, 'Erreur')
							console.error(e)
						}

					}

				}

			})

			if (id) {

				form.disableByName('_id')

				try {
					const data = await AccountingAccountService.getInstance().getById(id);
					if (data)
					{
						this.displayAcceptZero(data.isRawMaterial)
						form.setData(data)
					}
				}
				catch (e)
				{
					toaster.error('Chargement du compte', 'Erreur')
					console.error(e)
				}
			}


		})

	}

	private displayAcceptZero(value: boolean) {
		const N_acceptZero = this.element.querySelector('#zero-input') as HTMLInputElement

		if (value)
		{
			N_acceptZero.parentElement?.classList.remove('d-none')
		}
		else
		{
			N_acceptZero.checked = false
			N_acceptZero.parentElement?.classList.add('d-none')
		}
	}



}

export default AddEditAccountingAccount;
import { Controller, global, LoggedUser } from "@autoprog/core-client"

import TabsExtended from '@libs/TabsExtended'

import C_GroupsTab from '@libs/tabs/settings/GroupsTab'
import C_UsersTab from '@libs/tabs/settings/UsersTab'
import C_SitesTab from '@libs/tabs/settings/SitesTab'
import C_BillTab from '@libs/tabs/settings/BillTab'
import C_AccountingTab from '@libs/tabs/settings/AccountingTab'
import C_AnalyticTab from '@libs/tabs/settings/AnalyticTab'
import C_HumanRessourcesTab from '@libs/tabs/settings/HumanRessourcesTab'
import C_NotificationCenter from '@libs/tabs/settings/NotificationsTab'
import C_Data from '@js/libs/tabs/settings/DataTab'
import C_Units from '@libs/tabs/settings/UnitsTab'
import C_Calendar from '@libs/tabs/settings/CalendarTab'
import C_TablesAgGrid from '@libs/tabs/settings/TablesAgGrid'

import T_GroupsTab from '@tpl/tabs/settings/groups.html'
import T_UsersTab from '@tpl/tabs/settings/users.html'
import T_SitesTab from '@tpl/tabs/settings/sites.html'
import T_billTab from '@tpl/tabs/settings/bill.html'
import T_accountingTab from '@tpl/tabs/settings/accounting.html'
import T_analyticTab from '@tpl/tabs/settings/analytic.html'
import T_humanRessourcesTab from '@tpl/tabs/settings/humanRessources.html'
import T_NotificationCenter from '@tpl/tabs/settings/notifications.html'
import T_Data from '@tpl/tabs/settings/data.html'
import T_Units from '@tpl/tabs/settings/units.html'
import T_Calendar from '@tpl/tabs/settings/calendar.html'
import T_TablesAgGrid from '@tpl/tabs/settings/tablesAgGrid.html'

import '@css/settings.scss'

class SettingsCtrl extends Controller {
	private tabs: TabsExtended
	constructor(private el: HTMLElement, params: object) {
		super(el, params)

		const desktopNavTemplate = '<li permission="${tab.permission}"><a tab-link="${tab.id}"  href="#">${tab.name}</a></li>';
		const mobileNavTemplate = '<button permission="${tab.permission}" tab-link="${tab.id}" class="btn btn-tab btn-grey-300">${tab.name}</button>';

		const desktopNavElement = this.el.querySelector(`#settings-menu`);
		const mobileNavElement = this.el.querySelector(`#settings-mobile-menu`);
		const renderElement = this.el.querySelector(`.settings-content`) as HTMLElement;

		this.tabs = new TabsExtended({
			navElement: (global.IS_MOBILE ? mobileNavElement : desktopNavElement) as HTMLElement,
			renderElement: renderElement,
			navTemplate: global.IS_MOBILE ? mobileNavTemplate : desktopNavTemplate
		})

		if (global.IS_MOBILE && desktopNavElement) {
			desktopNavElement.parentElement && desktopNavElement.parentElement.remove();
			renderElement.className = `settings-content w-100`;
		}

		const loggedUser = LoggedUser.getInstance()

		this.tabs.add({
			name: `Sites`,
			controller: C_SitesTab,
			template: T_SitesTab,
			permission: `SETTINGS.SITES.SHOW`,
			default: loggedUser.evalPermissions(`
			SETTINGS.SITES.SHOW
			`)
		})

		this.tabs.add({
			name: `Groupes`,
			controller: C_GroupsTab,
			template: T_GroupsTab,
			permission: `SETTINGS.GROUPS.SHOW`,
			default: loggedUser.evalPermissions(`
			!SETTINGS.SITES.SHOW && 
			SETTINGS.GROUPS.SHOW
			`)
		})

		this.tabs.add({
			name: `Utilisateurs`,
			controller: C_UsersTab,
			template: T_UsersTab,
			permission: `SETTINGS.USERS.SHOW`,
			default: loggedUser.evalPermissions(`
			!SETTINGS.SITES.SHOW && 
			!SETTINGS.GROUPS.SHOW &&
			SETTINGS.USERS.SHOW 
			`)
		})

		this.tabs.add({
			name: `Ressources humaines`,
			controller: C_HumanRessourcesTab,
			template: T_humanRessourcesTab,
			permission: `SETTINGS.HUMAN_RESSOURCES.SHOW`,
			default: loggedUser.evalPermissions(`
			!SETTINGS.SITES.SHOW && 
			!SETTINGS.GROUPS.SHOW &&
			!SETTINGS.USERS.SHOW &&
			SETTINGS.HUMAN_RESSOURCES.SHOW
			`)
		})

		this.tabs.add({
			name: `Jours fériés`,
			controller: C_Calendar,
			template: T_Calendar,
			permission: `SETTINGS.CALENDAR.SHOW`,
			default: loggedUser.evalPermissions(`
			!SETTINGS.SITES.SHOW && 
			!SETTINGS.GROUPS.SHOW &&
			!SETTINGS.USERS.SHOW &&
			!SETTINGS.HUMAN_RESSOURCES.SHOW && 
			SETTINGS.CALENDAR.SHOW
			`)
		})

		// Ouvert à tous par défaut
		this.tabs.add({
			name: `Centre de notifications`,
			controller: C_NotificationCenter,
			template: T_NotificationCenter,
		})


		this.tabs.add({
			name: `Facturation`,
			controller: C_BillTab,
			template: T_billTab,
			permission: `SETTINGS.BILLING.SHOW`,
			default: loggedUser.evalPermissions(`
			!SETTINGS.SITES.SHOW && 
			!SETTINGS.GROUPS.SHOW &&
			!SETTINGS.USERS.SHOW &&
			!SETTINGS.HUMAN_RESSOURCES.SHOW && 
			!SETTINGS.CALENDAR.SHOW &&
			SETTINGS.BILLING.SHOW 
			`)
		})

		this.tabs.add({
			name: `Comptabilité`,
			controller: C_AccountingTab,
			template: T_accountingTab,
			permission: `SETTINGS.ACCOUNTING.SHOW`,
			default: loggedUser.evalPermissions(`
			!SETTINGS.SITES.SHOW && 
			!SETTINGS.GROUPS.SHOW &&
			!SETTINGS.USERS.SHOW &&
			!SETTINGS.HUMAN_RESSOURCES.SHOW && 
			!SETTINGS.CALENDAR.SHOW &&
			!SETTINGS.BILLING.SHOW && 
			SETTINGS.ACCOUNTING.SHOW
			`)
		})

		this.tabs.add({
			name: `Analytique`,
			controller: C_AnalyticTab,
			template: T_analyticTab,
			permission: `SETTINGS.ANALYTIC.SHOW`,
			default: loggedUser.evalPermissions(`
			!SETTINGS.SITES.SHOW && 
			!SETTINGS.GROUPS.SHOW &&
			!SETTINGS.USERS.SHOW &&
			!SETTINGS.HUMAN_RESSOURCES.SHOW && 
			!SETTINGS.CALENDAR.SHOW &&
			!SETTINGS.BILLING.SHOW && 
			!SETTINGS.ACCOUNTING.SHOW && 
			SETTINGS.ANALYTIC.SHOW
			`)
		})





		this.tabs.add({
			name: `Données`,
			controller: C_Data,
			template: T_Data,
			permission: `SETTINGS.OPERATING_DATA.SHOW`,
			default: loggedUser.evalPermissions(`
			!SETTINGS.SITES.SHOW && 
			!SETTINGS.GROUPS.SHOW &&
			!SETTINGS.USERS.SHOW &&
			!SETTINGS.HUMAN_RESSOURCES.SHOW && 
			!SETTINGS.CALENDAR.SHOW &&
			!SETTINGS.BILLING.SHOW && 
			!SETTINGS.ACCOUNTING.SHOW && 
			!SETTINGS.ANALYTIC.SHOW && 
			SETTINGS.OPERATING_DATA.SHOW 
			`)
		})

		this.tabs.add({
			name: `Unités`,
			controller: C_Units,
			template: T_Units,
			permission: `SETTINGS.UNITS.SHOW`,
			default: loggedUser.evalPermissions(`
			!SETTINGS.SITES.SHOW && 
			!SETTINGS.GROUPS.SHOW &&
			!SETTINGS.USERS.SHOW &&
			!SETTINGS.HUMAN_RESSOURCES.SHOW && 
			!SETTINGS.CALENDAR.SHOW &&
			!SETTINGS.BILLING.SHOW && 
			!SETTINGS.ACCOUNTING.SHOW && 
			!SETTINGS.ANALYTIC.SHOW && 
			SETTINGS.UNITS.SHOW
			`)
		})


		this.tabs.add({
			name: `Mise en forme des tableaux`,
			controller: C_TablesAgGrid,
			template: T_TablesAgGrid,
			default: false,
		})
	}

	public destructor() {
		this.tabs.destructor()

	}

}

export default SettingsCtrl

import { Form } from "@autoprog/core-client"
import EventEmitter from "@autoprog/eventemitter"

import h from 'hyperscript'
import _ from 'lodash'
import AnalyticsService from './services/AnalyticsService'
import SubAnalyticsService from './services/SubAnalyticsService'

type AnalyticsFormData = {
	analytics: AnalyticsRepartitionArray,
	subAnalytics: AnalyticsRepartitionArray
}

class AnalyticsFormGenerator extends EventEmitter {

	private N_container: HTMLElement
	private N_table: HTMLElement
	private N_tbody: HTMLElement
	private N_addRowBtn: HTMLButtonElement

	private form: Form

	private data: AnalyticsFormData

	public constructor(N_container: HTMLElement) {
		super({ wildcard: true });
		this.N_container = N_container;
		this.data = {
			analytics: [{
				id: '',
				percent: 0
			}],
			subAnalytics: [
				{
					id:'',
					percent: 100
				}
			]
		};

		this.form = new Form(this.N_container as HTMLFormElement);

		this.form.on('**.input', _.debounce(() => {
			this.data = this.form.getData() as unknown as AnalyticsFormData;

			// Filtrage des subsanlytics vide
			this.data.subAnalytics = this.data.subAnalytics.filter(subAnalytic => !_.isEmpty(subAnalytic.id))

			this.updatePercent();
			this.updatePermissions();
			this.form.checkValidity();

		}, 50));

		this.N_tbody = h('tbody');

		this.N_addRowBtn = h('button.btn.btn-sm.btn-green.btn-rounded', h('i.icon.icon-solid-plus'));

		this.N_addRowBtn.addEventListener('click', (e) => {
			this.data.analytics.push({
				id: '',
				percent: 0
			})
			this.render()
			e.preventDefault()
		})


		this.N_table = h('table.table.table-sm.text-center.table-borderless',
			h('thead.thead-light',
				h('tr',
					h('th', 'Analytique'),
					h('th.w-25', 'Répartition'),
					h('th.p-0.align-middle', this.N_addRowBtn),
				)
			),
			this.N_tbody
		)

		this.N_container.append(this.N_table);


		let N_select = h('select.custom-select', { name: `subAnalytics[0].id` }, h('option'));

		SubAnalyticsService.getInstance().getAllAvailable().forEach((subAnalytic) => {
			N_select.appendChild(h('option', { value: subAnalytic._id }, subAnalytic.name));
		})


		this.N_container.append(h('div.form-group',

			h('label.form-check-label', 'Sous analytique'),
			N_select,
			h('input.d-none', { name: `subAnalytics[0].percent`, type: 'number', value: 100 }),
		));


		//On differe le rendu d'un tours de cycle pour permettre le bind d'évenement
		setTimeout(() => {
			this.render()
		})
	}


	public render() {

		this.N_tbody.innerHTML = '';

		for (let i = 0; i < this.data.analytics.length; i++) {

			this.createLine(i);
		}

		this.form.updateInputs();
		this.updatePercent();
		this.updatePermissions();
		
		this.form.checkValidity();

		this.emit('render');

	}

	private createLine(index: number) {


		let N_removeBtn = h('button.btn.btn-red.btn-sm.btn-rounded', h('i.icon.icon-solid-minus'), { attrs: { type: 'button', confirmation: '' } }) as HTMLButtonElement;
		//N_removeBtn.disabled = (index != this.data.analytics.length - 1);

		let N_percentInput = h('input.form-control.text-center', { required: 'on', type: 'number', name: `analytics[${index}].percent`, min: 1, max: 100, step: 1 }) as HTMLInputElement;
		N_percentInput.disabled = (index == this.data.analytics.length - 1);

		let N_select = h('select.custom-select.text-center', { required: 'on', name: `analytics[${index}].id` }, h('option'));

		AnalyticsService.getInstance().getAll().forEach((analytic) => {
			N_select.appendChild(h('option', { value: analytic._id }, analytic.name));
		})

		let N_row = h('tr', { attrs: { 'data-index': index } },
			h('td', N_select),

			h('td', h('div.input-group',
				N_percentInput,
				h('div.input-group-append',
					h('span.input-group-text', '%')
				)
			)),

			h('td.align-middle', N_removeBtn),

		)

		N_removeBtn.addEventListener('click', (e) => {
			this.data.analytics.splice(index, 1)
			this.render()
			e.preventDefault()
		})

		this.N_tbody.appendChild(N_row);
	}

	private updatePercent() {
		let totalPercent = 0;

		for (let i = 0; i < this.data.analytics.length; i++) {
			let d = this.data.analytics[i];

			//Si c'est la dernière ligne
			if (i == this.data.analytics.length - 1) {
				d.percent = 100 - totalPercent
			} else {
				if (totalPercent + d.percent > 100) {
					d.percent = 100 - totalPercent;
				}

				totalPercent += d.percent;
			}

		}

		this.form.setData(this.data as any)
	}

	private updatePermissions() {

		this.N_addRowBtn.disabled = !this.isValid() && this.data.analytics.length > 0;

	}

	private isValid(): boolean {

		let definedIds = 0;
		let definedPercent = 0;
		let totalPercent = 0;

		for (let analytic of this.data.analytics) {

			analytic.id && definedIds++;
			analytic.percent && definedPercent++;
			totalPercent += analytic.percent || 0

		}


		return definedIds == this.data.analytics.length &&
			definedPercent == this.data.analytics.length &&
			totalPercent == 100;

	}

	public setData(data: AnalyticsFormData) {
		this.data = data;
		this.render();
	}


}

export default AnalyticsFormGenerator
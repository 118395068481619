import { Modal } from "@autoprog/core-client"

import T_transactionType from '@tpl/modals/weighBridge/transactionType.html'

class SelectTypeTransaction extends Modal {

	constructor() {

		super({
			tpl: T_transactionType,
			backdrop: 'static',
			keyboard: false
		})

		this.on('opened', () => {

			let N_inputBtn = this.element.querySelector('#inputBtn') as HTMLButtonElement;
			let N_outputBtn = this.element.querySelector('#outputBtn') as HTMLButtonElement;

			N_inputBtn.addEventListener2('click', () => {
				this.resolve('input');
			})

			N_outputBtn.addEventListener2('click', () => {
				this.resolve('output');
			});

		})

	}
}
export default SelectTypeTransaction
import _ from 'lodash'
import axios from 'axios'

export type HourCategory = {
	_id: string,
	_rev?: string,
	label: string,
	analytics?: AnalyticsRepartitionArray
	subAnalytics?: AnalyticsRepartitionArray
	color?: string
}

class HoursCategories {

	private static instance = new HoursCategories();

	private hoursCategories: HourCategory[];

	private constructor() {
		this.hoursCategories = [];
	}

	public static getInstance() {
		return HoursCategories.instance;
	}

	public async load() {
		let { data } = await axios.get('/api/hours-categories/')

		if (data && !data.error) {
			this.hoursCategories = data.data;
		} else {
			this.hoursCategories = [];
			throw data.error;
		}
	}

	public getAll() {
		return _.clone(this.hoursCategories);
	}


	public getById(id: string): HourCategory | null {
		return _.find(this.hoursCategories, { _id: id }) || null;
	}

	public getByIds(ids: Array<string>) : Array<HourCategory> {
		return this.hoursCategories.filter(hc => ids.includes(hc._id))
	}

	/*	public getDefault(): HourCategory | null {
			return (_.find(this.hoursCategories, { default: true }) as HourCategory || null) || null
		}*/



	public async update(id: string, hoursCategory: HourCategory) {

		const { data } = await axios.put('/api/hours-categories/', {
			...hoursCategory,
			_id: id
		})

		if (data && !data.error) {
			this.hoursCategories = data.data;
		} else {
			this.hoursCategories = [];
			throw data.error;
		}

	}

	public async add(hoursCategory: HourCategory) {

		let { data } = await axios.post('/api/hours-categories/', hoursCategory)

		if (data && !data.error) {
			this.hoursCategories = data.data;
		} else {
			this.hoursCategories = [];
			throw data.error;
		}

	}

	public async delete(ID: string) {

		let { data } = await axios.delete(`/api/hours-categories/${ID}`)

		if (data && !data.error) {
			this.hoursCategories = data.data;
		} else {
			this.hoursCategories = [];
			throw data.error;
		}
	}

}

export default HoursCategories;

import { Tab, toaster } from "@autoprog/core-client"

import AnalyticsService from '../../services/AnalyticsService'
import SubAnalyticsService from '../../services/SubAnalyticsService'
import { GridOptions, Grid } from 'ag-grid-community';
import h from 'hyperscript'


import AddEditAnalytic from '@js/libs/modals/settings/AddEditAnalytic';
import AddEditSubAnalytic from '@js/libs/modals/settings/AddEditSubAnalytic';

class AnalytiqueTab extends Tab {

	private analyticsGridOptions: GridOptions
	private subAnalyticsGridOptions: GridOptions

	constructor(private el: HTMLElement) {
		super(el)

		this.analyticsGridOptions = {

			columnDefs: [
				{
					headerName: 'Nom',
					field: 'name',
					sort: 'asc',
				},

				{
					headerName: "",
					field: "commands",
					width: 8 + 28 * 2,
					sortable: false,
					filter: false,
					suppressSizeToFit: true,
					pinned: 'right',
					cellRenderer: (params) => {

						let div = h('div.d-flex.justify-content-end') as HTMLElement;

						let editButton = document.createElement('button');
						editButton.classList.add('btn');
						editButton.classList.add('btn-xs');
						editButton.classList.add('btn-info');
						editButton.setAttribute('permission', 'SETTINGS.ANALYTIC.EDIT')
						editButton.innerHTML = '<i class="icon icon-solid-pencil-alt"></i>'

						let deleteButton = document.createElement('button');
						deleteButton.classList.add('btn');
						deleteButton.classList.add('btn-xs');
						deleteButton.classList.add('btn-danger');
						deleteButton.setAttribute('confirmation', '');
						deleteButton.setAttribute('permission', 'SETTINGS.ANALYTIC.DELETE')
						deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"></i>'

						deleteButton.disabled = params.data.locked;
						if (deleteButton.disabled) {
							deleteButton.setAttribute('tooltip', 'Actuellement utilisé')
							deleteButton.classList.add('btn-grey-200');

						} else {

							deleteButton.addEventListener2('click', async () => {
								try {
									await AnalyticsService.getInstance().delete(params.data._id);
									this.loadAnalyticData();
									toaster.success(`Analytique supprimée`);
								} catch (e) {
									toaster.error(`Supression de l'analytique`, 'Erreur');
								}
							});
						}

						editButton.addEventListener2('click', () => {
							(new AddEditAnalytic(params.data._id)).open().then(() => {
								this.loadAnalyticData();
							})
						})

						div.appendChild(editButton);
						div.appendChild(deleteButton);

						return div;
					}
				}
			],

			rowData: [],

			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			},
			defaultColDef: {
				sortable: true
			}
		}


		this.subAnalyticsGridOptions = {

			columnDefs: [
				{
					headerName: 'Nom',
					field: 'name',
					sort: 'asc',
				},
				{
					headerName: "",
					field: "commands",
					width: 8 + 28 * 2,
					sortable: false,
					filter: false,
					suppressSizeToFit: true,
					pinned: 'right',
					cellRenderer: (params) => {

						let div = h('div.d-flex.justify-content-end') as HTMLElement;

						let editButton = document.createElement('button');
						editButton.classList.add('btn');
						editButton.classList.add('btn-xs');
						editButton.classList.add('btn-info');
						editButton.setAttribute('permission', 'SETTINGS.ANALYTIC.EDIT')
						editButton.innerHTML = '<i class="icon icon-solid-pencil-alt"></i>'

						let deleteButton = document.createElement('button');
						deleteButton.classList.add('btn');
						deleteButton.classList.add('btn-xs');
						deleteButton.classList.add('btn-danger');
						deleteButton.setAttribute('confirmation', '');
						deleteButton.setAttribute('permission', 'SETTINGS.ANALYTIC.DELETE')
						deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"></i>'

						deleteButton.disabled = params.data.locked;
						if (deleteButton.disabled) {
							deleteButton.setAttribute('tooltip', 'Actuellement utilisé')
							deleteButton.classList.add('btn-grey-200');

						} else {

							deleteButton.addEventListener2('click', async () => {
								try {
									await SubAnalyticsService.getInstance().delete(params.data._id);
									this.loadSubAnalyticData();
									toaster.success(`Sous-Analytique supprimée`);
								} catch (e) {
									toaster.error(`Supression de sous-analytique`, 'Erreur');
								}
							});
						}

						editButton.addEventListener2('click', () => {
							(new AddEditSubAnalytic(params.data._id)).open().then(() => {
								this.loadSubAnalyticData();
							})
						})

						div.appendChild(editButton);
						div.appendChild(deleteButton);

						return div;
					}
				}
			],

			rowData: [],
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			},
			defaultColDef: {
				sortable: true
			}
		}


		let N_analyticsGrid = this.el.querySelector('#analytics-grid') as HTMLElement;
		let N_subAnalyticsGrid = this.el.querySelector('#subAnalytics-grid') as HTMLElement;

		new Grid(N_analyticsGrid, this.analyticsGridOptions);
		new Grid(N_subAnalyticsGrid, this.subAnalyticsGridOptions);

		this.loadAnalyticData();
		this.loadSubAnalyticData();

		const N_addAnalyticBtn = this.el.querySelector('#add-analytic-btn') as HTMLButtonElement
		N_addAnalyticBtn.addEventListener('click', () => {
			(new AddEditAnalytic()).open().then(() => this.loadAnalyticData())
		})

		const N_exportAnalyticBtn = this.el.querySelector('#export-analytic-btn') as HTMLButtonElement
		N_exportAnalyticBtn.addEventListener('click', () => {
			this.analyticsGridOptions.api && this.analyticsGridOptions.api.exportDataAsExcel({
				fileName: 'Analytique.xlsx'
			})
		})

		const N_addSubAnalyticBtn = this.el.querySelector('#add-subAnalytic-btn') as HTMLButtonElement
		N_addSubAnalyticBtn.addEventListener('click', () => {
			(new AddEditSubAnalytic()).open().then(() => this.loadSubAnalyticData())
		})

		const N_exportSubAnalyticBtn = this.el.querySelector('#export-subAnalytic-btn') as HTMLButtonElement
		N_exportSubAnalyticBtn.addEventListener('click', () => {
			this.subAnalyticsGridOptions.api && this.subAnalyticsGridOptions.api.exportDataAsExcel({
				fileName: 'Sous-Analytique.xlsx'
			})
		})

	}

	private async loadAnalyticData() {

		let a = await AnalyticsService.getInstance().getAll();

		if (this.analyticsGridOptions.api) {
			this.analyticsGridOptions.api.setRowData(a);
		}

	}

	private async loadSubAnalyticData() {

		let a = await SubAnalyticsService.getInstance().getAllAvailable()

		if (this.subAnalyticsGridOptions.api) {
			this.subAnalyticsGridOptions.api.setRowData(a);
		}

	}

	public destructor(): void {

	}


}

export default AnalytiqueTab;
import { global } from "@autoprog/core-client"
import EventEmitter from "@autoprog/eventemitter"

import moment from 'moment';

import TuiDatePicker from '@js/libs/TuiDatePicker';

import T_ScheduleCreationPopup from '@tpl/blocks/calendar/scheduleCreationPopup.ejs'

import _ from 'lodash'
import { ISchedule, TZDate } from "tui-calendar";

class ScheduleCreationPopup extends EventEmitter {

	private rangePicker: TuiDatePicker | null;
	private el: HTMLElement | null = null;
	private options: { [key: string]: any } = {};
	private guide: any;
	private saveData: any = {};

	constructor() {
		super({});

		this.rangePicker = null;
	}

	public open(data: ISchedule, calendarList: any[], options: { [key: string]: any }) {

		data.raw = data.raw || {};

		let N_appContent = document.querySelector('#app-content') as HTMLElement;
		this.el = document.querySelector('#content>.tui-full-calendar-floating-layer') as HTMLElement;

		if (!this.el) {

			this.el = document.createElement('div');
			this.el.classList.add('tui-full-calendar-floating-layer');

			this.el.style.position = 'absolute';

			this.el = N_appContent.appendChild(this.el)

		}

		if (this.el) {

			this.destroy(true);

			if (options.guide.target && options.guide.target.className == 'tui-full-calendar-weekday-schedule-title') {
				let N_layout = document.querySelector('.tui-full-calendar-layout .tui-full-calendar-floating-layer') as HTMLDivElement;
				N_layout && (N_layout.style.display = 'block');
			}

			this.guide = options.guide;
			this.options = options;

			this.saveData = data;

			N_appContent.style.overflow = 'hidden';

			this.el.style.display = 'block';
			this.el.classList.add('d-block');
			this.el.style.zIndex = '1005';

			let N_template = document.createElement('div');
			N_template.classList.add('tui-full-calendar-popup');

			const selectedCal = calendarList.find(c => c._id === data.calendarId)

			let tmp = [];

			for (let calendar of calendarList) {

				for (let site of (calendar.sites || [])) {

					tmp.push({
						...calendar,
						site
					});

				}

			}

			tmp = _.sortBy(tmp, ['site', 'category']);

			let calendars: { [key: string]: any[] } = {}

			for (let calendar of tmp) {

				calendar.site = calendar.site || '';
				calendar.category = calendar.category || '';

				calendars[calendar.site] = calendars[calendar.site] || [];

				calendars[calendar.site][calendar.category] = calendars[calendar.site][calendar.category] || [];

				calendars[calendar.site][calendar.category].push(calendar);

			}

			N_template.innerHTML = T_ScheduleCreationPopup({
				data,
				selectedCal: selectedCal || _.find(tmp, { site: global.SITE }),
				calendars,
				zIndex: this.el.style.zIndex,
				isUpdate: options.isUpdate
			});

			this.el.appendChild(N_template)

			this.presetInput(data);
			this.initDatePicker(data.start || new Date(), data.end || new Date());
			this.dropdown();
			this.checkbox(data.isAllDay || false);
			this.setPopupPositionAndArrowDirection();

			let N_close = this.el.querySelector('.tui-full-calendar-popup-close');
			let N_save = this.el.querySelector('.tui-full-calendar-popup-save');
			let N_moreOptions = this.el.querySelector('.tui-full-calendar-popup-more-options');

			N_close && N_close.addEventListener('click', () => {
				this.destroy(false)
			});

			setTimeout(() => {

				if (this.el) {
					let N_site = this.el.querySelector('#site-selected select') as HTMLSelectElement;

					if (N_site) {
						N_site.value = (selectedCal || calendarList[0]).sites[0];
					}

				}

			});


			N_save && N_save.addEventListener('click', this.save.bind(this));
			N_moreOptions && N_moreOptions.addEventListener('click', this.moreOptions.bind(this));

			let body = document.body;

			if (body) {

				let N_dropback = document.createElement('div');

				N_dropback.id = 'dropback';

				N_dropback.classList.add('bg-dark', 'h-100', 'w-100');
				N_dropback.style.position = 'absolute';
				N_dropback.style.top = '0';
				N_dropback.style.left = '0';
				N_dropback.style.opacity = '0.3';

				N_dropback.addEventListener('click', () => {
					this.destroy();
				});

				body.appendChild(N_dropback);

			}
		}
	}

	private presetInput(data: any) {

		if (this.el) {

			let N_button = this.el.querySelector('.tui-full-calendar-dropdown-button') as HTMLButtonElement;

			if (N_button) {

				let N_dotSelected = N_button.querySelector('.tui-full-calendar-calendar-dot') as HTMLSpanElement;
				let N_nameSelected = N_button.querySelector('.tui-full-calendar-content') as HTMLSpanElement;

				let N_allDay = this.el.querySelector('#tui-full-calendar-schedule-allday') as HTMLInputElement;
				let N_title = this.el.querySelector('#tui-full-calendar-schedule-title') as HTMLInputElement;

				let calendar: any = _.find(data.calendars, { id: data.id }) // FIXME: change any for something else

				if (calendar) {

					N_dotSelected.style.backgroundColor = calendar.bgColor;
					N_nameSelected.innerHTML = calendar.name || '';
					N_button.dataset.calendarId = calendar.id;

				}

				N_allDay.checked = data.checked;
				N_title.value = data.title || '';

			}

		}

	}

	private initDatePicker(start: Date | string | TZDate, end: Date | string | TZDate) {

		if (typeof start == "string") {
			start = new Date(start);
		} else {
			start = new Date(start.getTime());
		}

		if (typeof end == "string") {
			end = new Date(end);
		} else {
			end = new Date(end.getTime());
		}

		this.rangePicker = TuiDatePicker.createRangePicker({
			language: 'french',
			startpicker: {
				date: start,
				input: '#tui-full-calendar-schedule-start-date',
				container: '#tui-full-calendar-startpicker-container'
			},
			endpicker: {
				date: end,
				input: '#tui-full-calendar-schedule-end-date',
				container: '#tui-full-calendar-endpicker-container'
			},
			format: 'yyyy-MM-dd HH:mm',
			timepicker: {
				showMeridiem: false
			},
			usageStatistics: false
		});

	}


	private dropdown(): void {

		setTimeout(() => {

			if (this.el) {

				let N_button = this.el.querySelector('.tui-full-calendar-dropdown-button');
				let N_site = this.el.querySelector('#site-selected select') as HTMLSelectElement;

				if (N_site) {

					N_site.addEventListener('change', () => {

						if (this.el) {
							let N_list = this.el.querySelectorAll(`.tui-full-calendar-dropdown-menu`);

							for (let list of N_list) {
								let el = list as HTMLElement;
								el.style.display = 'none';
							}
						}

					});

				}

				if (N_button) {

					N_button.addEventListener('click', () => {

						if (this.el) {
							let N_list = this.el.querySelector(`.tui-full-calendar-dropdown-menu[data-site="${N_site.value}"]`) as HTMLUListElement;

							if (N_list) {
								if (N_list.style.display == 'none') {
									N_list.style.display = 'block';
								} else {
									N_list.style.display = 'none';
								}
							}
						}

					});

					let N_itemsList = this.el.querySelectorAll('.tui-full-calendar-dropdown-menu-item');

					for (let N_item of N_itemsList) {

						this.eventItemListDropdown(N_item as HTMLElement);

					}

				}

			}

		});

	}

	private checkbox(isAllDay: boolean) {

		if (this.el) {

			let N_container = this.el.querySelector('.tui-full-calendar-section-allday');

			if (N_container) {

				let N_input = N_container.querySelector('#tui-full-calendar-schedule-allday') as HTMLInputElement;

				N_container.addEventListener('click', () => {

					N_input.checked = !N_input.checked;

				});

				N_input.checked = isAllDay;

			}

		}

	}

	private eventItemListDropdown(N_item: HTMLElement) {

		if (this.el) {

			let N_button = this.el.querySelector('.tui-full-calendar-dropdown-button') as HTMLButtonElement;
			let N_site = this.el.querySelector('#site-selected select') as HTMLSelectElement;

			if (N_button && N_site) {

				let N_dotSelected = N_button.querySelector('.tui-full-calendar-calendar-dot') as HTMLSpanElement;
				let N_nameSelected = N_button.querySelector('.tui-full-calendar-content') as HTMLSpanElement;

				let N_dotItem = N_item.querySelector('.tui-full-calendar-calendar-dot') as HTMLSpanElement;
				let N_nameItem = N_item.querySelector('.tui-full-calendar-content') as HTMLSpanElement;

				N_item.addEventListener('click', () => {

					if (this.el) {

						let N_list = this.el.querySelector(`.tui-full-calendar-dropdown-menu[data-site="${N_site.value}"]`) as HTMLUListElement;

						if (N_button) {
							N_dotSelected.style.backgroundColor = N_dotItem.style.backgroundColor;
							N_nameSelected.innerHTML = N_nameItem.innerHTML;

							N_button.dataset.calendarId = N_item.dataset.calendarId;
						}

						N_list && (N_list.style.display = 'none');

					}

				});

			}

		}

	}

	private setPopupPositionAndArrowDirection() {

		if (this.el) {

			let N_popup = this.el.querySelector('.tui-full-calendar-popup');
			let N_container = document.querySelector('.tui-full-calendar-layout');
			let N_select = this.guide.target || this.getFirstGuideElement(this.guide);

			if (N_popup && N_select && N_container) {

				let selectBound = N_select.getBoundingClientRect();
				let containerBound = N_container.getBoundingClientRect();
				let popupBound = N_popup.getBoundingClientRect();

				let bottom = window.innerHeight - selectBound.top

				let N_arrow = this.el.querySelector('#tui-full-calendar-popup-arrow');

				if (bottom + this.el.scrollHeight > containerBound.height) {

					this.el.style.bottom = (bottom - selectBound.height - 8) + 'px';

					if (N_arrow) {
						N_arrow.classList.remove('tui-full-calendar-arrow-bottom');
						N_arrow.classList.add('tui-full-calendar-arrow-top');
					}

				} else {

					this.el.style.bottom = (bottom + this.el.scrollHeight) + 'px';

				}

				let rightSelect = selectBound.left + selectBound.width / 2;
				let centerEl = popupBound.width / 2;

				let left = (rightSelect - centerEl)

				this.el.style.left = left + 'px';

				if (left + this.el.scrollWidth > window.innerWidth) {

					left = window.innerWidth - this.el.scrollWidth

					this.el.style.left = left + 'px';

					if (N_arrow) {

						let N_arrowBorder = N_arrow.querySelector('.tui-full-calendar-popup-arrow-border') as HTMLDivElement;

						let leftArrow = selectBound.left - left + (selectBound.width / 2) - 10;

						N_arrowBorder.style.left = leftArrow + 'px';
					}

				}

			}

		}

	}

	private getFirstGuideElement(guide: any) {

		if (guide.guideElement) {
			return guide.guideElement
		}

		if (guide.guideElements) {

			let result = [];

			for (let i in guide.guideElements) {
				result.push(guide.guideElements[i]);
			}

			return result[0];

		}

	}

	private destroy(isInit: boolean = false) {

		let oldTemplate = document.querySelector('.tui-full-calendar-popup');
		let dropback = document.querySelector('#dropback');

		if (oldTemplate && oldTemplate.parentNode) {
			oldTemplate.parentNode.removeChild(oldTemplate);
		}

		let N_appContent = document.querySelector('#app-content') as HTMLElement;
		N_appContent.style.overflow = '';

		if (this.el) {
			this.el.style.bottom = '';
			this.el.style.left = '';
			this.el.style.top = '';
			this.el.style.right = '';
			this.el.classList.remove('d-block');
		}

		let N_layout = document.querySelector('.tui-full-calendar-layout .tui-full-calendar-floating-layer') as HTMLDivElement;
		N_layout && (N_layout.style.display = 'none');

		if (dropback && dropback.parentNode) {
			dropback.parentNode.removeChild(dropback);
		}

		if (!isInit && this.guide && this.guide.clearGuideElement) {
			this.guide.clearGuideElement();
		}

	}

	private save() {

		if (this.el) {

			let N_dropdown = this.el.querySelector('.tui-full-calendar-dropdown-button') as HTMLButtonElement;
			let N_allDay = this.el.querySelector('#tui-full-calendar-schedule-allday') as HTMLInputElement;
			let N_title = this.el.querySelector('#tui-full-calendar-schedule-title') as HTMLInputElement;

			if (N_title && N_allDay && N_dropdown) {

				if (N_title.value && this.rangePicker) {

					let start = moment(this.rangePicker.getStartDate());
					let end = moment(this.rangePicker.getEndDate());

					if (N_allDay.checked) {
						start = start.startOf('day');
						end = end.endOf('day');
					}

					if (this.options.isUpdate) {

						this.emit('update', {
							calendarId: N_dropdown.dataset.calendarId,
							id: this.saveData.id,
							isAllDay: N_allDay.checked,
							title: N_title.value,
							start: start.format('x'),
							end: end.format('x'),
							raw: this.saveData.raw
						} as ISchedule, this.saveData.calendarId);

					} else {

						this.emit('save', {
							calendarId: N_dropdown.dataset.calendarId,
							isAllDay: N_allDay.checked,
							title: N_title.value,
							start: start.format('x'),
							end: end.format('x')
						} as ISchedule);

					}

					this.destroy();

				} else {

					if (N_title.parentNode) {

						let parent = N_title.parentNode as HTMLElement;
						parent.classList.add('border-red');

					}

				}


			}

		}
	}

	private moreOptions() {

		if (this.el) {

			let N_dropdown = this.el.querySelector('.tui-full-calendar-dropdown-button') as HTMLButtonElement;
			let N_allDay = this.el.querySelector('#tui-full-calendar-schedule-allday') as HTMLInputElement;
			let N_title = this.el.querySelector('#tui-full-calendar-schedule-title') as HTMLInputElement;

			if (N_title && N_allDay && N_dropdown && this.rangePicker) {

				let start = moment(this.rangePicker.getStartDate());
				let end = moment(this.rangePicker.getEndDate());

				if (N_allDay.checked) {
					start = start.startOf('day');
					end = end.endOf('day');
				}

				this.emit('moreOptions', {
					calendarId: N_dropdown.dataset.calendarId,
					id: this.saveData.id,
					isAllDay: N_allDay.checked,
					title: N_title.value,
					start: start.format('x'),
					end: end.format('x'),
					raw: this.saveData.raw
				} as ISchedule, this.saveData.calendarId);

				this.destroy();

			}

		}

	}

}

export default ScheduleCreationPopup;
import Product from "@js/types/products";
import StocksService from "../services/StocksService";
import ProductsService from "../services/ProductsService";

class StocksManager {

	private constructor() {}

	/**
	 * Permet de récupérer les produits qui sont dans l'emplacement indiqué
	 */
	public static async getProductPerStorage(siteId: string, storageId: string) : Promise<Array<Product>> {
		let data = await StocksService.getInstance().getNumberOfProductPerStorage(siteId, storageId)
		let productsIds = data.map(row => row.productId)
		return ProductsService.getInstance().getByIDs(productsIds)
	}
}

export default StocksManager

import { Tab, Alert } from "@autoprog/core-client"
import AddEditCalendarHoliday from '@js/libs/modals/settings/AddEditCalendarHoliday'
import { CalendarHolidayService } from "@js/libs/services"
import { Grid, GridOptions, ICellRendererParams, ValueGetterParams } from "ag-grid-community"
import moment from "moment"

import toastr from 'toastr'

class CalendarTab extends Tab {

	private gridTemporaryHolidaysOptions: GridOptions

	constructor(private el: HTMLElement) {
		super(el)

		this.gridTemporaryHolidaysOptions = {}

		this.shouldInit()
	}

	private async shouldInit() {
		const N_add = this.el.querySelector('#add-holiday-btn') as HTMLButtonElement
		N_add.addEventListener('click', () => {
			const modal = new AddEditCalendarHoliday()
			modal.open().then(() => this.loadData())
		})

		this.createGridTemporaryHoliday()

		this.loadData()
	}

	private async createGridTemporaryHoliday() {
		this.gridTemporaryHolidaysOptions = {
			columnDefs: [
				{
					headerName: "Annee",
					colId: "year",
					width: 70,
					valueGetter: (params: ValueGetterParams) => +moment(params.data.day).format('YYYY'),
					filter: 'agNumberColumnFilter',
					sortable: true,
					sortingOrder: ['desc', 'asc', null],
					floatingFilter: true
				},
				{
					headerName: "Date",
					field: "day",
					width: 100,
					cellRenderer: (params: ICellRendererParams) => moment(params.value).format('DD/MM/YYYY')
				},
				{
					headerName: "Nom",
					field: "name",
					floatingFilter: true
				},
				{
					headerName: "Description",
					field: "description",
					floatingFilter: true
				},
				{
					headerName: "Nb Fiche Heure Associes",
					field: "associatedHours",
					width: 100,
					cellClass: (params) => {
						const classes: Array<string> = []
						if (params.value > 0) {
							classes.push('text-red')
						}
						return classes
					}
				},
				{
					headerName: "🌐",
					headerClass: "text-center",
					field: "_id",
					colId: "col-delete",
					width: 8 + 28 * 2,
					pinned: 'right',
					cellRenderer: (params: ICellRendererParams) => {
						const div = document.createElement('div') as HTMLDivElement

						const N_update = document.createElement('button')
						N_update.classList.add('btn', 'btn-xs', 'btn-info')
						N_update.setAttribute('permission', 'SETTINGS.CALENDAR.EDIT')
						N_update.innerHTML = '<i class="icon icon-solid-pencil-alt"></i>'

						N_update.addEventListener('click', () => {
							const modal = new AddEditCalendarHoliday(params.value)
							modal.open().then(() => this.loadData())
						})
						div.appendChild(N_update)

						const N_delete = document.createElement('button')
						N_delete.classList.add('btn', 'btn-xs', 'btn-danger')
						N_delete.setAttribute('permission', 'SETTINGS.CALENDAR.DELETE')
						N_delete.innerHTML = '<i class="icon icon-solid-trash-alt"></i>'

						N_delete.addEventListener('click', async () => {

							try {
								if (params.data.associatedHours && params.data.associatedHours > 0) {
									await Alert.confirm('Suppression de la date ferie', `${params.data.associatedHours} heures comptabilises avec ce jour feries. Voulez-vous continuer ?`)
								}

								await CalendarHolidayService.getInstance().delete(params.value)
								params.api.applyTransaction({ remove: [params.data] })
								toastr.success(`Suppression de l'evenement`)
							}
							catch {
								toastr.error('Erreur de suppression')
							}
						})
						div.appendChild(N_delete)

						return div
					}
				}
			],
			defaultColDef: {
				filter: false,
				floatingFilter: false,
				suppressMenu: true,
				resizable: true
			},
			rowData: []
		}
		

		const N_grid = this.el.querySelector('#temporary-holiday-grid') as HTMLDivElement
		new Grid(N_grid, this.gridTemporaryHolidaysOptions)
	}

	private async loadData() {
		// REMARK: a voir si on garde cette regle de gestion ici
		const data = await CalendarHolidayService.getInstance().getAllExtended().then(calendarHolidays => calendarHolidays.filter(calendarHoliday => !calendarHoliday.deleted))
		this.gridTemporaryHolidaysOptions.api?.setRowData(data)
		this.gridTemporaryHolidaysOptions.api?.sizeColumnsToFit()
	}

	public destructor(): void {

	}


}

export default CalendarTab

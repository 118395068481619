import { Modal, global } from "@autoprog/core-client"

import T_detailTiers from '@tpl/modals/tiers/detailTiers.html'
import AddEditTiersModal from './AddEditTiersModal'

import h from 'hyperscript'

import TiersService from '@js/libs/services/TiersService'
import SitesService from '@js/libs/services/SitesService'
import FilesManager from '@js/libs/FilesManager'
import Tier from '@js/types/Tiers'

import _ from 'lodash'

class DetailTiersModal extends Modal {

	private N_title: HTMLElement | null = null
	private N_name: HTMLElement | null = null
	private N_fullAddress: HTMLElement | null = null
	private N_fullBillAddress: HTMLElement | null = null
	private N_phone: HTMLElement | null = null
	private N_office: HTMLElement | null = null
	private N_places: HTMLElement | null = null
	private N_contacts: HTMLElement | null = null
	private N_siret: HTMLElement | null = null
	private N_tvaNumber: HTMLElement | null = null
	private N_bankingDomiciliation: HTMLElement | null = null
	private N_IBAN: HTMLElement | null = null
	private N_accountNumber: HTMLElement | null = null
	private N_sites: HTMLElement | null = null
	private N_regulationType: HTMLElement | null = null
	private N_paymentPeriod: HTMLElement | null = null
	private N_footerTextBill: HTMLElement | null = null
	private filesManager: FilesManager = new FilesManager()

	private tier: Tier | null = null

	constructor(id: string) {

		super({
			tpl: T_detailTiers,
			backdrop: 'static',
			keyboard: false
		});

		this.on('opened', async () => {

			this.getHTMLElement()

			let N_btnEdit = this.element.querySelector('#editButton') as HTMLButtonElement;
			N_btnEdit.addEventListener2('click', () => {
				this.hide()
				let modal = new AddEditTiersModal(id);
				modal.open().then(() => {
					this.show();
					this.init(id);
				}).catch(() => this.show());
			})

			await this.init(id);
		})
	}

	private async init(id: string) {

		let tier = await TiersService.getInstance().getByID(id)
		this.tier = tier

		if (tier.sites) {
			let sitesBDD = await SitesService.getInstance().getByIds(tier.sites)
			tier.sites = sitesBDD.map(site => site.name)
		}

		if (this.element) {

			let N_btnEdit = this.element.querySelector('#editButton') as HTMLButtonElement;

			if (tier.locked) {
				N_btnEdit.remove();
			}

			if (tier.name && this.N_name && this.N_title) {
				this.N_title.innerHTML += ` : ${tier.name}`;
				this.N_name.innerHTML = tier.name;
			}

			if (tier.office && this.N_office) {
				this.N_office.innerHTML = tier.office;
			}


			if (tier.address && this.N_fullAddress) {
				this.N_fullAddress.innerHTML = [tier.address, tier.zipCode, tier.city].join(' ');
			}

			tier.bill = tier.bill || { address: '', city: '', zipCode: '' }

			if (tier.bill.address && this.N_fullBillAddress) {
				this.N_fullBillAddress.innerHTML = [tier.bill.address, tier.bill.zipCode, tier.bill.city].join(' ');
			}

			if (tier.phone && this.N_phone) {
				this.N_phone.innerHTML = tier.phone;
			}

			if (tier.siret && this.N_siret) {
				this.N_siret.innerHTML = tier.siret;
			}

			if (tier.tvaNumber && this.N_tvaNumber) {
				this.N_tvaNumber.innerHTML = tier.tvaNumber;
			}

			if (tier.bankingDomiciliation && this.N_bankingDomiciliation) {
				this.N_bankingDomiciliation.innerHTML = tier.bankingDomiciliation;
			}

			if (tier.IBAN && this.N_IBAN) {
				this.N_IBAN.innerHTML = tier.IBAN;
			}

			if (tier.accountNumber && this.N_accountNumber) {
				this.N_accountNumber.innerHTML = tier.accountNumber;
			}

			if (tier.sites && this.N_sites) {
				this.N_sites.innerHTML = tier.sites.join(' ');
			}

			if (tier.regulationType && this.N_regulationType) {

				let values: { [key: string]: string } = {
					transfer: 'Virement',
					bankCheck: 'Chèque',
					bankDraft: 'Traite bancaire'
				}

				this.N_regulationType.innerHTML = values[tier.regulationType] || '';
			}

			if (tier.paymentPeriod && this.N_paymentPeriod) {

				let values: { [key: string]: string } = {
					'30days': '30 jours',
					monthEnd: 'Fin du mois',
					'30daysMonthEnd': '30 jours / Fin du mois',
					cash: 'Comptant'
				}

				this.N_paymentPeriod.innerHTML = values[tier.paymentPeriod] || '';

			}

			if (tier.footerTextBill && this.N_footerTextBill) {
				this.N_footerTextBill.innerHTML = tier.footerTextBill;
			}

			if (this.N_contacts && tier.contacts) {

				this.N_contacts.innerHTML = '';

				let N_thead = h('thead',
					h('tr.w-100',
						h('th.w-25', 'Nom'),
						h('th.w-25', 'Tél.'),
						h('th.w-25', 'Email'),
						h('th.w-25', 'Fonction'),
					)
				);

				let N_tbody = h('tbody',
					tier.contacts.map((contact) => {
						return h('tr.w-100',
							h('td.w-25', contact.name + ' ' + (contact.firstname || '')),
							h('td.w-25', contact.phone),
							h('td.w-25', contact.email),
							h('td.w-25', contact.function),
						)
					})
				)

				this.N_contacts.appendChild(N_thead)
				this.N_contacts.appendChild(N_tbody)

			}


			if (this.N_places && tier.places) {
				
				this.N_places.innerHTML = '';

				let N_thead = h('thead',
					h('tr',
						h('th', 'Nom')
					)
				);

				let N_tbody = h('tbody',
					tier.places.map((place) => {
						return h('tr',
							h('td', place.name),
						)
					})
				)

				this.N_places.appendChild(N_thead)
				this.N_places.appendChild(N_tbody)
			}

			this.configureAttachment()

		}

	}

	private configureAttachment() {
		const N_saveDocumentBtn = this.element.querySelector('#saveDocumentBtn') as HTMLButtonElement
		N_saveDocumentBtn.style.display = 'block';
		let N_attachments = this.element.querySelector('#attachments') as HTMLElement;
		const N_attachmentsList = this.element.querySelector('#attachments-list') as HTMLElement;
		N_attachments.appendChild(this.filesManager.getNode());

		if (this.tier?._attachments) {
			this.filesManager.setFiles(this.tier._attachments);
			this.addAttachment('tiers', N_attachmentsList, this.tier);
		}
		else {
			N_attachmentsList.innerHTML = "Pas de piece jointes";
		}


		// Bouton de modification des pièces jointes
		
		N_saveDocumentBtn.addEventListener2('click', async () => {
			await TiersService.getInstance().syncAttachment(this.tier!._id, this.filesManager.getArrayFiles())

			// TODO: On met à jour les pièces jointes en fonction du bon de livraison
			toastr.success('Documents du bon de livraison modifié')
		})
	}

	private addAttachment(table: string, N_element: HTMLElement, object: any):void {

        for (let attachment in object._attachments) {

            let N_buttonAttachment = document.createElement('button') as HTMLButtonElement;

            N_buttonAttachment.type = 'button';
            N_buttonAttachment.classList.add('btn', 'btn-outline-secondary', 'btn-sm', 'btn-rounded', 'mr-2');
            N_buttonAttachment.innerHTML = attachment;
            N_element.appendChild(N_buttonAttachment);

            N_buttonAttachment.addEventListener('click', () => {
                window.open(`${global.COUCHDB_URL}/${table}/${object._id}/${attachment}`, "_blank");
            });

        }
    }

	private getHTMLElement() {
		if (this.element) {

			// FIXME: remplacer par la classe form
			this.N_title = this.element.querySelector('#title') as HTMLElement
			this.N_name = this.element.querySelector("#name") as HTMLElement
			this.N_fullAddress = this.element.querySelector("#address") as HTMLElement
			this.N_fullBillAddress = this.element.querySelector("#bill_address") as HTMLElement
			this.N_phone = this.element.querySelector("#phone") as HTMLElement
			this.N_office = this.element.querySelector("#office") as HTMLElement
			this.N_places = this.element.querySelector("#places") as HTMLElement
			this.N_contacts = this.element.querySelector("#contacts") as HTMLElement
			this.N_siret = this.element.querySelector("#siret") as HTMLElement
			this.N_tvaNumber = this.element.querySelector("#tvaNumber") as HTMLElement
			this.N_bankingDomiciliation = this.element.querySelector("#bankingDomiciliation") as HTMLElement
			this.N_IBAN = this.element.querySelector("#IBAN") as HTMLElement
			this.N_accountNumber = this.element.querySelector("#accountNumber") as HTMLElement
			this.N_sites = this.element.querySelector("#sites") as HTMLElement
			this.N_regulationType = this.element.querySelector("#regulationType") as HTMLElement
			this.N_paymentPeriod = this.element.querySelector("#paymentPeriod") as HTMLElement
			this.N_footerTextBill = this.element.querySelector("#footerTextBill") as HTMLElement
		}
	}

}

export default DetailTiersModal;
import 'daterangepicker'
import 'daterangepicker/daterangepicker.css'
import moment, { Moment } from 'moment'

import Highcharts from '@libs/Highcharts';
import { global, Tab, LoggedUser } from "@autoprog/core-client"

import drpC from '@libs/utils/daterangepickerConfig'
import _ from 'lodash'

import { HoursCategories, SitesService, HoursService, UsersService } from "@js/libs/services"

class TabStats extends Tab {

	private startDate: Moment = moment().startOf('month');
	private endDate: Moment = moment().endOf('month');
	private site: string;

	private loggedUser: LoggedUser;

	constructor(private el: HTMLElement) {
		super(el)
		this.site = global.SITE;
		this.loggedUser = LoggedUser.getInstance();

		$(this.el).find('#date-picker').daterangepicker(drpC({
			startDate: this.startDate,
			endDate: this.endDate,

			opens: "center",

		}), (start, end, _label) => {

			this.startDate = start.startOf('day');
			this.endDate = end.endOf('day');
			this.loadRowData(this.startDate.format('x'), this.endDate.format('x'));
			this.saveDates()
		});

		this.loadDates()
		this.loadRowData(this.startDate.format('x'), this.endDate.format('x'))
	}

	public updateSelectSite(value: string) {
		this.site = value
		this.loadDates()
		this.loadRowData(this.startDate.format('x'), this.endDate.format('x'))

	}

	private async loadRowData(startDate: string, endDate: string): Promise<void> {

		const sites = await SitesService.getInstance().getAllSites().then(data => _.keyBy(data, '_id'))

		const userId = this.loggedUser.hasPermission('HUMAN_RESSOURCES.STATS.SHOW_ALL_USERS') ? null : this.loggedUser.get('ID')
		const values = await HoursService.getInstance().getHours(this.site, +startDate, +endDate, userId)

		const userIds = _.uniq(values.map(hourData => hourData.user))

		const users = await UsersService.getInstance().getByIds(userIds).then(data => _.keyBy(data, '_id'))
		const categories = Object.values(users).map(user => `${user.firstname} ${user.lastname}`)

		const hoursCategories = HoursCategories.getInstance().getAll()

		const statsByCat: {
			[categoryId: string]: { [siteId: string]: { [userId: string]: { hours: 0, standbyDuties: 0 } } }
		} = {}


		for (let value of values) {

			for (let siteId in value.stats) {

				let stats = value.stats[siteId];

				for (let catId in stats) {

					statsByCat[catId] = statsByCat[catId] || {};
					statsByCat[catId][siteId] = statsByCat[catId][siteId] || {};
					statsByCat[catId][siteId][value.user] = statsByCat[catId][siteId][value.user] || { hours: undefined, standbyDuties: undefined };

					statsByCat[catId][siteId][value.user].hours = statsByCat[catId][siteId][value.user].hours || 0;
					statsByCat[catId][siteId][value.user].standbyDuties = statsByCat[catId][siteId][value.user].standbyDuties || 0;

					statsByCat[catId][siteId][value.user].hours += value.stats[siteId][catId].hours
					statsByCat[catId][siteId][value.user].standbyDuties += value.stats[siteId][catId].standbyDuties

				}

			}
		}

		// TODO: le mettre dans une autre methode
		const seriesHours: Highcharts.SeriesColumnOptions[] = [];

		let index = 0;
		const maxCat = Object.keys(statsByCat).length

		for (let catId in statsByCat) {

			// FIXME: appeler autre que index2, merci xD
			let index2 = 0;
			const hourCategory = _.find(hoursCategories, { _id: catId })

			if (!hourCategory) {
				continue
			}

			const color = _.isEmpty(hourCategory.color) ? `hsl(${index * 40},75%,${100 - (((index2 / maxCat) * 25) + 25)}%)` : hourCategory.color

			seriesHours.push({
				type: 'column',
				name: hourCategory.label,
				color
			})

			for (let siteId in statsByCat[catId]) {
				const dataStandyDuties = Object.keys(users).map(userID => statsByCat[catId][siteId][userID]?.standbyDuties || 0)

				seriesHours.push({
					type: 'column',
					name: `Astreinte ${sites[siteId].name} ${hourCategory.label}`,
					data: dataStandyDuties,
					index: index2,
					color,
					linkedTo: ':previous',
				})

				index2++
			}

			for (let siteId in statsByCat[catId]) {
				const dataHours = Object.keys(users).map(userID => statsByCat[catId][siteId][userID]?.hours || 0)

				seriesHours.push({
					type: 'column',
					index: index2,
					name: `Heures ${sites[siteId].name} ${hourCategory.label}`,
					data: dataHours,
					color,
					linkedTo: ':previous',
				})

				index2++
			}


			index++;
		}

		this.createChart(categories, seriesHours)
	}

	private createChart(categories: Array<string>, seriesHours: Array<Highcharts.SeriesColumnOptions>): void {
		Highcharts.chart('highchart', {
			chart: {
				type: 'bar'
			},
			title: {
				text: 'Heures/Astreinte'
			},
			xAxis: {
				categories: categories
			},
			yAxis: {
				min: 0,
				title: {
					text: 'Heures/Astreinte'
				},

				labels: {
					formatter: function () {
						return Math.round(this.value / 3600000) + 'h'
					}
				},
				stackLabels: {
					enabled: true,
					style: {
						fontWeight: 'bold',
						color: 'gray'
					},
					formatter: function () {
						return Math.round(this.total / 3600000) + 'h'
					}
				},
			},

			plotOptions: {
				series: {
					stacking: 'normal',
					dataLabels: {
						enabled: true,
						formatter: function () {
							if (this.percentage && this.total) {
								return Math.round(((this.total / 100) * this.percentage) / 3600000) + 'h'
							}

							return ''
						}
					}
				}
			},
			tooltip: {
				formatter: function () {

					let duration = moment.duration(this.y);

					let days = duration.get('days');

					let hours = ('00' + duration.get('hour')).slice(-2);
					if (days >= 1) {
						hours = ('000' + (days * 24 + duration.get('hour'))).slice(-3);
					}

					let minutes = ('00' + duration.get('minute')).slice(-2);

					return `${this.series.name} ${hours}:${minutes}`
				}

			},
			credits: {
				enabled: false
			},
			series: seriesHours
		});
	}

	public destructor() {
		this.saveDates();
		let N_daterangepicker = document.querySelectorAll('.daterangepicker');

		N_daterangepicker.forEach((item) => {
			let parent = item.parentNode as HTMLElement;
			parent.removeChild(item);
		});
	}


	private saveDates() {

		let identifier = `${global.SITE}_humanressource_dates`;
		let dates = { startDate: this.startDate.format('x'), endDate: this.endDate.format('x') };

		localStorage.setItem(identifier, JSON.stringify(dates));
	}

	private loadDates() {
		const identifier = `${global.SITE}_humanressource_dates`;

		// Dans le cas une session de date a été crée
		if (localStorage.getItem(identifier)) {
			const dates = JSON.parse((localStorage.getItem(identifier) as string));

			this.startDate = moment(dates.startDate, 'x');
			this.endDate = moment(dates.endDate, 'x');

			// Erreur à laisser en typescript
			$(this.el).find('#date-picker').data('daterangepicker')!.setStartDate(this.startDate.format('DD/MM/YYYY'))
			$(this.el).find('#date-picker').data('daterangepicker')!.setEndDate(this.endDate.format('DD/MM/YYYY'))

		}

	}

}

export default TabStats;

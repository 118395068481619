import { TableChecklistDefinition } from "../customElements/checklists/TableChecklistRow"
import _ from "lodash"
import moment, { Duration, Moment } from "moment"
import { CollectionUtils } from "."
import { KeyCode } from "ag-grid-community"

export type ChecklistDataType = Moment | Duration | string | number | boolean | undefined

export type ChecklistData = {
	id: string
	value?: ChecklistDataType
	definition: string
	tag?: string
}

type ValueChangedCallback = (newValue?: string | number | boolean) => void

export type TableChecklistRowInput = {
	elements: Array<HTMLTableDataCellElement>
	valueGetter: () => ChecklistDataType
	valueSetter: (value?: ChecklistDataType) => void
	setChangedCallback: (callback?: ValueChangedCallback) => void
	isValid: () => boolean
}

type CustomInput = Pick<TableChecklistRowInput, "valueSetter" | "valueGetter" | "setChangedCallback" | "isValid"> & { element: HTMLDivElement }

class TableChecklistHelper {

	private constructor() {}

	private static readonly TabInputsTypes: { [key: string]: (type: string, definition?: TableChecklistDefinition) => TableChecklistRowInput } = {
		'title1': TableChecklistHelper.defaultCells,
		'title2': TableChecklistHelper.defaultCells,
		'title3': TableChecklistHelper.defaultCells,
		'text': TableChecklistHelper.defaultCells,
		'spacer': TableChecklistHelper.defaultCells,
		'input': TableChecklistHelper.customCells
	}

	private static TabCustomInputs: { [key: string]: (defintion: TableChecklistDefinition) => CustomInput } = {
		'checkbox': TableChecklistHelper.customCheckbox,
		'select': TableChecklistHelper.customSelect,
		'textarea': TableChecklistHelper.customTextArea,
		'text': TableChecklistHelper.customDefaultInput,
		'number': TableChecklistHelper.customDefaultInput,
		'date': TableChecklistHelper.customDefaultInput,
		'time': TableChecklistHelper.customDefaultInput
	}

	public static cell(type: string, definition?: TableChecklistDefinition) {
		return this.TabInputsTypes[type](type, definition)
	}

	public static defaultCells(type: string) : TableChecklistRowInput {
		const N_cell = document.createElement('td') as HTMLTableDataCellElement
		N_cell.classList.add(type)
		N_cell.colSpan = 3
		N_cell.textContent = ''

		return {
			elements: [N_cell],
			valueGetter: () => N_cell.textContent || '',
			valueSetter: (value?: ChecklistDataType) => _.set(N_cell, 'textContent', value || ''),
			setChangedCallback: () => {},
			isValid: () => true
		}
	}

	public static customCells(_type: string, definition?: TableChecklistDefinition) : TableChecklistRowInput {

		if (!definition) {
			throw new Error('Problem definition on creating custom input on table-checklist-row')
		}

		// Creation label
		const N_label = document.createElement('td') as HTMLTableDataCellElement
		N_label.classList.add('td-label')
		N_label.textContent = definition.name

		// Creation description
		const N_description = document.createElement('td') as HTMLTableDataCellElement
		N_description.classList.add('td-description')
		N_description.textContent = definition.description

		// Creation custom input
		const N_input = document.createElement('td') as HTMLTableDataCellElement
		N_input.classList.add('td-value')
		const input = TableChecklistHelper.customInput(definition)
		N_input.appendChild(input.element)

		// TODO: finir cette ligne
		return {
			elements: [N_label, N_description, N_input],
			..._.omit(input, 'element')
		}
	}

	
	private static customInput(definition: TableChecklistDefinition): CustomInput {
		const theInput = this.TabCustomInputs[definition.type](definition) as CustomInput
		theInput.element.querySelector("input")?.addEventListener("keyup", (event) => {
			if (event.keyCode === KeyCode.ENTER) {
				const el = (event.target as HTMLInputElement).closest("tr") as HTMLTableRowElement
				const nextIndex = el.tabIndex + 1
				const nextTarget = el.parentElement!.querySelector(`tr[tabIndex="${nextIndex}"]`)
				if (nextTarget) {
					(nextTarget.querySelector("input, textarea") as HTMLInputElement).focus()
				}
			}
		})
		return theInput
	}

	private static div(input: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement, unit?: string) : HTMLDivElement {
		const N_div = document.createElement('div') as HTMLDivElement
		N_div.appendChild(input)
		N_div.classList.add('input-group')

		if (unit) {
			const N_unit = document.createElement('div') as HTMLDivElement
			N_unit.classList.add('input-group-append')
			N_unit.innerHTML = `<span class="input-group-text">${unit}</span>`
			N_div.appendChild(N_unit)
		}

		return N_div
	}

	private static customCheckbox(definition: TableChecklistDefinition) : CustomInput {

		if (definition.type !== 'checkbox') {
			throw new Error('Error Type')
		}

		const N_input = document.createElement('input') as HTMLInputElement
		N_input.type = 'checkbox'
		N_input.name = definition.name
		N_input.checked = definition.default === true
		N_input.classList.add('form-check-input')

		const N_div = document.createElement('div') as HTMLDivElement
		N_div.classList.add('form-check')

		const N_label = document.createElement('label') as HTMLLabelElement
		N_label.classList.add('col-check-label', 'm-0')
		N_label.textContent = ' '

		N_div.appendChild(N_input)
		N_div.appendChild(N_label)

		return {
			valueGetter: (): boolean => N_input.checked,
			valueSetter: (value?: ChecklistDataType) => _.set(N_input, 'checked', !!value),
			setChangedCallback: (callback?: ValueChangedCallback) => {
				N_input.replaceWith(N_input.cloneNode(false))
				N_input.addEventListener('change', () => callback && callback(N_input.value))
			},
			isValid: () => N_input.validity.valid,
			element: N_div
		}
	}

	private static customSelect(definition: TableChecklistDefinition) : CustomInput {
		if (definition.type !== 'select') {
			throw new Error('Error Type')
		}

		const N_select = document.createElement('select') as HTMLSelectElement
		N_select.classList.add('custom-select')
		definition.values
			.map(value => new Option(value, value, value === definition.default, value === definition.default))
			.forEach(node => N_select.appendChild(node))
		const N_div = TableChecklistHelper.div(N_select, definition.unit)

		return {
			element: N_div,
			valueGetter: () => N_select.value,
			valueSetter: (value?: ChecklistDataType) => _.set(N_select, 'value', value),
			setChangedCallback: (callback?: ValueChangedCallback) => {
				N_select.replaceWith(N_select.cloneNode(false))
				N_select.addEventListener('change', () => callback && callback(N_select.value))
			},
			isValid: () => N_select.validity.valid
		}
	}

	private static customTextArea(definition: TableChecklistDefinition) : CustomInput {
		if (definition.type !== 'textarea') {
			throw new Error('Error Type')
		}

		const N_text = document.createElement('textarea') as HTMLTextAreaElement
		N_text.classList.add('form-control')
		N_text.value = definition.default || ""
		const N_div = TableChecklistHelper.div(N_text, definition.unit)

		// Le has est important pour eviter d'avoir des attributs a vide qui provoque les erreur par defaut comme l'attribut pattern
		const has = (definition: TableChecklistDefinition, property: string) => _.isEqual(_.isNil(Reflect.get(definition, property)) || Reflect.get(definition, property) === "", false)
		const defaultAttributes = ['step', 'pattern', 'placeholder']
		defaultAttributes.forEach(attr => has(definition, attr) && N_text.setAttribute(attr, Reflect.get(definition, attr)))
		has(definition, 'max') && N_text.setAttribute('maxLength', definition.max!.toString())
		has(definition, 'min') && N_text.setAttribute('minLength', definition.min!.toString())

		// Creer un evenement de debounce pour les textes qui changent

		return {
			element: N_div,
			valueGetter: () => N_text.value,
			valueSetter: (value?: ChecklistDataType) => _.set(N_text, 'value', value),
			setChangedCallback: () => {},
			isValid: () => N_text.validity.valid
		}
	}

	private static customDefaultInput(definition: TableChecklistDefinition) : CustomInput {
		const N_input = document.createElement('input')
		N_input.type = definition.type
		N_input.name = definition.name
		N_input.classList.add('form-control')

		const valueSetter = (value?: ChecklistDataType) => _.set(N_input, N_input.type === 'date' ? 'valueAsDate' : 'value', Formatter.inputValue(definition.type, value))

		const has = (definition: TableChecklistDefinition, property: string) => _.isEqual(_.isNil(Reflect.get(definition, property)) || Reflect.get(definition, property) === "", false)
		const defaultAttributes = ['step', 'pattern', 'placeholder']
		defaultAttributes.forEach(attr => has(definition, attr) && N_input.setAttribute(attr, Reflect.get(definition, attr)))
		
		if (definition.type === 'text') {
			has(definition, 'max') && N_input.setAttribute('maxLength', definition.max!.toString())
			has(definition, 'min') && N_input.setAttribute('minLength', definition.min!.toString())
		}
		else {
			has(definition, 'max') && N_input.setAttribute('max', Formatter.inputValue(definition.type, definition.max).toString())
			has(definition, 'min') && N_input.setAttribute('min', Formatter.inputValue(definition.type, definition.min).toString())
		}
		
		definition.default && valueSetter(definition.default)
		
		const N_div = TableChecklistHelper.div(N_input, definition.unit)

		// Creer un evenement de debounce pour les textes qui changent

		return {
			element: N_div,
			valueGetter: () => {
				let rawValue: string | Date | number | undefined = N_input.value

				if (definition.type === 'date') {
					rawValue = N_input.valueAsDate || undefined
				}
				else if (definition.type === 'time') {
					rawValue = N_input.valueAsNumber
				}

				return Formatter.formattedValue(definition.type, rawValue)
			},
			valueSetter,
			setChangedCallback: () => {},
			// Cas particulier ou le type date fait defaut avec stepMismatch, sachant qu'il nest pas important pour la verification, on peut l'ignorer
			isValid: () => TableChecklistHelper.isDateInputValid(N_input) || N_input.validity.valid
		}
	}

	private static isDateInputValid(input: HTMLInputElement) : boolean {
		return (input.type === 'date') ? !CollectionUtils.hasAnyPropertyValue(_.omit(input.validity, 'valid', 'stepMismatch'), true) : false
	}
}


class Formatter {
	private constructor() {}

	public static inputValue(type: string, value: any) : string | Date {
		let result: string | Date = _.isNil(value) ? "" : value.toString()

		if (moment.isMoment(value) && value.isValid()) {
			result = value.format('YYYY-MM-DD')
		}
		else if (moment.isDuration(value) && value.isValid()) {
			result = moment.utc(value.as('milliseconds')).format('HH:mm')
		}
		else if (type === 'date') {
			result = moment(value).toDate()
		}
		else if (type === 'time') {
			result = moment.utc(moment.duration(value).as('milliseconds')).format('HH:mm')
		}
		
		return result
	}

	public static formattedValue(type: string, value: string | Date | number | undefined) : ChecklistDataType {
		let result: ChecklistDataType = value as any

		if (type === 'number') {
		//	result = +(value?.toString() || 0)
		}
		else if (type === 'date') {
			result = +moment(value).format('x')
		}
		else if (type === 'time') {
			// TODO: a tester avec prudence
			result = moment.duration(+value!, 'ms')
		}

		return result
	}
}

export default TableChecklistHelper

const stateList = {
    all: {
        name: 'Toute',
        filterValue: '',
        cls: ''
    },
    toConfirm: {
        name: 'Validation manager demandée',
        filterValue: 'Validation Manager Demandée',
        cls: 'to-confirm'
    },
    preValidate: {
        name: 'En prévalidation',
        filterValue: 'En prévalidation',
        cls: 'pre-validate'
    },
    archived: {
        name: 'Archivée',
        filterValue: 'Archivée',
        cls: 'archived'
    },
    notArchived: {
        name: 'Non archivée',
        filterValue: 'Non archivée',
        cls: 'not-archived'
    },
    notDelivered: {
        name: 'Non livrée',
        filterValue: 'Non livrée',
        cls: 'not-delivered'
    },
    inDelivering: {
        name: 'En cours de livraison',
        filterValue: 'En cours de livraison',
        cls: 'in-delivering'
    },
    delivered: {
        name: 'Livrée',
        filterValue: 'Livrée',
        cls: 'delivered'
    },
    notBilled: {
        name: 'Non facturée',
        filterValue: 'Non facturée',
        cls: 'not-billed'
    },
    inBilling: {
        name: 'En cours de facturation',
        filterValue: 'En cours de facturation',
        cls: 'in-billing'
    },
    billed: {
        name: 'Facturée',
        filterValue: 'Facturée',
        cls: 'billed'
    },
    waiting : {
        name: 'En attente',
        filterValue: 'En attente',
        cls: 'waiting'
    },
    recorded : {
        name: 'Comptabilisée',
        filterValue: 'Comptabilisée',
        cls: 'recorded'
    },
    validated : {
        name: 'Validée',
        filterValue: 'Validée',
        cls: 'validated'
    },
    locked : {
        name: 'Bloquée',
        filterValue: 'Bloquée',
        cls: 'locked'
    },
    paid : {
        name: 'Payée',
        filterValue: 'Payée',
        cls: 'paid'
    },
    paying : {
        name: 'En cours de paiement',
        filterValue: 'En cours de paiement',
        cls: 'paying'
    },
    cancel_payment : {
        name: 'Dévalidée',
        filterValue: 'Dévalidée',
        cls: 'cancel-payment'
    }

} as { [state: string]: { name: string, filterValue: string, cls: string } };

export default stateList

import BDD from "@js/libs/BDD"
import TableChecklistHelper, { ChecklistData, ChecklistDataType } from "@js/libs/utils/table-checklist.helper"
import _ from "lodash"
import TableCheckList from "./TableChecklist"

type TableChecklistDefinitionType =
	{ type: 'text', min?: number, max?: number, default?: string, pattern?: string, placeholder?: string }
	| { type: 'textarea', min?: number, max?: number, default?: string, pattern?: string, placeholder?: string }
	| { type: 'number', min?: number, max?: number, default?: number, pattern?: string, placeholder?: string, step?: number }
	| { type: 'date', min?: number, max?: number, default?: string, pattern?: string, placeholder?: string, step?: number }
	| { type: 'time', min?: number, max?: number, default?: string, pattern?: string, placeholder?: string, step?: number }
	| { type: 'checkbox', min?: number, max?: number, default?: boolean }
	| { type: 'select', min?: number, max?: number, default?: string, values: Array<string> }

export type TableChecklistDefinition = { id: string, name: string, unit?: string, description: string } & TableChecklistDefinitionType

// TODO: ameliorer pour s'occuper de ordre des tablechecklistrow
export type TableChecklistStructure =
	{ type: 'title1' | 'title2' | 'title3' | 'text', value: string }
	| { type: 'spacer' }
	| { type: 'input', definitions?: TableChecklistDefinition }

class TableChecklistRow extends HTMLTableRowElement {
	public static readonly tagName = 'table-check-list-row'

	private _value: ChecklistData
	private _structure: TableChecklistStructure

	private valueGetter: () => ChecklistDataType
	private valueSetter: (value?: ChecklistDataType) => void
	private validGetter: () => boolean

	constructor() {
		super()

		this.valueGetter = () => ""
		this.valueSetter = () => { }
		this.validGetter = () => true

		this._value = {
			definition: '',
			id: BDD.generateID()
		}

		this.innerHTML = `<td colspan="3" class="title1"></td>`
		this._structure = { type: 'title1', value: '' }

		this.dataset.is = TableChecklistRow.tagName
		this.id = this._value.id
		this.drawType()
	}

	set structure(value: TableChecklistStructure) {
		this._structure = value
		this.draw()
	}

	get structure(): TableChecklistStructure {
		return this._structure
	}

	get value(): ChecklistData {
		const newValue = this.valueGetter()
		this._value.value = newValue
		this.definition && _.set(this._value, 'definition', this.definition.id)
		return this._value
	}

	set value(newValue: ChecklistData) {
		this._value = newValue
		this.draw()
		this.valueSetter(newValue.value)
	}

	get definition(): TableChecklistDefinition | undefined {
		return this._structure.type === 'input' ? this._structure.definitions : undefined
	}

	get type(): 'title1' | 'title2' | 'title3' | 'text' | 'spacer' | 'input' {
		return this._structure.type
	}

	public checkValidity(): boolean {

		const isValid = this.validGetter()
		// REMARK: trop feignant de le mettre dans le helper, quand le temps viendra, le mettre dans les helpers
		const N_input = this.querySelector('.form-control') // Pas forcement de form-control (boostrap seulement)
		N_input && N_input.classList.toggle('is-invalid', !isValid)

		return isValid
	}

	public draw() {
		this.drawType()
	}

	public connectedCallback() {
		if (this.getRoot().tagName !== TableCheckList.tagName.toUpperCase()) {
			throw new Error('It should be on a table-checklist')
		}
	}

	private getRoot(): TableCheckList {
		return this.parentElement?.parentElement?.tagName === TableCheckList.tagName.toUpperCase()
			// Gerer le cas du Tbody
			? this.parentElement?.parentElement as TableCheckList
			: this.parentElement?.parentElement?.parentElement as TableCheckList
	}

	public static createElement(): TableChecklistRow {
		return document.createElement('tr', { is: TableChecklistRow.tagName }) as TableChecklistRow
	}

	public static register() {
		if (_.isNil(customElements.get(TableCheckList.tagName))) {
			throw new Error('Should be declared on the table-checklist')
		}
		customElements.define(TableChecklistRow.tagName, TableChecklistRow, { extends: 'tr' })
	}

	private drawType() {
		this.innerHTML = ''
		const N_cells = TableChecklistHelper.cell(this.type, this.definition)

		this.valueGetter = N_cells.valueGetter
		this.valueSetter = N_cells.valueSetter
		this.validGetter = N_cells.isValid

		N_cells.elements.forEach(node => this.appendChild(node))
	}
}


export default TableChecklistRow

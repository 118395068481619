import { Modal, global, LoggedUser } from "@autoprog/core-client"

import { calendarBdd } from '@js/types/calendar'
import T_addCalendar from '@tpl/modals/calendar/addCalendar.ejs'

import _ from 'lodash'

class AddCalendarModal extends Modal {

	private saveData: { [key: string]: any }
	private loggedUser: LoggedUser

	constructor(data: any, type = 'add') {

		super({
			tpl: T_addCalendar({
				data,
				global,
				type
			}),
			backdrop: 'static',
			keyboard: false
		});

		this.saveData = data;
		this.loggedUser = LoggedUser.getInstance();

		this.on('opened', () => {

			this.save()

			const N_checkboxView = this.element.querySelectorAll('[data-path="calendar.view"]')

			for (let el of N_checkboxView) {
				this.eventCheckBoxView((el as HTMLInputElement));
			}

			setTimeout(() => {

				if (this.element) {
					const N_site = this.element.querySelector('#site select') as HTMLSelectElement

					if (N_site) {
						N_site.value = data.sites[0]
					}

				}

			})

		})

	}

	private eventCheckBoxView(el: HTMLInputElement) {

		el.addEventListener('change', () => {

			let N_lineGroup = (el.parentNode as HTMLElement);
			N_lineGroup = (N_lineGroup.parentNode as HTMLElement);
			N_lineGroup = (N_lineGroup.parentNode as HTMLElement);

			let N_checkbox = N_lineGroup.querySelectorAll('[type="checkbox"]');

			for (let tmp of N_checkbox) {

				const checkbox = tmp as HTMLInputElement

				if (checkbox.dataset.path != el.dataset.path) {

					checkbox.disabled = !el.checked;

					if (!el.checked) {
						checkbox.checked = false;
					}

				}

			}

		})

	}

	private save(): void {

		if (this.element) {
			const N_button = this.element.querySelector('#save')

			const N_name = this.element.querySelector('#name') as HTMLInputElement
			const N_bgColor = this.element.querySelector('#bgColor input') as HTMLInputElement
			const N_color = this.element.querySelector('#color input') as HTMLInputElement
			const N_category = this.element.querySelector('#category') as HTMLInputElement
			const N_type = this.element.querySelector('#type') as HTMLSelectElement
			const N_site = this.element.querySelector('#site select') as HTMLSelectElement

			if (N_button) {

				N_button.addEventListener('click', () => {

					const data: calendarBdd = {
						_id: this.saveData._id || this.generateId(),
						category: N_category.value || '',
						name: N_name.value,
						isRecall: N_type.value == '1',
						color: N_color.value,
						bgColor: N_bgColor.value,
						dragBgColor: N_bgColor.value,
						borderColor: N_bgColor.value,
						sites: [N_site.value],
						events: [],
						repeat: this.saveData.repeat || [],
						owner: this.saveData.owner || this.loggedUser.get('ID'),
						groups: {}
					};

					if (this.saveData.locked) {
						data.locked = this.saveData.locked;
					}

					if (this.saveData._rev) {
						data._rev = this.saveData._rev;
					}

					if (!data.name) {
						N_name.classList.add('is-invalid')
					}
				});

			}
		}

	}

	private generateId(): string {
		return Date.now().toString(36);
	}

}

export default AddCalendarModal;

import moment from 'moment'
import drpC from '@libs/utils/daterangepickerConfig'


import '@css/agGridDateFilter.scss'
import { IFloatingFilterComp, IFloatingFilterParams } from "ag-grid-community";
import _ from "lodash";


class DatePickerRangeFilter implements IFloatingFilterComp {

	private params?: IFloatingFilterParams;
	private eGui: HTMLElement;
	private eDateInput: HTMLInputElement | null = null;

	private startDate: Date | null = null;
	private endDate: Date | null = null;


	constructor() {
		this.eGui = document.createElement('div')
	}

	public init(params: IFloatingFilterParams) {
		this.params = params

		this.eGui.classList.add('ag-input-text-wrapper', 'ag-date-filter')
		this.eGui.innerHTML = `
            <input type='text' data-input value="" />
            <a class='input-button' title='clear' data-clear>
                <i class='icon icon-solid-times'></i>
            </a>
        `

		this.eDateInput = this.eGui.querySelector('input')

		const N_clear = this.eGui.querySelector('[data-clear]') as HTMLElement

		N_clear.addEventListener('click', () => {

			if (this.eDateInput) {
				this.startDate = null
				this.endDate = null

				let N_input = $(this.eDateInput)
				N_input.val('')

				this.updateFilter()
			}

		})

		if (this.eDateInput) {

			let N_input = $(this.eDateInput);

			N_input.daterangepicker(drpC({
				autoUpdateInput: false,
				startDate: new Date()
			}))

			N_input.on('apply.daterangepicker', (_ev, picker) => {
				this.startDate = picker.startDate.toDate()
				this.endDate = picker.endDate.toDate()

				N_input.val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'))

				this.updateFilter()
			})

			N_input.val('')

		}

	}

	public updateFilter() {

		if (!this.params) {
			throw new Error('Params is not defined')
		}

		const model = this.params?.api.getFilterModel()

		// On change que la colonne qui nous interesse et rien d'autre
		if (this.startDate && this.endDate) {
			_.set(model, this.params.column.getColId(), {
				type: 'inRange',
				dateFrom: moment(this.startDate).format('YYYY-MM-DD'),
				dateTo: moment(this.endDate).format('YYYY-MM-DD'),
				filterType: 'date'
			})
		}
		else {
			Reflect.deleteProperty(model, this.params.column.getColId())		
		}

		this.params.api.setFilterModel(model)
	}

	public onParentModelChanged(parentModel: any) {

		if (this.eDateInput) {

			const N_input = $(this.eDateInput);

			if (parentModel) {

				const startDate = moment(parentModel.dateFrom);
				const endDate = moment(parentModel.dateTo);


				const picker = N_input.data('daterangepicker')

				if (picker) {

					picker.setStartDate(startDate)
					picker.setEndDate(endDate)

				}

				N_input.val(startDate.format('DD/MM/YYYY') + ' - ' + endDate.format('DD/MM/YYYY'))

				this.startDate = startDate.toDate()
				this.endDate = endDate.toDate()

			}

		}

	}

	public getGui(): HTMLElement {
		return this.eGui
	}

}

export default DatePickerRangeFilter;

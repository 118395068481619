import { ICellEditorParams } from 'ag-grid-community'

class NumericConfirmationCellEditor {

	private eInput?: HTMLInputElement;
	private cancelBeforeStart: boolean;

	constructor() {
		this.cancelBeforeStart = false;
	}

	// gets called once before the renderer is used
	public init(params: ICellEditorParams) {
		// create the cell
		this.eInput = document.createElement('input') as HTMLInputElement
		this.eInput.type = 'number'

		this.eInput.classList.add('ag-cell-edit-input')

		if (params.charPress && this.isCharNumeric(params.charPress)) {
			this.eInput.value = params.charPress
		}
		else if (params.value === 0 || params.value) {
			this.eInput.value = params.value
		}

		this.eInput.addEventListener('keypress', (event) => {
			if (this.eInput) {
				if (!this.isKeyPressedNumeric(event)) {
					this.eInput.focus()
					event.preventDefault && event.preventDefault()
				}
				else if (this.isKeyPressedNavigation(event)) {
					event.stopPropagation()
				}
			}
		})

		this.eInput.addEventListener('blur', () => {
			params.stopEditing(true)
		})

		// only start edit if key pressed is a number, not a letter
		var charPressIsNotANumber = params.charPress && ('1234567890.,'.indexOf(params.charPress) < 0);
		this.cancelBeforeStart = !!charPressIsNotANumber
	}

	private isKeyPressedNavigation(event: KeyboardEvent) {
		return event.keyCode === 39
			|| event.keyCode === 37
	}


	// gets called once when grid ready to insert the element
	public getGui() {
		return this.eInput
	}

	// focus and select can be done after the gui is attached
	public afterGuiAttached() {
		this.eInput && this.eInput.focus()
	}

	// returns the new value after editing
	public isCancelBeforeStart() {
		return this.cancelBeforeStart
	}

	// returns the new value after editing
	public getValue() {
		return this.eInput && this.eInput.valueAsNumber ? this.eInput.valueAsNumber : 0
	}

	private isCharNumeric(charStr: string) {
		return !!/[0-9]|,|./.test(charStr)
	}

	private isKeyPressedNumeric(event: KeyboardEvent) {
		const charCode = this.getCharCodeFromEvent(event)
		const charStr = String.fromCharCode(charCode)
		return this.isCharNumeric(charStr)
	}

	private getCharCodeFromEvent(event: KeyboardEvent) {
		event = event || window.event as KeyboardEvent
		return (typeof event.which == "undefined") ? event.keyCode : event.which
	}

}

export default NumericConfirmationCellEditor

import axios from 'axios'

export type EnvironnementType = 'test' | 'production' | 'staging' | 'development'

export interface ApplicationInformations {
	client: {
		version: string
		environment: EnvironnementType
	},
	server: {
		version: string
		environment: EnvironnementType
	}
}

class ApplicationService {

	private static instance = new ApplicationService();

	private constructor() {}

	public static getInstance() : ApplicationService {
		return ApplicationService.instance;
	}

	public async getAll() : Promise<ApplicationInformations> {
		return axios.get(`/api/application`).then(response => response.data.data)
	}


	public async ping() : Promise<boolean> {
		return axios.get('/ping').then(response => response.data === 'pong')
	}
}

export default ApplicationService;

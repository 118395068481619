import { FilesManagerFile, CouchDBData, CouchDBAttachment } from './FilesManager'
import { global } from "@autoprog/core-client"
import axios from 'axios'

/**
 * Classe Utilitaire qui permet de gérer les fichiers de manière plus simple
 * @author Bastien VINH
 * @copyright Autoprog
 * @description Il est dépendant de l'ancien FilesManager
 */
class FilesManager2 {

	// FIXME: le mettre dans un autre fichier global
	private static async getData(table: string, id: string) : Promise<CouchDBData> {
		return axios.get(`${global.COUCHDB_URL}/${table}/${id}`).then((response) => response.data)
	}

	/**
	 * Permet de récupérer les métadata des pièces jointes
	 * @param table La table concernée
	 * @param id L'identifiant de la ligne
	 */
	public static async getAttachments(table: string, id: string) : Promise<Array<CouchDBAttachment>> {
		let data = await FilesManager2.getData(table, id)
		let result = []

		if (data._attachments) {
			for (let filename in data._attachments) {
				result.push({ name: filename, ...data._attachments[filename] })
			}
		}

		return result
	}

	/**
	 * Permet d'ajouter un fichier dans la base de données dans une ligne de CouchDB
	 * TODO: passer par des API au lieu de couchDB
	 * @param table Nom de la table
	 * @param id Identifiant CouchDB
	 * @param file Fichier à ajouter
	 */
	public static async addAttachment(table: string, id: string, file: FilesManagerFile) : Promise<void> {

		// Récupération la data couch db pour récupérer la révision	
		// TODO: vérifier que la valeur existe
		let couchDbData = await FilesManager2.getData(table, id)

		return new Promise( (resolve, reject) => {
			
			let reader = new FileReader();
			if (file.content) {
				reader.readAsArrayBuffer(file.content)
				reader.addEventListener('load', (event) => {

					if (event.target) {
						let fileData = (event.target as FileReader).result;

						axios.request({
                            url: `${global.COUCHDB_URL}/${table}/${id}/${file.name}`,
                            method: 'put',
                            withCredentials: true,
                            headers: {
                                'Content-Type': file.type
                            },
                            params: {
                                rev: couchDbData._rev
                            },
                            data: fileData,
						})
						.then((result) => {
							if (result.status == 201) {
								resolve()
							}
							else {
								reject()
							}
						})
						.catch(reject)
					}


				} )
			}
			else {
				resolve()
			}
		} )
	}

	/**
	 * Permet de d'ajouter des pièces jointes dans une ligne de couchDB
	 * @param table 
	 * @param id 
	 * @param files 
	 */
	public static async addAttachments(table: string, id: string, files: Array<FilesManagerFile>) {
		for (let file of files) {
			await FilesManager2.addAttachment(table, id, file)
		}
	}


	/**
	 * Permet de supprimer un piece jointe dans une ligne d'une table(database) de couchDB
	 * TODO: passer par des API au lieu de couchDB
	 * @param table Le nom de la table
	 * @param id identifiant de la table
	 * @param file La métadonnée du fichier à supprimer
	 */
	public static async removeAttachment(table: string, id: string, file: FilesManagerFile) : Promise<boolean> {
		// REMARK: On est obligé de récupérer la revision à chaque fois, il y a donc pas de probleme de performance sur les requêtes serveur

		if (!file.name || file.name == "") {
			throw new Error("Name invalid : empty or undefined")
		}

		const data = await FilesManager2.getData(table, id)
		const response = await axios.request({
			url: `${global.COUCHDB_URL}/${table}/${data._id}/${file.name}`,
			method: 'delete',
			withCredentials: true,
			headers: {
				'ContentType': file.type
			},
			params: {
				rev: data._rev
			}
		})

		// return response && response.status && response.status == 200
		return response?.status === 200
	}

	/**
	 * Permet de supprimer une liste de pieces jointes
	 * @param table 
	 * @param id 
	 * @param files 
	 */
	public static async removeAttachments(table: string, id: string, files: Array<FilesManagerFile>) : Promise<void> {
		for (let file of files) {
			await FilesManager2.removeAttachment(table, id, file)
		}
	}

	/**
	 * Permet de vider une ligne de couchDB de tous les pièces jointes
	 * @param table 
	 * @param id 
	 */
	public static async emptyAttachments(table: string, id: string) {
		const files = await FilesManager2.getAttachments(table, id)
		await Promise.all(files.map(file => FilesManager2.removeAttachment(table, id, { length: file.length, name: file.name!, type: file.content_type })))
	}

	/**
	 * Permet de synchoniser les fichiers dans attachment, il detruit les fichiers qui ne sont plus utilisés
	 * @param table 
	 * @param id 
	 * @param files 
	 * @param excludePrefix Tableau contenant les prefix de fichiers qu'on ne doit pas synchroniser
	 */
	public static async synchronizeFiles(table: string, id: string, files : Array<FilesManagerFile>, excludePrefix: Array<string> = []) {
		const filesMetadata = await FilesManager2.getAttachments(table, id)

		// récupération de tous les fichiers qui ne se trouvent pas dans le nouveau tableau
		let filesToRemove = filesMetadata.filter( file => files.filter( f => f.name == file.name ).length < 1 )
		// Exclude File with prefix

		// FIXME: finir cette partie
		filesToRemove = filesToRemove.filter( file => excludePrefix.filter( prefix => file.name?.startsWith( prefix ) ).length < 1 )
		
		// On supprime les fichiers qui ne sont plus utilisés
		await FilesManager2.removeAttachments(table, id, filesToRemove.map(FilesManager2.convertToFileManagerFile))

		// On ajoute les nouvelles
		await FilesManager2.addAttachments(table, id, files)
	}

	/**
	 * Permet de convertir un element 
	 * @param element 
	 */
	public static toFile(_element: HTMLInputElement) : FilesManagerFile {
		// TODO: faire cette fonction
		return { length: 0, name: "", type: "", content: undefined } // TODO: enlever cette ligne
	}

	public static convertToDictionnary(tabFiles: Array<CouchDBAttachment>) : { [key: string]: CouchDBAttachment } {

		const result : { [filename: string]: CouchDBAttachment } = {}
		
		for (let file of tabFiles) {
			result[file.name!] = file
		}

		return result
	}

	public static convertToFileManagerFile(attachment: CouchDBAttachment) : FilesManagerFile {
		return { length: attachment.length, name: attachment.name || '', type: attachment.content_type }
	}
}

export default FilesManager2
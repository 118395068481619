import { Tab } from "@autoprog/core-client"

import AddEditGroupModal from '@libs/modals/settings/AddEditGroup'

import h from 'hyperscript'

import toastr from 'toastr'
import { Grid, GridOptions, ICellRendererParams } from 'ag-grid-community'
import 'ag-grid-enterprise'

import { Group } from '@js/types/settings'
import { french as agGridFrench } from '@libs/utils/agGrid'
import GroupsService from '@js/libs/services/GroupsService'
import UsersService from '@js/libs/services/UsersService'


class GroupsTab extends Tab {
	private gridOptions: GridOptions;
	constructor(el: HTMLElement) {
		super(el)

		this.gridOptions = agGridFrench({
			columnDefs: [
				{
					headerName: "Nom",
					field: "name",
					sort: 'asc'
				},
				{
					headerName: "Montant limite de commande",
					field: "orderLimit",
					width: 50
				},
				{
					headerName: "",
					field: "commands",
					width: 8 + 28 * 2,
					sortable: false,
					filter: false,
					suppressSizeToFit: true,
					pinned: 'right',
					cellRenderer: (params: ICellRendererParams) => {

						const div = h('div.d-flex.justify-content-end') as HTMLElement;

						const editButton = document.createElement('button')
						editButton.classList.add('btn', 'btn-xs', 'btn-info')
						editButton.setAttribute('permission', 'SETTINGS.GROUPS.EDIT')
						editButton.innerHTML = '<i class="icon icon-solid-pencil-alt"></i>'

						const deleteButton = document.createElement('button')
						deleteButton.classList.add('btn', 'btn-xs', 'btn-danger')
						deleteButton.setAttribute('confirmation', '');
						deleteButton.setAttribute('permission', 'SETTINGS.GROUPS.DELETE')
						deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"></i>'

						deleteButton.addEventListener2('click', async () => {
							await this.deleteGroup(params.data)
							params.api.applyTransaction({ remove: [params.data] })
						})

						editButton.addEventListener2('click', () => {
							const modal = new AddEditGroupModal(params.data._id);
							modal.open().then(() => this.loadData())
						})

						div.appendChild(editButton);
						div.appendChild(deleteButton);

						return div;
					}
				}
			],
			rowData: [],

			suppressDragLeaveHidesColumns: true,
			getContextMenuItems: () => [],
			defaultColDef: {
				floatingFilter: true,
				sortable: true,
				filter: 'agTextColumnFilter',
				floatingFilterComponentParams: { suppressFilterButton: true },
				lockPinned: true,
				suppressMenu: true,
			},
			onGridReady: (params) => {
				this.loadData()
				params.api.sizeColumnsToFit()
			}
		});

		const N_grid = document.querySelector('#groups-grid') as HTMLElement
		new Grid(N_grid, this.gridOptions)

		const N_addButton = document.querySelector('#button-add-groups') as HTMLButtonElement

		N_addButton.addEventListener2('click', () => {
			let modal = new AddEditGroupModal()
			modal.open().then(() => this.loadData())
		})

		const N_exportGroupButton = document.getElementById('button-export-groups') as HTMLButtonElement
		N_exportGroupButton.addEventListener2('click', () => {
			this.gridOptions.api && this.gridOptions.api.exportDataAsExcel({
				fileName: 'groupes.xslx'
			})
		})

	}

	private async loadData(): Promise<void> {
		try {
			const groups = await GroupsService.getInstance().getAll()
			this.gridOptions.api?.setRowData(groups)
		}
		catch (e) {
			toastr.error(`Erreur lors de la récupération des groupes`)
			console.error(e)
		}
	}

	private async deleteGroup(data: Group): Promise<void> {

		// Regarde s'il y a un groupe qui existe sur ua moins un utilisateur
		let foundSomeone = await UsersService.getInstance().hasAtLeastOneGroup(data._id)
		if (foundSomeone) {
			toastr.warning('Supression impossible. Le groupe est utilisé par un/des utilisateur(s)')
			return
		}

		try {
			await GroupsService.getInstance().delete(data._id)
			toastr.success('Groupe supprimé')
		}
		catch (e) {
			toastr.error('Erreur lors de la supression du groupe');
			console.error(e);
		}
	}

	public destructor(): void {

	}


}

export default GroupsTab;

import { GetContextMenuItemsParams, ICellRendererFunc, ProcessCellForExportParams, ProcessHeaderForExportParams, ExcelExportParams, CsvExportParams } from 'ag-grid-community'

import moment from 'moment'

import _ from "lodash"

class Export {

	private params: GetContextMenuItemsParams;

	private data: { [key: string]: any } = {};

	constructor(params: GetContextMenuItemsParams, data: { [key: string]: any } = {}) {

		this.params = params;

		this.data = data;

	}

	private processCellCallback(params: ProcessCellForExportParams) {

		let colDef = params.column.getColDef();

		let value = params.value || '';		

		const cellRendererParams = {
			data: (params.node || { data: {} }).data,
			colDef,
			...params,
			dataExport: this.data
		}

		if (colDef && colDef?.cellRendererParams?.innerRenderer && colDef.cellRenderer === 'agGroupCellRenderer'){
			value = (colDef.cellRendererParams.innerRenderer as ICellRendererFunc)(cellRendererParams)
		}
		else if (colDef && colDef.cellRenderer && typeof colDef.cellRenderer == 'function') {			
			value = (colDef.cellRenderer as ICellRendererFunc)(cellRendererParams)
		}
		
		return value
	}

	private processHeaderCallback(params: ProcessHeaderForExportParams) {

		let parent = params.column.getParent();
		let parentName = '';

		if (parent) {

			let parentDef = parent.getColGroupDef();

			if (parentDef && parentDef.headerName) {
				parentName = parentDef.headerName + ' ';
			}

		}

		return parentName + params.column.getColDef().headerName;

	}

	public exportExcel(params?: ExcelExportParams): void {

		params = params || {};

		if (this.params.api) {

			let fileName = params.fileName || 'Export du ' + moment().format('YYYY-MM-DD');

			this.params.api.exportDataAsExcel({
				fileName,
				processCellCallback: this.processCellCallback.bind(this),
				processHeaderCallback: this.processHeaderCallback.bind(this),
				...params
			});

		}

	}

	public exportCSV(params?: CsvExportParams): void {

		params = params || {};

		if (this.params.api) {

			let fileName = params.fileName || 'Export du ' + moment().format('YYYY-MM-DD');

			this.params.api.exportDataAsCsv({
				fileName,
				processCellCallback: this.processCellCallback.bind(this),
				processHeaderCallback: this.processHeaderCallback.bind(this),
				...params
			});

		}

	}

}

export default Export;

import axios from 'axios'
import { Color } from "@autoprog/core-client"
import _ from 'lodash'
import { calendarBdd } from '@js/types/calendar'
import { CheckListModel } from '@js/types/checklist'

class CalendarService {
	private static readonly instance = new CalendarService()

	private constructor() { }

	public static getInstance(): CalendarService {
		return CalendarService.instance
	}

	/**
	 * Permet de récupérer tous les calendriers
	 */
	public async getAll(): Promise<Array<calendarBdd>> {
		return axios.get(`/api/calendars`).then(response => response.data.data)
	}

	public async getByID(id: string) : Promise<calendarBdd> {
		return axios.get(`/api/calendars/${id}`).then(response => response.data.data)
	}

	public async getByIds(ids: Array<string>) : Promise<Array<calendarBdd>> {
		return axios.post(`/api/calendars/by-ids`, { ids }).then(response => response.data.data)
	}

	public async create(calendar: calendarBdd) : Promise<void> {
		await axios.post(`/api/calendars`, calendar)
	}

	public async update(calendar: calendarBdd) : Promise<void> {
		await axios.put(`/api/calendars`, calendar)
	}

	public async delete(id: string) : Promise<void> {
		await axios.get(`/api/calendars/tag-delete/${id}`)
	}

	public async updateAll(calendars: Array<calendarBdd>) : Promise<void> {
		await axios.put(`/api/calendars/update-all`, calendars)
	}

	public async createForChecklist(checklistModel: CheckListModel) : Promise<Array<calendarBdd>> {

		if (!checklistModel.repeat?.enable) {
			return []
		}

		const createNewCalendar = (id: string) : calendarBdd => ({
			_id: id,
			name: '',
			color: '',
			bgColor: '',
			dragBgColor: '',
			borderColor: '',
			events: [],
			repeat: [],
			owner: '',
			groups: {}
		})

		const sites = checklistModel.sites.map(site => ({ id: 'checklist-' + checklistModel._id + '_' + site, site }))
		const existedCalendars = await this.getByIds(sites.map(site => site.id))

		const unvailableIds = _.xor(sites.map(site => site.id), existedCalendars.map(calendar => calendar._id))
		const calendars = existedCalendars.concat(unvailableIds.map(createNewCalendar)).map(calendar => {
			calendar.name = checklistModel.name
			calendar.color = Color.getContrastedColor(checklistModel.color).toHex()
			calendar.bgColor = checklistModel.color
			calendar.dragBgColor = checklistModel.color
			calendar.borderColor = checklistModel.color
			calendar.locked = true
			calendar.sites = [sites.find(site => site.id === calendar._id)!.site]
			return calendar
		})

		await this.updateAll(calendars)

		return calendars
	}

}

export default CalendarService

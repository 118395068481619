import { GridOptions } from "ag-grid-community";

class AgGridStateSaver {

	private gridOptions: GridOptions
	private name: string;
	private identifier: string;
	private displayName: string;

	constructor(gridOptions: GridOptions, name: string, displayName: string) {
		this.gridOptions = gridOptions;
		this.name = name;
		this.identifier = 'StateSaver-' + this.name;
		this.displayName = displayName || '';
	}


	public save(): void {
		if (this.gridOptions.columnApi && this.gridOptions.api) {

			let columnGroupState = this.gridOptions.columnApi.getColumnGroupState();
			let columnState = this.gridOptions.columnApi.getColumnState();

			localStorage[this.identifier] = JSON.stringify({
				columnState,
				columnGroupState,
				filters: this.gridOptions.api.getFilterModel(),
				displayName: this.displayName,
			});
		}
	}

	public load(): void {
		if (this.gridOptions.columnApi && this.gridOptions.api) {
			if (localStorage[this.identifier]) {

				let obj = JSON.parse(localStorage[this.identifier]);

				this.gridOptions.columnApi.setColumnGroupState(obj.columnGroupState);
				this.gridOptions.api.setFilterModel(obj.filters);
				this.gridOptions.columnApi.applyColumnState({ state: obj.columnState })

			}
		}
	}


}

export default AgGridStateSaver;

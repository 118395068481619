import { global, Tab, LoggedUser } from "@autoprog/core-client"
import { Grid, GridOptions, ICellRendererParams } from 'ag-grid-community'
import { ExpenseReportService, UsersService } from "@js//libs/services"

import 'ag-grid-enterprise'
import moment, { Moment } from 'moment'
import toastr from 'toastr'
import 'daterangepicker'
import 'daterangepicker/daterangepicker.css'
import AddEditExpenseReportModal from '@libs/modals/humanRessources/AddEditExpenseReportModal'
import DetailExpenseReportModal from '@libs/modals/humanRessources/DetailExpenseReportModal'
import ExpenseReport from '@js/types/expenseReport'
import 'moment-ferie-fr'



import AgGridStateSaver from '@libs/agGrid/StateSaver2'

import drpC from '@libs/utils/daterangepickerConfig'
import { french as agGridFrench } from '@libs/utils/agGrid'

import Export from '@libs/agGrid/Export'
import TextFilterAccent from '@libs/agGrid/TextFilterAccent'

import _ from 'lodash'
import User from '@js/types/user'

interface TabRowExpenseReport extends ExpenseReport {
	userData: User
}

class TabExpenseReport extends Tab {

	private gridOptions: GridOptions = {}
	private N_addExpenseReport: HTMLButtonElement;
	private listeners: EventListenerCanceller[] = []
	private startDate: Moment = moment().startOf('month')
	private endDate: Moment = moment().endOf('month')
	private site: string
	private loggedUser: LoggedUser

	private stateAGrid!: AgGridStateSaver

	constructor(private el: HTMLElement) {
		super(el)
		this.site = global.SITE;
		this.loggedUser = LoggedUser.getInstance()

		this.N_addExpenseReport = this.el.querySelector('#button-add-expense-report') as HTMLButtonElement;

		this.listeners.push(this.N_addExpenseReport.addEventListener2('click', () => {
			const modal = new AddEditExpenseReportModal()
			modal.open().then(() => this.updateGrid())
		}))

		this.createGrid()

		$(this.el).find('#date-picker2').daterangepicker(drpC({
			startDate: this.startDate,
			endDate: this.endDate,
			showDropdowns: true,
			opens: "center",
		}), (start, end, _label) => {
			this.startDate = start.startOf('day')
			this.endDate = end.endOf('day')

			// Sauvegarder le mois
			this.saveDates()
			this.updateGrid()
		})

		this.loadDates()
	}

	private createGrid() {
		this.gridOptions = agGridFrench({
			columnDefs: [
				{
					headerName: "Utilisateur",
					field: "user",
					rowGroup: true,
					rowGroupIndex: 0,
					hide: true,
					valueGetter: (params) => {

						if (params.data && (params.data.userData.firstname || params.data.userData.lastname)) {
							return (params.data.userData.firstname || '') + ' ' + (params.data.userData.lastname || '')
						}
						return params.data.userData || '';
					},
					filter: 'agSetColumnFilter',
				}, {
					field: "Mois",
					colId: 'day',
					rowGroup: true,
					rowGroupIndex: 1,
					hide: true,
					valueGetter: function (params: any) {

						if (params.data) {
							let date = moment(params.data.date, 'x').locale('fr');
							return date.format('MMMM');
						}
					}
				}, {
					headerName: "Jour",
					field: "day",
					colId: 'date',
					valueGetter: function (params: any) {

						if (params.data) {
							let date = moment(params.data.date, 'x').locale('fr');
							return date.format('dddd');
						}
					}
				}, {
					field: "Semaine",
					colId: 'day',
					valueGetter: function (params: any) {

						if (params.data) {
							let date = moment(params.data.date, 'x').locale('fr');
							return date.format('w');
						}
					}
				}, {
					headerName: "Date", field: "date",
					valueGetter: function (params: any) {


						if (params.data) {
							let date = moment(params.data.date, 'x');
							return date.format('DD/MM/YYYY');
						}
					}
				}, {
					headerName: "Nature des dépenses",
					field: "natureOfExpend"
				},
				{
					headerName: "Montant €",
					field: "amount"
				}, {
					headerName: "Justification",
					field: "justification"
				}, {
					headerName: 'Controles',
					field: 'seeMore',
					filter: false,
					cellRenderer: (params: ICellRendererParams) => {
						let div = document.createElement('div')

						if (params.data) {
							const data = params.data as TabRowExpenseReport

							const N_info = document.createElement('button');

							N_info.classList.add('btn', 'btn-xs', 'btn-info');

							N_info.innerHTML = '<i class="icon icon-solid-eye"></i>';

							N_info.addEventListener('click', () => {

								const modal = new DetailExpenseReportModal(data._id);
								modal.open().then((edited) => {
									if (edited) {
										this.updateGrid()
									}
								})
							})

							const deleteButton = document.createElement('button');

							deleteButton.classList.add('btn', 'btn-xs', 'btn-danger');
							deleteButton.setAttribute('confirmation', '');

							if (data.userData._id == this.loggedUser.get('ID')) {
								deleteButton.setAttribute('permission', "HUMAN_RESSOURCES.EXPENSE_REPORT.DELETE");
							} else {
								deleteButton.setAttribute('permission', "HUMAN_RESSOURCES.EXPENSE_REPORT.DELETE_ALL_USERS");
							}

							deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"> </i>';

							deleteButton.addEventListener2('click', async () => {
								await this.deleteExpenseReport(data)
								params.api.applyTransaction({ remove: [data] })
							})

							const lockButton = document.createElement('button');

							lockButton.classList.add('btn', 'btn-xs', 'btn-dark');
							lockButton.setAttribute('confirmation', '');

							if (data.userData._id == this.loggedUser.get('ID')) {
								lockButton.setAttribute('permission', "HUMAN_RESSOURCES.EXPENSE_REPORT.LOCK");
							} else {
								lockButton.setAttribute('permission', "HUMAN_RESSOURCES.EXPENSE_REPORT.LOCK_ALL_USERS");
							}

							lockButton.innerHTML = '<i class="icon icon-solid-lock"> </i>';

							lockButton.addEventListener2('click', async () => {

								try {
									await this.lockExpenseReport(data._id)
									data.locked = true
									params.node.setData(data)
									params.api.redrawRows({ rowNodes: [params.node] })
									toastr.success("Modification bloquée")
								}
								catch (e) {
									console.error(e)
									toastr.error('Erreur de base de données')
								}

							});

							const unlockButton = document.createElement('button');

							unlockButton.classList.add('btn', 'btn-xs', 'btn-blue-grey');
							unlockButton.setAttribute('confirmation', '');

							if (data.userData._id == this.loggedUser.get('ID')) {
								unlockButton.setAttribute('permission', "HUMAN_RESSOURCES.EXPENSE_REPORT.LOCK");
							} else {
								unlockButton.setAttribute('permission', "HUMAN_RESSOURCES.EXPENSE_REPORT.LOCK_ALL_USERS");
							}

							unlockButton.innerHTML = '<i class="icon icon-solid-unlock-alt"> </i>';

							unlockButton.addEventListener2('click', async () => {

								try {
									await this.unlockExpenseReport(data._id)
									data.locked = false
									params.node.setData(data)
									params.api.redrawRows({ rowNodes: [params.node] })
									toastr.success("Modification débloquée")
								}
								catch (e) {
									console.error(e)
									toastr.error('Erreur de base de données')
								}

							})

							div.appendChild(N_info)

							if (params.data.locked) {
								div.appendChild(unlockButton)
							} else {
								div.appendChild(lockButton)
							}

							div.appendChild(deleteButton)
						}

						return div
					}

				}
			],

			// floatingFilter: true,
			groupSelectsChildren: true,
			suppressDragLeaveHidesColumns: true,
			rowSelection: 'multiple',

			getContextMenuItems: (params) => {

				let result = [];

				if (params.columnApi) {

					let allColumns = params.columnApi.getAllColumns();

					let columnKeys = _.map(allColumns, 'colId');

					columnKeys.pop();

					let exportAllExcel = {
						name: 'Exporter tous Excel',
						action: () => {
							let exp = new Export(params);
							exp.exportExcel({
								columnKeys
							});
						}
					};

					let exportAllCSV = {
						name: 'Exporter tous CSV',
						action: () => {
							let exp = new Export(params);
							exp.exportCSV({
								columnKeys
							});
						}
					};

					let exportSelectedCSV = {
						name: "Exporter selection CSV",
						action: () => {
							if (params.api) {
								let exp = new Export(params);
								exp.exportCSV({
									columnKeys,
									onlySelected: true
								});
							}
						}
					}

					let exportSelectedExcel = {
						name: "Exporter selection Excel",
						action: () => {
							let exp = new Export(params);
							exp.exportCSV({
								columnKeys,
								onlySelected: true
							});
						}
					}

					result.push(exportAllExcel);
					result.push(exportSelectedCSV);
					result.push(exportAllCSV);
					result.push(exportSelectedExcel);

				}

				if (params.api) {
					let selectedNodes = params.api.getSelectedNodes()
					let expenseReportIds: Array<string> = selectedNodes.map(rowNode => rowNode.data._id)

					let lockedModification = {
						name: "Bloquer les modifications",
						action: async () => {

							let promises = expenseReportIds.map(id => this.lockExpenseReport(id))
							await Promise.all(promises)
							selectedNodes.forEach(rowNode => {
								const data: TabRowExpenseReport = rowNode.data
								data.locked = true
								rowNode.setData(data)
								params.api && params.api.redrawRows({ rowNodes: [rowNode] })
							})

							toastr.success("Modification(s) bloquée(s)")
						}
					}

					let unlockedModification = {
						name: "Débloquer les modifications",
						action: async () => {
							let promises = expenseReportIds.map(id => this.unlockExpenseReport(id))
							await Promise.all(promises)
							selectedNodes.forEach(rowNode => {
								const data: TabRowExpenseReport = rowNode.data
								data.locked = false
								rowNode.setData(data)
								params.api && params.api.redrawRows({ rowNodes: [rowNode] })
							})

							toastr.success("Modification(s) bloquée(s)")
						}
					}

					// Affichage des boutons débloquer et bloquer
					const hasLocked = selectedNodes.some(rowNode => rowNode.data.locked)
					const hasUnlocked = selectedNodes.some(rowNode => !rowNode.data.locked)

					this.loggedUser.hasPermission('HUMAN_RESSOURCES.EXPENSE_REPORT.LOCK') && hasLocked && result.push(unlockedModification)
					this.loggedUser.hasPermission('HUMAN_RESSOURCES.EXPENSE_REPORT.LOCK') && hasUnlocked && result.push(lockedModification)
				}

				return result;

			},

			sideBar: {
				toolPanels: [{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {

						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnFilter: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}],
			},

			autoGroupColumnDef: {
				headerName: "Groupe",
				cellRenderer: 'agGroupCellRenderer',
				cellRendererParams: {
					checkbox: true
				}
			},

			defaultColDef: {
				floatingFilter: true,
				resizable: true,
				sortable: true,
				filter: 'agTextColumnFilter',
				suppressMenu: true,
				floatingFilterComponentParams: { suppressFilterButton: true },
				filterParams: {
					filterOptions: ['contains'],
					textCustomComparator: TextFilterAccent
				},
			},
			onGridReady: (_params) => this.updateGrid(),
			getRowClass: (params) => {
				if (params.data) {

					if (params.data.locked == true) {
						return 'locked'
					}
					let date = moment(params.data.date, 'x').locale('fr');

					if (date.isFerie()) {
						return 'publicHoliday'
					}
					if (date.isoWeekday() == 7) {
						return 'sunday'
					}


				}
				return ''
			}
		})

		let N_report = document.querySelector('#expense-report-grid')!
		new Grid(N_report as HTMLElement, this.gridOptions);
		this.stateAGrid = new AgGridStateSaver(this.gridOptions, global.SITE + '_expensereport', `Onglet Note de Frais ${global.SITE}`)
		this.stateAGrid.load()
		this.updateGrid()
	}

	public updateSelectSite(value: string) {
		this.site = value;
		this.stateAGrid.save()
		this.stateAGrid = new AgGridStateSaver(this.gridOptions, this.site + '_expensereport', `Onglet Note de Frais ${this.site}`)
		this.stateAGrid.load()
		this.loadDates()
		this.updateGrid()
	}

	private async getRowData(startDate: string, endDate: string): Promise<TabRowExpenseReport[]> {
		const requestUserId = this.loggedUser.hasPermission('HUMAN_RESSOURCES.EXPENSE_REPORT.SHOW_ALL_USERS') ? null : this.loggedUser.get('ID')
		let expenseReports = await ExpenseReportService.getInstance().getByDateUser(this.site, +startDate, +endDate, requestUserId)

		// On change notre expenseReport au format qui va bien pour expenseReport.user
		expenseReports = expenseReports.map(expenseReport => {
			expenseReport.user = typeof (expenseReport.user) === 'string' ? expenseReport.user : expenseReport.user._id!
			return expenseReport
		})

		// on récupère ensuite tous les Ids des utilisateurs pour notre recherche
		const userIds = expenseReports.map(expenseReport => expenseReport.user as string)

		const users = await UsersService.getInstance().getByIds(userIds)

		return expenseReports.map(expenseReport => ({
			...expenseReport,
			userData: users.find(user => user._id === expenseReport.user)!
		}))
	}

	public destructor() {

		this.stateAGrid.save();
		this.gridOptions.api && this.gridOptions.api.destroy()
		this.listeners.forEach(canceller => canceller())

		this.saveDates()
		let N_daterangepicker = document.querySelectorAll('.daterangepicker')

		N_daterangepicker.forEach((item) => {
			const parent = item.parentNode as HTMLElement
			parent.removeChild(item)
		})

	}

	private async deleteExpenseReport(data: ExpenseReport): Promise<void> {
		try {
			await ExpenseReportService.getInstance().delete(data._id)
			toastr.success('Note de frais supprimé')
		}
		catch (e) {
			toastr.error(`Erreur lors de la supression`)
			console.error(e);
		}
	}

	private async updateGrid(): Promise<void> {

		try {
			let rowData = await this.getRowData(this.startDate.format('x'), this.endDate.format('x'))
			this.gridOptions && this.gridOptions.api && this.gridOptions.columnApi && this.gridOptions.api.setRowData(rowData)
		}
		catch (e) {
			console.error(e)
			toastr.error("Erreur de base de données")
		}
	}

	private async lockExpenseReport(id: string): Promise<void> {
		await ExpenseReportService.getInstance().lock(id)
	}

	private async unlockExpenseReport(id: string): Promise<void> {
		await ExpenseReportService.getInstance().unlock(id)
	}

	/**
	 * Sauvegarde de la date
	 */
	public saveDates() {

		const identifier = this.site + '_humanressource_dates';
		const dates = { startDate: this.startDate.format('x'), endDate: this.endDate.format('x') };

		localStorage.setItem(identifier, JSON.stringify(dates));
	}

	/**
	 * Restauration de la date
	 */
	public loadDates() {
		const identifier = this.site + '_humanressource_dates'

		// Dans le cas une session de date a été crée
		if (localStorage.getItem(identifier)) {
			const dates = JSON.parse((localStorage.getItem(identifier) as string))

			this.startDate = moment(dates.startDate, 'x')
			this.endDate = moment(dates.endDate, 'x')

			// Erreur à laisser en typescript
			//@ts-ignore
			$(this.el).find('#date-picker2').data('daterangepicker').setStartDate(this.startDate.format('DD/MM/YYYY'));
			//@ts-ignore
			$(this.el).find('#date-picker2').data('daterangepicker').setEndDate(this.endDate.format('DD/MM/YYYY'));

		}

	}

}

export default TabExpenseReport;

import { Modal, Form } from "@autoprog/core-client"

import 'select2'
import toastr from 'toastr'

import { Grid, GridOptions, ICellRendererParams } from 'ag-grid-community'
import 'ag-grid-enterprise'

import T_addSite from '../../../../tpl/modals/settings/addSite.html'

import BDD from '@js/libs/BDD';
import { CouchDBData } from '@js/types/couchDB';

import unitList from '@libs/lists/unitList'
import { french as agGridFrench } from '@libs/utils/agGrid'
import FilesManager from '@libs/FilesManager'
import FilesManager2 from '@js/libs/FilesManager2'
import SitesService from '@js/libs/services/SitesService'

import _ from 'lodash'
import Site, { SiteStorage } from '@js/types/site'
import Storage from "@js/types/storage"

interface SiteStorageTmp extends SiteStorage {
	existing?: boolean
}

class AddEditSite extends Modal {

	private form?: Form;
	private gridOptions: GridOptions;

	private listStorage: { [key: string]: string } = {};
	private filesManagerLogo: FilesManager = new FilesManager(1, 'image/*')
	private filesManagerPJ: FilesManager = new FilesManager()
	private filesManagerRIB: FilesManager = new FilesManager()
	private filesManagerKbis: FilesManager = new FilesManager()

	/**
	 * Constructeur
	 * @param id Id du site en question
	 */
	constructor(private id?: string) {

		super({
			tpl: T_addSite,
			backdrop: 'static',
			keyboard: false
		})

		// WARNING: A mettre en base de donnees
		const types: { [key: string]: string } = {
			box: 'Case',
			tank: 'Cuve',
			lagoon: 'Lagune',
			desk: 'Bureau',
			liquefaction_plant: 'Installation Liquefaction',
			building: 'Bâtiment',
			other: 'Autre'
		}

		this.gridOptions = agGridFrench({
			columnDefs: [
				{
					headerName: 'Nom', 
					field: 'name'
				},
				{
					headerName: 'Type',
					field: 'type',
					width: 100,
					suppressSizeToFit: true,
					cellEditor: 'agRichSelectCellEditor',
					cellEditorParams: {
						values: _.keys(types),
						cellRenderer: (params: ICellRendererParams) => types[params.value] || ''
					},
					cellRenderer: (params) => types[params.value] || ''
				},
				{
					headerName: 'Unité',
					field: 'unit',
					cellEditor: 'agRichSelectCellEditor',
					width: 100,
					suppressSizeToFit: true,
					cellEditorParams: {
						values: _.keys(unitList),
						cellRenderer: (params: ICellRendererParams) => unitList[params.value]?.name || ''
					},
					cellRenderer: (params) => unitList[params.value]?.name
				},
				{
					headerName: 'Capacité',
					field: 'capacity',
					suppressSizeToFit: true,
					width: 100,
				},
				{
					headerName: 'Supprimé',
					field: 'deleted',
					hide: true,
					valueGetter: (params) => params.data.deleted || false
				},
				{
					headerName: '',
					field: 'controls',
					filter: false,
					width: 30,
					suppressSizeToFit: true,
					cellRenderer: (params: ICellRendererParams) => {
						const div = document.createElement('div') as HTMLElement;
						const N_suppressLine = document.createElement('button') as HTMLButtonElement;

						N_suppressLine.innerHTML = `<span aria-hidden="true">&times;</span>`;
						N_suppressLine.setAttribute('type', 'button');
						N_suppressLine.setAttribute('confirmation', '');
						N_suppressLine.classList.add('close');

						N_suppressLine.addEventListener('click', () => {

							if (params.node.data.existing) {
								params.node.data.deleted = true
								params.api.applyTransaction({ update: [params.node.data] })
							}
							else {
								params.api.applyTransaction({ remove: [params.node.data] });
							}

							Reflect.deleteProperty(this.listStorage, params.node.data.id)
							this.drawSelectDigestat()

						})

						div.appendChild(N_suppressLine)

						return div
					}
				}
			],
			rowData: [],
			suppressDragLeaveHidesColumns: true,
			getContextMenuItems: () => [],
			rowClassRules: {
				'ag-row-error': (params) => !!params.data.error
			},
			onCellEditingStopped: (params) => {
				params.api.redrawRows()

				if ((params.colDef.field == 'name' || params.colDef.field == 'type') && params.data.type == 'tank') {
					this.listStorage[params.data.id] = params.data.name
					this.drawSelectDigestat()
				}

			},
			defaultColDef: {
				floatingFilter: true,
				filter: 'agTextColumnFilter',
				floatingFilterComponentParams: {
					suppressFilterButton: true,
					newRowsAction: 'keep'
				},
				lockPinned: true,
				suppressMenu: true,
				editable: true,
				sortable: true,
				resizable: true,
			},
			onGridReady: (params) => params.api.sizeColumnsToFit()
		})

		this.on('opened', async () => {

			const N_RCS = this.element.querySelector("#RCS") as HTMLInputElement
			const N_NafCode = this.element.querySelector("#NafCode") as HTMLInputElement
			const N_Siret = this.element.querySelector("#siret") as HTMLInputElement
			const N_VAT = this.element.querySelector("#tvaNumber") as HTMLInputElement

			const createEvent = (element: HTMLInputElement, regex: RegExp) => {
				element.addEventListener('keyup', () => {
					const N_nextSibling = element.nextElementSibling as HTMLElement
					N_nextSibling.classList.add('d-none')
					_.isEqual(!_.isEmpty(element.value) && !regex.test(element.value), true) && N_nextSibling.classList.remove('d-none')
				})
			}

			createEvent(N_RCS, new RegExp('^[0-9]{3}\ [0-9]{3}\ [0-9]{3}$'))
			createEvent(N_NafCode, /^\d{4}\s\w$/)
			createEvent(N_Siret, /^\d{14}$/)
			createEvent(N_VAT, /^FR\d{11}$/)

			setTimeout(async () => {

				// Logo
				const N_logo = this.element.querySelector("#logo") as HTMLDivElement
				N_logo.appendChild(this.filesManagerLogo.getNode())

				// Les pièces jointes
				const N_attachments = this.element.querySelector("#attachments") as HTMLDivElement
				N_attachments.appendChild(this.filesManagerPJ.getNode())

				// Le RIB
				const N_Rib = this.element.querySelector("#RIB") as HTMLDivElement
				N_Rib.appendChild(this.filesManagerRIB.getNode())

				// Le Kbis
				const N_kbis = this.element.querySelector("#kbis") as HTMLDivElement
				N_kbis.appendChild(this.filesManagerKbis.getNode())

				const N_form = this.element.querySelector('#add-site-form') as HTMLFormElement
				this.form = new Form(N_form)

				const N_grid = document.querySelector('#storages-grid') as HTMLElement;
				const N_addStorageLineBtn = this.element.querySelector('#add-storage-line-btn') as HTMLButtonElement;

				new Grid(N_grid, this.gridOptions)

				N_addStorageLineBtn.addEventListener2('click', () => {
					this.gridOptions.api?.applyTransaction({ add: [{}] })
				})

				const N_saveBtn = this.element.querySelector('#save-site-button') as HTMLButtonElement;
				N_saveBtn.addEventListener2('click', () => this.save())

				this.id && this.load()

			}, 100)
		})
	}

	private getStorages(): Array<Storage> {
		const result: Array<Storage> = []
		this.gridOptions.api?.forEachNode(node => {
			const data = _.omit(node.data, 'existing', 'error') as Storage
			data.capacity = +data.capacity * unitList[data.unit].coeficient
			data.id = !_.isEmpty(data.id) ? data.id : BDD.generateID()
			result.push(data)
		})
		return result
	}

	private checkValidity(): boolean {

		const RCS = this.form?.getDataByName<string>('RCS') as string
		const NafCode = this.form?.getDataByName<string>('NafCode') as string
		const siret = this.form?.getDataByName<string>('siret') as string
		const tvaNumber = this.form?.getDataByName<string>('tvaNumber') as string

		let hasError = false
		// RCS existe, RCS != "", regex false
		if (!_.isEmpty(RCS) && !(new RegExp('^[0-9]{3}\ [0-9]{3}\ [0-9]{3}$')).test(RCS)) {
			toastr.error('RCS ne respecte pas le format suivant : 999 999 999')
			hasError = true
		}

		// Naf Code existe, NafCode != "", regex false
		else if (!_.isEmpty(NafCode) && !/^\d{4}\s\w$/.test(NafCode)) {
			toastr.error('Code NAf ne respecte pas le format suivant : 7588 Z');
			hasError = true
		}

		else if (!_.isEmpty(siret) && !/^\d{14}$/.test(siret)) {
			toastr.error("Le numero de Siret n'est pas au bon format")
			hasError = true
		}

		else if (!_.isEmpty(tvaNumber) && !/^FR\d{11}$/.test(tvaNumber)) {
			toastr.error("TVA Format incorrect (exemple: FR12345678912)")
			hasError = true
		}
		
		this.gridOptions.api?.forEachNode(node => {
			const hasData = node.data.name && node.data.type && node.data.unit && node.data.capacity

			if (!hasData) {
				hasError = true
				node.data.error = true
			}

			node.setData(node.data)
		})

		return !!this.form?.checkValidity() && !hasError
	}

	private async save() {
		if (this.checkValidity()) {

			const data = this.form!.getData()

			data._id = data._id || BDD.generateID()
			data.storages = this.getStorages()

			try {

				const addOrUpdate = this.id ? SitesService.getInstance().update : SitesService.getInstance().create
				await addOrUpdate(data as Site)

				// Mise à jour des pièces jointes
				let filesAttachments = this.filesManagerPJ.getArrayFiles()
				await SitesService.getInstance().syncAttachments((data as CouchDBData)._id, filesAttachments)

				// Mise a jour du RIB
				let fileRib = this.filesManagerRIB.getArrayFiles()
				await SitesService.getInstance().syncRIBAttachment((data as CouchDBData)._id, fileRib)

				// Mise à jour du Kbis 
				let fileKbis = this.filesManagerKbis.getArrayFiles()
				await SitesService.getInstance().syncKbisAttachment((data as CouchDBData)._id, fileKbis)

				// Mise à jour du logo 
				let fileLogo = this.filesManagerLogo.getArrayFiles()
				await SitesService.getInstance().syncLogoAttachment((data as CouchDBData)._id, fileLogo)

				toastr.success('Site sauvegardé')
				this.resolve()
			}
			catch (e) {
				toastr.error('Erreur de base de données')
				console.error(e)
				this.reject()
			}

		}
	}


	private async load() {
		const site = await SitesService.getInstance().getByID(this.id!)

		const storages: Array<SiteStorageTmp> = site.storages;

		for (let i in storages) {
			storages[i].existing = true
			storages[i].capacity = storages[i].capacity / unitList[storages[i].unit].coeficient

			if (storages[i].type == 'tank') {
				this.listStorage[storages[i].id] = storages[i].name
			}
		}

		this.drawSelectDigestat()

		this.form?.setData(site as any)

		// récupération des pièces jointes
		const attachments = await SitesService.getInstance().getAttachments(site._id)
		// Les pièces jointes
		if (attachments.length > 0) {
			let attachmentsDictionnary = FilesManager2.convertToDictionnary(attachments)
			this.filesManagerPJ.setFiles(attachmentsDictionnary)
		}


		// RIB
		const ribAttachments = await SitesService.getInstance().getRibs(site._id)
		if (ribAttachments.length > 0) {
			let attachmentsDictionnary = FilesManager2.convertToDictionnary(ribAttachments)
			this.filesManagerRIB.setFiles(attachmentsDictionnary)
		}

		// Kbis
		const kbisAttachment = await SitesService.getInstance().getKbis(site._id)
		if (kbisAttachment.length > 0) {
			let attachmentsDictionnary = FilesManager2.convertToDictionnary(kbisAttachment)
			this.filesManagerKbis.setFiles(attachmentsDictionnary)
		}

		const logoAttachment = await SitesService.getInstance().getLogo(site._id)
		if (logoAttachment.length > 0) {
			let attachmentsDictionnary = FilesManager2.convertToDictionnary(logoAttachment)
			this.filesManagerLogo.setFiles(attachmentsDictionnary)
		}

		this.gridOptions.api?.setRowData(site.storages)

		this.gridOptions.api?.getFilterInstance('deleted')?.setModel({
			type: 'contains',
			filter: 'false'
		})

		this.gridOptions.api?.onFilterChanged()

	}


	private drawSelectDigestat() {

		let N_select = this.element.querySelector('[name="defaultOutputDigestat"]') as HTMLSelectElement;

		let value = N_select.value;

		N_select.innerHTML = '';

		for (let key in this.listStorage) {

			let N_option = new Option(this.listStorage[key], key, value == key, value == key);
			N_select.append(N_option);

		}

	}


}

export default AddEditSite;

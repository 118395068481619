///<reference path="types/moment-ferie-fr.d.ts" />
///<reference path="types/tui-date-picker.d.ts" />

import $ from 'jquery'
import _ from 'lodash'
import Hammer from 'hammerjs'
import h from 'hyperscript'

// TODO: supprimer jquery dans le futur
declare global {
	interface Window {
		$: typeof $,
		jQuery: typeof $
	}
}

//Il serait temps de payer une licence Ag-grid
const ce = console.error;
console.error = (...args: unknown[]) => {
	if (args[0] && typeof args[0] == 'string') {
		if (!args[0].startsWith('*')) {
			ce(...args);
		}
	} else {
		ce(...args);
	}
}

import '../css/letang.scss'

import { App, Router, global, LoggedUser, Alert, toaster, DOM, Loading, LoginModule } from '@autoprog/core-client'

import TabsExtended from '@libs/TabsExtended'

import T_loginFooter from '../tpl/blocks/login/footer.html'
import T_loginHeader from '../tpl/blocks/login/header.html'

import '@autoprog/theme/dist/theme.css'
import '@autoprog/theme/dist/theme.js'
import '@autoprog/icons'

import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.min.css'
import 'ag-grid-community/dist/styles/agGridBalhamFont.min.css'


/* -------------------------------- Services -------------------------------- */
import { AnalyticsService, SubAnalyticsService, AccountingAccountsService, VATService, HoursCategories, UnitsService, SitesService, ApplicationService, UsersService } from "./libs/services"

/* -------------------------------------------------------------------------- */
/*                              Controller / Vue                              */
/* -------------------------------------------------------------------------- */

import C_Settings from './controllers/Settings'
import T_Settings from '@tpl/settings.html'

import C_Calendar from './controllers/Calendar'
import T_Calendar from '@tpl/calendar.html'

import C_Exploitation from './controllers/Exploitation'
import T_Exploitation from '@tpl/exploitation.html'

import C_Tablet from './controllers/Tablet'
import T_Tablet from '@tpl/tablet.html'

import C_HumanRessources from './controllers/HumanRessources'
import T_HumanRessources from '@tpl/humanRessources.html'

import C_WeighBridge from './controllers/WeighBridge'
import T_WeighBridge from '@tpl/weighBridge.html'

import C_Products from './controllers/Products'
import T_ProductTier from '@tpl/productTier.html'

import C_Orders from './controllers/Orders'
import T_Orders from '@tpl/orders.html'

/* -------------------------------------------------------------------------- */
/*                            Éléments / Attributs                            */
/* -------------------------------------------------------------------------- */

import SiteSelect from './libs/customElements/SiteSelect'
import ApCheckboxSelect from './libs/customElements/ApCheckboxSelect'
import TableEditor from './libs/customElements/checklists/TableEditor'
import TableChecklist from './libs/customElements/checklists/TableChecklist'
import ServerStatusIndicatorElement from './libs/customElements/ServerStatusIndicator'

import HideIfAllowSites from './libs/customAttributes/HideIfAllowSitesAttribute'

/* -------------------------------------------------------------------------- */
/*                            Modal				                              */
/* -------------------------------------------------------------------------- */

import PasswordModal from '@libs/modals/password'
import CE_Tiers from './libs/printers/customElements/PrintTiersBlock'


/**
 * @description Permet d'actualiser les variables globales en fonction de l'utilisateur connecté
 */
const updateAfterLogin = async () => {

	if (loggedUser.isLogged) {
		if (!global.SITE) {
			global.SITE = UsersService.getInstance().getAffectedSite()
			global.DESIGN = global.SITE
		}

		// global.ALLOW_SITES = loggedUser.get('allowSites')
		global.ALLOW_SITES = UsersService.getInstance().getAllowSites()

		//on charge les services apres la connexion utilisateur par les routes api sont protégées.
		await AnalyticsService.getInstance().load()
		await SubAnalyticsService.getInstance().load()
		await AccountingAccountsService.getInstance().load()
		await VATService.getInstance().load()
		await HoursCategories.getInstance().load()
		await UnitsService.getInstance().load()

		//Creation de la zone utilisateur de l'aside

		const firstname = loggedUser.get('firstname')
		const lastname = loggedUser.get('lastname')

		const N_asideTop = document.querySelector('#aside-top-content') as HTMLElement
		N_asideTop.innerHTML = '';
		N_asideTop.appendChild(h('div#aside-user',
			h('img.aside-profile-picture', { src: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FscXVlXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNjAwIDYwMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNjAwIDYwMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0M2QzZDNjt9DQoJLnN0MXtmaWxsOm5vbmU7c3Ryb2tlOiNDNkM2QzY7c3Ryb2tlLXdpZHRoOjMwO30NCjwvc3R5bGU+DQo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNjAwLDI5OS43YzAsNzkuNC0zMC44LDE1MS41LTgxLjIsMjA1LjFDNDk1LjQsNDM5LDQ0My43LDM4Ny40LDM3Ny45LDM2NGMtMjQuNC04LjctNDkuMy0xMy03My44LTEzLjQNCgljNzAuMS0yLDEyNi4zLTU5LjQsMTI2LjMtMTMwYzAtNzEuOC01OC4yLTEzMC4xLTEzMC4xLTEzMC4xcy0xMzAuMSw1OC4yLTEzMC4xLDEzMC4xYzAsNzAuNiw1Ni4zLDEyOC4xLDEyNi41LDEzMA0KCWMtOTQsMS41LTE4MS42LDYwLjUtMjE0LjksMTU0LjNDMzEuNCw0NTEuMiwwLjYsMzc5LjEsMC42LDI5OS43QzAuNiwxMzQuMiwxMzQuOCwwLDMwMC4zLDBTNjAwLDEzNC4yLDYwMCwyOTkuN3oiLz4NCjx0aXRsZT5BYnN0cmFjdCB1c2VyIGljb248L3RpdGxlPg0KPHBhdGggY2xhc3M9InN0MSIgZD0iTTQ5My4yLDQ4MS40QzQ5My4yLDQ4MS40LDQ5My4yLDQ4MS40LDQ5My4yLDQ4MS40Ii8+DQo8cGF0aCBjbGFzcz0ic3QxIiBkPSJNMTA2LjgsNDgxLjRDMTA2LjgsNDgxLjQsMTA2LjgsNDgxLjQsMTA2LjgsNDgxLjQiLz4NCjwvc3ZnPg0K' }),
			h('div.name', firstname, ' ', lastname),
			h('div.passwordchanges',
				h('a#changepassword', "Modifier Mot de passe")
			)
		))

		// Gestion du changement de mot de passe

		const N_changepassword = N_asideTop.querySelector('#changepassword') as HTMLElement

		N_changepassword.addEventListener('click', async () => {
			const loggedUser = LoggedUser.getInstance()
			if (!!loggedUser.get('isSuperAdmin')) {
				Alert.error("Erreur", "Pour des raisons de sécurité, il est impossible de changer le mot de passe d'Autoprog.")
				return
			}
			const passwordModal = new PasswordModal()
			passwordModal.open()
		})
	}

}

const loggedUser = LoggedUser.getInstance();

loggedUser.on(['login', 'change'], _.debounce(() => {
	updateAfterLogin()
}, 10))

//Surchage de la page de connexion
LoginModule.loginPageCardHeader = T_loginHeader
LoginModule.loginPageCardFooter = T_loginFooter

CE_Tiers.register()
TableEditor.register()
TableChecklist.register()
ServerStatusIndicatorElement.register()

const app = new App({ disableConnectionLostModal: true });

(async () => {

	await app.preInit()

	global.PRIMARY_COLOR = 'black'
	global.SECONDARY_COLOR = global.TERTIARY_COLOR = 'green'
	global.NO_NAVBAR = true;
	global.NO_FOOTER = true;

	global.SITE = '';
	global.DESIGN = null;
	global.ALLOW_SITES = [];
	global.COUCHDB_URL = `${window.location.protocol}//${window.location.host}/db/` // TODO: l'enlever dans le futur

	await app.init()

	// REMARK: await est important pour recuperer toutes les donnees avant la creation des routes
	await updateAfterLogin()
	/* -------------------------------------------------------------------------- */
	/*                                   Routes                                   */
	/* -------------------------------------------------------------------------- */

	const router = Router.getInstance()

	router.addRoute({
		url: '/humanRessources',
		controller: C_HumanRessources,
		template: T_HumanRessources,
		default: true,
		permission: 'HUMAN_RESSOURCES.SHOW',
		requireValidUser: true
	})

	router.addRoute({
		url: '/weighBridge',
		controller: C_WeighBridge,
		template: T_WeighBridge,
		default: !loggedUser.hasPermission('HUMAN_RESSOURCES.SHOW')
			&& loggedUser.hasPermission('WEIGHBRIDGE.SHOW'),
		permission: 'WEIGHBRIDGE.SHOW',
		requireValidUser: true
	})

	router.addRoute({
		url: '/products',
		controller: C_Products,
		template: T_ProductTier,
		default: !loggedUser.hasPermission('HUMAN_RESSOURCES.SHOW')
			&& !loggedUser.hasPermission('WEIGHBRIDGE.SHOW')
			&& loggedUser.hasPermission('PRODUCTS_THIRDPARTIES.SHOW'),
		permission: 'PRODUCTS_THIRDPARTIES.SHOW',
		requireValidUser: true
	})

	router.addRoute({
		url: '/orders',
		controller: C_Orders,
		template: T_Orders,
		default: !loggedUser.hasPermission('HUMAN_RESSOURCES.SHOW')
			&& !loggedUser.hasPermission('WEIGHBRIDGE.SHOW')
			&& !loggedUser.hasPermission('PRODUCTS_THIRDPARTIES.SHOW')
			&& loggedUser.hasPermission('ORDERS.SHOW'),
		permission: 'ORDERS.SHOW',
		requireValidUser: true,
	})


	router.addRoute({
		url: '/calendar',
		controller: C_Calendar,
		template: T_Calendar,
		default: !loggedUser.hasPermission('HUMAN_RESSOURCES.SHOW')
			&& !loggedUser.hasPermission('WEIGHBRIDGE.SHOW')
			&& !loggedUser.hasPermission('PRODUCTS_THIRDPARTIES.SHOW')
			&& !loggedUser.hasPermission('ORDERS.SHOW')
			&& loggedUser.hasPermission('CALENDAR.SHOW'),
		permission: 'CALENDAR.SHOW',
		requireValidUser: true
	})

	router.addRoute({
		url: '/exploitation',
		controller: C_Exploitation,
		template: T_Exploitation,
		default: !loggedUser.hasPermission('HUMAN_RESSOURCES.SHOW')
			&& !loggedUser.hasPermission('WEIGHBRIDGE.SHOW')
			&& !loggedUser.hasPermission('PRODUCTS_THIRDPARTIES.SHOW')
			&& !loggedUser.hasPermission('ORDERS.SHOW')
			&& !loggedUser.hasPermission('CALENDAR.SHOW')
			&& loggedUser.hasPermission('EXPLOITATION.SHOW'),
		permission: 'EXPLOITATION.SHOW',
		requireValidUser: true
	})

	router.addRoute({
		url: '/settings',
		controller: C_Settings,
		template: T_Settings,
		default: !loggedUser.hasPermission('HUMAN_RESSOURCES.SHOW')
			&& !loggedUser.hasPermission('WEIGHBRIDGE.SHOW')
			&& !loggedUser.hasPermission('PRODUCTS_THIRDPARTIES.SHOW')
			&& !loggedUser.hasPermission('ORDERS.SHOW')
			&& !loggedUser.hasPermission('CALENDAR.SHOW')
			&& !loggedUser.hasPermission('EXPLOITATION.SHOW')
			&& loggedUser.hasPermission('SETTINGS.SHOW'),
		permission: 'SETTINGS.SHOW',
		requireValidUser: true
	})

	router.addRoute({
		url: '/tablet',
		controller: C_Tablet,
		template: T_Tablet,
		default: false,
		requireValidUser: true
	})

	DOM.registerElement(SiteSelect)
	HideIfAllowSites.register()
	ApCheckboxSelect.register()

	TabsExtended.navTemplate = '<button tab-link="${tab.id}" class="btn btn-tab btn-grey-300">${tab.name}</button>'

	const N_app = document.querySelector('#app') as HTMLElement
	const N_aside = document.querySelector('#aside') as HTMLElement

	if (global.IS_MOBILE) {
		const hammertime = new Hammer(N_app);
		hammertime.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL })

		let allowSwipe = false;
		N_app.addEventListener2('touchstart', (e: TouchEvent) => {
			allowSwipe = (e.touches[0] && e.touches[0].clientX < 30)
		})

		hammertime.on('swipe', (ev) => {
			if (ev.direction == Hammer.DIRECTION_RIGHT && allowSwipe) {
				N_aside.classList.add('active')
			}

			if (ev.direction == Hammer.DIRECTION_LEFT) {
				N_aside.classList.remove('active')
			}

		})

		router.on('route.change', () => N_aside.classList.remove('active'))
	}


	try {
		const site = await SitesService.getInstance().getDefault()
		global.SERVER_SITE = site._id
	}
	catch (e) {
		Alert.error('Erreur de chargement du site');
		throw e;
	}

	try {
		// Gestion du numero de version
		const informations = await ApplicationService.getInstance().getAll()
		N_aside.appendChild(h('span.app-version', informations.client.version))

		if (['test', 'development'].includes(informations.client.environment)) {
			global.SECONDARY_COLOR = global.TERTIARY_COLOR = 'indigo'
			toaster.warning('Environement de test', '', { timeOut: 0, extendedTimeOut: 0 })
		}
	}
	catch {
		toaster.error(`Chargement des informations l'application impossible`)
	}

	//---------------------------------------------
	Loading.setTitle('Chargements des référenciels')

	//Chargement des analytiques
	Loading.setStep('Analytique')

	await app.postInit()

}) ()

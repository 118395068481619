import { SocketIO, Form, global, LoggedUser } from "@autoprog/core-client"

import T_cardTransaction from '@tpl/blocks/weighbridge/cardTransactions.html'
import toastr from 'toastr'
import moment from 'moment'
import AddEditInputTransaction from '@js/libs/modals/weighBridge/AddEditInputTransaction'

import _ from 'lodash'
import { SiteStorage } from '@js/types/site'

import DeliveryNote from '@js/types/delivery-note'
import Order from '@js/types/order'
import Tier from '@js/types/Tiers'

import TypeDataEvent from '@js/libs/lists/TypeDataEvent'

import { SitesService, DeliveryNotesService, OrdersService, TiersService } from "@js/libs/services"

import StateTransaction from '@js/libs/lists/StateTransactionEnum'
import EventsManager, { EventsManagerEvent } from '@js/libs/EventsManager'

interface CartTransactionData extends DeliveryNote {
	orderData: Order,
	customerData: Tier,
	providerData: Tier
	dateString: string
	storageName: string
}

class CardTransaction {
	private el: HTMLElement
	private form?: Form
	private manualMode: boolean = false
	private data?: CartTransactionData
	private timeoutSave: number = 0

	private weights = {
		input: {
			weight: 0,
			dsd: ''
		},
		output: {
			weight: 0,
			dsd: ''
		}
	}

	public constructor(private id: string) {

		this.el = document.createElement('div')
		this.el.className = 'col-lg-4 col-md-6 col-sm-12'
		this.el.innerHTML = T_cardTransaction

		this.init()
	}


	private async init(): Promise<void> {
		const N_input = {
			input: this.el.querySelector('#inputWeight') as HTMLInputElement,
			button: this.el.querySelector('#inputWeightBtn') as HTMLButtonElement
		}

		const N_ouput = {
			input: this.el.querySelector('#outputWeight') as HTMLInputElement,
			button: this.el.querySelector('#outputWeightBtn') as HTMLButtonElement
		}

		const N_title = this.el.querySelector('#cardTitle') as HTMLElement
		const N_netWeight = this.el.querySelector('#netWeight') as HTMLInputElement
		const N_manualModeBtn = this.el.querySelector('#manualModeBtn') as HTMLButtonElement
		const N_autoModeBtn = this.el.querySelector('#autoModeBtn') as HTMLButtonElement
		const N_form = this.el.querySelector('#form-transactions') as HTMLFormElement

		const N_saveBtn = this.el.querySelector("#saveBtn") as HTMLButtonElement

		this.form = new Form(N_form)

		const io = SocketIO.getInstance()

		let deliveryNote = await this.getData(this.id)
		let storages = await this.getStorages()

		this.data = deliveryNote;

		const storage = _.find(storages, { id: deliveryNote.products[0].storage })

		if (storage) {
			deliveryNote.storageName = storage.name
		}

		deliveryNote.dateString = moment(deliveryNote.date, 'x').format('DD/MM/YYYY')

		const transportType: { [key: string]: string } = {
			truck: 'Camion',
			tractor: 'Tracteur'
		};

		deliveryNote.transportType = transportType[deliveryNote.transportType] || deliveryNote.transportType;

		N_title && (N_title.innerHTML = `N° ${deliveryNote.deliveryNumber}`);

		if (this.form) {
			this.form.setData(deliveryNote as any)
		}

		if (deliveryNote.weights) {

			this.weights = deliveryNote.weights;

			if (deliveryNote.weights.input.dsd == 'Aucun') {
				this.manualMode = true;

				N_manualModeBtn.classList.add('active');
				N_autoModeBtn.classList.remove('active');

				N_input.input.disabled = false;
				N_input.button.disabled = true;

				N_ouput.input.disabled = false;
				N_ouput.button.disabled = true;

			}

			if (this.weights.input.weight && this.weights.output.weight) {
				if ((this.weights.input.weight - this.weights.output.weight) < 0) {
					let N_card = this.el.querySelector('.card') as HTMLElement
					N_card.classList.add('bg-red-200');
					N_saveBtn.disabled = true;
				}
			}

		}

		//Event pesée manuel/auto
		N_manualModeBtn.addEventListener2('click', () => {

			if (!this.manualMode) {

				N_manualModeBtn.classList.add('active');
				N_autoModeBtn.classList.remove('active');

				N_input.input.disabled = false;
				N_input.button.disabled = true;

				N_ouput.input.disabled = false;
				N_ouput.button.disabled = true;

				this.manualMode = true;

			}

		});

		//Event pesée manuel/auto
		N_autoModeBtn.addEventListener2('click', () => {

			if (this.manualMode) {

				N_manualModeBtn.classList.remove('active');
				N_autoModeBtn.classList.add('active');

				this.manualMode = false;

				N_input.input.disabled = true;
				N_input.button.disabled = false;

				N_ouput.input.disabled = true;
				N_ouput.button.disabled = false;

			}

		});

		//Event au clique sur bouton pesée entrée
		N_input.button.addEventListener2('click', () => {

			io.emit('weighbridge.getFullWeight', 'PONT1')

			io.once('weighbridge.PONT1.fullWeight', (data) => {

				this.weights.input = data
				N_input.input.valueAsNumber = data.weight
				N_input.input.dispatchEvent(new Event('input'))

			})

		})

		//Event au clique sur bouton pesée sortie
		N_ouput.button.addEventListener2('click', () => {

			io.emit('weighbridge.getFullWeight', 'PONT1');

			io.once('weighbridge.PONT1.fullWeight', (data) => {

				this.weights.output = data;
				N_ouput.input.valueAsNumber = data.weight;
				N_ouput.input.dispatchEvent(new Event('input'));

			});

		});

		//Event changement pesée entrée
		N_input.input.addEventListener2('input', () => {

			if (this.manualMode) {

				this.weights.input = {
					dsd: 'Aucun',
					weight: N_input.input.valueAsNumber,
				};

			}

			let N_card = this.el.querySelector('.card') as HTMLElement;
			if (this.weights.input.weight && this.weights.output.weight) {
				N_netWeight.valueAsNumber = this.weights.input.weight - this.weights.output.weight;

				if (N_netWeight.valueAsNumber < 0 || N_netWeight.valueAsNumber > 50000) {
					N_card.classList.add('bg-red-200');
					N_saveBtn.disabled = true;
				} else {
					N_saveBtn.disabled = false;
					N_card.classList.remove('bg-red-200');
				}

			} else {
				N_netWeight.valueAsNumber = 0;
				N_card.classList.remove('bg-red-200');
				N_saveBtn.disabled = false;
			}

			this.saveDebounce()

		})

		//Event changement pesée sortie
		N_ouput.input.addEventListener2('input', () => {

			if (this.manualMode) {

				this.weights.output = {
					dsd: 'Aucun',
					weight: N_ouput.input.valueAsNumber,
				}

			}

			const N_card = this.el.querySelector('.card') as HTMLElement;
			if (this.weights.input.weight && this.weights.output.weight) {
				N_netWeight.valueAsNumber = this.weights.input.weight - this.weights.output.weight

				if (N_netWeight.valueAsNumber < 0) {
					N_card.classList.add('bg-red-200')
					N_saveBtn.disabled = true
				}
				else {
					N_card.classList.remove('bg-red-200')
					N_saveBtn.disabled = false
				}

			}
			else {
				N_netWeight.valueAsNumber = 0
				N_card.classList.remove('bg-red-200')
				N_saveBtn.disabled = false
			}

			this.saveDebounce()

		})

		N_saveBtn.addEventListener('click', async () => {

			try {
				await this.updateTransaction(StateTransaction.notBilled)
				toastr.success('Transaction sauvegardé')
				this.destructor()
			}

			catch (e) {
				toastr.error('Erreur de base de données')
				console.error(e)
			}

		});

		let N_editBtn = this.el.querySelector("#editBtn") as HTMLButtonElement

		//Event sur le bouton modifier
		N_editBtn.addEventListener2('click', () => {

			const modal = new AddEditInputTransaction(this.id)

			modal.open().then(() => {
				this.updateCard()
				toastr.info('Transaction mis à jour')
			}).catch(() => {
				toastr.info('Modification annulée')
			})

		})

		const N_deleteBtn = this.el.querySelector('#deleteBtn') as HTMLButtonElement

		N_deleteBtn.addEventListener2('click', async () => {
			await this.deleteBL()
			this.destructor()
		})


	}

	private async getStorages(): Promise<Array<SiteStorage>> {
		return SitesService.getInstance().getByID(global.SITE).then(site => site.storages)
	}

	private async getData(id: string): Promise<CartTransactionData> {

		const deliveryNote = await DeliveryNotesService.getInstance().getByID(id)
		const orderData = await OrdersService.getInstance().getByID(deliveryNote.order)
		const [customerData, providerData] = await Promise.all([
			TiersService.getInstance().getByID(orderData.customer),
			TiersService.getInstance().getByID(orderData.provider)
		])

		return {
			...deliveryNote,
			orderData,
			customerData,
			providerData,
			dateString: "",
			storageName: ""
		}
	}

	private saveDebounce() {
		if (this.timeoutSave) {
			clearTimeout(this.timeoutSave)
		}

		this.timeoutSave = window.setTimeout(() => {
			this.save()
		}, 1000)

	}

	private save() {

		if (Number.isInteger(this.weights.input.weight) && Number.isInteger(this.weights.output.weight) && this.data) {
			this.data.weights = this.data.weights || { input: { weight: 0, dsd: '' }, output: { weight: 0, dsd: '' } }

			this.data.weights.input.weight = this.weights.input.weight
			this.data.weights.input.dsd = ''

			this.data.weights.output.weight = this.weights.output.weight
			this.data.weights.output.dsd = ''
		}

		// On met à jour notre quantité livré
		this.data!.products[0].deliveredQuantity = this.weights.input.weight && this.weights.output.weight
			? this.weights.input.weight - this.weights.output.weight
			: 0

		this.updateTransaction()
	}

	private async updateTransaction(status: string | null = null): Promise<void> {
		let oldDelivery = await DeliveryNotesService.getInstance().getByID(this.id)
		oldDelivery.products[0] = this.data!.products[0]
		oldDelivery.weights = this.data!.weights

		const oldState = oldDelivery.state
		oldDelivery.state = status ? status : oldDelivery.state

		const event: EventsManagerEvent = {
			type: TypeDataEvent.edit,
		}

		if (oldState != oldDelivery.state) {
			event.changeState = {
				from: oldState,
				to: oldDelivery.state
			}
		}

		oldDelivery = EventsManager.addEvent(oldDelivery, event);
		await DeliveryNotesService.getInstance().update(oldDelivery)

		await OrdersService.getInstance().updateOrder(this.data!.order)
	}

	private async updateCard(): Promise<void> {

		const deliveryNote = await this.getData(this.id)
		const storages = await this.getStorages()

		let storage = _.find(storages, { id: deliveryNote.products[0].storage })
		if (storage) {
			deliveryNote.storageName = storage.name
		}

		deliveryNote.dateString = moment(deliveryNote.date, 'x').format('DD/MM/YYYY')
		this.form && this.form.setData(deliveryNote as any)
	}

	private async deleteBL(): Promise<void> {

		try {
			await DeliveryNotesService.getInstance().delete(this.id)
			await DeliveryNotesService.getInstance().createEvent({
				date: Date.now(),
				type: TypeDataEvent.delete,
				user: LoggedUser.getInstance().get('ID'),
				id: this.id
			})

			toastr.success('BL supprimée')
		}

		catch (e) {
			toastr.error(`Erreur lors de la supression du BL`)
			console.error(e)
		}
	}

	public getNode() {
		return this.el
	}

	public destructor() {
		this.el.parentNode?.removeChild(this.el)
	}

}

export default CardTransaction

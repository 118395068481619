import { Modal, Form } from "@autoprog/core-client"

import moment from 'moment'

import T_addEditBillModal from '@tpl/modals/orders/addEditBillModal.html'
import { Grid, GridOptions, ICellRendererParams } from 'ag-grid-community'
import SelectOrders from '@js/libs/modals/SelectOrders'
import SelectDeliveryNotesWorkOrders from '@js/libs/modals/orders/SelectDeliveryNotesWorkOrders'
import { french as agGridFrench } from '@libs/utils/agGrid'
import h from 'hyperscript'

import FilesManager from '@libs/FilesManager'

import unitList from '@libs/lists/unitList'

import VATService from '@js/libs/services/VATService'
import NumericConfirmationCellEditor from '@js/libs/agGrid/NumericConfirmationCellEditor'
import QuantityCellEditor from '@js/libs/agGrid/QuantityCellEditor'

import { AccountingAccountsService, AnalyticsService, BillsService, DeliveryNotesService, OrdersService, ProductsService, SubAnalyticsService, TiersService, WorkOrdersService } from '@js/libs/services'

import AgGridStateSaver from '@js/libs/agGrid/StateSaver2'
import suffixCellRenderer from '@autoprog/core-client/src/js/libs/utils/agGrid/suffixCellRenderer'
import { LoggedUser } from "@autoprog/core-client"

import BillType, { BillTypeProduct } from '@js/types/billType'
import OldDataEvent from '@js/types/data-event'
import TypeDataEvent from '@js/libs/lists/TypeDataEvent'
import StateTransaction from '@js/libs/lists/StateTransactionEnum'

import _ from 'lodash'
import BillsManager, { BillProductsInfoList } from '@js/libs/managers/BillsManager'
import { CouchDBAttachments } from '@js/types/couchDB'

import PaymentUtils from '@js/libs/utils/payment'
import Order from '@js/types/order'
import Confirm from '../Confirm'

interface IBillInfo {
	_id?: string
	_rev?: string
	order?: string
	orders?: string[]
	date?: number
	deliveryNotes?: string[]
	workOrders?: string[]
	state?: string
	[key: string]: any,
	products?: Array<BillTypeProduct>
	_attachments?: CouchDBAttachments,
	dueDate?: number
}

type ConfirmationMessageTag = {
	unitPriceChanged: boolean
	quantityChanged: boolean
	attachmentsisMissing: boolean
	shouldForceOrderBilled: boolean
}

class AddEditBillModal extends Modal {

	private editing: boolean = false
	private bill: IBillInfo = {}
	private orders: Order[] = []
	private deliveryNotes: any[] = []
	private workOrders: any[] = []
	private customer: any = {}
	private provider: any = {}
	private footer: string = ''
	private gridOptions: GridOptions = {}
	private hasChangedUnitPrice: boolean = false
	private hasChangedQuantity: boolean = false
	private productRows: any[] = []
	private stateAGrid: AgGridStateSaver | undefined
	private form?: Form

	private billInfoList?: BillProductsInfoList
	private filesManager: FilesManager;

	constructor(billID?: string) {

		super({
			tpl: T_addEditBillModal,
			backdrop: 'static',
			keyboard: false
		})

		this.bill = { _id: billID }
		this.filesManager = new FilesManager()

		this.editing = !!billID

		this.on('closed', this.destructor.bind(this))
		this.on('opened', async () => {


			const N_attachments = this.element.querySelector("#attachments") as HTMLElement
			const N_save = this.element.querySelector('#save-button') as HTMLButtonElement
			N_attachments.appendChild(this.filesManager.getNode())

			//Si on est en création
			if (!this.bill._id) {
				//On lance la procédure de selection du BC et des BL/BI 
				this.hide()

				//Il est possible de deja ajout le numéro de commande lors de la création de facture depuis l'onglet BC
				if (!this.bill.orders) {
					await this.selectOrders()
				}
				await this.selectDeliveryNotesWorkOrders();
				this.show()

				N_save.disabled = false
			}

			const N_form = this.element.querySelector('form.bill.creation') as HTMLFormElement
			this.form = new Form(N_form)

			await this.loadData()
			this.initGridOptions()
			this.drawBill()
			this.updateAgGridRows()

			N_save.addEventListener('click', () => this.save())
			N_save.disabled = false

			// Gestion de la modification de la date decheance lorsqu'il chnage la date fournisseur
			const N_DateProvider = this.form.getElementByName('providerBillDate') as HTMLInputElement
			const N_DateDue = this.form.getElementByName('dueDate') as HTMLInputElement
			// if (!this.editing) {
				N_DateProvider.addEventListener('change', () => {
					const value = N_DateProvider.valueAsNumber
					N_DateDue.valueAsNumber = PaymentUtils.getPaymentDate(this.provider.paymentPeriod, value)
				})
			// }
		})

	}

	private async save() {
		// Verification de pieces jointes
		// Verification de quantite
		// Verification de prix unitaire
		if (this.hasChangedUnitPrice || this.hasChangedQuantity || !this.filesManager.hasFile()) {

			const hasComfirmToContinue = await this.createConfirmationMessage({
				unitPriceChanged: this.hasChangedUnitPrice,
				quantityChanged: this.hasChangedQuantity,
				attachmentsisMissing: !this.filesManager.hasFile(),
				shouldForceOrderBilled: false
			})

			if (!hasComfirmToContinue) {
				return
			}

		}


		//Ajout des données du formulaire a la facture
		const formData = this.form!.getData();

		if (formData.providerBillDate && moment.isMoment(formData.providerBillDate)) {
			formData.providerBillDate = +formData.providerBillDate.format('x')
		}

		if (formData.dueDate && moment.isMoment(formData.dueDate)) {
			formData.dueDate = +formData.dueDate.format('x')
		}

		const bill: BillType = {
			_id: this.bill._id || '',
			date: this.bill.date || 0,
			deliveryNotes: this.bill.deliveryNotes || [],
			workOrders: this.bill.workOrders || [],
			events: [],
			deleted: false,
			billNumber: this.bill._id,
			products: [],
			footerText: formData.footerText as string,
			observation: formData.observation as string,
			orders: this.bill.orders || [],
			state: this.bill.state || StateTransaction.waiting,
			providerBillDate: +formData.providerBillDate,
			dueDate: formData.dueDate ? +formData.dueDate : +moment('9999-12-31').format('x'),
			fxlCode: formData.fxlCode as string,
			providerBillNumber: formData.providerBillNumber as string
		}

		this.productRows.forEach(productRow => bill.products.push({
			id: productRow.product._id,
			rowID: productRow.rowID,
			adjustment: parseFloat(productRow.adjustment),
			reference: productRow.product.reference,
			unit: productRow.product.unit,
			unitPrice: productRow.unitPrice,
			quantity: productRow.quantity,
			vat: productRow.vat,
			ttcTotal: productRow.totalInclTax,
			tvaPrice: productRow.vatCost
		}))

		if (this.editing) {
			await this.updateBill(bill)
		}
		else {

			bill.state = StateTransaction.waiting;
			if (LoggedUser.getInstance().hasPermission('ORDERS.BILL.STATE.VALIDATED')) {
				bill.state = StateTransaction.validated;
			}
			await this.addBill(bill)
		}


		await BillsService.getInstance().syncAttachment(bill._id, this.filesManager.getArrayFiles())

		// Cette ligne est importante car c'est cette ligne qui va mettre à jour les bons de livraisons, d'interventions et les commandes aux bon status
		await BillsService.getInstance().updateBillStatus(this.bill._id!)

		for (let orderID of bill.orders) {

			const order = await OrdersService.getInstance().getByID(orderID)
			await OrdersService.getInstance().updateTotalBills(orderID)

			const isOrderTotallyBilled = await OrdersService.getInstance().isFullyBilled(orderID)
			if (isOrderTotallyBilled) {
				try {
					const confirm = new Confirm(orderID, "Tous les bons de livraisons/d'interventions sont facturés. Voulez-vous passer la commande en facturée ?", {
						yesText: 'Oui',
						noText: 'Non'
					})
					await confirm.open();
					await OrdersService.getInstance().setState(orderID, StateTransaction.billed)
				}
				catch {
					await OrdersService.getInstance().setState(orderID, order.state)
				}
			}
		}

		this.resolve()
	}

	/**
	 * Va mettre à jour la facture
	 * - Met à jout la facture
	 * - Met à jour les DataEvents
	 * @param bill
	 */
	private async updateBill(bill: BillType): Promise<void> {
		const oldBill = await BillsService.getInstance().getByID(this.bill._id!)
		bill.events = oldBill.events
		await BillsService.getInstance().update(bill)

		const dataEvent: Omit<OldDataEvent, 'table'> = {
			date: Date.now(),
			type: TypeDataEvent.edit,
			user: LoggedUser.getInstance().get('ID'),
			id: bill._id
		}

		if (oldBill.state != bill.state) {
			dataEvent.changeState = {
				from: oldBill.state,
				to: bill.state
			}
		}

		await BillsService.getInstance().createEvents(dataEvent)
	}

	private async addBill(bill: BillType): Promise<void> {
		await BillsService.getInstance().create(bill)
		await BillsService.getInstance().createEvents({
			date: Date.now(),
			type: TypeDataEvent.create,
			user: LoggedUser.getInstance().get('ID'),
			id: bill._id,
		})
	}

	public static fromOrder(orderID: string) {
		const m = new AddEditBillModal()
		m.bill.orders = [orderID]
		return m
	}

	/**
	 * @description Ouvre la modal de selection de la commande
	 */
	private async selectOrders() {

		try {
			this.bill.orders = await new SelectOrders('FA').open();
		} catch (e) {
			this.reject();
			throw e;
		}

	}

	/**
	 * @description Ouvre la modal de selection des BL/BI
	 */
	private async selectDeliveryNotesWorkOrders() {

		try {
			const result = await new SelectDeliveryNotesWorkOrders(this.bill.orders!).open()
			this.bill.deliveryNotes = result.deliveryNotes
			this.bill.workOrders = result.workOrders

		} catch (e) {
			this.reject()
			throw e
		}

	}

	private drawBill() {

		//Fournisseur
		if (this.provider) {

			const N_providerBlock = this.element.querySelector('.provider-block') as HTMLElement;
			const N_logo = N_providerBlock.querySelector('.provider-img') as HTMLImageElement;
			const N_societyName = N_providerBlock.querySelector('.society-name') as HTMLElement;
			const N_societyAddress1 = N_providerBlock.querySelector('.society-address1') as HTMLElement;
			const N_societyAddress2 = N_providerBlock.querySelector('.society-address2') as HTMLElement;
			const N_societyPhone = N_providerBlock.querySelector('.society-phone') as HTMLElement;

			//TODO: logo
			N_logo.src = '';
			N_logo.style.display = 'none';

			!this.provider.name && (N_societyName.style.display = 'none')
			!this.provider.address && (N_societyAddress1.style.display = 'none')
			!this.provider.zipCode || !this.provider.city && (N_societyAddress2.style.display = 'none')
			!this.provider.phone && (N_societyPhone.style.display = 'none')

			N_societyName.innerHTML = this.provider.name
			N_societyAddress1.innerHTML = this.provider.address
			N_societyAddress2.innerHTML = `${this.provider.zipCode} ${this.provider.city}`
			N_societyPhone.innerHTML = `Tel: ${this.provider.phone}`

		}

		//Client
		if (this.customer) {

			let N_customerBlock = this.element.querySelector('.customer-block') as HTMLElement;
			let N_societyName = N_customerBlock.querySelector('.society-name') as HTMLElement;
			let N_societyAddress1 = N_customerBlock.querySelector('.society-address1') as HTMLElement;
			let N_societyAddress2 = N_customerBlock.querySelector('.society-address2') as HTMLElement;
			let N_societyPhone = N_customerBlock.querySelector('.society-phone') as HTMLElement;

			!this.customer.name && (N_societyName.style.display = 'none')
			!this.customer.address && (N_societyAddress1.style.display = 'none')
			!this.customer.zipCode || !this.customer.city && (N_societyAddress2.style.display = 'none')
			!this.customer.phone && (N_societyPhone.style.display = 'none')

			N_societyName.innerHTML = this.customer.name
			N_societyAddress1.innerHTML = this.customer.address
			N_societyAddress2.innerHTML = `${this.customer.zipCode} ${this.customer.city}`
			N_societyPhone.innerHTML = `Tel: ${this.customer.phone}`

		}

		//Numéro facture
		const N_billNumber = this.element.querySelector('.bill-number') as HTMLElement
		N_billNumber.innerHTML = `Facture<br>${this.bill._id}`;

		//Date de facture
		const N_billDate = this.element.querySelector('.bill-date') as HTMLElement
		N_billDate.innerHTML = moment(this.bill.date).format('DD/MM/YYYY HH:mm');

		//Numéro de commande
		const N_orderNumber = this.element.querySelector('.orders-numbers') as HTMLElement
		N_orderNumber.innerHTML = _.map(this.orders, '_id').join('<br>')


		//Liste des BLs
		const N_deliveryNotesList = this.element.querySelector('.delivery-notes-list') as HTMLElement;
		const N_deliveryNotesNumbers = N_deliveryNotesList.querySelector('.delivery-notes-numbers') as HTMLElement;

		!this.deliveryNotes.length && (N_deliveryNotesList.style.display = 'none')
		N_deliveryNotesNumbers.innerHTML = _.map(this.deliveryNotes, '_id').join('<br>')

		//Liste des BIs
		const N_WorkOrdersList = this.element.querySelector('.work-orders-list') as HTMLElement;
		const N_WorkOrdersNumbers = N_WorkOrdersList.querySelector('.work-orders-numbers') as HTMLElement;

		if (!this.workOrders.length) {
			N_WorkOrdersList.style.display = 'none'
		}

		N_WorkOrdersNumbers.innerHTML = _.map(this.workOrders, '_id').join('<br>')

		//Pied de facture
		const N_footerText = this.element.querySelector('#footer-text') as HTMLTextAreaElement;
		if (!N_footerText.value) {
			N_footerText.value = this.footer;
		}

	}

	private async loadData() {

		/* ------------------------ Chargement de la commande ----------------------- */

		const isNewBill = this.bill._id === undefined || this.bill._id === null || this.bill._id.trim() === ''

		//Si on a déja un id, on est en édition
		if (this.bill._id) {

			this.bill = await BillsService.getInstance().getByID(this.bill._id)
			delete this.bill._rev;
			delete this.bill.events;

			this.billInfoList = await BillsManager.getBillInfo(this.bill._id!)
		}
		else {
			this.bill._id = 'FA-' + moment().format('YYYY-MM-DD-HHmmss')
			this.bill.date = +moment().format('x')
			this.billInfoList = await BillsManager.getBillInfoByDeliveryAndWorkOrder(this.bill.deliveryNotes || [], this.bill.workOrders || [])
		}

		if (this.bill.orders) {
			let orderService = OrdersService.getInstance();
			this.orders = await orderService.getByIds(this.bill.orders);


			/* -------------------- Chargement des bons de livraison -------------------- */
			this.deliveryNotes = await DeliveryNotesService.getInstance().getByIds(this.bill.deliveryNotes || []);
			/* ------------------- Chargement des bons d'intervention ------------------- */
			this.workOrders = await WorkOrdersService.getInstance().getByIds(this.bill.workOrders || []);
			/* ------- Chargement des tiers corrspondant au fournisseur et client ------- */

			//Les fournisseurs / clients sont forcement identiques sur toutes les commandes sélectionnées.
			let tiersService = TiersService.getInstance();
			this.provider = await tiersService.getByID(this.orders[0].provider)
			this.customer = await tiersService.getByID(this.orders[0].customer)

			/* ------- Création de notre bill ------- */

			// Lors de la creation d'un nouveau
			if (isNewBill) {
				this.bill.dueDate = PaymentUtils.getPaymentDate(this.provider.paymentPeriod)
			}

			/* -------------------- Chargement du pied de la facture -------------------- */

			let siteID = null

			//Les types sont forcement identiques sur toutes les commandes sélectionnées.

			if (this.orders[0].type == 'input') {
				siteID = this.customer._id
			}
			else if (this.orders[0].type == 'output') {
				siteID = this.provider._id
			}

			this.footer = await BillsService.getInstance().getFooter(siteID) || ''

			/* -------------------- Creation des lignes de l'ag-grid -------------------- */

			let productsService = ProductsService.getInstance()
			const productIds = _.uniq(_.flatten(this.orders.map(order => (order.products || []).map(product => product.id)))) as Array<string>
			const products = await productsService.getByIDs(productIds)
			const vatService = VATService.getInstance()

			for (let order of this.orders) {
				for (let orderProduct of order.products || []) {

					// let product = await productsService.getByID(orderProduct.id)
					const product = products.find(pro => pro._id === orderProduct.id);

					let productBillInfo = this.billInfoList?.[`${order.orderNumber}|${orderProduct.rowID}`];
					let deliveredQuantity = 0;
					console.log(deliveredQuantity, productBillInfo)
					if (productBillInfo) {

						// TODO: voir pourquoi cette ligne etait importante, peut-etre a un moment j'avais desactive les quantite negative pour x raisons
						// deliveredQuantity = productBillInfo.getTotalOrdered() > productBillInfo.billedQuantity && isNewBill
						deliveredQuantity = isNewBill
							? productBillInfo.getTotalOrdered()
							: productBillInfo.billedQuantity
					}
					//Si le produit n'a pas de quantité livré, il est inutile de le facturer
					if (deliveredQuantity) {

						const billProduct: BillTypeProduct | { vat: null, unitPrice: null, adjustment: null } = _.find(this.bill.products, { rowID: orderProduct.rowID }) || { vat: null, unitPrice: null, adjustment: null }

						this.productRows.push({
							product,
							rowID: orderProduct.rowID,
							adjustment: billProduct.adjustment || 0,
							unitPrice: billProduct.unitPrice || orderProduct.unitPrice,
							quantity: deliveredQuantity,
							vat: billProduct.vat || product?.vat || vatService.getDefault()?._id,
							maxPrice: orderProduct.unitPrice
						})
					}
				}
			}

			//Calcule des totaux de chaque produits
			this.computeTotals();

			if (this.form) {
				this.form.setData(this.bill);
			}

			if (this.bill._attachments) {
				this.filesManager.setFiles(this.bill._attachments)
			}
		}
	}

	private initGridOptions() {

		let vatService = VATService.getInstance();

		this.gridOptions = agGridFrench({
			rowData: [],
			columnDefs: [
				{
					headerName: 'Référence du produit ou prestation',
					field: 'product.reference',
					suppressSizeToFit: false,

				},
				{
					headerName: 'Cmpt Comptable',
					field: 'product.accountingAccount',
					width: 150,
					cellRenderer: (params) => {
						const aa = AccountingAccountsService.getInstance().getById(params.value)
						return aa?.name || '???'
					}
				},
				{
					headerName: 'Analytique',
					field: 'product.analytics',
					width: 150,
					cellRenderer: (params: ICellRendererParams): HTMLElement | string => {

						if (params.value && params.value.length) {
							if (params.value.length == 1) {
								const analytic = AnalyticsService.getInstance().getById(params.value[0].id)
								return analytic?.name || '';
							}
							else {

								let tooltip = '';

								for (let value of params.value) {
									const analytic = AnalyticsService.getInstance().getById(value.id)
									tooltip += `${analytic?.name || ""} (${value.percent}%) <br>`
								}

								return h('span', `${params.value.length} analytiques`, { attrs: { tooltip } }) as HTMLElement;
							}
						}

						return ''
					}
				},
				{
					headerName: 'Sous-analytique',
					field: 'product.subAnalytics',
					width: 150,
					cellRenderer: (params) => {
						if (params.value && params.value.length) {
							const subAnalytic = SubAnalyticsService.getInstance().getById(params.value[0].id)
							return subAnalytic?.name || '';
						}

						return ''
					}
				},
				{
					headerName: 'Qte facturée',
					field: 'quantity',
					width: 80,
					editable: true,
					cellRenderer: (params) => {
						let qty = _.round(params.value / unitList[params.data.product.unit].coeficient, 2);
						let unit = unitList[params.data.product.unit].name

						return `${qty} ${unit}(s)`
					},
					valueSetter: (params) => {
						// Tous les quantites sont editables
						const forceEdit = LoggedUser.getInstance().hasPermission("ORDERS.BILL.FORCE_EDIT_QUANTITY")

						// La regle de base est qu'aucune personne ne peut commander une quantite inferieur la quantite calcule par les BL et les BI selectionnees
						// WARNING: Elle peut etre bypasser par forceEdit

						if (!forceEdit && params.data.quantity < params.newValue) {
							return false
						}

						params.data.quantity = params.newValue
						this.hasChangedQuantity = true
						return true
					},
					cellEditor: QuantityCellEditor as any
				}, {
					headerName: 'PU HT',
					field: 'unitPrice',
					cellRenderer: (params) => {
						return _.round(params.value * unitList[params.data.product.unit].coeficient, 2) + '€';
					},
					editable: true,
					width: 100,
					cellEditor: NumericConfirmationCellEditor as any,
					valueSetter: (params) => {
						// Il est impossible de depasser le montant du prix unitaire de la commande
						if (params.newValue > params.data.maxPrice * unitList[params.data.product.unit].coeficient) {
							return false
						}

						const newUnitPrice = params.newValue / unitList[params.data.product.unit].coeficient
						params.data.unitPrice = newUnitPrice
						this.hasChangedUnitPrice = true
						return true
					}
				}, {
					headerName: 'Réajustement',
					field: 'adjustment',
					editable: true,
					cellRenderer: (params) => {
						return (params.value || 0) + '%';
					},
					width: 80,
				}, {
					headerName: 'Total HT',
					width: 100,
					field: 'totalExclTax',
					cellRenderer: suffixCellRenderer('€')
				}, {
					headerName: 'TVA',
					field: 'vat',
					editable: true,
					width: 80,
					cellEditor: 'agRichSelectCellEditor',
					cellEditorParams: {
						values: _.map(vatService.getAll(), '_id'),
						cellRenderer: (params: any) => {

							let vat = vatService.getById(params.value)
							if (vat) {
								return `${vat.label} (${vat.percent}%)`;
							} else {
								return '???';
							}
						}
					},
					cellRenderer: (params) => {

						let vat = vatService.getById(params.value)
						if (vat) {
							return `${vat.percent}%`;
						} else {
							return '???';
						}

					},
					cellClass: (params) => {
						if (!params.value) {
							return 'bg-red'
						}

						return ''
					}
				}, {
					headerName: 'Mnt TVA',
					field: 'vatCost',
					width: 80,
					cellRenderer: (params) => {
						return params.value + '€';
					}
				}, {
					headerName: 'Total TTC',
					field: 'totalInclTax',
					width: 80,

					cellRenderer: (params) => {
						return params.value + '€';
					}
				}
			],

			onCellValueChanged: (params) => {

				if (params.colDef.field == 'quantity' || params.colDef.field == 'vat' || params.colDef.field == 'adjustment' || params.colDef.field == 'unitPrice') {
					this.computeTotals();
				}

			},
			suppressDragLeaveHidesColumns: true,
			onGridReady: (_params) => this.gridOptions.api?.sizeColumnsToFit(),
			defaultColDef: {
				resizable: true,
				filter: false,
				sortable: false,
				menuTabs: [],
				suppressSizeToFit: true,

			},
		});

		let N_grid = this.element.querySelector('#grid');

		if (N_grid) {
			new Grid(N_grid as HTMLElement, this.gridOptions);
			this.stateAGrid  = new AgGridStateSaver(this.gridOptions, "bill_creation", `Tableau creation/modification de facture`);
		}

	}

	private computeTotals() {

		let vatService = VATService.getInstance();

		for (let row of this.productRows) {

			let totalExclTax = row.quantity * row.unitPrice;
			totalExclTax = totalExclTax * (1 - (row.adjustment || 0) / 100);
			// totalExclTax += row.deliveryCost;

			const vat = vatService.getById(row.vat)!

			let vatCost = (totalExclTax * (vat.percent / 100)) || 0;
			let totalInclTax = (totalExclTax + vatCost) || 0;

			row.totalExclTax = _.round(totalExclTax, 2);
			row.vatCost = _.round(vatCost, 2);
			row.totalInclTax = _.round(totalInclTax, 2);
		}

		this.drawTotalsBlock();
		this.updateAgGridRows();

	}

	private drawTotalsBlock() {

		const vatService = VATService.getInstance();
		const N_totals = this.element.querySelector('#totals') as HTMLElement;

		let exclTax = 0;
		let inclTax = 0;
		const totalsByVAT: { [key: string]: number } = {}

		for (let row of this.productRows) {
			exclTax += row.totalExclTax;
			inclTax += row.totalInclTax;
			totalsByVAT[row.vat] = totalsByVAT[row.vat] || 0;
			totalsByVAT[row.vat] += row.vatCost
		}

		N_totals.innerHTML = '';

		N_totals.appendChild(h('div.total-excl-tax',
			h('span.label', 'Total HT:'),
			h('span', `${_.round(exclTax, 2)}€`),
		))

		for (let vatID in totalsByVAT) {
			const vat = vatService.getById(vatID)!
			N_totals.appendChild(h('div.total-vat',
				h('span.label', `Total ${vat.percent}%:`),
				h('span', `${_.round(totalsByVAT[vatID], 2)}€`),
			))
		}

		N_totals.appendChild(h('div.total-incl-tax',
			h('span.label', 'Total TTC:'),
			h('span', `${_.round(inclTax, 2)}€`),
		))


	}

	private updateAgGridRows() {
		this.gridOptions.api?.setRowData(this.productRows)
	}


	/**
	 * Create confirmation
	 */
	private async createConfirmationMessage(args: ConfirmationMessageTag): Promise<boolean> {
		let message = ""

		if (args.unitPriceChanged) {
			message += "<li>Un ou plusieurs prix unitaires ont été modifiés.</li>"
		}

		if (args.quantityChanged) {
			message += "<li>Un ou plusieurs quantités ont été modifiés.</li>"
		}

		if (args.attachmentsisMissing) {
			message += "<li>Piece(s) jointes manquants.</li>"
		}

		if (!_.isEmpty(message)) {

			message = "<p>Voulez vous vraiment continuer avec cette liste ?</p><ul>" + message + "</ul>"

			try {
				const confirmationModal = new Confirm('Confirmation', message, { raw: true, yesText: 'Confirmer', noText: 'Annuler' })
				await confirmationModal.open()
				return true
			}
			catch {

			}
		}

		return false
	}

	public destructor(): void {
		if (this.gridOptions.api) {
			this.stateAGrid && this.stateAGrid.save()
			this.gridOptions.api?.destroy()
		}
	}

}

export default AddEditBillModal

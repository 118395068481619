import { Modal, global } from "@autoprog/core-client"
import T_transfertDigestat from '@tpl/modals/exploitation/transfertDigestat.html'

import unitList from '@libs/lists/unitList'
import BDD from '@libs/BDD'
import Site from '@js/types/site'
import SitesService from '@js/libs/services/SitesService'
import Digestats from '@js/types/digestats'
import DigestatsService from '@js/libs/services/DigestatsService'

import _ from 'lodash'

class TransfertDigestat extends Modal {

	private designSite: string = global.DESIGN;

	private N_select_origin: HTMLSelectElement | null = null;
	private N_select_destination: HTMLSelectElement | null = null;
	private N_row_customer: HTMLElement | null = null;
	private N_unit: HTMLElement | null = null;

	private N_customer: HTMLSelectElement | null = null;
	private N_place: HTMLSelectElement | null = null;

	private unit: string = '';

	constructor(site: string) {

		super({
			tpl: T_transfertDigestat,
			backdrop: 'static',
			keyboard: false
		});

		this.designSite = site;

		this.on('opened', async () => {

			let site = await this.getSite()

			let N_name_site = this.element.querySelector('#name-site');
			N_name_site && (N_name_site.innerHTML = site.name);

			this.N_select_origin = this.element.querySelector('#origin')! as HTMLSelectElement;
			this.N_select_destination = this.element.querySelector('#destination')! as HTMLSelectElement;
			this.N_row_customer = this.element.querySelector('#row-customer')! as HTMLElement;
			this.N_unit = this.element.querySelector('#unit')! as HTMLElement;

			this.N_customer = this.element.querySelector('#customer')! as HTMLSelectElement;
			this.N_place = this.element.querySelector('#place')! as HTMLSelectElement;

			let index = 0;

			const option_origin = new Option('Exterieur', 'out', true)
			const option_origin_1 = new Option('lagune', 'lagune')
			this.N_select_origin.append(option_origin)
			this.N_select_origin.append(option_origin_1)

			const option_destination = new Option('Exterieur', 'out', true)
			const option_destination_1 = new Option('lagune', 'lagune')
			this.N_select_destination.append(option_destination)
			this.N_select_destination.append(option_destination_1)

			let defaultValue = site.defaultOutputDigestat;

			for (let item of (site.storages || [])) {

				if (item.type == 'tank') {

					let option_origin = new Option(item.name, item.id, index == 0, index == 0);

					if (defaultValue) {
						option_origin.selected = (item.id == defaultValue);
					}

					this.N_select_origin.append(option_origin);

					let option_destination = new Option(item.name, item.id);

					this.N_select_destination.append(option_destination);

					if (index == 0) {
						this.unit = item.unit;
						this.N_unit.innerHTML = unitList[item.unit].unit;
					}

					index++;

				}

			}

			this.N_select_destination.addEventListener('click', () => {

				if (this.N_row_customer && this.N_select_destination) {

					if (this.N_select_destination.value == 'out') {
						this.N_row_customer.classList.remove('d-none');
					} else {
						this.N_row_customer.classList.add('d-none');
					}

				}

			});

			$(this.N_customer).select2({
				ajax: {
					url: `api/tiers/search/select2`,
					data: function (params) {
						return {
							...params,
							site: site._id
						}
					}
				},
				dropdownParent: $(this.N_customer.parentNode as HTMLElement)
			}).on('change', () => {

				if (this.N_customer && this.N_place) {

					let data = $(this.N_customer).select2('data')[0] as any;

					let places = data.tier.places || [];

					places = _.map(places, (item: { [key: string]: string }) => {
						return {
							id: item.name,
							text: item.name
						}
					});

					$(this.N_place).html('').select2({
						data: places,
						dropdownParent: $(this.N_place.parentNode as HTMLElement)
					});

				}

			});

			$(this.N_place).select2({
				data: [],
				dropdownParent: $(this.N_place.parentNode as HTMLElement)
			})

			this.configureSaveBtn();

		});

	}

	private configureSaveBtn() {

		let N_save = this.element.querySelector('#save')!

		N_save.addEventListener('click', async () => {

			let N_quantity = this.element.querySelector('#quantity') as HTMLInputElement;

			if (N_quantity && this.N_select_origin && this.N_select_destination) {

				let save = true

				if (!N_quantity.valueAsNumber) {

					N_quantity.classList.add('is-invalid');
					save = false;

				}

				if (this.N_select_origin.value == this.N_select_destination.value) {
					this.N_select_origin.classList.add('is-invalid');
					this.N_select_destination.classList.add('is-invalid');
					save = false;
				}

				if (save) {

					let quantity = N_quantity.valueAsNumber * unitList[this.unit].coeficient;

					let events: Array<Digestats> = [{
						_id: BDD.generateID(),
						date: Date.now(),
						storage: this.N_select_origin.value,
						type: 'output',
						customer: "",
						place: "",
						site: this.designSite,
						quantity: quantity,
						isTransfert: this.N_select_origin.value != 'out' && this.N_select_destination.value != 'out'
					}, {
						_id: BDD.generateID(),
						date: Date.now(),
						storage: this.N_select_destination.value,
						type: "input",
						customer: "",
						place: "",
						site: this.designSite,
						quantity: quantity,
						isTransfert: this.N_select_origin.value != 'out' && this.N_select_destination.value != 'out'
					}];

					if (this.N_select_destination.value == 'out' && this.N_customer && this.N_place) {

						events[0].customer = $(this.N_customer).select2('data')[0].id;
						events[0].place = $(this.N_place).select2('data')[0].id;

					}

					let docs: Digestats[] = events.filter(item => item.storage != 'out')
					await DigestatsService.getInstance().updateAll(docs)
					this.resolve()
				}

			}

		});

	}

	private getSite(): Promise<Site> {
		return SitesService.getInstance().getByID(this.designSite)
	}

}

export default TransfertDigestat;
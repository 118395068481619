import { LoggedUser } from "@autoprog/core-client"
import { Importer, ImporterDefinition } from "@js/types/importer"
import axios from 'axios'


export type UploadedFile = {
	user: string
	data: Blob
	filename?: string
	size: number
	checksum?: string // pas encore utilise, peut etre dans le futur
	type: string
}

class ImportersService {
	private static readonly instance = new ImportersService()

	private constructor() {}

	public static getInstance() : ImportersService {
		return ImportersService.instance
	}

	public async getHeaders() : Promise<Array<Importer>> {
		return axios.get(`/api/importer-definitions/headers/all`).then(response => response.data.data)
	}

	public async getAll() : Promise<Array<ImporterDefinition>> {
		return axios.get(`/api/importer-definitions`).then(response => response.data.data)
	}

	public async delete(id: string) {
		await axios.delete(`/api/importer-definitions/${id}`)
	}

	public async deleteByColumn(identifier: string, column: string, _tag?: string) {
		// Definition should be uniq, just in case, we put constructor
		await axios.delete(`/api/importer-definitions/by-column/${identifier}/${column}`)
	}

	public async create(definition: ImporterDefinition) {
		await axios.post(`/api/importer-definitions`, definition)
	}

	public async update(definition: ImporterDefinition) {
		await axios.put(`/api/importer-definitions`, definition)
	}

	public async upload(files: Array<UploadedFile>, onSending?: (loaded: number, total: number, pourcent: number) => void) {
		const formData = new FormData()
		files.forEach(file => formData.append('imports', file.data))

		await axios.post(`/api/importer-definitions/uploads`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Content-Encoding': 'UTF-8',
				'Import-Filenames': `(${files.map(file => file.filename).join('|')})`,
				'Import-type': `(${files.map(file => file.type).join('|')})`,
				'Transfer-verification': `Suspended for now`, // Ce sera pour plus tard
				'Transfer-Owner': LoggedUser.getInstance().get('ID'),
				// Pour etre honnete, tous ce qu'il y a en dessous ne sert a rien, mais parfois utile pour identifier un tout peu
				'Transfer-Type': 'manual',
				'Transfer-sent': Date.now(),
				'Transfer-origin': window.location.origin,
				'Transfer-zone': Intl.DateTimeFormat().resolvedOptions().timeZone
			},
			onUploadProgress: (event: ProgressEvent) => {
				const { loaded, total } = event
				const percent = parseInt(Math.round((loaded * 100) / total).toString(), 10)
				onSending && onSending(loaded, total, percent)
			}
		})
	}
}

export default ImportersService


import { Alert, Tab, toaster } from "@autoprog/core-client"
import { SitesService } from "@js/libs/services";

import { GridOptions, Grid } from 'ag-grid-community';

class TablesAgGridTab extends Tab {

	private gridOptions!: GridOptions

	constructor(private el: HTMLElement) {
		super(el)
		this.initButton()
		this.shouldInit()
	}

	private async shouldInit() {
		this.createGrid()
		this.loadListStateSaver()
	}

	private initButton() {
		const N_btnDelete = this.el.querySelector('#delete-all-statesaver') as HTMLButtonElement;
		N_btnDelete.addEventListener('click', () => {
			Alert.confirm('Effacer toutes les mises en formes des tableaux', 'Voulez-vous effacer toutes les données?').then(() => {
				this.deleteAllStateSaver()
				this.loadListStateSaver()
			})
		})
	}
	private createGrid() {
		this.gridOptions = {
			columnDefs: [
				{
					headerName: 'id',
					field: 'id',
					sort: 'asc',
					hide: true,
				},
				{
					headerName: 'Nom du Tableau',
					field: 'name',
					filter: 'agTextColumnFilter',
					floatingFilter:true
				}, {
					headerName: "Action",
					field: "commands",
					width: 8 + 28 * 2,
					sortable: false,
					filter: false,
					suppressSizeToFit: true,
					pinned: 'right',
					cellRenderer: (params) => {
						let div = document.createElement('div') as HTMLDivElement
						div.classList.add('d-flex', 'justify-content-center')

						let deleteButton = document.createElement('button')
						deleteButton.classList.add('btn', 'btn-xs', 'btn-danger')
						deleteButton.setAttribute('confirmation', '')

						deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"></i>'

						deleteButton.addEventListener('click', () => {
							localStorage.removeItem(params.data.id)
							this.loadListStateSaver()
							toaster.success(`Suppression de la mise en forme du tableau`)
						})

						div.appendChild(deleteButton)

						return div
					},
				}
			],
			rowData: [],
			defaultColDef: {
				sortable: true
			},
			onGridReady: (params) => params.api.sizeColumnsToFit(),
			onRowDataUpdated: () => this.gridOptions.api?.refreshCells({ force: true }),

		}

		const N_grid = this.el.querySelector('#state-saver-grid') as HTMLDivElement
		new Grid(N_grid, this.gridOptions)
	}

	private async loadListStateSaver() {

		const sites = await SitesService.getInstance().getAllSites();

		let data = [],
			keys = Object.keys(localStorage),
			i = keys.length;

		while (i--) {
			if (keys[i].indexOf('StateSaver-') >= 0 && localStorage.getItem(keys[i]) && localStorage.getItem(keys[i]) !== "") {

				let name = JSON.parse(localStorage.getItem(keys[i])!).displayName;
				for (let site of sites) {
					name = name.replace(new RegExp(site._id, 'gmi'), site.name)
				}

				data.push({
					id: keys[i],
					name: name === "" ? keys[i] : name
				});
			}

		}
		this.gridOptions.api && this.gridOptions.api.setRowData(data)
	}

	private deleteAllStateSaver() {
		let keys = Object.keys(localStorage);
		let i = keys.length;
		while (i--) {
			if (keys[i].indexOf('StateSaver-') >= 0) {
				localStorage.removeItem(keys[i])
			}
		}
	}
	public destructor(): void {

	}


}

export default TablesAgGridTab;

import HumanizeNumber from '@libs/HumanizeNumber'


export default (n: number, unit: string) => {

	let value = HumanizeNumber(n);

	if (unit == 'kg' || unit == 'pcs') {

		let chunkValue = value.split(' ');

		if (chunkValue.length == 1) {

			chunkValue.unshift('00');

			chunkValue[1] = ('000' + chunkValue[1]).slice(-3);

		}

		if (chunkValue.length == 2 && chunkValue[0].length < 2) {

			chunkValue[0] = ('00' + chunkValue[0]).slice(-2);

		}

		return chunkValue.join(' ');

	}

	if (unit == 't') {

		let chunkValue = value.split('.');

		if (chunkValue.length == 0) {

			chunkValue = ['00', '000'];

		}

		if (chunkValue.length == 1) {

			chunkValue.push('000');

		}

		if (chunkValue.length >= 2) {

			if (chunkValue[0].length < 2) {
				chunkValue[0] = ('00' + chunkValue[0]).slice(-2);
			}

			chunkValue[chunkValue.length - 1] = (chunkValue[chunkValue.length - 1] + '000').slice(0, 3);

		}

		return chunkValue.join(',');

	}

	return value;
}
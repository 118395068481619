import { global, LoggedUser } from "@autoprog/core-client"
import { Grid, GridOptions, ProcessCellForExportParams, ICellRendererFunc } from 'ag-grid-community'
import 'ag-grid-enterprise'
import AgGridStateSaver from '@libs/agGrid/StateSaver2'
import AddEditDelivery from '@js/libs/modals/orders/AddEditDelivery'
import moment from 'moment'
import toastr from 'toastr'
import DetailDelivery from '@js/libs/modals/orders/DetailDelivery'
import unitList from '@libs/lists/unitList'
import stateList from '@libs/lists/stateListMale'
import { AGGridUtils, french as agGridFrench } from '@libs/utils/agGrid'
import Export from '@libs/agGrid/Export'

import Prompt from '@libs/modals/prompt'

import DatePickerRangeFilter from '@libs/agGrid/DatePickerRangeFilter'

import h from 'hyperscript'

import humanizeNumber from '@libs/HumanizeNumber';
import displayWeight from '@libs/DisplayWeight';

import M_file from '@libs/modals/orders/Attachments'

import TextFilterAccent from '@libs/agGrid/TextFilterAccent'
import { DeliveryNotesService, OrdersService, TiersService } from '@js/libs/services'

import { UnionTypeQualityCriteria } from '@js/libs/services/DeliveryNotesService'
import Order from '@js/types/order'
import DeliveryNote from '@js/types/delivery-note'
import Tier from '@js/types/Tiers'
import TypeDataEvent from '@js/libs/lists/TypeDataEvent'

import '@css/tabs.scss'
import '@css/stateslist.scss'

import _ from "lodash"

interface TabDeliveryNoteRowOrder extends Order {
	providerObject: Tier
	customerObject: Tier
}

interface TabDeliveryNoteRow extends DeliveryNote {
	orderData: TabDeliveryNoteRowOrder
}

class TabDeliveryNote {

	private gridOptions: GridOptions
	private stateAGrid: AgGridStateSaver | undefined
	private states = ['all','notArchived',  'notBilled', 'billed', 'archived']
	constructor(private el: HTMLElement) {
		this.initQuickFilter()

		this.gridOptions = agGridFrench({
			suppressCopyRowsToClipboard: true,
			enableRangeSelection: true,
			sideBar: {
				toolPanels: [{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnFilter: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}],
			},
			excelStyles: [
				{
					id: 'date',
					dataType: 'DateTime',
					numberFormat: {
						format: 'dd/mm/yyyy;@',
					}
				},
				{
					id: 'number',
					dataType: 'Number'
				}
			],
			columnDefs: [
				{
					headerName: "Etat",
					pinned: 'left',
					field: "state",
					filter: 'agSetColumnFilter',
					filterValueGetter: (params) => {
						if (params.data && params.data.state && stateList[params.data.state]) {
							return stateList[params.data.state].name
						}
					},
					cellRenderer: (params) => {

						if (params.node.level == 0 && params.value && stateList[params.value]) {
							return stateList[params.value].name
						}
						return '';

					},
					aggFunc: 'first'
				}, {
					headerName: "N° Bon de livraison",
					showRowGroup: 'groupKey',
					colId: 'deliveryNumber',
					cellRenderer: 'agGroupCellRenderer',
					pinned: 'left',
					sort: 'desc',
					filterValueGetter: function (params) {

						return params.data ? params.data.deliveryNumber : null;

					},
					valueGetter: (params) => {
						if (!params.node?.rowPinned) {
							if (params.node?.level == 0 && params.data.deliveryNumber) {
								return params.data.deliveryNumber
							}

							return '';
						}

						return params.data.deliveryNumber
					}
				},
				{
					field: "groupKey",
					rowGroup: true,
					hide: true,
					suppressColumnsToolPanel: true

				}, {
					field: "_id", //Ne pas supprimer cette colonne, utilisé pour avoir l'_id dans les aggData
					hide: true,
					aggFunc: 'first',
					suppressColumnsToolPanel: true
				},
				{
					headerName: 'Bon de commande',
					field: 'orderData._id',
					aggFunc: 'first',
					hide: false,
					valueGetter: (params) => {
						if (params.data && params.data.orderData && params.data.orderData._id) {
							return params.data.orderData._id
						}
						return ""
					}
				},
				{
					headerName: 'Date',
					field: 'date',
					filter: 'agDateColumnFilter',
					cellClass: 'date',
					floatingFilterComponent: DatePickerRangeFilter,
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					filterParams: {
						filterOptions: ['inRange', 'empty'],
						suppressAndOrCondition: true,
						inRangeInclusive: true,
						comparator: (filterValue: Date, cellValue: number) => {

							let filter = parseInt(moment(filterValue).startOf('day').format('x'));
							let cell = parseInt(moment(cellValue).startOf('day').format('x'))

							if (cell < filter) {
								return -1;
							} else if (cell > filter) {
								return 1
							}

							return 0
						}
					},
					cellRenderer: (params) => {
						if (params.node.level == 0 && params.value) {
							if (params.type == 'excel') {

								return moment(params.value, 'x').format('YYYY-MM-DD')
							}

							return moment(params.value, 'x').format('DD/MM/YYYY')
						}
						return '';
					},
					aggFunc: 'first'
				}, {
					headerName: 'Heure',
					field: 'date',
					filter: 'agDateColumnFilter',
					cellClass: 'date',
					floatingFilterComponent: DatePickerRangeFilter,
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					filterParams: {
						filterOptions: ['inRange', 'empty'],
						suppressAndOrCondition: true,
						inRangeInclusive: true,
						comparator: (filterValue: Date, cellValue: number) => {

							let filter = parseInt(moment(filterValue).startOf('day').format('x'));
							let cell = parseInt(moment(cellValue).startOf('day').format('x'))

							if (cell < filter) {
								return -1;
							} else if (cell > filter) {
								return 1
							}

							return 0
						}
					},
					cellRenderer: (params) => {
						if (params.node.level == 0 && params.value) {
							if (params.type == 'excel') {

								return moment(params.value, 'x').format('HH:mm');
							}

							return moment(params.value, 'x').format('HH:mm');
						}
						return '';
					},
					aggFunc: 'first'
				}, {
					headerName: 'Fournisseur',
					field: 'orderData.providerObject',
					filter: 'agSetColumnFilter',
					filterValueGetter: (params) => {
						return _.get(params.data, 'orderData.providerObject.name');
					},
					cellRenderer: (params) => {
						if (params.node.level == 0 && params.value) {
							if (params.value && params.value.name) {
								return params.value.name
							} else {
								return params.value
							}
						}
						return ''
					},
					aggFunc: 'first'
				}, {
					headerName: 'Référence',
					field: 'product',
					aggFunc: 'concat'
				}, {
					headerName: 'Quantité Commandée',
					children: [{
						headerName: 'Valeur',
						field: 'orderedQuantity',
						filter: 'agNumberColumnFilter',
						cellClass: (params) => {

							if (params.node.rowPinned == 'bottom') {
								return 'text-right';
							}

							return ['text-right', 'number']
						},
						cellRenderer: (params) => {
							if (params.node.rowPinned) {
								return params.value;
							} else {
								let unit = this.getUnit(params)
								let value = ''
								if (unit) {
									value = displayWeight(params.value, unit)
								}

								if (params.type == 'excel') {
									return parseFloat(value.replace(/\s/g, '').replace(/,/g, '.'))
								}

								return value;
							}
						},
						aggFunc: 'sum',
					},
					{
						headerName: 'Unité',
						field: "orderedQuantity",
						aggFunc: 'sum',
						width: 80,
						suppressSizeToFit: true,
						cellRenderer: (params) => {

							if (params.value) {
								return this.getUnit(params) || ''
							}

							return ''
						}
					}]
				},
				{
					headerName: 'Quantité Livrée',
					children: [{
						headerName: 'Valeur',
						field: 'deliveredQuantity',
						cellClass: (params) => {

							if (params.node.rowPinned == 'bottom') {
								return 'text-right'
							}

							return ['text-right', 'number']
						},
						filter: 'agNumberColumnFilter',
						cellRenderer: (params) => {
							if (params.node.rowPinned) {
								return params.value;
							} else {
								let unit = this.getUnit(params)
								let value = '';
								if (unit) {
									value = displayWeight(params.value, unit)
								}

								if (params.type == 'excel') {
									return parseFloat(value.replace(/\s/g, '').replace(/,/g, '.'))
								}

								return value
							}
						},
						aggFunc: 'sum'
					},
					{
						headerName: 'Unité',
						field: "deliveredQuantity",
						aggFunc: 'sum',
						width: 80,
						suppressSizeToFit: true,
						cellRenderer: (params) => {

							if (params.value) {
								return this.getUnit(params) || ''
							}

							return ''
						}
					}]
				},
				{
					headerName: 'Detail',
					field: 'orderData.detail',
					hide: true,
					cellRenderer: (params) => {
						if (params.node.level == 0 && params.value) {
							return params.value
						}
						return ''
					},
					aggFunc: 'first'
				},
				{
					headerName: 'Client',
					field: 'orderData.customerObject',
					hide: true,
					filter: 'agSetColumnFilter',
					filterValueGetter: (params) => _.get(params.data, 'orderData.customerObject.name'),
					cellRenderer: (params) => {
						if (params.node.level == 0 && params.value) {
							if (params.value && params.value.name) {
								return params.value.name
							} else {
								return params.value
							}
						}
						return ''
					},
					aggFunc: 'first'
				},
				{
					headerName: 'Chauffeur',
					hide: true,
					field: 'driver',
					aggFunc: 'first'
				},
				{
					headerName: 'Transporteur',
					hide: true,
					field: 'carrier',
					aggFunc: 'first',
					filter: 'agSetColumnFilter'
				},
				{
					headerName: 'Type de Transport',
					hide: true,
					field: 'transportType',
					filter: 'agSetColumnFilter',
					aggFunc: 'first',
					filterParams: {
						cellRenderer: (params: any) => {
							let choices: any = {
								truck: 'Camion',
								tractor: 'Tracteur'
							};
							return (choices[params.value]) || '';
						}
					},
					cellRenderer: (params) => {
						let choices: any = {
							truck: 'Camion',
							tractor: 'Tracteur'
						};
						return (choices[params.value]) || '';
					}
				},
				{
					headerName: 'Emplacement',
					hide: true,
					field: 'storage',
					aggFunc: 'first'
				},
				{
					headerName: 'Immatriculation',
					hide: true,
					field: 'immatriculation',
					aggFunc: 'first',
					filter: 'agSetColumnFilter',
					cellRenderer: (params) => {
						return (params.value || '').toUpperCase();
					}
				},
				{
					headerName: 'Commune de chargement',
					hide: true,
					field: 'loadingZone',
					aggFunc: 'first'
				},
				{
					headerName: 'Parcelle',
					hide: true,
					field: 'parcel',
					aggFunc: 'first'
				},
				{
					headerName: 'Critère qualité',
					children: [{
						headerName: 'Potentiel Methane',
						hide: true,
						field: 'qualityCriteria.potentialMethane',
						cellClass: ['text-right', 'number'],
						width: 100,
						aggFunc: 'concat'
					},
					{
						headerName: 'Freinte',
						hide: true,
						field: 'qualityCriteria.wastage',
						cellClass: ['text-right', 'number'],
						width: 100,
						aggFunc: 'concat'
					},
					{
						headerName: 'M.O.',
						hide: true,
						field: 'qualityCriteria.organicMatter',
						cellClass: ['text-right', 'number'],
						width: 100,
						aggFunc: 'concat'
					},
					{
						headerName: 'M.S.',
						hide: true,
						field: 'qualityCriteria.dryMatter',
						cellClass: ['text-right', 'number'],
						width: 100,
						aggFunc: 'concat'
					}]
				},
				{
					headerName: '',
					field: '_id',
					colId: 'actions',
					filter: false,
					resizable: false,
					pinned: 'right',
					width: 100,
					cellRenderer: (params) => {
						const div = document.createElement('div')
						if (params.node.level == 0 && params.value) {
							const infoButton = document.createElement('button')

							infoButton.classList.add('btn', 'btn-xs', 'btn-info')
							infoButton.setAttribute('permission', "ORDERS.BL.EDIT")

							infoButton.innerHTML = '<i class="icon icon-solid-eye"></i>'

							infoButton.addEventListener('click', () => {
								let modal = new DetailDelivery(params.value);
								modal.open().then((edited) => {
									_.isEqual(!!edited, true) && this.updateGrid()
								})
							})

							div.appendChild(infoButton)

							const data = params.node.data || params.node.aggData

							const deleteButton = document.createElement('button')

							deleteButton.classList.add('btn', 'btn-xs', 'btn-danger')
							deleteButton.setAttribute('confirmation', "")

							if (!data.isTransaction) {
								deleteButton.setAttribute('permission', "ORDERS.BL.DELETE")
							} else {
								deleteButton.setAttribute('permission', "WEIGHBRIDGE.TRANSACTIONS.DELETE")
							}

							deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"> </i>'

							deleteButton.addEventListener2('click', async () => {
								const orderId = data.orderData ? data.orderData._id : params.node.allLeafChildren[0].data.orderData._id
								await this.deleteRow(params.value, orderId)
								toastr.success(`Suppression du BL : ${params.value}`)
								this.updateGrid()
							})

							_.isEqual(_.includes(['billed', 'archived'], data.state), false) && div.appendChild(deleteButton)

							const attachments = (params.node.data) ? data._attachments : params.node.allLeafChildren[0].data._attachments

							if (attachments) {

								const N_attachments = document.createElement('button')

								N_attachments.classList.add('btn', 'btn-xs', 'btn-grey')
								N_attachments.innerHTML = `<i class="icon icon-solid-paperclip"></i>`

								N_attachments.addEventListener('click', () => {

									const values = Object.keys(attachments)

									if (values.length == 1) {

										window.open(`${global.COUCHDB_URL}/delivery-notes/${data._id}/${values[0]}`, 'target')

									} else {

										new M_file('delivery-notes', data._id, values).open()

									}

								})

								div.appendChild(N_attachments)

							}


						}

						return div
					},
					aggFunc: 'first'
				},
			],
			getContextMenuItems: (params) => {

				const result: any[] = []

				if (params.columnApi) {
					const columnKeys = AGGridUtils.getColumnsIds(this.gridOptions, 'actions')

					const exportAllExcel = {
						name: 'Exporter tous Excel',
						action: () => {
							const exp = new Export(params)
							exp.exportExcel({
								columnKeys
							})
						}
					};

					const exportAllCSV = {
						name: 'Exporter tous CSV',
						action: () => {
							const exp = new Export(params)
							exp.exportCSV({
								columnKeys
							})
						}
					}

					result.push(exportAllExcel, exportAllCSV)
				}

				const data = params.node.aggData || params.node.data;

				if (data) {

					const detail = {
						name: "Voir détails",
						action: () => {
							if (data) {
								const modal = new DetailDelivery(data._id);
								modal.open();
							}
						}
					}
					result.push(detail)

					const duplicate = {
						name: "Dupliquer",
						action: () => {
							if (data) {
								const modal = new AddEditDelivery(data._id, true)
								modal.open().then(() => this.updateGrid())
							}
						}
					}

					LoggedUser.getInstance().hasPermission('ORDERS.BL.MENU.DUPLICATE') && result.push(duplicate)
				}

				if (this.gridOptions.api) {

					const rangeSelection = this.gridOptions.api.getCellRanges()

					if (rangeSelection && rangeSelection.length == 1) {

						const range = rangeSelection[0]

						const colDef = params.column.getColDef()

						const rangeCM = {
							name: "Définir la valeur de " + colDef.headerName,
							action: async () => {

								const value = await new Prompt().open()
								const ids = new Set<string>()

								if (params.api && range.startRow && range.endRow) {

									// const start = Math.min(range.start.rowIndex, range.end.rowIndex)
									// const end = Math.max(range.start.rowIndex, range.end.rowIndex)
									const start = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
									const end = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)

									// Récupération de la clé
									params.api.forEachNode(node => {
										if (node.rowIndex && start <= node.rowIndex && node.rowIndex <= end) {
											const data = node.aggData || node.data
											ids.add(data._id)
										}
									})
								}

								try {

									const operationType = colDef.field?.replace('qualityCriteria.', "")
									await DeliveryNotesService.getInstance().setMultipleCriteria(
										operationType as UnionTypeQualityCriteria,
										Array.from(ids),
										+value
									)

									await this.createEditEventForDeliveries(Array.from(ids))
									this.updateGrid()
									toastr.success('Sauvegarde réussi')
								}
								catch (e) {
									console.error(e)
									toastr.error(`Erreur de la lors sauvegarde`)
								}

							}
						}

						result.push(rangeCM)
					}

				}

				return result

			},
			groupMultiAutoColumn: true,
			groupSuppressAutoColumn: true,
			suppressAggFuncInHeader: true,
			suppressDragLeaveHidesColumns: true,
			suppressMultiRangeSelection: true,
			aggFuncs: {
				concat: (params) => _.compact(params.values).join(', ')
			},
			getRowClass: (params) => {
				const data = params.node.aggData || params.data;
				return data && stateList[data.state] ? 'ag-row-' + stateList[data.state].cls : ''
			},
			defaultColDef: {
				floatingFilter: true,
				filter: 'agTextColumnFilter',
				suppressMenu: true,
				resizable: true,
				sortable: true,
				filterParams: {
					filterOptions: ['contains'],
					textCustomComparator: TextFilterAccent,
					excelMode: 'windows'
				}
			},
			onRangeSelectionChanged: (params) => {
				const range = this.gridOptions.api?.getCellRanges()
				const hasColumn = !!(params.started && range && range[0] && range[0].columns)

				if (hasColumn) {
					const doesRangeIncludequalityCriteria = !['qualityCriteria.potentialMethane', 'qualityCriteria.wastage', 'qualityCriteria.organicMatter', 'qualityCriteria.dryMatter'].includes(range![0].columns[0].getId())
					doesRangeIncludequalityCriteria && this.gridOptions.api?.clearRangeSelection()
				}

			},
			onRowDataChanged: () => this.updatePinnedRowData(),
			onFilterChanged: () => this.updatePinnedRowData(),
			processCellForClipboard: (params) => this.processCellCallback(params as any)
		})

		const N_grid = this.el.querySelector('#grid-delivery') as HTMLElement;
		this.gridOptions && new Grid(N_grid, this.gridOptions)

		const addDeliveryBtn = this.el.querySelector('#button-add-delivery') as HTMLButtonElement;
		addDeliveryBtn.addEventListener('click', () => {
			const modal = new AddEditDelivery();
			modal.open().then(() => this.updateGrid())
		})

		const N_filterDelivery = this.el.querySelector('#quick-filter-delivery') as HTMLInputElement
		N_filterDelivery.addEventListener2('input', () => this.gridOptions.api?.setQuickFilter(_.deburr(N_filterDelivery.value)))
	}

	private processCellCallback(params: ProcessCellForExportParams) {

		const colDef = params.column.getColDef()

		let value = params.value || '';

		if (colDef && colDef.cellRenderer && typeof colDef.cellRenderer == 'function') {
			value = (colDef.cellRenderer as ICellRendererFunc)({
				data: (params.node || { data: {} }).data,
				colDef,
				...params
			});
		}

		return value;
	}

	public async updateSelectSite(_value: string) {
		this.stateAGrid && this.stateAGrid.save()

		const currentFilterModel = this.gridOptions.api?.getFilterModel() || {}

		this.stateAGrid = new AgGridStateSaver(this.gridOptions, global.SITE + "_deliveryNotes", `Onglet Bon de Livraison ${global.SITE}`)
		await this.updateGrid()
		this.stateAGrid.load()
		//Garder le filtre du site précédent
		if (_.isEmpty(currentFilterModel)) {
			const values = this.states.filter(state => state !== 'all' && !!stateList[state]).map(state => stateList[state].name)
			this.gridOptions.api?.setFilterModel({state: {type: 'set',values}})
		} else {
			this.gridOptions.api?.setFilterModel(currentFilterModel)
		}
		this.gridOptions.api?.onFilterChanged()

	}

	private updatePinnedRowData() {

		const orderedQuantity: { [key: string]: any } = {}
		const deliveredQuantity: { [key: string]: any } = {}

		if (this.gridOptions.api) {

			this.gridOptions.api.forEachNodeAfterFilter((node) => {

				const data = _.cloneDeep(node.data)

				if (data) {

					let valueOrdered = data.orderedQuantity;
					let valueDelivery = data.deliveredQuantity;

					if (data.unit == 'tonne') {
						valueOrdered *= unitList['tonne'].coeficient;
						valueDelivery *= unitList['tonne'].coeficient;
						data.unit = 'kg';
					}

					orderedQuantity[data.unit] = orderedQuantity[data.unit] || [];
					deliveredQuantity[data.unit] = deliveredQuantity[data.unit] || [];

					orderedQuantity[data.unit].push(valueOrdered);
					deliveredQuantity[data.unit].push(valueDelivery);

				}

			})

			const average: { [key: string]: any } = {
				orderedQuantity: '',
				deliveredQuantity: ''
			}

			const sum: { [key: string]: any } = {
				orderedQuantity: '',
				deliveredQuantity: ''
			}

			for (let unit in orderedQuantity) {
				const value = _.sum(orderedQuantity[unit]);
				sum.orderedQuantity += `${humanizeNumber(value)} ${unitList[unit].unit} `
				const valueAverage = humanizeNumber(_.round(value / orderedQuantity[unit].length, 2))
				average.orderedQuantity += `${valueAverage} ${unitList[unit].unit} `
			}

			for (let unit in deliveredQuantity) {
				let value = _.sum(deliveredQuantity[unit]);
				sum.deliveredQuantity += `${humanizeNumber(value)} ${unitList[unit].unit} `;
				const valueAverage = humanizeNumber(_.round(value / deliveredQuantity[unit].length, 2))
				average.deliveredQuantity += `${valueAverage} ${unitList[unit].unit} `
			}

			average.deliveryNumber = 'Moyenne';
			sum.deliveryNumber = 'Somme';

			this.gridOptions.api.setPinnedBottomRowData([average, sum]);

		}

	}

	private initQuickFilter() {

		const N_quickFilters = this.el.querySelector("#quick-filters") as HTMLElement		

		this.states.forEach(key => {

			const state = stateList[key]

			const N_btn = h(`button.btn.btn-sm.btn-rounded.mr-1.btn-state-${state.cls}`, state.name, {
				onclick: () => {

					if (this.gridOptions && this.gridOptions.api) {

						if (key == 'all') {

							const values = this.states.map(key => stateList[key]?.name || false).filter(Boolean)

							this.gridOptions.api.setFilterModel({
								state: {
									type: 'set',
									values
								}
							})

						}
						else {
							this.gridOptions.api.setFilterModel({
								state: {
									type: 'set',
									values: [state.name]
								}
							})
						}

						this.gridOptions.api.onFilterChanged()
					}
				}
			})

			N_quickFilters.appendChild(N_btn)

		})

	}

	private async getRowData2(): Promise<Array<TabDeliveryNoteRow>> {
		const deliveryNotes = await DeliveryNotesService.getInstance().getBySite(global.SITE)

		const ordersNumber = _.uniq(deliveryNotes.map(deliveryNote => deliveryNote.order))
		const orders = await OrdersService.getInstance().getByIds(ordersNumber)

		const providersNumber = orders.map(order => order.provider)
		const customersNumber = orders.map(order => order.customer)
		const tiersNumber = _.uniq([...providersNumber, ...customersNumber])

		const tiers = await TiersService.getInstance().getByIds(tiersNumber)

		const result: Array<TabDeliveryNoteRow> = deliveryNotes.reduce((cumul, deliveryNote) => {
			const order = orders.find(o => o._id == deliveryNote.order)!
			cumul.push({
				...deliveryNote, orderData: {
					...order,
					customerObject: tiers.find(t => t._id == order.customer)!,
					providerObject: tiers.find(t => t._id == order.provider)!
				}
			})
			return cumul
		}, [] as Array<TabDeliveryNoteRow>)

		return result
	}

	private async updateGrid() {

		try {
			//recuperation des données de la base
			const data = await this.getRowData2();
			//insertion des donnée dans le tableau /// syntaxe possible : this.gridOptions.api.setRowData(this.data);
			if (this.gridOptions.api && this.gridOptions.api && this.gridOptions.columnApi) {

				let rows = []

				for (let d of data) {
					if (d.products && d.products.length) {
						for (let element of d.products) {

							rows.push({
								groupKey: d.products.length > 1 ? d.deliveryNumber : null,
								...d,
								product: element.reference,
								unit: element.unit,
								qualityCriteria: element.qualityCriteria,
								orderedQuantity: element.orderedQuantity / unitList[element.unit].coeficient,
								deliveredQuantity: element.deliveredQuantity / unitList[element.unit].coeficient
							})
						}
					}
					else {
						rows.push({ ...d })
					}
				}

				this.gridOptions.api.setRowData(rows)
			}

		}
		catch (e) {
			toastr.error("Echec du chargement des données", "Erreur");
		}
	}

	public destructor() {
		this.stateAGrid?.save()
	}

	private async deleteRow(id: string, orderId: string) {
		const loggedUser = LoggedUser.getInstance()
		await DeliveryNotesService.getInstance().delete(id)
		await OrdersService.getInstance().updateOrder(orderId)
		await OrdersService.getInstance().updateDeliveryProducts(orderId)
		await DeliveryNotesService.getInstance().createEvent({
			type: 'delete',
			date: Date.now(),
			user: loggedUser.get('ID'),
			id
		})
	}

	private getUnit(params: any) {

		let unit = null
		if (params.data && params.data.unit) {
			unit = unitList[params.data.unit].unit
		}
		else if (params.node.allLeafChildren) {
			const childrenUnitUniq = _.uniq(_.map(params.node.allLeafChildren, 'data.unit'))
			if (childrenUnitUniq.length == 1) {
				unit = unitList[childrenUnitUniq[0]].unit
			}
		}

		return unit
	}

	/**
	 * Mettre à jour tous les Bons de livraisons en ajoutant un évènement edit à ceux-ci
	 * @param ids 
	 */
	private async createEditEventForDeliveries(ids: Array<string>): Promise<void> {
		const promiseAll = ids.map(id => DeliveryNotesService.getInstance().createEvent({
			date: Date.now(),
			type: TypeDataEvent.edit,
			user: LoggedUser.getInstance().get('ID'),
			id
		}))

		await Promise.all(promiseAll)
	}
}

export default TabDeliveryNote

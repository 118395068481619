import axios from 'axios'

class UtilsService {
	private static readonly instance: UtilsService = new UtilsService

	private constructor() {}

	public static getInstance() : UtilsService {
		return UtilsService.instance
	}

	public async generateUUID() : Promise<string> {
		return axios.get('/api/utils/uuid').then(response => response.data.data)
	}
}

export default UtilsService
import { Modal, global } from "@autoprog/core-client"
import T_modal from '@tpl/modals/orders/attachments.ejs'

class Attachments extends Modal {

	public constructor(database: string, id: string, values: string[]) {
		
		super({
			tpl: T_modal({
				COUCHDB_URL: global.COUCHDB_URL,
				database,
				id,

				values
			})
		})

	}

}

export default Attachments

import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import Order from '@js/types/order';
import { FilesManagerFile } from '../FilesManager'
import FilesManager2 from '../FilesManager2'
import DataEventsService from './DataEventsService'
import { OldDataEvent } from '@js/types/data-event'
import ServiceCache from '../Cache'

export type AvailableType = 'WorkOrder' | 'DeliveryNote' | 'Bill' | 'InputTransaction'

class OrdersService {

	public static readonly TABLE = "orders"
	private static instance = new OrdersService()

	private cache?: ServiceCache

	private constructor() {
		this.cache = ServiceCache.getInstance('orders')
	}

	public static getInstance() {
		return OrdersService.instance;
	}

	/**
	 * Permet de récupérer une commande
	 * @param ID 
	 */
	public async getByID(ID: string, ignoreCache = false): Promise<Order> {

		if (ignoreCache) {
			return await axios.get(`/api/orders/${ID}`).then(response => response.data.data);;
		}

		return await this.cache?.gatherData(ID, async(ID)=>{
			return await axios.get(`/api/orders/${ID}`).then(response => response.data.data)
		})
	}

	/**
	 * Permet de récupérer les commandes en fonction des ids
	 * @param ids listes des identifiants de commandes à récupérer
	 */
	public async getByIds(ids: Array<string>): Promise<Array<Order>> {
		// TODO: utiliser allsettled pour ne pas géner l'éxécution de la requête
		// return Promise.all( ids.map( id => this.getByID(id) ) )
		return axios.post('/api/orders/by-ids', { ids }).then(response => response.data.data)
	}

	/**
	 * Permet de chercher les commandes en fonction de son site
	 * @param siteId 
	 */
	public async getBySite(siteId: string): Promise<Array<Order>> {
		return axios.get(`/api/orders/by-site/${siteId}`).then(response => response.data.data)
	}

	public async getAvailable(site: string, type: AvailableType): Promise<Array<Order>> {
		return axios.get(`/api/orders/available/${site}/${type}`).then(response => response.data.data)
	}

	/**
	 * Permet de mettre à jour le statut de la commande
	 * @param id 
	 */
	public async updateOrder(id: string): Promise<void> {
		await axios.get(`/api/orders/update-status/${id}?nocache=${new Date().getTime()}`)
	}

	/**
	 * Permet de mettre à jour les quantité délivrés dans la commande
	 * REMARK: je conseillerais d'utiliser la fonction OrdersManager.getOrderInfo()
	 */
	public async updateDeliveryProducts(id: string): Promise<void> {
		await axios.get(`/api/orders/update-products/${id}?nocache=${new Date().getTime()}`)
	}

	/**
	 * Permet de mettre ajour la facture total de la commande
	 * @param id 
	 */
	public async updateTotalBills(id: string): Promise<void> {
		await axios.get(`/api/orders/update-bills/${id}?nocache=${new Date().getTime()}`)
	}

	public async getAvailableNumber(year: number, month: number): Promise<number> {
		return axios.get(`/api/orders/available-number/${year}/${month}`).then(response => response.data.data)
	}

	public async update(order: Order): Promise<void> {
		await axios.put('/api/orders', order)
	}

	public async create(order: Order): Promise<void> {
		await axios.post('/api/orders', order)
	}

	public async syncAttachment(id: string, files: Array<FilesManagerFile>): Promise<void> {
		await FilesManager2.synchronizeFiles(OrdersService.TABLE, id, files)
	}

	public async getAvailableID(){
			let numberAvailableData = await OrdersService.getInstance().getAvailableNumber(moment().year(), moment().month() + 1);
			let numberAvailable = ("000000" + numberAvailableData).slice(-6);

			return "BC-" + moment().format('YYYY-MM-DD-') + numberAvailable;
	}


	/**
	 * Permet de créer un évènement dans une commadne
	 * @param id 
	 * @param event 
	 */
	public async createEvent(event: Omit<OldDataEvent, 'table'>) {
		await DataEventsService.getInstance().create({ ...event, table: OrdersService.TABLE })
	}

	/**
	 * Permet de changer le status d'un Bon de commande
	 * @param id identifiant du bon de commande
	 * @param status En quoi changer le status
	 */
	public async setState(id: string, status: string): Promise<void> {
		await axios.post('/api/orders/state', { id, status })
	}

	/**
	 * Permet de supprimer une commande
	 * @param id 
	 */
	public async delete(id: string): Promise<void> {
		await axios.get(`/api/orders/tag-delete/${id}`)
	}

	/**
	 * Va tagguer la commande comme valide au moins un fois
	 * @param id 
	 */
	public async validate(id: string): Promise<void> {
		await axios.get(`/api/orders/tag-validated/${id}`)
	}

	/**
	 * Va tagguer la commande comme ete prevalide au moins une fois
	 * @param id 
	 */
	public async prevalidate(id: string): Promise<void> {
		await axios.get(`/api/orders/tag-prevalidated/${id}`)
	}

	public async isFullyBilled(id: string): Promise<boolean> {
		return axios.get(`/api/orders/totally-billed/${id}`).then(response => response.data.data)
	}

	public async archived(id: string): Promise<void> {
		await axios.get(`/api/orders/archived/${id}`)
	}

	public async unarchived(id: string) : Promise<void> {
		await axios.get(`/api/orders/unarchived/${id}`)
	}

	public async hasAttachedBilled(id: string) : Promise<boolean> {
		return axios.get(`/api/orders/has-attached-billed/${id}`).then(response => response.data.data)
	}

	public async getAttachedNotBilled(id: string) : Promise<{ deliveryNotes: Array<string>, workOrders: Array<string> }> {
		return axios.get(`/api/orders/get-attached-not-billed/${id}`).then(response => response.data.data)
	}

	public async getPDF(id: string) : Promise<Blob> {
		return axios.get(`/api/orders/pdf/${id}`, { responseType: "blob" }).then(response => response.data)
	}

}

export default OrdersService

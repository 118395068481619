module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<a href="#exploitation?checklist=' +
((__t = (checklistID)) == null ? '' : __t) +
'">Saisir</a>';

}
return __p
}
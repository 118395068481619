import axios from 'axios'
import { global, toaster } from "@autoprog/core-client"
import T_attachments from '@tpl/blocks/attachmentTemplate.ejs';
import { CouchDBAttachments } from '@js/types/couchDB';
import h from 'hyperscript'
import _ from 'lodash'

export type FilesManagerFile = {
	name: string
	length: number
	type: string
	content?: File
}

export type FilesManagerFiles = {
	[key: string]: FilesManagerFile
};

export type CouchDBAttachment = {
	content_type: string,
	revpos: number,
	digest: string,
	length: number,
	stub: boolean,
	name?: string
}

export type CouchDBData = {
	_id: string,
	_rev: string,
	_attachments: {
		[key: string]: CouchDBAttachment

	},
	[key: string]: any
}

class FilesManager {

	private el: HTMLElement;
	private files: FilesManagerFiles
	private removedFiles: FilesManagerFiles

	private limit: number;
	private allowTypes: string[]

	private N_attachmentInput: HTMLInputElement
	private N_attachmentList: HTMLElement

	/**
	 * 
	 * @param limit 
	 * @param allowTypes Types autorisés 
	 * Voir: https://developer.mozilla.org/fr/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
	 * Voir: https://www.iana.org/assignments/media-types/media-types.xhtml
	 * Utilisation d'une étoile possible pour remplacer une partie d'un type
	 */
	constructor(limit: number = Infinity, allowTypes?: string | string[]) {

		this.files = {}
		this.removedFiles = {}
		this.limit = limit;

		if (typeof allowTypes == 'string') {
			this.allowTypes = [allowTypes] || [];
		} else {
			this.allowTypes = allowTypes || [];
		}


		this.el = document.createElement('div');
		this.el.innerHTML = T_attachments({
			id: _.uniqueId()
		});

		this.N_attachmentInput = this.el.querySelector('.attachmentInput') as HTMLInputElement;
		this.N_attachmentList = this.el.querySelector('.attachmentsList') as HTMLElement;

		this.N_attachmentInput.addEventListener('input', () => {

			if (this.N_attachmentInput && this.N_attachmentInput.files) {
				for (let file of this.N_attachmentInput.files) {

					if (this.checkType(file.type)) {

						this.addFile({
							name: file.name,
							length: file.size,
							type: file.type,
							content: file
						});
					} else {
						toaster.error(`Le fichier ${file.name} ne respecte pas les conditions`)
					}
				}
			}

		})
	}
	private checkType(type: string): boolean {

		if (this.allowTypes.length) {

			for (let allowType of this.allowTypes) {
				let r = new RegExp(`${allowType.replace(/\*/g, '.*')}`);
				if (type.match(r)) {
					return true;
				}
			}

			return false;
		} else {
			return true;
		}
	}

	private addFile(file: FilesManagerFile) {
		this.files[file.name] = file;
		this.drawFile(file);

		this.N_attachmentInput.disabled = (Object.keys(this.files).length >= this.limit);

	}

	public setFiles(attachments: CouchDBAttachments) {
		for (let attachment in attachments) {
			this.addFile({
				name: attachment,
				length: attachments[attachment].length,
				type: attachments[attachment].content_type
			})
		}
	}

	private drawFile(file: FilesManagerFile) {

		if (this.N_attachmentList) {

			let N_removeBtn = h('a.remove-link', { href: '#' }, '×');
			let N_label = h('span.label', file.name);
			let N_item = h('div.attachment',
				N_label,
				N_removeBtn
			)

			N_item.addEventListener('mousemove', () => {

				if (N_label.scrollWidth > N_label.clientWidth) {
					!N_item.hasAttribute('tooltip') && N_item.setAttribute('tooltip', file.name)
				} else {
					N_item.removeAttribute('tooltip')
				}

			})


			N_removeBtn.addEventListener('click', (event: Event) => {

				if (N_item.parentNode) {
					N_item.parentNode.removeChild(N_item);
					this.removeFile(file);
				}
				event.preventDefault();

			})
			this.N_attachmentList.appendChild(N_item);

			if (N_label.scrollWidth > N_label.clientWidth) {
				N_item.setAttribute('tooltip', file.name)
			}

		}
	}

	private removeFile(file: FilesManagerFile) {

		this.removedFiles[file.name] = this.files[file.name];
		delete this.files[file.name];
		this.N_attachmentInput.disabled = (Object.keys(this.files).length >= this.limit);

	}

	public getNode(): HTMLElement {
		return this.el;
	}

	public getFiles() {
		return this.files;
	}

	public getArrayFiles() {
		return Object.values(this.files)
	}

	public getRemovedFiles() {
		return this.removedFiles;
	}

	public hasFile() {
		return this.getArrayFiles().length > 0
	}

	public updateDocument(table: string, id: string): Promise<void> {

		return new Promise((resolve, reject) => {

			let addPromise : Promise<void> = new Promise((resolve, reject) => {

				if (this.getFiles() && id) {
					this.insertAttachments(table, id).then(() => {
						resolve();
					}).catch((e) => {
						reject(e);
					})
				} else {
					resolve();
				}
			})

			addPromise.then(() => {

				let removePromise : Promise<void> = new Promise((resolve, reject) => {

					if (this.getRemovedFiles() && id) {
						this.removeAttachments(table, id).then(() => {
							resolve();
						}).catch((e) => {
							reject(e);
						})
					} else {
						resolve();
					}

				})

				removePromise.then(() => {
					resolve();
				}).catch((e) => {
					reject(e);
				})

			}).catch((e) => {
				reject(e);
			});
		})
	}


	//table: la table dans laquelle on travaille
	//id: l'id de l'objet dans lequel on travaille
	//index (optionnel): permet de faire un récurrence sur la fonction tant qu'il y a des fichiers à ajouter
	public insertAttachments(table: string, id: string, index?: number) : Promise<void> {

		return new Promise((resolve, reject) => {
			//Requete pour récupérer les données de l'objet dans lequel on souhaite ajouter la pièce jointe
			this.getDataByID(table, id).then((result) => {

				index = index || 0;
				let keys = Object.keys(this.files);
				let file = this.files[keys[index]];
				index++;
				//récurrence s'il y a un fichier
				if (file) {
					//s'il y a un contenu (fichier qui n'est pas encore dans l'objet)
					if (file.content) {
						this.insertAttachment(table, result, file).then(() => {
							this.insertAttachments(table, id, index).then(resolve).catch(reject)
						}).catch(reject);
					} else {
						this.insertAttachments(table, id, index).then(resolve).catch(reject)
					}
				} else {
					resolve();
				}

			}).catch(reject)

		})

	}

	// table : le nom de la table dans laquelle on travaille
	// data : le fichier dans lequel on souhaite ajouter la pièce jointe
	// file : le fichier qu'on souhaite ajouter comme pièce jointe
	private insertAttachment(table: string, data: CouchDBData, file: FilesManagerFile) {
		return new Promise((resolve, reject) => {

			var reader = new FileReader();
			if (file.content) {

				reader.readAsArrayBuffer(file.content);

				reader.addEventListener('load', (e) => {
					if (e.target) {
						let fileData = (e.target as FileReader).result;

						axios.request({
							url: `${global.COUCHDB_URL}/${table}/${data._id}/${file.name}`,
							method: 'put',
							withCredentials: true,
							headers: {
								'Content-Type': file.type
							},
							params: {
								rev: data._rev
							},
							data: fileData,
						}).then((result) => {
							resolve(result);
						}).catch((e) => {
							reject(e);
						});
					}
				})
			}

		})
	}


	//table: la table dans laquelle on travaille
	//id: l'id de l'objet dans lequel on travaille
	//index (optionnel): permet de faire un récurrence sur la fonction tant qu'il y a des fichiers à supprimer
	public removeAttachments(table: string, id: string, index?: number) : Promise<void> {

		return new Promise((resolve, reject) => {
			//Requete pour récupérer les données de l'objet dans lequel on souhaite supprimer la pièce jointe
			this.getDataByID(table, id).then((result) => {

				index = index || 0;
				let keys = Object.keys(this.removedFiles);
				let removedFile = this.removedFiles[keys[index]];
				index++;
				//Si'il y a un fichier à retirer on réitère la fonction
				if (removedFile) {

					this.removeAttachment(table, result, removedFile).then(() => {
						this.removeAttachments(table, id, index).then(resolve).catch(reject)
					}).catch(reject);

				} else {
					resolve();
				}

			}).catch(reject)

		})

	}

	// table : le nom de la table dans laquelle on travaille
	// data : le fichier dans lequel on souhaite supprimer la pièce jointe
	// removedFile : le fichier qu'on souhaite supprimer
	private removeAttachment(table: string, data: CouchDBData, removedFile: FilesManagerFile) {
		return new Promise((resolve, reject) => {

			//Requete pour supprimer la pièce jointe de l'objet
			axios.request({
				url: `${global.COUCHDB_URL}/${table}/${data._id}/${removedFile.name}`,
				method: 'delete',
				withCredentials: true,
				headers: {
					'ContentType': removedFile.type
				},
				params: {
					rev: data._rev
				}
			}).then((result) => {
				resolve(result);
			}).catch((e) => {
				reject(e);
			});

		})
	}

	//Récupère un objet dans la base en fonction de son ID
	private getDataByID(table: string, id: string | null): Promise<CouchDBData> {

		return new Promise((resolve, reject) => {

			axios.get(`${global.COUCHDB_URL}/${table}/${id}`).then((response) => {
				resolve(response.data);
			}).catch((e) => {
				reject(e);

			})
		})

	}

}


export default FilesManager;


import { Tab, global, LoggedUser } from "@autoprog/core-client"
import { Grid, GridOptions, RowNode, MenuItemDef, ICellRendererFunc, ProcessCellForExportParams } from 'ag-grid-community'
import { BillsService, OrdersService, TiersService } from "@js/libs/services"

import toastr from 'toastr'
import moment from 'moment'
import h from 'hyperscript'
import 'ag-grid-enterprise'
import AgGridStateSaver from "@libs/agGrid/StateSaver2"
import AddBillModal from '@libs/modals/orders/AddBillModal'
import DetailBillModal from '@libs/modals/orders/DetailBillModal'
import '@css/orders.scss'
import stateList from '@libs/lists/stateListFemale'
import { AGGridUtils, french as agGridFrench } from '@libs/utils/agGrid'

import Export from '@libs/agGrid/Export'
import DatePickerRangeFilter from '@libs/agGrid/DatePickerRangeFilter'

import humanizeNumber from '@libs/HumanizeNumber'

import Prompt from '@libs/modals/prompt'

import M_file from '@libs/modals/orders/Attachments'
import TextFilterAccent from '@libs/agGrid/TextFilterAccent'
import StateTransaction from '@libs/lists/StateTransactionEnum'
import OldDataEvent from '@js/types/data-event'
import TypeDataEvent from '@libs/lists/TypeDataEvent'

import _ from 'lodash'
import BillType from '@js/types/billType'
import Tier from '@js/types/Tiers'

import '@css/tabs.scss'
import '@css/stateslist.scss'

import OrdersTooltips from '../../agGrid/tooltips/Bill_OrdersTooltips'
import DataEventsService from "@js/libs/services/DataEventsService"
import BDD from "@js/libs/BDD"

interface IRulesStateCustomSubmenuAction {
	[rule: string]: {
		simple: (node: RowNode, state: string) => void | Promise<void>,
		multiple: (rowNodes: Array<RowNode>, state: string) => void | Promise<void>
	}
}

interface TabBillRow extends BillType {
	customerData: Tier
	providerData: Tier
}

class Billing extends Tab {

	private gridOptions: GridOptions = <GridOptions>{};

	private stateAGrid!: AgGridStateSaver

	private states = ['all', 'waiting', 'locked', 'validated', 'paid', 'cancel_payment', 'archived'];

	private rulesState: { [key: string]: string[] } = {
		waiting: ['validated', 'locked', 'archived'],
		cancel_payment: ['validated', 'locked', 'archived'],

		validated: ['paid', 'archived'],
		paid: ['cancel_payment', 'archived'],
		locked: [],
		archived: [],
		all: ['waiting', 'validated', 'locked', 'paid', 'cancel_payment', 'archived']
	}

	// FIXME: Devrait etre fusionner avec le tableau ruleState pour plus d'homogénité
	private rulesStateCustomSubmenuAction: IRulesStateCustomSubmenuAction = {
		locked: { simple: this.lockedSubMenuActionSimple.bind(this), multiple: this.lockedSubMenuActionMultiple.bind(this) },
		paid: { simple: this.paidSubMenuActionSimple.bind(this), multiple: this.paidSubMenuActionMultiple.bind(this) },
	}

	constructor(private el: HTMLElement) {
		super(el)

		this.gridOptions = agGridFrench({
			suppressCopyRowsToClipboard: true,
			sideBar: {
				toolPanels: [{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnFilter: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true,
					}
				}],
			},
			excelStyles: [
				{
					id: 'date',
					dataType: 'DateTime',
					numberFormat: {
						format: 'dd/mm/yyyy;@',
					}
				},
				{
					id: 'number',
					dataType: 'Number'
				}
			],
			columnDefs: [
				{
					headerName: 'Facture Interne',
					field: 'billNumber',
					sort: 'desc',
					checkboxSelection: true
				}, {
					headerName: 'N° Commande',
					field: 'orders',
					tooltipField: 'orders',
					cellRenderer: (params) => {
						let result = ''

						if (params.value) {
							if (params.type == 'excel') {
								return params.value.join(', ')
							}
							else {
								let values = Array.from(params.value)
								let firstTwo = values.splice(0, 2)
								result = firstTwo.join(', ')
								if (values.length) {
									result += `\t(+${values.length})`
								}
							}
						}

						return result
					},
					tooltipComponent: OrdersTooltips,
				},
				{
					headerName: 'Date',
					field: 'date',
					filter: 'agDateColumnFilter',
					cellClass: 'date',
					floatingFilterComponent: DatePickerRangeFilter,
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					filterParams: {
						filterOptions: ['inRange', 'empty'],
						suppressAndOrCondition: true,
						inRangeInclusive: true,
						comparator: (filterValue: Date, cellValue: number) => {

							let filter = parseInt(moment(filterValue).startOf('day').format('x'));
							let cell = parseInt(moment(cellValue).startOf('day').format('x'));

							if (cell < filter) {
								return -1;
							} else if (cell > filter) {
								return 1
							}

							return 0;
						}
					},
					cellRenderer: (params) => {
						if (!params.node.rowPinned) {
							if (params.type == 'excel') {

								return moment(params.value, 'x').format('YYYY-MM-DD');
							}

							return moment(params.value, 'x').format('DD/MM/YYYY HH:mm');
						} else {
							return '';
						}
					}
				}, {
					headerName: 'Etat',
					field: 'state',
					filter: 'agSetColumnFilter',
					filterValueGetter: (params) => {
						if (params.data && params.data.state && stateList[params.data.state]) {
							return stateList[params.data.state].name;
						}
					},
					cellRenderer: (params) => {

						if (params.value && stateList[params.value]) {
							return stateList[params.value].name
						}
						return '';

					},
				}, {
					headerName: 'Acheteur',
					filter: 'agSetColumnFilter',
					field: 'customerData.name'
				}, {
					headerName: 'Vendeur',
					filter: 'agSetColumnFilter',
					field: 'providerData.name'
				},
				{
					headerName: 'Date échéance',
					field: 'dueDate',
					filter: 'agDateColumnFilter',
					cellClass: 'date',
					floatingFilterComponent: DatePickerRangeFilter,
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					sortable: true,
					filterParams: {
						filterOptions: ['inRange', 'empty'],
						suppressAndOrCondition: true,
						inRangeInclusive: true,
						comparator: (filterValue: Date, cellValue: number) => {

							const filter = +moment(filterValue).startOf('day').format('x')
							const cell = +moment(cellValue).startOf('day').format('x')

							if (cell < filter) {
								return -1
							}
							else if (cell > filter) {
								return 1
							}

							return 0
						}
					},
					cellRenderer: (params) => {
						if (params.value) {
							const momentDate = moment(params.value, 'x')
							return momentDate.format('DD/MM/YYYY')
						}

						return "---"
					}
				},
				{
					headerName: 'Date fournisseur',
					field: 'providerBillDate',
					filter: 'agDateColumnFilter',
					cellClass: 'date',
					floatingFilterComponent: DatePickerRangeFilter,
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					filterParams: {
						filterOptions: ['inRange', 'empty'],
						suppressAndOrCondition: true,
						inRangeInclusive: true,
						comparator: (filterValue: Date, cellValue: number) => {

							const filter = parseInt(moment(filterValue).startOf('day').format('x'))
							const cell = parseInt(moment(cellValue).startOf('day').format('x'))

							if (cell < filter) {
								return -1;
							} else if (cell > filter) {
								return 1
							}

							return 0
						}
					},
					cellRenderer: (params) => {
						if (params.value) {
							const momentDate = moment(params.value, 'x')
							return momentDate.format('DD/MM/YYYY')
						}

						return "---"
					}
				},
				{
					headerName: 'Réglement',
					filter: 'agSetColumnFilter',
					field: 'providerData.regulationType',
					valueGetter: (params) => {

						//FIXME : En plusieur exemplaire dans le code
						let values: { [key: string]: string } = {
							transfer: 'Virement',
							bankCheck: 'Chèque',
							bankDraft: 'Traite bancaire'
						}

						return values[params.data?.providerData?.regulationType] || '';
					}
				}, 
				{
					headerName: 'Raison de bloquage',
					field: 'reasonLock',
				},
				{
					headerName: 'Facture fournisseur',
					field: 'providerBillNumber',
				},
				{
					headerName: 'Numero de lot',
					field: 'fxlCode',
				},
				{
					headerName: 'Prix HT',
					field: 'ht',
					cellClass: ['text-right', 'number'],
					valueGetter: (params) => {
						if (!params.node?.rowPinned) {
							let total = 0;
							for (let product of params.data.products) {
								total += (product.quantity * product.unitPrice) * (1 - (product.adjustment || 0) / 100);
							}
							return humanizeNumber(_.round(total, 4));
						}
						
						return params.data.ht
					},
					cellRenderer: (params) => {

						if (params.type == 'excel') {
							return parseFloat((params.value || '0').toString().replace(/\s/g, '')).toString();
						}

						return (params.value || '0') + ' €'
					}
				}, {
					headerName: 'Prix TTC',
					field: 'ttc',
					cellClass: ['text-right', 'number'],
					valueGetter: (params) => {
						if (!params.node?.rowPinned) {
							let total = 0;
							for (let product of params.data.products) {
								total += product.ttcTotal;
							}
							return _.round(total, 4);
						}
						else {
							return params.data.ttc;
						}
					},
					cellRenderer: (params) => {
						if (params.type == 'excel') {
							return parseFloat((params.value || '0').toString().replace(/\s/g, '')).toString();
						}

						return humanizeNumber((params.value || '0')) + ' €'
					}
				}, {
					headerName: 'controles',
					field: '_id',
					width: 8 + 28 * 5,
					filter: false,
					suppressSizeToFit: true,
					pinned: 'right',
					colId: 'actions',
					cellRenderer: (params) => {
						const div = document.createElement('div')

						if (params.data && !params.node.rowPinned) {
							const infoButton = document.createElement('button');
							infoButton.classList.add('btn', 'btn-xs', 'btn-info');
							infoButton.innerHTML = `<i class='icon icon-solid-eye'> </i>`;
							infoButton.title = 'Voir'

							infoButton.addEventListener('click', () => {
								new DetailBillModal(params.value).open().then((edit) => {
									if (edit) {
										new AddBillModal(params.value).open().then(() => this.updateGrid())
									}
								})

							});

							const deleteButton = document.createElement('button');

							deleteButton.classList.add('btn', 'btn-xs', 'btn-danger');
							deleteButton.setAttribute('confirmation', '');
							deleteButton.setAttribute('permission', 'ORDERS.BILL.DELETE');
							deleteButton.title = 'supprimer'
							deleteButton.innerHTML = `<i class='icon icon-solid-trash-alt'> </i>`

							deleteButton.addEventListener2('click', async () => {
								await this.deleteRow(params.value)
								this.updateGrid()
							});

							const validateButton = document.createElement('button');

							validateButton.classList.add('btn', 'btn-xs', 'btn-success');
							validateButton.setAttribute('confirmation', '');
							validateButton.title = 'Confirmation'
							validateButton.innerHTML = '<i class="icon icon-checkmark"></icon>';

							validateButton.addEventListener2('click', async () => {
								await this.updateBillState(params.data._id, StateTransaction.validated)
								this.updateGrid();
							})

							const paidButton = document.createElement('button')
							paidButton.classList.add('btn', 'btn-xs', 'btn-info')
							paidButton.setAttribute('confirmation', '')
							paidButton.title = 'Payer'
							paidButton.innerHTML = `<i class='icon icon-money-bill-alt'> </i>`;

							paidButton.addEventListener2('click', async () => {
								const prompt = new Prompt('Entrez votre référence de paiement')
								const code = await prompt.open()
								await this.fxlBill(params.data._id, code)
								this.updateGrid()
								toastr.info(`Facture : ${params.data._id} en payé`)
							})

							const devalidateButton = document.createElement('button')
							devalidateButton.classList.add('btn', 'btn-xs', 'btn-orange')
							devalidateButton.setAttribute('confirmation', '')
							devalidateButton.title = 'Devalidation'
							devalidateButton.innerHTML = `<i class='icon icon-times-circle'> </i>`

							devalidateButton.addEventListener('click', async () => {
								await this.updateBillState(params.data._id, StateTransaction.cancel_payment)
								this.updateGrid()
							})

							const archivedButton = document.createElement('button')
							archivedButton.classList.add('btn', 'btn-xs', 'btn-purple')
							archivedButton.setAttribute('confirmation', '')
							archivedButton.title = 'Archivé'
							archivedButton.innerHTML = `<i class='icon icon-solid-archive'> </i>`
							
							archivedButton.addEventListener('click', async () => {
								await this.updateBillState(params.data._id, StateTransaction.archived)
								this.updateGrid()
							})

							div.appendChild(infoButton)

							if (params.data.state !== StateTransaction.paid || LoggedUser.getInstance().hasPermission('ORDERS.BILL.CHANGE_ALL_STATE')) {
								
								if (params.data.state != StateTransaction.archived) {
									div.appendChild(deleteButton)
								}

								// Bouton payé
								if (params.data.state == StateTransaction.validated && LoggedUser.getInstance().hasPermission('ORDERS.BILL.STATE.PAID')) {
									div.appendChild(paidButton)
								}

								if (params.data.state == StateTransaction.paid && LoggedUser.getInstance().hasPermission('ORDERS.BILL.STATE.CANCEL_PAYMENT')) {
									div.appendChild(devalidateButton)
								}

								if (params.data.state == StateTransaction.waiting && LoggedUser.getInstance().hasPermission('ORDERS.BILL.STATE.VALIDATED')) {
									div.appendChild(validateButton);
								}

								if (params.data.state != StateTransaction.archived && LoggedUser.getInstance().hasPermission('ORDERS.BILL.STATE.ARCHIVED')) {
									div.appendChild(archivedButton)
								}

								

							}

							// Bouton des pieces jointes
							if (params.data._attachments) {

								let N_attachments = document.createElement('button') as HTMLButtonElement

								N_attachments.classList.add('btn', 'btn-xs')
								N_attachments.innerHTML = `<i class="icon icon-solid-paperclip"></i>`

								N_attachments.addEventListener2('click', () => {

									let values = Object.keys(params.data._attachments)

									if (values.length == 1) {
										window.open(`${global.COUCHDB_URL}/bills/${params.data._id}/${values[0]}`, 'target')
									} else {
										new M_file('bills', params.data._id, values).open()
									}

								})

								div.appendChild(N_attachments)
							}

						}

						return div;
					}
				}
			],
			// floatingFilter: true,
			groupMultiAutoColumn: true,
			groupSuppressAutoColumn: true,
			suppressAggFuncInHeader: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			rowSelection: 'multiple',

			defaultColDef: {
				floatingFilter: true,
				filter: 'agTextColumnFilter',
				resizable: true,
				sortable: true,
				suppressMenu: true,
				filterParams: {
					filterOptions: ['contains'],
					textCustomComparator: TextFilterAccent,
					excelMode: 'windows'
				}
			},
			getContextMenuItems: (params) => {

				let result: Array<MenuItemDef> = []

				if (params.columnApi) {
					const columnKeys = AGGridUtils.getColumnsIds(this.gridOptions, 'actions')

					const exportAllExcel = {
						name: 'Exporter tous Excel',
						action: () => {
							let exp = new Export(params as any);
							exp.exportExcel({
								columnKeys
							})
						}
					}

					const exportAllCSV = {
						name: 'Exporter tous CSV',
						action: () => {
							let exp = new Export(params as any);
							exp.exportCSV({
								columnKeys
							})
						}
					}

					const state = {
						name: 'Etat',
						subMenu: [] as any[]
					};

					const rowsNode = this.gridOptions.api!.getSelectedNodes()
					state.subMenu = rowsNode.length > 1
						? this.createStateMenuContextForMultiple(rowsNode)
						: this.createStateMenuContextForOne(params.node as unknown as RowNode)

					result = [...result, exportAllExcel, exportAllCSV]

					// On ajoute seulement l'état si on a des sous-menu état
					state.subMenu.length && result.push(state)

					// Crétion du bouton déselectionner tout
					if (rowsNode.length > 1) {
						const deselect: MenuItemDef = {
							name: 'Déselectionnez Tout',
							action: () => this.gridOptions.api!.deselectAll(),
							tooltip: "Permet de déselectionenr tous les factures"
						}

						result.push(deselect)
					}
					else if (params.node.data) {

						const detail = {
							name: "Voir détails",
							action: () => {
								if (params.node.data) {
									new DetailBillModal(params.node.data._id).open().then((edit) => {
										if (edit) {
											new AddBillModal(params.node.data._id).open().then(() => this.updateGrid())
										}
									})
								}
							}
						}

						result.push(detail)
					}

					// Menu debugging for Autoprog
					if (!!LoggedUser.getInstance().get("isSuperAdmin")) {
						const debugMenu = this.createDebuggingMenu(rowsNode)
						result.push(debugMenu)
					}
				}

				return result
			},
			tooltipShowDelay: 0,
			getRowClass: (params) => {
				const data = params.node.aggData || params.data;
				return (data && stateList[data.state]) ? 'ag-row-' + stateList[data.state].cls : ''
			},
			onRowDataChanged: () => this.updatePinnedRowData(),
			onFilterChanged: () => this.updatePinnedRowData(),
			onSelectionChanged: () => this.updatePinnedRowData(),
			processCellForClipboard: (params) => this.processCellCallback(params as any)
		})

		const N_grid = this.el.querySelector('#grid-billing') as HTMLElement;
		this.gridOptions && new Grid(N_grid, this.gridOptions)

		const addBillBtn = this.el.querySelector('#button-add-bill') as HTMLButtonElement;
		addBillBtn.addEventListener2('click', () => {
			const modal = new AddBillModal()
			modal.open().then(() => this.updateGrid())
		})

		const N_filterOrder = this.el.querySelector('#quick-filter-bills') as HTMLInputElement
		N_filterOrder.addEventListener2('input', () => this.gridOptions.api?.setQuickFilter(_.deburr(N_filterOrder.value)))

		this.initQuickFilter()
	}

	public async updateSelectSite(_value: string) {
		this.stateAGrid && this.stateAGrid.save()

		const currentFilterModel = this.gridOptions.api?.getFilterModel() || {}

		this.stateAGrid = new AgGridStateSaver(this.gridOptions, global.SITE + "_bill", `Onglet Facturation ${global.SITE}`)
		await this.updateGrid()
		this.stateAGrid.load()
		//Garder le filtre du site précédent
		if (_.isEmpty(currentFilterModel)) {
			const values = this.states.filter(state => state !== 'all' && !!stateList[state]).map(state => stateList[state].name)
			this.gridOptions.api?.setFilterModel({state: {type: 'set',values}})
		} else {
			this.gridOptions.api?.setFilterModel(currentFilterModel)
		}
		this.gridOptions.api?.onFilterChanged()
	}

	public destructor() {
		this.stateAGrid && this.stateAGrid.save()
	}

	private processCellCallback(params: ProcessCellForExportParams) {

		const colDef = params.column.getColDef()

		let value = params.value || '';

		if (colDef && colDef.cellRenderer && typeof colDef.cellRenderer == 'function') {
			value = (colDef.cellRenderer as ICellRendererFunc)({
				data: (params.node || { data: {} }).data,
				colDef,
				...params
			});
		}

		return value;
	}

	private getTotalSelected(): { ht: number, ttc: number } {
		if (this.gridOptions.api) {
			const selectedRows = this.gridOptions.api.getSelectedRows()
			const ttc = _.sumBy(_.flatMap(selectedRows, 'products'), 'ttcTotal')
			const ht = _.sum(_.flatMap(selectedRows, 'products').map(product => (product.quantity * product.unitPrice) * (1 - (product.adjustment || 0) / 100)))

			return { ht, ttc }
		}

		return { ht: 0, ttc: 0 }
	}

	private updatePinnedRowData() {

		const values: { [key: string]: any } = {};

		if (this.gridOptions.api) {

			this.gridOptions.api.forEachNodeAfterFilter((node) => {

				let data = _.cloneDeep(node.data);

				let ht = 0;
				let ttc = 0;

				for (let product of data.products) {
					ht += (product.quantity * product.unitPrice) * (1 - (product.adjustment || 0) / 100);
					ttc += product.ttcTotal;
				}

				if (!_.isUndefined(ht)) {

					values['ht'] = values['ht'] || [];
					values['ttc'] = values['ttc'] || [];

					values['ht'].push(ht);
					values['ttc'].push(ttc);
				}
			});

			// let average: { [key: string]: any } = {};
			let sum: { [key: string]: any } = {};
			const totalSelectedData = this.getTotalSelected()
			const totalSelected = { billNumber: 'Selectionnés', ht: humanizeNumber(totalSelectedData.ht), ttc: humanizeNumber(totalSelectedData.ttc) }

			for (let key in values) {
				let value = _.sum(values[key]) || 0
				_.set(sum, key, humanizeNumber(_.round(value, 2)));
			}

			// average.billNumber = 'Moyenne';
			sum.billNumber = 'Somme';

			this.gridOptions.api.setPinnedBottomRowData([totalSelected, sum]);

		}

	}

	private initQuickFilter() {

		const N_quickFilters = this.el.querySelector("#quick-filters") as HTMLElement;		

		this.states.forEach((key: string) => {

			let state = stateList[key];

			let N_btn = h(`button.btn.btn-sm.btn-rounded.mr-1.btn-state-${state.cls}`, state.name, {
				onclick: () => {

					if (this.gridOptions && this.gridOptions.api) {

						if (key == 'all') {

							let values = [];

							for (let key of this.states) {
								if (stateList[key]) {
									values.push(stateList[key].name)
								}
							}

							this.gridOptions.api.setFilterModel({
								state: {
									type: 'set',
									values
								}
							});

						} else {
							this.gridOptions.api.setFilterModel({
								state: {
									type: 'set',
									values: [state.name]
								}
							})
						}

						this.gridOptions.api.onFilterChanged();
					}
				}
			})

			N_quickFilters.appendChild(N_btn);

		});

	}

	private async updateGrid() {
		const currentFilterModel = this.gridOptions.api?.getFilterModel()		
		try {
			const data = await this.getRowData();
			this.gridOptions.api && this.gridOptions.api.setRowData(data);
			this.gridOptions.api?.setFilterModel(currentFilterModel);
			this.gridOptions.api?.onFilterChanged();
			setTimeout(() => this.gridOptions.api?.sizeColumnsToFit(), 50)
		}
		catch (e) {
			console.error(e)
			toastr.error('Echec du chargement des données', 'Erreur')
		}
	}

	private async lockedSubMenuActionSimple(node: RowNode, _state: string): Promise<void> {
		try {
			const prompt = new Prompt('Entrez la raison de blocage')
			const reason = await prompt.open()
			await this.lockedBill(node.data._id, reason)
			this.updateGrid()
			toastr.success('Sauvegarde réussi')
		}
		catch (e) {
			console.error(e)
			toastr.error('Erreur lors de la sauvegarde réussi')
		}
	}

	private async lockedSubMenuActionMultiple(rowsNode: Array<RowNode>, _permission: string): Promise<void> {
		try {
			const reason = await new Prompt('Entrez la raison de blocage').open()
			const promises = rowsNode.map(rowNode => this.lockedBill(rowNode.data._id, reason))
			await Promise.all(promises)
			this.updateGrid()
			toastr.success('Sauvegarde réussi')
		}
		catch (e) {
			console.error(e)
			toastr.error('Erreur lors de la sauvegarde réussi')
		}
	}

	private async paidSubMenuActionSimple(node: RowNode, _state: string) {
		const prompt = new Prompt('Entrez votre référence de paiement')
		const code = await prompt.open()
		await this.fxlBill(node.data._id, code)
		this.updateGrid()
		toastr.success('Sauvegarde reussi')
	}

	private async paidSubMenuActionMultiple(rowsNode: Array<RowNode>, _state: string): Promise<void> {
		try {
			const code = await new Prompt('Entrez votre référence de paiement').open()
			const promises = rowsNode.map(rowNode => this.fxlBill(rowNode.data._id, code))
			await Promise.all(promises)
			this.updateGrid()
			toastr.success('Sauvegarde réussi')
		}
		catch (e) {
			console.error(e)
			toastr.error('Erreur lors de la sauvegarde réussi')
		}
	}

	private async genericActionSimple(node: RowNode, state: string): Promise<void> {
		try {
			await this.updateBillState(node.data._id, state as StateTransaction)
			this.updateGrid()
			toastr.success('Sauvegarde réussi')
		}
		catch (e) {
			console.error(e)
			toastr.error('Erreur lors de la sauvegarde réussi')
		}
	}

	private async genericActionMultiple(rowsNode: Array<RowNode>, state: string): Promise<void> {
		try {
			const promises = rowsNode.map(rowNode => this.updateBillState(rowNode.data._id, state as StateTransaction))
			await Promise.all(promises)
			this.updateGrid()
			toastr.success('Sauvegarde réussi')
		}
		catch (e) {
			console.error(e)
			toastr.error('Erreur lors de la sauvegarde réussi')
		}
	}

	private createDebuggingMenu(rowsNode: Array<RowNode>): MenuItemDef {
		const menu: MenuItemDef = {
			name: "Debug",
		}

		// API mettre ajour
		const updateStates: MenuItemDef = {
			name: "Forcer la mise a jour des status des BL/BI/Commandes des factures selectionnees",
			icon: "📑",
			action: async () => {
				await Promise.allSettled(rowsNode.map(row => BillsService.getInstance().updateBillStatus(row.data._id)))
				await Promise.allSettled(rowsNode.map(row => DataEventsService.getInstance().create({
					date: Date.now(),
					id: row.data._id,
					table: "BILLS",
					type: "maintenance-autoprog-force-update-associated-elements-bill",
					user: LoggedUser.getInstance().get("ID"),
					_id: BDD.generateID()
				})))
			}
		}

		// Affected Submenu
		menu.subMenu = [updateStates]

		return menu
	}

	/**
	 * Permet de créer un menu en fonction d'un rowNode seulement
	 * Les etats sont mis en fonction de la cartographie d'action  this.rulesState
	 * @param node 
	 */
	private createStateMenuContextForOne(node: RowNode): Array<MenuItemDef> {
		let keys = _.cloneDeep(this.rulesState)
		let subMenu: Array<MenuItemDef> = []

		let loggedUser = LoggedUser.getInstance()
		// const orderLimit = UsersService.getInstance().orderLimit()

		// let total = _.sum(_.map(node.data.products, 'ttcTotal'))
		// keys.validated = loggedUser.hasPermission('ORDERS.BILL.STATE.VALIDATED') && total <= orderLimit ? keys.validated : []

		let allKeysAvailableFromCurrentStattus = keys[node.data.state] || []

		if (loggedUser.hasPermission('ORDERS.BILL.CHANGE_ALL_STATE')) {
			allKeysAvailableFromCurrentStattus = keys['all']
		}

		// Ce sont toutes les commandes comme locked/cancel_payment qui ont une spécificité
		let exceptionKeys = Object.keys(this.rulesStateCustomSubmenuAction).filter(key => allKeysAvailableFromCurrentStattus.includes(key))

		for (let key of allKeysAvailableFromCurrentStattus) {

			let permission = key

			if (loggedUser.hasPermission(`ORDERS.BILL.STATE.${permission.toUpperCase()}`) || loggedUser.hasPermission('ORDERS.BILL.CHANGE_ALL_STATE')) {

				if (!exceptionKeys.includes(key)) {
					subMenu.push({
						name: stateList[key].name,
						action: () => this.genericActionSimple(node, permission)
					})
				}
				else {
					subMenu.push({
						name: stateList[key].name,
						action: () => this.rulesStateCustomSubmenuAction[key].simple(node, permission)
					})
				}

			}

		}

		return subMenu
	}

	private createStateMenuContextForMultiple(rowsNode: Array<RowNode>): Array<MenuItemDef> {
		let subMenu: Array<MenuItemDef> = []
		const loggedUser = LoggedUser.getInstance()

		// Tous les états des lignes seletionnées
		const allStates = _.uniq(rowsNode.map(rowNode => rowNode.data.state))

		let rulesState = _.cloneDeep(this.rulesState)

		// let orderLimit = UsersService.getInstance().orderLimit()
		// const total = Math.max(...rowsNode.map(rowNode => _.sum(rowNode.data.products.map(product => product.ttcTotal))))
		// rulesState.validated = loggedUser.hasPermission('ORDERS.BILL.STATE.VALIDATED') && total <= orderLimit ? rulesState.validated : []

		// On récupère les données
		let autorizedStates = _.uniq(Object.entries(rulesState)
			.filter(entries => allStates.includes(entries[0]))
			.flatMap(entries => entries[1]))

		if (loggedUser.hasPermission('ORDERS.BILL.CHANGE_ALL_STATE')) {
			autorizedStates = rulesState['all']
		}

		let exceptionKeys = Object.keys(this.rulesStateCustomSubmenuAction).filter(key => autorizedStates.includes(key))

		for (let key of autorizedStates) {

			let newState = key.toLowerCase()

			const originStatesPossible = new Set(
				Object.entries(rulesState)
					.filter(entries => entries[1].includes(newState))
					.flatMap(entries => entries[0])
			)

			// On ne créee pas le bouton si les lignes selectionnés en contiennent pas un état possible

			if (loggedUser.hasPermission(`ORDERS.BILL.STATE.${newState.toUpperCase()}`) || loggedUser.hasPermission('ORDERS.BILL.CHANGE_ALL_STATE')) {

				// Tous les nodes de la grid ne peuvent pas se mettre à jour
				// Seulement ceux qui ont une origine possible peuvent mettre à jour vers le nouvelle état
				let rowsToUpdate = loggedUser.hasPermission('ORDERS.BILL.CHANGE_ALL_STATE') ? rowsNode : rowsNode.filter(rowNode => originStatesPossible.has(rowNode.data.state))

				if (!exceptionKeys.includes(key)) {
					subMenu.push({
						name: stateList[key].name,
						action: async () => this.genericActionMultiple(rowsToUpdate, newState)
					})
				}
				else {
					subMenu.push({
						name: stateList[key].name,
						action: () => this.rulesStateCustomSubmenuAction[key].multiple(rowsToUpdate, newState)
					})
				}

			}

		}




		return subMenu
	}

	private async getRowData(): Promise<Array<TabBillRow>> {
		const bills = await BillsService.getInstance().getBySite(global.SITE)
		const ordersIds = _.uniq(bills.map(bill => bill.orders[0]))
		const orders = await OrdersService.getInstance().getByIds(ordersIds)

		const tierIds = _.uniq(orders.map(order => [order.customer, order.provider]).flat())
		const tiers = await TiersService.getInstance().getByIds(tierIds)

		return bills.map(bill => {
			const order = orders.find(order => order._id === bill.orders[0])!
			const providerData = tiers.find(tier => tier._id === order.provider)!
			const customerData = tiers.find(tier => tier._id === order.customer)!

			return {
				...bill,
				customerData,
				providerData
			}
		})
	}

	private async deleteRow(id: string): Promise<void> {

		try {
			await BillsService.getInstance().delete(id)
			await BillsService.getInstance().createEvents({
				date: Date.now(),
				type: 'delete',
				user: LoggedUser.getInstance().get('ID'),
				id
			})
			await BillsService.getInstance().updateBillStatus(id)

			// TODO: mettre à jour la commande
			toastr.success('Facture supprimée');
		}
		catch (e) {
			toastr.error(`Erreur lors de la supression de la facture`);
			console.error(e)
		}

	}

	/**
	 * Permet de changer le status d'un facture
	 * @param id 
	 * @param status 
	 */
	private async updateBillState(id: string, state: StateTransaction): Promise<void> {
		let oldBill = await BillsService.getInstance().getByID(id, true)
		await BillsService.getInstance().setStatus(id, state)
		const dataEvent: Omit<OldDataEvent, 'table'> = {
			date: Date.now(),
			type: TypeDataEvent.edit,
			user: LoggedUser.getInstance().get('ID'),
			id,
		}

		if (oldBill.state != state) {
			dataEvent.changeState = { from: oldBill.state, to: state }
		}

		await BillsService.getInstance().createEvents(dataEvent)
		// REMARK: cela ne change pas les status des bons de commandes, des bons de livraisons, des commandes
		// await BillsService.getInstance().updateBillStatus(id)
	}

	private async lockedBill(id: string, reason: string) {
		let oldBill = await BillsService.getInstance().getByID(id)
		await BillsService.getInstance().locked(id, reason)
		const dataEvent: Omit<OldDataEvent, 'table'> = {
			date: Date.now(),
			type: TypeDataEvent.edit,
			user: LoggedUser.getInstance().get('ID'),
			id
		}

		if (oldBill.state != StateTransaction.locked) {
			dataEvent.changeState = { from: oldBill.state, to: StateTransaction.locked }
		}

		await BillsService.getInstance().createEvents(dataEvent)
	}

	private async fxlBill(id: string, code: string) {
		const oldBill = await BillsService.getInstance().getByID(id)
		await BillsService.getInstance().fxlCode(id, code)
		const dataEvent: Omit<OldDataEvent, 'table'> = {
			date: Date.now(),
			type: TypeDataEvent.edit,
			user: LoggedUser.getInstance().get('ID'),
			id
		}

		if (oldBill.state != StateTransaction.locked) {
			dataEvent.changeState = { from: oldBill.state, to: StateTransaction.paid }
		}

		await BillsService.getInstance().createEvents(dataEvent)
	}
}

export default Billing;

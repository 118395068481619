import UnitsService from "../services/UnitsService"
import Unit from "../../types/unit"

// REMARK BV: pour des raisons historiques nous avons garder cette objet unique

export interface XUnit extends Unit { }

// REMARK: voir pour les problemes de performance, mais cela evite de modifier plus de 30 fichiers
const handler: ProxyHandler<any> = {
	get: function (_, prop: string, __) {
		// REMARK: ATTENTION il prend meme les unites qui ne sont pas disponible
		return UnitsService.getInstance().getById(prop)
	},
	ownKeys: function (_) {
		// REMARK: ATTENTION il ne prend que les cles des unites qui sotn disponibles
		return UnitsService.getInstance().keys()
	},
	has: function (_, prop: string) {
		return UnitsService.getInstance().has(prop)
	},
	// Il est obligatoire pour les fonctions Object.keys() et _.keys()
	getOwnPropertyDescriptor: function (_target, _prop) {
		// TODO: l'etoffe un peu
		return {
			enumerable: true,
			configurable: true
		}
	}
}

const unitList = new Proxy({}, handler)

export default unitList

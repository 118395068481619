import { Modal, Form, global, Alert, LoggedUser } from "@autoprog/core-client"

import T_AddEditWorkOrder from '@tpl/modals/orders/addEditWorkOrder.html'
import FilesManager from '@js/libs/FilesManager'
import SelectOrders from '@js/libs/modals/SelectOrders'
import BDD from '@libs/BDD'
import { Grid, GridOptions, ICellRendererParams } from 'ag-grid-community'
import NumericCellEditor from '@libs/agGrid/NumericCellEditor'
import Select2CellEditor from '@libs/agGrid/Select2CellEditor'
import { Options } from 'select2'

import unitList from '@libs/lists/unitList'
import moment from 'moment'
import AgGridStateSaver from '@libs/agGrid/StateSaver'
import toastr from 'toastr'
import drpC from '@libs/utils/daterangepickerConfig'
import { french as agGridFrench } from '@libs/utils/agGrid'

import OrdersManager from '@js/libs/managers/OrdersManager'
import { ProductsInfoList } from '@js/libs/models/OrderInfo'

import _ from 'lodash'
import Order from '@js/types/order'
import Tier from '@js/types/Tiers'
import WorkOrder, { WorkOrderProduct } from '@js/types/workorder'
import TypeDataEvent from '@js/libs/lists/TypeDataEvent'
import EventsManager, { EventsManagerEvent } from '@js/libs/EventsManager'

import { OrdersService, TiersService, WorkOrdersService } from "@js/libs/services"

type DictionnaryProductsDelivered = { [idRowProduct: string]: number }

interface OrderOfWorkOrder extends Order {
	providerData: Tier
	customerData: Tier
}

interface WorkOrderData extends WorkOrder {
	orderData: Order,
	providerData: Tier
	customerData: Tier
	beginDateString: string
	endingDateString: string
}

class AddEditWorkOrder extends Modal {

	private gridOptions: GridOptions = {};
	private filesManager: FilesManager = new FilesManager();
	private formWorkOrder?: Form;

	private isInfiniteBudget: boolean = false

	private orderInfo: ProductsInfoList

	constructor(private idWorkOrder?: string, private duplicateMode: boolean = false) {
		super({
			tpl: T_AddEditWorkOrder,
			backdrop: 'static',
			keyboard: false
		})

		this.orderInfo = {}

		this.on('opened', () => {

			try {

				this.formWorkOrder = new Form(this.element.querySelector('#work-order-form') as HTMLFormElement);

				//On récupère le template du fileManager
				const N_attachments = this.element.querySelector('#attachments') as HTMLElement;
				N_attachments.appendChild(this.filesManager.getNode());

				//dateRangePicker
				$(this.element).find('.date-picker').daterangepicker(drpC({
					locale: {
						format: "DD/MM/YYYY HH:mm"
					},
					singleDatePicker: true,
					timePicker: true,
					timePicker24Hour: true,
					timePickerIncrement: 5,
					applyButtonClasses: 'btn-primary',

				}));

				this.createAgGrid()


				//Récupération du bouton "Ajout BC"
				const addLinkedOrderBtn = this.element.querySelector('#btnAddLinkedOrder') as HTMLButtonElement;
				const btnAddLine = this.element.querySelector("#addproductLine") as HTMLButtonElement;

				if (this.idWorkOrder) {
					addLinkedOrderBtn.disabled = true;
				}

				addLinkedOrderBtn.addEventListener2('click', () => {
					let modal = new SelectOrders('BI');
					this.hide();
					modal.open().then((idOrder: string) => {
						this.show();
						this.loadOrder(idOrder)
							.then(() => btnAddLine.removeAttribute("hidden"))
					}).catch(() => { this.show() })
				})


				//Event sur clique bouton "Sauvegarder"
				const N_saveBtn = this.element.querySelector('#save') as HTMLButtonElement;
				N_saveBtn.addEventListener2('click', () => this.save())

				this.idWorkOrder && this.loadWorkOrder().then(() => btnAddLine.removeAttribute("hidden"))
			}
			catch (e) {
				console.log(e)
			}

		})
	}

	private async save() {

		// On vérifie que la quantité est supérieur à zéro, peut avoir zéro si il y a multiple produits (quantité minimum pour au moins un produit)
		if (this.hasZeroCommand()) {
			Alert.info('Commande impossible', 'Il faut au minimum une commandes avec une quantité non négative.');
			return
		}

		if (!this.isInfiniteBudget && !LoggedUser.getInstance().hasPermission('ORDERS.BI.OPTIONS.LIMIT_QUANTITY') && this.hasExceedLimitOrder()) {
			Alert.error('Commande Impossible', "Vous avez excéder la limite de la commande autorisé")
			return
		}

		//TODO: vérifier que la commande est bien faite

		if (this.checkValidity()) {

			try {
				//On ajoute le BI dans la base
				const workOrderID = await this.createWorkOrder()
				await WorkOrdersService.getInstance().syncAttachments(workOrderID, this.filesManager.getArrayFiles())

				if (this.idWorkOrder) {
					toastr.success(`Bon d'intervention modifié`)
				}
				else {
					toastr.success(`Bon d'intervention ajouté`)
				}

				this.resolve()
			}
			catch (e) {
				this.reject(e);
				toastr.error('Erreur de base de données');
				console.error(e);
			}



		}

	}

	private createAgGrid(): void {

		//DeliveredProductsGrid
		const N_gridWorkOrderProduct = this.element.querySelector('#grid-workOrderProducts') as HTMLElement;
		const btnAddLine = this.element.querySelector("#addproductLine") as HTMLButtonElement;


		this.gridOptions = agGridFrench({
			//définition des colonnes du tableau
			columnDefs: [
				{
					headerName: 'Produit',
					field: 'reference',
					editable: true,
					cellEditor: Select2CellEditor as any,
					cellEditorParams: (_params: any) => { // requete ajax sur la base products
						return {
							options: ({
								//tags: true,
								ajax: {
									url: `api/products/search/select2`,
									data: function (params) {
										return {
											...params,
											site: global.SITE
										}
									}
								}, dropdownParent: $(N_gridWorkOrderProduct as HTMLElement)
							} as Options)
						}
					}
				},
				{
					headerName: 'Unité',
					field: 'unit',
					editable: true,
					cellEditor: 'agRichSelectCellEditor',
					cellEditorParams: {
						values: _.keys(unitList),
						cellRenderer: (params: ICellRendererParams) => unitList[params.value]?.name || ''
					},
					cellRenderer: (params) => unitList[params.value]?.name || params.value
				},
				{
					headerName: 'Qté commandée',
					field: 'orderedQuantity',
					editable: true,
					cellEditor: NumericCellEditor,
					cellClass: 'ag-cell-focused-grey'
				},
				{
					headerName: 'Qté déjà livrée',
					field: 'alreadyWorkOrderQuantity',
					cellClass: 'ag-cell-focused-grey'
				},
				{
					headerName: 'Qté livrée',
					field: 'deliveredQuantity',
					editable: true,
					cellEditor: NumericCellEditor
				},
				{
					headerName: 'Prix Unitaire HT',
					field: 'unitPrice',
					editable: true,
					cellEditor: NumericCellEditor,
					cellRenderer: (params) => {
						if (params.value || params.value === 0) {
							return `${_.round(params.value, 2) || ''} €`
						}
						return ''
					}
				},
				{
					headerName: 'Total HT',
					field: 'totalHT',
					cellRenderer: (params) => {
						if (params.value || params.value === 0) {
							return (_.round(params.value, 2) || '').toString() + '€';
						}
						return '';
					}
				},
				{
					headerName: '',
					field: 'suppress',
					cellRenderer: (params : ICellRendererParams) => {
						let div = document.createElement('div') as HTMLElement;
						if (!params.node.rowPinned) {

							let suppressLineBtn = document.createElement('button') as HTMLButtonElement;

							suppressLineBtn.innerHTML = `<span aria-hidden="true">&times;</span>`;
							suppressLineBtn.setAttribute('type', 'button');
							suppressLineBtn.setAttribute('confirmation', '');
							suppressLineBtn.classList.add('close');

							suppressLineBtn.addEventListener2('click', () => {
								params.api.applyTransaction({ remove: [params.node.data] })
							})

							div.appendChild(suppressLineBtn);
						}
						return div;
					}
				}
			],

			suppressDragLeaveHidesColumns: true,
			singleClickEdit: true,
			rowData: [],
			onCellValueChanged: (params) => {


				if (params.colDef.field == 'reference') {

					if (typeof params.newValue == 'string') {
						params.data.reference = params.newValue;
					} else {
						params.data.reference = params.value.text;
						params.data.id = params.value.id;

						if (params.value.product) {
							params.data.type = params.value.product.type;
							params.data.unit = params.value.product.unit;
							params.data.unitPrice = params.value.product.price * unitList[params.value.product.unit].coeficient;
						} else {
							params.data.id = BDD.generateID();
							params.data.type = 'custom';
							params.data.unit = null;
							params.data.unitPrice = null;
						}
					}
				}

				if (params.colDef.field == 'unitPrice') {

					if (!params.newValue) {
						params.data.unitPrice = params.oldValue;
					}
				}

				if (params.colDef.field == 'deliveredQuantity') {

					if (!params.newValue) {
						params.data.deliveredQuantity = params.oldValue;
					}

					if (params.data && params.data.orderedQuantity) {
						if (params.value > params.data.orderedQuantity) {
							params.data.deliveredQuantity = params.data.orderedQuantity;

						}
					}
				}

				if (params.data.deliveredQuantity && params.data.unitPrice) {
					params.data.totalHT = params.data.deliveredQuantity * params.data.unitPrice;
				}

				if (params.api) {
					let deliveredQuantityTotal = 0 as number;
					let costTotal = 0 as number;
					params.api.forEachNode((rowNode) => {
						deliveredQuantityTotal += rowNode.data.deliveredQuantity || 0;
						costTotal += rowNode.data.totalHT || 0;
					})
					params.api.setPinnedBottomRowData([{
						deliveredQuantity: `Total : ${deliveredQuantityTotal}`,
						totalHT: costTotal
					}]);
				}

				params.node.setData(params.data);
			},

			onCellEditingStarted: (params) => {
				if (this.gridOptions.api) {

					if (params.colDef.field != 'reference' && !params.data.reference) {
						this.gridOptions.api.stopEditing(true);
					}
					if (params.colDef.field == 'unit' && params.data.type != 'custom') {
						this.gridOptions.api.stopEditing(true);
					}
					if (params.colDef.field != 'deliveredQuantity' && params.data.locked) {
						this.gridOptions.api.stopEditing(true);
					}


				}
			},

			rowClassRules: {
				'ag-row-danger': (params) => {
					return params.data && params.data.error;
				}
			},
			onGridReady: () => {
				if (this.gridOptions && this.gridOptions.api) {
					this.gridOptions.api.sizeColumnsToFit();
				}
			}
		})

		btnAddLine.addEventListener2("click", () => {
			this.gridOptions.api?.applyTransaction({ add: [{}] })
		})

		new Grid(N_gridWorkOrderProduct, this.gridOptions)
		new AgGridStateSaver(this.gridOptions, 'workOrderProduct')
	}

	private async getOrder(idOrder: string): Promise<OrderOfWorkOrder> {
		let order = await OrdersService.getInstance().getByID(idOrder)
		let customerData = await TiersService.getInstance().getByID(order.customer)
		let providerData = await TiersService.getInstance().getByID(order.provider)

		return {
			...order,
			customerData,
			providerData
		}
	}

	private async loadOrder(idOrder: string): Promise<void> {

		let order = await this.getOrder(idOrder)
		this.isInfiniteBudget = !!order.isInfiniteBudget

		this.orderInfo = await OrdersManager.getOrderInfo(order._id)

		if (this.formWorkOrder) {
			this.formWorkOrder.setDataByName('order.detail', order.detail)
			this.formWorkOrder.setDataByName('provider', order.providerData.name)
			this.formWorkOrder.setDataByName('customer', order.customerData.name)
			this.formWorkOrder.setDataByName('orderNumber', order.orderNumber)
		}

		if (this.gridOptions.api) {

			const rows = (order.products || []).map(product => ({
				rowID: product.rowID,
				id: product.id,
				reference: product.reference,
				alreadyWorkOrderQuantity: this.orderInfo[product.rowID].getTotalOrdered() / unitList[product.unit].coeficient,
				orderedQuantity: product.quantity / unitList[product.unit].coeficient,
				deliveredQuantity: 0,
				unit: product.unit,
				unitPrice: product.unitPrice * unitList[product.unit].coeficient,
				locked: true
			}))

			this.gridOptions.api.setRowData(rows)
		}

	}

	private async getWorkOrderData(idWorkOrder: string): Promise<WorkOrderData> {
		const workOrder = await WorkOrdersService.getInstance().getByID(idWorkOrder)
		const orderData = await OrdersService.getInstance().getByID(workOrder.order)
		const [providerData, customerData] = await Promise.all([
			TiersService.getInstance().getByID(orderData.provider),
			TiersService.getInstance().getByID(orderData.customer)
		])

		return {
			...workOrder,
			orderData,
			providerData,
			customerData,
			beginDateString: "",
			endingDateString: ""
		}
	}

	private async loadWorkOrder(): Promise<void> {

		type WorkOrderRow = {
			rowID: string,
			id: string,
			reference: string,
			type: string,
			alreadyWorkOrderQuantity: number,
			orderedQuantity: number,
			deliveredQuantity: number,
			unit: string,
			unitPrice: number,
			locked: boolean,
		}

		const workOrder = await this.getWorkOrderData(this.idWorkOrder!)
		this.isInfiniteBudget = !!workOrder.orderData.isInfiniteBudget

		this.orderInfo = this.duplicateMode
			? await OrdersManager.getOrderInfo(workOrder.orderData._id)
			: await OrdersManager.getOrderInfoFilterWorkOrder(workOrder.orderData._id, workOrder._id)

		if (this.duplicateMode) {
			Reflect.deleteProperty(workOrder, '_id')
			Reflect.deleteProperty(workOrder, '_rev')
			Reflect.deleteProperty(workOrder, 'workOrderNumber')
			Reflect.deleteProperty(workOrder, 'state')
		}

		workOrder.beginDateString = moment(workOrder.beginDate, 'x').format('DD/MM/YYYY LT')
		workOrder.endingDateString = moment(workOrder.endDate, 'x').format('DD/MM/YYYY LT')

		if (this.formWorkOrder) {

			const obj = {
				...workOrder,
				orderNumber: workOrder.orderData._id,
				customer: workOrder.customerData.name,
				provider: workOrder.providerData.name,
				state: workOrder.state
			}

			this.formWorkOrder.setData(obj as any);
		}

		const hasNotPermissionToExceedLimit = !LoggedUser.getInstance().hasPermission('ORDERS.BI.OPTIONS.LIMIT_QUANTITY')

		if (this.gridOptions.api) {

			const rows: Array<WorkOrderRow> = (workOrder.products || []).map(product => {

				const alreadyWorkOrderQuantity = this.orderInfo[product.rowID].getTotalOrdered() / unitList[product.unit].coeficient
				const orderedQuantity = product.orderedQuantity / unitList[product.unit].coeficient
				let deliveredQuantity = product.deliveredQuantity / unitList[product.unit].coeficient

				const hasExceedLimit = (deliveredQuantity + alreadyWorkOrderQuantity) > orderedQuantity
				if (this.duplicateMode && !this.isInfiniteBudget && hasNotPermissionToExceedLimit && hasExceedLimit) {
					deliveredQuantity = orderedQuantity - alreadyWorkOrderQuantity
				}

				return {
					rowID: product.rowID,
					id: product.id,
					reference: product.reference,
					type: product.type,
					alreadyWorkOrderQuantity,
					orderedQuantity,
					deliveredQuantity,
					unit: product.unit,
					locked: product.locked,
					unitPrice: product.unitPrice * unitList[product.unit].coeficient
				}

			})

			this.gridOptions.api.setRowData(rows)

			for (let i = 0; i < rows.length; i++) {
				this.gridOptions.api.startEditingCell({
					rowIndex: i,
					colKey: 'deliveredQuantity'
				})

				this.gridOptions.api.stopEditing()
			}

		}

		workOrder._attachments && this.filesManager.setFiles(workOrder._attachments)
	}

	private checkValidity(): boolean {

		if (this.formWorkOrder && !this.formWorkOrder.checkValidity()) {
			return false;
		}

		let pass = true;
		if (this.gridOptions.api) {

			this.gridOptions.api.forEachNode((node) => {
				if (!node.data.unit || !node.data.unitPrice) {
					pass = false;
					node.data.error = true;
					node.setData(node.data);
				} else {
					node.data.error = false;
					node.setData(node.data);
				}
			})
		}

		return pass;

	}

	private async createWorkOrder(): Promise<string> {

		if (!this.formWorkOrder) {
			throw new Error('createWorkOrder : Form is empty')
		}

		const data = this.formWorkOrder.getData()

		const workOrder: WorkOrder = {
			workOrderNumber: (data.workOrderNumber || "BI-" + moment().format('YYYY-MM-DD-HHmmss')).toString(),
			order: data.orderNumber as string,
			beginDate: parseInt((data.beginDate as moment.Moment).format('x')),
			endDate: parseInt((data.endDate as moment.Moment).format('x')),
			detail: data.detail as string,
			comment: data.comment as string,
			deleted: false,
			products: [],
			state: data.state as string || 'notBilled',
			deliveryReceiver: data.deliveryReceiver as string,
			_id: (data.workOrderNumber || "BI-" + moment().format('YYYY-MM-DD-HHmmss')).toString()
		}

		//On récupère les lignes du tableau des produits livrées
		const rows = [] as WorkOrderProduct[]
		if (this.gridOptions.api) {
			this.gridOptions.api.forEachNode((rowNode) => {
				delete rowNode.data.totalHT
				delete rowNode.data.error
				delete rowNode.data.alreadyWorkOrderQuantity
				rows.push({ ...rowNode.data });
			})
		}

		//On met la quantité délivrée à la bonne unité
		for (let row of rows) {
			row.deliveredQuantity = row.deliveredQuantity * unitList[row.unit].coeficient;
			row.orderedQuantity = row.orderedQuantity * unitList[row.unit].coeficient;
			row.unitPrice = row.unitPrice / unitList[row.unit].coeficient
		}

		workOrder.products = rows
		const addOrEdit = this.idWorkOrder && !this.duplicateMode ? this.updateWorkOrder.bind(this) : this.addWorkOrder.bind(this)
		await addOrEdit(workOrder)
		await OrdersService.getInstance().updateDeliveryProducts(workOrder.order)
		return workOrder.workOrderNumber
	}

	private async updateWorkOrder(workOrder: WorkOrder) {
		const oldWorkOrder = await WorkOrdersService.getInstance().getByID(workOrder._id)
		workOrder.events = oldWorkOrder.events

		const event: EventsManagerEvent = {
			type: 'edit',
		}

		if (oldWorkOrder.state != workOrder.state) {
			event.changeState = {
				from: oldWorkOrder.state,
				to: workOrder.state
			}
		}

		workOrder = EventsManager.addEvent(workOrder, event)
		await WorkOrdersService.getInstance().update(workOrder)
		await OrdersService.getInstance().updateOrder(workOrder.order)
	}

	private async addWorkOrder(workOrder: WorkOrder) {
		workOrder = EventsManager.addEvent(workOrder, TypeDataEvent.create)
		await WorkOrdersService.getInstance().create(workOrder)
		await OrdersService.getInstance().updateOrder(workOrder.order)
	}

	private hasZeroCommand() {
		const rows = [] as any[]
		this.gridOptions.api?.forEachNode((rowNode) => rows.push(rowNode.data))
		return rows.filter(x => x.deliveredQuantity != 0).length === 0
	}

	public hasExceedLimitOrder(): boolean {
		const allData = this.getAllDeliveredProduct()

		// Comparaison entre les données
		for (let idRow in allData) {
			// REMARK: le produit existe forcement dans la commande
			const unit = this.orderInfo[idRow].unit
			const quantity = allData[idRow] * unitList[unit].coeficient + this.orderInfo[idRow].getTotalOrdered()
			if (quantity > this.orderInfo[idRow].maxOrdered) {
				return true
			}
		}

		return false
	}

	private getAllDeliveredProduct(): DictionnaryProductsDelivered {
		const result: DictionnaryProductsDelivered = {}

		this.gridOptions.api?.forEachNode(rowNode => {
			result[rowNode.data.rowID] = rowNode.data.deliveredQuantity
		})

		return result
	}
}

export default AddEditWorkOrder

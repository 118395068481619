import { ICellEditorComp, ICellEditorParams } from 'ag-grid-community'
import 'select2'
import _ from 'lodash'
import { DataFormat, GroupedDataFormat } from "select2"

export const CHAR_PRESS_AUTOEDIT = 'Select2CellEditorAutoSelect'

type AdditionalSelect2Option = {
	typeResponse?: 'identifier' | 'select2Object'
	default?: string
	canBeEmpty?: boolean
}

export type Select2CellEditorParams = ICellEditorParams & {
	options: Select2.Options & AdditionalSelect2Option
}

class Select2CellEditor implements ICellEditorComp {

	private eGui?: HTMLElement
	private value: any
	private params?: Select2CellEditorParams

	constructor() {

	}

	public getGui() : HTMLElement {
		return this.eGui!;
	}

	public getValue() {
		// FIXME: faire attention a ce comportement, tres dangereux
		let result = this.value.length > 1 ? this.value : _.first(this.value)

		// Dans les cas a ce niveau c'est un array, d'objet bizarre
		if (!this.params?.options.multiple && this.params?.options.typeResponse === 'identifier') {
			result = result?.id || ''
		}
		else if (this.params?.options.multiple && this.params?.options.typeResponse === 'identifier') {
			result = result.map((selected: { id: string }) => selected.id)
		}

		return result
	}

	public afterGuiAttached() {
		if (this.eGui && this.params) {
			const select = this.eGui.querySelector('.select2') as HTMLSelectElement;
			$(select).select2('open')
			const N_inputSearch = $(select).data('select2').$dropdown.find("input").get(0)
			N_inputSearch && N_inputSearch.focus()

			// Techniques pour auto-selectionner un element avec params.startEditingCell
			if (this.params.charPress === CHAR_PRESS_AUTOEDIT) {
				$(select).select2('close')
			}
		}
	}

	public isPopup() {
		return false
	}

	public init(params: Select2CellEditorParams) {

		this.params = params;
		this.value = [params.value || '']
		this.eGui = document.createElement('div')
		this.eGui.style.width = '100%'
		this.eGui.style.height = '100%'
		this.eGui.innerHTML = '<select class="select2" style="width:100%; height:100%"></select>'

		if (this.eGui) {
			const select = this.eGui.querySelector('.select2') as HTMLSelectElement

			params.options = params.options || {}
			params.options.typeResponse = params.options.typeResponse || 'select2Object'
			params.options.width = 'resolve'

			// GESTION D'UNE VALEUR EMPTY PAR DEFAUT
			// Dsl j'ai pas trouver d'autre solution que le any, Select2 c'est vraiment pourri
			// Ce cas sert pour verifier que la valeur empty par defaut ne se duplique pas, Select2Editor n'est pas detruit tant que la ligne existe dans ag-grid
			params.options.data = (Array.isArray(params.options.data) && params.options.canBeEmpty ? Array.from<{ id: string }>(params.options.data as any).filter(item => !_.isEmpty(item.id)) : params.options.data) as Array<DataFormat> | Array<GroupedDataFormat> | undefined
			params.options.canBeEmpty && params.options.data?.unshift({ id: '', text: ' ' } as never)

			// Pour un certaine raison lorsqu'on redonne le format de reponse Ag grid on perd le focus et n'arrive pas a le reselectionner, donc on le transforme en tableau d'ID
			this.value = Array.isArray(this.value) && this.value[0] && this.value[0].id && this.value[0].text ? this.value.map(v => v.id) : this.value

			$(select).select2(params.options)
			$(select)
				.on('change', () => { this.value = $(select).select2('data') })
				.on('select2:close', () => { this.params && this.params.stopEditing() })
			this.setValue(this.value)
		}

	}

	private setValue(val: string) {
		const select = this.eGui!.querySelector('.select2') as HTMLSelectElement
		$(select).val(val).trigger("change")
	}

	public destroy() {
		if (this.eGui) {
			const select = this.eGui.querySelector('.select2') as HTMLSelectElement
			$(select).select2("close")
		}
	}

}

export default Select2CellEditor;

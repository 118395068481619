import _ from 'lodash'
import axios from 'axios'
import { Tier, TierPlace } from '@js/types/Tiers';
import { FilesManagerFile } from '../FilesManager';
import FilesManager2 from '../FilesManager2';
import ServiceCache from '../Cache'

class TiersService {

	public static readonly TABLE = "tiers"
	private static instance = new TiersService()
	private cache?: ServiceCache;

	private constructor() {
		this.cache = ServiceCache.getInstance('tiers');
	}

	public static getInstance(): TiersService {
		return TiersService.instance;
	}

	public async getByID(ID: string, ignoreCache = false): Promise<Tier> {

		if (ignoreCache) {
			return await axios.get(`/api/tiers/${ID}`).then(response => response.data.data);;
		}

		return await this.cache?.gatherData(ID, async(ID)=>{
			return await axios.get(`/api/tiers/${ID}`).then(response => response.data.data);
		})
	}


	/**
	 * Permet de récupérer tous les Tiers à partir d'un tableau IDs de tiers
	 * @param ids 
	 */
	public async getByIds(ids: Array<string>): Promise<Array<Tier>> {
		// return Promise.all( ids.map( id => this.getByID(id) ) )
		return axios.post('/api/tiers/by-ids', { ids }).then(response => response.data.data)
	}

	/**
	 * Permet de questionner la base si le numero de siret est deja utilisé
	 * @param siret 
	 */
	public async doesSiretNumberExist(siret: string): Promise<boolean> {
		return axios.get(`/api/tiers/search-siret/${siret}`).then(response => response.data.data)
	}

	public async create(tier: Tier): Promise<void> {
		await axios.post('/api/tiers', tier)
	}

	public async update(tier: Tier): Promise<void> {
		await axios.put('/api/tiers', tier)
	}

	public async syncAttachment(id: string, files: Array<FilesManagerFile>): Promise<void> {
		await FilesManager2.synchronizeFiles(TiersService.TABLE, id, files)
	}

	public async getAll(): Promise<Array<Tier>> {
		return axios.get('/api/tiers').then(response => response.data.data)
	}

	public async getBySite(siteId: string): Promise<Array<Tier>> {
		return axios.get(`/api/tiers/by-site/${siteId}`).then(response => response.data.data)
	}

	/**
	 * Permet d'ajouter une place dans le tier correspondant
	 * @param id 
	 * @param place 
	 */
	public async addPlace(id: string, place: TierPlace): Promise<void> {
		await axios.post(`/api/tiers/add-place/${id}`, { place })
	}

	// TODO: rajouter une fonction removePlace

	public async delete(id: string): Promise<void> {
		await axios.get(`/api/tiers/tag-delete/${id}`)
	}
}

export default TiersService;
import { LoggedUser } from "@autoprog/core-client"
import PasswordUtil from '@libs/utils/password'
import axios from 'axios'
import User from '@js/types/user'

/**
 * REMARK: une classe UsersService existe deja, mais celle-ci gere celle de l'application LETANG
 * Permet de gérer les utilisateurs
 */
class UsersService {
	public static instance = new UsersService()

	private constructor() {

	}

	public static getInstance() {
		return UsersService.instance
	}


	public async load() {

	}

	/**
	 * Permet de récupérer tous les informations des utilisateurs
	 * REMARK: par défaut, vous n'allez pas avoir le mot de passe
	 */
	public async getAll() : Promise<Array<User>> {
		return axios.get(`/api/users/get/all`).then(response => response.data.data)
	}

	/**
	 * Permet de supprimer un utilisateur
	 * @param userId 
	 */
	public async delete(userId: string) {
		await axios.get(`/api/users/tag-delete/${userId}`)
	}

	public async changePassword(newPassword: string) : Promise<void> {
		let user = await this.getByID(LoggedUser.getInstance().get('ID'))

		// TODO: trouver un moyen plus secure de le faire, comme par exemple le cote server
		// Hashage du mot de passe
		const hashPassword = PasswordUtil.hashPassword(newPassword)
		user.password = hashPassword
		await this.update(user)
	}

	public async getByID(id: string) : Promise<User> {
		return axios.get(`/api/users/${id}`).then(response => response.data.data)
	}

	public async getByIds(ids: Array<string>): Promise<Array<User>> {
		return axios.post(`/api/users/by-ids`, { ids }).then(response => response.data.data)
	}

	public hasPermissions(permission: string) : boolean {
		return LoggedUser.getInstance().hasPermission(permission)
	}

	public getAllowSites() : Array<string> | undefined {
		// TODO: changer pour getAditionalInformation
		return LoggedUser.getInstance().get('additionalData.allowSites')
	}

	public getAffectedSite() : string | undefined {
		return LoggedUser.getInstance().get('additionalData.site')
	}

	/**
	 * Cherche en base si un utilisateur a un groupe specifié
	 * REMARK: voir s'il faut le mettre dans GroupsService plutôt
	 * @param group 
	 */
	public async hasAtLeastOneGroup(groupId: string) : Promise<boolean> {
		return axios.get(`/api/users/has-group/${groupId}`).then(response => response.data.data)
	}

	/**
	 * Permet de voir si un identifiant existe déjà en base de données
	 * @param login 
	 */
	public async loginExists(login: string) : Promise<boolean> {
		return axios.get(`/api/users/exists/${login}`).then(response => response.data.data)
	}

	public async create(user: User) {
		await axios.post('/api/users', user)
	}

	public async update(user: User) {
		await axios.put('/api/users', user)
	}

	/**
	 * Permet de d'activer l'option d'envoie de l'email de commande prévalidé
	 * @param userId 
	 */
	public async activatePrevalidationEmail(userId: string) {
		await axios.get(`/api/users/has_emails_prevalidation/${userId}/activated`)
	}

	/**
	 * Permet de désactiver l'option d'envoie de l'email de commande prévalidé
	 * @param userId 
	 */
	public async deactivateValidationEmail(userId: string) {
		await axios.get(`/api/users/has_emails_validation/${userId}/deactivated`)
	}


	/**
	 * Permet de d'activer l'option d'envoie de l'email de commande prévalidé
	 * @param userId 
	 */
	 public async activateValidationEmail(userId: string) {
		await axios.get(`/api/users/has_emails_validation/${userId}/activated`)
	}

	/**
	 * Permet de désactiver l'option d'envoie de l'email de commande prévalidé
	 * @param userId 
	 */
	public async deactivatePrevalidationEmail(userId: string) {
		await axios.get(`/api/users/has_emails_prevalidation/${userId}/deactivated`)
	}

	/**
	 * Donne le montant maximum d'une commande pour l'utilisateur courant
	 * REMARK: il prend le max parmi les groupes
	 */
	public orderLimit() : number {
		const groups = LoggedUser.getInstance().get('groups')
		const orderLimit = Math.max(...groups.map((group: any) => group.additionalData.orderLimit))
		return orderLimit
	}

	public getHoursCategories(): Array<string> {
		const groups = LoggedUser.getInstance().get('groups')
		return Array.from(new Set(groups.map((group: any) => group.additionalData.hoursCategories).flat(Infinity)))
	}

	public async getFullname(id: string) : Promise<{ _id: string, firstname: string, lastname: string, fullname: string }> {
		return axios.get(`/api/users/fullname/${id}`).then(response => response.data.data)
	}
}

export default UsersService

import OrderInfo from '@js/libs/models/OrderInfo'
import BillsService from '../services/BillsService'
import DeliveryNotesService from '../services/DeliveryNotesService'
import WorkOrdersService from '../services/WorkOrdersService'

import _ from 'lodash'
import OrdersService from '../services/OrdersService'

/**
 * Bill Info contient les mêmes info que OrderInfo, excepté que c'est pour seulement pour les factures avec une information supplémentaire
 */
class BillInfo extends OrderInfo {
	public readonly billedQuantity: number

	public constructor(rowId: string, idProduct: string, maxOrdered: number, deliveryOrdered: number, workOrdered: number, unit: string, billQuantity: number) {
		super(rowId, idProduct, maxOrdered, deliveryOrdered, workOrdered, unit)
		this.billedQuantity = billQuantity
	}
}

/**
 * Dictionnary OrderInfo
 */
export type BillProductsInfoList = {
	[rowId: string]: BillInfo
}

class BillsManager {
	public static async getBillInfo(billId: string): Promise<BillProductsInfoList> {

		// Recherche des infirmations neccessaires
		const bill = await BillsService.getInstance().getByID(billId)
		const orders = await OrdersService.getInstance().getByIds(bill.orders)

		const deliveryNotes = await DeliveryNotesService.getInstance().getByIds(bill.deliveryNotes)
		const workOrders = await WorkOrdersService.getInstance().getByIds(bill.workOrders)

		const productsDeliveryNotes = deliveryNotes.map(deliveryNote => deliveryNote.products).flat()
		const productsWorkOrders = workOrders.map(workOrder => workOrder.products).flat()

		const result: BillProductsInfoList = {}

		for (let order of orders) {
			for (let product of order.products || []) {

				const productsWorkOrdersFromRowId = productsWorkOrders.filter(pro => pro.rowID === product.rowID)
				const productsDeliveryNoteFromRowId = productsDeliveryNotes.filter(pro => pro.rowID === product.rowID)

				const deliveryQuantities = productsDeliveryNoteFromRowId.reduce((total, pro) => total + pro.deliveredQuantity, 0)
				const workOrderedQuantities = productsWorkOrdersFromRowId.reduce((total, pro) => total + pro.deliveredQuantity, 0)
				const maxQuantity = product.quantity

				const billProduct = bill.products.find(pro => pro.rowID === product.rowID);
				const billQuantity = billProduct ? billProduct.quantity : 0;
					
				result[`${order.orderNumber}|${product.rowID}`] = new BillInfo(product.rowID, product.id, maxQuantity, deliveryQuantities, workOrderedQuantities, product.unit, billQuantity)
			}
		}

		return result
	}

	// REMARK: deliveryNotesIds && workOrdersIds doievtn venir de la meme commande sinon cela marche pas
	// FIXME: Trouver un moyen de l'appeler getbillInfo en overload
	public static async getBillInfoByDeliveryAndWorkOrder(deliveryNotesIds: Array<string>, workOrdersIds: Array<string>): Promise<BillProductsInfoList> {
		const deliveryNotes = await DeliveryNotesService.getInstance().getByIds(deliveryNotesIds)
		const workOrders = await WorkOrdersService.getInstance().getByIds(workOrdersIds)

		const ordersIds = [...deliveryNotes.map(d => d.order), ...workOrders.map(d => d.order)]
		const orders = await OrdersService.getInstance().getByIds(ordersIds);

		const productsDeliveryNotes = deliveryNotes.map(deliveryNote => deliveryNote.products.map( pro => ({ ...pro, orderId: deliveryNote.order }) )).flat()
		const productsWorkOrders = workOrders.map(workOrder => workOrder.products.map(pro => ({ ...pro, orderId: workOrder.order }) ) ).flat()

		const result: BillProductsInfoList = {}
		for (let order of orders) {
			for (let product of order.products || []) {

				const productsWorkOrdersFromRowId = productsWorkOrders.filter(pro => pro.rowID === product.rowID && pro.orderId === order._id)
				const productsDeliveryNoteFromRowId = productsDeliveryNotes.filter(pro => pro.rowID === product.rowID && pro.orderId === order._id)

				const deliveryQuantities = productsWorkOrdersFromRowId.reduce((total, pro) => total + pro.deliveredQuantity, 0)
				const workOrderedQuantities = productsDeliveryNoteFromRowId.reduce((total, pro) => total + pro.deliveredQuantity, 0)
				const maxQuantity = product.quantity

				const billQuantity = 0

				result[`${order.orderNumber}|${product.rowID}`] = new BillInfo(product.rowID, product.id, maxQuantity, deliveryQuantities, workOrderedQuantities, product.unit, billQuantity)
			}
		}

		return result
	}
}

export default BillsManager
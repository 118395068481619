import { Modal, Form, global, Alert, LoggedUser } from "@autoprog/core-client"

import moment, { Moment } from 'moment'
import T_addHoursSheet from '@tpl/modals/humanRessources/addHoursSheet.html';
import T_hoursLine from '@tpl/blocks/humanRessources/hoursLine.html';
import T_statsCarousel from '@tpl/blocks/humanRessources/statsCarousel.ejs';

import 'daterangepicker'
import 'daterangepicker/daterangepicker.css'
import toastr from 'toastr'

import drpC from '@libs/utils/daterangepickerConfig'
import _ from 'lodash'
import { HourCategory } from '@js/libs/services/HoursCategories'
import { SitesService, UsersService, HoursService, HoursCategories } from '@js/libs/services'
import { HoursDataType, SiteCategoriesHour } from '@js/libs/services/HoursService'

type StatsObject = { [key: string]: { [key: string]: { hours: number | moment.Duration, standbyDuties: number | moment.Duration } } };


class AddEditHoursSheetModal extends Modal {

	private N_hoursTable: HTMLTableElement | null = null;
	private N_statsTables: NodeListOf<HTMLTableElement> | null = null;
	private N_standbyDutyTable: HTMLTableElement | null = null;
	private N_totalHours: HTMLElement | null = null;
	private N_totalStandbyDuties: HTMLElement | null = null;
	private N_remainingHours: NodeListOf<HTMLElement> | null = null;
	private N_remainingStandbyDuties: NodeListOf<HTMLElement> | null = null;
	private N_carousels: HTMLElement | null = null;

	private N_datePicker: HTMLInputElement | null = null;
	private N_kilometers: HTMLInputElement | null = null;
	private N_comment: HTMLTextAreaElement | null = null;
	private statsForm: Form | null = null;

	private idHours: string | null = null;
	// private revHours: string | null = null;

	constructor(id?: string) {

		super({
			tpl: T_addHoursSheet,
			backdrop: 'static',
			keyboard: false
		});

		this.on("opened", async () => {

			if (this.element) {

				this.N_datePicker = this.element.querySelector('#date-picker') as HTMLInputElement;

				this.N_comment = this.element.querySelector('#comment') as HTMLTextAreaElement;
				this.N_kilometers = this.element.querySelector('#kilometers') as HTMLInputElement;

				let N_Save = this.element.querySelector("#save") as HTMLElement;


				let N_add1 = this.element.querySelector('#add1') as HTMLElement;
				let N_add2 = this.element.querySelector('#add2') as HTMLElement;

				let N_changeDateBtn = this.element.querySelector('#changeDateBtn') as HTMLButtonElement;

				this.N_hoursTable = this.element.querySelector('#hours-table') as HTMLTableElement;
				this.N_standbyDutyTable = this.element.querySelector('#standbyDuty-table') as HTMLTableElement;
				this.N_totalHours = this.element.querySelector('#totalHours') as HTMLElement;
				this.N_totalStandbyDuties = this.element.querySelector('#totalStandbyDutys') as HTMLElement;

				this.N_statsTables = this.element.querySelectorAll('.stats-table') as NodeListOf<HTMLTableElement>;
				this.N_carousels = this.element.querySelector('#statsCarousel') as HTMLElement;

				this.statsForm = new Form(this.element.querySelector('#stats-form') as HTMLFormElement);

				await this.generateCarousels()

				this.N_remainingStandbyDuties = this.element.querySelectorAll('.remaining-standby-duties') as NodeListOf<HTMLElement>;
				this.N_remainingHours = this.element.querySelectorAll('.remaining-hours') as NodeListOf<HTMLElement>;

				$(this.element).find('#date-picker').daterangepicker(drpC({
					singleDatePicker: true,
					parentEl: '#container-date-picker'
				}), function (_start, _end, _label) {

				});

				N_add1.addEventListener("click", () => {

					if (this.checkTabHours()) {
						this.addHoursLine();
					}
				})

				N_add2.addEventListener("click", () => {

					if (this.checkTabStandbyDuty()) {

						this.addStandbyDutyLine();

					}
				})

				N_changeDateBtn.addEventListener2('click', async () => {

					if (id) {
						let date: Moment = await Alert.prompt('Nouvelle date', 'Choisissez la nouvelle date de la fiche d\'heures', { type: 'date' }) as unknown as Moment;
						let newDate = date.format('YYYY_MM_DD');
						let chunks = id.split('_');
						let userID = chunks.shift();
						let oldDate = chunks.join('_');

						try {
							await HoursService.getInstance().changeDate(userID!, oldDate, newDate);
							toastr.success('Date modifiée');
							this.resolve();
						} catch (e) {

							if (e.message == 'OLD_DOESNT_EXIST') {
								toastr.error('Aucune fiche d\'heure a l\'ancienne date')
							} else if (e.message == 'NEW_ALREADY_EXIST') {
								toastr.error('Une fiche d\'heure existe déjà à la nouvelle date sélectionnée')
							} else {
								toastr.error('Erreur inconnue');
							}

						}

					}

				})

				N_Save.addEventListener("click", async () => {

					// On vérifie que le jour n'est pas déjà rentré par l'utilisateur
					let loggedUser = LoggedUser.getInstance();
					let selectedDate = moment((this.N_datePicker as HTMLInputElement).value, 'DD/MM/YYYY')
					let hasDate = await HoursService.getInstance().exists(loggedUser.get('ID'), selectedDate.format('YYYY_MM_DD'))

					if (hasDate && !this.idHours) {
						toastr.error('Erreur : La date existe déjà')
						return
					}


					let totalHours = this.getTotalHours();
					let totalStandbyDuties = this.getTotalStandbyDuties();

					if (totalHours != 0 || totalStandbyDuties != 0) {
						let remaining = this.getRemaining();

						if (remaining.hours == 0 && remaining.standbyDuties == 0) {

							if (this.N_hoursTable && this.N_totalHours && this.N_standbyDutyTable && this.N_totalStandbyDuties) {

								let N_hoursTableRows = this.N_hoursTable.querySelectorAll('tr');
								let N_standbyDutyTableRows = this.N_standbyDutyTable.querySelectorAll('tr');
								let hours: { start: number, end: number }[] = [];
								let standbyDuty: { start: number, end: number }[] = [];

								N_hoursTableRows.forEach((row) => {

									let N_start = row.querySelector('.hours-start-input') as HTMLInputElement;
									let N_end = row.querySelector('.hours-end-input') as HTMLInputElement;

									if (N_start && N_end && !(N_start.valueAsNumber == 0 && N_end.valueAsNumber == 0)) {
										hours.push({
											start: N_start.valueAsNumber,
											end: N_end.valueAsNumber

										})
									}
								})

								N_standbyDutyTableRows.forEach((row) => {

									let N_start = row.querySelector('.hours-start-input') as HTMLInputElement;
									let N_end = row.querySelector('.hours-end-input') as HTMLInputElement;

									if (N_start && N_end && !(N_start.valueAsNumber == 0 && N_end.valueAsNumber == 0)) {
										standbyDuty.push({
											start: N_start.valueAsNumber,
											end: N_end.valueAsNumber

										})
									}

								})

								if (this.N_kilometers && this.N_comment && this.N_datePicker) {
									let date = Number(moment(this.N_datePicker.value, 'DD/MM/YYYY').format('x'));



									let data: HoursDataType = {
										_id: this.idHours || this.generateID(),
										date,
										hours,
										total: Number(this.N_totalHours.dataset.time),
										standbyDuty,
										totalStandbyDutys: Number(this.N_totalStandbyDuties.dataset.time),
										kilometers: this.N_kilometers.valueAsNumber,
										comment: this.N_comment.value,
										stats: this.getStats(),
										site: global.SITE,
										user: loggedUser.get('ID'),
										locked: false
									}

									// Quick fix
									// Probleme ticket #440 (https://gitlab.com/charlesletang77/autoprog-letang-bioagaz/-/issues/440)
									// Impossible de determiner comment cette erreur intervient
									// Donc dans le cas qu'un utilisateur bypass le mecanims, on lui affiche une erreur
									if (!loggedUser.get('ID')) {
										toastr.error("⚠️ Problème: Impossible d'enregistrer cette heure, veuillez reessayer ultérieument")
										return
									}



									let addOrUpdate = this.idHours ? HoursService.getInstance().update : HoursService.getInstance().create
									await addOrUpdate(data)
									toastr.success(this.idHours ? 'Heure modifié' : 'Heure Ajouté')
									this.resolve()
								}

							}
						}
					}
				})

				if (id) {
					this.setValueForm(id);
				} else {
					N_changeDateBtn.classList.add('d-none')
					this.addHoursLine();
					this.addHoursLine();
					this.addStandbyDutyLine();
				}
			}
		})

	}

	private checkTabHours() {

		let validInput = true;

		let N_hoursTable = document.querySelector('#hours-table') as HTMLTableElement;
		let tbody = N_hoursTable.querySelector('tbody') as HTMLElement;

		let N_hoursTableRows = tbody.querySelectorAll('tr');
		N_hoursTableRows.forEach((row) => {

			let N_start = row.querySelector('.hours-start-input') as HTMLInputElement;
			let N_end = row.querySelector('.hours-end-input') as HTMLInputElement;

			if (!N_start.value && !N_end.value || !N_start.value && N_end.value || N_start.value && !N_end.value) {
				validInput = false;
				N_start.classList.remove("is-valid");
				N_start.classList.add("is-invalid");
				N_end.classList.remove("is-valid");
				N_end.classList.add("is-invalid");
			} else {
				N_start.classList.remove("is-invalid");
				N_start.classList.add("is-valid");
				N_end.classList.remove("is-invalid");
				N_end.classList.add("is-valid");
			}

		})


		return validInput;

	}

	private checkTabStandbyDuty() {

		let validInput = true;

		let N_standbyDutyTable = document.querySelector('#standbyDuty-table') as HTMLTableElement;
		let tbody = N_standbyDutyTable.querySelector('tbody') as HTMLElement;

		let N_stanbyDutyTableRows = tbody.querySelectorAll('tr');
		N_stanbyDutyTableRows.forEach((row) => {

			let N_start = row.querySelector('.hours-start-input') as HTMLInputElement;
			let N_end = row.querySelector('.hours-end-input') as HTMLInputElement;

			if (!N_start.value && !N_end.value || !N_start.value && N_end.value || N_start.value && !N_end.value) {
				validInput = false;
				N_start.classList.remove("is-valid");
				N_start.classList.add("is-invalid");
				N_end.classList.remove("is-valid");
				N_end.classList.add("is-invalid");
			} else {
				N_start.classList.remove("is-invalid");
				N_start.classList.add("is-valid");
				N_end.classList.remove("is-invalid");
				N_end.classList.add("is-valid");
			}

		})


		return validInput;
	}

	private addHoursLine(): HTMLTableRowElement | null {

		if (this.N_hoursTable) {
			let row = this.addLine(this.N_hoursTable);

			if (row) {

				row.querySelectorAll('.hours-start-input,.hours-end-input').forEach((el) => {

					el.addEventListener('input', () => {
						this.calculateTotalHours();
					});

				});

				return row;
			}
		}

		return null;
	}

	private addStandbyDutyLine(): HTMLTableRowElement | null {

		if (this.N_standbyDutyTable) {
			let row = this.addLine(this.N_standbyDutyTable);

			if (row) {

				row.querySelectorAll('.hours-start-input,.hours-end-input').forEach((el) => {

					el.addEventListener('input', () => {
						this.calculateTotalStandbyDuties();
					});

				});
				return row;
			}
		}

		return null;
	}

	private getTotalHours(): number {
		let total = 0;

		if (this.N_hoursTable) {
			let N_totals = this.N_hoursTable.querySelectorAll('.total');


			for (let N_total of N_totals) {
				let time = (N_total as HTMLElement).dataset.time;
				if (time) {
					total += Number(time);
				}
			}
		}

		return total;
	}

	private getTotalStandbyDuties(): number {
		let total = 0;

		if (this.N_standbyDutyTable) {
			let N_totals = this.N_standbyDutyTable.querySelectorAll('.total');


			for (let N_total of N_totals) {
				let time = (N_total as HTMLElement).dataset.time;
				if (time) {
					total += Number(time);
				}
			}
		}

		return total;

	}

	private getTotalStatsHours(): { hours: number, standbyDuties: number } {

		let totalHours = 0;
		let totalStandbyDuties = 0;

		if (this.N_statsTables && this.statsForm) {

			let stats = this.statsForm.getData() as StatsObject;


			for (let siteID in stats) {
				for (let catId in stats[siteID]) {

					totalHours += (stats[siteID][catId].hours as moment.Duration).as('ms');
					totalStandbyDuties += (stats[siteID][catId].standbyDuties as moment.Duration).as('ms');

				}
			}

		}

		return {
			hours: totalHours,
			standbyDuties: totalStandbyDuties
		};
	}

	private addLine(N_hoursTable: HTMLTableElement): HTMLTableRowElement | null {
		let tbody = N_hoursTable.querySelector('tbody');
		if (tbody) {
			let row = tbody.insertRow();
			row.innerHTML = T_hoursLine;

			let N_start = row.querySelector('.hours-start-input') as HTMLInputElement;
			let N_end = row.querySelector('.hours-end-input') as HTMLInputElement;
			let N_total = row.querySelector('.total') as HTMLInputElement;
			let N_errase = row.querySelector('#errase') as HTMLButtonElement;

			N_start.addEventListener("input", () => {

				if (N_end.valueAsDate && N_start.valueAsDate) {

					let time = this.calculateHours(N_start.valueAsDate, N_end.valueAsDate);

					N_total.innerHTML = this.humanizeDuration(time);
					N_total.dataset.time = time.asMilliseconds().toString();

				}
			})

			N_end.addEventListener("input", () => {
				if (N_end.valueAsDate && N_start.valueAsDate) {
					let time = this.calculateHours(N_start.valueAsDate, N_end.valueAsDate);

					N_total.innerHTML = this.humanizeDuration(time);
					N_total.dataset.time = time.asMilliseconds().toString();

				}
			})

			N_errase.addEventListener("click", () => {
				if (row.parentNode) {
					row.parentNode.removeChild(row);
					this.calculateRemainingHours();
					this.calculateTotalHours();
					this.calculateTotalStandbyDuties();
				}
			})

			return row
		}
		return null;
	}

	private async generateCarousels(): Promise<void> {

		const sites = await SitesService.getInstance().getAllowedSites()

		if (this.N_carousels && this.statsForm) {

			const innerCarousel = this.N_carousels.querySelector('.carousel-inner') as HTMLElement;

			const hoursCategories = HoursCategories.getInstance()
			const userHoursCategories = UsersService.getInstance().getHoursCategories()

			const cats: HourCategory[] = hoursCategories.getByIds(userHoursCategories)

			const filteredSites = sites
			for (let i = 0; i < filteredSites.length; i++) {

				innerCarousel.innerHTML += T_statsCarousel({
					selected: filteredSites[i]._id == global.SITE,
					site: filteredSites[i],
					hoursCategories: cats
				});

			}

			innerCarousel.querySelectorAll('input').forEach((el) => {

				el.addEventListener('input', () => {
					let remaining = 0;

					if (el.name.endsWith('.hours')) {
						remaining = this.getRemaining().hours
					} else if (el.name.endsWith('.standbyDuties')) {
						remaining = this.getRemaining().standbyDuties
					}

					// TODO: FIXME: REMARK: Il sort d'ou ce nombre magique
					if (el.valueAsNumber == 82800000) {
						el.valueAsNumber = 0;
					}
					else if (remaining < 0) {
						el.valueAsNumber = el.valueAsNumber + remaining
					}

					this.calculateRemainingHours();
				});

			});

			this.statsForm.updateInputs();
		}


	}

	private getStats(): SiteCategoriesHour {

		let result: SiteCategoriesHour = {}

		if (this.statsForm) {
			let stats = this.statsForm.getData() as StatsObject;

			for (let siteID in stats) {
				result[siteID] = {}
				for (let catId in stats[siteID]) {
					result[siteID][catId] = { hours: 0, standbyDuties: 0 }
					result[siteID][catId].hours = (stats[siteID][catId].hours as moment.Duration).as('ms');
					result[siteID][catId].standbyDuties = (stats[siteID][catId].standbyDuties as moment.Duration).as('ms');

				}
			}

		}

		return result;

	}

	private calculateHours(startHours: Date, endHours: Date) {

		let start = moment(startHours);
		let end = moment(endHours);

		if (end.diff(start) < 0) {
			end.add(1, 'day');
		}
		return moment.duration(end.diff(start), 'ms');
	}

	private humanizeDuration(duration: moment.Duration) {

		let days = duration.get('days');
		let hours = ('00' + (days * 24 + duration.get('hour'))).slice(-2);
		let minutes = ('00' + duration.get('minute')).slice(-2);

		return hours + 'h' + minutes;
	}

	private getRemaining(): { hours: number, standbyDuties: number } {

		let totalHours = this.getTotalHours();
		let totalStandbyDuties = this.getTotalStandbyDuties();
		let statsHours = this.getTotalStatsHours();

		return {
			hours: totalHours - statsHours.hours,
			standbyDuties: totalStandbyDuties - statsHours.standbyDuties
		}
	}

	private calculateRemainingHours() {

		if (this.element && this.N_remainingStandbyDuties && this.N_remainingHours) {

			let remaining = this.getRemaining();

			for (let el of this.N_remainingStandbyDuties) {
				if (remaining.standbyDuties > 0 || remaining.standbyDuties < 0) {
					el.classList.add('text-danger');
				} else {
					el.classList.remove('text-danger');
				}
				el.innerHTML = this.humanizeDuration(moment.duration(remaining.standbyDuties, 'ms'));
			}

			for (let el of this.N_remainingHours) {
				if (remaining.hours > 0 || remaining.hours < 0) {
					el.classList.add('text-danger');
				} else {
					el.classList.remove('text-danger');
				}
				el.innerHTML = this.humanizeDuration(moment.duration(remaining.hours, 'ms'));
			}
		}
	}

	private calculateTotalHours() {

		if (this.N_totalHours) {
			let totalHours = this.getTotalHours();
			let duration = moment.duration(totalHours, 'ms');
			this.N_totalHours.dataset.time = totalHours.toString();
			this.N_totalHours.innerHTML = this.humanizeDuration(duration);

			this.calculateRemainingHours();
		}

	}


	private calculateTotalStandbyDuties() {

		if (this.N_totalStandbyDuties) {
			let totalHours = this.getTotalStandbyDuties();
			let duration = moment.duration(totalHours, 'ms');
			this.N_totalStandbyDuties.dataset.time = totalHours.toString();
			this.N_totalStandbyDuties.innerHTML = this.humanizeDuration(duration);

			this.calculateRemainingHours();
		}

	}


	private generateID(): string {
		const loggedUser = LoggedUser.getInstance()
		let selectedDate = moment((this.N_datePicker as HTMLInputElement).value, 'DD/MM/YYYY')

		return loggedUser.get('ID') + "_" + selectedDate.format('YYYY_MM_DD')

	}

	private async setValueForm(id: string): Promise<void> {

		this.idHours = id;
		const data = await HoursService.getInstance().getByID(id)
		// this.revHours = data._rev;

		if (this.element) {
			let dateRangePicker = $(this.element).find('#date-picker').data('daterangepicker')
			if (dateRangePicker) {

				dateRangePicker.setStartDate(moment(data.date, 'x'))
				dateRangePicker.setEndDate(moment(data.date, 'x'))

				if (this.N_datePicker) {
					this.N_datePicker.value = moment(data.date, 'x').format('DD/MM/YYYY')
					this.N_datePicker.disabled = true;
				}
			}
		}

		if (this.N_hoursTable) {

			for (let hours of data.hours) {

				let row = this.addHoursLine();

				if (row) {
					let startInput = row.querySelector('.hours-start-input') as HTMLInputElement;
					if (startInput) {
						startInput.valueAsNumber = hours.start;
					}

					let endInput = row.querySelector('.hours-end-input') as HTMLInputElement;
					if (endInput) {
						endInput.valueAsNumber = hours.end;
					}

					let event = new Event('input');
					startInput.dispatchEvent(event);
				}
			}
		}

		if (this.N_standbyDutyTable) {

			if (data.standbyDuty && data.standbyDuty.length) {
				for (let standbyDuties of data.standbyDuty) {

					let row = this.addStandbyDutyLine();

					if (row) {
						let startInput = row.querySelector('.hours-start-input') as HTMLInputElement;
						if (startInput) {
							startInput.valueAsNumber = standbyDuties.start;
						}
						let endInput = row.querySelector('.hours-end-input') as HTMLInputElement;
						if (endInput) {
							endInput.valueAsNumber = standbyDuties.end;
						}
						let event = new Event('input');
						startInput.dispatchEvent(event);
					}
				}
			} else {
				this.addStandbyDutyLine();
			}
		}

		if (this.statsForm) {

			let stats: StatsObject = {};

			for (let siteID in data.stats) {
				stats[siteID] = {}
				for (let catId in data.stats[siteID]) {
					stats[siteID][catId] = { hours: 0, standbyDuties: 0 }
					stats[siteID][catId].hours = moment.duration(data.stats[siteID][catId].hours, 'ms');
					stats[siteID][catId].standbyDuties = moment.duration(data.stats[siteID][catId].standbyDuties, 'ms');
				}
			}

			this.statsForm.setData(stats as any);
			this.calculateRemainingHours();
		}

		if (this.N_kilometers) {
			this.N_kilometers.value = data.kilometers.toString();
		}

		if (this.N_comment) {
			this.N_comment.value = data.comment;
		}
	}
}

export default AddEditHoursSheetModal;

import { Modal, global, toaster, Alert } from "@autoprog/core-client"

import { CellClassParams, Grid, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import moment from 'moment'
import 'ag-grid-enterprise'

import T_addEditAlimentation from '@tpl/modals/exploitation/addEditAlimentation.html'

import Select2CellEditor, { CHAR_PRESS_AUTOEDIT, Select2CellEditorParams } from '@libs/agGrid/Select2CellEditor'
import unitList from '@libs/lists/unitList'

import drpC from '@libs/utils/daterangepickerConfig'
import BDD from '@libs/BDD'

import { french as agGridFrench } from '@libs/utils/agGrid'
import StocksService, { ProductPerStorage } from '@js/libs/services/StocksService'
import ProductsService from '@js/libs/services/ProductsService'

import Product from '@js/types/products'
import Site from '@js/types/site'
import SitesService from '@js/libs/services/SitesService'

import _ from 'lodash'
import StockEvent from '@js/types/stock-event'


interface StorageEditorParams extends Select2CellEditorParams {
	filterFunctionStorageOptions: (productId: string) => Array<string>
}

interface AlimentationDailyStorageInformation {
	_id: string
	unit: string
	quantity: number
	product: { id: string, text: string }
	storage: { id: string, text: string }
	date: number
	unlocked: boolean
}

class StorageCellEditor extends Select2CellEditor {

	public init(params: StorageEditorParams) {
		//Demande de Laura le 17 mai de remettre toute les cases
		//	const availableStorage = params.filterFunctionStorageOptions(params.node.data.product.id)
		//	params.options.data = params.options.data ? (params.options.data as any[]).filter(row => availableStorage.includes(row.id)) : params.options.data
		super.init(params)
	}

}

class AddEditAlimentationModal extends Modal {

	private gridOptions?: GridOptions
	private site?: Site
	private deletedRow: Array<string>

	private date: moment.Moment = moment()

	// Utilise pour la fonction getDefaultStorage
	private productsPerStorage: Array<ProductPerStorage>

	constructor(private designSite: string, date?: number | string) {

		super({
			tpl: T_addEditAlimentation,
			backdrop: 'static',
			keyboard: false
		})

		this.productsPerStorage = []
		this.deletedRow = []

		this.on('opened', async () => {

			this.date = date ? moment(date, 'x') : moment()
			this.site = await this.getSite()

			const N_name_site = this.element.querySelector('#name-site') as HTMLElement
			N_name_site.innerHTML = this.site.name

			this.createGrid()

			const N_date = this.element.querySelector('#date-input') as HTMLInputElement
			$(N_date).daterangepicker(drpC({
				singleDatePicker: true,
				startDate: this.date,
			}), (start, _, _2) => {
				this.deletedRow = []
				this.date = start
				this.loadData()
			})

			this.loadData()
			this.configureSaveBtn()
			this.configureAddProductLine()
		})

	}

	private async createGrid(): Promise<void> {

		const gridEl = this.element.querySelector('#list-product-grid') as HTMLElement

		const availableProductsIds = await StocksService.getInstance().getAvailableProductsIds(global.SITE)
		const availableProducts = await ProductsService.getInstance().getByIDs(availableProductsIds)

		this.gridOptions = agGridFrench({
			columnDefs: [
				// {
				// 	// Cette colonne sert a indique si la capacite atteint ces limites, on se base alors sur la capacite
				// 	headerName: '📋',
				// 	colId: 'quantity-indicator',
				// 	width: 25,
				// 	valueGetter: (params: ValueGetterParams) => +params.getValue('capacity') >= 0,
				// 	cellRenderer: (params: ICellRendererParams) => !!params.value ? '✔️' : '⛔️'
				// },
				{
					headerName: 'Produit',
					field: 'product',
					cellEditor: Select2CellEditor as any,
					cellEditorParams: {
						options: ({
							data: availableProducts.map(product => { return { id: product._id, text: product.reference, product: product } }),
							dropdownParent: $(gridEl as HTMLElement)
						} as Options)
					},
					editable: (params) => params.data.unlocked,
					cellRenderer: (params: ICellRendererParams) => params.value?.text || ""
				},
				{
					headerName: 'Emplacement',
					field: 'storage',
					cellEditor: StorageCellEditor,
					cellEditorParams: {

						// filterFunctionStorageOptions: this.getAvailableStorage.bind(this),
						options: ({
							data: _.map(_.filter(this.site?.storages || [], { type: 'box' }), (item: any) => {
								return {
									id: item.id,
									text: item.name
								}
							}),
							dropdownParent: $(gridEl as HTMLElement)
						} as Options)
					},

					cellRenderer: (params) => params.value?.text || "",
					editable: (params) => params.data.unlocked
				},
				{
					headerName: 'Quantité',
					field: 'quantity',
					cellClass: 'text-right',
					editable: (params) => params.data.unlocked
				},
				{
					headerName: 'Stock Disponible',
					colId: 'capacity',
					cellClassRules:{
						'text-right': () => true,
						'text-red': (params: CellClassParams) => params.value < 0 || !params.data.storage
					},
					// Ce Code est disfonctionnel, AG-GRid, lors du raffraichissement, il n'enleve pas la classe deja affecte
					//
					// 
					// cellClass: (params) => {
					// 	const classes = ['text-right']
					// 	if (params.value < 0 || !params.data.storage) {
					// 		classes.push('text-red')
					// 	}

					// 	!params.data.storage && classes.shift()

					// 	console.log(params.value, params, classes)
					// 	return classes
					// },
					valueGetter: (params: ValueGetterParams) => {
						const data = this.productsPerStorage.find(productPerStorage => productPerStorage.product === params.data.product?.id && productPerStorage.storage === params.data.storage?.id)
						const coeficient = (unitList[params.data.unit]?.coeficient || 1)
						const newQuantity = (data?.quantity || 0) - (params.data.quantity || 0) * coeficient
						return _.round(_.divide(newQuantity, coeficient), 2)
					},
					editable: false,
					cellRenderer: (params) => {

						if (params.node.rowPinned === "bottom") {
							return ""
						}

						return params.data.storage
							? params.value
							: "Pas d'emplacement choisie ..."
					}
				},
				{
					headerName: 'Unité',
					field: 'unit',
					cellRenderer: (params) => {
						return unitList[params.value] ? unitList[params.value].name : params.value;
					}
				},
				{
					headerName: '#',
					width: 60,
					suppressSizeToFit: true,
					cellRenderer: (params: ICellRendererParams) => {

						if (!params.node.rowPinned && params.data.unlocked) {

							const N_Deletebtn = document.createElement('button')

							N_Deletebtn.classList.add('btn-xs', 'btn-danger', 'btn')
							N_Deletebtn.setAttribute('confirmation', '')

							N_Deletebtn.innerHTML = '<i class="icon icon-solid-trash-alt"></i>'

							N_Deletebtn.addEventListener('click', () => {

								params.data._id && this.deletedRow.push(params.data._id)

								params.api.applyTransaction({
									remove: [params.data]
								})


								// Recalcule de la pinnedrow pour les quantités commandés
								const pinnedRow = {
									quantity: 0,
									unit: unitList['tonne'].name
								}

								params.api.forEachNode((item: any) => {
									pinnedRow.quantity += parseFloat(item.data.quantity || 0)
								})
								pinnedRow.quantity = _.round(pinnedRow.quantity, 2)

								params.api.setPinnedBottomRowData([pinnedRow])

							})

							return N_Deletebtn;
						}

						return ''

					}
				}
			],
			rowData: [],
			suppressContextMenu: true,
			suppressDragLeaveHidesColumns: true,
			pinnedBottomRowData: [{
				quantity: 0,
				unit: unitList['tonne'].name
			}],
			onCellValueChanged: (params) => {
				if (['storage', 'product', 'quantity'].includes(params.colDef.field!)) {
					params.api.refreshCells({
						force: true,
						columns: ['capacity'],
						rowNodes: [params.node]
					})
				}
			},
			onCellEditingStopped: async (params) => {

				if (params.colDef.field == 'product' && params.data.product) {
					params.node.setDataValue('unit', params.data.product.product.unit)

					// Quand l'utilisateur change de produits on lui met un storage par defaut
					const defaultStorage = this.getDefaultStorage(params.data.product.product._id)
					if (defaultStorage) {
						params.node.setDataValue('storage', defaultStorage)
						params.api.startEditingCell({ rowIndex: params.node.rowIndex!, colKey: 'storage', charPress: CHAR_PRESS_AUTOEDIT })
					}
				}

				if (params.colDef.field == 'quantity') {

					if (params.api) {

						let pinnedRow = {
							quantity: 0,
							unit: unitList['tonne'].name
						};

						params.api.forEachNode((item) => {
							pinnedRow.quantity += parseFloat(item.data.quantity || 0)
						});

						pinnedRow.quantity = _.round(pinnedRow.quantity, 2)

						params.api.setPinnedBottomRowData([pinnedRow])
					}

				}
			},
			getRowStyle: (params: any) => {
				if (params.node.rowPinned) {
					return { 'font-weight': 'bold' }
				}
			},
			onGridReady: (params) => params.api?.sizeColumnsToFit()
		})

		this.gridOptions && new Grid(gridEl, this.gridOptions)
	}

	private configureSaveBtn(): void {

		const N_save = this.element.querySelector('#add-alimentation-button') as HTMLButtonElement
		N_save.addEventListener('click', async () => {


			try {
				const hasError = !this.checkValidity()
				hasError && await Alert.confirm("Confirmation", "Voulez-vous continuer sachant que certains stocks sont inferieur a zero ?", { yesText: "Continuer", noText: "Annuler" })

				const promises: Array<Promise<void>> = []
				this.getCurrentGridData().forEach(item => promises.push(this.addOrUpdateStockEvent(item)))
				// Suppression de tous les Stock-Events à supprimer
				this.deletedRow.forEach(id => promises.push(StocksService.getInstance().delete(id)))

				await Promise.all(promises).catch(() => toaster.error('Erreur lors de la sauvegarde'))

				this.resolve()
				toaster.success('Alimentation sauvegardée')
			}
			catch {

			}

		})
	}

	/**
	 * Permet d'ajouter ou supprimer un stock-event data
	 * @param data La data est un ligne du tableau
	 */
	private async addOrUpdateStockEvent(item: AlimentationDailyStorageInformation): Promise<void> {
		const unit = item.unit

		if (item.storage && item.quantity && item.product) {

			const obj: StockEvent = {
				_id: item._id,
				date: item.date,
				site: this.designSite,
				isTransfert: false,
				product: item.product.id,
				unlocked: item.unlocked,
				wastage: 0,
				quantity: unitList[unit].coeficient * item.quantity,
				storage: item.storage.id,
				type: 'alimentation'
			}

			if (obj._id) {
				// Update the into the database
				await StocksService.getInstance().update(obj)
			}
			else {
				obj._id = BDD.generateID()
				// Create a new line
				await StocksService.getInstance().create(obj)
			}
		}
	}

	private configureAddProductLine(): void {
		const N_addLine = this.element.querySelector('#addproductLine')!

		N_addLine.addEventListener('click', () => {

			this.gridOptions?.api?.applyTransaction({
				add: [{
					date: parseInt(this.date.format('x')),
					hours: new Date().getHours() + ':' + new Date().getMinutes(),
					unlocked: true
				}]
			});

		})

	}

	private getSite(): Promise<Site> {
		return SitesService.getInstance().getByID(this.designSite)
	}

	private async getAllProduct(): Promise<Product[]> {
		return ProductsService.getInstance().getAllProducts()
	}



	/**
	 * Permet de récupérer tous les stocks du jour sélectionné
	 * @param date 
	 */
	private async getDataByDate(date: moment.Moment): Promise<Array<AlimentationDailyStorageInformation>> {

		const products = await this.getAllProduct()
		const stockEvents = await StocksService.getInstance().getAlimentationBySite(this.designSite, +date.startOf('day').format('x'), +date.endOf('day').format('x'))

		// TODO: Améliorer cette fonction
		const rowData = [] as Array<AlimentationDailyStorageInformation>

		if (this.site) {
			for (let item of stockEvents) {

				const product = _.find(products, { _id: item.product }) as Product
				const storage = _.find(this.site.storages || [], { id: item.storage })

				rowData.push({
					_id: item._id,
					product: {
						id: item.product,
						text: product ? product.reference : item.product
					},
					unit: product.unit,
					quantity: item.quantity / unitList[product.unit].coeficient,
					storage: {
						id: item.storage,
						text: storage ? storage.name : item.storage
					},
					date: item.date,
					unlocked: !!item.unlocked
				})
			}
		}

		return rowData
	}

	/**
	 * Permet de recuperer le storage par defaut d'un produit
	 * Un storage est designe s'il est l'unique storage dans pour ce produit
	 * @param productId 
	 * @returns Retourne false si le storage n'est pas unique ou s'il y en pas, sinon retourne le storage id du storage par defaut
	 */
	private getDefaultStorage(productId: string): string | false {
		const result = this.productsPerStorage.filter(row => row.product === productId && row.quantity > 0).sort((a,b)=> b.quantity - a.quantity)
		return result.length ? result[0].storage : false
	}

	// private getAvailableStorage(productId: string): Array<string> {
	// 	return Array.from(new Set(this.productsPerStorage.filter(row => row.product === productId).map(row => row.storage)))
	// }

	private async loadData() {
		this.productsPerStorage = await StocksService.getInstance().getQuantitiesPerStorage(this.site!._id)

		const rowData = await this.getDataByDate(this.date)
		this.gridOptions?.api?.setRowData(rowData)

		// IMPORTANT: Mettre a jour les quantites dans les stocks en fonction
		// Sachant qu'on ne va reactualiser les informations des quantites dans les stocks qu'un seule fois, il faut activer manuellement de rejustement des quantites dans les produits par stock
		// Il faut rajouter les quantites deja present dans les stocks quotidiennes pour ne pas fosser les quantites actueles et avoir la bonne quantite
		// On le fait seulement apres setRowData pour se base que sur les donnees du tableau actuel
		this.readjustQuantityPerProduct()
		this.gridOptions?.api?.refreshCells()
	}

	private getCurrentGridData(): Array<AlimentationDailyStorageInformation> {
		const result = [] as Array<AlimentationDailyStorageInformation>
		this.gridOptions?.api?.forEachNode(node => result.push(node.data))
		return result
	}

	/**
	 * Reajuster les stocks en fonction des quantite quotidienne actuelles
	 */
	private readjustQuantityPerProduct() {
		const gridData = this.getCurrentGridData()
		// this.productsPerStorage est deja appeler auparavant
		this.productsPerStorage = this.productsPerStorage.map(productPerStorage => {
			const alimentation = gridData.find(row => row.product.id === productPerStorage.product)
			productPerStorage.quantity += (alimentation?.quantity || 0) * (unitList[alimentation?.unit || Infinity]?.coeficient || 1)
			return productPerStorage
		})
	}

	private checkValidity() {
		return !this.getCurrentGridData().some(row => {
			const data = this.productsPerStorage.find(productPerStorage => productPerStorage.product === row.product?.id && productPerStorage.storage === row.storage?.id)
			const coeficient = (unitList[row.unit]?.coeficient || 1)
			const newQuantity = (data?.quantity || 0) - (row.quantity || 0) * coeficient
			return newQuantity < 0
		})
	}
}

export default AddEditAlimentationModal

import { Modal } from "@autoprog/core-client"

import T_Confirm from '@tpl/modals/confirm.html'

type ConfirmOptions = {
	yesText?: string,
	noText?: string,
	yesColor?: string,
	noColor?: string,
	raw?: boolean
}

class Confirm extends Modal {

	private yesText: string
	private noText: string
	private yesColor: string
	private noColor: string

	constructor(title: string, content: string, options: ConfirmOptions = {}) {

		super({
			tpl: T_Confirm,
			backdrop: 'static',
			keyboard: false
		})

		this.yesText = options.yesText || 'Valider';
		this.noText = options.noText || 'Annuler';
		this.yesColor = options.yesColor || 'green';
		this.noColor = options.noColor || 'red';

		this.on('opened', () => {

			const N_title = this.element.querySelector('.modal-title') as HTMLElement
			const N_content = this.element.querySelector('.modal-body') as HTMLElement
			const N_btnResolve = this.element.querySelector('.btn-resolve') as HTMLButtonElement
			const N_btnReject = this.element.querySelector('.btn-reject') as HTMLButtonElement

			N_title.innerHTML = title

			if (options.raw) {
				N_content.innerHTML = content
			}
			else {
				N_content.prepend(document.createTextNode(content))
			}

			N_btnResolve.innerHTML = this.yesText;
			N_btnResolve.classList.add(`bg-${this.yesColor}`)
			N_btnReject.innerHTML = this.noText;
			N_btnReject.classList.add(`bg-${this.noColor}`)
		})

	}

}

export default Confirm

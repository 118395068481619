import OldDataEvent, { DefaultDataEvent } from '@js/types/data-event'
import axios from 'axios'

class DataEventsService {

	private static readonly instance = new DataEventsService()

	private constructor() {}

	public static getInstance() {
		return DataEventsService.instance
	}

	
	public async create(event: DefaultDataEvent | OldDataEvent): Promise<void> {
		await axios.post('/api/data-events', event)
	}

	public async getAll() : Promise<Array<DefaultDataEvent>> {
		return axios.get('/api/data-events').then(response => response.data.data)
	}

	public async getByTable(table: string) : Promise<Array<DefaultDataEvent>> {
		return axios.get(`/api/data-events/table/${table}`).then(response => response.data.data)
	}

	public async getBydoc(table: string, id: string) : Promise<Array<DefaultDataEvent>> {
		return axios.get(`/api/data-events/document/${table}/${id}`).then(response => response.data.data)
	}
}

export default DataEventsService

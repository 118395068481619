module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\r\n<table class="table table-sm" id="tableProduct">\r\n\r\n    <thead>\r\n    \r\n        <tr>\r\n            <th scope="col">Reference</th>\r\n            <th scope="col">Unité</th>\r\n\r\n            <th scope="col">Quantité</th>\r\n        </tr>\r\n      \r\n    </thead>\r\n\r\n    <tbody>\r\n\r\n        ';
 for(let el of products){;
__p += '\r\n        <tr>\r\n            <td>' +
((__t = (el.reference)) == null ? '' : __t) +
'</td>\r\n            <td>' +
((__t = (el.unit)) == null ? '' : __t) +
'</td>\r\n            <td>' +
((__t = (el.deliveredQuantity / unitList[el.unit].coeficient)) == null ? '' : __t) +
'</td>\r\n        </tr>\r\n        ';
 } ;
__p += '\r\n\r\n    </tbody>\r\n\r\n</table>';

}
return __p
}
import { Tab, global, LoggedUser, toaster } from "@autoprog/core-client"

import { Grid, GridOptions, RowNode, ValueGetterParams } from 'ag-grid-community'
import 'ag-grid-enterprise'
import moment from 'moment'
import Highcharts from '@libs/Highcharts'
import BDD from '@js/libs/BDD'

import ViewDetailsStock from '@libs/modals/exploitation/ViewDetailsStock';
import TransfertStock from '@libs/modals/exploitation/TransfertStock';

import unitList from '@libs/lists/unitList'
import drpC from '@libs/utils/daterangepickerConfig'
import { french as agGridFrench } from '@libs/utils/agGrid'

import TextFilterAccent from '@libs/agGrid/TextFilterAccent'

import Export from '@libs/agGrid/Export';

import '@css/stock.scss';
import Site, { SiteStorage } from '@js/types/site'
import SitesService from '@js/libs/services/SitesService'

import _ from 'lodash'
import Product from '@js/types/products'
import { ProductPerStorage } from '@js/libs/services/StocksService'
import { SeriesOptionsType } from 'highcharts'
import StockEvent from '@js/types/stock-event'

import { StocksService, ProductsService } from "@js/libs/services"


interface TabDashBoardRow extends ProductPerStorage {
	storageData: SiteStorage,
	productData: Product
}

interface ChartData {
	data: Array<number>
	name?: string
	productId?: string
	color?: string
}

class Stock extends Tab {

	private el: HTMLElement;

	private currentSite: string = global.DESIGN;

	private site: Site | null = null;
	private loggedUser: LoggedUser | null = null;
	// private products: Array<Product> = [];

	private gridOptionsDashBoard: GridOptions | null = null;

	private startDate: moment.Moment = moment().startOf('month');
	private endDate: moment.Moment = moment().endOf('month');

	constructor(el: HTMLElement) {

		super(el);

		this.el = el;

		this.loggedUser = LoggedUser.getInstance();

		this.initButton();

		this.getSite().then((site) => {

			this.site = site;

			this.getAllProduct().then(() => this.refreshUI());

		});

		let N_btn_transfer = this.el.querySelector('#btn-transfert');

		if (N_btn_transfer) {

			N_btn_transfer.addEventListener('click', () => {

				let modal = new TransfertStock(this.currentSite);

				modal.open().then(() => {
					this.getAllProduct().then(() => this.refreshUI());
				})

			});

		}

	}

	public updateSelectSite(value: string) {

		this.currentSite = value;

		this.getSite().then((site) => {

			this.site = site;

			this.getAllProduct().then(() => this.refreshUI());

		});

	}

	private initButton() {

		let N_container_dashboard = this.el.querySelector('#container-dashboard');
		let N_container_evolution = this.el.querySelector('#container-evolution');

		let N_btn_dashboard = this.el.querySelector('#btn-dashboard');
		let N_btn_evolution = this.el.querySelector('#btn-evolution');

		if (N_btn_dashboard && N_btn_evolution) {

			N_btn_dashboard.addEventListener('click', () => {

				if (N_container_dashboard && N_container_evolution && N_btn_dashboard && N_btn_evolution) {
					N_container_dashboard.classList.remove('d-none');
					N_container_dashboard.classList.add('d-flex');
					N_container_evolution.classList.add('d-none');
					N_container_evolution.classList.remove('d-flex');

					N_btn_dashboard.classList.add('d-none');
					N_btn_evolution.classList.remove('d-none');
				}

			});

			N_btn_evolution.addEventListener('click', () => {

				if (N_container_dashboard && N_container_evolution && N_btn_dashboard && N_btn_evolution) {
					N_container_dashboard.classList.add('d-none');
					N_container_dashboard.classList.remove('d-flex');
					N_container_evolution.classList.remove('d-none');
					N_container_evolution.classList.add('d-flex');

					N_btn_dashboard.classList.remove('d-none');
					N_btn_evolution.classList.add('d-none');
				}

			});

		}
	}

	private async dashboard(): Promise<void> {
		// On doit avoir les quantites de stocks a zero seulement
		const productPerStorageData = await this.getDataToDashBoard().then(data => data.filter(row => row.quantity >= 0))
		this.gridDashboard(productPerStorageData)
		this.chartDashboard(productPerStorageData)
	}

	private async getDataToDashBoard(): Promise<Array<TabDashBoardRow>> {
		const productsPerStorage = await StocksService.getInstance().getQuantitiesPerStorage(this.currentSite)
		const products = await ProductsService.getInstance().getByIDs(productsPerStorage.map(productPerStorage => productPerStorage.product))
		const storages = await SitesService.getInstance().getStorages(this.currentSite)

		return productsPerStorage.map(productPerStorage => {
			
			return {
				...productPerStorage,
				productData: products.find(product => product._id === productPerStorage.product)!,
				storageData: storages.find(storage => storage.id === productPerStorage.storage)!
			}
		})
	}

	private gridDashboard(data: Array<TabDashBoardRow>) {

		const N_stock = this.el.querySelector("#grid-dashboard") as HTMLDivElement

		if (this.gridOptionsDashBoard && this.gridOptionsDashBoard.api) {
			this.gridOptionsDashBoard.api.setRowData(data)
		}
		else {

			this.gridOptionsDashBoard = agGridFrench({
				defaultColDef: {
					floatingFilter: true,
				},
				rowData: data,
				groupMultiAutoColumn: true,
				suppressAggFuncInHeader: true,
				rememberGroupStateWhenNewData :true,
				suppressDragLeaveHidesColumns: true,
				columnDefs: [
					{
						headerName: "Emplacement",
						showRowGroup: 'storage',
						cellRenderer: 'agGroupCellRenderer',						
						filter: "agTextColumnFilter",
						valueGetter: this.storageValueGetter.bind(this),
					},
					{
						headerName: "Emplacement",
						field: "storage",
						rowGroup: true,
						hide: true,
						valueGetter: this.storageValueGetter.bind(this),
						cellRenderer: (params) => {
							return params.node.childrenAfterGroup[0].data.storageData.name
						}
					},
					{
						headerName: "Produits",
						field: "product",
						filter: "agTextColumnFilter",
						filterParams: {
							filterOptions: ['contains'],
							textCustomComparator: TextFilterAccent
						},
						valueGetter: (params) => {
							return params.data ? params.data.productData.reference : ''
						}
					}, {
						headerName: 'Unité',
						aggFunc: 'first',
						filter: "agTextColumnFilter",
						filterParams: {
							filterOptions: ['contains'],
							textCustomComparator: TextFilterAccent
						},
						valueGetter: (params) => {
							return params.data ? unitList[params.data.productData.unit].name : ''
						}
					}, {
						headerName: "Quantité",
						field: "quantity",
						colId: "quantity",
						width: 100,
						suppressSizeToFit: true,
						filter: 'agNumberColumnFilter',
						aggFunc: 'sum',
						cellClass: 'text-right',
						valueGetter: (params) => {
							return params.data ? _.round(params.data.quantity / unitList[params.data.productData.unit].coeficient, 2) : 0
						},
						cellRenderer: (params) => {
							return _.round(params.value, 2).toString()
						}
					},
					{
						headerName: "isVisible",
						colId: "isvisible",
						valueGetter: (params) => params.getValue("quantity") > 0,
						hide: true
					},
					{
						headerName: "Quantité avec freinte",
						width: 150,
						suppressSizeToFit: true,
						field: 'quantityWastage',
						filter: 'agNumberColumnFilter',
						aggFunc: 'sum',
						cellClass: 'text-right',
						valueGetter: (params) => {
							return params.data ? _.round(params.data.quantityWastage / unitList[params.data.productData.unit].coeficient, 2) : 0
						},
						cellRenderer: (params) => {
							return _.round(params.value, 2).toString()
						}
					}, {
						headerName: '#',
						width: 100,
						suppressSizeToFit: true,
						cellRenderer: (params) => {

							const div = document.createElement('div');

							if (this.loggedUser && this.loggedUser.hasPermission('EXPLOITATION.STOCK.RESET')) {
								div.classList.add('btn-group')
								const N_btnReset = document.createElement('button')
								N_btnReset.classList.add('btn', 'btn-xs', 'btn-info')
								N_btnReset.setAttribute('confirmation', '')
								N_btnReset.innerHTML = 'RAZ'
								N_btnReset.addEventListener('click', () => this.resetHandler(params.node))
								div.appendChild(N_btnReset)
							}

							// TODO: Ajouter une permission pour les details
							if (!params.data && params.node.field == 'storage' && this.loggedUser) {
								const N_btnDetails = document.createElement('button')
								N_btnDetails.classList.add('btn', 'btn-xs', 'btn-info')
								N_btnDetails.innerHTML = 'Details'
								N_btnDetails.addEventListener('click', () => {
									if (this.site && params.node.childrenAfterGroup && params.node.childrenAfterGroup.length) {
										let modal = new ViewDetailsStock(params.node.childrenAfterGroup[0].data.storage, this.currentSite)
										modal.open()
									}
								})
								div.appendChild(N_btnDetails);
							}

							return div
						}
					}
				],
				getContextMenuItems: (params) => {

					if (params.columnApi) {

						let allColumns = params.columnApi.getAllColumns();

						let columnKeys = _.map(allColumns, 'colId');

						columnKeys.pop();

						let exportAllExcel = {
							name: 'Exporter tous Excel',
							action: () => {
								let exp = new Export(params);
								exp.exportExcel({
									columnKeys
								});
							}
						};

						let exportAllCSV = {
							name: 'Exporter tous CSV',
							action: () => {
								let exp = new Export(params);
								exp.exportCSV({
									columnKeys
								});
							}
						};

						return [exportAllExcel, exportAllCSV];

					}

					return [];
				},
				groupSuppressAutoColumn: true,
				onGridReady: (params) => {
					params.api.sizeColumnsToFit()
					params.api.forEachNode(node => {
						// REMARK: C'est le moyen plus simple pour rendre invisible un ligne sans se prendre la tete
						if (!node.leafGroup && node.data.quantity <= 0) {
							node.setRowHeight(0)
						}
					})
					params.api.redrawRows()
				}
			})

			new Grid(N_stock as HTMLElement, this.gridOptionsDashBoard)
		}
	}

	/**
	 * Permet de transformer un tableau de TabDashBoardRow en Tableau de Chart Data qui sera utilisé dans le Chart Dashboard
	 * @param data 
	 */
	private TabDashBoardRow_to_chartData(data: Array<TabDashBoardRow>): Array<ChartData> {
		// La trasnformation se fait deux phases:
		// - Incrementation et concaténation de tous les données en un format de données exploitable
		// - Comparaison avec les emplacements des sites pour definir leur pourcentage
		
			type tabdashboard_row_to_charDataType = { [product: string]: { [storage_or_product: string]: Product | number | string } }

		// Incrementation et concaténation de tous les données en un format de données exploitable
		const tmp: tabdashboard_row_to_charDataType = data.filter(storage => storage.quantity > 0).reduce((allTmp, row) => {
			allTmp[row.product] = allTmp[row.product] || {}
			allTmp[row.product][row.storage] = allTmp[row.product][row.storage] || 0
			allTmp[row.product][row.storage] = allTmp[row.product][row.storage] as number + row.quantity
			allTmp[row.product]['product'] = row.productData
			allTmp[row.product]['product_id'] = row.product
			return allTmp
		}, {} as tabdashboard_row_to_charDataType)
		
		// Comparaison avec les emplacements des sites pour definir leur pourcentage par produit
		const tabChartData: Array<ChartData> = []

		Object.values(tmp).forEach(tmpProductData => {

			let chartDataLine: ChartData = { data: [], name: (tmpProductData['product'] as Product).reference, productId: tmpProductData['product_id'] as string}
			this.site?.storages
				.filter(storage => storage.type === 'box' && !storage.deleted)
				.forEach((storage, index) => {									
						chartDataLine.data[index] = (tmpProductData[storage.id] as number || 0) / storage.capacity * 100
						chartDataLine.data[index] = _.floor(chartDataLine.data[index], 2)											
				})

			tabChartData.push(chartDataLine)
		})		
		return tabChartData
	}

	/**
	 * Permet de créer les emplacement vides en fonction des données récupérés
	 * @param data 
	 */
	private chartDataDashboard_FreeStorage(data: Array<ChartData>): ChartData {

		// Création du stockage vides
		let objFreeSpace: ChartData = {
			name: 'Emplacement disponible',
			color: '#c1c1c1',
			data: [] as number[]
		}

		this.site?.storages
			.filter(storage => storage.type === 'box' && !storage.deleted)
			.forEach(_ => objFreeSpace.data.push(100))

		// REMARK: faite attention à la double boucle
		data.forEach(chartData => chartData.data.forEach((storageData, indexStorage) => {
			objFreeSpace.data[indexStorage] -= storageData
		}))

		return objFreeSpace
	}

	/**
	 * Permet de créer les catégories 
	 */
	private chartDataDashboard_createCategories(): Array<string> {
		return this.site?.storages?.map(storage => storage.name) || []
	}

	private chartDashboard(data: Array<TabDashBoardRow>) {

		if (!this.site) return

		// Création des données
		const dataCharTable = this.TabDashBoardRow_to_chartData(data)
		
		const objFreeSpace: ChartData = this.chartDataDashboard_FreeStorage(dataCharTable)

		const categories = this.chartDataDashboard_createCategories()

		let series = [objFreeSpace, ...dataCharTable] as SeriesOptionsType[]
		
		if (this.site) {

			let N_container = this.el.querySelector('#chart-dashboard')!

			Highcharts.chart(N_container as HTMLElement, {
				chart: {
					type: 'column'
				},
				title: {
					text: 'Pourcentage %'
				},
				xAxis: {
					categories
				},
				yAxis: {					
					title: {
						text: `Quantité`
					},
					plotLines: [{
						value: 0,
						color: '#000000',
						width: 2,
						zIndex: 4,
					}]
				},
				tooltip: {
					headerFormat: '<span style="font-size:10px"><b>{point.key}</b></span><table>',
					pointFormatter: function() {						
						if (this.y === 0) return ''
						return `<tr><td style="color:${this.color};padding:0">${this.series.name}: </td>` +
						`<td style="padding:0"><b>${this.y?.toPrecision(3)} %</b></td></tr>`
					},		
					// pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
					// 	`<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>`,
					footerFormat: '</table>',
					shared: true,
					useHTML: true
				},
				credits: {
					enabled: false
				},
				plotOptions: {
					series: {
						stacking: 'normal',
						events: {
							legendItemClick: function (e) {
								e.preventDefault();
							}
						}
					}
				},
				series
			})

		}

	}

	private getSite(): Promise<Site> {
		return SitesService.getInstance().getByID(this.currentSite)
	}

	private async getAllData(startDate: number, endDate: number): Promise<StockEvent[]> {
		return StocksService.getInstance().getBySite(this.currentSite, startDate, endDate)
	}

	/**
	 * Met à jour tous les produits
	 */
	private async getAllProduct(): Promise<Array<Product>> {
		const data = await ProductsService.getInstance().getAllProducts()
		// this.products = data
		return data
	}

	private evolution() {
		this.initDatePickerEvolution();
		this.getAllData(parseInt(this.startDate.format('x')), parseInt(this.endDate.format('x'))).then((data) => this.chartEvolution(data));
	}

	private initDatePickerEvolution() {

		$(this.el).find('#date-picker-evolution').daterangepicker(drpC({

			startDate: this.startDate,
			endDate: this.endDate,

		}), (start, end) => {

			this.startDate = start.startOf('day')
			this.endDate = end.endOf('day')

			this.getAllData(parseInt(start.format('x'), 10), parseInt(end.format('x'), 10))
				.then((data) => this.chartEvolution(data))
				.catch((e) => console.error(e))

		});

	}

	private chartEvolution(data: StockEvent[]) {

		let oldDate = this.startDate.clone().subtract(1, 'day').endOf('day').format('x')

		StocksService.getInstance().getBySite(this.currentSite, 0, parseInt(oldDate, 10))
			.then((stockEvents) => {

				let oldStockByStorage: { [key: string]: number } = {};

				for (let item of stockEvents) {

					oldStockByStorage[item.storage] = oldStockByStorage[item.storage] || 0;

					let quantity = item.quantity;

					if (item.type == 'output' || item.type == 'alimentation') {
						quantity = -1 * quantity;
					}

					oldStockByStorage[item.storage] += quantity;

				}

				if (this.site) {

					let N_listStorage = this.el.querySelector('#storage-chart-list') as HTMLSelectElement;

					N_listStorage.innerHTML = '';

					let series: any[] = [];

					let groupEvolution: { [key: string]: any } = {};

					let groupInput: { [key: string]: any } = {};
					let groupOutput: { [key: string]: any } = {};
					let groupReset: { [key: string]: any } = {};

					for (let item of data) {

						let idStorage = item.storage;

						groupInput[idStorage] = groupInput[idStorage] || [];
						groupOutput[idStorage] = groupOutput[idStorage] || [];
						groupReset[idStorage] = groupReset[idStorage] || [];

						if (!groupEvolution[idStorage]) {

							let oldValue = oldStockByStorage[idStorage] || 0;

							groupEvolution[idStorage] = {
								value: oldValue,
								data: [[parseInt(oldDate), oldValue]]
							};

						}

						let quantity = item.quantity;

						if (item.type == 'output' || item.type == 'alimentation') {
							quantity = -1 * item.quantity;
						} else {
							quantity = item.quantity;
						}

						groupEvolution[idStorage].value += quantity;

						groupEvolution[idStorage].data.push([
							item.date,
							_.clone(groupEvolution[idStorage].value)
						]);

						if (item.type == 'output' || item.type == 'alimentation') {
							groupOutput[idStorage].push([
								item.date,
								quantity
							]);
						}

						if (item.type == 'input') {
							groupInput[idStorage].push([
								item.date,
								quantity
							]);
						}

						if (item.type == 'reset') {
							groupReset[idStorage].push([
								item.date,
								quantity
							]);
						}

					}

					let numStorage = Object.keys(groupEvolution).length;
					let index = 0;
					let unit = 'tonne';

					for (let idStorage in groupEvolution) {

						let storage = _.find(this.site.storages || [], { id: idStorage });

						if (storage) {

							let name = storage ? storage.name : idStorage;

							unit = storage.unit;

							series.push({
								name: name + ' (Evolution)',
								color: `hsl(${index * (360 / numStorage)},75%,25%)`,
								type: 'spline',
								id: `evolution_${idStorage}`,
								data: _.map(groupEvolution[idStorage].data, (item: number[]) => {
									item[1] = _.round(item[1] / unitList[unit].coeficient, 2);
									return item;
								})
							});

							series.push({
								name: name + ' (Entrée)',
								color: `hsl(${index * (360 / numStorage)},75%,42%)`,
								type: 'column',
								id: `IO_${idStorage}`,
								data: _.map(groupInput[idStorage], (item: number[]) => {
									item[1] = _.round(item[1] / unitList[unit].coeficient, 2);
									return item;
								})
							});

							series.push({
								name: name + ' (Sortie)',
								color: `hsl(${index * (360 / numStorage)},75%,58%)`,
								type: 'column',
								id: `IO_${idStorage}`,
								data: _.map(groupOutput[idStorage], (item: number[]) => {
									item[1] = _.round(item[1] / unitList[unit].coeficient, 2);
									return item;
								})
							});

							series.push({
								name: name + ' (R.A.Z. Stock)',
								color: `hsl(${index * (360 / numStorage)},75%,75%)`,
								type: 'column',
								id: `IO_${idStorage}`,
								data: _.map(groupReset[idStorage], (item: number[]) => {
									item[1] = _.round(item[1] / unitList[unit].coeficient, 2);
									return item;
								})
							});



							if (N_listStorage) {

								N_listStorage.innerHTML += `
                            <optgroup label="${name}">
                                <option value="IO_${idStorage}" selected>Entrée/Sortie</option>
                                <option value="evolution_${idStorage}" selected>Evolution</option>
                            </optgroup>
                        `;

							}

							index++;
						}

					}

					const N_container_chart = this.el.querySelector('#chart-evolution');

					if (N_container_chart) {

						const charts = Highcharts.stockChart(N_container_chart as HTMLElement, {
							chart: {
								zoomType: 'x',
							},
							xAxis: {
								type: 'datetime',
							},
							tooltip: {
								headerFormat: '<span style="font-size:10px">{point.key}</span><table>',								
								pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
									`<td style="padding:0"><b>{point.y:.1f} ${unitList[unit].unit}</b></td></tr>`,
								footerFormat: '</table>',
								shared: true,
								useHTML: true
							},
							plotOptions: {
								series: {
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										}
									}
								}
							},
							title: {
								text: 'Evolution'
							},
							yAxis: {
								title: {
									text: `Quantité (${unitList[unit].name})`
								}
							},
							series
						});

						if (N_listStorage) {

							N_listStorage.querySelectorAll('option').forEach((N_option) => {

								N_option.addEventListener('mousedown', (e) => {

									N_option.selected = !N_option.selected;

									for (let serie of charts.series) {

										if ((serie.options.id || '').startsWith(N_option.value)) {

											if (N_option.selected) {
												serie.show()
											} else {
												serie.hide()
											}

										}

									}

									e.preventDefault()

								})

							})

						}

					}

				}

			}).catch((e) => {
				console.error(e);
			});

	}

	private async resetHandler(node: RowNode) {
		let functionToCall = _.isEmpty(node.childrenAfterGroup)
			? this.resetProductInStorage.bind(this)
			: this.resetAllStorageFromNode.bind(this)

		try {
			await functionToCall(node)
		}
		catch {
			toaster.error('Impossible de reinitialiser')
		}
	}

	private async resetProductInStorage(node: RowNode) {
		const data = node.data

		const product = data.product
		const storage = data.storage
		const quantity = data.quantity
		const quantityWastage = data.quantityWastage

		await StocksService.getInstance().create({
			_id: BDD.generateID(),
			date: Date.now(),
			isTransfert: false,
			product,
			storage,
			site: this.currentSite,
			wastage: 1 - ((quantityWastage * 100 / quantity) / 100),
			quantity: -1 * quantity,
			type: 'reset'
		})

		this.refreshUI()
	}

	private async resetAllStorageFromNode(node: RowNode) {
		const lineProductsToChange: Array<StockEvent> = []
		for (let lineProduct of node.childrenAfterGroup || []) {
			const product = lineProduct.data.product
			const storage = lineProduct.data.storage
			const quantity = lineProduct.data.quantity
			const quantityWastage = lineProduct.data.quantityWastage

			if (quantity) {
				lineProductsToChange.push({
					_id: BDD.generateID(),
					date: Date.now(),
					isTransfert: false,
					product,
					storage,
					site: global.SITE,
					wastage: 1 - ((quantityWastage * 100 / quantity) / 100),
					quantity: -1 * quantity,
					type: 'reset'
				})
			}

		}

		await StocksService.getInstance().updateAll(lineProductsToChange)
		this.refreshUI()
	}

	private refreshUI() {
		this.dashboard()
		this.evolution()
	}

	private storageValueGetter(params: ValueGetterParams) {
		return this.site && params.data ? params.data.storageData.name : ''
	}

	public destructor() {
		const N_daterangepicker = document.querySelectorAll('.daterangepicker')
		N_daterangepicker.forEach((item) => item.remove())
	}


}

export default Stock;

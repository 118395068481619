import { Modal } from "@autoprog/core-client"
import TEMPLATE_MODAL from '@tpl/utils/printer.helper.html'

class PrinterHelper {
	private constructor() { }

	public static download(fileBlob: Blob, documentName: string) {
		const url = URL.createObjectURL(fileBlob)
		const a = document.createElement('a')
		a.href = url
		a.download = documentName
		a.click()
		a.remove()
	}

	public static async previewPDF(pdfBlob: Blob, documentName: string) {
		const modal = new PDFModal(pdfBlob, documentName)
		return modal.open()
	}
}

class PDFModal extends Modal {
	constructor(pdfBlob: Blob, documentaName: string) {
		super({ tpl: TEMPLATE_MODAL, backdrop: 'static', keyboard: false })

		this.on('opened', async () => {

			const N_dialog = this.element.querySelector('.modal-dialog') as HTMLDivElement
			N_dialog.style.maxWidth = "100%"
			N_dialog.style.maxHeight = "100%"

			const N_title = this.element.querySelector('.modal-title') as HTMLElement
			N_title.textContent = `Aperçu : ${documentaName}`

			const iframe = this.element.querySelector('#iframe-pdf') as HTMLIFrameElement
			const url = URL.createObjectURL(pdfBlob)
			iframe.src = url

			const N_download = this.element.querySelector('#download-pdf') as HTMLButtonElement
			N_download.addEventListener('click', () => PrinterHelper.download(pdfBlob, documentaName))

			const N_print = this.element.querySelector('#print-pdf') as HTMLButtonElement
			N_print.addEventListener('click', () => iframe.contentWindow?.print())
		})
	}
}

export default PrinterHelper

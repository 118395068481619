
import { Tab, toaster } from "@autoprog/core-client"
import AddEditUnit from '@js/libs/modals/settings/AddEditUnit';
import UnitsService from '@js/libs/services/UnitsService';

import { GridOptions, Grid } from 'ag-grid-community';

class UnitsTab extends Tab {

	private unitsOptions!: GridOptions

	constructor(private el: HTMLElement) {
		super(el)
		this.shouldInit()
	}

	private async shouldInit() {
		this.createGrid()
		this.loadUnit()
		
		const N_addUnit = this.el.querySelector('#add-unit-btn') as HTMLButtonElement
		N_addUnit.addEventListener('click', () => new AddEditUnit().open().then(() => this.loadUnit()))
	}

	private createGrid() {
		this.unitsOptions = {
			columnDefs: [
				{
					headerName: 'Nom',
					field: 'name',
					sort: 'asc'
				},
				{
					headerName: 'Unité',
					field: 'unit'
				},
				{
					headerName: 'Coeficient',
					field: 'coeficient'
				},
				{
					headerName: "",
					field: "commands",
					width: 8 + 28 * 2,
					sortable: false,
					filter: false,
					suppressSizeToFit: true,
					pinned: 'right',
					cellRenderer: (params) => {
						let div = document.createElement('div') as HTMLDivElement
						div.classList.add('d-flex', 'justify-content-end')

						let editButton = document.createElement('button')
						editButton.classList.add('btn', 'btn-xs', 'btn-info')
						editButton.setAttribute('permission', 'SETTINGS.UNITS.EDIT')
						editButton.innerHTML = '<i class="icon icon-solid-pencil-alt"></i>'

						editButton.addEventListener('click', () => new AddEditUnit(params.data._id).open().then(() => this.loadUnit()))
						
						let deleteButton = document.createElement('button')
						deleteButton.classList.add('btn', 'btn-xs', 'btn-danger')
						deleteButton.setAttribute('confirmation', '')
						deleteButton.setAttribute('permission', 'SETTINGS.UNITS.DELETE')
						deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"></i>'

						deleteButton.addEventListener('click', async () => {
							await UnitsService.getInstance().delete(params.data._id)
							this.loadUnit()
							toaster.success(`Suppression de l'unité`)
						})

						div.appendChild(editButton)
						div.appendChild(deleteButton)

						return div
					},
				}
			],
			rowData: [],
			defaultColDef: {
				sortable: true
			},
			onGridReady: (params) => params.api.sizeColumnsToFit()
		}

		const N_grid = this.el.querySelector('#units-grid') as HTMLDivElement
		new Grid(N_grid, this.unitsOptions)
	}

	private async loadUnit() {
		const data = UnitsService.getInstance().getAllAvailable()
		this.unitsOptions.api && this.unitsOptions.api.setRowData(data)
	}

	public destructor(): void {

	}


}

export default UnitsTab;
import T_password from '@tpl/modals/password.html'

import UsersService from '@libs/services/UsersService'
import { Alert, Modal } from '@autoprog/core-client'

import _ from 'lodash'

class PasswordChangeModal extends Modal {

	constructor() {

		super({
			tpl: T_password,
			backdrop: 'static',
			keyboard: false
		});

		this.on('opened', () => {

			const N_passwordText = this.element.querySelector('#password-input') as HTMLInputElement
			const N_confirmationText = this.element.querySelector('#password-confirmation-input') as HTMLInputElement
			const N_saveBtn = this.element.querySelector('#confirm-ne-password-button') as HTMLButtonElement


			N_saveBtn.addEventListener('click', async () => {

				// Changement de mot de passe
				const newPassword = N_passwordText.value
				const confirmationPassword = N_confirmationText.value


				if (newPassword != confirmationPassword) {
					Alert.error('Erreur de saisie', 'Les deux mot de passe ne sont pas identiques')
					return
				}


				// TODO: Demande de validation

				UsersService.getInstance().changePassword(newPassword)
				Alert.info('Confirmation', 'Changement de mot de passe changé')

				this.resolve()
			})

		});

	}

}

export default PasswordChangeModal;
import { ICellRendererParams, ICellRendererComp  } from 'ag-grid-community';

class FullWidthCellRenderer implements ICellRendererComp  {

    private eGui : HTMLElement | null = null;

    constructor() {

    }

    public init(params : ICellRendererParams) {


        let el = document.createElement('h5');

        el.className = 'w-100 h-100 pt-2 pl-5 bg-grey-300';

        el.innerHTML = params.data.date;

        this.eGui = el;

    }

    public getGui() {
        return this.eGui as HTMLElement;
    }
    
    public refresh(_params : any){
        return false;
    }

}

export default FullWidthCellRenderer;

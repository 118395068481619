import h from 'hyperscript'
import Tier from '@js/types/Tiers';

class CE_PrintTierBlock extends HTMLElement {

	private static tagName = 'print-tier-block'
	private tier?: Tier;
	private _title?: string;

	public static register() {
		customElements.define(CE_PrintTierBlock.tagName, CE_PrintTierBlock);
	}

	public constructor() {
		super();

	}

	public connectedCallback() {

	}

	public set dataSource(data: Tier) {
		this.tier = data;
		this.draw();
	}

	public set title(title: string) {
		this._title = title;
		this.draw();
	}


	private draw() {
		this.innerHTML = '';
		if (this._title) {
			this.appendChild(h('div.title', this._title))
		}
		if (this.tier) {
			let N_address = h('address', h('span.name', this.tier.name));

			if (this.tier.bill) {
				N_address.appendChild(h('span.street', this.tier.bill.address))
				N_address.appendChild(h('span.zip-code', this.tier.bill.zipCode))
				N_address.appendChild(h('span.city', this.tier.bill.city))
			} else {
				N_address.appendChild(h('span.street', this.tier.address))
				N_address.appendChild(h('span.zip-code', this.tier.zipCode))
				N_address.appendChild(h('span.city', this.tier.city))
			}

			N_address.appendChild(h('span.phone', this.tier.phone))
			N_address.appendChild(h('span.siret', this.tier.siret))
			this.append(N_address);
		}
	}


}

export default CE_PrintTierBlock;
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="modal fade" role="dialog">\r\n    <div class="modal-dialog modal-80" id="modal-more-options" role="document">\r\n\r\n        <div class="modal-content">\r\n\r\n            <div class="modal-header sticky-top container-fluid white">\r\n\r\n                <div>\r\n                    <h5 class="modal-title">Nouvel évenement</h5>\r\n                </div>\r\n\r\n                <div class="text-right">\r\n                    <button class="btn btn-green btn-rounded" id="save">Sauvegarder</button>\r\n                    <button class="btn btn-red btn-rounded" data-reject="modal">Annuler</button>\r\n                </div>\r\n\r\n            </div>\r\n\r\n            <div class="modal-body">\r\n\r\n                <div class="row w-100">\r\n\r\n                    <div class="col-lg-6">\r\n                        <div>Détails</div>\r\n                        <div class="border-bottom border-green my-2"></div>\r\n\r\n                        <div class="form-group">\r\n                            <label>Titre : <span class="text-red">*</span></label>\r\n                            <input type="text" class="form-control" id="title" placeholder="Titre" value="' +
((__t = (data.title)) == null ? '' : __t) +
'">\r\n                        </div> \r\n\r\n                        <div class="form-group row">\r\n\r\n                            <div class="col-sm-12 col-lg-6" id="site-selected">\r\n                                <label>Site :</label>\r\n                                \r\n                                <site-select class="form-control"></site-select>\r\n\r\n                            </div>\r\n\r\n                            <div class="col-sm-12 col-lg-6">\r\n                                <label>Agenda :</label>\r\n\r\n                                <div class="dropdown" id="dropdown-calendar">\r\n\r\n                                    <button class="form-control text-left" id="button-dropdown-calendar" data-calendar-id="' +
((__t = (selectedCal._id)) == null ? '' : __t) +
'">\r\n                                        <span class="tui-full-calendar-icon tui-full-calendar-calendar-dot" style="background-color: ' +
((__t = (selectedCal.bgColor)) == null ? '' : __t) +
';"></span>\r\n                                        <span id="calendar-name">' +
((__t = (selectedCal.name)) == null ? '' : __t) +
'</span>\r\n                                        <span class="tui-full-calendar-icon tui-full-calendar-dropdown-arrow"></span>\r\n                                    </button>\r\n                                    \r\n                                    ';
for(let site in calendarList){;
__p += '\r\n\r\n                                        <ul class="dropdown-menu" id="list-dropdown-calendar" data-site="' +
((__t = (site)) == null ? '' : __t) +
'" style="display:none;">\r\n\r\n                                            ';
for(let category in calendarList[site]){;
__p += '\r\n\r\n                                                ';
if(category){;
__p += '\r\n                                                    <li class="dropdown-item">\r\n                                                        <span>\r\n                                                            <i class="icon icon-solid-chevron-right d-inline-block"></i>\r\n                                                            ' +
((__t = (category)) == null ? '' : __t) +
'\r\n                                                        </span>\r\n                                                    </li>\r\n                                                    <ul>\r\n                                                ';
};
__p += '\r\n\r\n                                                ';
for(let calendar of calendarList[site][category]){;
__p += '\r\n                                                    <li class="dropdown-item" data-calendar-id="' +
((__t = (calendar._id)) == null ? '' : __t) +
'">\r\n                                                        <span class="tui-full-calendar-icon tui-full-calendar-calendar-dot" style="background-color: ' +
((__t = (calendar.bgColor)) == null ? '' : __t) +
';"></span>\r\n                                                        <span id="calendar-name">' +
((__t = (calendar.name)) == null ? '' : __t) +
'</span>\r\n                                                    </li>\r\n                                                ';
};
__p += '\r\n\r\n                                                ';
if(category){;
__p += '\r\n                                                    </ul>\r\n                                                ';
};
__p += '\r\n                                        \r\n                                            ';
};
__p += '\r\n\r\n                                        </ul>\r\n\r\n                                    ';
};
__p += '\r\n\r\n                                </div>\r\n\r\n                            </div>\r\n\r\n                        \r\n                        </div>\r\n\r\n                        <div class="form-group row">\r\n\r\n                            <div class="col-lg-6 col-sm-12">\r\n                                <label>Début :</label>\r\n                                <input type="text" class="form-control" id="start-date" placeholder="Date début">\r\n                                <div id="container-start-date-range-picker" style="margin-left: 12px; position: relative"></div>\r\n                            </div>\r\n                            <div class="col-lg-6 col-sm-12">\r\n                                <label>Fin :</label>\r\n                                <input type="text" class="form-control" id="end-date" placeholder="Date fin">\r\n                                <div id="container-end-date-range-picker" style="margin-left: 12px; position: relative"></div>\r\n                            </div>\r\n                        </div>\r\n\r\n                        <div class="custom-control custom-checkbox col-lg-12 col-sm-12 mb-3">\r\n                                <input type="checkbox" class="custom-control-input" id="all-days" ' +
((__t = (data.isAllDay ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                <label class="custom-control-label" for="all-days">Journée entière</label>\r\n                            </div>\r\n\r\n                        <div class="form-group">\r\n                            <label>Description :</label>\r\n                            <textarea class="form-control" id="description" placeholder="Description">' +
((__t = (data.raw.description)) == null ? '' : __t) +
'</textarea>\r\n                        </div>\r\n\r\n                        <label>\r\n                            Intervenants :\r\n                        </label>\r\n                        <ul id="users" class="list-group scroll-x mb-3">\r\n                            <li class="list-group-item p-0">\r\n                                <select class="form-control" id="select-list-users"></select>\r\n                            </li>\r\n                        </ul>\r\n\r\n                    </div>\r\n\r\n                    <div class="col-lg-6">\r\n                        <div>\r\n                            Répéter\r\n                            <div class="custom-control custom-checkbox float-right">\r\n                                <input type="checkbox" class="custom-control-input" id="enableRepeat" ' +
((__t = (!repeat.notEnabled ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                <label class="custom-control-label" for="enableRepeat">Activer</label>\r\n                            </div>\r\n                        </div>\r\n                        <div class="border-bottom border-green my-2"></div>\r\n\r\n                        <div id="container-repeat">\r\n                            <div>\r\n                                <span class="text-underline">Fréquence </span>\r\n\r\n                                <div class="ml-2 mt-2">\r\n                                    \r\n                                    <div>\r\n                                        <span class="mt-1 mr-1 ">\r\n                                            &bull;\r\n                                        </span>\r\n                                        <span class="mt-1">\r\n                                            Répéter tout(es) les :\r\n                                        </span>\r\n                                        <div class="pl-3 input-group form-group mt-2">\r\n                                            <input class="form-control w-30" id="value-frequency" type="number" value="' +
((__t = (repeat.frequency.value)) == null ? '' : __t) +
'">\r\n                                            <select class="form-control w-40" id="type-frequency">\r\n                                                <option value="day"   ' +
((__t = (repeat.frequency.type == 'day'   ? 'selected' : '')) == null ? '' : __t) +
'>jour(s)</option>\r\n                                                <option value="week"  ' +
((__t = (repeat.frequency.type == 'week'  ? 'selected' : '')) == null ? '' : __t) +
'>semaine(s)</option>\r\n                                                <option value="month" ' +
((__t = (repeat.frequency.type == 'month' ? 'selected' : '')) == null ? '' : __t) +
'>mois</option>\r\n                                                <option value="year"  ' +
((__t = (repeat.frequency.type == 'year'  ? 'selected' : '')) == null ? '' : __t) +
'>année(s)</option>\r\n                                            </select>\r\n                                        </div>\r\n                                    </div>\r\n\r\n                                    <div id="week-options" class="' +
((__t = (repeat.frequency.type != 'week' ? 'd-none' : '')) == null ? '' : __t) +
'">\r\n                                        &bull; Repeter le :\r\n\r\n                                        ';
let days = repeat.frequency.days || {};
__p += '\r\n                                        <div>\r\n                                            <div class="custom-control custom-checkbox ml-2 mt-2">\r\n                                                <input type="checkbox" class="custom-control-input" id="monday"    ' +
((__t = (days.monday ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                                <label class="custom-control-label" for="monday">Lundi</label>\r\n                                            </div>\r\n                                            <div class="custom-control custom-checkbox mt-2 ml-2">\r\n                                                <input type="checkbox" class="custom-control-input" id="tuesday"   ' +
((__t = (days.tuesday ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                                <label class="custom-control-label" for="tuesday">Mardi</label>\r\n                                            </div>\r\n                                            <div class="custom-control custom-checkbox mt-2 ml-2">\r\n                                                <input type="checkbox" class="custom-control-input" id="wednesday" ' +
((__t = (days.wednesday ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                                <label class="custom-control-label" for="wednesday">Mercredi</label>\r\n                                            </div>\r\n                                            <div class="custom-control custom-checkbox mt-2 ml-2">\r\n                                                <input type="checkbox" class="custom-control-input" id="thursday"  ' +
((__t = (days.thursday ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                                <label class="custom-control-label" for="thursday">Jeudi</label>\r\n                                            </div>\r\n                                            <div class="custom-control custom-checkbox mt-2 ml-2">\r\n                                                <input type="checkbox" class="custom-control-input" id="friday"    ' +
((__t = (days.friday ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                                <label class="custom-control-label" for="friday">Vendredi</label>\r\n                                            </div>\r\n                                            <div class="custom-control custom-checkbox mt-2 ml-2">\r\n                                                <input type="checkbox" class="custom-control-input" id="saturday"  ' +
((__t = (days.saturday ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                                <label class="custom-control-label" for="saturday">Samedi</label>\r\n                                            </div>\r\n                                            <div class="custom-control custom-checkbox mt-2 ml-2">\r\n                                                <input type="checkbox" class="custom-control-input" id="sunday"    ' +
((__t = (days.sunday ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                                <label class="custom-control-label" for="sunday">Dimanche</label>\r\n                                            </div>\r\n                                            \r\n                                        </div>\r\n                                        \r\n                                    </div>\r\n\r\n                                </div>\r\n\r\n                            </div>\r\n\r\n                            <div class="mt-2">\r\n                                <span class="text-underline">Se termine</span>\r\n                                <div class="ml-2">\r\n                                    <div class="custom-control custom-radio my-2 ' +
((__t = (!repeat.end ? '' : 'text-muted')) == null ? '' : __t) +
'">\r\n                                        <input type="radio" id="never" name="lastuse" class="custom-control-input" ' +
((__t = (!repeat.end ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                        <label class="custom-control-label" for="never">&nbsp;</label>\r\n                                        Jamais\r\n                                    </div>\r\n                                    <div class="custom-control custom-radio my-2 ' +
((__t = (repeat.end.date ? '' : 'text-muted')) == null ? '' : __t) +
'">\r\n                                        <input type="radio" id="inDate" name="lastuse" class="custom-control-input" ' +
((__t = (repeat.end.date ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                        <label class="custom-control-label" for="inDate">&nbsp;</label>\r\n                                        <div class="d-inline-flex">\r\n                                            <span class="mt-1">Le </span>\r\n                                            <div>\r\n                                                <input type="text" data-type="value" id="date-end-repeat" class="form-control mx-2" disabled>\r\n                                                <div id="date-end-repeat-container ml-2"></div>\r\n                                            </div>\r\n                                        </div>\r\n                                    </div>\r\n                                    <div class="custom-control custom-radio my-2 ' +
((__t = (repeat.end.times ? '' : 'text-muted')) == null ? '' : __t) +
'">\r\n                                        <input type="radio" id="times" name="lastuse" class="custom-control-input" ' +
((__t = (repeat.end.times ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                        <label class="custom-control-label" for="times">&nbsp;</label>\r\n                                        <div class="d-inline-flex">\r\n                                            <span class="mt-1">Après </span>\r\n                                            \r\n                                            <input type="number" data-type="value" class="form-control mx-2 " style="width:100px " id="times-repeat" disabled value="' +
((__t = (repeat.end.times)) == null ? '' : __t) +
'">\r\n                                           <span class="mt-1">occurrences</span> \r\n                                        </div>\r\n                                    </div>\r\n\r\n                                </div>\r\n\r\n                            </div>\r\n\r\n                        </div>\r\n\r\n                    </div>\r\n                    \r\n                </div>\r\n            </div>\r\n\r\n        </div>\r\n    </div>\r\n</div>';

}
return __p
}
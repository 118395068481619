import _ from 'lodash'

export default (n: number, options?: any): string => {

	if (!_.isNull(n)) {
		options = options || {};
		let d = options.delimiter || ' ';
		let s = options.separator || '.';
		let res = n.toString().split('.');
		res[0] = res[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + d);

		return res.join(s);
	} else {
		return '';
	}
}
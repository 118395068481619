import { Tabs } from '@autoprog/core-client'
class TabsExtended extends Tabs {
	public async showTab(id: string): Promise<void> {
		await super.showTab(id)
		return new Promise((resolve, _reject) => {
			setTimeout(() => {
				this.emit('tab.show')
				resolve()
			})
		})
	}

}

export default TabsExtended
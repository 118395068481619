module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table class="table table-sm">\r\n\r\n    <thead>\r\n\r\n        <th>Type</th>\r\n        <th>Utilisateur</th>\r\n        <th>Date</th>\r\n        <th>Change etat</th>\r\n\r\n    </thead>\r\n\r\n    <tbody>\r\n\r\n        ';
  
            let translate = {
                create : 'Creation',
                edit : 'Modification',
                delete : 'Suprresion',
            }
        ;
__p += '\r\n\r\n        ';
for(let item of events){;
__p += '\r\n            \r\n            <tr>\r\n\r\n                <td>\r\n                    ' +
((__t = (translate[item.type])) == null ? '' : __t) +
'\r\n                </td>\r\n\r\n                <td>\r\n                    ' +
((__t = (item.user.firstname)) == null ? '' : __t) +
' ' +
((__t = (item.user.lastname)) == null ? '' : __t) +
'\r\n                </td>\r\n\r\n                <td>\r\n                    ' +
((__t = (moment(item.date).format('DD/MM/YYYY HH:mm'))) == null ? '' : __t) +
'\r\n                </td>\r\n\r\n                <td>\r\n                    ';
if(item.changeState && item.changeState.to && item.changeState.from){;
__p += '\r\n                        \r\n                        ' +
((__t = (stateList[item.changeState.from].name)) == null ? '' : __t) +
' &#8594; ' +
((__t = (stateList[item.changeState.to].name)) == null ? '' : __t) +
'\r\n\r\n                    ';
};
__p += '\r\n                </td>\r\n\r\n            </tr>    \r\n            \r\n        ';
};
__p += '\r\n\r\n    </tbody>\r\n\r\n</table>';

}
return __p
}
import { Tab, global, toaster, Alert, LoggedUser } from "@autoprog/core-client"

import { Grid, GridOptions } from 'ag-grid-community'
import { CouchDBData } from '@js/types/couchDB'
import 'ag-grid-enterprise'
import moment, { Moment } from 'moment'
import h from 'hyperscript'
import BDD from '@js/libs/BDD'

import Highcharts from '@libs/Highcharts'
import unitList from '@libs/lists/unitList'
import AddEditAlimentationModal from '@libs/modals/exploitation/AddEditAlimentationModal'
import AddQuantityPredictive from '@libs/modals/exploitation/AddQuantityPredictive';
import AgGridStateSaver from '@libs/agGrid/StateSaver2';

import drpC from '@libs/utils/daterangepickerConfig'
import { french as agGridFrench } from '@libs/utils/agGrid'

import Export from '@libs/agGrid/Export'

import TextFilterAccent from '@libs/agGrid/TextFilterAccent'

import '@css/alimentation.scss'
import AlimentationsService from '@js/libs/services/AlimentationsService'
import StocksService from '@js/libs/services/StocksService'
import SitesService from '@js/libs/services/SitesService'
import AlimentationPredictiveService from '@js/libs/services/AlimentationPredictiveService'
import ProductsService from '@js/libs/services/ProductsService'

import _ from "lodash"


class Alimentation extends Tab {

	private currentSite: string = global.SITE;

	private startDate: moment.Moment = moment().startOf('month')
	private endDate: moment.Moment = moment().endOf('month')

	private products: CouchDBData[] = [];
	private site: CouchDBData | null = null;

	private sumByDay: { [key: string]: any } = {}

	private gridOptionsDaily: GridOptions = {};
	private gridOptionsPredictive: GridOptions = {};

	private stateAGrid: AgGridStateSaver | undefined
	private stateAGridDaily: AgGridStateSaver | undefined
	private loggedUser: LoggedUser


	constructor(private el: HTMLElement) {
		super(el)

		this.loggedUser = LoggedUser.getInstance()

		this.getStorageBySite()
			.then((site) => {
				this.site = site
				return this.getAllProduct()
			})
			.then(() => this.init())

	}

	private async init() {

		// Récupération des données
		let promiseAll = await Promise.all([
			this.getDataToPredictive(this.startDate.format('x')),
			this.getDataToDaily(this.startDate.format('x'), this.endDate.format('x'))
		])

		let [{ rowData, columnDefs }, dailyRowData] = promiseAll


		// Affichage des différents grid et chart
		this.gridPredictive(rowData, columnDefs)
		this.predictive()
		this.chart(rowData)
		this.datePickerPredictive()
		this.btn_chart_grid()

		this.gridDaily(dailyRowData)
		this.daily()
		this.datePickerDaily()

	}

	public updateSelectSite(value: string) {

		this.currentSite = value;
		this.stateAGrid && this.stateAGrid.save();
		this.stateAGridDaily && this.stateAGridDaily.save();
		this.stateAGrid = new AgGridStateSaver(this.gridOptionsPredictive, global.SITE + "_predictive", `Tableau Alimentation Prévisionelle ${global.SITE}`);
		this.stateAGridDaily = new AgGridStateSaver(this.gridOptionsDaily, global.SITE + "_daily", `Tableau Alimentation Journalière ${global.SITE}`);
		this.stateAGrid.load();
		this.stateAGridDaily.load();

		this.getStorageBySite().then((site) => {

			this.site = site;

			this.getDataToPredictive(this.startDate.format('x')).then(({ rowData, columnDefs }) => {

				this.gridPredictive(rowData, columnDefs)
				this.chart(rowData);

			});

			this.getDataToDaily(this.startDate.format('x'), this.endDate.format('x')).then(this.gridDaily.bind(this));

		});

	}

	private btn_chart_grid() {

		let N_containerPredictive = this.el.querySelector('#predictive-container') as HTMLElement;
		let N_containerDaily = this.el.querySelector('#daily-container') as HTMLElement;
		let N_containerChart = this.el.querySelector('#chart-container') as HTMLElement;

		let N_btn_chart = this.el.querySelector('#button-chart');
		let N_btn_grid = this.el.querySelector('#button-grid');

		let N_addAlimentation = this.el.querySelector('#add-alimentation');

		if (N_btn_chart) {

			N_btn_chart.addEventListener('click', () => {

				if (N_containerPredictive && N_containerDaily && N_containerChart) {

					N_containerPredictive.classList.add('d-none');
					N_containerDaily.classList.add('d-none');
					N_containerChart.classList.remove('d-none');

					N_btn_chart && N_btn_chart.classList.add('d-none');
					N_btn_grid && N_btn_grid.classList.remove('d-none');

				}

			});

		}

		if (N_btn_grid) {

			N_btn_grid.addEventListener('click', () => {

				if (N_containerPredictive && N_containerDaily && N_containerChart) {

					if (N_addAlimentation && N_addAlimentation.classList.contains('d-none')) {

						N_containerPredictive.classList.remove('d-none');
						N_containerDaily.classList.add('d-none');

					} else {

						N_containerPredictive.classList.add('d-none');
						N_containerDaily.classList.remove('d-none');

					}

					N_containerChart.classList.add('d-none');

					N_btn_chart && N_btn_chart.classList.remove('d-none');
					N_btn_grid && N_btn_grid.classList.add('d-none');

				}

			});

		}

	}

	private gridPredictive(rowData: any[], columnDefs: any[]) {

		if (this.gridOptionsPredictive && this.gridOptionsPredictive.api) {

			this.gridOptionsPredictive.api.setRowData(rowData);
			this.gridOptionsPredictive.api.setColumnDefs(columnDefs);

		} else {

			this.gridOptionsPredictive = agGridFrench({
				rowData,
				columnDefs,
				suppressDragLeaveHidesColumns: true,
				defaultColDef: {
					width: 150,
					resizable: true,
					suppressMenu: true,
					valueGetter: (params: any) => {

						let value = _.get(params.data, params.colDef.field) || '';

						if (params.colDef.field != 'product' && params.colDef.field != 'stock') {

							let product = _.find(this.products, { _id: params.data.product });

							if (product) {
								return _.round(value / unitList[product.unit].coeficient, 2);
							}

						}

						return value;
					},
					cellRenderer: (params: any) => {
						return params.value;
					}
				},
				getContextMenuItems: (params) => {

					let exportAllExcel = {
						name: 'Exporter tous Excel',
						action: () => {
							let exp = new Export(params);
							exp.exportExcel();
						}
					};

					let exportAllCSV = {
						name: 'Exporter tous CSV',
						action: () => {
							let exp = new Export(params);
							exp.exportCSV();
						}
					};


					let result = [
						exportAllExcel,
						exportAllCSV,
						{
							name: 'Ajouter quantité prévisionnelle',
							action: () => {

								const colDef = params.column.getColDef()

								if (colDef && colDef.field) {

									const modal = new AddQuantityPredictive(this.currentSite, colDef.field.split('.')[0], params.node.data.product);

									modal.open().then(() => {

										this.getDataToPredictive(this.startDate.format('x')).then(({ rowData }) => {

											if (this.gridOptionsPredictive && this.gridOptionsPredictive.api) {

												this.gridOptionsPredictive.api.setRowData(rowData)
												this.chart(rowData)

											}

										});

									})

								}

							}
						}
					];

					return result;

				},
				onCellEditingStopped: (params) => {

					if (params && params.api && params.colDef && params.colDef.field) {

						let lineRowdata = _.findIndex(rowData, { product: params.data.product });

						let chunkfield = params.colDef.field.split('.');

						chunkfield.pop();

						let key = chunkfield.join('.');

						let product = _.find(this.products, { _id: params.data.product });

						let data = _.get(params.data, key);

						data.quantity = parseInt(data.quantity || 0);

						if (product) {
							data.quantity = data.quantity * unitList[product.unit].coeficient;
						}

						let isNewData = false
						if (!data._id) {
							isNewData = true
							data._id = BDD.generateID();
							data.month = chunkfield[0]
							data.product = params.data.product;
							data.site = this.currentSite

						}

						_.set(rowData[lineRowdata], params.colDef.field, data.quantity);

						let sum = 0;

						for (let month in params.data) {
							if (month != 'sum' && month != 'product' && month != 'stock') {
								sum += params.data[month].predictive.quantity;
							}
						}

						params.data.sum.predictive.quantity = sum;

						params.node.setData(params.data);
						let functionToExecute = (isNewData)
							? AlimentationPredictiveService.getInstance().create
							: AlimentationPredictiveService.getInstance().update

						functionToExecute(data).then(() => {
							params.api.refreshCells({ force: true });
							this.chart(rowData)
						})
					}
				}
			});

			let N_Predictive = this.el.querySelector("#predictive-grid")!
			new Grid(N_Predictive as HTMLElement, this.gridOptionsPredictive)
			this.stateAGrid = new AgGridStateSaver(this.gridOptionsPredictive, global.SITE + '_predictive', `Tableau Alimentation Prévisionelle ${global.SITE}`);
			this.stateAGrid.load()

		}

	}

	private predictive(): void {

		let N_addPredictive = this.el.querySelector('#add-predictive');

		if (N_addPredictive) {

			N_addPredictive.addEventListener('click', () => {

				let modal = new AddQuantityPredictive(this.currentSite);

				modal.open().then(() => {

					this.getDataToPredictive(this.startDate.format('x')).then(({ rowData, columnDefs }) => {

						this.gridPredictive(rowData, columnDefs)
						this.chart(rowData);

					});

				}).catch(() => {

				});

			});

			let N_containerPredictive = this.el.querySelector('#predictive-container') as HTMLElement;
			let N_containerDaily = this.el.querySelector('#daily-container') as HTMLElement;
			let N_containerChart = this.el.querySelector('#chart-container') as HTMLElement;

			let N_btn_daily = this.el.querySelector('#daily');

			let N_btn_chart = this.el.querySelector('#button-chart');
			let N_btn_grid = this.el.querySelector('#button-grid');

			let N_addAlimentation = this.el.querySelector('#add-alimentation');

			let N_dateDaily = this.el.querySelector('#date-picker-daily');
			let N_datePredictive = this.el.querySelector('#date-picker-predictive');

			let N_btn_predictive = this.el.querySelector('#predictive');

			if (N_btn_daily) {

				N_btn_daily.addEventListener('click', () => {

					N_containerDaily && N_containerDaily.classList.remove('d-none');
					N_containerPredictive && N_containerPredictive.classList.add('d-none');
					N_containerChart && N_containerChart.classList.add('d-none');

					N_btn_grid && N_btn_grid.classList.add('d-none');
					N_btn_chart && N_btn_chart.classList.remove('d-none');

					N_addPredictive && N_addPredictive.classList.add('d-none');
					N_addAlimentation && N_addAlimentation.classList.remove('d-none');

					N_datePredictive && N_datePredictive.classList.add('d-none');
					N_dateDaily && N_dateDaily.classList.remove('d-none');

					N_btn_daily && N_btn_daily.classList.add('d-none');
					N_btn_predictive && N_btn_predictive.classList.remove('d-none');

				});

			}

		}

	}

	private chart(data: any[]) {

		let N_listProduct = this.el.querySelector('#product-chart-list') as HTMLSelectElement;

		if (N_listProduct) {
			N_listProduct.innerHTML = '';

			let N_chart = this.el.querySelector('#chart') as HTMLElement;

			let series: any = [];
			let categories: any = [];

			for (let row of data) {

				let objReal = {
					name: row.product,
					color: `hsl(${(series.length / 2) * (360 / data.length)},75%,70%)`,
					id: row.product + '_real',
					data: [] as (number | null)[]
				};

				let objPredictive = {
					name: row.product,
					color: `hsl(${(series.length / 2) * (360 / data.length)},75%,30%)`,
					id: row.product + '_predictive',
					data: [] as (number | null)[]
				};

				let now = moment();

				for (let month in row) {

					if (month != 'product' && month != 'sum' && month != 'stock') {

						let date = moment(month, 'YYYY_MM');

						if (date.isBefore(now, 'month')) {

							objReal.data.push(row[month].real);
							objPredictive.data.push(null);

						} else if (date.isSame(now, 'month')) {

							objReal.data.push(row[month].real);
							objPredictive.data.push(row[month].predictive.quantity);

						} else {

							objReal.data.push(null);
							objPredictive.data.push(row[month].predictive.quantity);

						}

						let category = date.format('MMM YYYY');

						if (categories.indexOf(category) == -1) {

							categories.push(category);

						}

					}

				}

				series.push(objReal);
				series.push(objPredictive);

				let productData = _.find(this.products, { _id: row.product });

				if (productData) {

					N_listProduct.innerHTML += `
                        <optgroup label="${productData.reference}">
                            <option value="${objReal.id}" selected>Reel</option>
                            <option value="${objPredictive.id}" selected>Prévisionnelle</option>
                        </optgroup>
                    `;

				}

			}

			for (let serie of series) {

				let productData = _.find(this.products, { _id: serie.name });

				let chunkId = serie.id.split('_');
				let type = _.last(chunkId);

				if (productData) {

					serie.name = productData.reference + (type == 'real' ? ' (Réel)' : ' (Previsionnelle)');

					for (let i in serie.data) {

						if (serie.data[i]) {

							serie.data[i] = _.round(serie.data[i] / unitList[productData.unit].coeficient, 2);

						}

					}

				}

			}

			let charts = Highcharts.chart(N_chart, {
				chart: {
					type: 'line'
				},
				title: {
					text: 'Alimentations'
				},
				xAxis: {
					categories,
					crosshair: true
				},
				yAxis: {
					title: {
						text: 'Quantité (T)'
					}
				},
				legend: {
					layout: 'vertical',
					align: 'right',
					verticalAlign: 'middle'
				},
				tooltip: {
					headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
					pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
						`<td style="padding:0"><b>{point.y:.1f} ${unitList['tonne'].unit}</b></td></tr>`,
					footerFormat: '</table>',
					shared: true,
					useHTML: true
				},
				plotOptions: {
					series: {
						events: {
							legendItemClick: function (e) {
								e.preventDefault();
							}
						}
					}
				},
				series
			});

			if (N_listProduct) {

				N_listProduct.querySelectorAll('option').forEach((N_option) => {

					N_option.addEventListener('mousedown', (e) => {

						N_option.selected = !N_option.selected;

						for (let serie of charts.series) {

							if (serie.options.id == N_option.value) {

								if (N_option.selected) {
									serie.show();
								} else {
									serie.hide();
								}

								break;

							}

						}

						e.preventDefault();

					});

				});

			}
		}
	}

	private async getDataToPredictive(dateStart: number | string): Promise<{ columnDefs: Array<any>, rowData: Array<any> }> {

		let startDate = moment(dateStart, 'x')
		let endDate = startDate.clone().add(15, 'month') // Ajout de période de 15 mois par raport à la date de départ


		let promiseAllData = await Promise.all([
			AlimentationPredictiveService.getInstance().getBySite(this.currentSite, startDate.format("YYYY_MM"), endDate.format("YYYY_MM")),
			StocksService.getInstance().getAlimentationByMonth(this.currentSite, startDate.format('YYYY_MM'), endDate.format('YYYY_MM')),
			StocksService.getInstance().getQuantitiesByProduct(this.currentSite, "0000_00", moment().format('YYYY_MM'))
		])

		let [predictiveValue, realValue, currentStock] = promiseAllData

		let rowData: any[] = [];
		let columnDefs: any[] = [];

		let date = startDate.clone()

		let lineByProduct: { [idProduct: string]: any } = {}

		while (date.isSameOrBefore(endDate)) {

			let key = date.format('YYYY_MM')

			let findByProduct: { [key: string]: boolean } = {}

			for (let item of realValue) {

				let month = item.month;
				let product = item.product;

				lineByProduct[product] = lineByProduct[product] || {}

				findByProduct[product] = findByProduct[product] || false;

				lineByProduct[product][month] = lineByProduct[product][month] || { real: 0, predictive: { quantity: 0 } }

				if (month == key) {

					findByProduct[product] = true

					lineByProduct[product][month].real = item.quantity

				}

			}

			for (let item of predictiveValue) {

				let month = item.month;
				let product = item.product;

				lineByProduct[product] = lineByProduct[product] || {};

				findByProduct[product] = findByProduct[product] || false;

				lineByProduct[product][month] = lineByProduct[product][month] || { real: 0, predictive: { quantity: 0 } }

				if (month == key) {

					findByProduct[product] = true;

					lineByProduct[product][month].predictive = item;

				}

			}

			for (let product in findByProduct) {

				if (!findByProduct[product]) {

					lineByProduct[product][key] = { real: 0, predictive: { quantity: 0 } };

				}

			}

			columnDefs.push({
				headerName: date.format('MMM YYYY'),
				children: [{
					headerName: 'Prévision',
					field: key + '.predictive.quantity',
					cellClass: 'text-right',
					editable: true
				}, {
					headerName: 'Réel',
					cellClass: 'text-right',
					field: key + '.real'
				}]
			});

			date = date.add(1, 'month');

		}

		for (let product in lineByProduct) {

			let sum: { [key: string]: any } = {
				real: 0,
				predictive: {
					quantity: 0
				}
			};

			for (let key in lineByProduct[product]) {
				sum.real += lineByProduct[product][key].real;
				sum.predictive.quantity += lineByProduct[product][key].predictive.quantity;
			}

			let stock = 0;

			for (let item of currentStock) {

				if (item.key[1] == product) {
					stock += item.quantity;
				}

			}

			rowData.push(
				_.merge(lineByProduct[product], {
					sum,
					product,
					stock: (stock ? stock : 0)
				})
			)
		}

		columnDefs = [{
			headerName: 'Matiere',
			field: 'product',
			pinned: 'left',
			cellRenderer: (params: any) => {
				let product = _.find(this.products, { _id: params.value });

				if (product) {
					return product.reference;
				}

				return params.value;
			}
		}, {
			headerName: 'Unité',
			pinned: 'left',
			cellRenderer: (params: any) => {

				let product = _.find(this.products, { _id: params.data.product });

				if (product) {

					let unit = unitList[product.unit];

					return unit.name;

				}

				return '';
			}
		}, {
			headerName: 'Total Alimention',
			children: [{
				headerName: 'Prévision',
				pinned: 'left',
				cellClass: 'text-right',
				field: 'sum.predictive.quantity'
			}, {
				headerName: 'Réel',
				pinned: 'left',
				cellClass: 'text-right',
				field: 'sum.real'
			}]
		}, {
			headerName: 'Stock',
			children: [{
				headerName: 'Prévisionnelle',
				pinned: 'left',
				cellClass: 'text-right',
				field: 'stock',
				cellRenderer: (params: any) => {
					let now = moment();

					let product = _.find(this.products, { _id: params.data.product });

					let predictive = 0;

					if (params.data[now.format('YYYY_MM')]) {

						for (let month in params.data) {

							let date = moment(month, 'YYYY_MM');

							if (date.isSameOrAfter(now, 'month')) {

								predictive += params.data[month].predictive.quantity

							}

						}

						if (product) {
							let unit = unitList[product.unit];
							return _.round((params.value - predictive) / unit.coeficient, 2);
						}

						return params.value - predictive;

					} else {
						return '---';
					}

				}
			}, {
				headerName: 'Fin du mois',
				pinned: 'left',
				cellClass: 'text-right',
				field: 'stock',
				cellRenderer: (params: any) => {
					let now = moment();

					if (params.data[now.format('YYYY_MM')]) {

						let predictive = params.data[now.format('YYYY_MM')].predictive.quantity;

						let product = _.find(this.products, { _id: params.data.product });

						if (product) {
							let unit = unitList[product.unit];
							return _.round((params.value - predictive) / unit.coeficient, 2);
						}

						return params.value - predictive;

					} else {
						return '---';
					}
				}
			}]
		}].concat(columnDefs)

		return { rowData, columnDefs }
	}

	private datePickerPredictive() {


		const datepickerDaily = $(this.el).find('#date-picker-daily')
		const datePickerPredictive = $(this.el).find('#date-picker-predictive')

		datePickerPredictive.daterangepicker(drpC({
			showDropdowns: true,
			singleDatePicker: true,
			startDate: this.startDate
		}), (start, _end, _label) => {

			this.startDate = start.startOf('month');
			this.endDate = start.clone().endOf('month');

			datepickerDaily.data('daterangepicker')!.setStartDate(this.startDate.format('DD/MM/YYYY'))
			datepickerDaily.data('daterangepicker')!.setEndDate(this.endDate.format('DD/MM/YYYY'))

			this.getDataToPredictive(this.startDate.format('x')).then(({ rowData, columnDefs }) => {
				this.gridOptionsPredictive?.api?.setRowData(rowData)
				this.gridOptionsPredictive?.api?.setColumnDefs(columnDefs)
				this.gridOptionsPredictive?.api && this.chart(rowData)
			})

			this.getDataToDaily(this.startDate.format('x'), this.endDate.format('x')).then((data) => this.gridOptionsDaily?.api?.setRowData(data))

		})

	}

	private gridDaily(rowData: any[]) {

		this.sumByDay = {}

		for (let item of rowData) {

			let day = moment(item.date).format('DD_MM_YYYY');

			this.sumByDay[day] = this.sumByDay[day] || 0;

			this.sumByDay[day] += item.quantity;

		}

		if (this.gridOptionsDaily && this.gridOptionsDaily.api) {

			this.gridOptionsDaily.api.setRowData(rowData)

		}
		else {
			const N_Alimentation = this.el.querySelector("#stock-output-grid") as HTMLElement

			this.gridOptionsDaily = agGridFrench({

				rowData,
				suppressAggFuncInHeader: true,
				suppressDragLeaveHidesColumns: true,
				sideBar: {
					toolPanels: [{
						id: 'columns',
						labelDefault: 'Columns',
						labelKey: 'columns',
						iconKey: 'columns',
						toolPanel: 'agColumnsToolPanel',
						toolPanelParams: {
							suppressValues: true,
							suppressPivots: true,
							suppressPivotMode: true,
							suppressSideButtons: true,
							suppressColumnFilter: true,
							suppressColumnSelectAll: true,
							suppressColumnExpandAll: true
						}
					}],
				},
				defaultColDef: {
					floatingFilter: true,
					resizable: true,
					enableRowGroup: true
				},
				autoGroupColumnDef: {
					headerName: "Groupe",
					cellRenderer: 'agGroupCellRenderer',
				},
				pinnedBottomRowData: [{}],
				columnDefs: [
					{
						headerName: "Jour",
						field: "date",
						rowGroup: true,
						hide: true,
						rowGroupIndex: 0,
						valueGetter: (params) => {
							return params.data && !params.node?.rowPinned ? moment(params.data.date, 'x').format('DD/MM/YYYY') : ''
						}
					},
					{
						headerName: "Mois",
						field: "date",
						valueGetter: (params) => {
							return params.data && !params.node?.rowPinned ? moment(params.data.date, 'x').format('MM/YYYY') : ''
						},
						filter: 'agTextColumnFilter'
					},
					{
						headerName: "Année",
						field: "date",
						filter: 'agNumberColumnFilter',
						valueGetter: (params) => {
							return params.data && !params.node?.rowPinned ? moment(params.data.date, 'x').format('YYYY') : ''
						}
					},
					{
						headerName: "Produits",
						field: "product",
						rowGroup: true,
						hide: true,
						rowGroupIndex: 1,
						valueGetter: (params) => {

							if (params.data && !params.node?.rowPinned) {
								const tmp = _.find(this.products, { _id: params.data.product })
								return tmp ? tmp.reference : params.data.product;
							}

							return ''
						},
						filter: 'agTextColumnFilter',
						filterParams: {
							filterOptions: ['contains'],
							textCustomComparator: TextFilterAccent
						},
					}, {
						headerName: "Emplacement",
						field: "storage",
						filter: 'agTextColumnFilter',
						filterParams: {
							filterOptions: ['contains'],
							textCustomComparator: TextFilterAccent
						},
						valueGetter: (params) => {
							if (params.data && params.data.storage) {
								if (this.site) {
									const tmp = _.find(this.site.storages || [], { id: params.data.storage });
									if (tmp) {
										return tmp.name
									}
								}
							}
						},
						cellRenderer: (params) => params.value || ''
					},
					{
						headerName: "Quantité",
						field: "quantity",
						sortable: true,
						cellClass: 'text-right',
						valueGetter: (params: any) => {
							if (!params.node.rowPinned) {
								
								if (params.data) {
									
									let tmp = _.find(this.products, { _id: params.data.product });
									if (tmp) {
										return _.round(params.data.quantity / unitList[tmp.unit].coeficient, 2)
									}

									return params.data.quantity;
								
								} else {

									const children = this.getNodeChildrenData(params)
									let quantities = _.sumBy(children, 'quantity')									
									
									return _.round(quantities / unitList['tonne'].coeficient, 2);
								}
							}

							return params.data.quantity
						},						
						cellRenderer: (params) => {							
							if (params.node.rowPinned) {

								if (params.type == 'csv' || params.type == 'excel') {
									return parseFloat(params.value)
								}
								if (params.value) {
									return params.value + " / " + params.data.daysCount + " jours"
								}							
							}

							return params.value												
						}
					}, {
						headerName: 'Unité',
						aggFunc: (params) => params,
						cellRenderer: (params) => {

							if (!params.node.rowPinned) {

								if (params.data) {
									const tmp = _.find(this.products, { _id: params.data.product }) as { unit: string } | undefined
									return tmp ? unitList[tmp.unit].unit : '';
								}
								else {
									return unitList['tonne'].unit;
								}

							}
							else if (params.type == 'csv' || params.type == 'excel') {
								const quantity = params.data.quantity;
								return quantity.replace(parseFloat(quantity), '')
							}

							return ''
						}
					},
					{
						headerName: "Pourcentage",
						field: "percentage",
						filter: false,
						cellRenderer: (params) => {
							if (params.data && !params.node.rowPinned) {
								const parentQuantity = _.map(params.node.parent.childrenAfterGroup, 'data.quantity')
								const sum = _.sum(parentQuantity)
								return _.round(params.data.quantity * 100 / sum, 2) + '%'
							}

							return ''
						}
					},
					{
						headerName: '#',
						width: 80,
						filter: false,
						suppressSizeToFit: true,
						cellRenderer: (params) => {

							const div = document.createElement('div')

							let value: Moment | undefined = undefined;

							if (parseInt(params.node.key)) {
								value = moment(params.node.key, 'DD/MM/YYYY')
							}

							if (params.data && params.data.date) {
								value = moment(params.data.date, 'x');
							}

							if (value?.isValid() && !params.node.rowPinned && !params.data) {

								// Bouton modifier
								const N_btn = document.createElement('button')

								N_btn.classList.add('btn', 'btn-xs', 'btn-info');

								N_btn.innerHTML = '<i class="icon icon-solid-pen"></i>';

								N_btn.addEventListener('click', () => {
									const modal = new AddEditAlimentationModal(this.currentSite, value!.format('x'));
									modal.open().then(() => this.refreshAllGrid())
								})

								div.appendChild(N_btn)
							}

							if (params.data) {
								// bouton supprimer
								const N_delete_Btn = h('button.btn.btn-xs.btn-danger', { attrs: { confirmation: '' } }, h('i.icon.icon-solid-trash'))
								N_delete_Btn.addEventListener2('click', async () => {
									await this.deleteGridDailyRow(params.data._id)
									toaster.success('Ligne Supprimé')
									this.refreshAllGrid()
								})
								div.appendChild(N_delete_Btn)
							}

							return div
						}
					}
				],
				getRowStyle: (params: any) => {
					if (params.node.rowPinned) {
						return { 'font-weight': 'bold' }
					}
				},
				getContextMenuItems: (params) => {

					if (params.columnApi) {

						const allColumns = params.columnApi.getAllColumns()
						const columnKeys = _.map(allColumns, 'colId')

						columnKeys.pop()

						const exportAllExcel = {
							name: 'Exporter tous Excel',
							action: () => {
								let exp = new Export(params);
								exp.exportExcel({
									columnKeys
								});
							}
						};

						const exportAllCSV = {
							name: 'Exporter tous CSV',
							action: () => {
								let exp = new Export(params);
								exp.exportCSV({
									columnKeys
								});
							}
						};


						const changeDate = {
							name: 'Changer la date',
							action: async () => {

								const data = params.node.group ? _.map(params.node.allLeafChildren, 'data') : [params.node.data]

								const alimentationService = AlimentationsService.getInstance();

								try {

									const date = moment(await Alert.prompt('Nouvelle date', 'Sélectionnez la nouvelle date', { type: 'date' }) as Date);

									await alimentationService.changeDate(_.map(data, '_id'), parseInt(date.format('x')))

									toaster.success('Date modifiée');

									this.getDataToDaily(this.startDate.format('x'), this.endDate.format('x')).then((data) => this.gridDaily(data))

								}
								catch (e) {
									toaster.error('Erreur lors du changement de la date');
								}
							}
						};

						if (this.loggedUser.hasPermission('EXPLOITATION.ALIMENTATION.CHANGE_DATE')) {
							return [exportAllExcel, exportAllCSV, changeDate]
						}

						return [exportAllExcel, exportAllCSV]
					}

					return []
				},
				onGridReady: (params) => {
					params.api.sizeColumnsToFit()
					this.updatePinnedRow(params)
				},
				onRowDataChanged: (params) => this.updatePinnedRow(params),
				onFilterChanged: (params) => this.updatePinnedRow(params)
			});

			if (N_Alimentation) {
				new Grid(N_Alimentation as HTMLElement, this.gridOptionsDaily)
				this.stateAGridDaily = new AgGridStateSaver(this.gridOptionsDaily, global.SITE + "_daily", `Tableau Alimentation Journalière ${global.SITE}`)
				this.stateAGridDaily.load()
			}

		}

	}

	private daily() {

		const N_addAlimentation = this.el.querySelector('#add-alimentation') as HTMLElement

		N_addAlimentation.addEventListener('click', () => {

			const modal = new AddEditAlimentationModal(this.currentSite);

			modal.open().then(() => {
				this.getDataToDaily(this.startDate.format('x'), this.endDate.format('x')).then((data) => this.gridDaily(data))
				this.getDataToPredictive(this.startDate.format('x')).then(({ rowData, columnDefs }) => {
					this.gridPredictive(rowData, columnDefs)
					this.chart(rowData)
				})
			})

		})

		const N_containerPredictive = this.el.querySelector('#predictive-container') as HTMLElement
		const N_containerDaily = this.el.querySelector('#daily-container') as HTMLElement
		const N_containerChart = this.el.querySelector('#chart-container') as HTMLElement

		const N_btn_predictive = this.el.querySelector('#predictive') as HTMLButtonElement

		const N_btn_chart = this.el.querySelector('#button-chart') as HTMLElement
		const N_btn_grid = this.el.querySelector('#button-grid') as HTMLElement

		const N_addPredictive = this.el.querySelector('#add-predictive') as HTMLElement

		const N_dateDaily = this.el.querySelector('#date-picker-daily') as HTMLElement
		const N_datePredictive = this.el.querySelector('#date-picker-predictive') as HTMLElement

		const N_btn_daily = this.el.querySelector('#daily') as HTMLElement

		N_btn_predictive.addEventListener('click', () => {

			N_containerDaily.classList.add('d-none')
			N_containerPredictive.classList.remove('d-none')
			N_containerChart.classList.add('d-none')

			N_btn_grid.classList.add('d-none')
			N_btn_chart.classList.remove('d-none')

			N_addPredictive.classList.remove('d-none')
			N_addAlimentation.classList.add('d-none')

			N_datePredictive.classList.remove('d-none')
			N_dateDaily.classList.add('d-none')

			N_btn_daily.classList.remove('d-none')
			N_btn_predictive.classList.add('d-none')

		})

	}

	private updatePinnedRow(params: any) {

		if (params.api) {

			let quantity = 0;

			const dates = new Set()

			params.api.forEachNodeAfterFilter((node: any) => {
				if (!node.childrenAfterFilter) {
					quantity += node.data.quantity
					dates.add(moment(node.data.date, 'x').startOf('day').format("YYYY_MM_DD"))
				}
			})

			const data = {
				daysCount: dates.size,
				quantity: _.round(quantity / unitList['tonne'].coeficient, 2) + ' ' + unitList['tonne'].unit
			}

			params.api.setPinnedBottomRowData([data])
		}

	}

	private async getDataToDaily(dateStart: number | string, dateEnd: number | string): Promise<object[]> {
		return StocksService.getInstance().getAlimentationBySite(this.currentSite, +dateStart, +dateEnd)
	}

	private datePickerDaily() {

		const datepickerDaily = $(this.el).find('#date-picker-daily')
		const datePickerPredictive = $(this.el).find('#date-picker-predictive')

		datepickerDaily.daterangepicker(drpC({
			showDropdowns: true,
			startDate: this.startDate,
			endDate: this.endDate,

		}), (start, end, _label) => {

			this.startDate = start.startOf('day')
			this.endDate = end.endOf('day')

			const startDatePredictive = this.startDate.clone().startOf('month')

			datePickerPredictive.data('daterangepicker')!.setStartDate(startDatePredictive.format('DD/MM/YYYY'))
			datePickerPredictive.data('daterangepicker')!.setEndDate(startDatePredictive.format('DD/MM/YYYY'))

			this.getDataToDaily(this.startDate.format('x'), this.endDate.format('x')).then(data => this.gridOptionsDaily.api?.setRowData(data))

			this.getDataToPredictive(this.startDate.format('x')).then(({ rowData, columnDefs }) => {

				this.gridOptionsPredictive.api?.setRowData(rowData)
				this.gridOptionsPredictive.api?.setColumnDefs(columnDefs)
				this.gridOptionsPredictive.api && this.chart(rowData)

			})

		})

	}

	private async getAllProduct(): Promise<void> {
		this.products = await ProductsService.getInstance().getAllProducts() as Array<CouchDBData>
	}

	/**
	 * Permet de récupérer les datas des enfants d'une ligne de groupement
	 * @param params 
	 */
	private getNodeChildrenData(params: any) {
		return params.node.allLeafChildren.map((node: any) => node.data)
	}

	private async getStorageBySite(): Promise<CouchDBData> {
		return await SitesService.getInstance().getByID(this.currentSite) as CouchDBData
	}

	private async deleteGridDailyRow(id: string) {
		await StocksService.getInstance().delete(id)
	}

	private async refreshAllGrid() {

		const startDate = this.startDate.format('x')
		const endDate = this.endDate.format('x')
		let [dataDaily, dataPredictive] = await Promise.all([this.getDataToDaily(startDate, endDate), this.getDataToPredictive(startDate)])

		this.gridOptionsDaily && this.gridOptionsDaily.api && this.gridOptionsDaily.api.setRowData(dataDaily)
		this.gridOptionsPredictive && this.gridOptionsPredictive.api && this.gridOptionsPredictive.api.setRowData(dataPredictive.rowData)
		this.gridOptionsPredictive && this.gridOptionsPredictive.api && this.chart(dataPredictive.rowData)
	}

	public destructor() {

		this.stateAGrid && this.stateAGrid.save();
		this.stateAGridDaily && this.stateAGridDaily.save();


		const N_daterangepicker = document.querySelectorAll('.daterangepicker')

		N_daterangepicker.forEach((item) => {
			const parent = item.parentNode as HTMLElement;
			parent.removeChild(item)
		})

	}

}

export default Alimentation

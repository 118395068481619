module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\r\n<table class="table table-sm" id="tableProduct">\r\n\r\n    <thead>\r\n    \r\n        <tr>\r\n            <th scope="col">Reference</th>\r\n            <th scope="col">Quantité</th>\r\n            <th scope="col">Emplacement</th>\r\n\r\n            ';
 
            let keys = {};
            for(let product of products){
                for(let key in rawMaterialProperties){
                    if(product.qualityCriteria && product.qualityCriteria[key]){
                        keys[key] = true;
                    }
                } 
            };
__p += '\r\n\r\n            ';
 for(let key in keys){ ;
__p += '\r\n                <th scope="col">' +
((__t = ( rawMaterialProperties[key].name)) == null ? '' : __t) +
'</th>\r\n            ';
 } ;
__p += '\r\n\r\n            \r\n        \r\n        </tr>\r\n      \r\n    </thead>\r\n\r\n    <tbody>\r\n\r\n        ';
 for(let product of products){;
__p += '\r\n        <tr>\r\n            <td>' +
((__t = (product.reference)) == null ? '' : __t) +
'</td>\r\n            <td>' +
((__t = (product.deliveredQuantity / unitList[product.unit].coeficient)) == null ? '' : __t) +
' ' +
((__t = (unitList[product.unit].unit)) == null ? '' : __t) +
'</td>\r\n            <td>\r\n                ' +
((__t = (product.storage && product.storage != '' ? storagesList[product.storage].name : '' )) == null ? '' : __t) +
'\r\n            </td>\r\n            ';
 
            for(let key in  rawMaterialProperties){
                
                if(product.qualityCriteria && product.qualityCriteria[key]){

                    ;
__p += '\r\n                    <td>' +
((__t = (product.qualityCriteria[key])) == null ? '' : __t) +
' ' +
((__t = ( unitList[rawMaterialProperties[key].unit].unit)) == null ? '' : __t) +
'</td>\r\n                    ';

                }

            } ;
__p += '\r\n        </tr>\r\n        ';
 } ;
__p += '\r\n\r\n    </tbody>\r\n\r\n</table>';

}
return __p
}
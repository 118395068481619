import { CheckListModel } from '@js/types/checklist'
import axios from 'axios'

class ChecklistModelsService {
	private static readonly instance = new ChecklistModelsService()
	private constructor() {}
	public static getInstance() : ChecklistModelsService {
		return ChecklistModelsService.instance
	}

	public async getByID(id: string) : Promise<CheckListModel> {
		return axios.get(`/api/checklists-models/${id}`).then(response => response.data.data)
	}

	public async getBySite(siteId: string) : Promise<Array<CheckListModel>> {
		return axios.get(`/api/checklists-models/by-site/${siteId}`).then(response => response.data.data)
	}

	public async delete(id: string) : Promise<void> {
		await axios.get(`/api/checklists-models/tag-delete/${id}`)
	}


	public async create(checklistModel: CheckListModel) : Promise<void> {
		await axios.post('/api/checklists-models', checklistModel)
	}

	public async update(checklistModel: CheckListModel) : Promise<void> {
		await axios.put('/api/checklists-models', checklistModel)
	}

}


export default ChecklistModelsService
import { global } from "@autoprog/core-client"
import EventEmitter from "@autoprog/eventemitter"

import moment from 'moment'

import T_ScheduleDetailPopup from '@tpl/blocks/calendar/scheduleDetailPopup.ejs'
import T_ScheduleDetailPopupMobile from '@tpl/blocks/calendar/scheduleDetailPopupMobile.ejs'
import SitesService from './services/SitesService'
import UsersService from './services/UsersService'

import _ from 'lodash'
import { ISchedule } from "tui-calendar"

class ScheduleDetailPopup extends EventEmitter {

	private el: HTMLElement | null = null;
	private guide: any;
	private saveData: ISchedule = {};

	constructor() {
		super({});
	}

	public async open(data: ISchedule, calendarList: any[], options: { [key: string]: any }) {

		let N_appContent = document.querySelector('#app-content') as HTMLElement;

		this.el = document.querySelector('#content>.tui-full-calendar-floating-layer') as HTMLElement;

		data.raw = data.raw || {}

		this.guide = options.guide;
		this.saveData = data;

		if (!this.el) {
			this.el = document.createElement('div');
			this.el.classList.add('tui-full-calendar-floating-layer');

			this.el.style.position = 'absolute';

			this.el = N_appContent.appendChild(this.el);
		}

		const calendar = calendarList.find(c => c._id === data.calendarId) // FIXME: should put something else than any

		const sites = await SitesService.getInstance().getAllSites()
		const users = await UsersService.getInstance().getAll()

		if (this.el) {

			this.destroy();

			N_appContent.style.overflow = 'hidden';

			this.el.style.display = 'block';
			this.el.classList.add('d-block');
			this.el.style.zIndex = '1005';

			let N_template = document.createElement('div');
			N_template.classList.add('tui-full-calendar-popup', 'tui-full-calendar-popup-detail');

			if (global.IS_MOBILE) {

				let N_listMore = document.querySelector('#fullcalendar .tui-full-calendar-floating-layer .tui-full-calendar-month-more') as HTMLDivElement;

				if (N_listMore) {
					(N_listMore.parentNode as HTMLElement).removeChild(N_listMore);
				}

				N_template.innerHTML = T_ScheduleDetailPopupMobile({
					users,
					sites,
					data: {
						date: this.formatDate(data),
						title: data.title,
						bgColor: data.borderColor,
						locked: options.locked,
						suppressEdit: options.suppressEdit,
						suppressRemove: options.suppressRemove,
						users: data.raw.users || [],
						description: data.raw.description,
						calendar
					}
				});
			} else {

				N_template.innerHTML = T_ScheduleDetailPopup({
					users,
					sites,
					data: {
						date: this.formatDate(data),
						title: data.title,
						bgColor: data.borderColor,
						locked: options.locked,
						suppressEdit: options.suppressEdit,
						suppressRemove: options.suppressRemove,
						users: data.raw.users || [],
						description: data.raw.description,
						calendar
					}
				});
			}

			this.el.appendChild(N_template);

			this.edit();
			this.delete();

			this.specificMobile();

			if (!global.IS_MOBILE) {

				this.setPopupPositionAndArrowDirection();

				let body = document.body;

				if (body) {

					let N_dropback = document.createElement('div');

					N_dropback.id = 'dropback';

					N_dropback.classList.add('bg-dark', 'h-100', 'w-100');
					N_dropback.style.position = 'absolute';
					N_dropback.style.top = '0';
					N_dropback.style.left = '0';
					N_dropback.style.opacity = '0.3';

					N_dropback.addEventListener('click', () => {
						this.destroy();
					});

					body.appendChild(N_dropback);

				}

			}

		}

	}

	private formatDate(data: any) {

		if (data.isAllDay) {

			return moment(data.start.getTime() || 'x').format('DD MMM YYYY');

		} else {

			return moment(data.start.getTime() || 'x').format('DD MMM YYYY ● HH:mm') + ' - ' + moment(data.end.getTime() || 'x').format('HH:mm');;

		}

	}

	private setPopupPositionAndArrowDirection() {

		if (this.el) {

			let N_popup = this.el.querySelector('.tui-full-calendar-popup');
			let N_container = document.querySelector('.tui-full-calendar-layout');
			let N_select = this.guide.target;

			if (N_popup && N_select && N_container) {

				let selectBound = N_select.getBoundingClientRect();
				let containerBound = N_container.getBoundingClientRect();
				let popupBound = N_popup.getBoundingClientRect();

				let left = selectBound.right;

				let N_arrow = this.el.querySelector('#tui-full-calendar-popup-arrow');

				if (left + this.el.scrollWidth > containerBound.width) {

					this.el.style.left = (selectBound.left - this.el.scrollWidth - 8) + 'px';

					if (N_arrow) {
						N_arrow.classList.remove('tui-full-calendar-arrow-left');
						N_arrow.classList.add('tui-full-calendar-arrow-right');
					}

				} else {

					this.el.style.left = (left + 8) + 'px';

				}

				let topSelect = selectBound.top + selectBound.height / 2;

				let centerEl = popupBound.height / 2;

				let top = (topSelect - centerEl);

				this.el.style.top = top + 'px';

				if (top + this.el.scrollHeight > window.innerHeight) {

					top = window.innerHeight - this.el.scrollHeight;

					this.el.style.top = top + 'px';

					if (N_arrow) {

						let N_arrowBorder = N_arrow.querySelector('.tui-full-calendar-popup-arrow-border') as HTMLDivElement;

						let topArrow = selectBound.top - top + (selectBound.height / 2) - 10;

						N_arrowBorder.style.top = topArrow + 'px';
					}

				}

			}

		}

	}

	private edit() {

		if (this.el) {

			let N_button = this.el.querySelector('.tui-full-calendar-popup-edit');

			if (N_button) {

				N_button.addEventListener('click', () => {

					this.destroy();

					this.emit('edit',
						this.saveData,
						{
							target: this.guide.target
						}
					);

				});

			}

		}

	}

	private delete() {

		if (this.el) {

			let N_button = this.el.querySelector('.tui-full-calendar-popup-delete');

			if (N_button) {

				N_button.addEventListener('click', () => {

					this.destroy();

					this.emit('delete', this.saveData);

				});

			}

		}

	}

	private destroy() {

		let oldTemplate = document.querySelector('.tui-full-calendar-popup');
		let dropback = document.querySelector('#dropback');

		if (oldTemplate && oldTemplate.parentNode) {
			oldTemplate.parentNode.removeChild(oldTemplate);
		}

		let N_appContent = document.querySelector('#app-content') as HTMLElement;
		N_appContent.style.overflow = '';

		if (this.el) {
			this.el.style.bottom = '';
			this.el.style.left = '';
			this.el.style.top = '';
			this.el.style.right = '';
			this.el.classList.remove('d-block');
		}

		if (dropback && dropback.parentNode) {
			dropback.parentNode.removeChild(dropback);
		}

	}

	private specificMobile() {

		if (global.IS_MOBILE && this.el) {

			let N_btnReturn = this.el.querySelector('#return-list-detail');

			if (N_btnReturn) {

				N_btnReturn.addEventListener('click', () => {

					this.destroy();

				});

			}

		}

	}


}

export default ScheduleDetailPopup;
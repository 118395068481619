import moment from 'moment'

type PaymentPeriodType = '30days' | 'monthEnd' | '30daysMonthEnd' | 'cash' | null

class PaymentUtils {
	private constructor() {}

	public static getPaymentDate(type: PaymentPeriodType, startDate?: number) : number {
		let date = startDate ? moment(startDate) : moment()

		if (type === '30days') {
			date = date.add(30, "day")
		}
		else if (type === 'monthEnd') {
			date = date.endOf('month')
		}
		else if (type === '30daysMonthEnd') {
			date = date.add(30, 'day').endOf('month')
		}
		else {
			date = moment('9999-12-31')
		}

		return +date.format('x')
	}
}

export default PaymentUtils

import axios from 'axios'
import _ from 'lodash'

export type Analytic = {
	_id: string,
	_rev?: string,
	name: string,
}

class AnalyticsService {

	private static instance = new AnalyticsService();

	private analytics: Analytic[];

	private constructor() {
		this.analytics = [];
	}

	public static getInstance() : AnalyticsService {
		return AnalyticsService.instance;
	}

	public async load() : Promise<void> {
		try {
			let { data } = await axios.get('/api/analytics__')


			if (data && !data.error) {
				this.analytics = data.data;
			} else {
				this.analytics = [];
				throw data.error;
			}
		} catch (e) {
			console.log(e);
		}
	}

	public getAll() : Array<Analytic> {
		return _.cloneDeep(this.analytics);
	}

	public getById(id: string): Analytic | null {
		return _.find(this.analytics, { _id: id }) || null;
	}

	public getByIds(ids: Array<string>) {
		return this.analytics.filter( analytic => ids.indexOf(analytic._id) >= 0 )
	}

	public async update(id: string, analytics: Analytic) : Promise<void> {

		const { data } = await axios.put('/api/analytics__/', {
			...analytics,
			_id: id
		})

		if (data && !data.error) {
			this.analytics = data.data;
		} else {
			this.analytics = [];
			throw data.error;
		}

	}

	public async add(analytics: Analytic) : Promise<void> {

		let { data } = await axios.post('/api/analytics__/', analytics)

		if (data && !data.error) {
			this.analytics = data.data;
		} else {
			this.analytics = [];
			throw data.error;
		}

	}

	public async delete(ID: string) : Promise<void> {

		let { data } = await axios.delete(`/api/analytics__/${ID}`)

		if (data && !data.error) {
			this.analytics = data.data;
		} else {
			this.analytics = [];
			throw data.error;
		}
	}

}

export default AnalyticsService;
import Checklist from '@js/types/checklist'
import axios from 'axios'
import { global } from "@autoprog/core-client"

class ChecklistsService {
	private static readonly instance = new ChecklistsService()
	private constructor() {
	}

	public static getInstance() {
		return ChecklistsService.instance
	}

	public async getByID(id: string): Promise<Checklist> {
		return axios.get(`/api/checklists/${id}`).then(response => response.data.data)
	}

	//TODO: A migrer en API
	public async getPercentByIds(ids: Array<string>): Promise<Map<string, number>> {

		const map = new Map()
		// TODO: FIXME: enlever couchdb
		const res = await axios.post(`${global.COUCHDB_URL}/checklists/_find`, {
			selector: {
				_id: {
					$in: ids
				}
			},
			limit: ids.length,
			fields: ["_id", "percent"]
		});

		for (let doc of res.data.docs) {
			map.set(doc._id, doc.percent)
		}

		return map
	}

	public async create(checklist: Checklist): Promise<void> {
		await axios.post(`/api/checklists`, checklist)
	}

	public async update(checklist: Checklist): Promise<void> {
		await axios.put(`/api/checklists`, checklist)
	}

	public async delete(checklistID: string): Promise<void> {
		await axios.delete(`/api/checklists/${checklistID}`);
	}

	public async lock(checklistID: string){
		const checklist  = await this.getByID(checklistID)
		checklist.locked = true
		await this.update(checklist)
	}

	public async unlock(checklistID: string){
		const checklist  = await this.getByID(checklistID)
		checklist.locked = false
		await this.update(checklist)
	}

	/**
	 * Permet de récupérer les Checklist en fonction du modele et d'un site
	 * @param siteId 
	 * @param checkListModelId 
	 */
	public async getBySiteModel(siteId: string, checkListModelId : string) : Promise<Array<Checklist>> {
		return axios.get(`/api/checklists/by-site-model/${siteId}/${checkListModelId}`).then(response => response.data.data)
	}
}

export default ChecklistsService

import { Modal, Form, toaster } from "@autoprog/core-client"

import T_addEditAnalytic from '../../../../tpl/modals/settings/addEditAnalytic.html'

import AnalyticService, { Analytic } from '../../services/AnalyticsService'


class AddEditAnalytic extends Modal {


	public constructor(id?: string) {
		super({
			template: T_addEditAnalytic,
			backdrop: 'static',
			keyboard: false
		});

		this.on('opened', async () => {

			let N_form = this.element.querySelector('form');

			if (N_form) {

				let form = new Form(N_form)

				let N_save = this.element.querySelector('#save-button');

				if (N_save) {

					N_save.addEventListener('click', async () => {

						if (form.checkValidity()) {

							let service = AnalyticService.getInstance();

							let data = form.getData();

							//Gestion des chaines vide
							if (!data._rev) {
								delete data._rev
							}

							if (id) {

								try {
									await service.update(id, data as Analytic);
									toaster.success('Analytique mise à jour');
									this.resolve();
								} catch (e) {
									toaster.error(`Mise à jour de l'analytique`, 'Erreur')
									console.error(e);
								}
							} else {

								try {
									await service.add(data as Analytic);
									toaster.success('Analytique ajoutée')
									this.resolve();
								} catch (e) {
									toaster.error(`Ajout de l'analytique`, 'Erreur')
									console.error(e);
								}

							}

						}

					});

				}

				if (id) {

					try {
						let data = await AnalyticService.getInstance().getById(id);
						if (data) {
							form.setData(data);
						}
					} catch (e) {
						toaster.error(`Chargement de l'analytique`, 'Erreur');
						console.error(e);
					}
				}

			}

		})

	}



}

export default AddEditAnalytic;
import axios from 'axios'

export type SiteCategoriesHour = {
	[site: string]: HoursDataTypeCategories
}

export type HoursDataTypeCategories = {
	[category: string]: ApointedHourEntry
}

export type ApointedHourEntry = {
	hours: number
	standbyDuties: number
}

export type HoursDataTypeDuration = {
	start: number
	end: number
}

export type HoursDataType = {
	_id: string
	_rev?: string
	site: string
	date: number
	hours: HoursDataTypeDuration[]
	total: number
	standbyDuty: HoursDataTypeDuration[]
	totalStandbyDutys: number
	kilometers: number
	comment: string
	stats: SiteCategoriesHour
	deleted?: boolean
	locked?: boolean
	user: string
}

/**
 * Permet gérer les heures
 */
class HoursService {
	public static readonly instance = new HoursService()
	public static TABLE = "hours"

	private constructor() { }

	public async getByID(id: string): Promise<HoursDataType> {
		return axios.get(`/api/hours/${id}`).then(response => response.data.data)
	}

	public async update(data: HoursDataType): Promise<void> {
		await axios.put(`/api/hours`, data)
	}

	public async create(data: HoursDataType): Promise<void> {
		await axios.post(`/api/hours`, data)
	}

	public static getInstance(): HoursService {
		return HoursService.instance
	}

	public async delete(id: string): Promise<void> {
		await axios.delete(`/api/hours/${id}`)
	}

    /**
     * Permet de récupérer tous les heures d'un site + celui d'un utilisateur s'il est renseigné
     * @param siteId 
     * @param startingDate 
     * @param endingDate 
     * @param userId Pas obligatoire
     */
	public async getHours(siteId: string, startingDate: number, endingDate: number, userId: string | null = null): Promise<Array<HoursDataType>> {
		if (userId) {
			return this._getAllHoursFromUser(siteId, userId, startingDate, endingDate)
		}

		return this._getAllHours(siteId, startingDate, endingDate)
	}

	private async _getAllHours(siteId: string, startingDate: number, endingDate: number): Promise<Array<HoursDataType>> {
		return axios.get(`/api/hours/all-hours/${siteId}/${startingDate}/${endingDate}`).then(response => response.data.data)
	}

    /**
     * Permet de récupérer tous les heures d'un utilisateur en fonction de deux dates données et du site
     * @param siteId 
     * @param userId 
     * @param startingDate 
     * @param endingDate 
     */
	private async _getAllHoursFromUser(siteId: string, userId: string, startingDate: number, endingDate: number): Promise<Array<HoursDataType>> {
		return axios.get(`/api/hours/all-hours-from-user/${siteId}/${userId}/${startingDate}/${endingDate}`).then(response => response.data.data)
	}

    /**
     * Permet de savoir si une heure pour un utlisateur dans la fiche d'heure existe dans la base de données
     * @param userId 
     * @param date Format YYYY_MM_DD
     */
	public async exists(userId: string, date: string): Promise<boolean> {
		return axios.get(`/api/hours/exists/${userId}/${date}`).then(response => response.data.data)
	}

	public async changeDate(userId: string, oldDate: string, newDate: string): Promise<void> {
		try {
			let res = await axios.get(`/api/hours/change-date/${userId}/${oldDate}/${newDate}`);
			if (res.data && res.data.error) {
				throw new Error(res.data.error);
			}
		} catch (e) {
			if (e.response.data && e.response.data.error) {
				throw new Error(e.response.data.error);
			}
		}

	}
}

export default HoursService
import axios from 'axios'
import { GroupData } from '@js/types/settings'

/**
 * REMARK: il y a un deuxieme classe group dans le CORE
 * Pour organiser les groupes
 */
class GroupsService {
	private static readonly instance = new GroupsService()

	private constructor() { }

	public static getInstance() : GroupsService {
		return GroupsService.instance
	}


	/**
	 * Permet de récupérer tous les groupes
	 */
	public async getAll() : Promise<Array<GroupData>> {
		return axios.get('/api/groups/get/all').then(response => response.data.data)
	}

	public async getByIds(ids: Array<string>) : Promise<Array<GroupData>> {
		return axios.post(`/api/groups/by-ids`, { ids }).then(response => response.data.data)
	}

	/**
	 * Permet de supprimer totalement un groupe en base de données
	 * @param id 
	 */
	public async delete(id: string) {
		await axios.delete(`/api/groups/${id}`)
	}

	public async getByID(id: string) : Promise<GroupData> {
		return axios.get(`/api/groups/${id}`).then(response => response.data.data)
	}

	public async update(group: GroupData) : Promise<void> {
		await axios.put('/api/groups', group)
	}

	public async create(group: GroupData) : Promise<void> {
		await axios.post('/api/groups', group)
	}
}

export default GroupsService
import axios from 'axios'
import Product from '@js/types/products'
import { FilesManagerFile } from '../FilesManager'
import FilesManager2 from '../FilesManager2'
import ServiceCache from '../Cache'

class ProductsService {

	public static readonly TABLE = "products"
	private static instance = new ProductsService()
	private cache?: ServiceCache;

	private constructor() {
		this.cache = ServiceCache.getInstance('products')
	}

	public static getInstance() : ProductsService {
		return this.instance;
	}

	public async getByID(ID: string, ignoreCache:boolean = false) : Promise<Product> {
	
		if (ignoreCache) {
			return await axios.get(`/api/products/${ID}`).then(response => response.data.data);;
		}

		return await this.cache?.gatherData(ID, async(ID)=>{
			return await axios.get(`/api/products/${ID}`).then(response => response.data.data);
		})
	}

	/**
	 * Permet de récupérer tous les produits renseignés ou tout les produits
	 * @param ids 
	 */
	public async getByIDs(ids: Array<string> = []): Promise<Array<Product>> {
		return axios.post('/api/products/by-ids', { ids }).then(response => response.data.data)
	}

	/**
	 * Prermet de récupérer tous les produits, même ceux qui ont été supprimé
	 */
	public async getAllProducts(): Promise<Array<Product>> {
		return axios.get(`/api/products/get-all/null`).then(response => response.data.data)	
	}

	public async create(product: Product) : Promise<void> {
		await axios.post('/api/products', product)
	}

	public async update(product: Product) : Promise<void> {
		await axios.put('/api/products', product)
	}


	/**
	 * Permet d'uploader des fichiers dans la database products
	 * @param id 
	 * @param files 
	 */
	public async syncLogoAttachment(id: string, files: Array<FilesManagerFile>) {
		await FilesManager2.synchronizeFiles(ProductsService.TABLE, id, files)
	}

	/**
	 * Permet de supprimer un produit en base de données
	 * @param id 
	 */
	public async delete(id: string) : Promise<void> {
		await axios.get(`/api/products/tag-delete/${id}`)
	}

	public async getAll() : Promise<Array<Product>> {
		return axios.get('/api/products').then(response => response.data.data)
	}

	// REMARK: est-ce pertinent comme fonction et api ?
	public async setWastage(id: string, wastage: number) {
		let data = await this.getByID(id)
		data.wastage = wastage
		await this.update(data)
	}
}

export default ProductsService
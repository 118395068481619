const properties: { [key: string]: any } = {
    potentialMethane: {
        name: 'Potentiel Méthane',
        unit: 'normal_m3'
    },
    wastage: {
        name: 'Freinte',
        unit: 'percent',
    },
    organicMatter: {
        name: 'M.O.' ,
        unit: 'percent',
    },
    dryMatter: {
        name: 'M.S.',
        unit: 'percent',
    }
}
export default properties
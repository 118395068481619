module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="modal fade" role="dialog">\r\n    <div class="modal-dialog modal-lg" id="modal-add-calendar" role="document">\r\n\r\n        <div class="modal-content">\r\n\r\n            <div class="modal-header sticky-top container-fluid white">\r\n\r\n                <div>\r\n                    <h5 class="modal-title">\r\n                        ';
if(type == 'add'){;
__p += '\r\n                        Nouvel\r\n                        ';
};
__p += '\r\n                        ';
if(type == 'edit' || type == 'colorsOnly'){;
__p += '\r\n                        Modification\r\n                        ';
};
__p += '\r\n                        agenda\r\n                    </h5>\r\n                </div>\r\n\r\n                <div class="text-right">\r\n                    <button type="button" class="btn btn-green btn-rounded" id="save">Sauvegarder</button>\r\n                    <button type="button" class="btn btn-red btn-rounded" data-reject="modal">Annuler</button>\r\n                </div>\r\n\r\n            </div>\r\n\r\n            <div class="modal-body p-3">\r\n\r\n            <div class="form-group">\r\n                <label>Catégorie</label>\r\n                <input type="text" class="form-control" id="category" placeholder="Catégorie" value="' +
((__t = (data.category)) == null ? '' : __t) +
'" ' +
((__t = (type == 'colorsOnly' ? 'disabled' : '')) == null ? '' : __t) +
'>\r\n            </div>\r\n\r\n\r\n            <div class="form-group">\r\n                <label>Nom</label>\r\n                <input type="text" class="form-control" id="name" placeholder="Nom" value="' +
((__t = (data.name)) == null ? '' : __t) +
'"  ' +
((__t = (type == 'colorsOnly' ? 'disabled' : '')) == null ? '' : __t) +
'>\r\n            </div>\r\n\r\n            <div class="form-group">\r\n                <label>Type</label>\r\n                <select class="form-control" id="type"  ' +
((__t = (type == 'colorsOnly' ? 'disabled' : '')) == null ? '' : __t) +
'>\r\n                    <option value="" value="0" ' +
((__t = (data.isRecall ? '' : 'selected')) == null ? '' : __t) +
'>Normal</option>\r\n                    <option value="1" ' +
((__t = (data.isRecall ? 'selected' : '')) == null ? '' : __t) +
'>Rappels</option>\r\n                </select>\r\n            </div>\r\n\r\n            <div class="form-group" id="site">\r\n                <label>Site</label>\r\n                <site-select class="form-control"  ' +
((__t = (type == 'colorsOnly' ? 'disabled' : '')) == null ? '' : __t) +
'></site-select >\r\n            </div>\r\n\r\n            <div class="row">\r\n\r\n                <div class="col text-center" id="bgColor">\r\n                    <label>Couleur de fond :</label>\r\n                    <color-picker value="' +
((__t = (data.bgColor)) == null ? '' : __t) +
'"></color-picker>\r\n                </div>\r\n\r\n                <div class="col text-center" id="color">\r\n                    <label>Couleur de texte :</label>\r\n                    <color-picker value="' +
((__t = (data.color)) == null ? '' : __t) +
'"></color-picker>\r\n                </div>\r\n                \r\n            </div>\r\n\r\n        </div>\r\n\r\n    </div>\r\n\r\n</div>';

}
return __p
}
import { global } from "@autoprog/core-client"

class HideIfAllowSitesAttribute extends HTMLDivElement {
	public static readonly tagName = "hide-if-allow-sites"

	private _nbSites: number | null = null

	public constructor() {
		super()
	}

	public connectedCallback() {
		
		if (!this.hasAttribute('nb-sites')) {
			throw new Error('nb-sites is affected to this element')
		}

		this._nbSites = +this.getAttribute('nb-sites')!

		this.applyStyle()
	}

	public attributeChangedCallback(name: string, _oldValue: string | null, newValue: string | null) {
		if (name === 'nb-sites') {
			this._nbSites = newValue ? +newValue : null
			this.applyStyle()
		}
	}

	static get observedAttributes() {
		return ['nb-sites']
	}


	private applyStyle() {
		if (this._nbSites) {
			this.style.display = global.ALLOW_SITES.length === this._nbSites ? 'none' : ''
		}
	}

	public static register() {
		customElements.define(HideIfAllowSitesAttribute.tagName, HideIfAllowSitesAttribute, { extends: 'div' })
	}
}

export default HideIfAllowSitesAttribute
import _ from 'lodash'
import axios from 'axios'


export type VAT = {
	_id: string,
	_rev?: string,
	percent: number,
	label: string,
	default:boolean
	accountingAccount: string
}

class VATService {

	private static instance = new VATService();

	private VAT: VAT[];

	private constructor() {
		this.VAT = [];
	}

	public static getInstance() {
		return VATService.instance;
	}

	public async load() {
		
		const { data } = await axios.get('/api/VAT/')

		if (data && !data.error) {
			this.VAT = data.data;
		} else {
			this.VAT = [];
			throw data.error;
		}

	}

	public getAll() {
		return _.clone(this.VAT);
	}


	public getById(id: string): VAT | null {
		return _.find(this.VAT, { _id: id }) || null;
	}

	public getDefault(): VAT | null {
		return (_.find(this.VAT, { default: true }) as VAT || null) || null
	}


	public async update(id: string, vat: VAT) {

		const { data } = await axios.put('/api/VAT/', {
			...vat,
			_id: id
		})

		if (data && !data.error) {
			this.VAT = data.data;
		} else {
			this.VAT = [];
			throw data.error;
		}

	}

	public async add(vat: VAT) {

		const { data } = await axios.post('/api/VAT/', vat)

		if (data && !data.error) {
			this.VAT = data.data;
		} else {
			this.VAT = [];
			throw data.error;
		}

	}

	public async delete(ID: string) {

		const { data } = await axios.delete(`/api/VAT/${ID}`)

		if (data && !data.error) {
			this.VAT = data.data;
		} else {
			this.VAT = [];
			throw data.error;
		}
	}

}

export default VATService;
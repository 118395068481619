import { Tab, global } from "@autoprog/core-client"

import { Grid, GridOptions, ICellRendererParams } from 'ag-grid-community'
import 'ag-grid-enterprise'


import DigeststatType from '@js/types/digestats'
import unitList from '@libs/lists/unitList'

import TransfertDigestat from '@libs/modals/exploitation/TransfertDigestat'

import Highcharts from '@libs/Highcharts'
import moment from 'moment'


import '@css/digestats.scss'

import drpC from '@libs/utils/daterangepickerConfig'
import { french as agGridFrench } from '@libs/utils/agGrid'

import Export from '@libs/agGrid/Export'

import TextFilterAccent from '@libs/agGrid/TextFilterAccent'
import Site, { SiteStorage } from '@js/types/site'
import SitesService from '@js/libs/services/SitesService'
import Tier from '@js/types/Tiers'
import TiersService from '@js/libs/services/TiersService'
import DigestatsService, { DictionaryDigestatStorageInformation } from '@js/libs/services/DigestatsService'

import _ from 'lodash'

class Digestats extends Tab {

	private currentSite: string = global.DESIGN;

	private gridOptionsData: GridOptions = {};
	private gridOptionsHistory: GridOptions = {};
	private site: Site | null = null;
	private data: Array<DigeststatType> | null = null;

	private quantityDayPerStorage: DictionaryDigestatStorageInformation = {}

	private startDate: moment.Moment = moment().startOf('month');
	private endDate: moment.Moment = moment().endOf('month');

	constructor(private el: HTMLElement) {
		super(el)

		this.init()

		this.getQuantityPerDay()
			.then(() => this.getSite())
			.then((site) => {
				this.site = site
				this.initDashboard()
				this.initDatePickerIO()
			})

		const N_btn_transfer = this.el.querySelector('#btn-transfert');

		if (N_btn_transfer) {

			N_btn_transfer.addEventListener('click', () => {

				const modal = new TransfertDigestat(this.currentSite);

				modal.open().then(() => {

					this.initDashboard();

					this.getRowData().then(() => {

						this.initIO();

						let N_container_IO = this.el.querySelector('#IO-container');

						if (N_container_IO && !N_container_IO.classList.contains('d-none')) {

							this.initChartIO()

						}

					});

				}).catch(() => {

				});

			});

		}

	}

	public updateSelectSite(value: string) {

		this.currentSite = value;

		this.getQuantityPerDay().then(() => {

			this.getSite().then((site) => {

				this.site = site;

				this.initDashboard();

				this.getRowData().then(() => {

					this.initIO();

					let N_container_IO = this.el.querySelector('#IO-container');

					if (N_container_IO && !N_container_IO.classList.contains('d-none')) {

						this.initChartIO()

					}

				});

			});

		});

	}

	private async init(): Promise<void> {

		let N_btn_dashboard = this.el.querySelector('#btn-dashboard');
		let N_btn_IO = this.el.querySelector('#btn-IO');
		let N_btn_IO_grid = this.el.querySelector('#btn-IO-grid');
		let N_btn_IO_spin = this.el.querySelector('#btn-IO-spin');
		let N_btn_IO_chart = this.el.querySelector('#btn-IO-chart');

		let N_container_dashboard = this.el.querySelector('#dashboard-container');
		let N_container_IO = this.el.querySelector('#IO-container');
		let N_container_IO_grid = this.el.querySelector('#IO-grid-container');
		let N_container_IO_chart = this.el.querySelector('#IO-chart-container');

		if (N_btn_dashboard && N_btn_IO && N_btn_IO_grid && N_btn_IO_chart) {

			N_btn_dashboard.addEventListener('click', () => {

				if (N_container_dashboard && N_container_IO && N_btn_dashboard && N_btn_IO) {

					N_container_dashboard.classList.remove('d-none');
					N_container_dashboard.classList.add('d-flex');
					N_container_IO.classList.add('d-none');
					N_container_IO.classList.remove('d-flex');

					N_btn_dashboard.classList.add('d-none');
					N_btn_IO.classList.remove('d-none');

				}

			});

			N_btn_IO.addEventListener('click', () => {

				this.getRowData().then(() => {

					if (N_container_dashboard && N_container_IO && N_container_IO_grid && N_container_IO_chart && N_btn_IO_grid && N_btn_IO_chart && N_btn_IO_spin && N_btn_dashboard && N_btn_IO) {

						N_container_dashboard.classList.add('d-none');
						N_container_dashboard.classList.remove('d-flex');
						N_container_IO.classList.remove('d-none');
						N_container_IO.classList.add('d-flex');

						N_container_IO_grid.classList.remove('d-none');
						N_container_IO_chart.classList.add('d-none');

						N_btn_dashboard.classList.remove('d-none');
						N_btn_IO.classList.add('d-none');

						this.initIO();

						N_btn_IO_chart.classList.add('d-none');
						N_btn_IO_grid.classList.add('d-none');
						N_btn_IO_spin.classList.remove('d-none');

						setTimeout(() => {

							this.initChartIO()

							setTimeout(() => {

								N_btn_IO_grid && N_btn_IO_grid.classList.add('d-none');
								N_btn_IO_chart && N_btn_IO_chart.classList.remove('d-none');

								N_btn_IO_spin && N_btn_IO_spin.classList.add('d-none');

							}, 50);

						}, 50);
					}

				});

			});

			N_btn_IO_grid.addEventListener('click', () => {

				if (N_container_IO_grid && N_container_IO_chart && N_btn_IO_grid && N_btn_IO_chart) {
					N_container_IO_grid.classList.remove('d-none');
					N_container_IO_chart.classList.add('d-none');

					N_btn_IO_grid.classList.add('d-none');
					N_btn_IO_chart.classList.remove('d-none');
				}

			});

			N_btn_IO_chart.addEventListener('click', () => {

				if (N_container_IO_grid && N_container_IO_chart && N_btn_IO_grid && N_btn_IO_chart) {
					N_container_IO_grid.classList.add('d-none');
					N_container_IO_chart.classList.remove('d-none');

					N_btn_IO_grid.classList.remove('d-none');
					N_btn_IO_chart.classList.add('d-none');
				}

			});

		}

	}

	private initIO() {

		this.getAllCustomers().then((customers) => {

			let N_history = this.el.querySelector("#IO-grid");

			if (this.gridOptionsHistory.api) {

				this.gridOptionsHistory.api.setRowData(this.data || []);

			} else {

				this.gridOptionsHistory = agGridFrench({

					rowData: this.data || [],
					groupMultiAutoColumn: true,
					suppressAggFuncInHeader: true,
					suppressDragLeaveHidesColumns: true,
					defaultColDef: {
						floatingFilter: true,
						filter: true,
						resizable: true,
						sortable: true,
						suppressMenu: true,
					},
					columnDefs: [
						{
							headerName: 'Date',
							field: 'date',
							filter: false,
							cellRenderer: (params) => {
								return !params.node.rowPinned ? moment(params.value, 'x').format('DD/MM/YYYY HH:mm') : ''
							}
						}, {
							headerName: 'Stockage',
							field: 'storage',
							valueGetter: (params) => {

								if (this.site && !params.node?.rowPinned) {
									const tmp = _.find(this.site.storages || [], { id: params.data.storage });
									return tmp ? tmp.name : params.data.storage
								}

								return '';
							}
						}, {
							headerName: 'Type',
							field: 'type',
							valueGetter: (params) => {

								if (!params.node?.rowPinned) {

									if (params.data.type == 'input') {
										return 'Entrée'
									}

									if (params.data.type == 'output') {
										return 'Sortie'
									}

								}

								return '';

							}
						}, {
							headerName: 'Client',
							field: 'customer',
							valueGetter: (params) => {

								let result = [];

								if (!params.node?.rowPinned) {

									let customer = _.find(customers, { _id: params.data.customer });

									result.push(customer ? customer.name : (params.data.customer || ''));
									result.push(params.data.place || '');
								}

								result = _.compact(result);

								return result.join(' - ');

							}
						}, {
							headerName: 'Quantité',
							field: 'quantity',
							cellClass: 'text-right',
							cellRenderer: (params) => {

								if (this.site) {

									let tmp = _.find(this.site.storages || [], { id: params.data.storage });

									if (tmp) {
										return _.round(params.value / unitList[tmp.unit].coeficient, 2);
									}

								}

								return params.value;

							}
						}, {
							headerName: 'Unité',
							field: 'unit',
							cellRenderer: (params) => {

								if (this.site) {

									let tmp = _.find(this.site.storages || [], { id: params.data.storage });

									if (tmp) {
										return unitList[tmp.unit].unit;
									}

								}

								return params.value ? unitList[params.value].unit : '';

							}
						}, {
							headerName: '#',
							width: 80,
							filter: false,
							cellRenderer: (params: ICellRendererParams) => {

								if (!params.node.rowPinned) {

									let N_delete = document.createElement('button');

									N_delete.classList.add('btn', 'btn-xs', 'btn-danger');

									N_delete.innerHTML = '<i class="icon icon-solid-trash-alt"></i>'

									N_delete.setAttribute('confirmation', '');
									N_delete.setAttribute('permission', 'EXPLOITATION.DIGESTAT.DELETE');

									N_delete.addEventListener('click', async () => {
										await DigestatsService.getInstance().delete(params.data._id)
										params.api.applyTransaction({ remove: [params.data] });
										this.initDashboard()
									})

									return N_delete;

								}

								return '';

							}

						}
					],
					getRowStyle: (params: any) => {
						if (params.node.rowPinned) {
							return { 'font-weight': 'bold' };
						}
					},
					getContextMenuItems: (params) => {

						if (params.columnApi) {

							let allColumns = params.columnApi.getAllColumns();

							let columnKeys = _.map(allColumns, 'colId');

							columnKeys.pop();

							let exportAllExcel = {
								name: 'Exporter tous Excel',
								action: () => {
									let exp = new Export(params);
									exp.exportExcel({
										columnKeys
									});
								}
							};

							let exportAllCSV = {
								name: 'Exporter tous CSV',
								action: () => {
									let exp = new Export(params);
									exp.exportCSV({
										columnKeys
									});
								}
							};

							return [exportAllExcel, exportAllCSV];

						}

						return [];

					},
					onGridReady: (params) => {
						(params && params.api) && params.api.sizeColumnsToFit();
						this.updatePinnedRow(params);
					},
					onRowDataChanged: (params) => {
						this.updatePinnedRow(params);
					},
					onFilterChanged: (params) => {
						this.updatePinnedRow(params);
					}
				});

				if (N_history) {
					new Grid(N_history as HTMLElement, this.gridOptionsHistory);
				}

			}

		});

	}

	private initDashboard() {

		this.getDataToDashboard().then((rowData) => {

			if (this.site) {

				let N_stock = this.el.querySelector("#dashboard-grid");

				if (this.gridOptionsData && this.gridOptionsData.api) {

					this.gridOptionsData.api.setRowData(rowData);

				} else {

					this.gridOptionsData = agGridFrench({

						rowData,
						groupMultiAutoColumn: true,
						suppressAggFuncInHeader: true,
						suppressDragLeaveHidesColumns: true,
						floatingFilter: true,
						defaultColDef: {
							filter: 'agTextColumnFilter',
							filterParams: {
								filterOptions: ['contains'],
								textCustomComparator: TextFilterAccent
							}
						},
						columnDefs: [
							{
								headerName: 'Stockage',
								field: 'storageData.name',
								cellRenderer: (params) => {
									return !params.value || params.value === "" ? params.data.storage : params.value
								}
							}, {
								headerName: 'Unité',
								filter: 'agNumberColumnFilter',
								field: 'storageData.unit',
								cellRenderer: (params) => {
									return this.site && params.value ? unitList[params.value].name : '';
								}
							}, {
								headerName: 'Volume total',
								cellClass: 'text-right',
								filter: 'agNumberColumnFilter',
								field: 'storageData.unit',
								cellRenderer: (params) => {
									if (this.site && params.value && params.data.storageData) {
										return _.round(params.data.storageData.capacity / unitList[params.value].coeficient, 2).toString()
									}
									return '---';
								}
							}, {
								headerName: 'Volume utilisé',
								filter: 'agNumberColumnFilter',
								field: 'quantity',
								cellClass: 'text-right',
								cellRenderer: (params) => {
									if (this.site && params.storageData && params.value) {
										return _.round(params.value / unitList[params.data.storageData.unit].coeficient, 2)
									}
									return params.value;

								}
							}, {
								headerName: 'Volume disponible',
								filter: 'agNumberColumnFilter',
								cellClass: 'text-right',
								cellRenderer: (params) => {
									if (!params.node.rowPinned && this.site && params.data.storageData) {
										const quantity = params.data.storageData.capacity - params.data.quantity
										const value = quantity > params.data.storageData.capacity ? params.data.storageData.capacity : quantity
										return _.round(value / unitList[params.data.storageData.unit].coeficient, 2).toString()
									}
									return '---';
								}
							}, {
								headerName: 'Tonnage Moyen / Jour',
								cellClass: 'text-right',
								cellRenderer: (params) => {

									if (this.site) {
										let quantityDay = this.quantityDayPerStorage[params.data.storageData._id]
										if (quantityDay) {
											return _.round(quantityDay.quantityPerDay / unitList[params.data.storageData.unit].coeficient, 2).toString()
										}
									}

									return '---';

								}
							}, {
								headerName: 'Date limite',
								filter: 'agDateColumnFilter',
								field: 'lastDate',
								cellRenderer: (params) => {

									if (this.site) {
										let quantityDay = this.quantityDayPerStorage[params.data.storageData._id]
										if (quantityDay) {
											let quantityFree = params.data.storageData.capacity - params.data.quantity
											let numberDay = quantityFree / quantityDay.quantityPerDay
											return moment(params.value).add(Math.ceil(numberDay), 'day').format('DD/MM/YYYY')
										}
									}

									return '---'
								}
							}
						],
						getContextMenuItems: (params) => {

							let exportAllExcel = {
								name: 'Exporter tous Excel',
								action: () => {
									let exp = new Export(params);
									exp.exportExcel();
								}
							};

							let exportAllCSV = {
								name: 'Exporter tous CSV',
								action: () => {
									let exp = new Export(params);
									exp.exportCSV();
								}
							};

							return [exportAllExcel, exportAllCSV];
						},
						onGridReady: (params) => {

							(params && params.api) && params.api.sizeColumnsToFit();

						}
					});

					if (N_stock) {
						new Grid(N_stock as HTMLElement, this.gridOptionsData);
					}

				}

				this.loadChartDashboard(rowData);
			}
		})
	}

	private updatePinnedRow(params: any) {

		if (params.api) {

			let quantity = 0;

			let units: string[] = [];

			params.api.forEachNodeAfterFilter((node: any) => {
				if (!node.childrenAfterFilter && this.site) {

					let storageData = _.find(this.site.storages || [], { id: node.data.storage });

					if (storageData) {
						units.push(storageData.unit);
					}

					if (node.data.type == 'output') {
						quantity -= node.data.quantity;
					} else {
						quantity += node.data.quantity;
					}

				}
			});

			units = _.uniq(units);

			let data = {
				quantity: '',
				unit: ''
			};

			if (units.length == 1) {
				data.quantity = _.round(quantity / unitList[units[0]].coeficient, 2).toString()
				data.unit = units[0];
			} else {
				data.quantity = _.round(quantity, 2).toString()
			}

			params.api.setPinnedBottomRowData([data]);

		}

	}

	private async getDataToDashboard(): Promise<Array<{
		quantity: number,
		storage: string,
		lastDate: number | null,
		storageData: SiteStorage | null
	}>> {

		const data = await DigestatsService.getInstance().getQuantityPerStorage(this.currentSite)

		let result: Array<{
			quantity: number,
			storage: string,
			lastDate: number | null,
			storageData: SiteStorage | null
		}> = []

		if (this.site) {

			result = this.site.storages
				.filter(storage => storage.type === 'tank')
				.map(storage => {
					const item = data.find(quantityPerStorage => quantityPerStorage.storage === storage.id)
					const storageData = this.site!.storages.find(s => s.id === storage.id)!
					return item ? { ...item, lastDate: 0, storageData } : { quantity: 0, storage: storage.id, lastDate: null, storageData }
				})

			const promiseAll = result.map(quantityPerStorage => DigestatsService.getInstance().getLastDigestatFromStorage(this.currentSite, quantityPerStorage.storage))
			const digestats = await Promise.allSettled(promiseAll).then(allSettle => allSettle.filter(row => row.status == 'fulfilled').map((row: any) => row.value))

			result = result.map(quantityPerStorage => {
				const digestat = digestats.find(d => d.storage === quantityPerStorage.storage)
				quantityPerStorage.lastDate = digestat?.date || null
				return quantityPerStorage
			})
		}

		return result

	}

	private loadChartDashboard(data: any[]) {

		if (this.site) {

			let series: any[] = [{
				name: 'Emplacement disponible',
				color: '#c1c1c1',
				data: []
			}, {
				name: 'Niveau',
				data: []
			}];

			const categories = [];

			for (let item of this.site.storages || []) {
				if (item.type == 'tank') {
					categories.push(item.name);
				}
			}

			for (let item of data) {

				const storage = _.find(this.site.storages || [], { id: item.key })!

				const value = _.round(item.value * 100 / storage.capacity, 2)

				series[1].data.push(value);
				series[0].data.push(value < 0 ? 100 : _.round(100 - value, 2))

			}

			Highcharts.chart(this.el.querySelector('#dashboard-chart') as HTMLElement, {
				chart: {
					type: 'column'
				},
				title: {
					text: 'Pourcentage (%)'
				},
				xAxis: {
					categories,
				},
				plotOptions: {
					series: {
						stacking: 'normal',
						events: {
							legendItemClick: function (e) {
								e.preventDefault();
							}
						}
					}
				},
				tooltip: {
					headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
					pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
						`<td style="padding:0"><b>{point.y:.2f} %</b></td></tr>`,
					footerFormat: '</table>',
					shared: true,
					useHTML: true
				},
				yAxis: {
					title: {
						text: 'Quantité'
					},
					plotLines: [{
						value: 0,
						color: '#000000',
						width: 2,
						zIndex: 4,
					}]
				},
				credits: {
					enabled: false
				},
				series
			});

		}

	}

	private initDatePickerIO() {

		$(this.el).find('#date-picker-IO').daterangepicker(drpC({
			showDropdowns: true,
			startDate: this.startDate,
			endDate: this.endDate,

		}), (start, end, _label) => {

			this.startDate = start.startOf('day');
			this.endDate = end.endOf('day');

			this.getRowData().then(() => {

				this.initIO();

				let N_btn_IO_chart = this.el.querySelector('#btn-IO-chart');
				let N_btn_IO_grid = this.el.querySelector('#btn-IO-grid');
				let N_btn_IO_spin = this.el.querySelector('#btn-IO-spin');

				if (N_btn_IO_chart && N_btn_IO_grid && N_btn_IO_spin) {

					let isViewGrid = N_btn_IO_grid.classList.contains('d-none');

					N_btn_IO_chart.classList.add('d-none');
					N_btn_IO_grid.classList.add('d-none');
					N_btn_IO_spin.classList.remove('d-none');

					setTimeout(() => {

						this.initChartIO()

						setTimeout(() => {

							if (isViewGrid) {
								N_btn_IO_chart && N_btn_IO_chart.classList.remove('d-none');
								N_btn_IO_grid && N_btn_IO_grid.classList.add('d-none');
							} else {
								N_btn_IO_chart && N_btn_IO_chart.classList.add('d-none');
								N_btn_IO_grid && N_btn_IO_grid.classList.remove('d-none');
							}

							N_btn_IO_spin && N_btn_IO_spin.classList.add('d-none');

						}, 50);

					}, 50);
				}


			}).catch((e) => {
				console.error(e);
				//TODO: toster echec aggrid
			})

		});

	}

	private async initChartIO(): Promise<void> {

		let dataInitChartIO = await DigestatsService.getInstance().getBySite(this.currentSite, 0, +this.startDate.format('x'))

		let oldData: { [key: string]: number } = {};

		for (let item of dataInitChartIO) {

			oldData[item.storage] = oldData[item.storage] || 0

			let quantity = item.quantity

			if (item.type == 'output') {
				quantity = -1 * quantity
			}

			oldData[item.storage] += quantity

		}

		if (this.data && this.site) {

			let tmpStock: { [key: string]: number[][] } = {};

			let tmpInput: { [key: string]: number[][] } = {};
			let tmpOutput: { [key: string]: number[][] } = {};

			let N_listStorage = this.el.querySelector('#storage-chart-list') as HTMLSelectElement;

			N_listStorage.innerHTML = '';

			let lastDate = moment(this.startDate);

			for (let storage of this.site.storages || []) {
				if (storage.type == 'tank') {
					tmpStock[storage.id] = [];
					tmpInput[storage.id] = [];
					tmpOutput[storage.id] = [];
					oldData[storage.id] = oldData[storage.id] || 0;
				}
			}

			for (let item of this.data) {

				const idStorage = item.storage;
				const storage = _.find(this.site.storages || [], { id: idStorage })

				tmpStock[idStorage] = tmpStock[idStorage] || [];
				tmpInput[idStorage] = tmpInput[idStorage] || [];
				tmpOutput[idStorage] = tmpOutput[idStorage] || [];

				const lastValue = _.last(tmpStock[idStorage]) || [parseInt(this.startDate.format('x')), oldData[idStorage]];

				let value = 0;

				const quantity = item.quantity || 0;

				if (item.type == 'input' && storage) {

					value = lastValue[1] + quantity;

					tmpInput[idStorage].push([item.date, _.round(quantity / unitList[storage.unit].coeficient, 2)]);

				}

				if (item.type == 'output' && storage) {

					value = lastValue[1] - quantity;

					tmpOutput[idStorage].push([item.date, _.round(-1 * quantity / unitList[storage.unit].coeficient, 2)]);

				}

				storage && tmpStock[idStorage].push([item.date, _.round(value / unitList[storage.unit].coeficient, 2)]);

				lastDate = moment(item.date, 'x');
			}

			let series: any[] = [];

			for (let idStorage in tmpStock) {

				let quantityDay = this.quantityDayPerStorage[idStorage];
				let storage = _.find(this.site.storages || [], { id: idStorage });

				let last = _.last(tmpStock[idStorage]) || [parseInt(this.startDate.format('x')), oldData[idStorage]];

				let value = last[1];

				let date = moment(last[0], 'x');

				if (value >= 0) {

					const data: number[][] = [];

					while (date.isSameOrBefore(this.endDate)) {

						date = date.add(1, 'day');
						value += quantityDay.quantityPerDay;

						storage && data.push([
							parseInt(date.format('x')),
							_.round(value / unitList[storage.unit].coeficient, 2)
						])
					};

					tmpStock[idStorage] = tmpStock[idStorage].concat(data)

				}

			}

			let index = 1;
			let numStorage = Object.keys(tmpStock).length + 1;

			for (let idStorage in tmpStock) {

				let storage = _.find(this.site.storages || [], { id: idStorage });

				let data = tmpStock[idStorage]

				series.push({
					color: `hsl(${index * (360 / numStorage)},75%,24%)`,
					id: `${idStorage}-stock`,
					type: 'spline',
					name: storage ? storage.name : idStorage + ' (Evolution)',
					data
				});

				series.push({
					color: `hsl(${index * (360 / numStorage)},75%,33%)`,
					type: 'column',
					id: `${idStorage}-IO`,
					name: (storage ? storage.name : idStorage) + ' (Entree)',
					data: tmpInput[idStorage]
				});

				series.push({
					color: `hsl(${index * (360 / numStorage)},75%,60%)`,
					type: 'column',
					id: `${idStorage}-IO`,
					name: (storage ? storage.name : idStorage) + ' (Sortie)',
					data: tmpOutput[idStorage]
				});

				if (N_listStorage) {

					N_listStorage.innerHTML += `
                        <optgroup label="${storage ? storage.name : idStorage}">
                            <option value="${idStorage}-IO" selected>Entrée/Sortie</option>
                            <option value="${idStorage}-stock" selected>Evolution</option>
                        </optgroup>
                    `;

				}

				index++;

			}

			let N_chart = this.el.querySelector('#IO-chart');

			if (N_chart) {

				let charts = Highcharts.stockChart(N_chart as HTMLElement, {
					chart: {
						zoomType: 'x',
					},
					xAxis: {
						type: 'datetime',
						plotLines: lastDate.isBefore(this.endDate, 'day') ? [{
							color: '#888',
							value: parseInt(lastDate.format('x')),
							width: 1,
							label: {
								text: 'Prévisionnelle',
								rotation: 90
							}
						}] : [],
					},
					tooltip: {
						headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
						pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
							`<td style="padding:0"><b>{point.y:.1f} ${unitList['m3'].unit}</b></td></tr>`,
						footerFormat: '</table>',
						shared: true,
						useHTML: true
					},
					plotOptions: {
						series: {
							events: {
								legendItemClick: function (e) {
									e.preventDefault();
								}
							}
						}
					},
					title: {
						text: 'Stock'
					},
					yAxis: {
						title: {
							text: 'Quantité (Tonne)'
						}
					},
					series
				});

				if (N_listStorage) {

					N_listStorage.querySelectorAll('option').forEach((N_option) => {

						N_option.addEventListener('mousedown', (e) => {

							N_option.selected = !N_option.selected;

							for (let serie of charts.series) {

								if ((serie.options.id || '').startsWith(N_option.value)) {

									if (N_option.selected) {
										serie.show();
									} else {
										serie.hide();
									}

								}

							}

							e.preventDefault();

						});

					});

				}

			}

		}

	}

	private async getQuantityPerDay(): Promise<void> {
		this.quantityDayPerStorage = await DigestatsService.getInstance().getQuantityTotalPerDay(this.currentSite)
	}

	private async getRowData(): Promise<void> {
		this.data = await DigestatsService.getInstance().getBySite(this.currentSite, +this.startDate.format('x'), +this.endDate.format('x'))
	}

	private async getSite(): Promise<Site> {
		return SitesService.getInstance().getByID(this.currentSite)
	}

	private getAllCustomers(): Promise<Array<Tier>> {
		return TiersService.getInstance().getAll()
	}

	public destructor() {

		let N_daterangepicker = document.querySelectorAll('.daterangepicker');

		N_daterangepicker.forEach((item) => {
			let parent = item.parentNode as HTMLElement;
			parent.removeChild(item);
		});

	}

}

export default Digestats;

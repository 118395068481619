module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="d-flex h-100 flex-column d-none">\r\n\r\n    <div class="bg-secondary header pl-3">\r\n\r\n        <i class="icon icon-solid-arrow-left" id="return-list-detail"></i>\r\n\r\n        <div class="float-right">\r\n\r\n            ';
if(!data.locked){;
__p += '\r\n                <div class="dropdown d-inline-flex">\r\n                    <div data-toggle="dropdown">\r\n                        <i class="icon icon-solid-ellipsis-v"></i>\r\n                    </div>\r\n                    <div class="dropdown-menu dropdown-menu-right">\r\n                        ';
if(!data.suppressEdit){;
__p += '\r\n                            <a class="dropdown-item tui-full-calendar-popup-edit w-100">Modifier</a>\r\n                        ';
};
__p += '\r\n            \r\n                        ';
if(!data.suppressRemove){;
__p += '\r\n                            <a class="dropdown-item tui-full-calendar-popup-delete w-100">Supression</a>\r\n                        ';
};
__p += '\r\n                    </div>\r\n                </div>\r\n            ';
};
__p += '\r\n\r\n        </div>\r\n\r\n    </div>\r\n\r\n    <div class="flex-grow-1 fix-container">\r\n\r\n        <div class="h-20 p-1" style="background-color: ' +
((__t = (data.bgColor)) == null ? '' : __t) +
'">\r\n\r\n            <h3> ' +
((__t = (data.title)) == null ? '' : __t) +
' </h3>\r\n\r\n            <div class="text-muted">\r\n                ';
let html = [];;
__p += '\r\n\r\n                ';
for(let idSite of data.calendar.sites){;
__p += '\r\n\r\n                    ';
let tmp = _.find(sites, {_id : idSite});;
__p += '\r\n\r\n                    ';
html.push(tmp ? tmp.name : idSite);;
__p += '\r\n\r\n                ';
};
__p += '\r\n\r\n                ' +
((__t = (html.length > 1 ? 'Sites' : 'Site')) == null ? '' : __t) +
' : ' +
((__t = (html.join(', '))) == null ? '' : __t) +
'\r\n            </div>\r\n\r\n            <div>\r\n                ' +
((__t = (data.date)) == null ? '' : __t) +
'\r\n            </div>\r\n\r\n            ';
if(data.description){;
__p += '\r\n                <div>\r\n                    ' +
((__t = (data.description)) == null ? '' : __t) +
'\r\n                </div>\r\n            ';
};
__p += '\r\n            ';
if(data.users && data.users.length){;
__p += '\r\n                <div>Interventants :</div>\r\n                ';
for(let user of data.users){;
__p += '\r\n                    ';
let tmp = _.find(users, {_id : user });
__p += '\r\n                    ';
if(tmp){;
__p += '\r\n                        <div>\r\n                            ' +
((__t = (tmp.firstname)) == null ? '' : __t) +
' ' +
((__t = (tmp.lastname)) == null ? '' : __t) +
'\r\n                        </div>\r\n                    ';
}else{;
__p += '\r\n                        <div>\r\n                            ' +
((__t = (user)) == null ? '' : __t) +
'\r\n                        </div>\r\n                    ';
};
__p += '\r\n                ';
};
__p += '\r\n            ';
};
__p += '\r\n        </div>\r\n\r\n    </div>\r\n    \r\n</div>';

}
return __p
}
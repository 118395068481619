import { Settings } from "@autoprog/core-client"
import SitesService from "./SitesService";
import axios from 'axios'
import BillType from "@js/types/billType";
import OldDataEvent from "@js/types/data-event";
import DataEventsService from "./DataEventsService";
import { FilesManagerFile } from "../FilesManager";
import FilesManager2 from "../FilesManager2";
import ServiceCache from "../Cache";

class BillsService {

	public static readonly TABLE = "bills"
	private static instance = new BillsService();
	private cache?: ServiceCache;

	private constructor() { 
		this.cache = ServiceCache.getInstance('delivery-notes')
	}

	public static getInstance() {
		return BillsService.instance
	}

	public async getFooter(siteID?: string): Promise<string | null> {

		let defaultFooter = Settings.getInstance().get('billFooter') as string | null;;

		if (siteID) {
			let site = await SitesService.getInstance().getByID(siteID);
			return site.footerTextBill || defaultFooter;
		}

		return defaultFooter;

	}

	public async set(footer: string, siteID?: string) {

		if (!siteID) {
			return Settings.getInstance().setGeneral('billFooter', footer);
		} else {
			//FIXME: Methode à terminer
			throw new Error('Methode à terminer');
		}

	}

	// TODO: changer le nom pur setState
	public async setStatus(id: string, status: string): Promise<void> {
		let bill = await this.getByID(id, true)
		bill.state = status
		await this.update(bill)
	}

	public async getByID(ID:string, ignoreCache: boolean = false) : Promise<BillType> {

		if (ignoreCache) {
			return await axios.get(`/api/bills/${ID}`).then(response => response.data.data);;
		}

		return await this.cache?.gatherData(ID, async(ID)=>{
			return await axios.get(`/api/bills/${ID}`).then(response => response.data.data);
		})
	}

	// FIXME: le remplacer par l'API /bills/by-ids
	public async getByIds(ids: Array<string>) : Promise<Array<BillType>> {
		return Promise.all(ids.map( id => this.getByID(id) ))
	}

	public async update(bill: BillType) : Promise<void> {
		await axios.put(`/api/bills`, bill)
	}

	public async create(bill: BillType) : Promise<void> {
		await axios.post(`/api/bills`, bill)
	}

	/**
	 * Permet de supprimer les fatures en fonction du numero de commande
	 * @param orderId 
	 */
	public async deleteByOrder(orderId: string) : Promise<void> {
		await axios.delete(`/api/bills/by-order/${orderId}`)
	}

	public async getBySite(siteId: string) : Promise<Array<BillType>> {
		return axios.get(`/api/bills/by-site/${siteId}`).then(response => response.data.data)
	}

	public async delete(id: string) : Promise<void> {
		await axios.get(`/api/bills/tag-delete/${id}`)
	}

	public async createEvents(dataEvent: Omit<OldDataEvent, 'table'>) : Promise<void> {
		await DataEventsService.getInstance().create({... dataEvent, table: 'bills'})
	}

	/**
	 * Cette fonction permet mettre à jour les status des bons de livraisons, bon d'interventions et commande en fonction de la facture
	 * @param id identifiant de la facture
	 */
	public async updateBillStatus(id: string) : Promise<void> {
		await axios.get(`/api/bills/update-status/${id}`)
	}

	/**
	 * Permet de locker une facture
	 * @param id 
	 * @param reason 
	 */
	public async locked(id: string, reason: string) : Promise<void> {
		await axios.put(`/api/bills/locked`, { id, reason })
	}

	/**
	 * Permet d'entrer les codes FXL
	 */
	public async fxlCode(id: string, code: string) : Promise<void> {
		await axios.put(`/api/bills/fxlCode`, { id, code })
	}

	public async syncAttachment(id: string, files: Array<FilesManagerFile>) : Promise<void> {
		await FilesManager2.synchronizeFiles(BillsService.TABLE, id, files)
	}

	public async getPDF(id: string, options = { workOrders: false, order: false, deliveryNotes: false }) : Promise<Blob> {
		const workOrder = options.workOrders ? 1 : 0
		const deliveryNotes = options.deliveryNotes ? 1 : 0
		const order = options.order ? 1 : 0
		return axios.get(`/api/bills/pdf/${id}`, { responseType: "blob", params: { workOrder, deliveryNotes, order } }).then(response => response.data)
	}
}

export default BillsService

import { Modal, global, toaster, LoggedUser } from "@autoprog/core-client"
import { OperatingDataDefinitionService, ChecklistModelsService, ChecklistsService, OperatingDataService, EventsService } from "@js/libs/services"

import 'select2'

import T_addEditCheckList from '@tpl/modals/exploitation/addEditCheckList.html'

import _ from "lodash"
import ServerStatusIndicatorElement, { StatusConnection } from "@js/libs/customElements/ServerStatusIndicator"
import { ChecklistData } from "@js/libs/utils/table-checklist.helper"
import { TableChecklistDefinition, TableChecklistStructure } from "@js/libs/customElements/checklists/TableChecklistRow"
import { CheckListModel, CheckList } from "@js/types/checklist"
import { OperatingData } from "@js/types/operating-data"
import { OperatingDataDefinition } from "@js/libs/services/OperatingDataDefinitionService"
import unitList from "@js/libs/lists/unitList"
import TableCheckList from "@js/libs/customElements/checklists/TableChecklist"
import moment, { Moment } from "moment"
import BDD from "@js/libs/BDD"
import DataEventsService from "@js/libs/services/DataEventsService"
import { EventBdd } from "@js/types/calendar"

class AddEditCheckList extends Modal {

	private name: string
	private mode: 'ADD' | 'UPDATED'
	private repeatEnabled: boolean

	constructor(private model: string, private id?: string, private date?: number) {
		super({
			tpl: T_addEditCheckList,
			backdrop: 'static',
			keyboard: false
		})

		if (this.id && this.date) {
			throw new Error(`Impossible to have date and id filled in th same time.`)
		}

		this.mode = this.id ? 'UPDATED' : 'ADD' // On en besoin pour les evenements
		this.name = ''
		this.repeatEnabled = false

		this.on('opened', async () => {
			this.initStatus()
			const N_save = this.element.querySelector('#save-checklist-button') as HTMLButtonElement
			N_save.disabled = true
			await this.load()

			N_save.addEventListener('click', () => this.save())
			N_save.disabled = false
		})

	}

	private now() : number {
		return +moment().startOf('day').format('x')
	}

	private initStatus() {
		const N_save = this.element.querySelector('#save-checklist-button') as HTMLButtonElement
		const N_status = this.element.querySelector(ServerStatusIndicatorElement.tagName) as ServerStatusIndicatorElement
		N_status.addEventListener(StatusConnection.normal, () => _.set(N_save, 'disabled', false))
		N_status.addEventListener(StatusConnection.slow, () => _.set(N_save, 'disabled', false))
		N_status.addEventListener(StatusConnection.slower, () => _.set(N_save, 'disabled', true))
		N_status.addEventListener(StatusConnection.error, () => _.set(N_save, 'disabled', true))
		N_status.start()
	}

	private async getData(): Promise<{ values: Array<ChecklistData>, structures: Array<TableChecklistStructure>, model: CheckListModel, list?: CheckList }> {
		const model = await ChecklistModelsService.getInstance().getByID(this.model)
		const list = this.id ? await ChecklistsService.getInstance().getByID(this.id) : undefined
		const definitionsIDs = model.structure.filter(struct => struct.type === 'input').map(struct => struct.value)
		const definitions = await OperatingDataDefinitionService.getInstance().getByIds(definitionsIDs)
		const data = await OperatingDataService.getInstance().getByList(this.id ?? "unknown") // Dans le futur pour changer par getByModelDate()

		const convertDefinition = (definition: OperatingDataDefinition): TableChecklistDefinition => ({
			id: definition._id,
			name: definition.label,
			type: definition.dataType as any,
			default: definition.dataTypeParams.value || undefined,
			unit: unitList[definition.unit || 'unknown']?.unit || undefined,
			values: definition.dataTypeParams.values?.split(';') || undefined,
			max: definition.dataTypeParams.maxlength || definition.dataTypeParams.max || undefined,
			min: definition.dataTypeParams.minlength || definition.dataTypeParams.min || undefined,
			placeholder: definition.dataTypeParams.placeholder,
			pattern: definition.dataTypeParams.pattern,
			step: definition.dataTypeParams.step || undefined,
			description: definition.description
		})

		const structures: Array<TableChecklistStructure> = model.structure.map(structure => {
			const definition = definitions.find(def => def._id === structure.value)!
			return {
				value: structure.value,
				definitions: structure.type === 'input' ? convertDefinition(definition) : undefined,
				type: structure.type
			}
		})

		const values: Array<ChecklistData> = data.map(row => ({ ..._.omit(row, '_id', 'list', 'date'), id: row._id }))

		return {
			structures,
			values,
			model,
			list
		}
	}

	private generateID(repeat?: { to: number | Moment, from: number | Moment, interval: { value: number, type: moment.unitOfTime.DurationConstructor } }): string {
		let checklistID = this.model + '_' + global.SITE + '_' + moment().format('YYYY-MM-DD-HH-mm')

		if (repeat) {

			if (this.date) {
				checklistID = this.model + '_' + global.SITE + '_' + moment(this.date, 'x').format('YYYY-MM-DD')
			}
			else {
				//Si on est en mode répétition, on cheche la date la plus proche de maintenant correspondant a l'interval
				const fromDate = moment(repeat.from).startOf('day')
				const date = moment().startOf('day')

				while (fromDate.isBefore(date)) {
					fromDate.add(repeat.interval.value, repeat.interval.type)
				}

				fromDate.isAfter(date) && fromDate.subtract(repeat.interval.value, repeat.interval.type)
				checklistID = this.id + '_' + global.SITE + '_' + fromDate.format('YYYY-MM-DD')
			}
		}

		return checklistID
	}

	private async load() {
		const data = await this.getData()

		// this.date devrait valoir la date imposer par defaut
		this.date = this.date || data.list?.date || this.now()
		this.id = this.id || this.generateID(data.model.repeat)
		this.name = data.model.name

		const N_table = this.element.querySelector(TableCheckList.tagName) as TableCheckList
		N_table.structures = data.structures
		N_table.values = data.values
	}

	private createDefault(): CheckList {
		return {
			_id: this.id || BDD.generateID(),
			site: global.SITE,
			date: this.date || this.now(),
			updateDate: this.now(),
			name: this.name,
			modelID: this.model,
			percent: 0,
			locked: false
		}
	}

	private async save() {

		const N_table = this.element.querySelector(TableCheckList.tagName) as TableCheckList

		if (!N_table.checkValidity()) {
			toaster.error('Checklist invalide')
			return
		}

		// On detruit automatiquement les ancienne donnees, il est plus facile de reecrire toutes les donnees que de faire la mise a jour
		await Promise.allSettled([
			ChecklistsService.getInstance().delete(this.id!),
			OperatingDataService.getInstance().deleteList(this.id!)
		])

		const data = N_table.values.concat(N_table.rejected)

		const list = this.createDefault()
		list.percent = N_table.percent

		const operatingData: Array<OperatingData> = data.map(checklistData => ({
			..._.omit(checklistData, 'value', 'id'),
			_id: `${this.id!}|${checklistData.definition}`,
			date: this.date!,
			list: this.id!,
			value: checklistData.value as string | number,
			type: 'list'
		}))

		await Promise.all([
			ChecklistsService.getInstance().create(list),
			OperatingDataService.getInstance().updateAll(operatingData)
		])

		// Enregistrer dans operating data nos nouvelles donnees

		// On signale un evenement sur la checklist en question
		const typeEvent = this.mode === 'ADD' ? 'create' : 'update'
		await DataEventsService.getInstance().create({
			id: this.id!,
			_id: BDD.generateID(),
			date: this.now(),
			table: 'checklist',
			type: typeEvent,
			user: LoggedUser.getInstance().get('ID')
		})

		this.repeatEnabled && await this.updateRepeatEvent()

		toaster.success('Checklist crée')
		this.resolve()
	}

	private async updateRepeatEvent() {
		const N_table = this.element.querySelector(TableCheckList.tagName) as TableCheckList
		
		try {
			const title = `${this.name} <i>(${N_table.percent}%)</i>`
			const description = `<a href="#exploitation?checklist=${this.id}">Saisir</a>`

			const event: EventBdd = await EventsService.getInstance().getByID('checklist-' + this.id)
				.then(ev => ({ ..._.omit(ev, 'title', 'description', 'bgColor', 'color'), title, description }))

			if (N_table.percent >= 100) {
				event.bgColor = '#eeeeee'
				event.color = '#000000'
			}

			await EventsService.getInstance().update(event)
		}
		catch {
			toaster.error("Erreur de la mise a jour de l'événement")
		}
	}
}

export default AddEditCheckList

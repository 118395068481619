import { Modal, Form, global } from "@autoprog/core-client"

import 'select2'
import 'select2/dist/css/select2.min.css'
import FilesManager from '@libs/FilesManager'
import toastr from 'toastr'
import moment, { Moment } from 'moment'
import _ from 'lodash'
import T_addEditInputTransaction from '@tpl/modals/weighBridge/addEditInputTransaction.html'
import SelectOrder from '@js/libs/modals/SelectOrders'
import typeList from '@js/libs/lists/typeList'
import Prompt from '@libs/modals/prompt'

import 'daterangepicker'
import 'daterangepicker/daterangepicker.css'

import drpC from '@libs/utils/daterangepickerConfig'
import Order from '@js/types/order'
import Tier from '@js/types/Tiers'
import OrdersService from '@js/libs/services/OrdersService'
import TiersService from '@js/libs/services/TiersService'
import DeliveryNote, { DeliveryNoteQualityCriteria } from '@js/types/delivery-note'
import DeliveryNotesService from '@js/libs/services/DeliveryNotesService'
import TypeDataEvent from '@js/libs/lists/TypeDataEvent'
import { SiteStorage } from '@js/types/site'
import SitesService from '@js/libs/services/SitesService'
import EventsManager, { EventsManagerEvent } from '@js/libs/EventsManager'
import { SearchOptions } from "select2"


interface AddEditInputTransactionDeliveryNoteData extends DeliveryNote {
	orderData: AddEditInputTransactionOrderData
}

interface AddEditInputTransactionOrderData extends Order {
	customerData: Tier
	providerData: Tier
}

class AddEditInputTransaction extends Modal {

	private form: Form | null = null;
	private filesManager: FilesManager = new FilesManager();
	private idDeliveryNote: string | null = null;
	// private data: { [key: string]: any } = {};
	private products: any[] = [];

	private dataOrder?: AddEditInputTransactionOrderData;

	constructor(id?: string) {

		super({
			tpl: T_addEditInputTransaction,
			backdrop: 'static',
			keyboard: false
		})

		this.on('opened', async () => {

			const N_form = this.element.querySelector('#inputTransaction-form') as HTMLFormElement
			const N_attachments = this.element.querySelector('#attachments') as HTMLDivElement
			const N_save = this.element.querySelector('#save') as HTMLButtonElement

			this.form = new Form(N_form)

			N_attachments.appendChild(this.filesManager.getNode())

			const storages = await this.getStorages()

			const N_selectStorage = this.element.querySelector('#storage') as HTMLSelectElement;

			const N_option = new Option('', '')
			N_selectStorage.append(N_option)

			for (let storage of storages) {
				if (storage.type == 'box') {
					const N_option = new Option(storage.name, storage.id)
					N_selectStorage.append(N_option)
				}
			}

			const N_addOrder = this.element.querySelector('#add-order') as HTMLButtonElement

			N_addOrder.addEventListener('click', async () => {

				this.hide()

				try {
					let idOrder = await (new SelectOrder('InputTransaction')).open()
					this.show()

					const order = await this.getOrder(idOrder)
					this.dataOrder = order

					order.type = typeList[order.type]

					this.form && this.form.setData({ order })

					this.products = order.products || []

					const N_loadingZone = this.element.querySelector('#loadingZone') as HTMLSelectElement
					const N_addLoadingZone = this.element.querySelector('#add-loading-zone') as HTMLButtonElement

					N_addLoadingZone.disabled = false

					for (let place of (order.providerData.places || [])) {
						const N_option = new Option(place.name, place.name)
						N_loadingZone.append(N_option)
					}

				}
				catch (e)
				{
					this.show()
				}
			})

			N_save.addEventListener('click', () => {
				this.form && this.form.checkValidity() && this.save()
			})

			let N_addLoadingZone = this.element.querySelector('#add-loading-zone') as HTMLElement;
			let N_selectLoadingZone = this.element.querySelector('#loadingZone') as HTMLSelectElement;

			N_addLoadingZone.addEventListener2('click', async () => {

				const name = await (new Prompt('Ajout d\'une commune')).open()

				if (name && this.dataOrder) {
					const tierId = this.dataOrder.type == 'input' ? this.dataOrder.customerData._id : this.dataOrder.providerData._id
					TiersService.getInstance().addPlace(tierId, { name })
					N_selectLoadingZone.appendChild(new Option(name, name, true, true))
				}
			})

			const N_date = this.element.querySelector('[name="date"]') as HTMLElement

			$(N_date).daterangepicker(drpC({
				singleDatePicker: true,
				timePicker: true,
				timePicker24Hour: true,
				locale: {
					format: 'DD/MM/YYYY HH:mm'
				}
			}));

			this.lastValueTransport()

			if (id) {

				this.idDeliveryNote = id

				const deliveryNote = await this.getData()

				this.dataOrder = deliveryNote.orderData

				deliveryNote.orderData.type = typeList[deliveryNote.orderData.type]

				this.products = [{
					...deliveryNote.orderData.products![0], // TODO: faire attention
					...deliveryNote.products[0]
				}]

				deliveryNote._attachments && this.filesManager.setFiles(deliveryNote._attachments)

				const N_loadingZone = this.element.querySelector('#loadingZone') as HTMLSelectElement;
				const N_addLoadingZone = this.element.querySelector('#add-loading-zone') as HTMLButtonElement;

				N_addLoadingZone.disabled = false;

				for (let place of (deliveryNote.orderData.providerData.places || [])) {
					const N_option = new Option(place.name, place.name);
					N_loadingZone.append(N_option);
				}

				this.form && this.form.setData({
					date: moment(deliveryNote.date, 'x'),
					order: deliveryNote.orderData as any,
					qualityCriteria: deliveryNote.products[0].qualityCriteria as any,
					immatriculation: {
						id: deliveryNote.immatriculation,
						text: deliveryNote.immatriculation,
					},
					driver: {
						id: deliveryNote.driver,
						text: deliveryNote.driver,
					},
					state: deliveryNote.state,
					carrier: {
						id: deliveryNote.carrier,
						text: deliveryNote.carrier,
					},
					storage: deliveryNote.products[0].storage,
					transportType: deliveryNote.transportType,
					loadingZone: deliveryNote.loadingZone,
					place: deliveryNote.place,
					parcel: {
						id: deliveryNote.parcel,
						text: deliveryNote.parcel
					},
					comment: deliveryNote.comment,
					observations: deliveryNote.comment,
					weights: deliveryNote.weights ? JSON.stringify(deliveryNote.weights) : ""
				})

			}
			else {
				this.form && this.form.setData({
					date: moment(),
					state: 'notArchived'
				})
			}

		})

	}

	private async save() {

		if (this.form && this.form.checkValidity()) {

			const data = this.form.getData()

			const deliveryNumber = this.idDeliveryNote || 'BL-' + (data.date as Moment).format('YYYY-MM-DD-HHmmSS')

			const deliveryNote: DeliveryNote = {
				_id: deliveryNumber,
				carrier: data.carrier as string,
				comment: data.observations as string,
				isTransaction: true,
				date: +(data.date as Moment).format('x'),
				state: data.state as string,
				deleted: false,
				deliveryNumber: deliveryNumber,
				deliveryReceiver: "",
				driver: data.driver as string,
				immatriculation: data.immatriculation as string,
				order: (data.order as Order).orderNumber,
				loadingZone: data.loadingZone as string,
				parcel: data.parcel as string,
				place: data.place as string,
				products: [],
				transportType: data.transportType as string,
				weights: data.weights && (data.weights as { length: number }).length > 0 ? JSON.parse(data.weights as any) : undefined // TODO: revoir cette ligne
			}

			if (this.products && this.products.length > 0) {
				deliveryNote.products.push({
					id: this.products[0].id,
					reference: this.products[0].reference,
					deliveredQuantity: this.products[0].deliveredQuantity,
					orderedQuantity: this.products[0].quantity,
					qualityCriteria: data.qualityCriteria as DeliveryNoteQualityCriteria,
					rowID: this.products[0].rowID,
					storage: data.storage as string,
					unit: this.products[0].unit,
					unitPrice: this.products[0].unitPrice
				})
			}

			try {
				const addOrUpdate = this.idDeliveryNote ? this.updatedDelivery : this.addDelivery
				await addOrUpdate(deliveryNote)

				await OrdersService.getInstance().updateOrder(this.dataOrder!._id)

				await DeliveryNotesService.getInstance().syncAttachments(deliveryNote._id, this.filesManager.getArrayFiles())
				toastr.success('Bon de livraison sauvegardé')
				this.resolve()
			}
			catch (e) {
				console.error(e)
				toastr.error('Erreur de base de données')
				this.reject(e)
			}
		}

	}

	private async updatedDelivery(deliveryNote: DeliveryNote): Promise<void> {
		const oldDeliveryNote = await DeliveryNotesService.getInstance().getByID(deliveryNote._id)
		deliveryNote._attachments = oldDeliveryNote._attachments
		deliveryNote.events = oldDeliveryNote.events

		const event: EventsManagerEvent = {
			type: 'edit',
		}

		if (oldDeliveryNote.state != deliveryNote.state) {
			event.changeState = {
				from: oldDeliveryNote.state,
				to: deliveryNote.state
			}
		}

		deliveryNote = EventsManager.addEvent(deliveryNote, event);
		await DeliveryNotesService.getInstance().update(deliveryNote)
	}

	private async addDelivery(deliveryNote: DeliveryNote): Promise<void> {
		deliveryNote = EventsManager.addEvent(deliveryNote, TypeDataEvent.create)
		await DeliveryNotesService.getInstance().create(deliveryNote)
	}

	private lastValueTransport() {

		const createTag = (params: SearchOptions) => {
			const term = params.term.trim()
			return term === '' ? null : {
				id: term,
				text: term
			}
		}

		const N_parcel = this.element.querySelector('[name="parcel"]') as HTMLSelectElement;
		const N_carrier = this.element.querySelector('[name="carrier"]') as HTMLSelectElement;
		const N_driver = this.element.querySelector('[name="driver"]') as HTMLSelectElement;
		const N_immatriculation = this.element.querySelector('[name="immatriculation"]') as HTMLSelectElement;

		$(N_parcel).select2({
			ajax: { url: `/api/delivery-notes/search/${global.SITE}/parcel` },
			dropdownParent: $(N_parcel.parentElement as HTMLElement),
			tags: true,
			createTag
		});

		$(N_carrier).select2({
			ajax: { url: `/api/delivery-notes/search/${global.SITE}/carrier` },
			dropdownParent: $(N_carrier.parentElement as HTMLElement),
			tags: true,
			createTag
		});

		$(N_driver).select2({
			ajax: { url: `/api/delivery-notes/search/${global.SITE}/driver` },
			dropdownParent: $(N_driver.parentElement as HTMLElement),
			tags: true,
			createTag
		});

		$(N_immatriculation).select2({
			ajax: { url: `/api/delivery-notes/search/${global.SITE}/immatriculation` },
			dropdownParent: $(N_immatriculation.parentElement as HTMLElement),
			tags: true,
			createTag
		});

	}

	private async getOrder(id: string): Promise<AddEditInputTransactionOrderData> {
		const order = await OrdersService.getInstance().getByID(id)
		const [customerData, providerData] = await Promise.all([
			TiersService.getInstance().getByID(order.customer), 
			TiersService.getInstance().getByID(order.provider)
		])

		return {
			...order,
			customerData,
			providerData
		}
	}

	private async getStorages(): Promise<SiteStorage[]> {
		return SitesService.getInstance().getByID(global.SITE).then(site => site.storages)
	}

	private async getData(): Promise<AddEditInputTransactionDeliveryNoteData> {
		const deliveryNote = await DeliveryNotesService.getInstance().getByID(this.idDeliveryNote!)
		const orderData = await this.getOrder(deliveryNote.order)

		return {
			...deliveryNote,
			orderData
		}
	}

}


export default AddEditInputTransaction;


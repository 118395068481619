export const DefinitionTypesList: { [type: string]: { id: string, displayName: string } } = {
	"text": {
		id: "text",
		displayName: "Texte",
	},
	"textarea": {
		id: "textarea",
		displayName: "Texte long"
	},
	"number": {
		id: "number",
		displayName: "Nombre"
	},
	"date": {
		id: "date",
		displayName: "Date"
	},
	"time": {
		id: "time",
		displayName: "Temps"
	},
	"checkbox": {
		id: "checkbox",
		displayName: "Case à cocher"
	},
	"select": {
		id: "select",
		displayName: "Liste déroulante"
	}
}


export default DefinitionTypesList

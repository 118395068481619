import { global, Controller, utils, LoggedUser } from "@autoprog/core-client"

import TabsExtended from '@libs/TabsExtended'

import C_Alimentation from '@libs/tabs/exploitation/Alimentation'
import C_Digestats from '@libs/tabs/exploitation/Digestats'
import C_Checklist from '@libs/tabs/exploitation/Checklist'
import C_Stock from '@libs/tabs/exploitation/Stock'
import C_Data from '@libs/tabs/exploitation/OperatingData'

import T_Alimentation from '@tpl/tabs/exploitation/alimentation.html'
import T_Digestats from '@tpl/tabs/exploitation/digestats.html'
import T_Checklist from '@tpl/tabs/exploitation/checkList.html'
import T_Stock from '@tpl/tabs/exploitation/stock.html'
import T_Data from '@tpl/tabs/exploitation/operatingData.html'

class ExploitationCtrl extends Controller {

	private tabs: TabsExtended

	constructor(private el: HTMLElement, params: object) {
		super(el, params)

		const loggedUser = LoggedUser.getInstance();

		this.tabs = new TabsExtended({
			navElement: this.el.querySelector('#exploitation-menu') as HTMLElement,
			renderElement: this.el.querySelector('.exploitation-content') as HTMLElement,
			navTemplate: '<button permission="${tab.data.permission}" tab-link="${tab.id}" class="btn btn-tab btn-grey-300">${tab.name}</button>',
		});

		this.tabs.on('tab.show', () => {
			const activeTab = this.tabs.getActiveTab() as any;

			if (activeTab && activeTab.instance && activeTab.instance.updateSelectSite) {
				activeTab.instance.updateSelectSite(global.SITE);
			}
		})

		this.tabs.add({
			name: 'Alimentation',
			controller: C_Alimentation,
			template: T_Alimentation,
			data: {
				permission: 'EXPLOITATION.ALIMENTATION.SHOW'
			},
			default:
				loggedUser.hasPermission('EXPLOITATION.ALIMENTATION.SHOW'),
		});

		this.tabs.add({
			name: 'Stock',
			controller: C_Stock,
			template: T_Stock,
			data: {
				permission: 'EXPLOITATION.STOCK.SHOW'
			},
			default:
				!loggedUser.hasPermission('EXPLOITATION.ALIMENTATION.SHOW') &&
				loggedUser.hasPermission('EXPLOITATION.STOCK.SHOW'),
		})

		this.tabs.add({
			id: 'checklist',
			name: 'Checklist',
			controller: C_Checklist,
			template: T_Checklist,
			data: {
				permission: 'EXPLOITATION.CHECKLIST.SHOW'
			},
			default:
				!loggedUser.hasPermission('EXPLOITATION.ALIMENTATION.SHOW') &&
				!loggedUser.hasPermission('EXPLOITATION.STOCK.SHOW') &&
				loggedUser.hasPermission('EXPLOITATION.CHECKLIST.SHOW'),
		})

		this.tabs.add({
			name: "Données d'expl.",
			id: 'listData',
			controller: C_Data,
			template: T_Data,
			data: {
				permission: 'EXPLOITATION.OPERATING_DATA.SHOW',
				title: "Données d'exploitation",
				type: 'list'
			},
			default:
				!loggedUser.hasPermission('EXPLOITATION.ALIMENTATION.SHOW') &&
				!loggedUser.hasPermission('EXPLOITATION.STOCK.SHOW') &&
				!loggedUser.hasPermission('EXPLOITATION.CHECKLIST.SHOW') &&
				!loggedUser.hasPermission('EXPLOITATION.BUILDER_DATA.SHOW') &&
				loggedUser.hasPermission('EXPLOITATION.OPERATING_DATA.SHOW'),
		})

		this.tabs.add({
			name: "Données Const.",
			id: 'importedData',
			controller: C_Data,
			template: T_Data,
			data: {
				permission: 'EXPLOITATION.IMPORTED_DATA.SHOW',
				type: 'imported',
				title: "Données Constructeurs",
				permissions: {
					import: 'EXPLOITATION.IMPORTED_DATA.IMPORT'
				}
			},
			default:
				!loggedUser.hasPermission('EXPLOITATION.ALIMENTATION.SHOW') &&
				!loggedUser.hasPermission('EXPLOITATION.STOCK.SHOW') &&
				!loggedUser.hasPermission('EXPLOITATION.CHECKLIST.SHOW') &&
				!loggedUser.hasPermission('EXPLOITATION.BUILDER_DATA.SHOW') &&
				!loggedUser.hasPermission('EXPLOITATION.OPERATING_DATA.SHOW') &&
				loggedUser.hasPermission('EXPLOITATION.IMPORTED_DATA.SHOW')
		})

		this.tabs.add({
			name: 'Digestats',
			controller: C_Digestats,
			template: T_Digestats,
			data: {
				permission: 'EXPLOITATION.DIGESTAT.SHOW'
			},
			default:
				!loggedUser.hasPermission('EXPLOITATION.ALIMENTATION.SHOW') &&
				!loggedUser.hasPermission('EXPLOITATION.STOCK.SHOW') &&
				!loggedUser.hasPermission('EXPLOITATION.CHECKLIST.SHOW') &&
				!loggedUser.hasPermission('EXPLOITATION.BUILDER_DATA.SHOW') &&
				!loggedUser.hasPermission('EXPLOITATION.OPERATING_DATA.SHOW') &&
				loggedUser.hasPermission('EXPLOITATION.DIGESTAT.SHOW'),
		})

		const urlQuery = utils.getQuery() as any;

		if (urlQuery.checklist) {
			setTimeout(() => {
				this.tabs.showTab('checklist')
			})
		}

		this.siteSelect()
	}

	private siteSelect() {

		setTimeout(() => {

			const N_siteSelect = this.el.querySelector('#site-select') as HTMLSelectElement;

			N_siteSelect.addEventListener('input', () => {


				global.SITE = N_siteSelect.value;

				let activeTab = this.tabs.getActiveTab() as any;

				if (activeTab && activeTab.instance && activeTab.instance.updateSelectSite) {
					activeTab.instance.updateSelectSite(global.SITE)
				}

			})

		})

	}

	public destructor() {
		const N_daterangepicker = document.querySelectorAll('.daterangepicker')
		N_daterangepicker.forEach(item => item.remove())
		this.tabs.destructor()
	}
}

export default ExploitationCtrl;

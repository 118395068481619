module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<td>\r\n    <input readonly class="form-control-plaintext w-100 custom" value="' +
((__t = (orderProduct.reference)) == null ? '' : __t) +
'">\r\n</td>\r\n<td>\r\n    <input readonly class="form-control-plaintext w-100 custom" value="' +
((__t = (orderProduct.accountingAccount)) == null ? '' : __t) +
'">\r\n</td>\r\n<td>\r\n    <input readonly class="form-control-plaintext w-100 custom" value="' +
((__t = (orderProduct.analytics)) == null ? '' : __t) +
'">\r\n</td>\r\n<td>\r\n    <input readonly class="form-control-plaintext w-100 custom" value="' +
((__t = (orderProduct.subAnalytics)) == null ? '' : __t) +
'">\r\n</td>\r\n<td>\r\n    <input readonly class="form-control-plaintext w-100 custom" value="' +
((__t = (unitList[orderProduct.unit].name)) == null ? '' : __t) +
'">\r\n</td>\r\n<td>\r\n    <input readonly class="form-control-plaintext w-100 custom" type="number" value="' +
((__t = (orderProduct.quantity)) == null ? '' : __t) +
'">\r\n</td>\r\n<td>\r\n    <input readonly class="form-control-plaintext w-100 custom" type="number" value="' +
((__t = (orderProduct.unitPrice)) == null ? '' : __t) +
'">\r\n</td>\r\n<td>\r\n    <span> ' +
((__t = (totalPrice)) == null ? '' : __t) +
' </span>€\r\n</td>\r\n';

}
return __p
}
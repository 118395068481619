import { ChecklistData } from "@js/libs/utils/table-checklist.helper"
import TableChecklistRow, { TableChecklistStructure } from "./TableChecklistRow"

import T_tableChecklist from "../../../../tpl/components/table-checklist.html"
import _ from 'lodash'

import '@css/checklist.scss'

class TableCheckList extends HTMLElement {
	public static readonly tagName = 'table-checklist'

	private _deleted: Array<ChecklistData>
	private _unloaded: Array<ChecklistData>
	private _isDrawing: boolean

	constructor() {
		super()

		this.style.display = 'block'
		this.innerHTML = T_tableChecklist
		this._deleted = []
		this._isDrawing = false
		// Ce tableau contient les donnees qui n'ont pas encore ete charge, on redessine pour plus tard, surtout important pour le chargement, le set values gere tres mal le drawing
		// REMARK, possible de bypasser en utiliser une fonction classique au lieu d'un setter
		this._unloaded = []
	}

	set structures(values: Array<TableChecklistStructure>) {
		this.draw(values)
	}

	get values(): Array<ChecklistData> {
		return this.rows().filter(row => row.type === 'input').map(row => row.value)
	}

	set values(data: Array<ChecklistData>) {
		this.fill(data)
	}

	get rejected(): Array<ChecklistData> {
		return this._deleted
	}

	get percent(): number {
		const lengthNotEmpty = this.values.filter(data => !_.isNil(data.value) && data.value !== "").length
		return lengthNotEmpty / (this.values.length || 1) * 100
	}

	private async draw(structures: Array<TableChecklistStructure>) {
		this._isDrawing = true
		this.innerHTML = T_tableChecklist
		const N_table = this.querySelector('table') as HTMLTableElement

		const documentFragment = document.createDocumentFragment()
		let N_body = document.createElement('tbody') as HTMLElement

		const inputs = await this.generateInputs(structures)
		inputs.forEach((node, index) => {
			if (node.type === 'spacer') {
				if (N_body.childNodes.length > 0) {
					documentFragment.appendChild(N_body)
				}
				documentFragment.appendChild(node)
				N_body = document.createElement('tbody') as HTMLElement
			}
			else {
				if (node.type !== 'input') {
					const nodeValue = node.value
					nodeValue.value = Reflect.get(node.structure, 'value')
					node.value = nodeValue
				}
				else {
					node.tabIndex = index
				}
				N_body.appendChild(node)
			}
		})

		N_body.hasChildNodes() && documentFragment.appendChild(N_body)

		N_table.appendChild(documentFragment)
		this._isDrawing = false
		this._unloaded.length && this.fill(this._unloaded)
	}

	private async generateInputs(structures: Array<TableChecklistStructure>): Promise<Array<TableChecklistRow>> {
		return Promise.all(structures.map(structure => {
			const N_row = TableChecklistRow.createElement()
			N_row.structure = structure
			return Promise.resolve(N_row)
		}))
	}

	private rows(): Array<TableChecklistRow> {
		return Array.from(this.querySelectorAll(`tr[data-is="${TableChecklistRow.tagName}"]`))
	}

	private async fill(checklists: Array<ChecklistData>) {

		if (this._isDrawing) {
			this._unloaded = checklists
			return
		}

		this._unloaded = []
		const rows = this.rows().filter(N_row => N_row.type === 'input')

		const availableDefinitionsIds: Array<string> = []
		await Promise.all(rows.map(N_row => {
			// TODO: gerer le systeme de tags pour avoir des donnees uniques avec le meme operating data definition
			const matchedData = checklists.find(checklist => checklist.definition === N_row.definition?.id || '')

			if (matchedData) {
				N_row.value = matchedData
				availableDefinitionsIds.push(matchedData.definition)
			}

			return Promise.resolve(N_row)
		}))
		this._deleted = checklists.filter(checklist => !availableDefinitionsIds.includes(checklist.definition))
	}

	public static register() {
		customElements.define(TableCheckList.tagName, TableCheckList)
		TableChecklistRow.register()
	}

	public checkValidity() {
		return _.compact(this.rows().map(node => !node.checkValidity())).length <= 0
	}
}


export default TableCheckList

module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="modal mfade no-scroll" role="dialog">\r\n    <div class="modal-dialog modal-fullscreen" id="modal-more-options" role="document">\r\n\r\n        <div class="modal-content">\r\n\r\n            <div class="d-flex h-100 flex-column">\r\n\r\n                <div class="bg-success header">\r\n\r\n                    <div class="btn-group w-100">\r\n\r\n                        <button class="btn btn-success w-50" id="save">\r\n                            <i class="icon icon-save"></i>\r\n                            Sauvegarder\r\n                        </button>\r\n                        <button class="btn btn-success w-50" data-reject="modal">\r\n                            <i class="icon icon-solid-times"></i>\r\n                            Annuler\r\n                        </button>\r\n                    \r\n                    </div>\r\n\r\n                </div>\r\n\r\n                <div class="flex-grow-1 fix-container m-2 scroll-y">\r\n\r\n                    <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">\r\n                        <li class="nav-item">\r\n                            <a class="nav-link active" data-toggle="tab" href="#infoLayout" role="tab">Information</a>\r\n                        </li>\r\n                        <li class="nav-item">\r\n                            <a class="nav-link" data-toggle="tab" href="#repeatLayout" role="tab">Répétition</a>\r\n                        </li>\r\n                    </ul>\r\n                    <div class="tab-content" id="myTabContent">\r\n                        <div class="tab-pane fade show active" id="infoLayout" role="tabpanel">\r\n                            <div class="pb-3 border-bottom border-green">\r\n                        \r\n                                <div class="my-1"> Titre </div>\r\n                                <input type="text" class="form-control" id="title" placeholder="Titre" value="' +
((__t = (data.title)) == null ? '' : __t) +
'">\r\n                            </div>\r\n\r\n                            <div class="pb-3 border-bottom border-green">\r\n\r\n                                <div class="my-1"> Agenda </div>\r\n\r\n                                <div class="input-group" id="site-selected">\r\n\r\n                                    <site-select class="form-control"></site-select>\r\n\r\n                                    <div class="dropdown w-50" id="dropdown-calendar">\r\n\r\n                                        <button class="form-control text-left" id="button-dropdown-calendar" data-calendar-id="' +
((__t = (selectedCal._id)) == null ? '' : __t) +
'">\r\n                                            <span class="tui-full-calendar-icon tui-full-calendar-calendar-dot" style="background-color: ' +
((__t = (selectedCal.bgColor)) == null ? '' : __t) +
';"></span>\r\n                                            <span id="calendar-name">' +
((__t = (selectedCal.name)) == null ? '' : __t) +
'</span>\r\n                                            <span class="tui-full-calendar-icon tui-full-calendar-dropdown-arrow"></span>\r\n                                        </button>\r\n                                        \r\n                                        ';
for(let site in calendarList){;
__p += '\r\n\r\n                                            <ul class="dropdown-menu" id="list-dropdown-calendar" data-site="' +
((__t = (site)) == null ? '' : __t) +
'" style="display:none;">\r\n\r\n                                                ';
for(let category in calendarList[site]){;
__p += '\r\n\r\n                                                    ';
if(category){;
__p += '\r\n                                                        <li class="dropdown-item">\r\n                                                            <span>\r\n                                                                <i class="icon icon-solid-chevron-right d-inline-block"></i>\r\n                                                                ' +
((__t = (category)) == null ? '' : __t) +
'\r\n                                                            </span>\r\n                                                        </li>\r\n                                                        <ul>\r\n                                                    ';
};
__p += '\r\n\r\n                                                    ';
for(let calendar of calendarList[site][category]){;
__p += '\r\n                                                        <li class="dropdown-item" data-calendar-id="' +
((__t = (calendar._id)) == null ? '' : __t) +
'">\r\n                                                            <span class="tui-full-calendar-icon tui-full-calendar-calendar-dot" style="background-color: ' +
((__t = (calendar.bgColor)) == null ? '' : __t) +
';"></span>\r\n                                                            <span id="calendar-name">' +
((__t = (calendar.name)) == null ? '' : __t) +
'</span>\r\n                                                        </li>\r\n                                                    ';
};
__p += '\r\n\r\n                                                    ';
if(category){;
__p += '\r\n                                                        </ul>\r\n                                                    ';
};
__p += '\r\n                                            \r\n                                                ';
};
__p += '\r\n\r\n                                            </ul>\r\n\r\n                                        ';
};
__p += '\r\n\r\n                                    </div>\r\n\r\n                                </div>\r\n                            \r\n                            </div>\r\n\r\n                            <div class="pb-3 border-bottom border-green">\r\n                            \r\n                                <div class="my-1">  </div>\r\n\r\n                                <div class="w-100">\r\n                                    Date début : \r\n                                    <input type="datetime-local" class="form-control" id="start-date" placeholder="Date début">\r\n                                </div>\r\n                                <div class="w-100">\r\n                                    Date fin : \r\n                                    <input type="datetime-local" class="form-control" id="end-date" placeholder="Date fin">\r\n                                </div>\r\n                                <div class="custom-control custom-checkbox mt-2 w-100">\r\n                                    <input type="checkbox" class="custom-control-input" id="all-days" ' +
((__t = (data.isAllDay ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                    <label class="custom-control-label ml-3" for="all-days">Journée entière</label>\r\n                                </div>\r\n\r\n                            </div>\r\n\r\n                            <div class="pb-3 border-bottom border-green">\r\n                                <div class="my-1"> Description </div>\r\n                                <textarea class="form-control" id="description" placeholder="Description">' +
((__t = (data.description)) == null ? '' : __t) +
'</textarea>\r\n                            </div>\r\n\r\n                            <div class="pb-3">\r\n                                <div class="my-1"> Intervenants </div>                        \r\n                                <ul id="users" class="list-group scroll-x">\r\n                                    <li class="list-group-item p-0">\r\n                                        <select class="form-control" multiple id="select-list-users"></select>\r\n                                    </li>\r\n                                </ul>\r\n                            </div>\r\n\r\n                        </div>\r\n\r\n                        <div class="tab-pane fade" id="repeatLayout" role="tabpanel">\r\n\r\n                            <div class="my-2">\r\n                                <div class="custom-control custom-checkbox">\r\n                                    <input type="checkbox" class="custom-control-input" id="enableRepeat" ' +
((__t = (!repeat.notEnabled ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                    <label class="custom-control-label" for="enableRepeat">Activer</label>\r\n                                </div>\r\n                            </div>\r\n\r\n                            <div id="container-repeat">\r\n                                \r\n                                <div>\r\n                                    <span class="text-underline">Fréquence </span>\r\n\r\n                                    <div class="ml-2 mt-2">\r\n                                        \r\n                                        <div class="d-inline-flex">\r\n                                            <span class="mt-1 mr-1">\r\n                                                &bull;\r\n                                            </span>\r\n                                            <span class="mt-1 w-50">\r\n                                                Répéter tout(es) les \r\n                                            </span>\r\n                                            <div class="input-group form-group w-50">\r\n                                                <input class="w-25 form-control" id="value-frequency" type="number" value="' +
((__t = (repeat.frequency.value)) == null ? '' : __t) +
'">\r\n                                                <select class="w-25 form-control" id="type-frequency">\r\n                                                    <option value="day"   ' +
((__t = (repeat.frequency.type == 'day'   ? 'selected' : '')) == null ? '' : __t) +
'>jour(s)</option>\r\n                                                    <option value="week"  ' +
((__t = (repeat.frequency.type == 'week'  ? 'selected' : '')) == null ? '' : __t) +
'>semaine(s)</option>\r\n                                                    <option value="month" ' +
((__t = (repeat.frequency.type == 'month' ? 'selected' : '')) == null ? '' : __t) +
'>mois</option>\r\n                                                    <option value="year"  ' +
((__t = (repeat.frequency.type == 'year'  ? 'selected' : '')) == null ? '' : __t) +
'>année(s)</option>\r\n                                                </select>\r\n                                            </div>\r\n                                        </div>\r\n\r\n                                        <div id="week-options" class="' +
((__t = (repeat.frequency.type != 'week' ? 'd-none' : '')) == null ? '' : __t) +
'">\r\n                                            &bull; Repeter le :\r\n\r\n                                            ';
let days = repeat.frequency.days || {};
__p += '\r\n                                            <div class="d-inline-flex">\r\n                                                <div class="custom-control custom-checkbox mt-2">\r\n                                                    <input type="checkbox" class="custom-control-input" id="monday"    ' +
((__t = (days.monday ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                                    <label class="custom-control-label" for="monday">Lundi</label>\r\n                                                </div>\r\n                                                <div class="custom-control custom-checkbox mt-2 ml-2">\r\n                                                    <input type="checkbox" class="custom-control-input" id="tuesday"   ' +
((__t = (days.tuesday ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                                    <label class="custom-control-label" for="tuesday">Mardi</label>\r\n                                                </div>\r\n                                                <div class="custom-control custom-checkbox mt-2 ml-2">\r\n                                                    <input type="checkbox" class="custom-control-input" id="wednesday" ' +
((__t = (days.wednesday ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                                    <label class="custom-control-label" for="wednesday">Mercredi</label>\r\n                                                </div>\r\n                                            </div>\r\n                                            <div class="d-inline-flex">\r\n                                                <div class="custom-control custom-checkbox mt-2 ml-2">\r\n                                                    <input type="checkbox" class="custom-control-input" id="thursday"  ' +
((__t = (days.thursday ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                                    <label class="custom-control-label" for="thursday">Jeudi</label>\r\n                                                </div>\r\n                                                <div class="custom-control custom-checkbox mt-2 ml-2">\r\n                                                    <input type="checkbox" class="custom-control-input" id="friday"    ' +
((__t = (days.friday ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                                    <label class="custom-control-label" for="friday">Vendredi</label>\r\n                                                </div>\r\n                                                <div class="custom-control custom-checkbox mt-2 ml-2">\r\n                                                    <input type="checkbox" class="custom-control-input" id="saturday"  ' +
((__t = (days.saturday ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                                    <label class="custom-control-label" for="saturday">Samedi</label>\r\n                                                </div>\r\n                                                <div class="custom-control custom-checkbox mt-2 ml-2">\r\n                                                    <input type="checkbox" class="custom-control-input" id="sunday"    ' +
((__t = (days.sunday ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                                    <label class="custom-control-label" for="sunday">Dimanche</label>\r\n                                                </div>\r\n                                                \r\n                                            </div>\r\n                                            \r\n                                        </div>\r\n\r\n                                    </div>\r\n\r\n                                </div>\r\n\r\n                                <div class="mt-2">\r\n                                    <span class="text-underline">Se termine</span>\r\n                                    <div class="ml-2">\r\n                                        <div class="custom-control custom-radio my-2 ' +
((__t = (!repeat.end ? '' : 'text-muted')) == null ? '' : __t) +
'">\r\n                                            <input type="radio" id="never" name="lastuse" class="custom-control-input" ' +
((__t = (!repeat.end ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                            <label class="custom-control-label" for="never">&nbsp;</label>\r\n                                            Jamais\r\n                                        </div>\r\n                                        <div class="custom-control custom-radio my-2 ' +
((__t = (repeat.end.date ? '' : 'text-muted')) == null ? '' : __t) +
'">\r\n                                            <input type="radio" id="inDate" name="lastuse" class="custom-control-input" ' +
((__t = (repeat.end.date ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                            <label class="custom-control-label" for="inDate">&nbsp;</label>\r\n                                            <div class="d-inline-flex">\r\n                                                <span class="mt-1">Le </span>\r\n                                                <input type="date" data-type="value" id="date-end-repeat" class="form-control d-inline-flex mx-2" disabled>\r\n                                            </div>\r\n                                        </div>\r\n                                        <div class="custom-control custom-radio my-2 ' +
((__t = (repeat.end.times ? '' : 'text-muted')) == null ? '' : __t) +
'" style="white-space:nowrap;">\r\n                                            <input type="radio" id="times" name="lastuse" class="custom-control-input" ' +
((__t = (repeat.end.times ? 'checked' : '')) == null ? '' : __t) +
'>\r\n                                            <label class="custom-control-label" for="times">&nbsp;</label>\r\n                                            <div class="d-inline-flex">\r\n                                                <span class="mt-1">Après </span>\r\n                                                <input type="number" data-type="value" class="form-control d-inline-flex mx-2 w-25" id="times-repeat" disabled value="' +
((__t = (repeat.end.times)) == null ? '' : __t) +
'">\r\n                                                <span class="mt-1">occurrences </span>\r\n                                            </div>\r\n                                        </div>\r\n\r\n                                    </div>\r\n\r\n                                </div>\r\n\r\n                            </div>\r\n\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>';

}
return __p
}
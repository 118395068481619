import { Tab, global, utils } from "@autoprog/core-client"

import C_ChecklistTab from '@libs/tabs/exploitation/ChecklistTab'
import T_ChecklistTab from '@tpl/tabs/exploitation/checkListTab.html'

import AddEditCheckListModelModal from '@libs/modals/exploitation/AddEditCheckListModel2'
import toastr from 'toastr'

import TabsExtended from '@libs/TabsExtended'
import ChecklistModelsService from '@js/libs/services/ChecklistModelsService'
import CheckListModelsManager from '@js/libs/managers/CheckListModelsManager'

import _ from 'lodash'

class Checklist extends Tab {

	constructor(private el: HTMLElement) {
		super(el)

		const N_add = this.el.querySelector('#add-checklist-btn') as HTMLButtonElement

		N_add.addEventListener('click', () => {
			const modal = new AddEditCheckListModelModal()
			modal.open().finally(() => this.createTabs())
		})

		this.createTabs()
	}

	private async createTabs(): Promise<void> {
		const checklists = await ChecklistModelsService.getInstance().getBySite(global.SITE).then(lists => _.sortBy(lists, 'name'))

		const navElement = this.el.querySelector('#checklists-list') as HTMLElement
		const renderElement = this.el.querySelector('#checklist-content') as HTMLElement

		const tabs = new TabsExtended({
			navElement,
			renderElement,
			navTemplate: `<li tab-link="\${tab.id}" class="list-group-item d-flex justify-content-between align-items-center">\${tab.name} 
            <div class="btn-group">
                <button class="btn btn-sm btn-info edit-checklist-btn" permission="EXPLOITATION.CHECKLIST_MODEL.EDIT" data-checklist-id="\${tab.data}">
                    <i class="icon icon-solid-pen"></i>
                </button>    
                <button class="btn btn-sm btn-danger delete-checklist-btn" confirmation permission="EXPLOITATION.CHECKLIST_MODEL.DELETE" data-checklist-id="\${tab.data}">
                    <i class="icon icon-solid-trash-alt"></i>
                </button>
            </div>
        </li>`
		})

		let d = true
		for (let i in checklists) {

			tabs.add({
				id: checklists[i]._id,
				name: checklists[i].name,
				controller: C_ChecklistTab,
				template: T_ChecklistTab,
				data: checklists[i]._id,
				default: d
			})

			d = false
		}

		// Association du bouton delete
		navElement.querySelectorAll('.delete-checklist-btn').forEach(btn => btn.addEventListener('click', this.deleteBtnEvent.bind(this)));

		// Association du bouton modifier
		Array.from<HTMLButtonElement>(navElement.querySelectorAll('.edit-checklist-btn')).forEach((N_edit) => {
			N_edit.addEventListener('click', () => {

				const id = N_edit.dataset.checklistId

				if (id) {
					const modal = new AddEditCheckListModelModal(id)
					modal.open().finally(() => this.createTabs())
				}
			})
		})

		setTimeout(() => {
			const urlParams = utils.getQuery() as any

			if (urlParams.checklist) {
				const chunks = urlParams.checklist.split('_');
				if (chunks) {
					tabs.showTab(chunks[0])
				}
			}
		})
	}


    /**
     * Evenement sur le bouton supprimer de l'application
     * @param event 
     */
	private async deleteBtnEvent(event: Event) : Promise<void> {
		const btn = event.target as HTMLButtonElement
		const checklistID = btn.dataset.checklistId

		if (checklistID) {
			btn.disabled = true

			toastr.info('Supression de la checklist', '');
			
			await CheckListModelsManager.delete(checklistID)

			toastr.success('Checklist supprimée')
		}

		btn.closest('li')?.remove()
	}

	public updateSelectSite() {
		this.createTabs()
	}

	public destructor(): void {

	}
}


export default Checklist;

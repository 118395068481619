import { Modal, Form, global, LoggedUser } from "@autoprog/core-client"

import moment from 'moment'
import T_addExpenseReport from '../../../../tpl/modals/humanRessources/addExpenseReport.html'
import FilesManager from '../../FilesManager'
import { CouchDBAttachment } from '@js/types/couchDB'
import drpC from '@libs/utils/daterangepickerConfig'
import ExpenseReport from '@js/types/expenseReport'
import BDD from '@js/libs/BDD'

import { ExpenseReportService } from "@js/libs/services"

class AddExpenseReportModal extends Modal {

	private filesManager: FilesManager = new FilesManager();
	private idExpenseReport: string | null = null;
	private attachmentsExpenseReport?: { [key: string]: CouchDBAttachment }
	private form: Form | null = null;
	private N_datePicker: HTMLInputElement | null = null;

	constructor(id?: string) {

		super({
			tpl: T_addExpenseReport,
			backdrop: 'static',
			keyboard: false
		});

		this.on("opened", async () => {

			if (this.element) {
				this.N_datePicker = this.element.querySelector('#date-picker') as HTMLInputElement;
				let N_Save = this.element.querySelector("#save") as HTMLElement;
				let N_attachments = this.element.querySelector("#attachments") as HTMLElement;
				N_attachments.appendChild(this.filesManager.getNode());

				$(this.element).find('#date-picker').daterangepicker(drpC({
					singleDatePicker: true,
					parentEl: '#container-date-picker'
				}), function (_start, _end, _label) {

				});

				let N_form = this.element.querySelector('#add-expense-form') as HTMLFormElement;
				this.form = new Form(N_form);

				N_Save.addEventListener("click", this.saveBtnHandler.bind(this))
				id && this.loadData(id)
			}
		})
	}

	private async saveBtnHandler(_: Event): Promise<void> {
		if (this.form && this.form.checkValidity() && this.N_datePicker) {

			let loggedUser = LoggedUser.getInstance();

			let data = this.form.getData();
			data.date = Number(moment(this.N_datePicker.value, 'DD/MM/YYYY').format('x'));
			data.site = global.SITE;
			data.user = loggedUser.get('ID');


			try {
				let idExpenseReport = await this.addUpdateData(data)
				await ExpenseReportService.getInstance().syncAttachment(idExpenseReport, this.filesManager.getArrayFiles())
			}
			catch (e) {
				console.error(e)
				this.reject()
			}

		}
		this.resolve()
	}

	private async addUpdateData(data: any): Promise<string> {
		let dataToInsertOrUpdate: ExpenseReport = {
			...data,
			_id: this.idExpenseReport ? this.idExpenseReport : BDD.generateID(),
			_attachments: this.attachmentsExpenseReport
		}

		let functionToCall = this.idExpenseReport ? ExpenseReportService.getInstance().update : ExpenseReportService.getInstance().create
		await functionToCall(dataToInsertOrUpdate)
		return dataToInsertOrUpdate._id
	}

	private async loadData(id: string) {
		this.idExpenseReport = id
		let expenseReport = await ExpenseReportService.getInstance().getByID(id)

		this.attachmentsExpenseReport = expenseReport._attachments;

		if (this.form) {
			this.form.setData({
				...expenseReport,
				date: moment(expenseReport.date, 'x')
			} as any);
		}

		if (expenseReport._attachments) {
			this.filesManager.setFiles(expenseReport._attachments);
		}
	}
}

export default AddExpenseReportModal;

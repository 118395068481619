import { ITooltipComp, ITooltipParams } from 'ag-grid-community'
import h from 'hyperscript'

class OrdersTooltips implements ITooltipComp {

	private N_el: HTMLElement;

	public constructor() {
		this.N_el = h('div')
		this.N_el.style.position = 'absolute'
	}


	public init(params: ITooltipParams) {
		let lis: Array<HTMLElement> = []
		
		if (typeof params.value === 'string') {
			lis = ([params.value]).map(order => h('li.list-group-item.py-2', order))
		}
		else {
			lis = (params.value as Array<string> || []).map(order => h('li.list-group-item.py-2', order))
		}
		
		this.N_el.appendChild(h('ul.list-group', ...lis))
	}

	public getGui() {
		return this.N_el
	}

}

export default OrdersTooltips

import { Controller, global, LoggedUser } from "@autoprog/core-client"

import C_TabTransaction from '@libs/tabs/weighBridge/TabTransaction'
import C_TabHistoric from '@libs/tabs/weighBridge/TabHistoric'

import T_TabTransaction from '@tpl/tabs/weighBridge/tabTransaction.html'
import T_TabHistoric from '@tpl/tabs/weighBridge/tabHistoric.html'

import TabsExtended from '@libs/TabsExtended'

class WeighBridgeCtrl extends Controller {

	private tabs: TabsExtended;

	constructor(private el: HTMLElement, params: object) {
		super(el, params)

		let loggedUser = LoggedUser.getInstance();

		this.tabs = new TabsExtended({
			navElement: this.el.querySelector('#weigh-bridge-menu') as HTMLElement,
			renderElement: this.el.querySelector('.weigh-bridge-content') as HTMLElement,
			navTemplate: '<button permission="${tab.data.permission}" tab-link="${tab.id}" class="btn btn-tab btn-grey-300">${tab.name}</button>',
		});

		this.tabs.on('tab.show', () => {
			let activeTab = this.tabs.getActiveTab() as any;

			if (activeTab && activeTab.instance && activeTab.instance.updateSelectSite) {
				activeTab.instance.updateSelectSite(global.SITE);
			}
		})

		this.tabs.add({
			name: 'Transactions',
			controller: C_TabTransaction,
			default: loggedUser.hasPermission('WEIGHBRIDGE.TRANSACTIONS.SHOW'),
			template: T_TabTransaction,
			data: {
				permission: 'WEIGHBRIDGE.TRANSACTIONS.SHOW'
			}
		})

		this.tabs.add({
			name: 'Historique',
			controller: C_TabHistoric,
			default:
				!loggedUser.hasPermission('WEIGHBRIDGE.TRANSACTIONS.SHOW') &&
				loggedUser.hasPermission('WEIGHBRIDGE.HISTORY.SHOW'),

			template: T_TabHistoric,
			data: {
				permission: 'WEIGHBRIDGE.HISTORY.SHOW',
			}
		})

		this.siteSelect()

	}

	private siteSelect() {

		setTimeout(() => {

			let N_siteSelect = this.el.querySelector('#site-select') as HTMLSelectElement;

			N_siteSelect.addEventListener('input', () => {

				global.SITE = N_siteSelect.value;

				let activeTab = this.tabs.getActiveTab() as any;

				if (activeTab && activeTab.instance && activeTab.instance.updateSelectSite) {
					activeTab.instance.updateSelectSite(global.SITE);
				}

			});

		});

	}

	public destructor() {
		this.tabs.destructor()
	}

}

export default WeighBridgeCtrl;

import { Modal, Form, toaster, LoggedUser } from "@autoprog/core-client"

import T_addHoursSheet from '../../../../tpl/modals/humanRessources/detailHoursSheet.html'
import T_statsCarousel from '../../../../tpl/blocks/humanRessources/statsCarouselDetail.ejs'
import AddEditHoursSheetModal from './AddEditHoursSheetModal'
import moment from 'moment'

import HoursCategories from '@js/libs/services/HoursCategories'

import _ from 'lodash'

import { HoursDataType } from '@js/libs/services/HoursService'

import { HoursService, SitesService, UsersService, GroupsService } from "@js/libs/services"

class DetailHoursSheetModal extends Modal {

    /*
     █████╗ ████████╗████████╗██████╗ ██╗██████╗ ██╗   ██╗████████╗
    ██╔══██╗╚══██╔══╝╚══██╔══╝██╔══██╗██║██╔══██╗██║   ██║╚══██╔══╝
    ███████║   ██║      ██║   ██████╔╝██║██████╔╝██║   ██║   ██║
    ██╔══██║   ██║      ██║   ██╔══██╗██║██╔══██╗██║   ██║   ██║
    ██║  ██║   ██║      ██║   ██║  ██║██║██████╔╝╚██████╔╝   ██║
    ╚═╝  ╚═╝   ╚═╝      ╚═╝   ╚═╝  ╚═╝╚═╝╚═════╝  ╚═════╝    ╚═╝
    */

	private data: HoursDataType | null;

	private N_date: HTMLElement | null = null;
	private N_km: HTMLElement | null = null;

	private N_hoursTable: HTMLTableElement | null = null;
	private N_standbyDutyTable: HTMLTableElement | null = null;
	private N_totalHours: HTMLElement | null = null;
	private N_totalStandbyDuties: HTMLElement | null = null;

	private N_comment: HTMLTextAreaElement | null = null;
	// private N_kilometers: HTMLDivElement | null = null;
	private N_carousels: HTMLElement | null = null;
	private statsForm: Form | null = null;
	private loggedUser: LoggedUser;
    /*
     ██████╗ ██████╗ ███╗   ██╗███████╗████████╗██████╗ ██╗   ██╗ ██████╗████████╗███████╗██╗   ██╗██████╗
    ██╔════╝██╔═══██╗████╗  ██║██╔════╝╚══██╔══╝██╔══██╗██║   ██║██╔════╝╚══██╔══╝██╔════╝██║   ██║██╔══██╗
    ██║     ██║   ██║██╔██╗ ██║███████╗   ██║   ██████╔╝██║   ██║██║        ██║   █████╗  ██║   ██║██████╔╝
    ██║     ██║   ██║██║╚██╗██║╚════██║   ██║   ██╔══██╗██║   ██║██║        ██║   ██╔══╝  ██║   ██║██╔══██╗
    ╚██████╗╚██████╔╝██║ ╚████║███████║   ██║   ██║  ██║╚██████╔╝╚██████╗   ██║   ███████╗╚██████╔╝██║  ██║
     ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝  ╚═════╝   ╚═╝   ╚══════╝ ╚═════╝ ╚═╝  ╚═╝

    */
	constructor(id: string) {

		super({
			tpl: T_addHoursSheet,
			backdrop: 'static',
			keyboard: false
		});

		this.loggedUser = LoggedUser.getInstance();
		this.data = null;

		//lorsque la modale est ouverte
		this.on('opened', async () => {

			// this.N_kilometers = this.element.querySelector('#kilometers') as HTMLDivElement;
			this.N_carousels = this.element.querySelector('#statsCarousel') as HTMLElement;
			this.N_comment = this.element.querySelector('#comment') as HTMLTextAreaElement;

			this.statsForm = new Form(this.element.querySelector('#stats-form') as HTMLFormElement)

			if (this.element) {
				this.getHTMLElement()

				const N_buttonEdit = this.element.querySelector("#buttonEdit") as HTMLButtonElement;

				try {
					this.data = await HoursService.getInstance().getByID(id)
					this.generateCarousels().then(() => {
						this.fillFormWithStats()

						if (N_buttonEdit) {

							if (this.data?.user == this.loggedUser.get('ID')) {
								N_buttonEdit.setAttribute('permission', "HUMAN_RESSOURCES.HOURS.EDIT");
							} else {
								N_buttonEdit.setAttribute('permission', "HUMAN_RESSOURCES.HOURS.EDIT_ALL_USERS");
							}


							if (this.data?.locked) {
								//N_buttonEdit.style.display = 'none';
								N_buttonEdit.remove();
							} else {
								N_buttonEdit.addEventListener('click', () => {
									//obliger de vider la modal pour que le "carousel" de la page d'ajout fonctionne 
									this.element.innerHTML = '';

									const modal = new AddEditHoursSheetModal(this.data?._id);
									this.hide()
									modal.open().then(() => this.resolve(true)).catch(() => this.resolve());
								})
							}
						}

						//Insertion des données dans le HTML
						if (this.N_date && this.data) {
							this.N_date.innerHTML = moment(this.data.date, 'x').format('DD/MM/YYYY');
						}

						if (this.N_hoursTable && this.data) {

							for (let hours of this.data.hours) {

								let row = this.N_hoursTable.insertRow();
								row.insertCell().innerHTML = "De";
								row.insertCell().innerHTML = this.humanizeDuration(moment.duration(hours.start, 'ms'));
								row.insertCell().innerHTML = "à";
								row.insertCell().innerHTML = this.humanizeDuration(moment.duration(hours.end, 'ms'));

							}

						}

						if (this.N_standbyDutyTable && this.data) {

							for (let standbyDuty of this.data.standbyDuty) {
								let row = this.N_standbyDutyTable.insertRow();
								row.insertCell().innerHTML = "De";
								row.insertCell().innerHTML = this.humanizeDuration(moment.duration(standbyDuty.start, 'ms'));
								row.insertCell().innerHTML = "à";
								row.insertCell().innerHTML = this.humanizeDuration(moment.duration(standbyDuty.end, 'ms'));
							}
						}

						if (this.N_totalHours && this.data) {
							this.N_totalHours.innerHTML = this.humanizeDuration(moment.duration(this.data.total, 'ms'));
						}

						if (this.N_totalStandbyDuties && this.data) {
							this.N_totalStandbyDuties.innerHTML = this.humanizeDuration(moment.duration(this.data.totalStandbyDutys, 'ms'));
						}


						if (this.N_km && this.data) {
							this.N_km.innerHTML = this.data.kilometers.toString();
						}

						if (this.N_comment && this.data) {
							this.N_comment.innerHTML = this.data.comment;
						}
					}).catch((e) => {
						toaster.error('Erreur lors de génération des statistiques');
						console.error(e);
					})
				}
				catch {
					toastr.error('Erreur de chargement des heures')
				}
			}
		})



	}

    /*
    ███╗   ███╗███████╗████████╗██╗  ██╗ ██████╗ ██████╗ ███████╗
    ████╗ ████║██╔════╝╚══██╔══╝██║  ██║██╔═══██╗██╔══██╗██╔════╝
    ██╔████╔██║█████╗     ██║   ███████║██║   ██║██║  ██║█████╗
    ██║╚██╔╝██║██╔══╝     ██║   ██╔══██║██║   ██║██║  ██║██╔══╝
    ██║ ╚═╝ ██║███████╗   ██║   ██║  ██║╚██████╔╝██████╔╝███████╗
    ╚═╝     ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝

    */

	/**
	 * Va initialiser tous les variables privées
	 */
	private getHTMLElement() {

		if (this.element) {
			this.N_date = this.element.querySelector("#date-picker") as HTMLElement;
			this.N_km = this.element.querySelector('#kilometers') as HTMLElement;

			this.N_hoursTable = this.element.querySelector('#hours-table tbody') as HTMLTableElement;
			this.N_standbyDutyTable = this.element.querySelector('#standbyDuty-table tbody') as HTMLTableElement;
			this.N_totalHours = this.element.querySelector('#totalHours') as HTMLElement;
			this.N_totalStandbyDuties = this.element.querySelector('#totalStandbyDutys') as HTMLElement;
		}
	}

	private humanizeDuration(duration: moment.Duration) {

		const days = duration.get('days');
		const hours = ('00' + (days * 24 + duration.get('hour'))).slice(-2);
		const minutes = ('00' + duration.get('minute')).slice(-2);

		return hours + 'h' + minutes;
	}

	private async generateCarousels(): Promise<void> {

		if (!this.data) {
			throw new Error('Data is not initialized')
		}

		const sites = await SitesService.getInstance().getAllSites()

		const hoursCategories = HoursCategories.getInstance();

		const user = await UsersService.getInstance().getByID(this.data.user)

		if (user) {

			const groups = await GroupsService.getInstance().getByIds(user.groups)
			const userHoursCategoriesIDs = _.uniq(groups.map(group => group.hoursCategories).flat())
			const userHoursCategories = hoursCategories.getByIds(userHoursCategoriesIDs)

			if (this.N_carousels && this.statsForm) {

				const innerCarousel = this.N_carousels.querySelector('.carousel-inner') as HTMLElement;
				const filteredSites = sites.filter((site: any) => user.allowSites.indexOf(site._id) >= 0)

				for (let i = 0; i < filteredSites.length; i++) {
					innerCarousel.innerHTML += T_statsCarousel({
						selected: filteredSites[i]._id == user.site,
						site: filteredSites[i],
						hoursCategories: userHoursCategories
					})
				}

				this.statsForm.updateInputs();
			}

		}


	}

	private fillFormWithStats() {


		if (this.statsForm && this.data) {

			let stats = this.data.stats;

			for (let siteID in stats) {

				for (let catId in stats[siteID]) {
					stats[siteID][catId].hours = +moment.duration(stats[siteID][catId].hours, 'ms');
					stats[siteID][catId].standbyDuties = +moment.duration(stats[siteID][catId].standbyDuties, 'ms');
				}
			}

			this.statsForm.setData(stats as any)
		}


	}

}

export default DetailHoursSheetModal;


import BDD from "@js/libs/BDD"
import TableEditorHelper from "@js/libs/utils/table-editor.helper"
import _ from "lodash"
import TableEditor from "./TableEditor"

// TODO: remplacer completement l'ancien format par le nouveau format
export interface TableEditorRowStructure {
	id: string
	model: string
	order: number
	type: 'title1' | 'title2' | 'title3' | 'text' | 'spacer' | 'input'
	value: string
}

// Cette classe permet de creer des TR pour les TableEDitor seulement
class TableEditorRow extends HTMLTableRowElement {
	public static readonly tagName = "table-editor-row"

	private structure: TableEditorRowStructure
	private valueGetter: () => string
	private valueSetter: (value: string) => void
	private _valueChangedCallback?: (element: TableEditorRow, newValue: string) => void
	constructor() {
		super()
		this.structure = { id: BDD.generateID(), model: "", order: 0, type: "title1", value: "" }
		this.innerHTML = `
			<td class="settings-cell">
				<select class="type-chooser custom-select type-select" data-name="type">
					<option value="title1" selected>Titre 1</option>
					<option value="title2">Titre 2</option>
					<option value="title3">Titre 3</option>
					<option value="text">Texte</option>
					<option value="spacer">Espace</option>
					<option value="input">Saisie</option>
				</select>
				<button class="btn btn-sm btn-green btn-pin-right add-before-row-btn"><i class="icon icon-solid-plus"></i></button>
				<button class="btn btn-sm btn-green btn-pin-right add-after-row-btn"><i class="icon icon-solid-plus"></i></button>
			</td>
			<td class="spacer"></td>
			<td class="type-cell"></td>
			<td class="type-cell"></td>
			<td class="type-cell"></td>
			<td class="spacer ref-cell"></td>
			<td class="remove-cell">
				<button class="btn btn-sm btn-red btn-rounded btn-table-editor-remove"><i class="icon icon-solid-minus"></i></button>
			</td>
		`

		this.dataset.is = TableEditorRow.tagName
		this.id = this.structure.id
		this.init()

		this.valueGetter = () => ""
		this.valueSetter = (_value: string) => { }

		this.chooseType('title1');
	}

	public async connectedCallback() {
		if (this.getRoot().tagName !== TableEditor.tagName.toUpperCase()) {
			throw new Error('It should be on a table-editor')
		}
	}

	get value(): TableEditorRowStructure {
		this.structure.value = this.valueGetter()
		return this.structure
	}

	set value(newValue: TableEditorRowStructure) {
		const id = _.isEmpty(newValue.id) ? this.structure.id : newValue.id
		this.id = id
		this.structure = { ...newValue, id }
		this.isConnected && this.draw()
	}

	get order(): number {
		return this.structure.order
	}

	set order(newOrder: number) {
		this.structure.order = newOrder
	}

	get model(): string {
		return this.structure.model
	}

	set model(newModel: string) {
		this.structure.model = newModel
	}

	get type(): string {
		return this.structure.type
	}

	set valueChangedCallback(callback: (element: TableEditorRow, newValue: string) => void | undefined) {
		this._valueChangedCallback = callback
	}

	public draw() {
		if (this.isConnected) {
			this.setChooser(this.structure.type)
			this.chooseType(this.structure.type)
			this.valueSetter(this.structure.value)
		}
	}

	private getRoot(): TableEditor {
		return this.parentElement?.parentElement?.tagName === TableEditor.tagName.toUpperCase()
			// Gerer le cas du Tbody
			? this.parentElement?.parentElement as TableEditor
			: this.parentElement?.parentElement?.parentElement as TableEditor
	}

	public static createElement(): TableEditorRow {
		const N_row = document.createElement('tr', { is: TableEditorRow.tagName })
		return N_row as TableEditorRow
	}

	public static register() {
		if (_.isNil(customElements.get(TableEditor.tagName))) {
			throw new Error('Should be declared on the table-editor')
		}
		customElements.define(TableEditorRow.tagName, TableEditorRow, { extends: 'tr' })
	}

	private init() {
		this.initDelete()
		this.initAddRow()
		this.initTypeChooser()
		this.initDragAndDrop();
	}

	private initDelete() {
		const N_remove = this.querySelector('.btn-table-editor-remove') as HTMLButtonElement
		N_remove.addEventListener('click', () => this.remove())
	}

	private initAddRow() {
		const N_addAfter = this.querySelector('.add-after-row-btn') as HTMLButtonElement
		const N_addBefore = this.querySelector('.add-before-row-btn') as HTMLButtonElement
		N_addBefore.addEventListener('click', (e) => {
			this.insertAdjacentElement("beforebegin", TableEditorRow.createElement())
			e.preventDefault();
		})
		N_addAfter.addEventListener('click', (e) => {
			this.insertAdjacentElement("afterend", TableEditorRow.createElement())
			e.preventDefault();
		})

	}

	private initTypeChooser() {
		const N_chooser = this.querySelector('select.type-chooser') as HTMLSelectElement
		N_chooser.addEventListener('click', () => this.chooseType(N_chooser.value))
	}

	private chooseType(type: string) {
		const options = type === 'input' ? { definitions: this.getRoot().definitions, rootElement: this } : undefined
		const N_EditorCells = TableEditorHelper.input(type, options)

		// Detruire par defaut les cases du milieu
		const N_toDelete = this.querySelectorAll('td.type-cell')
		N_toDelete.forEach(node => node.remove())

		const N_refCell = this.querySelector('.ref-cell') as HTMLTableDataCellElement
		N_EditorCells.elements.forEach(node => N_refCell.insertAdjacentElement('beforebegin', node))

		// Recuperation de la structure
		this.valueGetter = N_EditorCells.valueGetter
		this.valueSetter = N_EditorCells.valueSetter
		const callback = (newValue: string) => this._valueChangedCallback && this._valueChangedCallback(this, newValue)
		N_EditorCells.setChangedCallback(callback)

		this.structure.type = type as 'title1' | 'title2' | 'title3' | 'text' | 'spacer' | 'input'
	}

	private setChooser(type: string) {
		const N_chooser = this.querySelector('select.type-chooser') as HTMLSelectElement
		N_chooser.value = type
	}


	private initDragAndDrop() {
		this.draggable = true;
		this.addEventListener('dragstart', (e) => {
			// e.dataTransfer?.setData('text/plain', this.id);
			this.dataset.dragging = "true"
			e.stopPropagation();
		})

		this.addEventListener('dragend', (e) => {
			this.dataset.dragging = "false"
			e.stopPropagation();
		})
	}
}

export default TableEditorRow

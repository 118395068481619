import { Modal, Form, global } from "@autoprog/core-client"
import T_detailDelivery from '@tpl/modals/orders/detailDelivery.html'
import moment, { Moment } from 'moment'
import toastr from 'toastr'
import T_tableDeliveredProducts from '@tpl/blocks/orders/delivery-note/tableDeliveredProduct.ejs'
import rawMaterialProperties from '@libs/lists/rawMaterialPropertiesList'
import AddEditDelivery from './AddEditDelivery'
import typeTransport from '@libs/lists/typeTransportList'
import unitList from '@libs/lists/unitList'
import stateList from '@libs/lists/stateListMale'

import T_events from '@tpl/blocks/orders/detailEvent.ejs'
import FilesManager from '@js/libs/FilesManager'

import _ from 'lodash'
import { SiteStorage } from '@js/types/site'
import SitesService from '@js/libs/services/SitesService'
import DeliveryNote from '@js/types/delivery-note'
import Order from '@js/types/order'
import Tier from '@js/types/Tiers'
import { DeliveryNotesService, OrdersService, TiersService, UsersService } from '@js/libs/services'
import PrinterHelper from "@js/libs/utils/printer.helper"
import User from "@js/types/user"

interface DetailDeliveryData extends Omit<DeliveryNote, "date"> {
	orderData: Order
	customerData: Tier
	providerData: Tier
	eventUsers: Array<User>
	date: Moment | number
}

class DetailDelivery extends Modal {

	private N_form: HTMLFormElement | null = null
	private delivery!: DetailDeliveryData
	private filesManager: FilesManager = new FilesManager()

	private listStorages: { [key: string]: any } = []

	constructor(private id: string) {
		super({
			tpl: T_detailDelivery,
			backdrop: 'static',
			keyboard: false
		})

		this.on('opened', async () => {

			this.N_form = this.element.querySelector('#detail-order-form') as HTMLFormElement
			(this.element.querySelector('#saveDocumentBtn') as HTMLButtonElement).style.display = 'none'
			await this.loadData()
		})
	}

	private async loadData() {

		this.listStorages = _.keyBy(await this.getStorages(), 'id');

		this.delivery = await this.getDeliveryData()

		this.initEvent(this.delivery.events?.map(event => ({...event, user: this.delivery!.eventUsers.find(u => u._id === event.user)  })) || [])

		const N_title = this.element.querySelector('#title') as HTMLElement
		N_title.innerHTML = this.delivery._id

		this.delivery.date = moment(this.delivery.date, 'x').add(12, 'hours')
		this.delivery.transportType = typeTransport[this.delivery.transportType]

		if (this.N_form) {
			const form = new Form(this.N_form)
			form.setData(this.delivery as any)
			this.addTableProduct(this.delivery.products)
		}

		if (this.delivery.state !== 'billed') {
			(this.element.querySelector('#saveDocumentBtn') as HTMLButtonElement).style.display = 'block'
			let N_attachments = this.element.querySelector('#attachments') as HTMLElement
			const N_attachmentsList = this.element.querySelector('#attachments-list') as HTMLElement
			N_attachments.appendChild(this.filesManager.getNode())

			if (this.delivery._attachments) {
				this.filesManager.setFiles(this.delivery._attachments)

				this.addAttachment('delivery-notes', N_attachmentsList, this.delivery)
			}
			else {
				N_attachmentsList.innerHTML = "Pas de piece jointes"
			}

		}


		const N_editBtn = this.element.querySelector('#editButton') as HTMLButtonElement
		_.isEqual(this.delivery.state === 'billed', true) && N_editBtn.remove()

		N_editBtn.addEventListener('click', () => {
			if (this.delivery && this.delivery.state != 'billed') {
				const modal = new AddEditDelivery(this.delivery._id)
				this.hide()
				modal.open().then(() => this.resolve(true)).catch(() => this.show())
			}
		})

		this.configureBtn()
	}

	private configureBtn(): void {
		const N_printBtn = this.element.querySelector('#printButton') as HTMLButtonElement
		N_printBtn.addEventListener('click', async () => {
			try {
				const blob = await DeliveryNotesService.getInstance().getPDF(this.id)
				PrinterHelper.previewPDF(blob, `${this.id}.pdf`)
			}
			catch (e) {
				toastr.error(`Erreur lors de l'impression`)
				console.error(e)
			}
		})

		// Bouton de modification des pièces jointes
		const N_saveDocumentBtn = this.element.querySelector('#saveDocumentBtn') as HTMLButtonElement
		N_saveDocumentBtn.addEventListener2('click', async () => {

			// On met à jour les pièces jointes en fonction du bon de livraison
			await this.filesManager.updateDocument('delivery-notes', this.id)
			toastr.success('Documents du bon de livraison modifié')
		})
	}

	private async getDeliveryData(): Promise<DetailDeliveryData> {
		if (!this.id) throw 'Error : Id is not filled'

		const deliveryNote = await DeliveryNotesService.getInstance().getByID(this.id)
		const orderData = await OrdersService.getInstance().getByID(deliveryNote.order)
		const userIds = _.uniq((deliveryNote.events || []).map(event => event.user))
		const [customerData, providerData, eventUsers] = await Promise.all([
			TiersService.getInstance().getByID(orderData.customer),
			TiersService.getInstance().getByID(orderData.provider),
			UsersService.getInstance().getByIds(userIds)
		])


		return {
			...deliveryNote,
			orderData,
			customerData,
			providerData,
			eventUsers
		}
	}

	private initEvent(events: any[]) {

		let N_container = this.element.querySelector('#events')

		if (N_container) {

			N_container.innerHTML = T_events({
				events,
				moment,
				stateList
			});

		}

	}

	private addTableProduct(products: { [key: string]: any }[]): void {
		const divProduct = this.element.querySelector('#deliveredProducts') as HTMLDivElement
		const storagesList = this.listStorages
		divProduct.innerHTML = T_tableDeliveredProducts({ products, rawMaterialProperties, unitList, storagesList })
	}

	private addAttachment(table: string, N_element: HTMLElement, object: any): void {

		for (let attachment in object._attachments) {

			const N_buttonAttachment = document.createElement('button') as HTMLButtonElement

			N_buttonAttachment.type = 'button'
			N_buttonAttachment.classList.add('btn', 'btn-outline-secondary', 'btn-sm', 'btn-rounded', 'mr-2')
			N_buttonAttachment.innerHTML = attachment
			N_element.appendChild(N_buttonAttachment)

			N_buttonAttachment.addEventListener('click', () => {
				window.open(`${global.COUCHDB_URL}/${table}/${object._id}/${attachment}`, "_blank")
			})

		}
	}

	private async getStorages(): Promise<Array<SiteStorage>> {
		const site = await SitesService.getInstance().getByID(global.SITE)
		return site.storages || []
	}

}

export default DetailDelivery

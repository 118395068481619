import { Tab, toaster } from "@autoprog/core-client"

import AccountingAccountsService from '../../services/AccountingAccountsService'
import VATService from '../../services/VATService'
import { GridOptions, Grid } from 'ag-grid-community';
import h from 'hyperscript'
import AddEditAccountingAccount from '@js/libs/modals/settings/AddEditAccountingAccount';
import AddEditVAT from '@js/libs/modals/settings/AddEditVAT';

import suffixCellRenderer from '@autoprog/core-client/src/js/libs/utils/agGrid/suffixCellRenderer';

class AccountingTab extends Tab {

	private accountGridOptions: GridOptions
	private VATGridOptions: GridOptions

	constructor(private el: HTMLElement) {
		super(el)

		this.accountGridOptions = {

			excelStyles: [
				{
					id: 'booleanType',
					dataType: 'Boolean',
				}
			],

			columnDefs: [
				{
					headerName: 'Code',
					field: '_id',
					minWidth: 80,

				},
				{
					headerName: 'Nom',
					field: 'name',
					minWidth: 120,
					sort: 'asc',

				},
				{
					headerName: 'Est de la Mat. première',
					field: 'isRawMaterial',
					cellRenderer: (params) => {
						let tag = (params.value) ? 'i.icon.icon-solid-check.text-green' : 'i.icon.icon-solid-times.text-red'
						return h(tag) as HTMLElement
					},
					cellClass: 'booleanType'
				},
				{
					headerName: "",
					field: "commands",
					width: 8 + 28 * 2,
					sortable: false,
					filter: false,
					suppressSizeToFit: true,
					pinned: 'right',
					cellRenderer: (params) => {

						let div = h('div.d-flex.justify-content-end') as HTMLElement;

						let editButton = document.createElement('button');
						editButton.classList.add('btn');
						editButton.classList.add('btn-xs');
						editButton.classList.add('btn-info');
						editButton.setAttribute('permission', 'SETTINGS.ACCOUNTING.ACCOUNT.EDIT')
						editButton.innerHTML = '<i class="icon icon-solid-pencil-alt"></i>'

						let deleteButton = document.createElement('button');
						deleteButton.classList.add('btn');
						deleteButton.classList.add('btn-xs');
						deleteButton.classList.add('btn-danger');
						deleteButton.setAttribute('confirmation', '');
						deleteButton.setAttribute('permission', 'SETTINGS.ACCOUNTING.ACCOUNT.DELETE')
						deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"></i>'

						deleteButton.disabled = params.data.locked;
						if (deleteButton.disabled) {
							deleteButton.setAttribute('tooltip', 'Actuellement utilisé')
							deleteButton.classList.add('btn-grey-200');

						} else {

							deleteButton.addEventListener2('click', async () => {
								try {
									await AccountingAccountsService.getInstance().delete(params.data._id);
									this.loadAccounts();
									toaster.success(`Compte supprimé`);
								} catch (e) {
									toaster.error(`Supression du compte`, 'Erreur');
								}
							});
						}

						editButton.addEventListener2('click', () => {
							(new AddEditAccountingAccount(params.data._id)).open().then(() => {
								this.loadAccounts();
							})
						})

						div.appendChild(editButton);
						div.appendChild(deleteButton);

						return div
					}
				}
			],

			rowData: [],
			onGridReady: (params) => params.api.sizeColumnsToFit(),
			defaultColDef: {
				sortable: true
			}
		}

		this.VATGridOptions = {
			excelStyles: [
				{
					id: 'booleanType',
					dataType: 'Boolean',
				}
			],
			columnDefs: [
				{
					headerName: 'Code',
					field: '_id',
				},
				{
					headerName: 'Pourcentage',
					field: 'percent',
					cellRenderer: suffixCellRenderer('%')
				},
				{
					headerName: 'Libéllé',
					field: 'label',
					minWidth: 120,
					sort: 'asc',
				},
				{
					headerName: 'Cpt comptable',
					field: 'accountingAccount'
				},
				{
					headerName: 'Par défaut',
					field: 'default',
					cellRenderer: (params) => {
						let tag = params.value ? 'i.icon.icon-solid-check.text-green' : 'i.icon.icon-solid-times.text-red'
						return h(tag) as HTMLElement
					},
					cellClass: 'booleanType'
				},
				{
					headerName: "",
					field: "commands",
					width: 8 + 28 * 2,
					sortable: false,
					filter: false,
					suppressSizeToFit: true,
					pinned: 'right',
					cellRenderer: (params) => {

						const div = h('div.d-flex.justify-content-end') as HTMLElement;

						const editButton = document.createElement('button')
						editButton.classList.add('btn', 'btn-xs', 'btn-danger')
						editButton.setAttribute('permission', 'SETTINGS.ACCOUNTING.VAT.EDIT')
						editButton.innerHTML = '<i class="icon icon-solid-pencil-alt"></i>'

						const deleteButton = document.createElement('button')
						deleteButton.classList.add('btn', 'btn-xs', 'btn-danger')
						deleteButton.setAttribute('confirmation', '')
						deleteButton.setAttribute('permission', 'SETTINGS.ACCOUNTING.VAT.DELETE')
						deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"></i>'

						deleteButton.disabled = params.data.locked;
						if (deleteButton.disabled) {
							deleteButton.setAttribute('tooltip', 'Actuellement utilisé')
							deleteButton.classList.add('btn-grey-200')

						}
						else
						{
							deleteButton.addEventListener('click', async () => {
								try {
									await VATService.getInstance().delete(params.data._id);
									this.loadVAT();
									toaster.success(`Compte supprimé`);
								} catch (e) {
									toaster.error(`Supression du compte`, 'Erreur');
								}
							});

						}

						editButton.addEventListener('click', () => {
							new AddEditVAT(params.data._id).open().then(() => this.loadVAT())
						})

						div.appendChild(editButton);
						div.appendChild(deleteButton);

						return div;
					}
				}
			],

			rowData: [],
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			},
			defaultColDef: {
				sortable: true
			}
		}

		const N_accountsGrid = this.el.querySelector('#accounts-grid') as HTMLElement;
		new Grid(N_accountsGrid, this.accountGridOptions);

		const N_VATGrid = this.el.querySelector('#VAT-grid') as HTMLElement;
		new Grid(N_VATGrid, this.VATGridOptions);

		this.loadAccounts();
		this.loadVAT();


		const N_addAccountBtn = this.el.querySelector('#add-account-btn') as HTMLButtonElement
		N_addAccountBtn.addEventListener2('click', () => {
			(new AddEditAccountingAccount()).open().then(() => this.loadAccounts())
		})

		const N_exportAccountBtn = this.el.querySelector('#export-account-btn') as HTMLButtonElement
		N_exportAccountBtn.addEventListener2('click', () => {
			this.accountGridOptions.api && this.accountGridOptions.api.exportDataAsExcel({
				fileName: 'Compte-Comptable.xlsx'
			})
		})

		const N_addVATBtn = this.el.querySelector('#add-VAT-btn') as HTMLButtonElement
		N_addVATBtn.addEventListener2('click', () => {
			(new AddEditVAT()).open().then(() => this.loadVAT())
		})

		const N_exportBtn = this.el.querySelector('#export-VAT-btn') as HTMLButtonElement
		N_exportBtn.addEventListener2('click', () => {
			this.VATGridOptions.api && this.VATGridOptions.api.exportDataAsExcel({
				fileName: 'TVA.xlsx'
			})
		})
	}

	private async loadAccounts() {
		const accounts = await AccountingAccountsService.getInstance().getAll();
		this.accountGridOptions.api?.setRowData(accounts)
	}

	private async loadVAT() {
		const VAT = await VATService.getInstance().getAll()
		this.VATGridOptions.api?.setRowData(VAT)
	}

	public destructor(): void {

	}
}

export default AccountingTab;
import axios from 'axios'
import _ from 'lodash'
import CalendarHoliday from "@js/types/calendar-holiday"


class CalendarHolidayService {
	private static readonly instance = new CalendarHolidayService()

	private constructor() { }

	public static getInstance(): CalendarHolidayService {
		return CalendarHolidayService.instance
	}

	/**
	 * Permet de récupérer tous les calendriers
	 */
	public async getAll(): Promise<Array<CalendarHoliday>> {
		return axios.get(`/api/calendars-holidays`).then(response => response.data.data)
	}

	public async getByID(id: string) : Promise<CalendarHoliday> {
		return axios.get(`/api/calendars-holidays/${id}`).then(response => response.data.data)
	}

	public async getByIds(ids: Array<string>) : Promise<Array<CalendarHoliday>> {
		return axios.post(`/api/calendars-holidays/by-ids`, { ids }).then(response => response.data.data)
	}

	public async create(calendar: CalendarHoliday) : Promise<void> {
		await axios.post(`/api/calendars-holidays`, calendar)
	}

	public async update(calendar: CalendarHoliday) : Promise<void> {
		await axios.put(`/api/calendars-holidays`, calendar)
	}

	public async delete(id: string) : Promise<void> {
		await axios.get(`/api/calendars-holidays/tag-delete/${id}`)
	}

	public async existsFixedDate(day: number, month: number) : Promise<boolean> {
		return axios.get(`/api/calendars-holidays/has-fixed/${day}/${month}`).then(response => response.data.data)
	}

	public async exists(day: number) : Promise<boolean> {
		return axios.get(`/api/calendars-holidays/exists/${day}`).then(response => response.data.data)
	}

	public async getByDates(startingDate: number, endingDate: number) : Promise<Array<CalendarHoliday>> {
		return axios.get(`/api/calendars-holidays/by-dates/${startingDate}/${endingDate}`).then(response => response.data.data)
	}

	public async getAllExtended() : Promise<Array<CalendarHoliday>> {
		return axios.get(`/api/calendars-holidays/all/extended`).then(response => response.data.data)
	}

}

export default CalendarHolidayService

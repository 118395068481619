import { CElement, global } from "@autoprog/core-client"

import { LoDashStatic } from 'lodash'
import SitesService from '../services/SitesService'

declare const _: LoDashStatic

class SiteSelect extends CElement {

	public static tagName: string = 'site-select'

	private static knownNames: {
		[key: string]: string
	} = {};

	private element: Element;

	constructor(originEl: Element) {
		super(originEl as HTMLElement)
		this.element = originEl;

		let attributes = this.element.attributes;

		let N_tempSelect = document.createElement('select');
		N_tempSelect.id = 'temp-' + _.uniqueId();

		for (let siteID of global.ALLOW_SITES) {
			let option = document.createElement('option') as HTMLOptionElement;
			option.value = siteID;
			option.innerHTML = SiteSelect.knownNames[siteID] || siteID;
			N_tempSelect.appendChild(option);
		}

		this.element.outerHTML = N_tempSelect.outerHTML;

		let N_select = document.querySelector('#' + N_tempSelect.id) as HTMLSelectElement;

		if (N_select) {

			N_select.removeAttribute('id')
			N_select.setAttribute('name', 'site')
			N_select.value = global.SITE

			let sitesService = SitesService.getInstance();

			sitesService.getAllowedSites().then(sites => {

				// Cela va éffacer les sites qui ne n'ont pas de données
				Array.from(global.ALLOW_SITES).filter(siteID => sites.filter(site => site._id == siteID).length <= 0)
					.forEach(siteID => {
						const N_option = N_select.querySelector(`[value="${siteID}"]`) as HTMLOptionElement
						N_option?.remove()
					})
				
				sites.forEach(site => {
					const N_option = N_select.querySelector(`[value="${site._id}"]`) as HTMLOptionElement
					N_option.innerHTML = SiteSelect.knownNames[site._id] = site.name

					if (N_option.value == global.SITE) {
						N_option.setAttribute('selected', '')
					}
				})
			})
			.then(() => {
				this.sortElements(N_select)
				N_select.value = global.SITE;
				
				if (N_select.childNodes.length <= 1) {
					N_select.disabled = true;
				}
			})

			for (let i = 0; i < attributes.length; i++) {
				N_select.setAttribute(attributes[i].name, attributes[i].value)
			}

		}
	}

	public created(): void {

	}

	/**
	 * Permet de ranger par ordre alphabétique les éléments
	 */
	private sortElements(selectElement: HTMLSelectElement) {
		let elementsValues = this.optionsToArray(selectElement.options)
		elementsValues = elementsValues.sort((a, b) => { return a.name.localeCompare(b.name) })

		this.emptySelect(selectElement)

		elementsValues.forEach(el => selectElement.options.add(new Option(el.name, el.value)))
	}

	/**
	 * Permet de transformer HTMLOptionsCollection en options
	 * @param options 
	 */
	private optionsToArray(options: HTMLOptionsCollection): Array<{ name: string, value: string }> {
		return Array.from(options).map(option => ({ name: option.innerHTML, value: option.value }))
	}

	/**
	 * Permet de vider un select element
	 * @param selectElement 
	 */
	private emptySelect(selectElement: HTMLSelectElement) {

		for (let i = selectElement.options.length - 1; i > -1; i--) {
			selectElement.options.remove(i)
		}

	}

	public attributeChanged(_name: string, _oldValue: string): void {

	}


}


export default SiteSelect;

import _ from 'lodash'
import axios from 'axios'
import DeliveryNote from '@js/types/delivery-note'

import { FilesManagerFile } from '@libs/FilesManager'
import FilesManager2 from '@libs/FilesManager2'
import { OldDataEvent } from '@js/types/data-event'
import DataEventsService from './DataEventsService'
import ServiceCache from '../Cache'

export type UnionTypeQualityCriteria = 'potentialMethane' | 'wastage' | 'organicMatter' | 'dryMatter'

class DeliveryNotesService {

	public static readonly TABLE = 'delivery-notes'

	private cache?: ServiceCache;
	private static instance = new DeliveryNotesService()

	private constructor() {
		this.cache = ServiceCache.getInstance('delivery-notes')
	}

	public static getInstance() {
		return this.instance;
	}

	public async getByID(ID: string, ignoreCache = false): Promise<DeliveryNote> {

		if (ignoreCache) {
			return await axios.get(`/api/delivery-notes/${ID}`).then(response => response.data.data);;
		}

		return await this.cache?.gatherData(ID, async(ID)=>{
			return await axios.get(`/api/delivery-notes/${ID}`).then(response => response.data.data);
		})
	}

	public async getAll(): Promise<Array<DeliveryNote>> {
		// TODO: finir cette fonction
		throw new Error('Not implemented yet')
	}

	/**
	 * Permet de chercher les Bon de livraisons en fonction d'un tableau d'identifiants
	 * @param ids 
	 */
	public async getByIds(ids: Array<string>): Promise<Array<DeliveryNote>> {
		return axios.post('/api/delivery-notes/by-ids', { ids }).then(response => response.data.data)
	}

	public async create(deliveryNote: DeliveryNote): Promise<void> {
		await axios.post(`/api/delivery-notes`, deliveryNote)
	}

	public async update(deliveryNote: DeliveryNote): Promise<void> {
		await axios.put(`/api/delivery-notes`, deliveryNote)
	}

	/**
	 * Permet de récupérer les bons de livraisons en fonction du bon de commande
	 * @param idOrder Le numero de commande
	 */
	public async getFromOrder(idOrder: string): Promise<Array<DeliveryNote>> {
		return axios.get(`/api/delivery-notes/from-order/${idOrder}`).then(response => response.data.data)
	}

	public async getTransactionalBySite(site: string): Promise<Array<DeliveryNote>> {
		return this.getBySite(site).then(data => data.filter(d => d.isTransaction))
	}

	public async getBySiteNotArchived(site: string): Promise<Array<DeliveryNote>> {
		return axios.get(`/api/delivery-notes/by-site-not-archived/${site}`).then(response => response.data.data)
	}

	public async getBySite(site: string): Promise<Array<DeliveryNote>> {
		return axios.get(`/api/delivery-notes/by-site/${site}`).then(response => response.data.data)
	}

	public async addAttachments(id: string, files: Array<FilesManagerFile>) {
		await FilesManager2.addAttachments(DeliveryNotesService.TABLE, id, files)
	}

	public async syncAttachments(id: string, files: Array<FilesManagerFile>) {
		await FilesManager2.synchronizeFiles(DeliveryNotesService.TABLE, id, files)
	}

	/**
	 * Permet de supprimer un bon de livraison en base de données
	 * @param id Identifiant du BL
	 */
	public async delete(id: string): Promise<void> {
		await axios.get(`api/delivery-notes/tag-delete/${id}`)
	}

	public async createEvent(data: Omit<OldDataEvent, 'table'>) {
		await DataEventsService.getInstance().create({ ...data, table: 'delivery-notes' })
	}

	/**
	 * Permet de supprimer tous les bons de livraisons par numéro de commande
	 * @param orderId 
	 */
	public async deleteByOrder(orderId: string) {
		await axios.delete(`/api/delivery-notes/by-order/${orderId}`)
	}

	/**
	 * Peut mettre à jour les valeurs de critères qualités sur plusieurs lignes de Bon de livraisons
	 * @param criteria 
	 * @param ids 
	 * @param value 
	 */
	public async setMultipleCriteria(criteria: UnionTypeQualityCriteria, ids: Array<string>, value: number): Promise<void> {
		const tabMultipleCriteria = {
			"potentialMethane": this.setMultipleCriteriaPotentialMethane,
			"wastage": this.setMultipleCriteriaWastage,
			"organicMatter": this.setMultipleCriteriaOrganicMatter,
			"dryMatter": this.setMultipleCriteriaDryMatter
		}

		await tabMultipleCriteria[criteria](ids, value)
	}

	/**
	 * Permet de mettre à jour le potentielle méthane de plusieurs bon de livraisons
	 * @param ids 
	 * @param value 
	 */
	private async setMultipleCriteriaPotentialMethane(ids: Array<string>, value: number): Promise<void> {
		await axios.post('/api/delivery-notes/qualityCriteria/potentialMethane', { ids, value })
	}

	/**
	 * Permet de mettre à jour le wastage de plusieurs bon de livraisons
	 * @param ids 
	 * @param value 
	 */
	private async setMultipleCriteriaWastage(ids: Array<string>, value: number) {
		await axios.post('/api/delivery-notes/qualityCriteria/wastage', { ids, value })
	}

	/**
	 * Permet de mettre à jour Organic Matter de plusieurs bon de livraisons
	 * @param ids 
	 * @param value 
	 */
	private async setMultipleCriteriaOrganicMatter(ids: Array<string>, value: number) {
		await axios.post('/api/delivery-notes/qualityCriteria/organicMatter', { ids, value })
	}

	/**
	 * Permet de mettre à jour Dry Matter dans plusieurs bon de livraisons
	 * @param ids 
	 * @param value 
	 */
	private async setMultipleCriteriaDryMatter(ids: Array<string>, value: number) {
		await axios.post('/api/delivery-notes/qualityCriteria/dryMatter', { ids, value })
	}

	public async getPDF(id: string) : Promise<Blob> {
		return axios.get(`/api/delivery-notes/pdf/${id}`, { responseType: "blob" }).then(response => response.data)
	}
}

export default DeliveryNotesService;

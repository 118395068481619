import axios from 'axios'
import OperatingData from "@js/types/operating-data"

class OperatingDataService {

	private static instance = new OperatingDataService()

	private constructor() {

	}

	public static getInstance() {
		return this.instance;
	}

	public async getAllByIDAndDate(operatingDataID: string, startDate: number, endDate: number): Promise<Array<OperatingData>> {
		return axios.get(`/api/operating-data/by-definition-date/${operatingDataID}/${startDate}/${endDate}`).then(response => response.data.data)
	}

	public async search(startingDate: number, endingDate: number, definitions?: Array<string>, type: 'list' | 'imported' = 'list', tag?: string) : Promise<Array<OperatingData>> {
		return axios.post('/api/operating-data/search', { startingDate, endingDate, definitions, type, tag }).then(response => response.data.data)
	}

	public async getById(id: string) : Promise<OperatingData|undefined> {
		return axios.get(`/api/operating-data/${id}`).then(response => response.data.data)
	}

	public async getByList(checklistId: string) : Promise<Array<OperatingData>> {
		return axios.get(`/api/operating-data/by-list/${checklistId}`).then(response => response.data.data)
	}

	public async deleteList(checkListID: string) {
		await axios.delete(`/api/operating-data/list/${checkListID}`)
	}

	public async updateAll(data: Array<OperatingData>) {
		await axios.put(`/api/operating-data/update-all`, data)
	}
}

export default OperatingDataService

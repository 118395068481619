import { Tab, global, LoggedUser } from "@autoprog/core-client"

import { ColDef, GetContextMenuItemsParams, Grid, GridOptions, ICellRendererParams, MenuItemDef } from 'ag-grid-community'
import 'ag-grid-enterprise'
import moment from 'moment'
import drpC from '@libs/utils/daterangepickerConfig'
import unitList from '@libs/lists/unitList'
import _ from 'lodash'
import Export from '@js/libs/agGrid/Export'

import { OperatingDataDefinitionService, OperatingDataService } from '@js/libs/services'

import '@css/exploitation_operatingData.scss'
import ApCheckboxSelect from '@js/libs/customElements/ApCheckboxSelect'
import { OperatingDataDefinition } from "@js/libs/services/OperatingDataDefinitionService"
import ImportersService from "@js/libs/services/ImportersService"
import ImportHelper from "@js/libs/utils/import.helper"

import { AGGridUtils } from '@js/libs/utils/agGrid'

type IParameter = { 
	type?: 'list' | 'imported'
	title?: string,
	permissions?: {
		import?: string
	}
}

class OperatingData extends Tab {

	private startDate: moment.Moment
	private endDate: moment.Moment
	private gridOptions: GridOptions

	constructor(private el: HTMLElement, private data: IParameter) {
		super(el)

		this.startDate = moment().startOf('month')
		this.endDate = moment().endOf('month')
		
		this.gridOptions = {}

		this.data.type = ["list", "imported"].includes(this.data.type!) ? this.data.type : "list"

		this.init()
	}

	private async init() {
		const N_datePicker = this.el.querySelector('#date-picker') as HTMLElement
		const N_load = this.el.querySelector('#load-btn') as HTMLButtonElement

		this.initComponents()

		/* ------------------------- Création du date picker ------------------------ */

		$(N_datePicker).daterangepicker(drpC({
			startDate: this.startDate,
			endDate: this.endDate,
		}), (start, end, _label) => {
			this.startDate = start.startOf('day')
			this.endDate = end.endOf('day')
		})

		N_load.addEventListener('click', () => this.loadData())

		this.createGrid()
		await this.load() // Chargement des definitions
	}

	private initComponents() {
		
		if (this.data.title) {
			const N_title = this.el.querySelector('.title') as HTMLHeadingElement
			N_title.textContent = this.data.title
		}

		// Le bouton d'importation ne peut que s'afficher que si c'est un type imported et que des permissions existe
		if (this.data.type === 'imported') {
			const N_import = this.el.querySelector('#import-btn') as HTMLButtonElement
			const hasPermission = this.data.permissions?.import && LoggedUser.getInstance().evalPermissions(this.data.permissions.import)
			N_import.classList.toggle('d-none', false)
			N_import.disabled = !hasPermission

			// Mettre a jour la permission ne fonctionne pas
			this.data.permissions?.import && N_import.setAttribute('permission', this.data.permissions?.import)

			N_import.addEventListener('click', () => ImportHelper.upload())
		}
	}

	private async load() {
		// Boutons load
		const N_load = this.el.querySelector('#load-btn') as HTMLButtonElement
		N_load.disabled = true

		let definitions = await OperatingDataDefinitionService.getInstance().getAll().then(result => _.sortBy(result, 'label'))

		if (this.data.type === 'imported') {
			const filteredDefinitions = await ImportersService.getInstance().getAll()
			definitions = definitions.filter(definition => filteredDefinitions.some(fd => fd.definition === definition._id))
		}

		// Chargement de la liste
		const N_select = this.el.querySelector(ApCheckboxSelect.tagName) as ApCheckboxSelect
		N_select.values = definitions.map(definition => ({ id: definition._id, text: definition.label }))
	
		this.setStructures(definitions)
		// REMARK: Be careful, this function shoudl be called once 
		N_select.addEventListener('load', () => _.set(N_load, 'disabled', false))
	}


	private setStructures(definitions: Array<OperatingDataDefinition>) {
		const columns: Array<ColDef> = definitions.map(definition => ({
			headerName: `${definition.label} ${!_.isEmpty(definition.unit) ? `(${unitList[definition.unit!].unit})` : ''}`,
			field: definition._id,
			cellRenderer: (params: ICellRendererParams) => {

				if (definition.dataType == 'checkbox') {
					return params.value ? 'oui' : 'non'
				}
				else if (definition.dataType == 'date') {
					return moment(params.value).format('DD/MM/YYYY')
				}

				return params.value
			}

		}))

		this.gridOptions.api?.setColumnDefs(this.defaultColumns().concat(columns))
	}

	private defaultColumns(): Array<ColDef> {
		return [{
			field: 'date',
			headerName: 'Date',
			cellRenderer: (params) => moment(params.value).format('DD/MM/YYYY HH:mm') // FIXME; regarder is la limite c'est pas l'heure
		}]
	}


	private createGrid() {
		this.gridOptions = {
			columnDefs: this.defaultColumns(),
			defaultColDef: {
				resizable: true,
				sortable: true,
				suppressMenu: true
			},
			getContextMenuItems: (params: GetContextMenuItemsParams) => {
				const menu: Array<MenuItemDef> = []

				if (LoggedUser.getInstance().evalPermissions('EXPLOITATION.OPERATING_DATA.EXPORT || EXPLOITATION.IMPORTED_DATA.EXPORT')) {
					menu.push(
						{
							name: 'Exporter en Excel',
							action: () => {
								const exp = new Export(params)
								const columnKeys = AGGridUtils.getColumnsIds(this.gridOptions)

								exp.exportExcel({
									fileName: `${this.data.title || 'Données'} - ${global.SITE} - ${moment().format('YYYY-MM-DD')}`,
									columnKeys
								})

							}
						}
					)
				}

				return menu
			}
		}

		const N_grid = this.el.querySelector('#grid-operating-data') as HTMLDivElement
		new Grid(N_grid, this.gridOptions)
	}

	// Remark ou vous avez un any C'est la seule exception ou
	private async getData() : Promise<Array<{[definition: string]: string | number | boolean}>> {
		const N_select = this.el.querySelector(ApCheckboxSelect.tagName) as ApCheckboxSelect
		const data = await OperatingDataService.getInstance().search(+this.startDate.format('x'), +this.endDate.format('x'), N_select.selected, this.data.type, `[[site:${global.SITE}]]`)
		
		// Construction de nos rows
		const maps = data.reduce((result, operatingData) => {
			result[operatingData.date] = result[operatingData.date] || { date: operatingData.date }
			result[operatingData.date][operatingData.definition] = operatingData.value
			return result
		}, {} as { [date: number]: { [definition: string]: string | number | boolean } })

		return Object.values(maps)
	}


	private setLoading(isLoading: boolean) {
		const N_noneDisplay = this.el.querySelector('#grid-container > span') as HTMLSpanElement
		const N_grid = this.el.querySelector('#grid-operating-data') as HTMLDivElement
		N_noneDisplay.classList.toggle('d-none', !isLoading)
		N_grid.classList.toggle('d-none', isLoading)
	}

	/**
	 * @description Permet d'initialiser la grille en fonction des données d'exploitation sélectionnées
	 */
	private async loadData() {

		const N_select = this.el.querySelector(ApCheckboxSelect.tagName) as ApCheckboxSelect

		this.setLoading(true)

		// Changer la visibilite des colonnes en fonction du choix des definitions
		const columns = this.gridOptions.columnApi?.getAllColumns() || []
		this.gridOptions.columnApi?.setColumnsVisible(columns.map(column => column.getColId()), false)

		if (N_select.selected.length) {

			const data = await this.getData()
			
			this.gridOptions.columnApi?.setColumnsVisible(N_select.selected, true)
			this.gridOptions.columnApi?.setColumnsVisible(["date"], true)

			this.gridOptions.api?.setRowData(data)

			this.setLoading(false)
		}
	}

	public destructor() {

	}
}

export default OperatingData

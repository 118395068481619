import { Tab, toaster } from "@autoprog/core-client"

import { OperatingDataDefinitionService } from '@js/libs/services'
import { french as agGridFrench } from '@libs/utils/agGrid'
import { Grid, GridOptions, ICellEditorParams } from 'ag-grid-community'

import h from 'hyperscript'
import unitList from '@js/libs/lists/unitList'
import AddEditOperatingDataDefinition from "@js/libs/modals/settings/AddEditOperatingDataDefinition"

class DefinitionsTab extends Tab {

	private gridOptions: GridOptions

	constructor(private el: HTMLElement) {
		super(el)
		this.gridOptions = {}
		this.init()
	}

	private async init() {
		this.createGrid()
	}

	private createGrid() {
		this.gridOptions = agGridFrench({
			columnDefs: [
				{
					headerName: "Libéllé",
					field: "label"
				},
				{
					headerName: "Type de la donnée",
					field: "dataType",
					valueGetter: (params) => {
						const labels = {
							text: 'Texte',
							textarea: 'Texte long',
							number: 'Nombre',
							date: 'Date',
							time: 'Temps',
							checkbox: 'Case à cocher',
							select: 'Liste déroulante',
						}
						return labels[params.data.dataType as keyof typeof labels];
					}
				},
				{
					headerName: "Unité",
					field: "unit",
					valueGetter: (params) => unitList[params.data.unit]?.name || ''
				},
				{
					headerName: "",
					field: "commands",
					width: 8 + 28 * 3,
					sortable: false,
					filter: false,
					suppressSizeToFit: true,
					pinned: 'right',
					cellRenderer: (params: ICellEditorParams) => {
						const div = h('div.d-flex.justify-content-end') as HTMLElement;

						const N_duplicate = document.createElement('button');
						N_duplicate.classList.add('btn', 'btn-xs', 'btn-purple')
						N_duplicate.setAttribute('permission', 'SETTINGS.OPERATING_DATA.DUPLICATE')
						N_duplicate.innerHTML = '<i class="icon icon-solid-copy"></i>'


						const N_edit = document.createElement('button')
						N_edit.classList.add('btn', 'btn-xs', 'btn-info')
						N_edit.setAttribute('permission', 'SETTINGS.OPERATING_DATA.EDIT')
						N_edit.innerHTML = '<i class="icon icon-solid-pencil-alt"></i>'

						const N_delete = document.createElement('button')
						N_delete.classList.add('btn', 'btn-xs', 'btn-danger')
						N_delete.setAttribute('confirmation', '');
						N_delete.setAttribute('permission', 'SETTINGS.OPERATING_DATA.DELETE')
						N_delete.innerHTML = '<i class="icon icon-solid-trash-alt"></i>'
						N_delete.disabled = params.data.locked

						if (N_delete.disabled) {
							N_delete.setAttribute('tooltip', 'Actuellement utilisé')
							N_delete.classList.add('btn-grey-200')
						}
						else {
							N_delete.addEventListener('click', async () => {
								try {
									await OperatingDataDefinitionService.getInstance().delete(params.data._id)
									params.api?.applyTransaction({ remove: [params.data] })
									toaster.success(`Donnée d'exploitation supprimée`)
								}
								catch (e) {
									toaster.error(`Erreur de supression`)
									console.error(e);
								}
							})
						}


						N_edit.addEventListener('click', () => {
							new AddEditOperatingDataDefinition(params.data._id).open().then(() => this.load())
						})

						N_duplicate.addEventListener('click', () => {
							new AddEditOperatingDataDefinition(params.data._id, true).open().then(() => this.load())
						})

						div.appendChild(N_duplicate)
						div.appendChild(N_edit)
						div.appendChild(N_delete)

						return div

					}
				}

			],
			rowData: [],
			suppressDragLeaveHidesColumns: true,
			defaultColDef: {
				floatingFilter: true,
				sortable: true,
				resizable: true,
				filter: 'agTextColumnFilter',
				floatingFilterComponentParams: { suppressFilterButton: true },
				suppressMenu: true,
				lockPinned: true
			},
			getContextMenuItems: () => [],
			onGridReady: () => this.load()
		})

		const N_grid = this.el.querySelector('#grid-definitions') as HTMLElement
		new Grid(N_grid, this.gridOptions)
	}

	private async load() {
		const data = await OperatingDataDefinitionService.getInstance().getAll()
		this.gridOptions.api?.setRowData(data)
	}

	public destructor() {

	}
}


export default DefinitionsTab

import { Modal } from "@autoprog/core-client"
import moment from 'moment'
import { Grid, GridOptions } from 'ag-grid-community'
import 'ag-grid-enterprise'

import T_viewDetailsStock from '@tpl/modals/exploitation/viewDetailsStock.html'

import DatePickerRangeFilter from '@libs/agGrid/DatePickerRangeFilter'

import unitList from '@libs/lists/unitList'
import { french as agGridFrench } from '@libs/utils/agGrid'

import Export from '@libs/agGrid/Export'

import _ from 'lodash'
import SitesService from '@js/libs/services/SitesService'
import Site from '@js/types/site'
import Product from '@js/types/products'
import ProductsService from '@js/libs/services/ProductsService'
import StockEvent from '@js/types/stock-event'
import StocksService from '@js/libs/services/StocksService'

class ViewDetailsStock extends Modal {

	private gridOptions?: GridOptions;
	private site: string = ''
	private idStorage!: string

	constructor(idStorage: string, site: string) {

		super({
			tpl: T_viewDetailsStock,
			backdrop: 'static',
			keyboard: false
		});

		this.on('opened', async () => {
			this.site = site
			this.idStorage = idStorage
			this.init()
		})
	}


	private async init() {
		let site = await this.getStorageBySite()

		let products = await this.getAllProduct()

		let gridEl = this.element.querySelector('#details-stock-grid')! as HTMLElement
		let N_name = this.element.querySelector('#box')!

		let storageData = _.find(site.storages || [], { type: 'box', id: this.idStorage });

		N_name && (N_name.innerHTML = storageData ? storageData.name : this.idStorage);

		let rowData = await this.getDataByStorage(this.idStorage || '')

		this.gridOptions = agGridFrench({
			columnDefs: [{
				headerName: 'Produit',
				field: 'product',
				rowGroup: true,
				hide: true,
				cellRenderer: (params) => {
					if (params.value && !params.node.rowPinned) {
						let tmp = _.find(products, { _id: params.value });
						
						return tmp ? tmp.reference : params.value;
					} else {
						return '';
					}
				}
			}, {
				headerName: "Date",
				field: "date",
				filter: 'agDateColumnFilter',
				sort:'desc',
				floatingFilterComponent: DatePickerRangeFilter,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				filterParams: {
					suppressAndOrCondition: true,
					inRangeInclusive: true,
					comparator: (filterValue: Date, cellValue: number) => {

						let filter = parseInt(moment(filterValue).startOf('day').format('x'));
						let cell = parseInt(moment(cellValue).startOf('day').format('x'))

						if (cell < filter) {
							return -1;
						} else if (cell > filter) {
							return 1
						}

						return 0
					}
				},
				cellRenderer: (params) => {
					if (params.value && !params.node.rowPinned) {
						return moment(params.value, 'x').format('DD/MM/YYYY HH:mm');
					} else {
						return '';
					}
				}
			}, {
				headerName: 'Type',
				field: 'type',
				filter: 'agSetColumnFilter',
				valueGetter: (params) => {

					if (params.data) {
						return ({
							reset: 'RAZ',
							input: 'Entrée',
							alimentation: 'Alimentation',
							output: 'Sortie'
						} as { [key: string]: string })[params.data.type] || '';
					}
				},
				cellRenderer: (params) => {
					return params.value;
				}
			}, {
				headerName: 'Unité',
				filter: 'agSetColumnFilter',
				aggFunc: (params) => {
					return params;
				},
				cellRenderer: (params) => {

					if (!params.node.rowPinned) {

						if (params.data) {
							const product = _.find(products, { _id: params.data.product });

							if (product) {
								return unitList[product.unit].name || product.unit;
							}

							return '';
						}
						else {
							const product = _.find(products, { _id: params.node.key }) as Product
							return unitList[product.unit].name;
						}

					}

					return params.value || '';

				}
			}, {
				headerName: 'Quantité',
				filter: 'agNumberColumnFilter',
				field: 'quantity',
				aggFunc: (params) => {
					return params;
				},
				cellClass: 'text-right',
				cellRenderer: (params) => {

					if (!params.node.rowPinned) {

						if (params.data) {

							const product = _.find(products, { _id: params.data.product }) as Product
							const value = params.value / unitList[product.unit].coeficient

							return _.round(value, 2)

						} else {

							const groups = params.node.childrenAfterFilter;

							const product = _.find(products, { _id: params.node.key }) as Product
							let sum = 0;

							for (let group of groups) {

								let quantity = group.data.quantity

								if (group.data.type == 'output' || group.data.type == 'alimentation') {
									quantity = -1 * quantity
								}

								sum += quantity
							}

							sum /= unitList[product.unit].coeficient;

							return _.round(sum, 2);
						}

					}
					return params.value || '';
				}
			}, {
				headerName: 'Quantité sans la freinte',
				field: 'quantity',
				filter: false,
				aggFunc: (params) => {
					return params;
				},
				cellClass: 'text-right',
				cellRenderer: (params) => {

					if (!params.node.rowPinned) {

						if (params.data) {

							if (params.data.type == 'input') {

								let product = _.find(products, { _id: params.data.product });

								if (product) {

									let value = params.value / unitList[product.unit].coeficient;

									value = value * (1 - params.data.wastage);

									if (value >= 0) {
										return _.round(value, 2).toString()
									}

								}

								if (params.value >= 0) {
									return _.round(params.value, 2).toString()
								}

							}

						} else {

							let groups = params.node.childrenAfterFilter;

							let sum = 0;

							for (let group of groups) {

								let quantity = group.data.quantity * (1 - group.data.wastage);

								if (group.data.type == 'output' || group.data.type == 'alimentation') {
									quantity = -1 * quantity;
								}

								sum += quantity;

							}

							const product = _.find(products, { _id: params.node.key }) as Product

							sum /= unitList[product.unit].coeficient;

							return _.round(sum, 2).toString()
						}

						return '---';
					}

					return '';
				}
			}, {
				headerName: 'Quantité perdu',
				field: 'quantity',
				filter: false,
				cellClass: 'text-right',
				cellRenderer: (params) => {

					if (!params.node.rowPinned) {

						let columnDefs = params.columnApi.columnController.columnDefs;

						let quantityValue = columnDefs[4].cellRenderer(params);

						let quantity = parseFloat(quantityValue);
						let quantityWastage = parseFloat(columnDefs[5].cellRenderer(params));

						if (!isNaN(quantityWastage) && !isNaN(quantityWastage)) {
							return _.round(quantity - quantityWastage, 2).toString()
						} else {
							return '---';
						}

					}

					return '';

				}
			}],
			rowData,
			groupMultiAutoColumn: true,
			suppressAggFuncInHeader: true,
			suppressDragLeaveHidesColumns: true,
			floatingFilter: true,
			sideBar: {
				toolPanels: [{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,						
					}
				}]
			},			
			defaultColDef: {
				resizable: true,
				sortable: true,
				suppressMenu: true,				
				filter: "agTextColumnFilter",
			},
			getRowStyle: (params: any) => {
				if (params.node.rowPinned) {
					return { 'font-weight': 'bold' };
				}
			},
			getContextMenuItems: (params) => {

				let exportAllExcel = {
					name: 'Exporter tous Excel',
					action: () => {
						let exp = new Export(params);
						exp.exportExcel();
					}
				};

				let exportAllCSV = {
					name: 'Exporter tous CSV',
					action: () => {
						let exp = new Export(params);
						exp.exportCSV();
					}
				};

				return [exportAllExcel, exportAllCSV];


			},
			onGridReady: (params) => {

				(params && params.api) && params.api.sizeColumnsToFit();
				this.updatePinnedRow(params, products);

			},
			onRowDataChanged: (params) => {
				this.updatePinnedRow(params, products);
			},
			onFilterChanged: (params) => {
				this.updatePinnedRow(params, products);
			}
		})

		if (this.gridOptions) {
			new Grid(gridEl, this.gridOptions);
		}
	}

	private updatePinnedRow(params: any, products: any[]) {

		if (params.api) {

			let quantity = 0;

			let units: string[] = [];

			params.api.forEachNodeAfterFilter((node: any) => {
				if (!node.childrenAfterFilter) {

					let product = _.find(products, { _id: node.data.product });

					if (product) {
						units.push(product.unit);
					}

					if (node.data.type == 'output' || node.data.type == 'alimentation') {
						quantity -= node.data.quantity;
					} else {
						quantity += node.data.quantity;
					}

				}
			});

			units = _.uniq(units);

			let data = {
				quantity: ''
			}

			if (units.length == 1) {
				data.quantity = _.round(quantity / unitList[units[0]].coeficient, 2) + ' ' + unitList[units[0]].unit;
			} else {
				data.quantity = _.round(quantity, 2).toString()
			}

			params.api.setPinnedBottomRowData([data])
		}

	}

	private async getStorageBySite(): Promise<Site> {
		return SitesService.getInstance().getByID(this.site)
	}

	private async getAllProduct(): Promise<Product[]> {
		return ProductsService.getInstance().getAllProducts()
	}

	private async getDataByStorage(idStorage: string): Promise<StockEvent[]> {
		return StocksService.getInstance().getBySiteStorage(this.site, idStorage)
	}

}

export default ViewDetailsStock

import _ from "lodash"
import IMG_checkbox from '../../../img/checklists/checkbox.svg?raw'
import IMG_date from '../../../img/checklists/date.svg?raw'
import IMG_number from '../../../img/checklists/number.svg?raw'
import IMG_select from '../../../img/checklists/select.svg?raw'
import IMG_text from '../../../img/checklists/text.svg?raw'
import IMG_textarea from '../../../img/checklists/textarea.svg?raw'
import IMG_time from '../../../img/checklists/time.svg?raw'

type StructureDefinitionType = 'checkbox' | 'date' | 'select' | 'number' | 'text' | 'textarea' | 'time'

export interface StructureDefinition {
	id: string
	type: StructureDefinitionType
	text: string
	description: string
}

const TabPreviewImage = {
	'checkbox': IMG_checkbox,
	'date': IMG_date,
	'select': IMG_select,
	'number': IMG_number,
	'text': IMG_text,
	'textarea': IMG_textarea,
	'time': IMG_time
}

type ValueChangedCallback = (newValue: string) => void

export type TableEditorRowInput = {
	elements: Array<HTMLTableDataCellElement>
	valueGetter: () => string
	valueSetter: (value: string) => void
	setChangedCallback: (callback?: ValueChangedCallback) => void
}

class TableEditorHelper {

	private constructor() {}

	private static readonly TabInputsTypes: { [key: string]: (type: string, options?: any) => TableEditorRowInput } = {
		'title1': TableEditorHelper.titleDataInput,
		'title2': TableEditorHelper.titleDataInput,
		'title3': TableEditorHelper.titleDataInput,
		'text': TableEditorHelper.textDataInput,
		'spacer': (_type: string) => TableEditorHelper.spacer(),
		'input': TableEditorHelper.customDatInputs
	}

	public static input(type: string, options?: any) : TableEditorRowInput {
		return this.TabInputsTypes[type](type, options)
	}


	public static titleDataInput(type: string) : TableEditorRowInput {
		const N_cell = document.createElement('td')
		N_cell.classList.add('type-cell')
		N_cell.colSpan = 3

		const N_input = document.createElement('input')
		N_input.classList.add('form-control', 'w-100', type, 'table-cell-editor-value')
		N_input.dataset.name = 'value'

		N_cell.appendChild(N_input)

		return {
			elements: [N_cell],
			valueGetter: () => N_input.value,
			valueSetter: (value: string) => _.set(N_input, 'value', value),
			setChangedCallback: () => {} // Pas la peine de cree un evenement pour le titre
		}
	}


	public static customDatInputs(_type: string, options: { definitions: Array<StructureDefinition>, rootElement?: HTMLElement }) : TableEditorRowInput {
		let callbackValueChanged: ValueChangedCallback | undefined = undefined

		const N_select = document.createElement('select') as HTMLSelectElement
		N_select.classList.add('custom-select', 'table-cell-editor-value')
		N_select.dataset.name = 'value'

		const N_chooser = document.createElement('td') as HTMLTableDataCellElement
		N_chooser.classList.add('type-cell', 'input-type')
		N_chooser.appendChild(N_select)

		const N_description = document.createElement('td') as HTMLTableDataCellElement
		N_description.classList.add('type-cell', 'description')

		const N_preview = document.createElement('td') as HTMLTableDataCellElement
		N_preview.classList.add('type-cell', 'input-preview')
		N_preview.innerHTML = TabPreviewImage['text']

		const sOptions = { data: options.definitions, multiple: false }
		options.rootElement && _.set(sOptions, 'dropdownParent', options.rootElement)

		// TODO: imaginer un systeme de oldValue qui est stocker au moment de l'enregistrement
		$(N_select).select2(sOptions)
		$(N_select).on('change', () => {
			const definition = _.first($(N_select).select2('data')) as { type: string, id: string, description: string } | undefined

			N_description.innerHTML = `<span>${definition?.description || ''}</span>`
			
			N_preview.innerHTML = TabPreviewImage[definition?.type as StructureDefinitionType || 'number']

			_.isEqual(!!callbackValueChanged && !!definition, true) && callbackValueChanged!(definition!.id)
		})

		if (options.definitions.length > 0) {
			$(N_select).val(options.definitions[0].id).trigger('change')
		}

		return {
			elements: [N_chooser, N_description, N_preview],
			valueGetter: () => N_select.value,
			valueSetter: (value: string) => $(N_select).val(value).trigger('change'),
			setChangedCallback(callback?: ValueChangedCallback) {
				callbackValueChanged = callback
			}
		}
	}

	public static textDataInput(type: string) : TableEditorRowInput {
		const N_cell = document.createElement('td')
		N_cell.classList.add('type-cell')
		N_cell.colSpan = 3

		const N_input = document.createElement('textarea') as HTMLTextAreaElement
		N_input.classList.add('form-control', 'w-100', type, 'table-cell-editor-value')
		N_input.dataset.name = 'value'

		N_cell.appendChild(N_input)
		return {
			elements: [N_cell],
			valueGetter: () => N_input.value,
			valueSetter: (value: string) => _.set(N_input, 'value', value),
			setChangedCallback: () => {}
		}
	}

	public static spacer() : TableEditorRowInput {
		const N_cell = document.createElement('td')
		N_cell.classList.add('type-cell', 'spacer')

		return {
			elements: [N_cell, N_cell.cloneNode() as HTMLTableDataCellElement, N_cell.cloneNode() as HTMLTableDataCellElement],
			valueGetter: () => "",
			valueSetter: (_value: string) => {},
			setChangedCallback: () => {}
		}
	}

}


export default TableEditorHelper

import { Modal, Form, global, Alert, LoggedUser } from "@autoprog/core-client"
import { EditableCallbackParams, Grid, GridOptions, ICellRendererParams } from 'ag-grid-community'
import FilesManager from '@js/libs/FilesManager'
import toastr from 'toastr'
import moment from 'moment'
import T_addEditDelivery from '@tpl/modals/orders/addEditDelivery.html'
import NumericCellEditor from '@libs/agGrid/NumericCellEditor'
import SelectOrders from '@js/libs/modals/SelectOrders'
import rawMaterialProperties from '@libs/lists/rawMaterialPropertiesList'
import { Tier } from "@js/types/Tiers"
import typeTransportList from '@libs/lists/typeTransportList'
import unitList from '@libs/lists/unitList'
import drpC from '@libs/utils/daterangepickerConfig'
import { french as agGridFrench } from '@libs/utils/agGrid'
import Prompt from '@libs/modals/prompt'


import { AccountingAccountsService, DeliveryNotesService, SitesService, ProductsService, OrdersService, TiersService } from "@js/libs/services"

import OrdersManager from '@js/libs/managers/OrdersManager'
import { ProductsInfoList } from '@js/libs/models/OrderInfo'

import Order from '@js/types/order'
import DeliveryNote, { DeliveryNoteQualityCriteria } from '@js/types/delivery-note'
import TypeDataEvent from '@js/libs/lists/TypeDataEvent'
import { Storage } from "@js/types/storage"

import 'select2'
import 'select2/dist/css/select2.min.css'
import EventsManager, { EventsManagerEvent } from '@js/libs/EventsManager'
import { SearchOptions } from "select2"

interface AddEditDeliveryData extends DeliveryNote {
	orderData: AddEditOrderData
}

interface AddEditOrderData extends Order {
	customerData: Tier
	providerData: Tier
}

type DictionnaryProductsDelivered = { [idRowProduct: string]: number }

class AddEditDelivery extends Modal {

	private gridOptions: GridOptions = {}
	private filesManager: FilesManager = new FilesManager()
	private formDelivery: Form | null = null
	private listStorages: Storage[] = []

	private dataOrder?: AddEditOrderData
	private isInfiniteBudget: boolean = false

	private orderInfo: ProductsInfoList

	public constructor(private idDeliveryNote?: string, private duplicateMode: boolean = false) {

		super({
			tpl: T_addEditDelivery,
			backdrop: 'static',
			keyboard: false
		})

		this.orderInfo = {}

		this.on('opened', async () => {

			try {

				this.formDelivery = new Form(this.element.querySelector('#delivery-form') as HTMLFormElement)

				const N_transportType = this.element.querySelector('#transportType') as HTMLSelectElement
				for (let key in typeTransportList) {
					let option = new Option(typeTransportList[key], key);
					N_transportType.append(option);
				}

				//On récupère le template du fileManager
				const N_attachments = this.element.querySelector('#attachments') as HTMLElement
				N_attachments.appendChild(this.filesManager.getNode())

				$(this.element).find('.date-picker').daterangepicker(drpC({
					singleDatePicker: true
				}))

				this.lastValueTransport()

				this.listStorages = await SitesService.getInstance().getStorages(global.SITE)

				this.createAgGrid()

				//Récupération du bouton "Ajout BC"
				const addLinkedOrderBtn = this.element.querySelector('#btnAddLinkedOrder') as HTMLButtonElement;
				addLinkedOrderBtn.disabled = this.idDeliveryNote !== undefined && this.idDeliveryNote !== null && this.idDeliveryNote.trim() !== ''

				addLinkedOrderBtn.addEventListener2('click', () => {
					const modal = new SelectOrders('BL');
					this.hide()
					modal.open().then((idOrder: string) => {
						this.show()
						this.loadOrder(idOrder)
					}).catch(() => { this.show() })
				})

				//Event sur clique bouton "Sauvegarder"
				const N_saveBtn = this.element.querySelector('#save') as HTMLButtonElement;
				N_saveBtn.addEventListener('click', () => this.save())

				// Chargement du delivery note
				this.idDeliveryNote && this.loadDeliveryNote()

				const N_addLoadingZone = this.element.querySelector('#add-loading-zone') as HTMLElement
				const N_selectLoadingZone = this.element.querySelector('#loadingZone') as HTMLSelectElement

				N_addLoadingZone.addEventListener2('click', async () => {

					const name = await (new Prompt('Ajout d\'une commune')).open()

					if (name) {
						const id = this.dataOrder!.type === "input" ? this.dataOrder!.customerData._id : this.dataOrder!.providerData._id
						TiersService.getInstance().addPlace(id, { name })
						N_selectLoadingZone.appendChild(new Option(name, name, true, true))
					}

				})

			}
			catch (e) {
				console.error(e)
			}

		})
	}

	private async save() {
		// Règle spécials : Regarde si les champs sont à zéro quantités

		// Il faut qu'il est au moins une commande avec une quantité
		if (this.hasZeroCommand()) {
			Alert.info('Commande impossible', 'Il faut au minimum une commandes avec une quantité non négative.');
			return
		}

		// On regarde si un des emplacements renseigné est non renseigné (vide)
		if (!this.isEmplacementFill()) {
			try {
				await Alert.confirm('Confirmation', "Emplacement non-renseigné. Voulez vous valider?")
			}
			catch {
				// Dans le cas d'une annulation, on continue pas notre action et revient en arrière 
				return
			}
		}

		// On vérifie que le client a droit de commander plus que la quantité renseigné dans le bon de commande
		// S'il n'a pas le droit de commander plus, il faut s'assurer que la quantité délivré est inférieur ou égale
		if (!this.isInfiniteBudget && !LoggedUser.getInstance().hasPermission('ORDERS.BL.OPTIONS.LIMIT_QUANTITY') && this.hasExceedLimitOrder()) {
			Alert.error('Erreur de commande', "Vous avez excéder la limite de la commande autorisé")
			return
		}

		try {
			// On crée en base de données notre Bon de livraison
			const deliveryID = await this.createDeliveryNote()

			// On synchronise nos fichiers
			await DeliveryNotesService.getInstance().syncAttachments(deliveryID, this.filesManager.getArrayFiles())

			if (this.idDeliveryNote && !this.duplicateMode) {
				toastr.success('Bon de livraison modifié')
			}
			else {
				toastr.success('Bon de livraison ajouté')
			}

			this.resolve()
		}
		catch (e) {
			this.reject(e)
			toastr.error('Erreur de base de données')
			console.error(e)
		}

	}

	private createAgGrid(): void {

		//DeliveredProductsGrid
		const N_gridDeliveryProduct = this.element.querySelector('#grid-deliveredProducts') as HTMLElement;
		const storagesList: Array<Storage|string> = this.listStorages
		storagesList.unshift('')

		//GridOptions Tableau Produits livrées
		this.gridOptions = agGridFrench({
			columnDefs: [
				{
					headerName: 'Produit',
					field: 'reference',
				},
				{
					headerName: 'Unité',
					field: 'unit',
					cellRenderer: (params: ICellRendererParams) => unitList[params.value]?.name || ''
				},
				{
					headerName: 'Qté commandée',
					field: 'orderedQuantity',
					cellClass: 'ag-cell-focused-grey'
				},
				{
					headerName: 'Qté déjà livrée',
					field: 'alreadyDeliveredQuantity',
					cellClass: 'ag-cell-focused-grey'
				},
				{
					headerName: 'Qté livrée',
					field: 'deliveredQuantity',
					editable: true,
					cellEditor: NumericCellEditor,
				},
				{
					headerName: 'Emplacement',
					field: 'storage',
					cellEditor: 'agRichSelectCellEditor',
					keyCreator: (storage) => storage.name,
					cellEditorParams: {
						values: storagesList,
						cellRenderer: (params: ICellRendererParams) => params?.value?.name || ''
					},
					cellRenderer: (params: ICellRendererParams) => params?.value?.name || '',
					editable: true
				}
			],
			suppressDragLeaveHidesColumns: true,

			defaultColDef: {
				resizable: true,
				suppressMenu: true,

			},
			onCellValueChanged: (params) => {

				if (params.colDef.field == 'deliveredQuantity') {

					if (!params.newValue && params.newValue != 0) {
						params.data.deliveredQuantity = params.oldValue;
					}

					if (params.api) {
						let subTotal = 0 as number;
						params.api.forEachNode((rowNode) => {

							if (rowNode.data.deliveredQuantity as number) {
								subTotal += rowNode.data.deliveredQuantity
							}
						})

						params.api.setPinnedBottomRowData([{
							deliveredQuantity: `Total : ${subTotal}`,
						}]);
					}
				}

				params.node.setData(params.data);
			},
			onGridReady: () => this.updateGrid()
		})

		if (this.gridOptions.columnDefs) {
			for (let key in rawMaterialProperties) {

				this.gridOptions.columnDefs.push({
					headerName: rawMaterialProperties[key].name,
					field: 'qualityCriteria.' + key,
					editable: (params: EditableCallbackParams) => {
						if (params.data) {
							const accountingAccount = AccountingAccountsService.getInstance().getById(params.data.accountingAccount)
							return !!accountingAccount?.isRawMaterial;
						}

						return false
					},
					cellRenderer: (params) => {

						if (params.data) {
							let accountingAccount = AccountingAccountsService.getInstance().getById(params.data.accountingAccount)
							if (accountingAccount && accountingAccount.isRawMaterial) {
								return (params.value || '') + unitList[rawMaterialProperties[key].unit].unit
							}
						}
						return '----';

					},
					hide: true,
					cellEditor: NumericCellEditor
				})

			}
		}

		//On intancie la grid
		new Grid(N_gridDeliveryProduct, this.gridOptions)
	}

	private async loadDeliveryNote(): Promise<void> {

		const deliveryNote = await this.getAddEditDeliveryData(this.idDeliveryNote!)

		this.dataOrder = deliveryNote.orderData;

		this.isInfiniteBudget = !!this.dataOrder.isInfiniteBudget

		// REMARK: il y a forcement une commande qui existe
		// On va chercher les informations lié à la commande sélectionné
		// On reprend les informations de la commande sans notre bon de livraison actuel
		this.orderInfo = this.duplicateMode
			? await OrdersManager.getOrderInfo(this.dataOrder._id)
			: await OrdersManager.getOrderInfoFilterDeliveryOrder(this.dataOrder._id, deliveryNote._id)

		if (this.formDelivery) {

			const zone = deliveryNote.orderData.type === 'input' ? deliveryNote.orderData.providerData : deliveryNote.orderData.customerData
			this.setSelectLoadingZone(zone)

			const obj: any = {
				...deliveryNote,
				date: moment(deliveryNote.date),
				orderNumber: deliveryNote.orderData._id,
				deliveryNumber: deliveryNote._id,
				customer: deliveryNote.orderData.customerData.name,
				provider: deliveryNote.orderData.providerData.name,
				detail: deliveryNote.orderData.detail,
				state: deliveryNote.state,
				immatriculation: {
					id: deliveryNote.immatriculation,
					text: deliveryNote.immatriculation,
				},
				driver: {
					id: deliveryNote.driver,
					text: deliveryNote.driver,
				},
				carrier: {
					id: deliveryNote.carrier,
					text: deliveryNote.carrier,
				},
				parcel: {
					id: deliveryNote.parcel,
					text: deliveryNote.parcel
				}
			}

			if (deliveryNote.weights) {
				obj.weights = JSON.stringify(deliveryNote.weights)
			}

			if (this.duplicateMode) {
				delete obj._id
				delete obj._rev
				delete obj.deliveryNumber
				delete obj.state
			}

			this.formDelivery.setData(obj)
		}

		const hasNotPermissionToExceedTheLimit = !LoggedUser.getInstance().hasPermission('ORDERS.BL.OPTIONS.LIMIT_QUANTITY')

		type DeliveryNoteRow = {
			rowID: string,
			id: string,
			reference: string,
			orderedQuantity: number,
			alreadyDeliveredQuantity: number,
			deliveredQuantity: number,
			unit: string,
			unitPrice: number,
			qualityCriteria?: DeliveryNoteQualityCriteria,
			storage: any
		}

		if (this.gridOptions.api) {

			const rows = [] as Array<DeliveryNoteRow>
			let drawQualityCols = false;

			if (deliveryNote.products) {


				for (let product of deliveryNote.products) {

					const orderedProduct = deliveryNote.orderData.products!.find(prod => prod.rowID === product.rowID)!
					const accountingAccount = AccountingAccountsService.getInstance().getById(orderedProduct.accountingAccount)

					if (accountingAccount && accountingAccount.isRawMaterial) {
						drawQualityCols = true;
					}

					// Calcule des differents quantites
					const alreadyDeliveredQuantity = this.orderInfo[product.rowID].getTotalOrdered() / unitList[product.unit].coeficient
					const orderedQuantity = product.orderedQuantity / unitList[product.unit].coeficient

					let deliveredQuantity = product.deliveredQuantity / unitList[product.unit].coeficient
					const hasExceedLimit = (alreadyDeliveredQuantity + deliveredQuantity) > orderedQuantity

					// Dans le cas d'une duplication, on reajuste le prix
					if (this.duplicateMode && !this.isInfiniteBudget && hasNotPermissionToExceedTheLimit && hasExceedLimit) {
						deliveredQuantity = orderedQuantity - alreadyDeliveredQuantity
					}

					rows.push({
						rowID: product.rowID,
						id: product.id,
						reference: product.reference,
						orderedQuantity,
						alreadyDeliveredQuantity,
						deliveredQuantity,
						unit: product.unit,
						unitPrice: product.unitPrice * unitList[product.unit].coeficient,
						qualityCriteria: product.qualityCriteria,
						storage: this.listStorages.find(storage => storage.id === product.storage)
					})
				}

				if (drawQualityCols && this.gridOptions.columnApi) {
					for (let key in rawMaterialProperties) {
						this.gridOptions.columnApi.setColumnVisible('qualityCriteria.' + key, true);
					}
				}

			}

			this.gridOptions.api.setRowData(rows)
			this.gridOptions.api.sizeColumnsToFit()
		}

		deliveryNote._attachments && this.filesManager.setFiles(deliveryNote._attachments)
	}

	private async getAddEditDeliveryData(deliveryId: string): Promise<AddEditDeliveryData> {
		const deliveryNote = await DeliveryNotesService.getInstance().getByID(deliveryId)
		const order = await OrdersService.getInstance().getByID(deliveryNote.order)
		const [customerData, providerData] = await Promise.all([
			TiersService.getInstance().getByID(order.customer),
			TiersService.getInstance().getByID(order.provider)
		])

		return {
			...deliveryNote,
			orderData: {
				...order,
				customerData,
				providerData
			}
		}
	}

	private async loadOrder(idOrder: string): Promise<void> {

		// FIXME: recupération de la donnée 2 fois, vraiment moche

		this.dataOrder = await this.getAddEditOrder(idOrder)

		this.isInfiniteBudget = !!this.dataOrder.isInfiniteBudget

		// REMARK: il y a forcement une commande qui existe
		// On va chercher les informations lié à la commande sélectionné
		this.orderInfo = await OrdersManager.getOrderInfo(this.dataOrder._id)

		if (this.formDelivery) {
			this.formDelivery.setDataByName('detail', this.dataOrder.detail)
			this.formDelivery.setDataByName('provider', this.dataOrder.providerData.name)
			this.formDelivery.setDataByName('customer', this.dataOrder.customerData.name)
			this.formDelivery.setDataByName('orderNumber', this.dataOrder.orderNumber)
		}

		if (this.dataOrder.type == 'input') {
			this.setSelectLoadingZone(this.dataOrder.providerData);
		} else {
			this.setSelectLoadingZone(this.dataOrder.customerData);
		}

		const productIds = this.dataOrder.products!.map(product => product.id) || []
		const products = await ProductsService.getInstance().getByIDs(productIds)

		if (this.gridOptions.api) {

			let rows = [];

			let drawQualityCols = false;

			for (let product of this.dataOrder.products || []) {

				let accountingAccount = AccountingAccountsService.getInstance().getById(product.accountingAccount)

				if (accountingAccount && accountingAccount.isRawMaterial) {
					drawQualityCols = true;
				}

				let productData = products.find(pro => pro._id === product.id)

				rows.push({
					id: product.id,
					rowID: product.rowID,
					reference: product.reference,
					orderedQuantity: product.quantity / unitList[product.unit].coeficient,
					alreadyDeliveredQuantity: this.orderInfo[product.rowID].getTotalOrdered() / unitList[product.unit].coeficient,
					deliveredQuantity: 0,
					unit: product.unit,
					unitPrice: product.unitPrice * unitList[product.unit].coeficient,
					qualityCriteria: { wastage: productData?.wastage, dryMatter: productData?.dryMatter, organicMatter: productData?.organicMatter, potentialMethane: productData?.potentialMethane }
				});

			}

			if (drawQualityCols && this.gridOptions.columnApi) {
				for (let key in rawMaterialProperties) {
					this.gridOptions.columnApi.setColumnVisible('qualityCriteria.' + key, true);
				}
			}

			this.gridOptions.api.setRowData(rows);
			this.gridOptions.api.sizeColumnsToFit();
		}

	}



	private hasZeroCommand() {
		const rows = [] as Array<{ deliveredQuantity: number }>
		this.gridOptions.api?.forEachNode(rowNode => rows.push(rowNode.data))
		return rows.filter(x => x.deliveredQuantity !== 0).length === 0
	}


	/**
	 * Vérifie que un des emplacements des produit est vide ou non
	 */
	private isEmplacementFill() {
		//On récupère les lignes du tableau des produits livrées
		const rows = [] as Array<{ storage?: { id?: string } }>
		this.gridOptions.api?.forEachNode(rowNode => rows.push(rowNode.data))
		//On met la quantité délivrée à la bonne unité
		for (let row of rows) {
			if (!row.storage || !row.storage.id) {
				return false
			}
		}

		return true
	}

	/**
	 * Vérifie qu'il n'a pas dépasser la limite des commandes autorisés.
	 * Cette règle devrait s'appliquer seulement si les autorisations sont neccessaires
	 */
	public hasExceedLimitOrder(): boolean {
		const allData = this.getAllDeliveredProduct()

		// Comparaison entre les données
		for (let idRow in allData) {
			// REMARK: le produit existe forcement dans la commande
			const unit = this.orderInfo[idRow].unit
			const quantity = allData[idRow] * unitList[unit].coeficient + this.orderInfo[idRow].getTotalOrdered()
			if (quantity > this.orderInfo[idRow].maxOrdered) {
				return true
			}
		}

		return false
	}

	public getAllDeliveredProduct(): DictionnaryProductsDelivered {
		const result: DictionnaryProductsDelivered = {}

		this.gridOptions.api?.forEachNode(rowNode => {
			result[rowNode.data.rowID] = rowNode.data.deliveredQuantity
		})

		return result
	}

	// TODO: faire une fonction pour montrer la différence dans la commande

	/**
	 * Permet d'ajouter un nouveau BL
	 */
	private async createDeliveryNote(): Promise<string> {

		if (this.formDelivery) {

			const data = this.formDelivery.getData()
			const id = (data.deliveryNumber || "BL-" + moment().format('YYYY-MM-DD-HHmmss')).toString()

			const deliveryNote: DeliveryNote = {
				_id: id,
				deliveryNumber: id,
				date: parseInt((data.date as moment.Moment).format('x')),
				transportType: data.transportType as string,
				order: data.orderNumber as string,
				carrier: data.carrier as string,
				driver: data.driver as string,
				comment: data.comment as string,
				immatriculation: data.immatriculation as string,
				loadingZone: data.loadingZone as string,
				parcel: data.parcel as string,
				deleted: false,
				place: '',
				products: [],
				state: data.state as string || 'notBilled',
				deliveryReceiver: data.deliveryReceiver as string
			}

			if (data.weights && data.weights !== '') {
				deliveryNote.weights = JSON.parse(data.weights as string)
			}


			//On récupère les lignes du tableau des produits livrées
			const rows = [] as any[]
			this.gridOptions.api && this.gridOptions.api.forEachNode(rowNode => rows.push(rowNode.data))

			deliveryNote.products = rows.map(row => ({
				deliveredQuantity: row.deliveredQuantity * unitList[row.unit].coeficient,
				orderedQuantity: row.orderedQuantity * unitList[row.unit].coeficient,
				unitPrice: row.unitPrice / unitList[row.unit].coeficient,
				storage: (row.storage && row.storage.id) ? row.storage.id : '',
				qualityCriteria: row.qualityCriteria,
				rowID: row.rowID,
				id: row.id,
				reference: row.reference,
				unit: row.unit
			}))

			const addOrUpdate = this.idDeliveryNote && !this.duplicateMode ? this.updateIntoDatabase : this.addIntoDatabase
			await addOrUpdate(deliveryNote)

			await OrdersService.getInstance().updateOrder(deliveryNote.order)

			await OrdersService.getInstance().updateDeliveryProducts(deliveryNote.order)

			return id
		}

		throw new Error("formDelivery doesnt exist")
	}

	private async addIntoDatabase(delivery: DeliveryNote): Promise<void> {
		delivery = EventsManager.addEvent(delivery, TypeDataEvent.create)
		await DeliveryNotesService.getInstance().create(delivery)
	}

	private async updateIntoDatabase(delivery: DeliveryNote): Promise<void> {
		const olderDeliveryNote = await DeliveryNotesService.getInstance().getByID(delivery._id)
		delivery._attachments = olderDeliveryNote._attachments
		delivery.events = olderDeliveryNote.events

		const event: EventsManagerEvent = {
			type: TypeDataEvent.edit,
		}

		if (olderDeliveryNote.state != delivery.state) {
			event.changeState = {
				from: olderDeliveryNote.state,
				to: delivery.state
			}
		}

		delivery = EventsManager.addEvent(delivery, event);
		await DeliveryNotesService.getInstance().update(delivery)

	}

	/**
	 * Permet de chercher une commande avec les informations de son client et fournisseur
	 * @param orderId 
	 */
	private async getAddEditOrder(orderId: string): Promise<AddEditOrderData> {
		let order = await OrdersService.getInstance().getByID(orderId)
		let customerData = await TiersService.getInstance().getByID(order.customer)
		let providerData = await TiersService.getInstance().getByID(order.provider)

		return {
			...order,
			customerData,
			providerData
		}
	}

	private setSelectLoadingZone(provider: Tier): void {
		let N_selectLoadingZone = this.element.querySelector('#loadingZone') as HTMLSelectElement;
		let N_addLoadingZone = this.element.querySelector('#add-loading-zone') as HTMLButtonElement;

		N_addLoadingZone.disabled = false;

		while (N_selectLoadingZone.firstChild) {
			N_selectLoadingZone.removeChild(N_selectLoadingZone.firstChild)
		}

		if (provider.places) {
			for (let place of provider.places) {
				let option = document.createElement('option');
				option.innerHTML = `<option value="${place.name}"> ${place.name} </option>`;
				N_selectLoadingZone.appendChild(option);
			}
		}
	}

	private updateGrid(rows?: any[]): void {
		this.gridOptions.api?.setRowData(rows || [])
	}

	private lastValueTransport() {

		const createTag = (params: SearchOptions) => {
			const term = params.term.trim()
			return term === '' ? null : {
				id: term,
				text: term
			}
		}


		const N_parcel = this.element.querySelector('[name="parcel"]') as HTMLSelectElement
		const N_carrier = this.element.querySelector('[name="carrier"]') as HTMLSelectElement
		const N_driver = this.element.querySelector('[name="driver"]') as HTMLSelectElement
		const N_immatriculation = this.element.querySelector('[name="immatriculation"]') as HTMLSelectElement

		$(N_parcel).select2({
			ajax: { url: `/api/delivery-notes/search/${global.SITE}/parcel` },
			dropdownParent: $(N_parcel.parentElement as HTMLElement),
			tags: true,
			createTag
		});

		$(N_carrier).select2({
			ajax: { url: `/api/delivery-notes/search/${global.SITE}/carrier` },
			dropdownParent: $(N_carrier.parentElement as HTMLElement),
			tags: true,
			createTag
		});

		$(N_driver).select2({
			ajax: { url: `/api/delivery-notes/search/${global.SITE}/driver` },
			dropdownParent: $(N_driver.parentElement as HTMLElement),
			tags: true,
			createTag
		});

		$(N_immatriculation).select2({
			ajax: { url: `/api/delivery-notes/search/${global.SITE}/immatriculation` },
			dropdownParent: $(N_immatriculation.parentElement as HTMLElement),
			tags: true,
			createTag
		})

	}

}
export default AddEditDelivery

import { Modal } from "@autoprog/core-client"
 
import moment from 'moment'
import { Grid, GridOptions } from 'ag-grid-community'
import 'ag-grid-enterprise'

import { calendarBdd } from '@js/types/calendar';

import T_listRecall from '@tpl/modals/calendar/listRecall.html';

import { french as agGridFrench } from '@libs/utils/agGrid'
import drpC from '@libs/utils/daterangepickerConfig'
import EventsService from '@js/libs/services/EventsService'

import _ from 'lodash'

class ListRecallModal extends Modal {

    private calendars: calendarBdd[] = [];
    private gridOptions: GridOptions = {};
    private endDate: moment.Moment = moment().add(6, 'month');

    constructor(calendars: calendarBdd[]) {

        super({
            tpl: T_listRecall,
            backdrop: 'static',
            keyboard: false
        });


        this.calendars = calendars;

        this.gridOptions = agGridFrench({
            suppressDragLeaveHidesColumns: true,
            suppressContextMenu: true,
            groupSuppressAutoColumn: true,
            suppressAggFuncInHeader: true,
            defaultColDef: {
                suppressMenu: true,
                floatingFilterComponentParams: { suppressFilterButton: true },
                resizable: true
            },
            columnDefs: [{
                headerName: 'Date',
                showRowGroup: 'date',
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    suppressCount: true,
                },
                width: 140,
                suppressSizeToFit: true
            }, {
                field: 'date',
                hide: true,
                rowGroup: true
            }, {
                headerName: 'Titre',
                field: 'title',
                aggFunc: (params) => {
                    return {
                        get(): any {
                            return params;
                        }
                    }
                },
                cellRenderer: (params) => {

                    if (params.data) {

                        let result = `<div class="ag-grid-circle" style="border-color: ${params.data.color}; background-color:  ${params.data.color};">&nbsp;</div>`;

                        result += `<span class="ag-grid-circle-text">${params.value}</span>`

                        return result;
                    } else {
                        let data = params.value.get();
                        return data.length + ` rappel${data.length == 1 ? '' : 's'}`;
                    }

                }
            }, {
                headerName: 'Description',
                field: 'description'
            }],
            onGridReady: (params: any) => {
                params.api.sizeColumnsToFit();
            }
        });

        this.on('opened', () => {

            if (this.element) {
                let N_grid = this.element.querySelector('#grid');

                if (N_grid) {
                    new Grid(N_grid as HTMLElement, this.gridOptions);
                }
            }


            this.loadData();

            this.initDatePicker();

        });

    }

    private async loadData(): Promise<void> {

        // FIXME: optimiser cette requête
        const events = await EventsService.getInstance().getByDates(Date.now(), +this.endDate.format('x'))

        let data = []
        for (let event of events) {

            let calendar = _.find(this.calendars, { _id: event.calendarId }) as { [key: string]: any };

            if (calendar?.isRecall) {

                let date = moment(event.start, 'x');

                if (date.isSameOrBefore(this.endDate)) {

                    data.push({
                        date: date.format('DD/MM/YYYY'),
                        title: event.title,
                        description: event.description || '',
                        color: calendar.bgColor
                    })

                }

            }

        }

        this.gridOptions.api && this.gridOptions.api.setRowData(data);
    }

    private initDatePicker():void {

        $(this.element).find('#date').daterangepicker(drpC({
            singleDatePicker: true,
            startDate: this.endDate,
        }), (start, _end, _label) => {

            this.endDate = start;

            this.loadData();

        });

    }

}

export default ListRecallModal;
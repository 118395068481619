import AlimentationPredictive from "@js/types/alimentation-predictive"
import axios from "axios"

class AlimentationPredictiveService {
	private static readonly instance = new AlimentationPredictiveService()

	private constructor() {}

	public static getInstance() : AlimentationPredictiveService {
		return AlimentationPredictiveService.instance
	}


	public async getAll() : Promise<Array<AlimentationPredictive>> {
		return axios.get('/api/alimentation-predictive/').then(response => response.data.data)
	}

	public async create(aliment: AlimentationPredictive) : Promise<void> {
		await axios.post('/api/alimentation-predictive', aliment)
	}

	public async update(aliment: AlimentationPredictive) : Promise<void> {
		await axios.put('/api/alimentation-predictive', aliment)
	}

	/**
	 * Permet de récupérer les aliments prédictive dans un période donnée ou non
	 * @param siteId Identifiant du site en question
	 * @param startingDate Début de la période -> example: 2020_07
	 * @param endingDate Fin de la période -> example: 2020_08
	 */
	public async getBySite(siteId: string, startingDate: string | null = null, endingDate: string | null = null) {
		if (startingDate && endingDate) {
			return this._getBySiteAndDate(siteId, startingDate, endingDate)
		}

		return this._getBySite(siteId)
	}

	private async _getBySite(siteId: string) : Promise<Array<AlimentationPredictive>> {
		return axios.get(`/api/alimentation-predictive/by-site/${siteId}`).then(response => response.data.data)
	}

	private async _getBySiteAndDate(siteId: string, startingDate: string, endingDate: string) {
		return axios.get(`/api/alimentation-predictive/by-site-and-date/${siteId}/${startingDate}/${endingDate}`).then(response => response.data.data)
	}

	public async updateAll(data: Array<AlimentationPredictive>) : Promise<void> {
		await axios.put(`/api/alimentation-predictive/update-all`, data)
	}
}

export default AlimentationPredictiveService

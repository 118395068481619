export class OrderInfo {
	/**
	 * Constructeur
	 * @param id Identifiant du produit
	 * @param maxOrdered le nombre commandé dans le bon de livraison
	 * @param deliveryOrdered le nombre commandé dans le bon d'intervention
	 * @param workOrdered le nombre max commandable pour ce produit
	 * @param unit l'unité de prix
	 */
	public constructor(
		public readonly rowId: string,
		public readonly productId: string, 
		public readonly maxOrdered: number, 
		public readonly deliveryOrdered: number, 
		public readonly workOrdered: number,
		public readonly unit: string
	) {}

	/**
	 * Renvoie la quantité totale commandé sans le coeficient
	 */
	public getTotalOrdered() : number {
		return this.deliveryOrdered + this.workOrdered
	}

	/**
	 * Permet de savoir si on peut encore livrer plus
	 */
	public canOrderMore() : boolean {
		return (this.maxOrdered - this.getTotalOrdered()) > 0
	}

	/**
	 * Permet de svoir si la limite a été dépassé
	 */
	public isLimitExceed() {
		return (this.maxOrdered - this.getTotalOrdered()) < 0
	}

}

/**
 * Dictionnary OrderInfo
 */
export type ProductsInfoList = {
	[rowId: string] : OrderInfo
}

export default OrderInfo
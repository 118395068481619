/**
* Ce fichier est un utilitaire pour hacher les mots de passes
*/

import { sha256 } from 'hash.js'

class PasswordUtil {

	private constructor() {}

	/**
	 * Permet de hasher un mot de passe
	 * @param passwordStr mot de passe à hasher
	 */
	public static hashPassword(passwordStr: string) : string {
		return sha256().update(passwordStr).digest('hex');
	}

}


export default PasswordUtil
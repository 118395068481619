import { GridOptions } from "ag-grid-community";
import { sha1 } from 'hash.js'

class AgGridStateSaver {

	private gridOptions: GridOptions
	private name: string;
	private identifier: string


	constructor(gridOptions: GridOptions, name: string, ignoreChange?: boolean) {

		this.gridOptions = gridOptions;
		this.name = name;
		this.identifier = this.name;

		if (gridOptions.api) {

			let blacklist = [
				'api',
				'columnApi'
			];

			if (!ignoreChange) {
				this.identifier += '-' + sha1().update(JSON.stringify(gridOptions, (key, value) => {
					if (blacklist.indexOf(key) < 0) {

						if (typeof value == 'function') {
							return value.toString();
						}
						return value;

					}
				})).digest('hex');

				for (let key in localStorage) {
					if (key.startsWith(this.name) && key != this.identifier) {
						delete localStorage[key];
					}
				}

			}

			gridOptions.api.addEventListener('columnEverythingChanged', this.save.bind(this));
			gridOptions.api.addEventListener('columnVisible', this.save.bind(this));
			gridOptions.api.addEventListener('columnPinned', this.save.bind(this));
			gridOptions.api.addEventListener('columnResized', this.save.bind(this));
			gridOptions.api.addEventListener('columnRowGroupChanged', this.save.bind(this));
			gridOptions.api.addEventListener('columnValueChanged', this.save.bind(this));
			gridOptions.api.addEventListener('columnMoved', this.save.bind(this));
			gridOptions.api.addEventListener('columnGroupOpened', this.save.bind(this));
			gridOptions.api.addEventListener('filterChanged', this.save.bind(this));
			gridOptions.api.addEventListener('sortChanged', this.save.bind(this));

			gridOptions.api.addEventListener('firstDataRendered', this.load.bind(this));

			this.load();
		}

	}


	public save(): void {
		if (this.gridOptions.columnApi && this.gridOptions.api) {

			let columnGroupState = this.gridOptions.columnApi.getColumnGroupState();
			let columnState = this.gridOptions.columnApi.getColumnState();

			localStorage[this.identifier] = JSON.stringify({
				columnState,
				columnGroupState,
				filters: this.gridOptions.api.getFilterModel(),
			});
		}
	}

	public load(): void {
		if (this.gridOptions.columnApi && this.gridOptions.api) {
			if (localStorage[this.identifier]) {
				let obj = JSON.parse(localStorage[this.identifier])
				this.gridOptions.columnApi.setColumnGroupState(obj.columnGroupState);
				this.gridOptions.api.setFilterModel(obj.filters);
				this.gridOptions.columnApi.applyColumnState({ state: obj.columnState })
			}
		}
	}


}

export default AgGridStateSaver;
import { ICellEditorComp, ICellEditorParams } from 'ag-grid-community'
import UnitList, { XUnit } from '@js/libs/lists/unitList'

/**
 * Cette classe est pour seulement la facturation (AddEditBill)
 */
class QuantityCellEditor implements ICellEditorComp {

	private input: HTMLInputElement
	private unit!: XUnit

	constructor() {
		this.input = document.createElement('input')
	}

	public getValue() {
		return +this.input.value * this.unit.coeficient
	}

	public getGui(): HTMLElement {
		return this.input
	}

	public init?(params: ICellEditorParams): void {
		this.unit = UnitList[params.data.product.unit]
		this.input.value = (params.value / this.unit.coeficient).toString()
	}
}

export default QuantityCellEditor
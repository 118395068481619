class ServiceCache {

	private static instances: Map<string, ServiceCache> = new Map();

	public static getInstance(name: string) {
		if (!ServiceCache.instances.has(name)) {
			ServiceCache.instances.set(name, new ServiceCache(name));
		}
		return ServiceCache.instances.get(name);
	}

	//---------------------------------------------------------

	private pendingIDs: Map<string, boolean>
	private data: Map<string, any>
	private waitingResolves: Map<string, ((value?: unknown) => void)[]>

	public constructor(_name: string) {

		this.pendingIDs = new Map();
		this.data = new Map();
		this.waitingResolves = new Map();
	}

	public isPending(ID: string) {
		return this.pendingIDs.get(ID);
	}

	public setPending(ID: string, pending: boolean) {
		this.pendingIDs.set(ID, pending);
	}

	public setData(ID: string, data: any, timeout: number = 500) {
		
		this.data.set(ID, data);
		this.pendingIDs.set(ID, false);

		let resolves = this.waitingResolves.get(ID) || [];
		for (let resolve of resolves) {
			resolve(data);
		}

		setTimeout(() => {
			this.data.delete(ID);
		}, timeout)
	}

	public hasData(ID:string): boolean{
		return this.data.has(ID);
	}
	
	public getData(ID:string):any{
		return this.data.get(ID);
	}

	public async waitData(ID: string) {
		if (this.data.has(ID)) {
			return this.data.get(ID);
		} else {
			let resolves = this.waitingResolves.get(ID) || [];
			let promise = new Promise((resolve, _reject) => {
				resolves.push(resolve);
			})
			this.waitingResolves.set(ID, resolves);
			return promise;
		}
	}


	public async gatherData(ID:string, getDataCB: (ID:string)=>any){

		if (!this.isPending(ID)) {

			if(this.hasData(ID)){
				return this.getData(ID)
			}

			this.setPending(ID, true);
			let data = await getDataCB(ID);
			this.setData(ID, data);
			return data;
		} else {
			return await this.waitData(ID);
		}

	}



}

export default ServiceCache
import axios from "axios"
import ExpenseReport from '@js/types/expenseReport'
import { FilesManagerFile } from "../FilesManager"
import FilesManager2 from "../FilesManager2"

/**
 * Classe pour les Notes de Frais en base de données
 */
class ExpenseReportService {

	public static readonly TABLE = "expensereport"
	private static readonly instance = new ExpenseReportService()

	private constructor() {}

	public static getInstance() : ExpenseReportService {
		return ExpenseReportService.instance
	}

	/**
	 * Permet de récupérer tous les notes de frais en fonction du site, date de départ, date arrivé et non obligatoire un user ID
	 * @param siteId 
	 * @param startingDate 
	 * @param endingDate 
	 * @param userId Non obligatoire
	 */
	public async getByDateUser(siteId: string, startingDate: number, endingDate: number, userId: string | null) : Promise<Array<ExpenseReport>> {
		const requestUserId = userId || "null"
		return axios.get(`/api/expensereport/by-site-date-user/${siteId}/${startingDate}/${endingDate}/${requestUserId}`)
			.then(response => response.data.data)
	}

	/**+
	 * Permet de supprimer une note de frais
	 */
	public async delete(id: string) : Promise<void> {
		await axios.get(`/api/expensereport/tag-delete/${id}`)
	}

	public async lock(id: string) : Promise<void> {
		await axios.get(`/api/expensereport/lock/${id}`)
	}

	public async unlock(id: string) : Promise<void> {
		await axios.get(`/api/expensereport/unlock/${id}`)
	}

	public async syncAttachment(id: string, files: Array<FilesManagerFile>) : Promise<void> {
		await FilesManager2.synchronizeFiles(ExpenseReportService.TABLE, id, files)
	}

	public async create(data: ExpenseReport) : Promise<void> {
		await axios.post(`/api/expensereport`, data)
	}

	public async update(data: ExpenseReport): Promise<void> {
		await axios.put(`/api/expensereport`, data)
	}

	public async getByID(id: string) : Promise<ExpenseReport> {
		return axios.get(`/api/expensereport/${id}`).then(response => response.data.data)
	}
}

export default ExpenseReportService
import { Modal, Form, Permissions } from "@autoprog/core-client"
import { RegistredPermissions } from "@autoprog/core-client/dist/js/libs/Permissions";

import 'select2'
import toastr from 'toastr'

import T_addGroup from '@tpl/modals/settings/addGroup.html'


import BDD from '@js/libs/BDD';
import HoursCategories from '@js/libs/services/HoursCategories'
import GroupsService from '@js/libs/services/GroupsService'
import { GroupData } from '@js/types/settings'
import DictionnaryUtils, { BooleanDictionary } from '@js/libs/utils/dictionnaryUtils'

type subTree = { [key: string]: subTree }

class AddEditGroup extends Modal {

	private permissions: Permissions

	private N_permissions: HTMLElement | null
	private form?: Form

	private id?: string

	constructor(id?: string) {

		super({
			tpl: T_addGroup,
			backdrop: 'static',
			keyboard: false
		});

		this.permissions = {};
		this.N_permissions = null;


		this.on('opened', async () => {

			this.N_permissions = this.element.querySelector('#permissions') as HTMLElement;
			this.form = new Form(this.element.querySelector('#add-group-form') as HTMLFormElement);
			this.id = id

			let hoursCategories = HoursCategories.getInstance().getAll();
			let select2HoursCategories = hoursCategories.map(hourCategory => { return { id: hourCategory._id, text: hourCategory.label } })

			$('#hoursCategories-select').select2({ data: select2HoursCategories, dropdownParent: $(this.element) });

			this.permissions = Permissions.get() as RegistredPermissions;

			if (this.N_permissions && this.form) {
				this.drawPermissions(this.permissions);
				this.form.updateInputs();
				this.checkboxEvents();
			}

			this.loadData()

			let N_saveButton = this.element.querySelector('#save-group-button') as HTMLButtonElement;
			N_saveButton.addEventListener2('click', () => this.save());
		})
	}

	private async loadData() {
		if (this.id) {
			(this.element.querySelector('.modal-title') as HTMLElement).innerHTML = 'Modification Groupe';

			let group = await GroupsService.getInstance().getByID(this.id)
			if (this.form) {
				this.fillForm(group)
				if (this.N_permissions) {
					Array.prototype.slice.call(this.N_permissions.querySelectorAll('input')).reverse().forEach(this.updateInputState.bind(this))
				}
			}
		}
	}

	private async save() {
		if (this.form) {

			const newData = this.form.getData();

			const group = (this.id) ? await GroupsService.getInstance().getByID(this.id) : this.createEmptyGroup()

			group.name = newData.name as string
			group.orderLimit = newData.orderLimit as number
			group.permissions = newData.permissions as BooleanDictionary
			group.hoursCategories = newData.hoursCategories as string[]
			group.has_mail_prevalidation_order = newData.checkboxMailValidationOrder as boolean
			group.has_notification_mail_prevalidated_order = newData.checkboxMailPrevalidationOrder as boolean

			try {
				let functionToUse = (this.id) ? GroupsService.getInstance().update : GroupsService.getInstance().create
				await functionToUse(group)
				toastr.success('Groupe enregistré')
				this.resolve()
			}
			catch (e) {
				toastr.error(`Erreur lors de l'enregistrement`)
				this.reject()
			}
		}
	}

	// TODO: le mettre dans GroupsService
	private createEmptyGroup(): GroupData {
		return { 
			name: "", 
			hoursCategories: [], 
			orderLimit: 0, 
			permissions: {}, 
			site: "", 
			_id: BDD.generateID(), 
			has_mail_prevalidation_order: false, 
			has_notification_mail_prevalidated_order: false 
		}
	}

	/**
	 * Remplit 
	 * @param groupData 
	 */
	private fillForm(groupData: GroupData) {

		this.form = this.form ? this.form : new Form(this.element.querySelector('#add-group-form') as HTMLFormElement);
		this.form.setData({
			name: groupData.name,
			orderLimit: groupData.orderLimit,
			hoursCategories: groupData.hoursCategories,
			checkboxMailValidationOrder: groupData.has_mail_prevalidation_order,
			checkboxMailPrevalidationOrder: groupData.has_notification_mail_prevalidated_order
		})

		// Rajout des permissions
		const persmissionsList: Array<string> = DictionnaryUtils.extractKeys(groupData.permissions)

		for (const permission of persmissionsList) {
		
			try {
				this.form.setDataByName('permissions.' + permission, true);
			} catch (e) {

			}
		}

	}

	private drawPermissions(permissions: Permissions | null, parents?: string[]): HTMLUListElement {

		parents = parents || []
		let level = parents.length

		let N_ul = document.createElement('ul');
		if (level > 0) {
			N_ul.classList.add('d-none')
		}

		N_ul.classList.add('list-group', 'm-0', 'p-0', 'text-center')
		N_ul.dataset.parents = parents.join('.');

		if (this.N_permissions && permissions) {

			let N_el = this.N_permissions.querySelector('.level-' + level);

			if (!N_el) {
				N_el = document.createElement('div');
				N_el.classList.add('level-' + level);
				N_el.classList.add('col-3');

				this.N_permissions.appendChild(N_el);
			}

			for (let key in permissions) {

				// FIXME: Trouver une solution pour les permisions
				//@ts-ignore
				const permission = permissions[key]
				const N_li = document.createElement('li')
				N_li.classList.add('list-group-item', 'd-flex', 'justify-content-between', 'align-items-stretch', 'p-0', 'pr-3')

				N_li.innerHTML = `
                    <div class="input-group-text">
                        <input type="checkbox" name="permissions.${[...parents, key].join('.')}">
                    </div>
                    <span class="p-2">${permission.name || key}</span>
                `;

				if (permission.children) {

					N_li.innerHTML += `<div class="d-flex align-items-center" ><i class="icon icon-solid-angle-right"></i></div>`

					const N_child = this.drawPermissions(permission.children, [...parents, key])

					N_li.addEventListener('click', () => {

						if (!N_li.classList.contains('active')) {

							let N_active = N_ul.querySelector('.active');
							N_active && N_active.classList.remove('active')

							let i = level + 1
							let N_level = null

							if (this.N_permissions) {

								while (N_level = this.N_permissions.querySelector('.level-' + i)) {

									N_level.querySelectorAll('ul').forEach(N_ul => {
										N_ul.classList.add('d-none')
										N_ul.querySelectorAll('.active').forEach(N_active => N_active.classList.remove('active'))
									})

									i++;
								}

							}

							N_li.classList.add('active')
							N_child.classList.remove('d-none')
						}
					})
				}
				else {
					N_li.innerHTML += `<div class="d-flex align-items-center" ></div>`
				}

				N_ul.appendChild(N_li)
				N_el.appendChild(N_ul)
			}
		}

		return N_ul
	}

	private checkboxEvents(): void {

		if (this.form) {
			this.form.on('permissions.**.input', (e: Event) => {

				if (this.form) {


					let input = e.target as HTMLInputElement;

					let subTree = {
						[input.name]: this.form.getData(input.name)
					}
					let keys = this.getKeys(subTree as subTree);
					for (let key of keys) {
						this.form.setDataByName(key, input.checked)
					}

					this.updateInputState(input);

				}
			})
		}
	}

	private updateInputState(input: HTMLInputElement): void {
		if (this.form) {
			let parentKeys = input.name.split('.').slice(0, -1);

			while (parentKeys.length) {

				let parentName = parentKeys.join('.');

				let v = JSON.stringify(this.form.getData(parentName));

				let trueCount = (v.match(/true/g) || []).length;
				let falseCount = (v.match(/false/g) || []).length;

				let N_parent = this.form.getElementByName(parentName) as HTMLInputElement | null;
			
				if (N_parent) {

					N_parent.indeterminate = false;
					N_parent.checked = false;

					if (trueCount > 0 && falseCount == 0) {
						N_parent.checked = true;
					} else if (trueCount == 0 && falseCount > 0) {
						N_parent.checked = false;
					} else {
						N_parent.indeterminate = (trueCount > 0 && falseCount > 0);
					}


				}

				parentKeys.pop();
			}
		}
	}

	private getKeys(obj: subTree, keys?: string[], parents?: string[]): string[] {

		keys = keys || [];
		parents = parents || [];

		for (let key in obj) {

			keys.push([...parents, key].join('.'));

			if (obj[key]) {
				this.getKeys(obj[key], keys, [...parents, key])
			}


		}

		return keys;
	}



}

export default AddEditGroup;

import { Modal } from "@autoprog/core-client"
import moment from 'moment'

import unitList from '@libs/lists/unitList'
import T_selectDeliveryNotesWorkOrders from '@tpl/modals/orders/selectDeliveryNotesWorkOrders.html'
import { Grid, GridOptions } from 'ag-grid-community'
import { french as agGridFrench } from '@libs/utils/agGrid'

import _ from 'lodash'
import DeliveryNote from '@js/types/delivery-note'
import WorkOrder from '@js/types/workorder'
import DeliveryNotesService from '@js/libs/services/DeliveryNotesService'
import WorkOrdersService from '@js/libs/services/WorkOrdersService'


interface SelectDeliveryNotesWorkOrdersResult {
	deliveryNotes: Array<DeliveryNote>
	workOrders: Array<WorkOrder>
}

class SelectDeliveryNotesWorkOrders extends Modal {

	private gridOptions: GridOptions = {}

	constructor(private orders: string[]) {

		super({
			tpl: T_selectDeliveryNotesWorkOrders,
			backdrop: 'static',
			keyboard: false
		})

		this.on('opened', async () => {

			const result = await this.getRowData()

			const rowData: any[] = [];

			for (let item of result.deliveryNotes) {

				if (item.state != 'billed' && item.state != 'archived') {

					for (let product of item.products) {
						rowData.push({
							number: item.deliveryNumber,
							...item,
							...product,
							type: 'deliveryNotes'
						});
					}

				}

			}

			for (let item of result.workOrders) {

				if (item.state != 'billed' && item.state != 'archived') {

					for (let product of item.products) {
						rowData.push({
							number: item.workOrderNumber,
							...item,
							...product,
							date: item.beginDate,
							type: 'workOrders',
						});
					}

				}

			}

			this.gridOptions = agGridFrench({
				columnDefs: [
					{
						headerName: 'N° BL / BI',
						showRowGroup: 'number',
						cellRenderer: 'agGroupCellRenderer',
						checkboxSelection: (params) => !!params.node.group
					},
					{
						field: 'number',
						rowGroup: true,
						hide: true,
					},
					{
						headerName: 'Date',
						field: 'date',
						cellRenderer: (params) => {
							if (params.data) {
								return '';
							} else {
								return moment(params.value, 'x').format('DD/MM/YYYY');
							}
						},
						aggFunc: (params) => params.values[0]
					},
					{
						headerName: 'Produits',
						field: 'product',
						cellRenderer: (params) => {
							if (params.data) {
								return params.data.reference
							} else {

								let group = params.node.childrenAfterGroup;
								return _.uniq(_.map(group, 'data.reference')).join(',');

							};
						},
						aggFunc: (params) => params.values[0]
					},
					{
						headerName: 'Quantité Livré',
						field: 'deliveredQuantity',
						cellRenderer: (params) => {
							if (params.data) {

								let unit = unitList[params.data.unit];

								return (params.data.deliveredQuantity / unit.coeficient) + ' ' + unit.unit;
							} else {
								let group = params.node.childrenAfterGroup;

								let units = _.map(group, 'data.unit');

								units = _.uniq(units);

								if (units.length == 1) {
									let unit = unitList[units[0]];
									return (params.value / unit.coeficient) + ' ' + unit.unit
								}

								return '';
							}

						},
						aggFunc: 'sum',
					}
				],
				onRowSelected: (params) => {
					let numberSelect = 0;

					const N_save = this.element.querySelector('#save-button') as HTMLButtonElement

					if (params.api) {
						params.api.forEachNode((node) => {
							if (node.isSelected()) {
								numberSelect++;
							}
						})
					}

					if (N_save) {
						N_save.disabled = !!!numberSelect
					}

					this.checkGridSelectedHandler()

				},
				onGridReady: (params) => {
					params.api && params.api.sizeColumnsToFit()
				},
				rowData,
				rowSelection: 'multiple',
				groupMultiAutoColumn: true,
				groupSelectsChildren: true,
				groupSuppressAutoColumn: true,
				suppressAggFuncInHeader: true,
				suppressDragLeaveHidesColumns: true,
				defaultColDef: {
					resizable: true,
					sortable: true,
					filter: 'agTextColumnFilter'
				}
			})

			const N_grid = this.element.querySelector('#grid-delivery') as HTMLElement;
			this.gridOptions && new Grid(N_grid, this.gridOptions)

			const N_save = this.element.querySelector('#save-button');

			if (N_save) {

				N_save.addEventListener('click', () => {

					if (this.gridOptions.api) {

						const deliveryNotes: string[] = [];
						const workOrders: string[] = [];

						this.gridOptions.api.forEachNode((node) => {

							if (node.isSelected() && node.key) {

								let data = node.allLeafChildren[0].data;

								if (data.type == 'workOrders') {
									workOrders.push(data._id);
								}

								if (data.type == 'deliveryNotes') {
									deliveryNotes.push(data._id);
								}

							}

						})

						this.resolve({ deliveryNotes, workOrders });

					}

				});

			}

			this.initSelectAllElement()

		});

	}

	private initSelectAllElement() {
		const N_checkAll = this.element.querySelector('#checkAll') as HTMLInputElement
		N_checkAll.addEventListener2('change', () => this.checkAllChangeHanlder())
	}

	private checkAllChangeHanlder() {
		const N_checkAll = this.element.querySelector('#checkAll') as HTMLInputElement
		if (N_checkAll.checked) {
			this.gridOptions.api?.selectAll()
		}
		else {
			this.gridOptions.api?.forEachNode(node => node.setSelected(false))
		}
	}

	private checkGridSelectedHandler() {
		const N_checkAll = this.element.querySelector('#checkAll') as HTMLInputElement
		let oneUnselected = false

		this.gridOptions.api?.forEachNode(node => {
			if (node.isSelected() === false) {
				oneUnselected = true
			}
		})

		N_checkAll.checked = !oneUnselected
	}

	private async getRowData(): Promise<SelectDeliveryNotesWorkOrdersResult> {
		const [deliveryNotes, workOrders] = await Promise.all([
			Promise.all(this.orders.map(order => DeliveryNotesService.getInstance().getFromOrder(order))).then(_.flatten),
			Promise.all(this.orders.map(order => WorkOrdersService.getInstance().getFromOrder(order))).then(_.flatten)
		])

		return { deliveryNotes, workOrders }
	}

}

export default SelectDeliveryNotesWorkOrders; 
import axios from 'axios'

export type ODDAnalytics = {
	percent: number
	id: string
}

export type OperatingDataDefinition = {
	_id: string,
	label: string
	description: string
	dataType: string,
	dataTypeParams: { [key: string]: any }
	unit?: string,
	analytics: Array<ODDAnalytics>
	subAnalytics?: Array<ODDAnalytics>
}

class OperatingDataDefinitionService {

	private static instance = new OperatingDataDefinitionService()

	private constructor() {

	}

	public static getInstance() {
		return this.instance
	}

	public async getByID(id: string): Promise<OperatingDataDefinition> {
		return axios.get(`/api/operating-data-definition/${id}`).then(response => response.data.data)
	}

	public async create(OperatingDataDefinition: OperatingDataDefinition): Promise<void> {
		await axios.post('/api/operating-data-definition', OperatingDataDefinition)
	}

	public async update(OperatingDataDefinition: OperatingDataDefinition): Promise<void> {
		await axios.put('/api/operating-data-definition', OperatingDataDefinition)
	}

	public async delete(id: string): Promise<void> {
		await axios.delete(`/api/operating-data-definition/${id}`)
	}

	public async getAll(): Promise<Array<OperatingDataDefinition>> {
		return axios.get('/api/operating-data-definition').then(response => response.data.data)
	}

	public async getByIds(ids: Array<string>): Promise<Array<OperatingDataDefinition>> {
		return axios.post(`/api/operating-data-definition/by-ids`, ids).then(response => response.data.data)
	}
}

export default OperatingDataDefinitionService

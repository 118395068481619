import { Tab, toaster } from "@autoprog/core-client"

import _ from 'lodash'

import BillsService from '@js/libs/services/BillsService';


class BillTab extends Tab {

	constructor(private el: HTMLElement) {
		super(el)

		this.init()
	}

	private async init() {

		const N_textarea = this.el.querySelector('#bill-footer') as HTMLTextAreaElement

		N_textarea.value = (await BillsService.getInstance().getFooter()) || ''

		const N_saveBtn = this.el.querySelector('#edit-bill-footer') as HTMLButtonElement
		N_saveBtn.addEventListener('click', async () => {
			try {
				await BillsService.getInstance().set(N_textarea.value)
				toaster.success('Pied de page enregistré')
			} catch (e) {
				toaster.success(`Erreur lors de l'enregistrement du pied de page`)
			}
		})


	}

	public destructor() {
	}


}

export default BillTab;
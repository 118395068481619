const stateList = {
    all: {
        name: 'Tous',
        filterValue: '',
        cls: ''
    },
    toConfirm: {
        name: 'Validation manager demandée',
        filterValue: 'Validation Manager Demandée',
        cls: 'to-confirm'
    },
    preValidate: {
        name: 'En prévalidation',
        filterValue: 'En prévalidation',
        cls: 'pre-validate'
    },
    archived: {
        name: 'Archivé',
        filterValue: 'Archivé',
        cls: 'archived'
    },
    notArchived: {
        name: 'Non archivé',
        filterValue: 'Non archivé',
        cls: 'not-archived'
    },
    notDelivered: {
        name: 'Non livré',
        filterValue: 'Non livré',
        cls: 'not-delivered'
    },
    inDelivering: {
        name: 'En cours de livraison',
        filterValue: 'En cours de livraison',
        cls: 'in-delivering'
    },
    delivered: {
        name: 'Livré',
        filterValue: 'Livré',
        cls: 'delivered'
    },
    notBilled: {
        name: 'Non facturé',
        filterValue: 'Non facturé',
        cls: 'not-billed'
    },
    inBilling: {
        name: 'En cours de facturation',
        filterValue: 'En cours de facturation',
        cls: 'in-billing'
    },
    billed: {
        name: 'Facturé',
        filterValue: 'Facturé',
        cls: 'billed'
    },
    waiting : {
        name: 'En attente',
        filterValue: 'En attente',
        cls: 'waiting'
    },
    recorded : {
        name: 'Comptabilisé',
        filterValue: 'Comptabilisé',
        cls: 'recorded'
    },
    validated : {
        name: 'Validé',
        filterValue: 'Validé',
        cls: 'validated'
    },
    locked : {
        name: 'Bloqué',
        filterValue: 'Bloqué',
        cls: 'locked'
    },
    paid : {
        name: 'Payé',
        filterValue: 'Payé',
        cls: 'paid'
    },
    paying : {
        name: 'En cours de paiement',
        filterValue: 'En cours de paiement',
        cls: 'paying'
    },
    cancel_payment : {
        name: 'Dévalidé',
        filterValue: 'Dévalidé',
        cls: 'cancel-payment'
    }

} as { [state: string]: { name: string, filterValue: string, cls: string } };

export default stateList

import { Tab } from "@autoprog/core-client"

import AddEditOperatingDataDefinition from '@js/libs/modals/settings/AddEditOperatingDataDefinition'

import TabsExtended from "@js/libs/TabsExtended"

import C_definitions from "./DataTab/DefinitionsTab"
import T_definitions from '@tpl/tabs/settings/data/definitions.html'

import C_imported from './DataTab/ImportedTab'
import T_imported from '@tpl/tabs/settings/data/imported.html'

import '@css/settings/data.scss'

class DataTab extends Tab {

	constructor(private el: HTMLElement) {
		super(el)

		const N_nav = this.el.querySelector('#nav-ul-data-menu') as HTMLUListElement
		const N_content = this.el.querySelector('#data-tab-content') as HTMLDivElement
		
		const tabs = new TabsExtended({
			navElement: N_nav,
			renderElement: N_content,
			navTemplate: '<li class="nav-item" permission="${tab.permission}"><a tab-link="${tab.id}" class="nav-link"  href="#">${tab.name}</a></li>'
		})

		tabs.add({
			controller: C_definitions,
			name: 'Definitions',
			template: T_definitions,
			id: 'definitions',
			default: true
		})

		tabs.add({
			controller: C_imported,
			name: 'Importation',
			template: T_imported,
			id: 'imported',
			default: false
		})

		const N_add = this.el.querySelector('#add-button')as HTMLButtonElement

		N_add.addEventListener('click', () => {
			//FIXME: modifier le cors pour qu'on puisse reload la tab entiere courante
			new AddEditOperatingDataDefinition().open().then(() => tabs.showTab('definitions'))
		})
		
	}


}

export default DataTab

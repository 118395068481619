import { Modal, Form, toaster } from "@autoprog/core-client"

import h from 'hyperscript'
import T_addEditVAT from '../../../../tpl/modals/settings/addEditVAT.html'

import VATService, { VAT } from '../../services/VATService'
import AccountingAccountsService from '../../services/AccountingAccountsService'

class AddEditAnalytic extends Modal {

	public constructor(id?: string) {
		super({
			template: T_addEditVAT,
			backdrop: 'static',
			keyboard: false
		});

		this.on('opened', async () => {

			let N_form = this.element.querySelector('form');

			//Remplissage du select des comptes comptable
			const N_accountSelect = this.element.querySelector('#accountingAccount-select') as HTMLElement
			const accounts = AccountingAccountsService.getInstance().getAll()

			for (let account of accounts) {
				N_accountSelect.appendChild(h('option', { value: account._id }, `${account._id} - ${account.name}`))
			}


			const N_save = this.element.querySelector('#save-button')


			if (N_form && N_save) {

				const form = new Form(N_form)



				N_save.addEventListener('click', async () => {

					if (form.checkValidity()) {

						const service = VATService.getInstance()

						const data = form.getData()

						//Gestion des chaines vide
						if (!data._rev) {
							delete data._rev
						}

						const defaultVAT = service.getDefault()
						if (defaultVAT && defaultVAT._id != id && data.default) {
							defaultVAT.default = false
							await service.update(defaultVAT._id, defaultVAT)
						}

						if (id) {
							try {
								await service.update(id, data as VAT);
								toaster.success('TVA mise à jour');
								this.resolve();
							} catch (e) {
								toaster.error(`Mise à jour de la TVA`, 'Erreur')
								console.error(e);
							}
						}
						else {

							try {
								await service.add(data as VAT);
								toaster.success('TVA ajoutée')
								this.resolve();
							} catch (e) {
								toaster.error(`Ajout de la TVA`, 'Erreur')
								console.error(e);
							}

						}



					}

				})

				if (id) {

					try {
						const data = await VATService.getInstance().getById(id)
						form.disableByName('_id')
						data && form.setData(data)
					}
					catch (e) {
						toaster.error(`Chargement de la TVA`, 'Erreur');
						console.error(e);
					}
				}

			}

		})

	}



}

export default AddEditAnalytic;
import { global, Tab, LoggedUser } from "@autoprog/core-client"
import { Grid, GridOptions, MenuItemDef, GridApi, ProcessCellForExportParams, ICellRendererFunc } from 'ag-grid-community'
import 'ag-grid-enterprise'
import moment from 'moment'
import toastr from 'toastr'
import AddEditWorkOrder from '@js/libs/modals/orders/AddEditWorkOrder'
import DetailWorkOrder from '@js/libs/modals/orders/DetailWorkOrder'
import AgGridStateSaver from '@libs/agGrid/StateSaver2'
import unitList from '@libs/lists/unitList'
import stateList from '@libs/lists/stateListMale'
import '@css/orders.scss'
import { AGGridUtils, french as agGridFrench } from '@libs/utils/agGrid'
import Export from '@libs/agGrid/Export'

import h from 'hyperscript'

import DatePickerRangeFilter from '@libs/agGrid/DatePickerRangeFilter'
import humanizeNumber from '@libs/HumanizeNumber';
import displayWeight from '@libs/DisplayWeight';

import M_file from '@libs/modals/orders/Attachments'

import TextFilterAccent from '@libs/agGrid/TextFilterAccent'
import WorkOrderType from '@js/types/workorder'
import Order from '@js/types/order'
import Tier from '@js/types/Tiers'

import { TiersService, OrdersService, WorkOrdersService } from "@js/libs/services"

import '@css/tabs.scss'
import '@css/stateslist.scss'

import _ from 'lodash'

interface TabWorkOrderRowOrder extends Order {
	customerObject?: Tier
	providerObject?: Tier
}

interface TabWorkOrderRow extends WorkOrderType {
	orderData: TabWorkOrderRowOrder
}

class WorkOrder extends Tab {

	private gridOptions: GridOptions = <GridOptions>{};

	private stateAGrid: AgGridStateSaver | undefined

	private states = ['all', 'notBilled', 'billed', 'archived']
	constructor(private el: HTMLElement) {
		super(el)

		this.initQuickFilter()

		this.gridOptions = agGridFrench({
			suppressCopyRowsToClipboard: true,
			sideBar: {
				toolPanels: [{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnFilter: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}],
			},
			excelStyles: [
				{
					id: 'dateHours',
					dataType: 'DateTime',
					numberFormat: {
						format: 'dd/mm/yyyy hh:MM',
					}
				},
				{
					id: 'date',
					dataType: 'DateTime',
					numberFormat: {
						format: 'dd/mm/yyyy;@',
					}
				},
				{
					id: 'number',
					dataType: 'Number'
				}
			],
			columnDefs: [
				{
					headerName: "Etat",
					field: "state",
					filter: 'agSetColumnFilter',
					pinned: 'left',
					filterValueGetter: (params) => {
						if (params.data && params.data.state) {
							return stateList[params.data.state].name
						}
					},
					cellRenderer: (params) => {

						if (params.node.level == 0 && params.value && stateList[params.value]) {
							return stateList[params.value].name
						}
						return ''

					},
					aggFunc: 'first'
				},
				{
					headerName: "N° Bon d'intevention",
					showRowGroup: 'groupKey',
					colId: 'workOrderNumber',
					cellRenderer: 'agGroupCellRenderer',
					pinned: 'left',
					sort: 'desc',
					filterValueGetter: function (params) {

						return params.data ? params.data.workOrderNumber : null;

					},
					valueGetter: (params) => {
						if (!params.node?.rowPinned) {
							if (params.node?.level === 0 && params.data.workOrderNumber) {
								return params.data.workOrderNumber
							}

							return '';
						}

						return params.data.workOrderNumber
					}
				},
				{
					field: "groupKey",
					rowGroup: true,
					hide: true,
					suppressColumnsToolPanel: true
				},
				{
					field: "_id", //Ne pas supprimer cette colonne, utilisé pour avoir l'_id dans les aggData
					hide: true,
					aggFunc: 'first',
					suppressColumnsToolPanel: true
				},
				{
					headerName: 'Bon de commande',
					field: 'orderData._id',
					aggFunc: 'first',
					valueGetter: (params) => {
						if (params.data && params.data.orderData && params.data.orderData._id) {
							return params.data.orderData._id
						}

						return ""
					}
				},
				{
					headerName: 'Date début',
					field: 'beginDate',
					filter: 'agDateColumnFilter',
					cellClass: 'dateHours',
					floatingFilterComponent: DatePickerRangeFilter,
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					filterParams: {
						filterOptions: ['inRange', 'empty'],
						suppressAndOrCondition: true,
						inRangeInclusive: true,
						comparator: (filterValue: Date, cellValue: number) => {

							const filter = parseInt(moment(filterValue).startOf('day').format('x'));
							const cell = parseInt(moment(cellValue).startOf('day').format('x'))

							if (cell < filter) {
								return -1;
							} else if (cell > filter) {
								return 1
							}

							return 0
						}
					},

					cellRenderer: (params) => {
						if (params.node.level == 0 && params.value) {
							if (params.type == 'excel') {

								return moment(params.value, 'x').format('YYYY-MM-DDTHH:mm');
							}

							return moment(params.value, 'x').format('DD/MM/YYYY HH:mm');
						}
						return '';
					},
					aggFunc: 'first'
				},
				{
					headerName: 'Date fin',
					field: 'endDate',
					filter: 'agDateColumnFilter',
					cellClass: 'dateHours',
					filterParams: {
						comparator: (filterValue: Date, cellValue: number) => {

							const filter = parseInt(moment(filterValue).startOf('day').format('x'));
							const cell = parseInt(moment(cellValue).startOf('day').format('x'))

							if (cell < filter) {
								return -1;
							} else if (cell > filter) {
								return 1
							}

							return 0
						}
					},
					cellRenderer: (params) => {
						if (params.node.level == 0 && params.value) {
							if (params.type == 'excel') {

								return moment(params.value, 'x').format('YYYY-MM-DDTHH:mm');
							}

							return moment(params.value, 'x').format('DD/MM/YYYY HH:mm');
						}
						return '';
					},
					aggFunc: 'first'
				},
				{
					headerName: 'Fournisseur',
					field: 'orderData.providerObject',
					filter: 'agSetColumnFilter',
					filterValueGetter: (params) => {
						return _.get(params.data, 'orderData.providerObject.name');
					},
					cellRenderer: (params) => {
						if (params.node.level == 0 && params.value) {
							if (params.value.name) {
								return params.value.name
							} else {
								return params.value
							}
						}
						return '';
					},
					aggFunc: 'first'
				},
				{
					headerName: 'Référence',
					field: 'product',
					aggFunc: 'concat'
				},
				{
					headerName: 'Quantité Commandée',
					field: 'orderedQuantity',
					cellClass: (params) => {

						if (params.node.rowPinned == 'bottom') {
							return 'text-right';
						}

						return ['text-right', 'number']
					},
					filter: 'agNumberColumnFilter',
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return params.value;
						}

						const unit = this.getUnit(params)
						let value = '';
						if (unit) {
							value = displayWeight(params.value, unit)
						}

						if (params.type == 'excel') {
							return parseFloat(value.replace(/\s/g, ''))
						}

						return value
					},
					aggFunc: 'sum',
				},
				{
					headerName: 'Unité',
					field: "orderedQuantity",
					aggFunc: 'sum',
					width: 80,
					suppressSizeToFit: true,
					cellRenderer: (params) => {

						if (params.value) {
							return this.getUnit(params) || '';
						}

						return '';
					}
				},
				{
					headerName: 'Quantité Livrée',
					field: 'deliveredQuantity',
					filter: 'agNumberColumnFilter',
					cellClass: (params) => {

						if (params.node.rowPinned == 'bottom') {
							return 'text-right';
						}

						return ['text-right', 'number']
					},
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return params.value;
						} else {
							let unit = this.getUnit(params);
							let value = '';
							if (unit) {
								value = displayWeight(params.value, unit);
							}

							if (params.type == 'excel') {
								return parseFloat(value.replace(/\s/g, ''))
							}

							return value;
						}
					},
					aggFunc: 'sum',
				}, {
					headerName: 'Unité',
					field: "deliveredQuantity",
					aggFunc: 'sum',
					width: 80,
					suppressSizeToFit: true,
					cellRenderer: (params) => {

						if (params.value) {
							return this.getUnit(params) || '';
						}

						return '';
					}
				}, {
					headerName: 'Detail de la commande',
					field: 'orderData.detail',
					hide: true,
					cellRenderer: (params) => {
						if (params.node.level == 0 && params.value) {
							return params.value
						}
						return '';
					},
					aggFunc: 'first'
				}, {
					headerName: `Detail de l'intervention`,
					field: 'detail',
					hide: true,
					cellRenderer: (params) => {
						if (params.node.level == 0 && params.value) {
							return params.value
						}
						return '';
					},
					aggFunc: 'first'
				}, {
					headerName: 'Client',
					field: 'orderData.customerObject',
					filter: 'agSetColumnFilter',
					hide: true,
					filterValueGetter: (params) => params.data.orderData.customerObject.name,
					cellRenderer: (params) => {
						if (params.node.level == 0 && params.value) {
							if (params.value.name) {
								return params.value.name
							} else {
								return params.value
							}
						}
						return '';
					},
					aggFunc: 'first'
				}, {
					headerName: '',
					field: '_id',
					colId: 'actions',
					resizable: false,
					filter: false,
					pinned: 'right',
					width: 100,
					cellRenderer: (params) => {

						const div = document.createElement('div')

						if (params.node.level == 0 && params.value && !params.node.rowPinned) {

							const data = params.node.data || params.node.aggData

							const infoButton = document.createElement('button')
							infoButton.classList.add('btn', 'btn-xs', 'btn-info')

							infoButton.innerHTML = '<i class="icon icon-solid-eye"> </i>';

							infoButton.addEventListener('click', () => {
								const modal = new DetailWorkOrder(params.value);
								modal.open().then((edited) => {
									_.isEqual(!!edited, true) && this.updateGrid()
								})
							})

							const deleteButton = document.createElement('button')
							deleteButton.classList.add('btn', 'btn-xs', 'btn-danger')
							deleteButton.setAttribute('confirmation', "")

							deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"> </i>';

							deleteButton.addEventListener2('click', async () => {
								const orderId = data.orderData ? data.orderData._id : params.node.allLeafChildren[0].data.orderData._id
								await this.deleteRow(params.value, orderId)
								toastr.success(`Suppression du BI : ${params.value}`)
								this.updateGrid()
							})

							div.appendChild(infoButton)

							// Ajout du bouton supprimer
							_.isEqual(_.includes(['billed', 'archived'], data.state), false) && div.appendChild(deleteButton)

							const attachments = (params.node.data) ? data._attachments : params.node.allLeafChildren[0].data._attachments

							if (attachments) {

								let N_attachments = document.createElement('button');

								N_attachments.classList.add('btn', 'btn-xs', 'btn-grey');
								N_attachments.innerHTML = `<i class="icon icon-solid-paperclip"></i>`;

								N_attachments.addEventListener2('click', () => {

									let values = Object.keys(attachments)

									if (values.length == 1) {
										window.open(`${global.COUCHDB_URL}/work-orders/${data._id}/${values[0]}`, 'target')
									} else {
										new M_file('work-orders', data._id, values).open()
									}

								})

								div.appendChild(N_attachments)

							}

						}
						
						return div
					},
					aggFunc: 'first'
				},
			],
			getContextMenuItems: (params) => {

				const result: MenuItemDef[] = []

				if (params.columnApi) {
					const columnKeys = AGGridUtils.getColumnsIds(this.gridOptions, 'actions')

					const exportAllExcel: MenuItemDef = {
						name: 'Exporter tous Excel',
						action: () => {
							const exp = new Export(params)
							exp.exportExcel({
								columnKeys
							})
						}
					}

					const exportAllCSV: MenuItemDef = {
						name: 'Exporter tous CSV',
						action: () => {
							const exp = new Export(params)
							exp.exportCSV({
								columnKeys
							})
						}
					};

					result.push(exportAllExcel, exportAllCSV)

					const data = params.node.aggData || params.node.data;

					if (data) {

						const detail: MenuItemDef = {
							name: "Voir détails",
							action: () => {
								if (data) {
									const modal = new DetailWorkOrder(data._id);
									modal.open();
								}
							}
						}

						result.push(detail)

						const duplicate: MenuItemDef = {
							name: "Dupliquer",
							action: () => {
								if (data) {
									const modal = new AddEditWorkOrder(data._id, true)
									modal.open().then(() => this.updateGrid())
								}
							}
						}

						LoggedUser.getInstance().hasPermission('ORDERS.BI.MENU.DUPLICATE') && result.push(duplicate)
					}
				}

				return result;

			},

			// floatingFilter: true,
			groupMultiAutoColumn: true,
			groupSuppressAutoColumn: true,
			suppressAggFuncInHeader: true,
			suppressDragLeaveHidesColumns: true,
			aggFuncs: {
				concat: (params) => params.values.join(', ')
			},
			getRowClass: (params) => {
				let data = params.node.aggData || params.data;

				if (data) {
					return stateList[data.state] ? 'ag-row-' + stateList[data.state].cls : '';
				} else {
					return '';
				}
			},
			defaultColDef: {
				floatingFilter: true,
				filter: 'agTextColumnFilter',
				suppressMenu: true,
				resizable: true,
				sortable: true,
				filterParams: {
					filterOptions: ['contains'],
					textCustomComparator: TextFilterAccent,
					excelMode: 'windows'
				}
			},
			rowData: [],
			//onGridReady: () => this.updateGrid(), On ne charge plus les données ici car la méthode `updateSelectSite` le fait déja lors du chargement de l'onglet
			onRowDataChanged: () => {
				this.updatePinnedRowData();
			},
			onFilterChanged: () => {
				this.updatePinnedRowData();
			},
			processCellForClipboard: params => this.processCellCallback(params as any)

		});

		let N_grid = this.el.querySelector('#grid-workOrder') as HTMLElement;
		if (this.gridOptions) {
			new Grid(N_grid, this.gridOptions);
		}

		let N_addWorkOrder = this.el.querySelector('#button-add-workOrder') as HTMLButtonElement;
		N_addWorkOrder.addEventListener2('click', () => {
			let modal = new AddEditWorkOrder();
			modal.open().then(() => {
				this.updateGrid()
			}).catch(() => {
			})
		});

		const N_filterWorkOrder = this.el.querySelector('#quick-filter-workorder') as HTMLInputElement
		N_filterWorkOrder.addEventListener2('input', async () => {
			(this.gridOptions.api as GridApi).setQuickFilter(_.deburr(N_filterWorkOrder.value))
		})

	}


	private processCellCallback(params: ProcessCellForExportParams) {

		let colDef = params.column.getColDef();

		let value = params.value || '';

		if (colDef && colDef.cellRenderer && typeof colDef.cellRenderer == 'function') {
			value = (colDef.cellRenderer as ICellRendererFunc)({
				data: (params.node || { data: {} }).data,
				colDef,
				...params
			});
		}

		return value;
	}

	public async updateSelectSite(_value: string) {
		this.stateAGrid && this.stateAGrid.save();

		const currentFilterModel = this.gridOptions.api?.getFilterModel() || {}
		
		this.stateAGrid = new AgGridStateSaver(this.gridOptions, global.SITE + "_workOrder", `Onglet Bon d'Intervention ${global.SITE}`);
		await this.updateGrid();
		this.stateAGrid.load();
		//Garder le filtre du site précédent
		if (_.isEmpty(currentFilterModel)) {
			const values = this.states.filter(state => state !== 'all' && !!stateList[state]).map(state => stateList[state].name)			
			this.gridOptions.api?.setFilterModel({state: {type: 'set',values}})
		} else {
			this.gridOptions.api?.setFilterModel(currentFilterModel)
		}
		this.gridOptions.api?.onFilterChanged()

	}


	private updatePinnedRowData() {

		let orderedQuantity: { [key: string]: any } = {};
		let deliveredQuantity: { [key: string]: any } = {};

		if (this.gridOptions.api) {

			this.gridOptions.api.forEachNodeAfterFilter((node) => {

				let data = _.cloneDeep(node.data);

				if (data && data.unit) {

					let valueOrdered = data.orderedQuantity;
					let valueDelivery = data.deliveredQuantity;

					if (data.unit == 'tonne') {
						valueOrdered *= unitList['tonne'].coeficient;
						valueDelivery *= unitList['tonne'].coeficient;
						data.unit = 'kg';
					}

					orderedQuantity[data.unit] = orderedQuantity[data.unit] || [];
					deliveredQuantity[data.unit] = deliveredQuantity[data.unit] || [];

					if (!_.isUndefined(valueOrdered)) {
						orderedQuantity[data.unit].push(valueOrdered);
					}

					if (!_.isUndefined(valueDelivery)) {
						deliveredQuantity[data.unit].push(valueDelivery);
					}

				}

			});

			let average: { [key: string]: any } = {
				orderedQuantity: '',
				deliveredQuantity: ''
			};
			let sum: { [key: string]: any } = {
				orderedQuantity: '',
				deliveredQuantity: ''
			};

			for (let unit in orderedQuantity) {

				let value = _.sum(orderedQuantity[unit]);

				sum.orderedQuantity += `${humanizeNumber(value)} ${unitList[unit].unit} `;

				let valueAverage = humanizeNumber(_.round(value / orderedQuantity[unit].length, 2))

				average.orderedQuantity += `${valueAverage} ${unitList[unit].unit} `

			}

			for (let unit in deliveredQuantity) {

				let value = _.sum(deliveredQuantity[unit]);

				sum.deliveredQuantity += `${humanizeNumber(value)} ${unitList[unit].unit} `;

				let valueAverage = humanizeNumber(_.round(value / deliveredQuantity[unit].length, 2))

				average.deliveredQuantity += `${valueAverage} ${unitList[unit].unit} `

			}

			average.workOrderNumber = 'Moyenne';
			sum.workOrderNumber = 'Somme';

			this.gridOptions.api.setPinnedBottomRowData([average, sum]);

		}

	}

	public destructor() {
		this.stateAGrid && this.stateAGrid.save();

	}

	private initQuickFilter() {

		let N_quickFilters = this.el.querySelector("#quick-filters") as HTMLElement;		

		this.states.forEach((key: string) => {

			let state = stateList[key]

			let N_btn = h(`button.btn.btn-sm.btn-rounded.mr-1.btn-state-${state.cls}`, state.name, {
				onclick: () => {

					if (this.gridOptions && this.gridOptions.api) {

						if (key == 'all') {

							let values = [];

							for (let key of this.states) {
								if (stateList[key]) {
									values.push(stateList[key].name)
								}
							}

							this.gridOptions.api.setFilterModel({
								state: {
									type: 'set',
									values
								}
							});

						} else {
							this.gridOptions.api.setFilterModel({
								state: {
									type: 'set',
									values: [state.name]
								}
							})
						}

						this.gridOptions.api.onFilterChanged();
					}
				}
			})

			N_quickFilters.appendChild(N_btn);

		});

	} 

	private async getRowData(): Promise<Array<TabWorkOrderRow>> {
		const workorders = await WorkOrdersService.getInstance().getBySite(global.SITE)

		const ordersNumber = _.uniq(workorders.map(workorder => workorder.order))
		const orders = await OrdersService.getInstance().getByIds(ordersNumber)

		const tiersNumber = _.uniq(orders.flatMap(order => [order.customer, order.provider]))
		const tiers = await TiersService.getInstance().getByIds(tiersNumber)

		let result: Array<TabWorkOrderRow> = workorders.reduce((cumul, workOrder) => {
			const order = orders.find(o => o._id == workOrder.order)!
			cumul.push({
				...workOrder, orderData: {
					...order,
					providerObject: tiers.find(t => t._id === order.provider)!,
					customerObject: tiers.find(t => t._id === order.customer)!
				}
			})
			return cumul
		}, [] as Array<TabWorkOrderRow>)

		return result
	}

	private async updateGrid() {

		try {
			//recuperation des données de la base
			const data = await this.getRowData()
			//insertion des donnée dans le tableau /// syntaxe possible : this.gridOptions.api.setRowData(this.data);
			if (this.gridOptions.api && this.gridOptions.api && this.gridOptions.columnApi) {

				const rows = []

				for (let d of data) {

					if (d.products && d.products.length) {

						for (let element of d.products) {

							rows.push({
								groupKey: d.products.length > 1 ? d.workOrderNumber : null,
								...d,
								product: element.reference,
								unit: element.unit,
								orderedQuantity: element.orderedQuantity / unitList[element.unit].coeficient,
								deliveredQuantity: element.deliveredQuantity / unitList[element.unit].coeficient
							})
						}

					}
					else {
						rows.push({ ...d })
					}
				}
				this.gridOptions.api.setRowData(rows)
			}

		} catch (e) {
			console.error(e);
			toastr.error("Echec du chargement des données", "Erreur");
		}
	}

	private async deleteRow(id: string, orderId: string) {
		await WorkOrdersService.getInstance().delete(id)
		await OrdersService.getInstance().updateOrder(orderId)
		await OrdersService.getInstance().updateDeliveryProducts(orderId)
		await WorkOrdersService.getInstance().createEvent({
			date: Date.now(),
			type: 'delete',
			user: LoggedUser.getInstance().get('ID'),
			id
		})

	}

	private getUnit(params: any) {

		let unit = null;
		if (params.data && params.data.unit) {
			unit = unitList[params.data.unit].unit;
		} else if (params.node.allLeafChildren) {
			let childrenUnitUniq = _.uniq(_.map(params.node.allLeafChildren, 'data.unit'));
			if (childrenUnitUniq.length == 1) {
				unit = unitList[childrenUnitUniq[0]].unit;
			}
		}

		return unit;

	}

}

export default WorkOrder;

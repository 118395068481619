import { Modal, Form, global, LoggedUser } from "@autoprog/core-client"

import T_expenseReportDetail from '../../../../tpl/modals/humanRessources/detailExpenseReport.html';
import AddEditExpenseReportModal from './AddEditExpenseReportModal';
import moment from 'moment'

import { ExpenseReportService } from "@js/libs/services"

class DetailExpenseReportModal extends Modal {

	private N_form?: HTMLFormElement
	private expenseReport: any
	private loggedUser: LoggedUser

	constructor(id: string) {

		super({
			tpl: T_expenseReportDetail,
			backdrop: 'static',
			keyboard: false
		});

		this.loggedUser = LoggedUser.getInstance();

		this.on('opened', async () => {

			this.N_form = this.element.querySelector('#expense-report') as HTMLFormElement;

			this.expenseReport = await ExpenseReportService.getInstance().getByID(id)

			this.expenseReport.date = moment(this.expenseReport.date, 'x').format('DD/MM/YYYY')

			if (this.N_form) {
				const form = new Form(this.N_form)
				form.setData(this.expenseReport)
			}

			const N_attachment = this.element.querySelector('#attachments') as HTMLElement

			if (this.expenseReport._attachments) {
				this.addAttachment('expensereport', N_attachment, this.expenseReport)

			} else {
				N_attachment.innerHTML = 'Pas de pièces jointes'
			}

			let N_editBtn = this.element.querySelector('#buttonEdit') as HTMLButtonElement;

			if (N_editBtn) {

				if (this.expenseReport.user == this.loggedUser.get('ID')) {
					N_editBtn.setAttribute('permission', "HUMAN_RESSOURCES.HOURS.EDIT")
				} else {
					N_editBtn.setAttribute('permission', "HUMAN_RESSOURCES.HOURS.EDIT_ALL_USERS")
				}


				if (this.expenseReport.locked) {
					N_editBtn.remove()
				} else {

					N_editBtn.addEventListener2('click', () => {
						let modal = new AddEditExpenseReportModal(this.expenseReport._id)
						modal.open().then(() => this.resolve(true))
					})
				}
			}
		})

	}

	/*
	███╗   ███╗███████╗████████╗██╗  ██╗ ██████╗ ██████╗ ███████╗
	████╗ ████║██╔════╝╚══██╔══╝██║  ██║██╔═══██╗██╔══██╗██╔════╝
	██╔████╔██║█████╗     ██║   ███████║██║   ██║██║  ██║█████╗
	██║╚██╔╝██║██╔══╝     ██║   ██╔══██║██║   ██║██║  ██║██╔══╝
	██║ ╚═╝ ██║███████╗   ██║   ██║  ██║╚██████╔╝██████╔╝███████╗
	╚═╝     ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝
	*/


	private addAttachment(table: string, N_element: HTMLElement, object: any) {

		for (let attachment in object._attachments) {

			let N_buttonAttachment = document.createElement('button') as HTMLButtonElement

			N_buttonAttachment.classList.add('btn', 'btn-info', 'mr-2', 'mb-2')
			N_buttonAttachment.innerHTML = attachment

			N_element.appendChild(N_buttonAttachment)

			if (N_buttonAttachment) {
				N_buttonAttachment.addEventListener('click', () => {
					window.open(`${global.COUCHDB_URL}/${table}/${object._id}/${attachment}`, "_blank")
				});
			}
		}
	}

}

export default DetailExpenseReportModal;

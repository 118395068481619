import _ from 'lodash'
import axios from 'axios'
import WorkOrder from '@js/types/workorder';
import FilesManager2 from '../FilesManager2';
import { FilesManagerFile } from '../FilesManager';
import OldDataEvent from '@js/types/data-event';
import DataEventsService from './DataEventsService';
import ServiceCache from '../Cache';

class WorkOrdersService {

	public static readonly TABLE = "work-orders"
	private static instance = new WorkOrdersService()
	private cache?: ServiceCache;


	private constructor() { 
		this.cache = ServiceCache.getInstance('work-orders');
	}

	public static getInstance() {
		return this.instance;
	}

	public async getByID(ID: string, ignoreCache:boolean = false) : Promise<WorkOrder> {

		if (ignoreCache) {
			return await axios.get(`/api/work-orders/${ID}`).then(response => response.data.data);;
		}

		return await this.cache?.gatherData(ID, async(ID)=>{
			return await axios.get(`/api/work-orders/${ID}`).then(response => response.data.data);
		})
	}

	public async getByIds(ids: Array<string>) : Promise<Array<WorkOrder>> {
		return axios.post(`/api/work-orders/by-ids`, { ids }).then(response => response.data.data)
	}

	/**
	 * Permet de récupérer les bons d'interventions en fonction du bon de commande
	 * @param idOrder Le numero de commande
	 */
	public async getFromOrder(idOrder: string) : Promise<Array<WorkOrder>> {
		return axios.get(`/api/work-orders/from-order/${idOrder}`).then(response => response.data.data)
	}

	/**
	 * Permet de récupérer les bon d'interventions relié à un site en particulier
	 * @param site 
	 */
	public async getBySite(site: string) : Promise<Array<WorkOrder>> {
		return axios.get( `/api/work-orders/by-site/${site}` ).then(response => response.data.data)
	}

	public async syncAttachments(id: string, files: Array<FilesManagerFile>) {
		await FilesManager2.synchronizeFiles(WorkOrdersService.TABLE, id, files)
	}


	/**
	 * Permet de supprimer un Bon d'intervention
	 * @param id 
	 */
	public async delete(id: string) : Promise<void> {
		await axios.get(`/api/work-orders/tag-delete/${id}`)
	}

	/**
	 * Permet de créer un évènement dans un bon d'intervention
	 * @param id 
	 * @param event 
	 */
	public async createEvent(event: Omit<OldDataEvent, 'table'>) : Promise<void> {
		await DataEventsService.getInstance().create({ ...event, table: WorkOrdersService.TABLE })
	}

	/**
	 * Permet de supprimer un bon d'interventions par numéro de commandes
	 * @param orderId 
	 */
	public async deleteByOrder(orderId: string) : Promise<void> {
		await axios.delete(`/api/work-orders/by-order/${orderId}`)
	}

	public async create(workOrder: WorkOrder) : Promise<void> {
		await axios.post('/api/work-orders', workOrder)
	}

	public async update(workOrder: WorkOrder) : Promise<void> {
		await axios.put('/api/work-orders', workOrder)
	}

	public async getPDF(id: string) : Promise<Blob> {
		return axios.get(`/api/work-orders/pdf/${id}`, { responseType: "blob" }).then(response => response.data)
	}

}

export default WorkOrdersService;

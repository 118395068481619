import { Tab, LoggedUser } from "@autoprog/core-client"

import AddEditUserModel from '@libs/modals/settings/AddEditUser'
import AgGridStateSaver from '@libs/agGrid/StateSaver2'

import h from 'hyperscript'

import toastr from 'toastr'
import { Grid, GridOptions, ICellRendererParams } from 'ag-grid-community'
import 'ag-grid-enterprise'

import axios from 'axios'


import { Group, User } from '@js/types/settings'

import { french as agGridFrench } from '@libs/utils/agGrid'

import { GroupsService, SitesService, UsersService } from "@js/libs/services"

import _ from 'lodash'

class UsersTab extends Tab {

	private gridOptions: GridOptions;
	private groups: object[];
	private sites: object[];
	private stateAGrid: AgGridStateSaver | undefined
	constructor(el: HTMLElement) {
		super(el)


		this.groups = []
		this.sites = [];

		this.gridOptions = {}
		this.createAgGrid()
		this.init()
	}

	private async init() {

		try {
			// Récupération des données
			await this.loadGroups()
			await this.loadSites()

			let gridEl = document.querySelector('#users-grid') as HTMLElement;
			new Grid(gridEl, this.gridOptions);

			this.stateAGrid = new AgGridStateSaver(this.gridOptions, 'users', `Onglet Paramètres/Utilisateurs`);
			this.stateAGrid.load()
			
			let N_addButton = document.querySelector('#button-add-users') as HTMLButtonElement

			N_addButton.addEventListener2('click', () => {
				let modal = new AddEditUserModel();
				modal.open().then(() => this.loadData())
			})

			const N_exportButton = document.getElementById('button-export-users') as HTMLButtonElement
			N_exportButton.addEventListener2('click', () => {
				this.gridOptions.api && this.gridOptions.api.exportDataAsExcel({
					fileName: 'utilisateurs.xslx'
				})
			})
		}
		catch (e) {
			toastr.error(`Erreur lors de la récupération des données`);
			console.error(e);
		}
	}

	private async loadGroups(): Promise<void> {
		let groups = await GroupsService.getInstance().getAll()
		this.groups = groups
	}

	private async loadSites(): Promise<void> {
		let sites = await SitesService.getInstance().getAllSites()
		this.sites = sites
	}

	private async loadData(): Promise<void> {

		try {
			let users = await UsersService.getInstance().getAll()
			this.gridOptions.api && this.gridOptions.api.setRowData(users)
		}
		catch (e) {
			toastr.error(`Erreur lors de la récupération des utilisateurs`);
			console.error(e);
		}

	}

	private async deleteUser(data: User): Promise<void> {
		await UsersService.getInstance().delete(data._id)
	}


	private createAgGrid() {
		this.gridOptions = agGridFrench({
			columnDefs: [
				{
					headerName: "Nom",
					field: "lastname",
					sort: 'asc'
				}, {
					headerName: "Prénom",
					field: "firstname"
				}, {
					headerName: "Groupe(s)",
					field: "groups",
					valueGetter: (params) => {

						if (params.data.groups) {
							let groups = [];
							for (let id of params.data.groups) {

								let group: Group | undefined = _.find(this.groups, { _id: id }) as Group | undefined;
								if (group) {
									groups.push(group.name);
								}
							}
							return groups;
						}
						return '';
					}
				}, {
					headerName: "Poste",
					field: "job"
				}, {
					headerName: "Statut",
					field: "status"
				}, {
					headerName: "Appartient à",
					field: "site",
					valueGetter: (params) => {

						let site = _.find(this.sites, { _id: params.data.site }) as any;
						if (site) {
							return site.name;
						}

						return '';
					}
				}, {
					headerName: "Peut voir",
					field: "allowSites",
					valueGetter: (params) => {

						if (params.data.allowSites) {
							let data = [];
							for (let d of params.data.allowSites) {
								let site = _.find(this.sites, { _id: d }) as any;
								if (site) {
									data.push(site.name);
								}
							}

							return data;
						}
						return '';
					}
				}, {
					headerName: "",
					field: "commands",
					width: 8 + 28 * 3,
					sortable: false,
					filter: false,
					suppressSizeToFit: true,
					pinned: 'right',
					cellRenderer: (params: ICellRendererParams) => {
						const div = h('div.d-flex.justify-content-end') as HTMLElement

						if (params.data.login != 'autoprog') {

							

							const editButton = document.createElement('button')
							editButton.classList.add('btn', 'btn-xs', 'btn-info')
							editButton.setAttribute('permission', 'SETTINGS.USERS.EDIT')
							editButton.innerHTML = '<i class="icon icon-solid-pencil-alt"></i>'

							const deleteButton = document.createElement('button')
							deleteButton.classList.add('btn', 'btn-xs', 'btn-danger')
							deleteButton.setAttribute('confirmation', '')
							deleteButton.setAttribute('permission', 'SETTINGS.USERS.DELETE')
							deleteButton.innerHTML = '<i class="icon icon-solid-trash-alt"></i>'

							const loginButton = document.createElement('button')
							loginButton.classList.add('btn', 'btn-xs', 'btn-indigo')
							loginButton.setAttribute('confirmation', '')
							loginButton.innerHTML = '<i class="icon icon-solid-ghost"></i>'


							deleteButton.addEventListener2('click', async () => {
								await this.deleteUser(params.data)
								params.api.applyTransaction({ remove: [params.data] })
							})


							editButton.addEventListener2('click', () => {
								const modal = new AddEditUserModel(params.data._id)
								modal.open().then(() => this.loadData())
							})

							loginButton.addEventListener2('click', () => {
								axios.get('/fakeUserLogin', {
									params: {
										userID: params.data._id
									}
								}).then((res) => {

									if (res.data && !res.data.err && res.data.logged) {
										window.location.reload();
									} else {
										toastr.error('Erreur de connexion');
									}
								})
							})

							div.appendChild(editButton);
							div.appendChild(deleteButton);
							if (!!LoggedUser.getInstance().get('isSuperAdmin')) {
								div.appendChild(loginButton)
							}

						}

						return div
					}
				}

			],
			rowData: [],
			suppressDragLeaveHidesColumns: true,
			defaultColDef: {
				floatingFilter: true,
				sortable: true,
				resizable: true,
				filter: 'agTextColumnFilter',
				floatingFilterComponentParams: { suppressFilterButton: true },
				suppressMenu: true,
				lockPinned: true
			},
			getContextMenuItems: () => [],
			onGridReady: (_params) => this.loadData()
		});
	}



	public destructor(): void {
		if (this.gridOptions.api){
			this.stateAGrid && this.stateAGrid.save()
			this.gridOptions.api.destroy()
		}
	}



}

export default UsersTab;

export enum StatusConnection {
	normal = 'status-normal',
	slow = 'status-slow',
	slower = 'status-lower',
	error = 'status-error'
}

import _ from "lodash"
import { ApplicationService } from "../services"

class ServerStatusIndicatorElement extends HTMLElement {
	public static readonly tagName = "server-status-indicator"
	
	private cancel: boolean
	private _timeout: number

	constructor() {
		super()

		this.classList.add('badge', 'p-2', 'ml-auto')
		this.cancel = false
		this._timeout = 500
	}

	get timeout() : number {
		return this._timeout
	}

	set timeout(value: number) {
		this._timeout = value
	}

	public async connectedCallback() {

		this.hasAttribute('timeout') && _.set(this, 'timeout', +this.getAttribute('timeout')!)

		if (this.hasAttribute('mode') && this.getAttribute('mode') !== 'manual') {
			this.start()
		}
	}

	public static register() {
		customElements.define(ServerStatusIndicatorElement.tagName, ServerStatusIndicatorElement)
	}

	public clear() {
		const defaultClasses = ['badge-danger', 'badge-success', 'badge-warning']
		this.classList.remove(...defaultClasses)
	}

	public pause() {
		if (!this.cancel) {
			this.cancel = true
		}
		else {
			this.cancel = false
			setTimeout(this.start.bind(this), this._timeout)
		}
	}

	public start() {
		if (!this.cancel) {

			const startDate = Date.now()
			let timeout = this._timeout

			// TODO: Deplacer la fonction ailleurs
			ApplicationService.getInstance().ping().then(hasResponse => {
				if (hasResponse) {
					const delay = Date.now() - startDate
					timeout -= delay

					if (delay <= 100) {
						this.className = 'badge badge-success p-2 ml-auto'
						this.textContent = `Connexion OK (${delay}ms)`
						this.dispatchEvent(new Event(StatusConnection.normal))
					}
					else if (delay > 100 && delay <= 250) {
						this.className = 'badge badge-warning p-2 ml-auto'
						this.textContent = `Connexion lente (${delay}ms)`
						this.dispatchEvent(new Event(StatusConnection.slow))
					}
					else if (delay > 250) {
						this.className = 'badge badge-danger p-2 ml-auto'
						this.textContent = `Connexion très lente (${delay}ms)`
						this.dispatchEvent(new Event(StatusConnection.slower))
					}
				}
			})
			.catch(() => {
				this.className = 'badge badge-danger p-2 ml-auto'
				this.innerHTML = 'Aucune Connexion'
				this.dispatchEvent(new Event(StatusConnection.error))
			})
			.then(() => setTimeout(this.start.bind(this), timeout))
		}

	}

	public disconnectedCallback() {
		this.pause()
	}
}

export default ServerStatusIndicatorElement

import axios from 'axios'
import Unit from '../../types/unit'

class UnitsService {

	// private static readonly TABLE = "units"
	private static readonly instance = new UnitsService()
	private _units: Array<Unit>

	private constructor() {
		this._units = []
	}

	public static getInstance() : UnitsService {
		return UnitsService.instance
	}

	public async load() {
		// Il va charger toutes les donnees, meme les unites qui n'existent plus
		const units = await axios.get('/api/units').then(response => response.data.data)

		if (units) {
			this._units = units
		}
	}

	public async sync() {
		this._units = []
		await this.load()
	}

	/**
	 * Permet de recuperer toute la liste des unites y compris ceux qui sont supprimes
	 * @returns 
	 */
	public getAll() : Array<Unit> {
		return [...this._units]
	}

	/**
	 * Permet de recuperer toute la liste des unites non-supprime
	 * @returns 
	 */
	public getAllAvailable() : Array<Unit> {
		return this._units.filter(unit => !unit.deleted)
	}

	public getById(id: string): Unit | undefined {
		return this._units.find(unit => unit._id === id)
	}

	public async create(unit: Unit) : Promise<void> {
		await axios.post('/api/units', unit)
		await this.sync()
	}

	public async update(unit: Unit) : Promise<void> {
		await axios.put('/api/units', unit)
		await this.sync()
	}

	public async delete(id: string) : Promise<void> {
		await axios.get(`/api/units/tag-delete/${id}`)
		await this.sync()
	}

	/**
	 * Permet de verifier si une unite existe deja
	 * @param unit 
	 * @returns 
	 */
	public exists(unit: string) : boolean {
		return this._units.some(u => !u.deleted && u.unit === unit)
	}

	public has(id: string) {
		return !!this._units.find(unit => unit._id === id)
	}

	public keys() : Array<string> {
		return this.getAllAvailable().map(unit => unit._id)
	}
	public allKeys(): Array<string> {
		return this._units.map(unit => unit._id)
	}
}

export default UnitsService
import { LoggedUser } from "@autoprog/core-client"

import  { CouchDBAttachment, FilesManagerFile } from '@libs/FilesManager'
import FilesManager2 from '@libs/FilesManager2'
import axios from 'axios'
import Site from '@js/types/site'
import Storage from '@js/types/storage'
import { SiteStorage } from '@js/types/site'
import UsersService from "./UsersService"

export class ConcreteSite implements Site {
	public storages: Array<SiteStorage> = []

	public _id: string = ''
	public _rev?: string
	public name: string = ''
	public address: string = ''
	public city: string = ''
	public zipCode: string = ''
	public tvaNumber: string = ''
	public color: string[] = []
	public footerTextBill: string = ''
	public IBAN: string = ''
	public legalStatus: string = ''
	public phone: string = ''
	public siret: string = ''
	public RCS: string = ''
	public NafCode: string = ''
	public _attachments?: { [key: string]: CouchDBAttachment }
	public deleted: boolean = false
	public legalMention: string = ''

	/**
	 * Permet de convertir un site en ConcretSite
	 * @param site 
	 */
	public static convert(site : Site) {
		// FIXME: trouver une plus simple d'implementer sa
		let concreteSite = new ConcreteSite()
		Object.assign(concreteSite, site)
		return concreteSite
	}

	/**
	 * Permet de récupérer le nom du fichier
	 */
	public getFilenameLogo() : string {

		if (this._attachments) {
			let filename = Object.keys(this._attachments).find( file => file.startsWith('LOGO_') )
			return filename ? filename : ""
		}

		return ""
	}

}

class SitesService {
	private static readonly instance = new SitesService()
	public static TABLE = "sites"
	private static PREFIX_PJ = "PJ_"
	private static PREFIX_RIB = "RIB_"
	private static PREFIX_KBIS = "KBIS_"
	private static PREFIX_LOGO = "LOGO_"

	private constructor() {
	}

	public static getInstance() {
		return SitesService.instance
	}

	public async getAttachments(id: string) : Promise<Array<CouchDBAttachment>> {
		let files = await FilesManager2.getAttachments(SitesService.TABLE, id)

		let result: Array<CouchDBAttachment> = []

		if (files) {
			result = files.filter( file => file.name && file.name.startsWith(SitesService.PREFIX_PJ) )
			result = result.map( file => {
					// On enleve PJ_ du nom
					file.name = (file.name || '').substring( SitesService.PREFIX_PJ.length, (file.name || '').length )	
					return file
				} )
		}

		return result
	}

	public async getRibs(id: string) : Promise<Array<CouchDBAttachment>> {
		let files = await FilesManager2.getAttachments(SitesService.TABLE, id)

		let result: Array<CouchDBAttachment> = []

		if (files) {
			result = files.filter( file => file.name && file.name.startsWith(SitesService.PREFIX_RIB) )
			result = result.map( file => {
					// On enleve RIB_ du nom
					file.name = (file.name || '').substring( SitesService.PREFIX_RIB.length, (file.name || '').length )	
					return file
				})
		}

		return result
	}

	public async getKbis(id: string) : Promise<Array<CouchDBAttachment>> {
		let files = await FilesManager2.getAttachments(SitesService.TABLE, id)

		let result: Array<CouchDBAttachment> = []

		if (files) {
			result = files.filter( file => file.name && file.name.startsWith(SitesService.PREFIX_KBIS) )
			result = result.map( file => {
					// On enleve RIB_ du nom
					file.name = (file.name || '').substring( SitesService.PREFIX_KBIS.length, (file.name || '').length )	
					return file
				} )
		}

		return result
	}

	public async getLogo(id: string) : Promise<Array<CouchDBAttachment>> {
		let files = await FilesManager2.getAttachments(SitesService.TABLE, id)

		let result: Array<CouchDBAttachment> = []

		if (files) {
			result = files.filter( file => file.name && file.name.startsWith(SitesService.PREFIX_LOGO) )
			result = result.map( file => {
					// On enleve LOGO_ du nom
					file.name = (file.name || '').substring( SitesService.PREFIX_LOGO.length, (file.name || '').length )	
					return file
				} )
		}

		return result
	}

	public async addAttachment(id: string, file: FilesManagerFile) {
		// On change le nom du fichier en PJ_
		
		if (!file.name.startsWith(SitesService.PREFIX_PJ)) {
			file.name = SitesService.PREFIX_PJ + file.name
		}
		
		await FilesManager2.addAttachment(SitesService.TABLE, id, file)
	}

	public async addAttachments(id: string, files: Array<FilesManagerFile>) {
		
		// On change le nom du fichier en PJ_ pour chaque fichier

		files = files.map( file => {
			
			if (!file.name.startsWith(SitesService.PREFIX_PJ)) {
				file.name = SitesService.PREFIX_PJ + file.name
			}
			
			return file
		})

		await FilesManager2.addAttachments(SitesService.TABLE, id, files)
	}

	public async syncAttachments(id: string, files: Array<FilesManagerFile>) {

		// Renommage des fichiers à enregistrer en PNJ
		files = files.map( file => {
			
			if (!file.name.startsWith(SitesService.PREFIX_PJ)) {
				file.name = SitesService.PREFIX_PJ + file.name
			}
			
			return file
		})

		await FilesManager2.synchronizeFiles(SitesService.TABLE, id, files, [SitesService.PREFIX_RIB, SitesService.PREFIX_KBIS, SitesService.PREFIX_LOGO])
	}

	public async syncKbisAttachment(id: string, files: Array<FilesManagerFile>) {
		files = files.map( file => {
			
			if (!file.name.startsWith(SitesService.PREFIX_KBIS)) {
				file.name = SitesService.PREFIX_KBIS + file.name
			}
			
			return file
		})

		await FilesManager2.synchronizeFiles(SitesService.TABLE, id, files, [SitesService.PREFIX_RIB, SitesService.PREFIX_LOGO, SitesService.PREFIX_PJ])
	}


	public async syncRIBAttachment(id: string, files: Array<FilesManagerFile>) {
		files = files.map( file => {
			
			if (!file.name.startsWith(SitesService.PREFIX_RIB)) {
				file.name = SitesService.PREFIX_RIB + file.name
			}
			
			return file
		})

		await FilesManager2.synchronizeFiles(SitesService.TABLE, id, files, [SitesService.PREFIX_KBIS, SitesService.PREFIX_LOGO, SitesService.PREFIX_PJ])
	}

	public async syncLogoAttachment(id: string, files: Array<FilesManagerFile>) {
		files = files.map( file => {
			
			if (!file.name.startsWith(SitesService.PREFIX_LOGO)) {
				file.name = SitesService.PREFIX_LOGO + file.name
			}
			
			return file
		})

		await FilesManager2.synchronizeFiles(SitesService.TABLE, id, files, [SitesService.PREFIX_RIB,SitesService.PREFIX_KBIS, SitesService.PREFIX_PJ])
	}

	// TODO: Faire la suppression de piece jointes
	// TODO: Faire la suppression de Kbis
	// TODO: Faire la suppression de RIB

	/**
	 * Permet de récupérer tous les sites autorisés par l'utilisateur
	 */
	public async getAllowedSites() : Promise<Array<Site>> {
		const allowedSite = UsersService.getInstance().getAllowSites()
		const sitesData = await this.getAllSites()
		return sitesData.filter(site => allowedSite && allowedSite.includes(site._id))
	}

	public getAllowedSitesString() {
		return LoggedUser.getInstance().get('additionalData.allowSites')
	}

	/**
	 * Permet de récupérer tous les sites
	 */
	public async getAllSites() : Promise<Array<Site>> {
		return axios.get('/api/sites').then(response => response.data.data)
	}

	public async getByID(id:string) : Promise<Site> {
		return await axios.get(`/api/sites/${id}`).then(response => response.data.data)
	}

	public async getStorages(id:string) : Promise<Storage[]> {
		return axios.get(`/api/sites/storages/${id}`).then(response => response.data.data)
	}

	/**
	 * Permet de recuperer le site par defaut configure sur le serveur
	 * @returns 
	 */
	public async getDefault() : Promise<Site> {
		return axios.get(`/api/sites/default`).then(response => response.data.data)
	}

	public async update(site: Site) : Promise<void> {
		await axios.put('/api/sites', site)
	}

	public async create(site: Site) : Promise<void> {
		await axios.post('/api/sites', site)
	}

	public async delete(id: string) : Promise<void> {
		await axios.get(`/api/sites/tag-delete/${id}`)
	}

	public async getByIds(ids: Array<string>) : Promise<Array<Site>> {
		return axios.post('/api/sites/by-ids', { ids }).then(response => response.data.data)
	}

}

export default SitesService

import { Modal, global } from "@autoprog/core-client"
import T_moreOptions from '@tpl/modals/calendar/moreOptions.ejs';
import T_moreOptionsMobile from '@tpl/modals/calendar/moreOptionsMobile.ejs';

import TuiDatePicker from '@js/libs/TuiDatePicker';

import UpdateEventRepeat from './UpdateEventRepeat';

import 'select2';
import 'select2/dist/css/select2.min.css';

import moment from 'moment';
import { calendarBdd, repeatBdd } from '@js/types/calendar';
import UsersService from '@js/libs/services/UsersService';

import _ from 'lodash'
import { ISchedule, TZDate } from "tui-calendar";

class MoreOptionsModal extends Modal {

	private rangePicker: TuiDatePicker | { [key: string]: HTMLInputElement } | null;
	private dateEndRepeat: TuiDatePicker | HTMLInputElement | null;
	private saveData: ISchedule;
	private calendarList: calendarBdd[];

	constructor(event: ISchedule, repeat: any, calendarList: calendarBdd[]) {

		repeat = _.cloneDeep(repeat) || {
			notEnabled: true,
			frequency: {
				type: 'day',
				value: '1'
			},
			end: false
		};

		event.raw = event.raw || {};

		let day = new Date().getDay();

		if (repeat) {

			repeat.frequency.days = repeat.frequency.days || {
				sunday: (day == 0),
				monday: (day == 1),
				tuesday: (day == 2),
				wednesday: (day == 3),
				thursday: (day == 4),
				friday: (day == 5),
				saturday: (day == 6)
			};

		}

		let tmp = [];

		for (let calendar of calendarList) {

			for (let site of (calendar.sites || [])) {

				tmp.push({
					...calendar,
					site
				});

			}

		}

		tmp = _.sortBy(tmp, ['site', 'category'])

		const calendars: { [site: string]: { [category: string]: Array<any> } } = {}

		for (let calendar of tmp) {

			calendar.site = calendar.site || '';
			calendar.category = calendar.category || '';

			calendars[calendar.site] = calendars[calendar.site] || [];
			calendars[calendar.site][calendar.category] = calendars[calendar.site][calendar.category] || [];

			calendars[calendar.site][calendar.category].push(calendar)

		}

		const selectedCal = _.find(calendarList, { _id: event.calendarId }) || calendarList[0]

		if (global.IS_MOBILE) {
			super({
				tpl: T_moreOptionsMobile({
					selectedCal,
					calendarList: calendars,
					repeat: repeat,
					global,
					data: event,
				}),
				backdrop: 'static',
				keyboard: false
			});
		} else {
			super({
				tpl: T_moreOptions({
					selectedCal,
					calendarList: calendars,
					repeat: repeat,
					global,
					data: event,
				}),
				backdrop: 'static',
				keyboard: false
			});
		}

		this.saveData = event;

		this.calendarList = calendarList;

		this.rangePicker = null;
		this.dateEndRepeat = null;

		this.on("opened", () => {

			if (event.start && event.end) {
				this.iniDatePickerRange(event.start || new Date(), event.end || new Date());
			}

			if (repeat) {

				this.toggleRepeat(!repeat.notEnabled);

				if (repeat.end) {

					if (typeof repeat.end == 'object' && 'date' in repeat.end) {
						this.dateEndRepeat = this.initDatePicker('#date-end-repeat', new Date(repeat.end.date));
					} else {
						this.dateEndRepeat = this.initDatePicker('#date-end-repeat', new Date());
					}

				} else {
					this.dateEndRepeat = this.initDatePicker('#date-end-repeat', new Date());
				}

			} else {
				this.dateEndRepeat = this.initDatePicker('#date-end-repeat', new Date());
			}

			this.initUser(event);
			this.initRepeat();
			this.initEnabledRepeat();
			this.initDropdown();

			const N_site = this.element.querySelector('#site-selected select') as HTMLSelectElement

			setTimeout(() => {
				N_site.value = selectedCal.sites? selectedCal.sites[0] : ''
			})

			this.save()
		})

	}

	private eventItemListDropdown(N_item: HTMLElement): void {

		let N_button = this.element.querySelector('#button-dropdown-calendar') as HTMLButtonElement;

		if (N_button) {

			let N_dotSelected = N_button.querySelector('.tui-full-calendar-calendar-dot') as HTMLSpanElement;
			let N_nameSelected = N_button.querySelector('#calendar-name') as HTMLSpanElement;

			let N_dotItem = N_item.querySelector('.tui-full-calendar-calendar-dot') as HTMLSpanElement;
			let N_nameItem = N_item.querySelector('#calendar-name') as HTMLSpanElement;
			let N_list = this.element.querySelectorAll('#list-dropdown-calendar');

			N_item.addEventListener('click', () => {

				if (N_button) {
					N_dotSelected.style.backgroundColor = N_dotItem.style.backgroundColor;
					N_nameSelected.innerHTML = N_nameItem.innerHTML;

					N_button.dataset.calendarId = N_item.dataset.calendarId;
				}

				N_list.forEach((item) => {
					(item as HTMLElement).style.display = 'none';
				});

			});

		}

	}

	private initDropdown(): void {

		setTimeout(() => {

			let N_button = this.element.querySelector('#button-dropdown-calendar');
			let N_site = this.element.querySelector('#site-selected select') as HTMLSelectElement;

			if (N_site) {

				N_site.addEventListener('click', () => {

					let N_list = this.element.querySelectorAll(`#list-dropdown-calendar`);

					for (let list of N_list) {
						let el = list as HTMLElement;
						el.style.display = 'none';
					}

				});

			}

			if (N_button) {

				N_button.addEventListener('click', () => {

					let N_list = this.element.querySelector(`#list-dropdown-calendar[data-site="${N_site.value}"]`) as HTMLUListElement;

					if (N_list) {
						if (N_list.style.display == 'none') {
							N_list.style.display = 'block';
						} else {
							N_list.style.display = 'none';
						}
					}

				});

				let N_itemsList = this.element.querySelectorAll('#dropdown-calendar li');

				for (let N_item of N_itemsList) {

					this.eventItemListDropdown(N_item as HTMLElement);

				}

			}

		});

	}

	private iniDatePickerRange(start: Date | string | TZDate, end: Date | string | TZDate): void {

		if (typeof start == "string") {
			start = new Date(parseInt(start));
		} else {
			start = new Date(start.getTime());
		}

		if (typeof end == "string") {
			end = new Date(parseInt(end));
		} else {
			end = new Date(end.getTime());
		}

		if (!global.IS_MOBILE) {

			this.rangePicker = TuiDatePicker.createRangePicker({
				language: 'french',
				startpicker: {
					date: start,
					input: '#start-date',
					container: '#container-start-date-range-picker'
				},
				endpicker: {
					date: end,
					input: '#end-date',
					container: '#container-end-date-range-picker'
				},
				format: 'yyyy-MM-dd HH:mm',
				timepicker: {
					showMeridiem: false
				},
				usageStatistics: false
			});

		} else {

			let N_start = this.element.querySelector('#start-date') as HTMLInputElement;
			let N_end = this.element.querySelector('#end-date') as HTMLInputElement;

			N_start.value = moment(start).format('YYYY-MM-DDTHH:mm');
			N_end.value = moment(end).format('YYYY-MM-DDTHH:mm');

			this.rangePicker = {
				start: N_start,
				end: N_end,
			};

		}

	}

	private initDatePicker(selector: string, date: Date): TuiDatePicker | HTMLInputElement {

		if (global.IS_MOBILE) {

			let N_input = this.element.querySelector(selector) as HTMLInputElement;

			N_input.valueAsNumber = date.getTime();

			return N_input;

		} else {

			return new TuiDatePicker(selector + '-container', {
				date,
				input: {
					element: selector,
					format: 'yyyy-MM-dd'
				},
				showAlways: false
			});

		}

	}

	private toggleRepeat(value: boolean): void {

		let N_containerRepeat = this.element.querySelector('#container-repeat') as HTMLInputElement;
		let N_inputs = N_containerRepeat.querySelectorAll('input');
		let N_selects = N_containerRepeat.querySelectorAll('select');

		if (!value) {

			N_containerRepeat.classList.add('text-muted');

			for (let input of N_inputs) {
				if (input.disabled) {
					input.dataset.isDisabled = '';
				}
				input.disabled = true;

			}

			for (let select of N_selects) {
				if (select.disabled) {
					select.dataset.isDisabled = '';
				}
				select.disabled = true;
			}

		} else {

			N_containerRepeat.classList.remove('text-muted');

			for (let input of N_inputs) {
				if (input.hasAttribute('data-is-disabled')) {
					input.removeAttribute('data-is-disabled');
				} else {
					input.disabled = false;
				}
			}

			for (let select of N_selects) {
				if (select.hasAttribute('data-is-disabled')) {
					select.removeAttribute('data-is-disabled');
				} else {
					select.disabled = false;
				}
			}

		}

	}

	private initEnabledRepeat(): void {

		let N_enabled = this.element.querySelector('#enableRepeat') as HTMLInputElement;
		let N_containerRepeat = this.element.querySelector('#container-repeat') as HTMLInputElement;

		if (N_enabled && N_containerRepeat) {

			N_enabled.addEventListener('change', () => {

				this.toggleRepeat(N_enabled.checked);

			});

		}

	}

	private initUser(data: ISchedule): void {

		this.getAllUsers().then((users) => {

			let N_selectAddUser = this.element.querySelector('#select-list-users');

			if (N_selectAddUser) {

				data.raw = data.raw || {};
				data.raw.users = data.raw.users || [];

				if (!global.IS_MOBILE) {

					$(N_selectAddUser).select2({
						data: users,
						tags: true,
						multiple: true,
						createTag: function (params) {
							return {
								text: params.term,
								id: params.term
							};
						},
						dropdownParent: $(this.element)
					}).val(data.raw.users as any).trigger('change.select2');

				} else {

					let html = '';

					for (let user of users) {

						html += `<option value="${user.id}" ${(data.raw.users as Array<any>).indexOf(user.id) != -1 ? 'selected' : ''}>${user.text}</option>`;

					}

					for (let user of (data.raw.users as Array<any>)) {

						if (!_.find(users, { id: user })) {

							html += `<option value="${user}" selected>${user}</option>`;

						}

					}

					N_selectAddUser.innerHTML = html;

				}

			}

		});

	}

	private async getAllUsers(): Promise<any[]> {
		const users = await UsersService.getInstance().getAll()
		return users.map(user => ({ id: user._id, text: user.firstname + ' ' + user.lastname }))
	}

	private eventRadioButton(el: HTMLInputElement): void {

		let N_endNever = this.element.querySelector('[name="lastuse"]#never') as HTMLInputElement;
		let N_endInDate = this.element.querySelector('[name="lastuse"]#inDate') as HTMLInputElement;
		let N_endTimes = this.element.querySelector('[name="lastuse"]#times') as HTMLInputElement;

		el.addEventListener('change', () => {
			let parentNever = N_endNever.parentNode as HTMLElement;
			if (parentNever) {
				if (N_endNever.checked) {
					parentNever.classList.remove('text-muted');
				} else {
					parentNever.classList.add('text-muted');
				}
			}

			let parentInDate = N_endInDate.parentNode as HTMLElement;
			let inputInDate = parentInDate.querySelector('[data-type="value"]') as HTMLInputElement;
			if (parentInDate) {
				if (N_endInDate.checked) {
					parentInDate.classList.remove('text-muted');
					inputInDate.disabled = false;
				} else {
					parentInDate.classList.add('text-muted');
					inputInDate.disabled = true;
				}
			}

			let parentTimes = N_endTimes.parentNode as HTMLElement;
			let inputTimes = parentTimes.querySelector('[data-type="value"]') as HTMLInputElement;
			if (parentTimes) {
				if (N_endTimes.checked) {
					parentTimes.classList.remove('text-muted');
					inputTimes.disabled = false;
				} else {
					parentTimes.classList.add('text-muted');
					inputTimes.disabled = true;
				}
			}
		});
	}

	private initRepeat(): void {

		let N_selectType = this.element.querySelector('#type-frequency') as HTMLSelectElement;
		let N_weekOptions = this.element.querySelector('#week-options') as HTMLDivElement;

		let N_endNever = this.element.querySelector('[name="lastuse"]#never') as HTMLInputElement;
		let N_endInDate = this.element.querySelector('[name="lastuse"]#inDate') as HTMLInputElement;
		let N_endTimes = this.element.querySelector('[name="lastuse"]#times') as HTMLInputElement;

		if (N_selectType && N_weekOptions && N_endNever && N_endInDate && N_endTimes) {

			this.eventRadioButton(N_endNever);
			this.eventRadioButton(N_endInDate);
			this.eventRadioButton(N_endTimes);

			N_selectType.addEventListener('change', () => {

				if (N_selectType.value == 'week') {
					N_weekOptions.classList.remove('d-none');
				} else {
					N_weekOptions.classList.add('d-none');
				}

			});

		}

	}

	private save(): void {

		let N_button = this.element.querySelector('#save');

		if (N_button) {

			N_button.addEventListener('click', () => {

				let N_title = this.element.querySelector('#title') as HTMLInputElement;
				let N_allDays = this.element.querySelector('#all-days') as HTMLInputElement;
				let N_description = this.element.querySelector('#description') as HTMLTextAreaElement;
				let N_calendar = this.element.querySelector('#button-dropdown-calendar') as HTMLButtonElement;

				let N_enabled = this.element.querySelector('#enableRepeat') as HTMLInputElement;

				let N_selectAddUser = this.element.querySelector('#select-list-users') as HTMLSelectElement;

				if (this.rangePicker) {


					let start = new Date();
					let end = new Date();

					if (this.rangePicker.getStartDate) {
						start = (this.rangePicker as DatePicker).getStartDate();
						end = (this.rangePicker as DatePicker).getEndDate();
					} else {

						let rangePicker = this.rangePicker as { [k: string]: HTMLInputElement };

						start = moment(rangePicker.start.value, 'YYYY-MM-DDTHH:mm').toDate();
						end = moment(rangePicker.end.value, 'YYYY-MM-DDTHH:mm').toDate();

					}

					let data: ISchedule = {
						id: this.saveData.id,
						start,
						end,
						title: N_title.value,
						isAllDay: N_allDays.checked,
						calendarId: N_calendar.dataset.calendarId,
						raw: _.merge(this.saveData.raw, {
							description: N_description.value,
							users: []
						})
					};

					if (data.title && data.raw && N_selectAddUser) {

						if (global.IS_MOBILE) {

							data.raw.users = Array.from(N_selectAddUser).map(el => (el as HTMLOptionElement).value);

						} else {

							data.raw.users = $(N_selectAddUser).select2('data');

							data.raw.users = _.map(data.raw.users as Array<any>, 'id');

						}


						if (N_enabled.checked) {

							let N_valueFrequency = this.element.querySelector('#value-frequency') as HTMLInputElement;
							let N_typeFrequency = this.element.querySelector('#type-frequency') as HTMLInputElement;
							let N_checkboxDays = this.element.querySelectorAll('#week-options input');

							let N_endInDate = this.element.querySelector('[name="lastuse"]#inDate') as HTMLInputElement;
							let N_endTimes = this.element.querySelector('[name="lastuse"]#times') as HTMLInputElement;
							let N_endValueTimes = this.element.querySelector('#times-repeat') as HTMLInputElement;

							let repeat: repeatBdd = {
								frequency: {
									type: N_typeFrequency.value,
									value: N_valueFrequency.value
								},
								end: false
							};

							if (N_typeFrequency.value == "week") {

								repeat.frequency.days = {}

								for (let checkbox of N_checkboxDays) {
									repeat.frequency.days[checkbox.id] = (checkbox as HTMLInputElement).checked;
								}

							}

							if (N_endInDate.checked && this.dateEndRepeat) {

								if (this.dateEndRepeat instanceof TuiDatePicker) {
									repeat.end = {
										date: this.dateEndRepeat.getDate().getTime()
									};
								} else {
									repeat.end = {
										date: this.dateEndRepeat.valueAsNumber
									};
								}

							}

							if (N_endTimes.checked) {
								repeat.end = {
									times: parseInt(N_endValueTimes.value)
								};
							}


							const calendarBdd = _.find(this.calendarList, { _id: this.saveData.calendarId });
							const dataRepeat = _.find(calendarBdd?.repeat || [], { idEvent: data.id });

							if (data.raw.idEventRef || dataRepeat) {

								let modal = new UpdateEventRepeat();

								modal.open().then((value: any) => {

									this.resolve({
										oldCalendarId: this.saveData.calendarId,
										event: data,
										repeat,
										actionRepeat: value
									});

								});

							} else {
								this.resolve({
									event: data,
									oldCalendarId: this.saveData.calendarId,
									repeat
								});
							}

						} else {

							this.resolve({
								event: data,
								oldCalendarId: this.saveData.calendarId
							});

						}

					} else {

						if (N_title) {

							N_title.classList.add('border-red');

						}

					}
				}
			});

		}

	}

}

export default MoreOptionsModal;
import { Modal, Alert, global } from "@autoprog/core-client"

import T_selectOrder from '@tpl/modals/selectOrder.html'
import { Grid, GridOptions } from 'ag-grid-community'
import moment from 'moment'
import _ from 'lodash'
import unitList from "@libs/lists/unitList";
import stateList from "@libs/lists/stateListMale";
import typeList from "@libs/lists/typeList";
import AgGridStateSaver from '@libs/agGrid/StateSaver2'

import { french as agGridFrench } from '@libs/utils/agGrid'
import Order from '@js/types/order'
import OrdersService, { AvailableType } from '../services/OrdersService'
import Tier from '@js/types/Tiers'
import TiersService from '../services/TiersService'


interface SelectOrdersRow extends Order {
	customerData: Tier
	providerData: Tier
}

type SelectOrderTypes = 'FA' | 'BL' | 'BI' | 'InputTransaction';

class selectOrders extends Modal {

	private gridOptions: GridOptions = <GridOptions>{};
	private ordersIDs: string[];
	private providerUniq: boolean;
	private customerUniq: boolean;
	private orderTypeUniq: boolean;
	private stateAGrid: AgGridStateSaver | undefined;
	private type: SelectOrderTypes
	private mode: string;
	constructor(type: SelectOrderTypes) {
		super({
			tpl: T_selectOrder,
			backdrop: 'static',
			keyboard: false
		})

		this.ordersIDs = [];
		this.type = type;
		this.mode = 'single'
		this.providerUniq = false;
		this.customerUniq = false;
		this.orderTypeUniq = false;

		if (this.type == 'FA') {
			this.mode = 'multiple'
		}

		this.on('closed', this.destructor.bind(this))
		this.on('opened', () => {
			let N_validateBtn = this.element.querySelector('#validateBtn') as HTMLButtonElement;
			this.gridOptions = agGridFrench({
				columnDefs: [
					{
						headerName: "N° Commande",
						showRowGroup: 'groupKey',
						colId: 'orderNumber',
						cellRenderer: 'agGroupCellRenderer',
						checkboxSelection: (params) => {
							return params.node.group === true;
						},
						filterValueGetter: function (params) {
							return params.data ? params.data.orderNumber : null;
						},
						valueGetter: (params) => {
							return params.node?.level === 0 && params.data.orderNumber ? params.data.orderNumber : ''
						}
					},

					{
						field: "groupKey",
						rowGroup: true,
						hide: true
					},
					{
						headerName: "Date Commande",
						field: "orderDate",
						sort: 'desc',
						filter: 'agDateColumnFilter',
						filterParams: {
							comparator: (filterValue: Date, cellValue: number) => {

								let filter = parseInt(moment(filterValue).startOf('day').format('x'));
								let cell = parseInt(moment(cellValue).startOf('day').format('x'))

								if (cell < filter) {
									return -1;
								} else if (cell > filter) {
									return 1
								}

								return 0
							}
						},
						cellRenderer: (params) => {

							if (params.node.level == 0 && params.value) {
								return moment(params.value, 'x').format('DD/MM/YYYY');
							}
							return '';

						},
						aggFunc: 'first'
					},
					{
						headerName: "Fournisseur",
						field: "providerData",

						valueGetter: (params) => {
							if (params.data && params.data.providerData) {
								return params.data.providerData.name;
							}
							return '';
						},
						cellRenderer: (params) => {
							if (params.node.level == 0 && params.value) {
								if (params.value && params.value.name) {
									return params.value.name
								} else {
									return params.value
								}
							}
							return '';
						},
						aggFunc: 'first'
					},
					{
						headerName: "Client",
						field: "customerData",

						valueGetter: (params) => {
							if (params.data && params.data.customerData) {
								return params.data.customerData.name;
							}
							return '';
						},
						cellRenderer: (params) => {
							if (params.node.level == 0 && params.value) {
								if (params.value && params.value.name) {
									return params.value.name
								} else {
									return params.value
								}
							}
							return '';
						},
						aggFunc: 'first'
					},

					{
						headerName: "Detail",
						field: "detail",
						cellRenderer: (params) => {
							if (params.node.level == 0 && params.value) {
								return params.value
							}
							return '';
						},
						aggFunc: 'first'
					},
					{
						headerName: "Type",
						field: "type",
						filter: 'agSetColumnFilter',
						cellRenderer: (params) => {
							if (params.node.level == 0 && params.value) {
								return params.value;
							}
							return '';
						},
						valueGetter: (params) => {
							if (params.data) return typeList[params.data.type];
						},
						aggFunc: 'first'
					},
					{
						headerName: "Etat",
						field: "state",

						filterValueGetter: (params) => {
							if (params.data) {
								return stateList[params.data.state].name
							}
						},
						cellRenderer: (params) => {

							if (params.node.level == 0 && params.value) {
								return stateList[params.value].name
							}
							return '';

						},
						aggFunc: 'first'
					},
					{
						headerName: "Date Livraison / Intervention",
						field: "deliveryDate",
						filter: 'agDateColumnFilter',
						filterParams: {
							comparator: (filterValue: Date, cellValue: number) => {

								let filter = parseInt(moment(filterValue).startOf('day').format('x'));
								let cell = parseInt(moment(cellValue).startOf('day').format('x'))

								if (cell < filter) {
									return -1;
								} else if (cell > filter) {
									return 1
								}

								return 0
							}
						},
						cellRenderer: (params) => {

							if (params.node.level == 0 && params.value) {
								return moment(params.value, 'x').format('DD/MM/YYYY');
							}
							return '';

						},
						aggFunc: 'first'
					},
					{
						headerName: "Client",
						field: "customerData",
						cellRenderer: (params) => {

							if (params.node.level == 0 && params.value) {
								if (params.value && params.value.name) {
									return params.value.name
								} else {
									return params.value
								}
							}
							return '';


						},
						aggFunc: 'first'
					},

					{
						headerName: "Produits",
						field: "product",
						aggFunc: 'concat'
					},

					{
						headerName: "Quantité",
						field: "orderedQuantity",
						aggFunc: 'sum',
						filter: 'agNumberColumnFilter',
					},
					{
						headerName: "Qté Livrée",
						field: "deliveredQuantity",
						filter: 'agNumberColumnFilter',
						cellRenderer: (params) => {
							if (params.value || params.value === 0) {
								return (Math.round(params.value * 100) / 100).toString();
							}
							return '';
						},
						aggFunc: 'sum'
					},
				],
				groupMultiAutoColumn: true,
				groupSuppressAutoColumn: true,
				suppressAggFuncInHeader: true,
				rowSelection: this.mode,
				groupSelectsChildren: false,
				suppressRowClickSelection: true,
				suppressDragLeaveHidesColumns: true,
				aggFuncs: {
					concat: (params) => params.values.join(', ')
				},
				onSelectionChanged: () => {
					N_validateBtn.removeAttribute('disabled');
					if (this.gridOptions.api && this.gridOptions.api.getSelectedNodes().length) {
						this.ordersIDs = this.gridOptions.api.getSelectedNodes().map(n => n.allLeafChildren[0].data.orderNumber)
						this.providerUniq = _.uniq(this.gridOptions.api.getSelectedNodes().map(n => n.allLeafChildren[0].data.providerData._id)).length == 1;
						this.customerUniq = _.uniq(this.gridOptions.api.getSelectedNodes().map(n => n.allLeafChildren[0].data.customerData._id)).length == 1;
						this.orderTypeUniq = _.uniq(this.gridOptions.api.getSelectedNodes().map(n => n.allLeafChildren[0].data.type)).length == 1;

					} else {
						N_validateBtn.setAttribute('disabled', '');
						this.ordersIDs = [];
					}
				},
				defaultColDef: {
					floatingFilter: true,
					filter: "agTextColumnFilter",
					resizable: true,
					sortable: true,
				},
				getRowClass: (params) => {

					const data = params.node.aggData || params.data;

					if (data) {
						// FIXME: use a switch case
						if (data.state == 'notDelivered') {
							return 'ag-order-notDelivered';
						} else if (data.state == 'inDelivering') {
							return 'ag-order-inDelivering';
						} else if (data.state == 'delivered') {
							return 'ag-order-delivrered';
						}
					}

					return ''
				},
				onGridReady: () => this.updateGrid()
			});

			let N_grid = this.element.querySelector('#grid-selectOrder') as HTMLElement;
			new Grid(N_grid, this.gridOptions);
			this.stateAGrid = new AgGridStateSaver(this.gridOptions, global.SITE + '_selectOrder', `Tableau de sélection de BC ${global.SITE}`);
			this.stateAGrid.load()
			N_validateBtn.addEventListener2('click', () => {

				if (!this.providerUniq) {
					Alert.error('Fournisseurs incompatibles', 'Les fournisseur ne sont pas identiques sur toutes les commandes sélectionnées.')
				} else if (!this.customerUniq) {
					Alert.error('Clients incompatibles', 'Les clients ne sont pas identiques sur toutes les commandes sélectionnées.')
				} else if (!this.orderTypeUniq) {
					Alert.error('Types incompatibles', 'Les types de commande ne sont pas identiques.')
				} else {
					if (this.mode == 'single') {
						this.resolve(this.ordersIDs[0] || null);
					} else {
						this.resolve(this.ordersIDs);
					}
				}
			})
		})
	}


	private async getRowData(): Promise<Array<SelectOrdersRow>> {

		const tabType: { [key in SelectOrderTypes]: AvailableType } = {
			'FA': 'Bill',
			'BL': 'DeliveryNote',
			'BI': 'WorkOrder',
			'InputTransaction': 'InputTransaction'
		}

		const orders = await OrdersService.getInstance().getAvailable(global.SITE, tabType[this.type])
		const tiersIds = orders.flatMap(order => [order.customer, order.provider])
		const tiers = await TiersService.getInstance().getByIds(tiersIds)

		return orders.map(order => ({
			...order,
			customerData: tiers.find(tier => tier._id === order.customer)!,
			providerData: tiers.find(tier => tier._id === order.provider)!
		}))
	}

	private async updateGrid(): Promise<void> {
		//recuperation des données de la base
		const data = await this.getRowData()

		//insertion des donnée dans le tableau /// syntaxe possible : this.gridOptions.api.setRowData(this.data);
		if (this.gridOptions.api && this.gridOptions.api && this.gridOptions.columnApi) {

			const rows = [];

			for (let d of data) {
				if (d.products && d.products.length) {
					for (let element of d.products) {
						rows.push({
							groupKey: d.orderNumber,
							...d,
							product: element.reference,
							orderedQuantity: element.quantity / unitList[element.unit].coeficient,
							deliveredQuantity: element.deliveredQuantity / unitList[element.unit].coeficient
						})
					}
				} else {
					rows.push(d)
				}
			}

			this.gridOptions.api.setRowData(rows);
			this.stateAGrid && this.stateAGrid.save()
		}
	}

	public destructor(): void {
		if (this.gridOptions.api) {
			this.stateAGrid && this.stateAGrid.save()
			this.gridOptions.api?.destroy()
		}
	}
}

export default selectOrders;

module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="input-group">\r\n   \r\n    <div class="attachmentsList form-control">\r\n\r\n    </div>\r\n\r\n    <div class="input-group-append">\r\n\r\n        <label class="mb-0 btn btn-grey-300"  for="input-' +
((__t = (id)) == null ? '' : __t) +
'"><span class="icon icon-solid-folder"></span>\r\n        </label>\r\n        <input type="file" class="attachmentInput" id="input-' +
((__t = (id)) == null ? '' : __t) +
'" multiple hidden>\r\n            \r\n    </div>\r\n\r\n</div>';

}
return __p
}
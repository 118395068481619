import { SocketIO, global } from "@autoprog/core-client"
import SelectTypeTransaction from '@libs/modals/weighBridge/SelectTypeTransaction'

import '@css/transaction.css';
import CardTransaction from '@libs/tabs/weighBridge/CardTransaction'
import AddEditInputTransaction from '@libs/modals/weighBridge/AddEditInputTransaction'
import toastr from 'toastr'
import DeliveryNotesService from '@js/libs/services/DeliveryNotesService'
import DeliveryNote from '@js/types/delivery-note'


class TabTransaction {

	private el: HTMLElement;
	private io: SocketIO;
	private transactions: CardTransaction[];
	private N_cardContainer: HTMLElement;

	constructor(el: HTMLElement) {
		this.el = el;
		this.transactions = [];
		this.N_cardContainer = document.querySelector("#card-container") as HTMLElement;

		this.io = SocketIO.getInstance();


		let addtransactionBtn = this.el.querySelector('#add-transaction-btn') as HTMLButtonElement;

		addtransactionBtn.addEventListener2('click', () => {

			new SelectTypeTransaction().open().then((type) => {

				if (type == 'input') {

					new AddEditInputTransaction().open().then(() => {

						this.updateTransactions().then(() => {

						}).catch(() => {

						});

					}).catch((e) => {
						console.log(e);
					})

				}

			}).catch((e) => {
				console.log(e);
			})

		})

		//Event qui update la valeur du pont bascule
		let N_weighBridgeValue = this.el.querySelector('#weighBridgeValue') as HTMLSpanElement;

		this.io.on('weighbridge.*.weight', (weight) => {

			N_weighBridgeValue.innerHTML = weight;

		});

		// Activer le changement au début
		this.updateSelectSite(global.SITE)
	}

	public updateSelectSite(_value: string) {
		this.updateTransactions().catch((e) => {
			console.error(e);
			toastr.error('Impossbile de récupérer les transactions');
		})

	}

	public destructor() {

		this.io.removeAllListeners('weighbridge.*.weight');

		for (let transaction of this.transactions) {
			transaction.destructor();
		}

	}

	private async updateTransactions(): Promise<void> {

		this.N_cardContainer.innerHTML = ''

		const data = await this.getRowData()
		
		if (data.length) {
			for (let element of data) {
				let transaction = new CardTransaction(element._id);
				this.transactions.push(transaction);
				this.N_cardContainer.append(transaction.getNode());
			}
		}
		else {
			const div = document.createElement('div')
			div.classList.add('d-flex', 'justify-content-center', 'align-items-center', 'w-100')
			div.innerHTML = "<span>Aucune transactions en cours</span>"

			this.N_cardContainer.appendChild(div);
		}
	}

	private async getRowData(): Promise<Array<DeliveryNote>> {
		return DeliveryNotesService.getInstance().getBySiteNotArchived(global.SITE)
	}

}

export default TabTransaction;
import { Modal, Form, global } from "@autoprog/core-client"
import T_detailWorkOrder from '@tpl/modals/orders/detailWorkOrder.html'
import moment from 'moment'
import toastr from 'toastr'
import T_tableproducts from '@tpl/blocks/orders/work-order/tableDeliveredProduct.ejs'
import AddEditWorkOrder from './AddEditWorkOrder'
import unitList from '@libs/lists/unitList'
// import WorkOrderPrinter from '@libs/printers/WorkOrderPrinter'
import stateList from '@libs/lists/stateListMale'

import T_events from '@tpl/blocks/orders/detailEvent.ejs'
import FilesManager from '@js/libs/FilesManager'
import WorkOrder from '@js/types/workorder'
import Order from '@js/types/order'
import Tier from '@js/types/Tiers'
import { WorkOrdersService, OrdersService, TiersService, UsersService } from '@js/libs/services'
import User from '@js/types/user'
import StateTransaction from "@js/libs/lists/StateTransactionEnum"
import PrinterHelper from "@js/libs/utils/printer.helper"

interface DetailWorkOrderData extends WorkOrder {
	orderData: Order
	customerData: Tier
	providerData: Tier,
	eventUsers: Array<User>
}

class DetailWorkOrder extends Modal {

	private N_form: HTMLFormElement | null = null
	private workOrder!: DetailWorkOrderData
	private filesManager: FilesManager = new FilesManager()

	constructor(private workOrderId: string) {
		super({
			tpl: T_detailWorkOrder,
			backdrop: 'static',
			keyboard: false
		})

		this.on('opened', async () => {
			this.N_form = this.element.querySelector('#workOrderForm') as HTMLFormElement

			this.workOrder = await this.getData()

			// this.initEvent(this.workOrder.events.map(event => ({ ...event, user: this.workOrder.eventUsers.find(u => u._id === event.user) })) || [])
			const events = (this.workOrder.events || []).map(event => ({ ...event, user: this.workOrder.eventUsers.find(u => u._id === event.user) }))
			this.initEvent(events)

			const N_title = this.element.querySelector("#title") as HTMLElement
			N_title.innerHTML = this.workOrder._id

			const beginDate = moment(this.workOrder.beginDate, 'x').format('DD/MM/YYYY LT')
			const endDate = moment(this.workOrder.endDate, 'x').format('DD/MM/YYYY LT')

			if (this.N_form) {
				const form = new Form(this.N_form)
				form.setData({
					...this.workOrder,
					beginDate,
					endDate
				})
				this.addTableProduct(this.workOrder.products)
			}

			const N_attachment = this.element.querySelector('#attachments') as HTMLElement
			const N_attachmentUpload = this.element.querySelector("#attachments-upload") as HTMLElement

			N_attachmentUpload.appendChild(this.filesManager.getNode())

			if (this.workOrder._attachments) {
				this.filesManager.setFiles(this.workOrder._attachments)
				this.addAttachment('work-orders', N_attachment, this.workOrder);
			}
			else {
				N_attachment.innerHTML = 'Pas de pièces jointes';
			}

			const N_details = this.element.querySelector("#details") as HTMLInputElement
			if (!this.workOrder.detail) {
				N_details.value = "(vide)"
			}


			const N_editBtn = this.element.querySelector('#editButton') as HTMLButtonElement
			
			if (this.workOrder.state === StateTransaction.billed) {
				N_editBtn.remove()
			}

			N_editBtn.addEventListener('click', () => {
				if (this.workOrder && this.workOrder.state != 'billed') {
					const modal = new AddEditWorkOrder(this.workOrder._id)
					this.hide()
					modal.open().then(() => this.resolve(true)).catch(() => this.show())
				}
			})

			const N_printBtn = this.element.querySelector('#printButton') as HTMLButtonElement;
			N_printBtn.addEventListener('click', async () => {
				try {
					const blob = await WorkOrdersService.getInstance().getPDF(this.workOrderId)
					PrinterHelper.previewPDF(blob, `${this.workOrderId}.pdf`)
				}
				catch (e) {
					toastr.error(`Erreur lors de l'impression`)
					console.error(e)
				}
			})

			const N_SaveDocument = this.element.querySelector("#save-document") as HTMLButtonElement
			N_SaveDocument.addEventListener('click', async () => {
				// On met à jour les pièces jointes en fonction du bon d'intervention
				WorkOrdersService.getInstance().syncAttachments(this.workOrderId, this.filesManager.getArrayFiles())
				toastr.success("Documents du bon d'intervention modifié")
			})

		})
	}

	private initEvent(events: any[]) {

		let N_container = this.element.querySelector('#events');

		if (N_container) {

			N_container.innerHTML = T_events({
				events,
				moment,
				stateList
			});

		}

	}

	private async getData(): Promise<DetailWorkOrderData> {
		const workOrder = await WorkOrdersService.getInstance().getByID(this.workOrderId)
		const orderData = await OrdersService.getInstance().getByID(workOrder.order)
		const [customerData, providerData, eventUsers] = await Promise.all([
			TiersService.getInstance().getByID(orderData.customer),
			TiersService.getInstance().getByID(orderData.provider),
			UsersService.getInstance().getByIds((workOrder.events || []).map(event => event.user))
		])

		return {
			...workOrder,
			orderData,
			customerData,
			providerData,
			eventUsers
		}
	}

	private addTableProduct(products: { [key: string]: any }[]): void {
		const divProduct = this.element.querySelector('#deliveredProducts') as HTMLDivElement
		divProduct.innerHTML = T_tableproducts({ products, unitList });
	}

	private addAttachment(table: string, N_element: HTMLElement, object: any): void {

		for (let attachment in object._attachments) {

			const N_buttonAttachment = document.createElement('button') as HTMLButtonElement

			N_buttonAttachment.type = 'button'
			N_buttonAttachment.classList.add('btn', 'btn-outline-secondary', 'btn-sm', 'btn-rounded', 'mr-2')
			N_buttonAttachment.innerHTML = attachment
			N_element.appendChild(N_buttonAttachment)

			N_buttonAttachment.addEventListener('click', () => {
				window.open(`${global.COUCHDB_URL}/${table}/${object._id}/${attachment}`, "_blank")
			})

		}
	}
}

export default DetailWorkOrder;
